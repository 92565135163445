// TODO: escrever testes (falar com ailton)

/**
 * Função por trás dos cálculos de Lamina
 * @param tanques Lista de tanques
 */
export function calcularLamina(tanques: Tanque[] | null): Lamina {
  let m2 = 0;
  let m3 = 0;
  tanques?.map((tanque) => {
    if (tanque.tanque_tipo.id === 2) {
      m2 += tanque.tamanho;
    } else {
      m3 += tanque.tamanho;
    }
  });
  return { m2, m3 };
}

/**
 * Retorna a fase do tanque baseado no LoteTanque e no estoque
 * @param estoque Quantidade de animais no tanque
 * @param loteTanque Relacionamento lote_tanque
 */
export function returnFase(estoque: number, loteTanque: Tanque['lotes'] | null): string {
  if (loteTanque) {
    if (estoque !== 0) {
      return loteTanque.map((loteTanque) => (loteTanque.fase_lote ? loteTanque.fase_lote.nome : 'Livre')).join(', ');
    }
  }
  return 'Livre';
}

/**
 * Retorna a espécie do tanque baseado no LoteTanque e no estoque
 * @param estoque Quantidade de animais no tanque
 * @param loteTanque Relacionamento lote_tanque
 */
export function returnEspecie(estoque: number, loteTanque: Tanque['lotes'] | null): string {
  if (loteTanque) {
    if (estoque !== 0) {
      return loteTanque
        .map((loteTanque) => {
          return loteTanque.especie_explorada.nome;
        })
        .join(', ');
    } else {
      return 'Sem Espécie';
    }
  } else {
    return 'Sem Espécie';
  }
}

export function returnTanque(tanque: { nome: string | null; codigo: string }): string {
  if (tanque.nome === '' || tanque.nome === null) {
    return tanque.codigo;
  } else {
    return tanque.nome;
  }
}
