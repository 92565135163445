import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import useStore from '../../../services/hooks/useStore';
import Botao from '../../../components/Botoes/Botao';
import Formatter from '../../../services/Formatter';
import { apiV2 } from '../../../services/utils';
import { useLocalStore } from 'mobx-react-lite';
import { TextField } from '@material-ui/core';
import { LoteTanqueInfo } from '../../../components/LoteTanqueInfo';
import { returnTanque } from '../../../services/utils/propriedade';

type Props = ModalProps & {
  cancelar(): void;
  // eslint-disable-next-line no-unused-vars
  addTanque(tanqueID: number): void;
  selectedTanques: SelectedLoteTanque[];
};
const SelecionarTanque: React.FC<Props> = ({ open, handleClose, selectedTanques, cancelar, addTanque }) => {
  const state = useLocalStore(() => ({
    sync: false,
  }));
  const store = useStore();
  const [codigo, setCodigo] = useState<string>('');
  const [loteTanques, setLoteTanques] = useState<LoteTanque[]>([] as LoteTanque[]);

  const [, setSelectedLoteTanque] = useState<LoteTanque | null>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function searchTanque() {
      store.toggleLoader();
      await apiV2
        .get('/lote-tanque/modals/', {
          params: {
            propriedade: store.propriedade!.id,
            nome: codigo,
          },
        })
        .then((res) => {
          setLoteTanques(res.data.results);
        });

      state.sync = true;
      store.toggleLoader();
    }
    searchTanque();

    // eslint-disable-next-line
  }, [selectedTanques, codigo, setCodigo]);

  const TanquesDisponiveis: React.FC = () => (
    <>
      {loteTanques
        ?.sort((a: any, b: any) => {
          const textA = a.tanque.nome ? a.tanque.nome.toUpperCase() : a.tanque.codigo;
          const textB = b.tanque.nome ? b.tanque.nome.toUpperCase() : b.tanque.codigo;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map((loteTanque: any) => {
          const selected = selectedTanques.some((obj) => loteTanque.id === obj.item.id);

          if (loteTanque.estoque > 0) {
            const PM = loteTanque.peso_medio_atual || loteTanque.peso_medio;
            return (
              <LoteTanqueInfo
                selected={selected || undefined}
                onClick={() => {
                  setSelectedLoteTanque(loteTanque);
                  addTanque(loteTanque.id);
                }}
                key={loteTanque.id}
              >
                {/* <p className="left">{loteTanque.tanque}</p> */}
                <p className="left">{returnTanque(loteTanque.tanque)}</p>
                <p className="right">
                  {loteTanque.lote.codigo} ({Formatter.formatNumber(0, loteTanque.estoque)})
                </p>
                <b className="left" style={{ color: '#00C853' }}>
                  {Formatter.formatNumber(2, (PM * loteTanque.estoque) / 1000)} Kg
                </b>
                <p className="right" style={{ color: '#00C853' }}>
                  {loteTanque.fase_lote.nome}
                </p>
              </LoteTanqueInfo>
            );
          } else {
            return null;
          }
        })}
    </>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>Selecione um Tanque</ModalHeader>
      <ModalBody>
        <div
          style={{ color: '#707070', width: '100%', padding: '10px', display: 'flex', justifyContent: 'space-between' }}
        >
          <TextField
            label="Pesquisa Pelo Nome"
            value={codigo}
            onChange={(e) => {
              setCodigo(e.target.value);
            }}
          />
        </div>
        <TanquesDisponiveis />
      </ModalBody>
      <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
        <Botao cor="gray" onClick={cancelar}>
          Voltar
        </Botao>
        <Botao cor="#00C853" onClick={handleClose}>
          Confirmar
        </Botao>
      </div>
    </Modal>
  );
};

export default SelecionarTanque;
