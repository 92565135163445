/* eslint-disable react/display-name */
import React, { useRef, useState, useEffect } from 'react';
import useStore from '../../../services/hooks/useStore';
import MaterialTable, { Column } from 'material-table';
import ActionButton from '../../../components/Tabela/ActionButton';
import useModal from '../../../services/hooks/useModal';
import Formatter from '../../../services/Formatter';
import Header from '../../../components/Header';
import { Flex } from '../../../components/NavBar/styles';
import AddBtn from '../../../components/Botoes/AddBtn';
import AddIcon from '@material-ui/icons/Add';
import Filtro from '../../../components/Filtro';
import { Body } from '../../../components/Body';
import { config } from '../../../components/Tabela';
import { apiV2 } from '../../../services/utils';
import X9Logger from '../../../services/X9Logger';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import FormProgramaAlimentar from './FormProgramaAlimentar';
import { useLocalStore, observer } from 'mobx-react-lite';
import { permissoes } from '../../../services/utils/permissoes';

const SemanasTable: React.FC<SemanasTableProps> = ({ semanas, columns }) => {
  return (
    <MaterialTable
      columns={columns}
      data={semanas}
      options={{
        ...config.options,
        paging: false,
        minBodyHeight: 0,
        maxBodyHeight: 600,
        pageSize: semanas.length + 1,
        headerStyle: {
          background: 'white',
          color: '#2D2D2D',
          height: '40px',
          paddingTop: 10,
          paddingBottom: 10,
          fontSize: 14,
          borderBottom: 'none',
          zIndex: 0,
        },
      }}
      localization={config.localization}
      style={config.style}
    />
  );
};

const ProgramasAlimentares: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  const tableRef = useRef(null);
  store.propriedade && store.validarPermissao(permissoes.CONTROLE_RACAO_PROGRAMA_ALIMENTAR_LEITURA, history);
  const [selectedPrograma, setSelectedPrograma] = useState<any | null>(null);
  const modalPrograma = useModal();
  const modalExclusao = useModal();
  const state = useLocalStore(() => ({
    tipos: [] as GenericType[],
    especies: [] as GenericType[],
    racoes: [] as GenericType[],
    marcas: [] as GenericType[],
    fases: [] as GenericType[],

    pageSize: 0,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },

    async sync() {
      store.toggleLoader();

      try {
        await Promise.all([
          apiV2.get('/tipos-de-programa/', {
            params: {
              propriedade: store.propriedade!.id,
            },
          }),
          apiV2.get('/especies/', {
            params: {
              propriedade: store.propriedade!.id,
            },
          }),
          apiV2.get('/racao/modals/', {
            params: {
              propriedade: store.propriedade!.id,
            },
          }),
          apiV2.get('/fases/', {
            params: {
              propriedade: store.propriedade!.id,
            },
          }),
          apiV2.get('/marcas/', {
            params: {
              prop: store.propriedade!.id,
            },
          }),
        ]).then((values) => {
          const racoesFiltradas = values[2].data.results.filter((currentValue: any) => currentValue.ativo === true);
          this.tipos = values[0].data.results;
          this.especies = values[1].data.results;

          this.racoes = racoesFiltradas;
          this.fases = values[3].data.results;
          this.marcas = values[4].data.results;
        });
      } catch (err) {
        store.notificar('Ocorreu um erro ao pegar as informações necessárias!');
      }

      store.toggleLoader();
    },
  }));

  const columns: Column<any>[] = [
    {
      field: 'acoes',
      sorting: false,
      filtering: false,
      disableClick: true,
      render: (rowData) => (
        <ActionButton
          editar={
            store.checkPermissions(permissoes.CONTROLE_RACAO_PROGRAMA_ALIMENTAR_ESCRITA) && !rowData.instituicao_id
              ? () => {
                  setSelectedPrograma(rowData);
                  modalPrograma.open();
                }
              : undefined
          }
          excluir={
            store.checkPermissions(permissoes.CONTROLE_RACAO_PROGRAMA_ALIMENTAR_ESCRITA) && !rowData.instituicao_id
              ? () => {
                  setSelectedPrograma(rowData);
                  modalExclusao.open();
                }
              : undefined
          }
        />
      ),
      width: 25,
    },
    {
      title: 'Nome',
      field: 'nome',
      width: '21%',
    },

    { title: 'Tipo', field: 'tipo.nome', width: '20%' },
    {
      title: 'Espécie',
      field: 'especie.nome',
      width: '21%',
    },
    {
      title: 'Semanas/Dias',
      field: 'semanas',
      render: (rowData) => {
        return rowData.semanas.length;
      },
      width: '20%',
    },
  ];
  const semanaColumns: Column<any>[] = [
    {
      field: 'codigo',
      title: 'Semana/Dia',
      width: 100,
    },
    {
      field: 'racao.nome',
      title: 'Ração',
      width: 150,
    },
    {
      field: 'fase.nome',
      title: 'Fase',
      width: 100,
    },
    {
      field: 'pm_ini',
      title: 'PM Inicial (g)',
      type: 'numeric',
      render: ({ pm_ini }) => {
        return Formatter.formatNumber(3, pm_ini);
      },
      width: 160,
    },
    {
      field: 'pm_fim',
      title: 'PM Final (g)',
      type: 'numeric',
      render: ({ pm_fim }) => {
        return Formatter.formatNumber(3, pm_fim);
      },
      width: 150,
    },
    {
      field: 'sobrevivencia',
      title: '% Sobrevivência',
      type: 'numeric',
      width: 170,
    },
    {
      field: 'num_tratos',
      title: 'Nº Tratos',
      type: 'numeric',
      render: ({ num_tratos }) => {
        return Formatter.formatNumber(0, num_tratos);
      },
      width: 90,
    },
    {
      field: 'perc_biomassa',
      title: '% Biomassa (28ºC - 100%)',
      type: 'numeric',
      render: ({ perc_biomassa }) => {
        return Formatter.formatNumber(2, perc_biomassa);
      },
      width: 150,
    },
    {
      field: 'consumo_g',
      title: 'Consumo (g) (28ºC - 100%)',
      type: 'numeric',
      render: ({ consumo_g }) => {
        return Formatter.formatNumber(2, consumo_g);
      },
      width: 150,
    },
    {
      field: 'ca_esperado',
      title: 'CA Esperado',
      type: 'numeric',
      render: ({ ca_esperado }) => {
        return Formatter.formatNumber(2, ca_esperado);
      },
      width: 150,
    },
  ];

  async function excluir(): Promise<void> {
    store.toggleLoader();

    try {
      //TODO: verificar com ailton o problema de consistencia de deletar um programa alimentar que ainda tenha arraçoamentos
      // const response = await apiV2.get('/arracoamentos-programa/quantidade/', {
      //   params: { programa: selectedPrograma!.id, prop: store.propriedade!.id },
      // });

      // if (response.data.count === 0) {
      await apiV2.delete('/programas-alimentares/' + selectedPrograma!.id + '/', {
        params: {
          prop: store.propriedade!.id,
        },
      });
      modalExclusao.close();
      // } else {
      //   store.notificar(
      //     'Existem arraçoamentos utilizando o programa alimentar selecionado. O programa não pode ser excluído!',
      //   );
      // }
    } catch (err) {
      store.notificar('Ocorreu um erro ao excluir o programa.');
    }

    store.toggleLoader();
    (tableRef.current as any).onQueryChange({ page: 0 });
  }

  useEffect(() => {
    if (store.propriedade) {
      state.sync();
      (tableRef.current as any).onQueryChange();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  useEffect(() => {
    store.setTitulo('Programas Alimentares');
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header>
        <Flex>
          {store.propriedade && (
            <AddBtn
              disabled={!store.checkPermissions(permissoes.CONTROLE_RACAO_PROGRAMA_ALIMENTAR_ESCRITA)}
              onClick={() => {
                setSelectedPrograma(null);
                modalPrograma.open();
              }}
              text="ADICIONAR"
              Icon={AddIcon}
            />
          )}
        </Flex>
        <Flex>
          <Filtro
            sync={() => {
              state.sync();
              (tableRef.current as any).onQueryChange();
            }}
            clear={() => {
              state.sync();
              (tableRef.current as any).onQueryChange();
            }}
          ></Filtro>
        </Flex>
      </Header>
      <Body gridColumns="1fr">
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          <MaterialTable
            tableRef={tableRef}
            columns={columns}
            data={(query) =>
              new Promise((resolve) => {
                try {
                  store.propriedade &&
                    apiV2
                      .get('/programas-alimentares/', {
                        params: {
                          page_size: 50,
                          page: query.page + 1,
                          prop: store.propriedade!.id,
                        },
                      })
                      .then((res) => {
                        if (res.data.count) {
                          if (res.data.count >= 50) {
                            state.setPageSize(50);
                          } else {
                            state.setPageSize(res.data.count);
                          }
                        } else {
                          state.setPageSize(0);
                        }

                        resolve({
                          data: res.data.results,
                          page: query.page,
                          totalCount: res.data.count,
                        });
                      });
                } catch (err) {
                  X9Logger.report(err as Error);
                  store.notificar('Ocorreu um erro ao adquirir informações. Tente novamente mais tarde!');
                }
              })
            }
            options={{ ...config.options, pageSize: state.pageSize }}
            localization={config.localization}
            style={config.style}
            onRowClick={(_e, rowData) => {
              setSelectedPrograma(rowData);
              modalPrograma.open();
            }}
            detailPanel={(rowData) => {
              return <SemanasTable columns={semanaColumns} semanas={rowData.semanas} />;
            }}
          />
        </div>
      </Body>

      {modalPrograma.state && (
        <FormProgramaAlimentar
          open={modalPrograma.state}
          handleClose={modalPrograma.close}
          programa={selectedPrograma}
          onConfirm={(tableRef.current as any).onQueryChange}
          tipos={state.tipos}
          especies={state.especies}
          marcas={state.marcas}
          racoes={state.racoes}
          fases={state.fases}
        />
      )}

      <Dialog open={modalExclusao.state} onClose={modalExclusao.close}>
        <DialogTitle>Tem certeza que deseja excluir esse programa?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Você está prestes a excluir o programa alimentar &quot;{selectedPrograma?.nome}&quot;. Deseja continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={modalExclusao.close} color="primary">
            Cancelar
          </Button>
          <Button onClick={excluir} color="primary" autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default observer(ProgramasAlimentares);
