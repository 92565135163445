import { observer } from 'mobx-react-lite';
import React from 'react';
import { TabBar, TabItem, NavbarLink } from '../../../pages/Financeiro/Painel/styles';

const PainelZootecnicoTab: React.FC = () => {
    return (
        <>
            <TabBar>
                <TabItem>
                    <NavbarLink className="nav-link" to="/relatorios/painel-zootecnico"
                        activeStyle={{ color: '#EC7722', textDecorationLine: 'underline', textDecorationThickness: '3px', textUnderlineOffset: '10px' }}>
                        Painel
                    </NavbarLink >
                </TabItem>
                <TabItem>
                    <NavbarLink className="nav-link" to="/relatorios/crescimento"
                        activeStyle={{ color: '#EC7722', textDecorationLine: 'underline', textDecorationThickness: '3px', textUnderlineOffset: '10px' }}>
                        Curva de Crescimento
                    </NavbarLink >
                </TabItem>
                <TabItem>
                    <NavbarLink className="nav-link" to="/relatorios/historico-ca"
                        activeStyle={{ color: '#EC7722', textDecorationLine: 'underline', textDecorationThickness: '3px', textUnderlineOffset: '10px' }}>
                        Histórico de CA
                    </NavbarLink >
                </TabItem>
                <TabItem>
                    <NavbarLink className="nav-link" to="/relatorios/relatorios-zootecnicos"
                        activeStyle={{ color: '#EC7722', textDecorationLine: 'underline', textDecorationThickness: '3px', textUnderlineOffset: '10px' }}>
                        Listagem de Relatórios
                    </NavbarLink >
                </TabItem>

            </TabBar>
        </>
    );
};

export default observer(PainelZootecnicoTab);