/* eslint-disable react/display-name */
import { IconButton, Tooltip } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CachedIcon from '@material-ui/icons/Cached';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';
import MaterialTable, { Column } from 'material-table';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Conteudo, NewBody } from '../../../components/Body';
import { Flex } from '../../../components/NavBar/styles';

import { newConfig } from '../../../components/Tabela';
import Formatter from '../../../services/Formatter';
import useModal from '../../../services/hooks/useModal';
import useStore from '../../../services/hooks/useStore';
import { apiMain, aqReports } from '../../../services/utils';

// import FiltroAvancado from '../../../components/Modais/FiltroAvancado';
import X9Logger from '../../../services/X9Logger';
import { permissoes } from '../../../services/utils/permissoes';

const EstoqueTanques: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.PAINEL_ZOOTECNICO_LEITURA, history);
  const tableRef = useRef(null);
  const modalFiltros = useModal();
  const location: any = useLocation();
  const state = useLocalStore(() => ({
    rows: [] as object[],
    sync: false,
    pageSize: 0,

    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
    page: location.state ? location.state.page : 0,
    setPage(num: number) {
      this.page = num;
    },
  }));

  async function sync(): Promise<void> {
    const tabela: any = tableRef.current;
    tabela.onQueryChange();
  }

  useEffect(() => {
    store.setTitulo('Estoque de Tanques');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getThings(): Promise<void> {
      state.sync = true;
    }
    if (store.propriedade) {
      getThings();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  const columns: Column<object>[] = [
    {
      field: 'tanque.nome',
      title: 'Tanque',
      width: 150,
    },
    {
      field: 'lote.nome',
      title: 'Lote',
      type: 'string',
      width: 120,
    },
    {
      field: 'especie',
      title: 'Espécie',
      type: 'string',
      width: 120,
    },
    {
      field: 'fase',
      title: 'Fase Lote',
      type: 'string',
      width: 120,
    },
    {
      field: 'data_alojamento',
      title: 'Data Entrada',
      type: 'date',
      width: 140,
    },
    {
      field: 'data_prevista',
      title: 'Previsão de Despesca',
      type: 'date',
      width: 180,
    },
    {
      field: 'povoamento',
      title: 'Povoamento',
      type: 'numeric',
      width: 140,
      render: ({ povoamento }: any) => {
        return Formatter.formatNumber(0, povoamento);
      },
    },
    {
      field: 'transferencia_entrada',
      title: 'Trans. Ent.',
      width: 150,
      render: ({ transferencia_entrada }: any) => {
        return Formatter.formatNumber(0, transferencia_entrada);
      },
      type: 'numeric',
    },
    {
      field: 'transferencia_saida',
      title: 'Trans. Saída',
      type: 'numeric',
      width: 150,
      render: ({ transferencia_saida }: any) => {
        return Formatter.formatNumber(0, transferencia_saida);
      },
    },
    {
      field: 'qtd_mortalidade',
      title: 'Mortes',
      type: 'numeric',
      width: 120,
      render: ({ qtd_mortalidade }: any) => {
        return Formatter.formatNumber(0, qtd_mortalidade);
      },
    },
    {
      field: 'qtd_vendida',
      title: 'Venda',
      type: 'numeric',
      width: 120,
      render: ({ qtd_vendida }: any) => {
        return Formatter.formatNumber(0, qtd_vendida);
      },
    },
    {
      field: 'estoque_atual',
      title: 'Estoque Atual',
      type: 'numeric',
      width: 140,
      render: ({ estoque_atual }: any) => {
        return Formatter.formatNumber(0, estoque_atual);
      },
    },
    {
      field: 'pm_atual',
      title: 'PM Atual (kg)',
      type: 'numeric',
      width: 150,
      render: ({ pm_atual }: any) => {
        return Formatter.formatNumber(3, pm_atual);
      },
    },
    {
      field: 'biomassa_total',
      title: 'Biomassa Total (kg)',
      type: 'numeric',
      width: 180,
      render: ({ biomassa_total }: any) => {
        return Formatter.formatNumber(3, biomassa_total);
      },
    },
  ];

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getCSV() {
    try {
      const csv = await apiMain.get(`/gerador/csv/relatorio/tanques/${store.propriedade?.id}/`, {
        params: {
          authori: store.token,
        },
      });
      window.open(csv.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o CSV! Favor entrar em contato com o Suporte.');
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getPDF() {
    try {
      const pdf = await apiMain.get(`/gerador/pdf/relatorio/tanques/${store.propriedade?.id}/`, {
        params: {
          authori: store.token,
        },
      });
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }

  return state.sync ? (
    <NewBody>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Link
          to={'/relatorios/relatorios-zootecnicos'}
          style={{ color: '#049CE7', textDecoration: 'none', fontFamily: 'Roboto' }}
        >
          <Flex>
            <img src={'/images/returnArrow.svg'} />
            <div style={{ paddingLeft: '5px', fontFamily: 'Roboto', color: '#049CE7' }}>Lista de Relatórios</div>
          </Flex>
        </Link>
        <Flex>
          <Tooltip title="Exportar para CSV">
            <IconButton className="blue" onClick={getCSV}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Baixar PDF">
            <IconButton className="blue" onClick={getPDF}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Filtro Avançado">
            <IconButton className="blue" onClick={modalFiltros.open}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sincronizar">
            <IconButton className="blue" onClick={sync}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>

      <Conteudo style={{ padding: 0 }}>
        {store.propriedade && (
          <MaterialTable
            tableRef={tableRef}
            columns={columns}
            data={(query) =>
              new Promise((resolve, reject) => {
                aqReports
                  .get('/relatorio/tanques/' + store.propriedade?.uuid, {
                    params: {
                      page: query.page + 1,
                      item_count: 50,
                    },
                  })
                  .then((res) => {
                    let data = res.data.results.filter((item: any) => item.estoque_atual !== 0);

                    // sort por nome do tanque
                    data = data.sort((a: any, b: any) => {
                      if (a.tanque.nome < b.tanque.nome) {
                        return -1;
                      }
                      if (a.tanque.nome < b.tanque.nome) {
                        return 1;
                      }
                      return 0;
                    });

                    state.rows = data;

                    resolve({
                      data: data,
                      page: res.data.page - 1,
                      totalCount: res.data.count_total,
                    });
                  })
                  .catch((err) => {
                    X9Logger.report(err);
                    store.notificar('Ocorreu um erro ao gerar relatório. Tente novamente mais tarde!');
                    reject();
                  });
              })
            }
            options={{ ...newConfig.options, pageSize: 50 }}
            localization={newConfig.localization}
            style={newConfig.style}
          />
        )}
      </Conteudo>

      {/* <FiltroAvancado
        open={modalFiltros.state}
        handleClose={modalFiltros.close}
        onConfirm={sync}
        onClear={limparFiltros}
      >
        <div style={{ width: '90%' }}>
          <Select
            value={state.filtroFase}
            placeholder="Fase do lote"
            onChange={(e: any) => {
              state.filtroFase = e;
              state.filtroRapidoFase = null;
            }}
            options={store.fasesLote.getSelectRows()}
          />
        </div>
        <Divisor>
          <p>Período da Última Atualização</p>
        </Divisor>
        <div style={{ width: '90%' }}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Início"
            value={state.filtroDataInicio}
            onChange={(date) => (state.filtroDataInicio = date)}
          />
        </div>
        <div style={{ width: '90%' }}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Fim"
            value={state.filtroDataFim}
            onChange={(date) => (state.filtroDataFim = date)}
          />
        </div>
      </FiltroAvancado> */}
    </NewBody>
  ) : null;
};

export default observer(EstoqueTanques);
