/* eslint-disable react/display-name */
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable, { Column, QueryResult } from 'material-table';
import { useLocalStore, observer } from 'mobx-react-lite';
import AddIcon from '@material-ui/icons/Add';
import { Tooltip } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import Header from '../../../components/Header';
import { Flex } from '../../../components/NavBar/styles';
import AddBtn from '../../../components/Botoes/AddBtn';
import { Body } from '../../../components/Body';
import { config } from '../../../components/Tabela';
import Filtro from '../../../components/Filtro';
import Select from '../../../components/Select';
import ActionButton from '../../../components/Tabela/ActionButton';
import Confirmar from '../../../components/Modais/Confirmar';
import ModalNovoRateio from './ModalNovoRateio';
import VisualizarRateio from './VisualizarRateio';

import useStore from '../../../services/hooks/useStore';
import { apiV2 } from '../../../services/utils';
import useModal from '../../../services/hooks/useModal';
import { returnTanque } from '../../../services/utils/propriedade';
import Formatter from '../../../services/Formatter';
import { permissoes } from '../../../services/utils/permissoes';

type State = PageState & {
  rows: Rateio[];

  filtroMesAno: MaterialUiPickersDate;
  filtroCategoria: SelectRow | null;
  filtroLote: SelectRow | null;
  filtroTanque: SelectRow | null;

  filterUrlArray: string[];
  filterUrlString: string;

  selectedRateio: Rateio | null;

  sync: boolean;
};

type Row = {
  rateio: Rateio;
  mes: string;
  ano: number;
  categoria: string;
  area: string;
  valor: string;
  diaMes: number;
  porcentagemrateio: number;
  valorporarea: string;
  valorpordia: string;
  tanques: string[];
  lotes: string[];
};

const Rateio: React.FC = () => {
  const tableRef = useRef();
  const modalCriarRateio = useModal();
  const modalEditarRateio = useModal();
  const modalExclusao = useModal();
  const store = useStore();
  const history = useHistory();

  const state = useLocalStore(
    (): State => ({
      pageSize: 0,
      setPageSize(value: number) {
        this.pageSize = value + 1;
      },
      rows: [] as any,

      filtroMesAno: null,
      filtroCategoria: null,
      filtroLote: null,
      filtroTanque: null,

      filterUrlArray: [],
      filterUrlString: '',

      selectedRateio: null,
      sync: false,
    }),
  );
  store.propriedade && store.validarPermissao(permissoes.FINANCEIRO_RATEIO_DE_CUSTO_FIXO_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.FINANCEIRO_RATEIO_DE_CUSTO_FIXO_ESCRITA);
  const temPermissaoLeitura = store.checkPermissions(permissoes.FINANCEIRO_RATEIO_DE_CUSTO_FIXO_LEITURA);

  const columns: Column<Row>[] = [
    {
      align: 'center',
      field: 'mes',
      title: 'Mês',
      ...(true && ({ width: 50 } as object)),
    },
    {
      align: 'center',
      field: 'ano',
      title: 'Ano',
      type: 'date',
      ...(true && ({ width: 50 } as object)),
    },
    {
      field: 'acoes',
      render: (rowData: any) => (
        <ActionButton
          visualizar={
            temPermissaoLeitura
              ? () => {
                  state.selectedRateio = rowData.rateio;
                  modalEditarRateio.open();
                }
              : undefined
          }
          excluir={
            temPermissaoEscrita
              ? () => {
                  state.selectedRateio = rowData.rateio;
                  modalExclusao.open();
                }
              : undefined
          }
        />
      ),
      ...(true && ({ width: 25 } as object)),
    },
    {
      align: 'center',
      field: 'diaMes',
      title: 'Qtde Dias',
      type: 'numeric',
      ...(true && ({ width: 120, textAlign: 'center !important' } as object)),
    },
    {
      align: 'left',
      field: 'categoria',
      title: 'Categoria',
      ...(true && ({ width: 80, textAlign: 'start !important' } as object)),
    },
    {
      align: 'right',
      field: 'area',
      title: 'Area (M2/M3)',
      ...(true && ({ width: 135, textAlign: 'end' } as object)),
    },
    {
      align: 'right',
      field: 'valor',
      title: 'Vr Total Mês (R$)',
      ...(true && ({ width: 148, textAlign: 'end' } as object)),
    },
    {
      align: 'center',
      field: 'porcentagemrateio',
      title: '% Rateio',
      type: 'numeric',
      ...(true && ({ width: 100, textAlign: 'center !important' } as object)),
    },
    {
      align: 'right',
      field: 'valorporarea',
      title: 'Vr por Area',
      type: 'numeric',
      ...(true && ({ width: 140 } as object)),
    },
    {
      align: 'right',
      field: 'valorpordia',
      title: 'Vr por Dia/Area',
      type: 'numeric',
      ...(true && ({ width: 160 } as object)),
    },
    {
      field: 'lotes',
      title: 'Lotes',
      render: (rowData: any) => {
        const tanques = rowData.lotes?.join(', ');
        return (
          <Tooltip title={tanques}>
            <span>{tanques.substr(0, 20) + '...'}</span>
          </Tooltip>
        );
      },
      width: 150,
    },
    {
      field: 'tanques',
      title: 'Tanques',
      render: (rowData: any) => {
        const tanques = rowData.tanques.join(', ');
        return (
          <Tooltip title={tanques}>
            <span>{tanques.substr(0, 20) + '...'}</span>
          </Tooltip>
        );
      },
      width: 150,
    },
  ];

  function rows(rateios: Rateio[]): Row[] {
    if (!rateios || rateios?.length === 0) return [];
    return rateios?.map((rateio) => {
      return {
        rateio: rateio,
        mes:
          rateio.mes === 1
            ? 'Janeiro'
            : rateio.mes === 2
            ? 'Fevereiro'
            : rateio.mes === 3
            ? 'Março'
            : rateio.mes === 4
            ? 'Abril'
            : rateio.mes === 5
            ? 'Maio'
            : rateio.mes === 6
            ? 'Junho'
            : rateio.mes === 7
            ? 'Julho'
            : rateio.mes === 8
            ? 'Agosto'
            : rateio.mes === 9
            ? 'Setembro'
            : rateio.mes === 10
            ? 'Outubro'
            : rateio.mes === 11
            ? 'Novembro'
            : 'Dezembro',
        ano: rateio.ano,
        categoria: rateio.categoria.nome,
        area: Formatter.formatNumber(2, rateio.area),
        valor: Formatter.formatNumber(2, rateio.valor),
        diaMes: rateio.dias_mes,
        porcentagemrateio: rateio.porcentagem,
        valorporarea: rateio.valor_area.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
        valorpordia: rateio.valor_dia_area.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
        tanques: rateio.lote_tanques
          ? rateio.lote_tanques?.map((tanque) => {
              return tanque.tanque__nome;
            })
          : [],
        lotes: rateio.lote_tanques
          ? rateio.lote_tanques.map((tanque) => {
              return tanque.lote__nome ?? tanque.lote__codigo;
            })
          : [],
      };
    });
  }

  async function recarregarTabela() {
    // await store.rateios.getPaginated(0, 25, store.propriedade!.id, state.filterUrlString);
    await (tableRef.current as any).onQueryChange();
  }

  async function sync() {
    store.toggleLoader();

    await store.categorias.populate();
    await store.lotes.populate();
    await store.loteTanque.populate();
    recarregarTabela();

    state.sync = true;
    store.toggleLoader();
  }

  async function filtrar() {
    if (state.filtroCategoria || state.filtroLote || state.filtroTanque || state.filtroMesAno) {
      store.toggleLoader();
      if (state.filtroCategoria) {
        state.filterUrlArray.push(`&categoria=${state.filtroCategoria!.value}`);
      }
      if (state.filtroLote) {
        state.filterUrlArray.push(`&lote=${state.filtroLote!.value}`);
      }
      if (state.filtroTanque) {
        state.filterUrlArray.push(`&tanque=${state.filtroTanque!.value}`);
      }
      if (state.filtroMesAno) {
        const data = state.filtroMesAno.toISOString().split('T')[0];
        const [ano, mes] = data.split('-');
        state.filterUrlArray.push(`&mes=${mes}&ano=${ano}`);
      }

      state.filterUrlString = state.filterUrlArray.join('');
      await recarregarTabela();

      store.toggleLoader();
      state.filterUrlArray = [];
    } else {
      sync();
    }
  }

  async function limparFiltro() {
    state.filtroCategoria = null;
    state.filtroLote = null;
    state.filtroTanque = null;
    state.filtroMesAno = null;
    state.filterUrlArray = [];
    state.filterUrlString = '';
    recarregarTabela();
  }

  async function deletar() {
    modalExclusao.close();
    store.toggleLoader();
    if (state.selectedRateio?.id) {
      await apiV2
        .delete('/rateio/' + state.selectedRateio?.id + '/', {
          params: {
            prop: store.propriedade?.id,
          },
        })
        .then(() => {
          store.notificar('Rateio Excluído com sucesso!');
          recarregarTabela();
        });
    }
    store.toggleLoader();
  }

  async function buscarDadosParaTabela(page: number): Promise<QueryResult<Row>> {
    let data = rows([]);
    let totalCount = 0;
    if (store.propriedade) {
      try {
        await store.rateios.getPaginated(page, 25, store.propriedade!.id, state.filterUrlString);

        if (store.rateios.totalRes) {
          if (store.rateios.totalRes >= 50) {
            state.setPageSize(50);
          } else {
            state.setPageSize(store.rateios.totalRes);
          }
        } else {
          state.setPageSize(0);
        }

        data = store.rateios.rateios ? rows(store.rateios.rateios) : [];
        totalCount = store.rateios.totalRes;
      } catch (err) {
        store.notificar('Ocorreu um erro ao adquirir informações. Tente novamente mais tarde!');
      }
    }

    return {
      data,
      page,
      totalCount,
    };
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Rateio de Custo Fixo'), []);

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  const ExcluirTransf = () => {
    const rateioData = state.selectedRateio;
    return (
      <div style={{ width: '100%' }}>
        <b>Categoria: </b>
        {rateioData && rateioData.categoria.nome}
        <br />
        <b>Mês: </b> {rateioData && rateioData.mes}
        <br />
        <b>Ano: </b>
        {rateioData && rateioData.ano}
        <br />
        <b>Quantidades de Dias: </b>
        {rateioData && rateioData.dias_mes}
        <br />
        <b>% Rateio: </b>
        {rateioData && rateioData.porcentagem}
        <br />
        <b>Area(M2/M3): </b>
        {rateioData && Formatter.formatNumber(2, rateioData.area)}
        <br />
        <b>Valor Total Mês: </b>
        {rateioData && Formatter.formatBRLCurrency(rateioData.valor)}
        <br />
        <b>Valor por Area: </b>
        {rateioData && Formatter.formatBRLCurrency(rateioData.valor_area)}
        <br />
        <b>Valor por Dia/Mes: </b>
        {rateioData && Formatter.formatBRLCurrency(rateioData.valor_dia_area)}
        <br />
      </div>
    );
  };

  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn
            disabled={!temPermissaoEscrita}
            onClick={modalCriarRateio.open}
            text="Adicionar Rateio"
            Icon={AddIcon}
          />
          <div style={{ width: 200 }}>
            <KeyboardDatePicker
              autoOk
              views={['year', 'month']}
              disableToolbar
              variant="inline"
              format="MM/yyyy"
              margin="normal"
              label="Mês e Ano"
              value={state.filtroMesAno}
              onChange={(date) => {
                state.filtroMesAno = date;
                filtrar();
              }}
            />
          </div>
        </Flex>
        <Flex>
          <Filtro sync={filtrar} clear={limparFiltro}>
            <div style={{ width: '90%' }}>
              <Select
                value={state.filtroCategoria}
                placeholder="Categoria"
                onChange={(e: any) => (state.filtroCategoria = e)}
                options={store.categorias.getSelectRows()!}
              />
            </div>
            <div style={{ width: '90%' }}>
              <Select
                value={state.filtroLote}
                placeholder="Lote"
                onChange={(e: any) => (state.filtroLote = e)}
                options={store.lotes?.map((lote) => {
                  return { label: lote.codigo, value: lote.id.toString() };
                })}
              />
            </div>
            <div style={{ width: '90%' }}>
              <Select
                disabled={!state.filtroLote}
                value={state.filtroTanque}
                placeholder="Tanque Origem"
                onChange={(e: any) => (state.filtroTanque = e)}
                options={store.loteTanque.getByLote(parseInt(state.filtroLote?.value!))?.map((loteTanque) => {
                  return { label: returnTanque(loteTanque.tanque), value: loteTanque.tanque.id.toString() };
                })}
              />
            </div>
            <div style={{ width: '90%' }}>
              <KeyboardDatePicker
                autoOk
                views={['year', 'month']}
                disableToolbar
                variant="inline"
                format="MM/yyyy"
                margin="normal"
                label="Mês e Ano"
                value={state.filtroMesAno}
                onChange={(date) => (state.filtroMesAno = date)}
              />
            </div>
          </Filtro>
        </Flex>
      </Header>

      <Body grid={true}>
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          {
            <MaterialTable
              tableRef={tableRef}
              columns={columns}
              data={(query) => new Promise((resolve) => resolve(buscarDadosParaTabela(query.page)))}
              options={{ ...config.options, pageSize: 25 }}
              localization={config.localization}
              style={config.style}
            />
          }
        </div>
      </Body>
      {modalCriarRateio.state ? (
        <ModalNovoRateio
          open={modalCriarRateio.state}
          handleClose={modalCriarRateio.close}
          handleSuccess={recarregarTabela}
        />
      ) : null}
      {modalEditarRateio.state ? (
        <VisualizarRateio
          open={modalEditarRateio.state}
          handleClose={modalEditarRateio.close}
          transferencia={state.selectedRateio}
        />
      ) : null}
      {modalExclusao.state ? (
        <Confirmar
          open={modalExclusao.state}
          content={<ExcluirTransf />}
          title="Tem certeza que deseja excluir?"
          handleClose={modalExclusao.close}
          onCancel={modalExclusao.close}
          onConfirm={deletar}
        />
      ) : null}
    </>
  ) : null;
};

export default observer(Rateio);

// ----------------------------------------------
