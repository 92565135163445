import React from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import Select from '../../../components/Select';
import { useLocalStore, observer } from 'mobx-react-lite';
import useStore from '../../../services/hooks/useStore';
import Botao from '../../../components/Botoes/Botao';
import InputNumero from '../../../components/InputNumero';
import { Grid, Paper, Typography } from '@material-ui/core';
import Formatter from '../../../services/Formatter';
import { evaluate, round } from 'mathjs';

type Props = ModalProps & {
  semana?: any;
  racoes: GenericType[];
  marcas: GenericType[];
  fases: GenericType[];
  codigo: string;
  onConfirm(semana: any): void;
};
const FormDia: React.FC<Props> = ({ handleClose, open, semana, codigo, onConfirm, racoes, marcas, fases }) => {
  const store = useStore();
  const state = useLocalStore(() => ({
    codigo,
    marca: semana ? semana.marca : null,
    racao: semana ? semana.racao : null,
    fase: semana ? semana.fase : null,
    pm_ini: semana ? semana.pm_ini : 0,
    pm_fim: semana ? semana.pm_fim : 0,
    sobrevivencia: semana ? semana.sobrevivencia : 0,
    num_tratos: semana ? semana.num_tratos : 0,
    perc_biomassa: semana ? semana.perc_biomassa : 0,
    consumo_g: semana ? semana.consumo_g : 0,
    ca_esperado: semana ? semana.ca_esperado : 0,
  }));

  function handleCalculos(
    perc_biomassa: number,
    pm_ini: number,
    pm_fim: number,
  ): { consumo: number; caEsperado: number } {
    const consumo = round(evaluate(`(${perc_biomassa} * ((${pm_ini} + ${pm_fim}) / 2)) / 100`), 3);

    return {
      consumo,
      caEsperado: round(evaluate(`${consumo} / (${pm_fim} - ${pm_ini})`), 2),
    };
  }

  async function enviar(): Promise<void> {
    if (!state.racao) store.notificar('Informe a ração');
    else if (!state.fase) store.notificar('Informe a fase');
    else if (!state.pm_ini) store.notificar('Informe o peso médio inicial');
    else if (!state.pm_fim) store.notificar('Informe o peso médio final');
    else if (!state.sobrevivencia) store.notificar('Informe a porcentagem de sobrevivência');
    else if (!state.num_tratos) store.notificar('Informe o número de tratos');
    else if (!state.perc_biomassa) store.notificar('Informe a porcentagem de biomassa');
    else if (!state.consumo_g) store.notificar('Informe o consumo');
    else if (!state.ca_esperado) store.notificar('Informe o CA Esperado');
    else {
      handleClose();
      onConfirm({
        id: semana?.id,
        codigo: state.codigo,
        marca: state.marca,
        racao: state.racao,
        fase: state.fase,
        pm_ini: state.pm_ini,
        pm_fim: state.pm_fim,
        sobrevivencia: state.sobrevivencia,
        num_tratos: state.num_tratos,
        perc_biomassa: state.perc_biomassa,
        consumo_g: state.consumo_g,
        ca_esperado: state.ca_esperado,
      });
    }
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{semana ? 'Editar' : 'Nova'} semana</ModalHeader>
      <ModalBody>
        <div style={{ width: '100%' }}>
          <Paper elevation={3} style={{ padding: 10, textAlign: 'center' }}>
            <Typography variant="h5">
              {state.codigo.includes('S') ? 'Semana' : 'Dia'} {state.codigo.substr(1)}
            </Typography>
          </Paper>
        </div>

        <div style={{ width: '100%' }}>
          <Select
            value={state.marca}
            placeholder="Marca da ração"
            onChange={(e: SelectRow) => (state.marca = e)}
            options={marcas.map((marca: GenericType) => ({ label: marca.nome, value: marca.id.toString() }))}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Select
            value={state.racao}
            placeholder="Ração"
            onChange={(e: SelectRow) => (state.racao = e)}
            options={racoes
              .filter((racao: any) => racao.marca_id === (Number(state.marca?.value) || racao.marca_id))
              .map((racao) => ({
                label: racao.nome,
                value: racao.id.toString(),
              }))}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Select
            value={state.fase}
            placeholder="Fase"
            onChange={(e: SelectRow) => (state.fase = e)}
            options={fases.map((fase: GenericType) => ({ label: fase.nome, value: fase.id.toString() }))}
          />
        </div>
        <InputNumero
          value={state.pm_ini}
          onChange={(value) => {
            state.pm_ini = value;

            const calculos = handleCalculos(state.perc_biomassa, value, state.pm_fim);
            state.consumo_g = calculos.consumo;
            state.ca_esperado = calculos.caEsperado;
          }}
          precision="3"
          label="Peso Médio Inicial (g)"
        />
        <InputNumero
          value={state.pm_fim}
          onChange={(value) => {
            state.pm_fim = value;

            const calculos = handleCalculos(state.perc_biomassa, state.pm_ini, value);
            state.consumo_g = calculos.consumo;
            state.ca_esperado = calculos.caEsperado;
          }}
          precision="3"
          label="Peso Médio Final (g)"
        />
        <InputNumero
          value={state.sobrevivencia}
          onChange={(value) => (state.sobrevivencia = value)}
          precision="2"
          label="% Sobrevivência"
        />
        <InputNumero
          value={state.num_tratos}
          onChange={(value) => (state.num_tratos = value)}
          precision="0"
          label="Nº Tratos/Dia"
        />
        <InputNumero
          value={state.perc_biomassa}
          onChange={(value) => {
            state.perc_biomassa = value;

            const calculos = handleCalculos(value, state.pm_ini, state.pm_fim);
            state.consumo_g = calculos.consumo;
            state.ca_esperado = calculos.caEsperado;
          }}
          precision="2"
          label="% Biomassa (28%C - 100%)"
        />
        <InputNumero
          disabled
          value={state.consumo_g}
          onChange={(value) => (state.consumo_g = value)}
          precision="3"
          label="Consumo (g)"
        />
        <InputNumero
          disabled
          value={state.ca_esperado}
          onChange={(value) => (state.ca_esperado = value)}
          precision="2"
          label="CA Esperado"
        />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: 10 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">Qtde Ração/dia (kg)</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'end' }}>
                  <Typography variant="subtitle1">* Ex. 1.000 animais</Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                    {Formatter.formatNumber(3, evaluate(`(${state.consumo_g} * (${state.sobrevivencia} / 100))`))}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: 10 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">Qtde Ração/trato (kg)</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'end' }}>
                  <Typography variant="subtitle1">* Ex. 1.000 animais</Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                    {Formatter.formatNumber(
                      3,
                      evaluate(`(${state.consumo_g} * (${state.sobrevivencia} / 100)) / ${state.num_tratos}`) || 0,
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao onClick={enviar} variant="contained" cor="#00C853">
            Salvar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(FormDia);
