import React, { useState, useRef, useEffect } from 'react';
import { NavBox, Flex } from './styles';
import { IconButton, MenuItem, Popper, Paper, Grow, ClickAwayListener, MenuList } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import useStore from '../../services/hooks/useStore';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import GroupIcon from '@material-ui/icons/Group';
import LockIcon from '@material-ui/icons/Lock';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Select from 'react-select';
import { api } from '../../services/utils';

const NavBar: React.FC = () => {
  const [perfilDialog, setPerfilDialog] = useState(false);
  const [color, setColor] = useState({
    value: '',
    label: '',
    color: '',
  });
  const store = useStore();
  const anchorRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    async function SearchPropriedade() {
      const id = localStorage.getItem('propriedade');
      const token = localStorage.getItem('device_token');
      const request = await api.get('/propriedades/' + id + '/', {
        headers: {
          Authorization: token as any,
        },
      });

      setColor({
        value: request.data!.id,
        label: request.data!.nome,
        color: request.data!.cor,
      });
    }
    SearchPropriedade();
  }, []);

  const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      width: '200px',
      backgroundColor: color.color !== '' ? color.color : '#fff',
    }),
    option: (styles: any, { data, isDisabled, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: data.color,

        color: isDisabled ? '#ccc' : 'white',

        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled ? isSelected : undefined,
        },
      };
    },
    input: (styles: any) => ({ ...styles, ...dot() }),
    placeholder: (styles: any) => ({ ...styles, ...dot('#ccc') }),
    singleValue: (styles: any) => ({ ...styles, color: color.color !== '' ? '#fff' : '#000' }),
  };

  function openDrawer(): void {
    store.toggleDrawer();
  }
  function handleCloseAway(): void {
    setPerfilDialog(false);
  }
  function handleClose(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
    const value = event.currentTarget.id;
    if (value === 'perfil') {
      history.push('/perfil');
    } else if (value === 'senha') {
      store.toggleModalAlterarSenha();
    } else if (value === 'usuarios') {
      history.push('/usuarios');
    } else if (value === 'minha-propriedade') {
      history.push('/minha-propriedade');
    }
    setPerfilDialog(false);
  }
  function sair(): void {
    localStorage.removeItem('device_token');
    localStorage.removeItem('device_id');
    localStorage.removeItem('propriedade');
    window.location.href = '/';
  }
  const handleChange = async (event: any) => {
    if (store.usuario.propriedades.filter((propriedade: any) => propriedade.id === parseInt(event.value))[0].ativo) {
      await store.setPropriedade(event.value);
      localStorage.setItem('propriedade', event.value);
      setColor(event);
    } else {
      store.notificar('Essa propriedade não está ativa. Entre em contato conosco!');
    }
  };
  return (
    <NavBox>
      <Flex>
        <IconButton color="inherit" onClick={openDrawer} edge="start">
          <MenuIcon />
        </IconButton>
        <Link to="/home">
          <img src="/images/aquabit_com_camarao.png" alt="Logo Aquabit" />
        </Link>
      </Flex>
      <h1>{store.titulo}</h1>
      <Flex>
        {store.usuario && (
          <Select
            value={color}
            classNamePrefix="navSel"
            placeholder="Propriedade"
            onChange={(event) => handleChange(event)}
            options={store.usuario.propriedades.map((propriedade: any) => {
              return {
                value: propriedade.id,
                label: propriedade.nome,
                color: propriedade.cor,
              };
            })}
            styles={colourStyles}
          />
        )}
        <IconButton color="inherit" edge="start" disabled>
          <AddCircleIcon />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={() =>
            window.open(
              'https://api.whatsapp.com/send?phone=5511950765158&text=Olá%20Gostaria%20de%20suporte%20para%20o%20Aquabit%20Web.',
              '_blank',
            )
          }
          edge="start"
        >
          <WhatsAppIcon />
        </IconButton>
        <IconButton ref={anchorRef} onClick={(): void => setPerfilDialog(!perfilDialog)} color="inherit" edge="start">
          <AccountCircleIcon fontSize="large" />
        </IconButton>
        <Popper
          className="user-menu"
          open={perfilDialog}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'center bottom' }}>
              <Paper>
                <ClickAwayListener onClickAway={handleCloseAway}>
                  <MenuList autoFocusItem={perfilDialog} id="menu-list-grow">
                    <MenuItem id="perfil" onClick={handleClose}>
                      <AccountCircleIcon />
                      Meu Perfil
                    </MenuItem>
                    <MenuItem id="minha-propriedade" onClick={handleClose}>
                    <img src="/images/propriedade.svg" alt="Logo propriedade" />
                      Minha Propriedade
                    </MenuItem>

                    <MenuItem id="senha" onClick={handleClose}>
                      <LockIcon />
                      Trocar Senha
                    </MenuItem>

                    <MenuItem id="usuarios" onClick={handleClose}>
                      <GroupIcon />
                      Usuários
                    </MenuItem>

                    <MenuItem onClick={sair}>
                      <ExitToAppIcon />
                      Sair
                    </MenuItem>
                    <MenuItem style={{ justifyContent: 'center', fontSize: 12, color: '#7b7b7b' }}>
                      Versão {process.env.REACT_APP_VERSION}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Flex>
    </NavBox>
  );
};

export default observer(NavBar);
