import { observable, computed } from 'mobx';
import { getThingsV2 } from '../utils';

type listaSemanaTanque = {
  lote_tanque_id: number;
  num_tratos: number;
  perc_biomassa: number;
  peso_medio: number;
  qtd_racao_dia: number;
  qtd_racao_trato: number;
  racao_id: number;
  racao_nome: string;
  semana_codigo: string;
  semana_id: number;
  semana_pm_fim: number;
  semana_pm_ini: number;
  tanque_id: number;
  tanque_nome: string;
  lancado: Boolean;
};

class ListaSemanaTanque {
  @observable arr: listaSemanaTanque[] | null = null;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(params?: object): Promise<void> {
    this.arr = await getThingsV2<listaSemanaTanque>('/arracoamento/tanques-semana/', params);
  }

  async getEverything(params?: object): Promise<listaSemanaTanque[]> {
    return await getThingsV2<listaSemanaTanque>('/arracoamento/tanques-semana/', params);
  }

  get(id: number): listaSemanaTanque {
    return this.arr!.filter((item) => item.semana_id === id)[0];
  }

  getByTanque(tanqueID: number): listaSemanaTanque[] {
    return this.arr!.filter((item) => item.tanque_id === tanqueID);
  }

  getByLote(loteID: number): listaSemanaTanque[] {
    return this.arr!.filter((item) => item.lote_tanque_id === loteID);
  }

  getByLoteTanque(loteID: number, tanqueID: number): listaSemanaTanque {
    return this.arr!.filter((item) => item.lote_tanque_id === loteID && item.tanque_id === tanqueID)[0];
  }

  // filter(callback: (item: listaSemanaTanque, index: number, array: listaSemanaTanque[]) => void): listaSemanaTanque[] {
  //   return this.arr!.filter(callback);
  // }

  map(callback: (item: listaSemanaTanque, index: number, array: listaSemanaTanque[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default ListaSemanaTanque;
