/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { Body } from '../../../components/Body';
import Header from '../../../components/Header';
import AddIcon from '@material-ui/icons/Add';
import { Flex } from '../../../components/NavBar/styles';
import AddBtn from '../../../components/Botoes/AddBtn';
import Filtro from '../../../components/Filtro';
import useStore from '../../../services/hooks/useStore';
import MaterialTable, { Column } from 'material-table';
import { config } from '../../../components/Tabela';
import { api } from '../../../services/utils';
import Display from '../../../components/Tabela/Display';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { useLocalStore, observer } from 'mobx-react-lite';
import { returnTanque } from '../../../services/utils/propriedade';
import ActionButton from '../../../components/Tabela/ActionButton';
import useModal from '../../../services/hooks/useModal';
import Select from '../../../components/Select';
import Confirmar from '../../../components/Modais/Confirmar';
import FormMortalidade from './FormMortalidade';
import { useHistory } from 'react-router-dom';
import syncModel from '../../../services/models/Sync';
import Formatter from '../../../services/Formatter';
import { permissoes } from '../../../services/utils/permissoes';

type State = PageState & {
  selectedMortalidade: number;
  filtroMorte: SelectRow | null;
  filtroRapidoCausa: SelectRow | null;
  sync: boolean;
};
const Mortalidade: React.FC = () => {
  const colors = ['#049CE7', '#FFE600', '#FE196B', '#465A65'];

  const store = useStore();
  const history = useHistory();
  const state = useLocalStore(
    (): State => ({
      pageSize: 0,
      setPageSize(value: number) {
        this.pageSize = value + 1;
      },
      selectedMortalidade: 0,
      filtroMorte: null,
      filtroRapidoCausa: null,
      sync: false,
    }),
  );
  const [mortalidadesPesquisadas, setMortalidadesPesquisadas] = useState<Mortalidade[] | undefined>(undefined);
  const modalCadastro = useModal();
  const modalExclusao = useModal();
  const garficoBarra = useLocalStore(() => ({
    series: [
      {
        name: 'Mortes',
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'bar',
        id: 'mortalidade',
        events: {},
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          formatter: (val: string) => {
            return Formatter.formatNumber(0, val);
          },
        },
      },
    },
  }));
  store.propriedade && store.validarPermissao(permissoes.PRODUCAO_MORTALIDADE_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.PRODUCAO_MORTALIDADE_ESCRITA);

  const ExcluirMortalidade: React.FC = () => {
    const mortalidade = store.mortalidades.get(state.selectedMortalidade);
    const loteTanque = store.loteTanque.getByUUID(mortalidade.lote_tanque);

    return (
      <div style={{ width: '100%' }}>
        <b>Lote: </b> {mortalidade && loteTanque?.lote.codigo}
        <br />
        <b>Tanque: </b> {mortalidade && loteTanque && returnTanque(loteTanque.tanque)}
        <br />
        <b>Data: </b> {mortalidade && Formatter.getData(mortalidade.data)}
        <br />
        <b>Causa: </b>
        {mortalidade && mortalidade.tipo_morte.nome}
        <br />
        <b>Quantidade: </b>
        {mortalidade && Formatter.formatNumber(0, mortalidade.quantidade)}
      </div>
    );
  };

  async function sync(): Promise<void> {
    await syncModel.mortalidades();
    state.sync = true;
  }

  async function filter(): Promise<void> {
    // Checagem de filtros avançados
    if (state.filtroMorte) {
      store.toggleLoader();
      const params = {
        propriedade: store.propriedade?.id,
        tipo_morte: state.filtroMorte.value,
        page_size: 300,
      };
      const mortalidades = await store.mortalidades.getEverything(params);
      setMortalidadesPesquisadas(mortalidades);
      store.toggleLoader();

      // Limpar filtroRápido
      state.filtroRapidoCausa = null;
    } else {
      sync();
    }
  }
  function limparFiltro(): void {
    state.filtroMorte = null;
    state.filtroRapidoCausa = null;
    setMortalidadesPesquisadas(undefined);
  }

  // Configurações da tabela
  const columns: Column<object>[] = [
    {
      field: 'lote',
      title: 'Lote',
      ...(true && ({ width: 160 } as object)),
    },
    {
      field: 'acoes',
      render: (rowData: any) => (
        <ActionButton
          editar={
            temPermissaoEscrita
              ? () => {
                  state.selectedMortalidade = rowData.id;
                  modalCadastro.open();
                }
              : undefined
          }
          excluir={
            temPermissaoEscrita
              ? () => {
                  state.selectedMortalidade = rowData.id;
                  modalExclusao.open();
                }
              : undefined
          }
        />
      ),
      ...(true && ({ width: 25 } as object)),
    },
    {
      field: 'tanque',
      title: 'Tanque',
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'data',
      title: 'Data',
      type: 'date',
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'quantidade',
      type: 'numeric',
      title: 'Quantidade',
      render(rowData: any) {
        return Formatter.formatNumber(0, rowData.quantidade);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'especie',
      title: 'Espécie',
      ...(true && ({ width: 120 } as object)),
    },
    {
      field: 'causa',
      title: 'Causa',
      ...(true && ({ width: 240 } as object)),
    },
  ];
  const rows = (mortalidades: Mortalidade[]): object[] => {
    if (mortalidades.length) {
      if (mortalidades.length >= 50) {
        state.setPageSize(50);
      } else {
        state.setPageSize(mortalidades.length);
      }
    } else {
      state.setPageSize(0);
    }

    return mortalidades.map((mortalidade) => {
      const loteTanque = store.loteTanque.getByUUID(mortalidade.lote_tanque);
      const data = new Date(
        mortalidade.data.substr(3, 2) + '/' + mortalidade.data.substr(0, 2) + '/' + mortalidade.data.substr(6, 4),
      );

      return {
        id: mortalidade.id,
        lote: loteTanque?.lote.codigo,
        tanque: loteTanque ? returnTanque(loteTanque.tanque) : '',
        data,
        quantidade: mortalidade.quantidade,
        especie: loteTanque?.lote.especie_explorada.nome,
        causa: mortalidade.tipo_morte.nome,
      };
    });
  };

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Mortalidades'), []);

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  useEffect(() => {
    if (state.sync) {
      const data: { x: string; y: number }[] = [];

      store.tiposDeMorte.arr!.map((tipo) => {
        let mortes = 0;

        store.mortalidades.getByTipo(tipo.id).map((mortalidade) => {
          return (mortes += mortalidade.quantidade);
        });

        if (mortes) return data.push({ x: tipo.nome, y: mortes });
        else return null;
      });

      ApexCharts.exec('mortalidade', 'updateSeries', [
        {
          data,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [state.sync, store.mortalidades.arr]);

  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn
            disabled={!temPermissaoEscrita}
            onClick={() => {
              state.selectedMortalidade = 0;
              modalCadastro.open();
            }}
            text="ADICIONAR"
            Icon={AddIcon}
          />
          <div style={{ width: 180, marginRight: 10 }}>
            <Select
              value={state.filtroRapidoCausa}
              placeholder="Causa da morte"
              onChange={(e: any) => {
                if (!state.filtroMorte) {
                  state.filtroRapidoCausa = e;
                  const mortalidades = store.mortalidades.getByTipo(parseInt(e.value));
                  setMortalidadesPesquisadas(mortalidades);
                }
              }}
              options={store.tiposDeMorte.getSelectRows()}
            />
          </div>
        </Flex>
        <Flex>
          <Filtro sync={filter} clear={limparFiltro}>
            <div style={{ width: '90%' }}>
              <Select
                value={state.filtroMorte}
                placeholder="Causa da morte"
                onChange={(e: any) => (state.filtroMorte = e)}
                options={store.tiposDeMorte.getSelectRows()}
              />
            </div>
          </Filtro>
        </Flex>
      </Header>
      <Body gridColumns="1fr 500px">
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          {store.mortalidades.arr && rows && (
            <MaterialTable
              columns={columns}
              data={rows(mortalidadesPesquisadas || store.mortalidades.arr!)}
              options={{ ...config.options, pageSize: state.pageSize }}
              localization={config.localization}
              style={config.style}
            />
          )}
        </div>
        <div>
          <Display style={{ marginBottom: '10px', height: 400 }} overflowY={false} title="Mortalidade por causa">
            {store.mortalidades.arr && (
              <ReactApexChart options={garficoBarra.options as any} series={garficoBarra.series} type="bar" />
            )}
          </Display>
        </div>
      </Body>
      <Confirmar
        title="Tem certeza que deseja excluir?"
        content={<ExcluirMortalidade />}
        open={modalExclusao.state}
        handleClose={() => modalExclusao.close()}
        onConfirm={async () => {
          modalExclusao.close();
          store.toggleLoader();
          await api
            .delete(`/mortalidade/${state.selectedMortalidade}/?propriedade=${store.propriedade!.id}`)
            .then(() => store.notificar('Mortalidade excluída com sucesso!'))
            .catch(() => store.notificar('Ocorreu um erro ao excluir a mortalidade!'));
          await sync();
          store.toggleLoader();
        }}
        onCancel={modalExclusao.close}
      />
      {modalCadastro.state && (
        <FormMortalidade
          open={modalCadastro.state}
          handleClose={modalCadastro.close}
          mortalidadeID={state.selectedMortalidade}
        />
      )}
    </>
  ) : null;
};

export default observer(Mortalidade);
