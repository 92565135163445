import { IconButton, Tooltip } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CachedIcon from '@material-ui/icons/Cached';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MaterialTable, { Column } from 'material-table';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Conteudo, NewBody } from '../../../components/Body';
import Botao from '../../../components/Botoes/Botao';
import { Flex } from '../../../components/NavBar/styles';
import { newConfigRacoes } from '../../../components/Tabela';
import GetAppIcon from '@material-ui/icons/GetApp';
import Formatter from '../../../services/Formatter';
import useStore from '../../../services/hooks/useStore';
import { apiMain, aqReports } from '../../../services/utils';
import useModal from '../../../services/hooks/useModal';
import SelecionarLote from './crescimento/SelecionarLote';
import SelecionarLoteTanques from './crescimento/SelecionarLoteTanques';
import { permissoes } from '../../../services/utils/permissoes';

const TotalRacoes: React.FC = () => {
  const store = useStore();

  const modalSelecionarLote = useModal();
  const modalTanques = useModal();

  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.PAINEL_ZOOTECNICO_LEITURA, history);

  const state = useLocalStore(() => ({
    lote: null as SelectRow | null,
    tanque: null as SelectRow | null,
    data_ini: null as MaterialUiPickersDate,
    data_fim: null as MaterialUiPickersDate,

    id: {
      lote: null as any,
      tanque: null as any,
    },

    codigo: '',

    lotes: [] as SelectRow[],
    tanques: [] as SelectRow[],

    rows: [] as object[],
    totais: [] as object[],
    sync: false,
  }));
  const columns: Column<object>[] = [
    {
      field: 'data',
      title: 'Data',
      type: 'date',
    },
    {
      field: 'lote',
      title: 'Lote',
    },
    {
      field: 'tanque',
      title: 'Tanque',
    },
    {
      field: 'racao',
      title: 'Ração',
    },
    {
      field: 'peso',
      title: 'Peso (kg)',
      type: 'numeric',
      render: (rowData: any) => {
        return Formatter.formatNumber(3, rowData.peso);
      },
    },
  ];

  const sync = useCallback(async () => {
    // store.toggleLoader();

    await store.lotes.populate();
    await store.loteTanque.populate();

    state.sync = true;
    // store.toggleLoader();
  }, [store.lotes, store.loteTanque]);

  async function getRelatorio(): Promise<void> {
    if ((state.data_ini && !state.data_fim) || (state.data_fim && !state.data_ini)) {
      store.notificar('Informe a data inicial e a final do período');
    } else {
      store.toggleLoader();

      if (state.lote || state.tanque) {
        const response = await aqReports.get(`/relatorio/${store.propriedade!.id}/arracoamento-tanque`, {
          params: {
            lote_id: state.lote ? Number(state.lote.value) : undefined,
            tanque_id: state.tanque ? Number(state.tanque.value) : undefined,
            data_ini: state.data_ini ? Formatter.dateToString(state.data_ini, '/') : undefined,
            data_fim: state.data_fim ? Formatter.dateToString(state.data_fim, '/') : undefined,
          },
        });

        state.rows = response.data.results;
        state.totais = response.data.results_peso;
      }

      store.toggleLoader();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getCSV() {
    try {
      const pdf = await apiMain.get(`/gerador/csv/relatorio/${store.propriedade?.id}/arracoamento-tanque/`, {
        params: {
          authori: store.token,
          data_ini: state.data_ini ? Formatter.dateToString(state.data_ini, '/') : '',
          data_fim: state.data_fim ? Formatter.dateToString(state.data_fim, '/') : '',
          lote_id: state.lote?.value,
          tanque_id: state.tanque?.value,
        },
      });
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getPDF() {
    try {
      const pdf = await apiMain.get(`/gerador/pdf/relatorio/${store.propriedade?.id}/arracoamento-tanque/`, {
        params: {
          authori: store.token,
          data_ini: state.data_ini ? Formatter.dateToString(state.data_ini, '/') : '',
          data_fim: state.data_fim ? Formatter.dateToString(state.data_fim, '/') : '',
          lote_id: state.lote?.value,
          tanque_id: state.tanque?.value,
        },
      });
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }

  function limparFiltros(): void {
    state.lote = null;
    state.tanque = null;
    state.data_ini = null;
    state.data_fim = null;
    state.rows = [];
    state.totais = [];
  }
  useEffect(() => store.setTitulo('Consumo de Ração'), []);
  // eslint-disable-next-line
  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  return state.sync ? (
    <NewBody>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Link
          to={'/relatorios/relatorios-zootecnicos'}
          style={{ color: '#049CE7', textDecoration: 'none', fontFamily: 'Roboto' }}
        >
          <Flex>
            <img src={'/images/returnArrow.svg'} />
            <div style={{ paddingLeft: '5px', fontFamily: 'Roboto', color: '#049CE7' }}>Lista de Relatórios</div>
          </Flex>
        </Link>

        <h2>
          Ração - {state.lote ? `Lote:  ${state.lote.label}  ${'-'}` : ''}{' '}
          {state.lote && state.tanque ? `Tanque:  ${state.tanque.label}` : ''}{' '}
          {state.lote === null && state.tanque ? `Lote: ${state.codigo} ${'-'} Tanque:  ${state.tanque.label}` : ''}
        </h2>

        <Flex>
          <Tooltip title="Exportar para CSV">
            <IconButton className="blue" onClick={getCSV}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Baixar PDF">
            <IconButton className="blue" onClick={getPDF}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sincronizar">
            <IconButton
              className="blue"
              onClick={() => {
                sync();
              }}
            >
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 300px', gap: 10 }}>
        <Conteudo style={{ padding: 0 }}>
          <MaterialTable
            columns={columns}
            data={state.rows}
            options={{
              ...newConfigRacoes.options,
              paging: false,
            }}
            localization={newConfigRacoes.localization}
            style={newConfigRacoes.style}
          />
        </Conteudo>

        <Conteudo>
          <div style={{ width: '100%', marginBottom: 10 }}>
            <Botao onClick={() => modalSelecionarLote.open()}>Selecionar Lote</Botao>
          </div>

          <div style={{ width: '100%', marginBottom: 10 }}>
            <Botao onClick={() => modalTanques.open()}>Selecionar Tanque</Botao>
          </div>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Inicial"
            value={state.data_ini}
            onChange={(date) => (state.data_ini = date!)}
          />

          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Final"
            value={state.data_fim}
            onChange={(date) => (state.data_fim = date!)}
          />

          <Botao disabled={!state.tanque && !state.lote} onClick={getRelatorio} cor="#00C853">
            Gerar Relatório
          </Botao>
          <Botao onClick={limparFiltros} cor="#FC7467">
            Limpar Filtros
          </Botao>
        </Conteudo>
      </div>
      <SelecionarLote
        state={state}
        open={modalSelecionarLote.state}
        handleClose={modalSelecionarLote.close}
        onItemClick={(lote) => {
          state.tanques = [];
          state.lote = { label: lote.codigo, value: lote.id };
          state.id = {
            lote: lote.id,
            tanque: '',
          };
        }}
        loteID={state.lote ? parseInt(state.lote.value) : null}
      />

      <SelecionarLoteTanques
        open={modalTanques.state}
        handleClose={modalTanques.close}
        setTanque={(tanque) => {
          state.tanque = { label: tanque.label, value: tanque.tanqueId2 };
          state.id = {
            lote: '',
            tanque: tanque.loteTanque,
          };
          state.codigo = tanque.tanque;
        }}
        setLote={(lote) => console.log(lote)}
        state={state}
        exclude={[]}
      />
    </NewBody>
  ) : null;
};

export default observer(TotalRacoes);
