import React from 'react';
import { PDFContainer } from './styles';
import { Flex } from '../../../components/NavBar/styles';
import MaterialTable, { Column } from 'material-table';
import { newConfig } from '../../../components/Tabela';
import Formatter from '../../../services/Formatter';

type Props = {
  propriedade: string;
  rows: object[][];
  tanque: string;
  lote: string;
  especie: string;
  estoque: number;
};

const RelatorioResumoVendasDetalhado: React.FC<Props> = ({ propriedade, rows, tanque, lote, especie, estoque }) => {
  const columns: Column<object>[] = [
    {
      field: 'cliente',
      title: 'Cliente',
      type: 'string',
      width: 150,
    },
    {
      field: 'data',
      title: 'Data',
      type: 'date',
      width: 120,
    },
    {
      field: 'quantidade',
      title: 'Quantidade',
      type: 'numeric',
      width: 150,
      render: ({ quantidade }: any) => {
        return Formatter.formatNumber(0, quantidade);
      },
    },
    {
      field: 'biomassa',
      title: 'Biomassa (kg)',
      width: 150,
      render: ({ biomassa }: any) => {
        return Formatter.formatNumber(2, biomassa);
      },
      type: 'numeric',
    },
    {
      field: 'valor_unit',
      title: 'Valor Unit. (R$)',
      type: 'numeric',
      width: 150,
      render: ({ valor_unit }: any) => {
        return Formatter.formatNumber(2, valor_unit);
      },
    },
    {
      field: 'valor',
      title: 'Valor Total (R$)',
      type: 'numeric',
      width: 150,
      render: ({ valor }: any) => {
        return Formatter.formatNumber(2, valor);
      },
    },
  ];

  const Tables: React.FC = () => {
    return (
      <>
        {rows.map((tableRows, index) => {
          return (
            <div key={'table-' + index}>
              <MaterialTable
                columns={columns}
                data={tableRows}
                options={{
                  ...newConfig.options,
                  paging: false,
                  maxBodyHeight: 'none',
                  minBodyHeight: 'none',
                  headerStyle: {
                    color: '#000000',
                    fontSize: 12,
                  },
                }}
                localization={newConfig.localization}
                style={{ ...newConfig.style, fontSize: 12 }}
              />
              <br />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <PDFContainer>
      <Flex>
        <img src="/images/aqrelatorios.png" alt="Aquabit Relatórios" />
        <h2>Aquabit - Relatório de Vendas por Tanque - Detalhado</h2>
      </Flex>

      <br />

      <p
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          fontSize: 12,
        }}
      >
        <b>Data da impressão:</b> {Formatter.dateToString(new Date(), '/')}
      </p>

      <div
        style={{
          width: '100%',
          display: 'grid',
          justifyContent: 'space-between',
          gridTemplateColumns: '1fr 1fr',
          gap: 10,
        }}
      >
        <p>
          <b>Propriedade:</b> {propriedade}
        </p>
        <p>
          <b>Tanque:</b> {tanque}
        </p>
        <p>
          <b>Lote:</b> {lote}
        </p>
        <p>
          <b>Espécie:</b> {especie}
        </p>
        <p>
          <b>Estoque Atual:</b> {Formatter.formatNumber(0, estoque)}
        </p>
      </div>

      <br />

      <Tables />
    </PDFContainer>
  );
};

export default RelatorioResumoVendasDetalhado;
