import React, { useState } from 'react';
import { IconButton, TextField, Tooltip, Typography, Button as ButtonMUI, Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import useStore from '../../../services/hooks/useStore';
import { Fornecedor } from '../Biometrias/styles';
import Botao from '../../../components/Botoes/Botao';
import useModal from '../../../services/hooks/useModal';
import FormFornecedorPovoamento from '../../Financeiro/Fornecedor/FormFornecedorPovoamento';

type Props = ModalProps & {
  onConfirm(fornecedor: SelectRow): void;
};
const SelecionarFornecedor: React.FC<Props> = ({ open, handleClose, onConfirm }) => {
  const store = useStore();
  const modalFornecedor = useModal();

  const [pesquisa, setPesquisa] = useState('');
  const [mostrarApenasFornecedoresInsumo, setMostrarApenasFornecedoresInsumo] = useState(true);

  function handleToggleShowMore() {
    setMostrarApenasFornecedoresInsumo((prev) => !prev);
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>
        <Box 
          display="flex"
          justifyContent="space-between"
          width="100%"
        >
          <span>Selecionar fornecedor</span>

          <Tooltip title="Adicionar fornecedor">
            <IconButton style={{ background: 'rgba(0,0,0,0.2)' }} onClick={modalFornecedor.open}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </ModalHeader>
      <ModalBody>
        <TextField
          label="Pesquisa Pelo Nome"
          value={pesquisa}
          onChange={(e) => {
            setPesquisa(e.target.value);
          }}
        />

        {store.fornecedores
          .arr!.sort((a, b) => {
            const textA = a.nome;
            const textB = b.nome;
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .filter((fornecedor) => {
            if (mostrarApenasFornecedoresInsumo) return fornecedor.fornecedor_tipo.slug === 'insumos';
            else return fornecedor;
          })
          .filter((fornecedor) => {
            if (pesquisa) return fornecedor.nome.toLocaleLowerCase().includes(pesquisa.toLocaleLowerCase());
            else return fornecedor;
          })
          .map((fornecedor) => {
            return (
              <Fornecedor
                onClick={() => onConfirm({ label: fornecedor.nome, value: fornecedor.id.toString() })}
                key={fornecedor.id}
              >
                <div>
                  <b className="left">{fornecedor.nome}</b>
                </div>
              </Fornecedor>
            );
          })}
      </ModalBody>
      <ButtonMUI onClick={handleToggleShowMore}>
        <Typography variant="subtitle2" style={{ color: '#42a5f4' }}>
          {mostrarApenasFornecedoresInsumo ? '+ Mostrar todos os fornecedores' : '— Somente fornecedores de insumos'}
        </Typography>
      </ButtonMUI>
      <div style={{ width: '80%', margin: '10px' }}>
        <Botao onClick={handleClose} cor="gray">
          Voltar
        </Botao>
      </div>

      {modalFornecedor.state ? (
        <FormFornecedorPovoamento open={modalFornecedor.state} handleClose={modalFornecedor.close} fornecedorID={0} />
      ) : null}
    </Modal>
  );
};

export default SelecionarFornecedor;
