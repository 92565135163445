import React, { useState, useEffect } from 'react';
import { Flex } from '../../../components/NavBar/styles';
import { IconButton } from '@material-ui/core';
import Display from '../../../components/Tabela/Display';
import Header from '../../../components/Header';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router';
import { api, getThings } from '../../../services/utils';
import useStore from '../../../services/hooks/useStore';
import useModal from '../../../services/hooks/useModal';
import { useLocalStore, observer } from 'mobx-react-lite';
import { Body } from '../../../components/Body';
import DataTable from 'react-data-table-component';
import { ParametroInfo } from './styles';
import FormTanques from './FormTanques';
import FormTiposRacao from './FormTiposRacao';
// import * as XLSX from 'xlsx';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { permissoes } from '../../../services/utils/permissoes';

const ImportacaoCSV: React.FC = () => {
  const [columns, setColumns] = useState([{}]);
  const [data, setData] = useState([{}]);
  const [listaTratada, setlistaTratada] = useState<any>('');
  const [tanquesLoad, setTanquesLoad] = useState(true);
  const [racoesLoad, setRacoesLoad] = useState(true);
  const [tanqueData, setTanqueData] = useState<any>(null);
  const [racoesData, setRacoesData] = useState<any>(null);
  const [hidden, sethidden] = useState(false);
  const history = useHistory();
  const store = useStore();
  store.propriedade && store.validarPermissao(permissoes.PAINEL_ZOOTECNICO_LEITURA, history);
  const modalTanques = useModal();
  const modalRacao = useModal();

  const customStyles = {
    headCells: {
      style: {
        color: 'white',
        backgroundColor: '#049CE7',
      },
    },
  };

  const state = useLocalStore(() => ({
    async sync() {
      store.toggleLoader();

      try {
        await getThings('/importe/arracoamento/tanque-cadastrado/').then((response) => setTanqueData(response));
        setTanquesLoad(false);

        await getThings('/importe/arracoamento/racao-cadastrada/').then((response) => setRacoesData(response));

        setTanquesLoad(false);
        setRacoesLoad(false);
      } catch (err) {
        store.notificar('Ocorreu um erro ao pegar as informações necessárias!');
      }
      store.toggleLoader();
    },
  }));

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function tratarDados(data: { lista_csv: any[] }) {
    await api.post(`/importe/arracoamento/csv/?propriedade=${store.propriedade!.id}`, data).then((response) => {
      setlistaTratada(response.data);
    });
  }

  // process CSV data
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const processData = (dataString: any) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length === headers.length) {
        const obj: any = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // Coloca a lista dentro de um objeto com parâmetro específico que é enviado ao back
    const list_obj = {
      lista_csv: list,
    };

    // Envia esse objeto ao back por meio de um POST que retorna a lista tratada e coloca dentro de um state
    tratarDados(list_obj);
  };

  // handle file upload
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleFileUpload = (e: any) => {
    store.toggleLoader();
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const data = evt.target!.result;
      processData(data);
    };
    reader.readAsBinaryString(file);
  };

  function handleBack(): void {
    history.replace('/producao/arracoamento');
  }

  useEffect(() => {
    if (store.propriedade) {
      state.sync();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  useEffect(() => {
    if (listaTratada) {
      const dataTratado = listaTratada.lista_csv.map((currentValue: any) => ({
        Data: currentValue.Date,
        Hora: currentValue.Time,
        Acude: currentValue.Tanque.Acude_csv,
        Tanques: currentValue.Tanque.Tanque_nome === null ? 'Não tratado' : currentValue.Tanque.Tanque_nome,
        Racao_csv: currentValue.Racao.Numero_Racao_csv,
        Racao: currentValue.Racao.Racao_nome === null ? 'Não tratado' : currentValue.Racao.Racao_nome,
        Peso: currentValue.Racao_decarregad.toFixed(3).toString(),
      }));

      const columnsKeys = Object.keys(dataTratado[0]);

      const columnsTratados = columnsKeys.map((currentValue) => {
        if (currentValue === 'Racao') {
          return { name: 'Tipo Ração', selector: currentValue };
        } else if (currentValue === 'Racao_csv') {
          return { name: 'Ração (CSV)', selector: currentValue };
        } else if (currentValue === 'Peso') {
          return { name: 'Peso (Kg)', selector: currentValue };
        } else if (currentValue === 'Tanques') {
          return { name: 'Viveiros/Tanques', selector: currentValue };
        } else if (currentValue === 'Acude') {
          return { name: 'Açude (CSV)', selector: currentValue };
        } else {
          return { name: currentValue, selector: currentValue };
        }
      });

      setData(dataTratado);
      setColumns(columnsTratados);
      store.toggleLoader();
      sethidden(true);
    }
    // eslint-disable-next-line
  }, [listaTratada]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function salvarTabela(data: { lista_csv: any[] }) {
    await api.post(`/importe/arracoamento/csv-importa/?propriedade=${store.propriedade!.id}`, data);
    history.replace('/producao/arracoamento');
  }

  return (
    <>
      <Header>
        <Flex>
          <IconButton onClick={handleBack}>
            <ChevronLeftIcon />
          </IconButton>
          <h1 style={{ color: '#383838' }}>Importar CSV</h1>
        </Flex>

        <Flex>
          <input
            accept=".csv"
            style={{ display: 'none' }}
            id="raised-button-file"
            onChange={handleFileUpload}
            type="file"
          />
          <label htmlFor="raised-button-file" style={{ alignSelf: hidden ? '' : 'center' }}>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              component="span"
              style={{ backgroundColor: '#0098E2', color: 'white', borderColor: '#0098E2' }}
            >
              <SearchIcon fontSize="small" />
              Buscar Arquivo CSV
            </Button>
          </label>

          {hidden ? (
            <>
              <label htmlFor="salvar-button" style={{ alignSelf: hidden ? '' : 'center' }}>
                <Button
                  id="salvar-button"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={() => salvarTabela(listaTratada)}
                  style={{ backgroundColor: '#00C853', marginLeft: '3px', borderColor: '#00C853', color: 'white' }}
                >
                  <KeyboardArrowDownIcon fontSize="small" />
                  Importar CSV
                </Button>
              </label>
            </>
          ) : null}
        </Flex>
      </Header>

      <Body gridColumns="1fr 500px">
        <div style={{ backgroundColor: 'white' }}>
          {hidden ? (
            <>
              <DataTable
                pagination
                highlightOnHover
                responsive
                paginationPerPage={15}
                columns={columns}
                data={data}
                customStyles={customStyles}
                style={{ boxShadow: '0 0 13px 0 #00000036', borderRadius: '10px', width: '100%', fontSize: 14 }}
                paginationComponentOptions={{
                  rangeSeparatorText: 'de',
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: 'All',
                }}
              />
            </>
          ) : null}
        </div>

        <div>
          <Display style={{ marginBottom: '10px', height: '40vh' }} overflowY={false} title="Parâmetros Configurados">
            <ParametroInfo
              onClick={() => {
                modalTanques.open();
              }}
            >
              <b>Tanques/Viveiros</b>
              <i>
                {tanquesLoad
                  ? '0 Tanque(s) Configurado(s)'
                  : `${tanqueData.lista_lote_tanque.length} Tanque(s) Configurado(s)`}
              </i>
            </ParametroInfo>

            <ParametroInfo
              onClick={() => {
                modalRacao.open();
              }}
            >
              <b>Tipos de Ração</b>
              <i>
                {racoesLoad
                  ? '0 Ração(es) Configurada(s)'
                  : `${racoesData.lista_racao.length} Ração(es) Configurada(s)`}
              </i>
            </ParametroInfo>
          </Display>

          <Display
            style={{ height: '40vh', overflow: 'hidden', visibility: 'hidden' }}
            overflowY={false}
            title="Histórico de Importações"
          ></Display>
        </div>
      </Body>
      {modalTanques.state ? (
        <FormTanques open={modalTanques.state} handleClose={modalTanques.close} tanquesCadastrados={tanqueData} />
      ) : null}
      {modalRacao.state ? (
        <FormTiposRacao open={modalRacao.state} handleClose={modalRacao.close} racoesCadastradas={racoesData} />
      ) : null}
    </>
  );
};

export default observer(ImportacaoCSV);
