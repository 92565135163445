/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable, { Column } from 'material-table';
import { useLocalStore, observer } from 'mobx-react-lite';

import { Flex } from '../../components/NavBar/styles';
import { Body } from '../../components/Body';
import { config } from '../../components/Tabela';
import Header from '../../components/Header';
import AddBtn from '../../components/Botoes/AddBtn';
import ActionButton from '../../components/Tabela/ActionButton';
import useStore from '../../services/hooks/useStore';
import useModal from '../../services/hooks/useModal';
import syncModel from '../../services/models/Sync';
import Formatter from '../../services/Formatter';
import FormUsuario from './FormUsuario';
import ModalAlterarPermissoes from './AlterarPermissoes';
import { permissoes } from '../../services/utils/permissoes';

const Usuarios: React.FC = () => {
  const store = useStore();
  const history = useHistory();

  store.propriedade && store.validarPermissao(permissoes.PROPRIEDADE_LEITURA, history);

  const modalCadastro = useModal();
  const modalPermissoes = useModal();

  const state = useLocalStore(() => ({
    pageSize: 0,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
    rowData: 0,
    filtroRapidoNome: '',
    userSelected: 0,
    sync: false,
  }));
  const temPermissaoEscrita = store.checkPermissions(permissoes.PROPRIEDADE_ESCRITA);

  const columns: Column<object>[] = [
    {
      field: 'cpf_cnpj',
      title: 'CPF/CNPJ',
      render: (rowData: any) => {
        return rowData.cpf_cnpj ? Formatter.formatCPFCNPJ(rowData.cpf_cnpj) : null;
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'acoes',
      render: (rowData: any) => (
        <ActionButton
          editar={
            temPermissaoEscrita && store.usuario.responsavel
              ? () => {
                  state.userSelected = rowData.id;
                  modalCadastro.open();
                }
              : () => store.notificar('Você não tem permissão para realizar esta ação')
          }
          permissoes={
            temPermissaoEscrita && store.usuario.responsavel
              ? () => {
                  state.userSelected = rowData.id;
                  modalPermissoes.open();
                }
              : () => store.notificar('Você não tem permissão para realizar esta ação')
          }
        />
      ),
      ...(true && ({ width: 25 } as object)),
    },
    {
      field: 'nome',
      title: 'Nome',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'email',
      title: 'E-mail',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'telefone',
      title: 'Telefone/Whatsapp',
      ...(true && ({ width: 150 } as object)),
    },

    {
      field: 'perfil',
      title: 'Perfil',
      render: (rowData: any) => {
        if (store.tiposDeUsuario.arr && store.tiposDeUsuario.arr.length > 0) {
          return store.tiposDeUsuario.arr.find((tipoUsuario) => tipoUsuario.id === rowData.perfil)?.nome;
        } else {
          return rowData.perfil;
        }
      },
      ...(true && ({ width: 100 } as object)),
    },

    {
      field: 'ativo',
      title: 'Ativo',
      render: (rowData: any) => (rowData.ativo ? 'Sim' : 'Não'),
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'created_at',
      title: 'Data Cadastro',
      render: (rowData: any) =>
        rowData.created_at
          ? new Date(rowData.created_at).toLocaleDateString('pt-BR', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
          : null,
      ...(true && ({ width: 100 } as object)),
    },
  ];

  const rows = (usuarios: RawUser[]): object[] => {
    if (usuarios.length) {
      if (usuarios.length >= 50) {
        state.setPageSize(50);
      } else {
        state.setPageSize(usuarios.length);
      }
    } else {
      state.setPageSize(0);
    }

    return usuarios
      .sort((a, b) => a.nome.localeCompare(b.nome))
      .map((usuario) => {
        return {
          id: usuario.id,
          cpf_cnpj: usuario.inscricao_federal,
          nome: usuario.nome,
          email: usuario.email,
          telefone: usuario.telefone,
          perfil: usuario.tipo_de_usuario,
          ativo: usuario.ativo,
          created_at: usuario.created_at,
        };
      });
  };

  async function sync(): Promise<void> {
    await syncModel.usuarios();
    state.sync = true;
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Usuários'), []);

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn
            disabled={!temPermissaoEscrita}
            onClick={() => {
              state.userSelected = 0;
              modalCadastro.open();
            }}
            text="ADICIONAR"
            Icon={AddIcon}
          />
        </Flex>
      </Header>

      <Body grid={true}>
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          {rows && (
            <MaterialTable
              columns={columns}
              data={rows(store.usuarios.arr!)}
              options={{ ...config.options, pageSize: state.pageSize }}
              localization={config.localization}
              style={config.style}
            />
          )}
        </div>
      </Body>

      {modalCadastro.state ? (
        <FormUsuario open={modalCadastro.state} handleClose={modalCadastro.close} usuarioID={state.userSelected} />
      ) : null}
      {modalPermissoes.state ? (
        <ModalAlterarPermissoes
          open={modalPermissoes.state}
          handleClose={modalPermissoes.close}
          usuarioID={state.userSelected}
        />
      ) : null}
    </>
  ) : null;
};

export default observer(Usuarios);
