import { AxiosInstance } from 'axios';
import { ModalHook } from '../../../../services/hooks/useModal';

async function deletarInsumo(
  store: any,
  state: any,
  modalExclusao: ModalHook,
  sync: Function,
  api: AxiosInstance,
): Promise<void> {
  const compra = store.insumos!.get(state.selectedInsumo);
  modalExclusao.close();
  store.toggleLoader();

  if (compra.editavel ? false : true) {
    store.notificar('Esse insumo já possui movimentações!');
  } else {
    await api
      .delete('/produto/' + compra.id + '/?prop=' + store.propriedade.id)
      .then(() => {
        store.notificar('Insumo excluído com sucesso!');
      })
      .catch(() => store.notificar('Ocorreu um erro ao excluir o insumo!'));
  }
  await sync(state);
  store.toggleLoader();
}

export default deletarInsumo;
