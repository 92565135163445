import React, { useEffect, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Lote, Modal, ModalHeader, ModalBody } from './styles';
import { Flex } from '../NavBar/styles';
import useStore from '../../services/hooks/useStore';
import Formatter from '../../services/Formatter';
import { apiV2 } from '../../services/utils';

type Props = ModalProps & {
  onItemClick(lote: Lote): void;
  loteID: number | null;
};
const SelecionarLote: React.FC<Props> = ({ open, handleClose, onItemClick, loteID }) => {
  const state2 = useLocalStore(() => ({
    sync: false,
  }));

  const [lote, setLote] = useState([]);
  const store = useStore();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function searchLote() {
      await apiV2
        .get('/lote/modals/', {
          params: {
            prop: store.propriedade!.id,
          },
        })
        .then((res) => {
          setLote(res.data.results);
        });
      state2.sync = true;
    }

    searchLote();
  }, [state2, store.propriedade]);
  const LotesDisponiveis: React.FC = () => (
    <Flex style={{ flexDirection: 'column' }}>
      {lote?.map((lote: any) => {
        const selected = lote.id === loteID;

        if (lote.estoque && lote.fase_lote.nome !== 'FINALIZADO') {
          return (
            <Lote
              selected={selected || undefined}
              onClick={() => {
                if (!selected) onItemClick(lote);
                handleClose();
              }}
              key={lote.id}
            >
              <div>
                <p>{Formatter.dateToString(new Date(lote.data), '/')}</p>
                <p>Qtde Tanques: {Formatter.formatNumber(0, lote.quantidade_tanques)}</p>
                <p>Qtde Animais: {Formatter.formatNumber(0, lote.estoque)}</p>
              </div>
              <div style={{ textAlign: 'end' }}>
                <b>{lote.codigo}</b>
                <p style={{ color: 'rgb(0, 200, 83)' }}>
                  Peso Médio: {Formatter.formatNumber(3, lote.peso_medio_atual || lote.peso_medio)} g
                </p>
                <p>Biomassa: {Formatter.formatNumber(3, lote.biomassa)} kg</p>
              </div>
            </Lote>
          );
        } else {
          return null;
        }
      })}
    </Flex>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>Selecione um Lote</ModalHeader>
      <ModalBody>
        <LotesDisponiveis />
      </ModalBody>
    </Modal>
  );
};

export default observer(SelecionarLote);
