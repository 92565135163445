import React from 'react';

import useStore from '../../services/hooks/useStore';
import Botao from '../../components/Botoes/Botao';
import { Modal, ModalHeader, ModalBody, Divisor } from '../../components/Modais/styles';
import { useLocalStore, observer } from 'mobx-react-lite';
import Select from 'react-select';
import InputNumero from '../../components/InputNumero';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { api } from '../../services/utils';
import syncModel from '../../services/models/Sync';
import Formatter from '../../services/Formatter';

type Props = ModalProps & {
  aguaReservatorioID: number;
};
type State = {
  etapa: number;
  dataAnalise: MaterialUiPickersDate;
  selectedReservatorio: SelectRow | null;

  salvarAnalise(): void;
};

const EditarAguaDeReservatorio: React.FC<Props> = ({ open, handleClose, aguaReservatorioID }) => {
  const store = useStore();

  const aguaReservatorioSelected = store.aguaDeReservatorios.get(aguaReservatorioID);
  const etapas = ['Editar Análise'];
  const state = useLocalStore(
    (): State => ({
      etapa: 0,
      dataAnalise: new Date(
        aguaReservatorioSelected.data.substr(3, 2) +
          '/' +
          aguaReservatorioSelected.data.substr(0, 2) +
          '/' +
          aguaReservatorioSelected.data.substr(6, 4) +
          ' ' +
          aguaReservatorioSelected.data.split(' ')[1],
      ),
      selectedReservatorio: {
        label: aguaReservatorioSelected.reservatorio.nome,
        value: aguaReservatorioSelected.id,
      },

      async salvarAnalise() {
        handleClose();
        store.toggleLoader();

        const parametro_input: any = document.querySelectorAll('.parametro_input_edit[type=text]');
        const parametros_utilizados = [];

        for (let index = 0; index < parametro_input.length; index++) {
          parametros_utilizados.push({
            parametro: parametro_input[index].id,
            value: parametro_input[index].value.replace(',', '.'),
          });
        }

        const data = {
          reservatorio: aguaReservatorioSelected.reservatorio.id,
          data: Formatter.dateToString(this.dataAnalise),
          parametros_utilizados,
        };

        await api
          .patch(`/analise-agua-reservatorio/${this.selectedReservatorio?.value}/`, data)
          .then(() => {
            syncModel.aguaDeReservatorios();
          })
          .then(() => {
            store.notificar('Análise da água editada com sucesso!');
          })
          .catch(() => {
            store.notificar('Erro na edição da Análise da água, tente novamente!');
          });

        store.toggleLoader();
      },
    }),
  );

  const ParametroInput: React.FC = () => (
    <>
      {store.parametroValor?.map((item) => {
        if (item.used) {
          const parametro_utilizado = aguaReservatorioSelected.parametros_utilizados.filter(
            (parametro:ParametrosUtilizados) => parametro.parametro.id === item.parametro.id,
          )[0];
          const valor = parametro_utilizado ? parametro_utilizado.value : 0;
          return (
            <div key={item.id}>
              <InputNumero
                value={valor}
                precision="3"
                label={item.parametro.nome}
                className="parametro_input_edit"
                id={item.parametro.id.toString()}
              />
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  );

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{etapas ? etapas[state.etapa] : null}</ModalHeader>
      <ModalBody>
        <>
          <div style={{ width: '540px' }}>
            <Select
              placeholder="Selecione o Tanque"
              value={state.selectedReservatorio}
              options={store.reservatorios?.arr?.map((reservatorio) => {
                return {
                  label: reservatorio.nome,
                  value: reservatorio.id,
                };
              })}
              classNamePrefix="sel"
            />
            <KeyboardDateTimePicker
              autoOk
              disableToolbar
              ampm={false}
              variant="inline"
              margin="normal"
              label="Data da análise"
              value={state.dataAnalise}
              onChange={(date) => {
                state.dataAnalise = date;
              }}
              onError={(error) => {
                console.log(error);
              }}
              format="dd/MM/yyyy HH:mm"
            />
            <Divisor>
              <p>Parâmetros</p>
            </Divisor>

            <ParametroInput />

            <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
              <Botao onClick={handleClose} variant="contained" cor="#FC7467">
                Cancelar
              </Botao>
              <Botao onClick={state.salvarAnalise} variant="contained" cor="#00C853">
                Salvar
              </Botao>
            </div>
          </div>
        </>
      </ModalBody>
    </Modal>
  );
};

export default observer(EditarAguaDeReservatorio);
