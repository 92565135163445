import React, { useEffect, useState } from 'react';
import MaterialTable, { Column } from 'material-table';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Link, useHistory } from 'react-router-dom';
import { Box, Checkbox, FormControlLabel, FormGroup, IconButton, Tooltip, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CachedIcon from '@material-ui/icons/Cached';
import GetAppIcon from '@material-ui/icons/GetApp';
import Chart from 'react-apexcharts';
import * as dateFns from 'date-fns';

import { Conteudo, NewBody } from '../../../components/Body';
import Botao from '../../../components/Botoes/Botao';
import { Flex } from '../../../components/NavBar/styles';
import { newConfigRacoes } from '../../../components/Tabela';
import Formatter from '../../../services/Formatter';
import useStore from '../../../services/hooks/useStore';
import { apiMain, aqReports } from '../../../services/utils';
import { permissoes } from '../../../services/utils/permissoes';
import useModal from '../../../services/hooks/useModal';
import SelecionarTanque from './SelecionarTanque';
import { ApexOptions } from 'apexcharts';

interface ParamUtilizado {
  id: number;
  valor: number;
}

interface ParametroUtilizado {
  id: number;
  valor_maximo: number;
  valor_minimo: number;
}

interface Tanque {
  id: number;
  nome: string;
}

interface Result {
  data: string;
  nome: string;
  param_utilizado: ParamUtilizado;
  parametro_id: number;
  parametro_utilizado: ParametroUtilizado;
  tanque: Tanque;
}

interface ResponseData {
  count: number;
  count_total: number;
  results: Result[];
}

interface State {
  tanqueId: string | null;
  tanqueNome: string | null;
  data_ini: MaterialUiPickersDate | null;
  data_fim: MaterialUiPickersDate | null;
  rows: Result[];
  sync: boolean;
}

const RelatorioAnalises: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.PAINEL_ZOOTECNICO_LEITURA, history);

  const state: State = useLocalStore(() => ({
    tanqueId: null,
    tanqueNome: null,
    data_ini: null as MaterialUiPickersDate,
    data_fim: null as MaterialUiPickersDate,

    rows: [] as Result[],
    sync: false,
  }));

  const modalTanques = useModal();
  const [colunas, setColunas] = useState<string[]>([]);

  const [dadosParametros, setDadosParametros] = useState<Map<string, number | string>[]>([]);

  const [parametrosAtivados, setParametrosAtivados] = useState<Record<string, boolean>>({});

  const [valoresMaximos, setValoresMaximos] = useState<Record<string, number>>({});
  const [valoreMinimos, setValoresMinimos] = useState<Record<string, number>>({});

  const handleChangeParametros = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParametrosAtivados({ ...parametrosAtivados, [event.target.name]: event.target.checked });
  };

  function getDados() {
    if (!state.rows) return;

    const dadosPorData = new Map();

    let dadosParametrosMap: Map<string, number | string>[] = [];

    const valorMaximoMap = new Map<string, number>();
    const valorMinimoMap = new Map<string, number>();

    const registros = state.rows;
    for (const registro of registros) {
      const dataExtenso = dateFns.parse(registro.data, 'dd/MM/yyyy', new Date());
      const dataRegistro = dateFns.format(dataExtenso, 'dd/MM/yy');
      const nomeParametro = registro.nome;
      const valorParametro = Formatter.formatNumber(3, registro.param_utilizado.valor);
      const maximo = registro?.parametro_utilizado?.valor_maximo ?? 0;
      const minimo = registro?.parametro_utilizado?.valor_minimo ?? 0;

      // Obter o objeto de parâmetros para a data
      let dataObj = dadosPorData.get(dataRegistro);
      if (!dataObj) {
        dataObj = {};
        dadosPorData.set(dataRegistro, dataObj);
      }

      // Adicionar o parâmetro ao objeto de data
      dataObj['parametro'] = nomeParametro;
      dataObj[nomeParametro] = valorParametro;

      valorMaximoMap.set(nomeParametro, maximo);
      valorMinimoMap.set(nomeParametro, minimo);

      const temParametro = dadosParametrosMap.find((p) => p.get('Parâmetros') === nomeParametro);
      if (temParametro) {
        dadosParametrosMap.forEach((p) => {
          if (p.get('Parâmetros') === nomeParametro) p.set(dataRegistro, valorParametro);
        });
      } else {
        const novoParametro = new Map<string, string | number>();
        novoParametro.set('Parâmetros', nomeParametro);
        novoParametro.set(dataRegistro, valorParametro);
        dadosParametrosMap.push(novoParametro);
      }
    }

    const datas: string[] = Array.from(dadosPorData.keys());

    // preenche dadas vazias com '-'
    dadosParametrosMap.forEach((p) => {
      datas.forEach((dataRegistro) => {
        const jaTemValor = Boolean(p.get(dataRegistro));
        if (!jaTemValor) {
          p.set(dataRegistro, '-');
        }
      });
    });

    setColunas(['Parâmetros', ...datas]);
    setDadosParametros(dadosParametrosMap);
    const listaParametros = dadosParametrosMap.map((p) => p.get('Parâmetros')) as string[];
    const parametrosAtivadosObj: Record<string, boolean> = {};
    for (let p of listaParametros) parametrosAtivadosObj[p] = true;
    setParametrosAtivados(parametrosAtivadosObj);

    setValoresMaximos(Object.fromEntries(valorMaximoMap));
    setValoresMinimos(Object.fromEntries(valorMinimoMap));
  }

  async function sync(): Promise<void> {
    store.toggleLoader();

    store.tanques.populate();

    state.sync = true;
    store.toggleLoader();
  }

  async function getRelatorio(): Promise<void> {
    store.toggleLoader();

    if (state.data_ini === null || state.data_fim === null || state.tanqueId === null) {
      store.notificar('Selecione o tanque e intervalo de datas inicial e final');
    } else {
      const response = await aqReports.get<ResponseData>(`/relatorio/${store.propriedade!.uuid}/qualidade-agua`, {
        params: {
          tanque: state.tanqueId,
          data_ini: state.data_ini ? Formatter.dateToString(state.data_ini, '/') : undefined,
          data_fim: state.data_fim ? Formatter.dateToString(state.data_fim, '/') : undefined,
        },
      });
      state.rows = response.data.results;

      getDados();
    }
    store.toggleLoader();
  }

  async function getCSV() {
    try {
      if (state.data_ini === null || state.data_fim === null || state.tanqueId === null) {
        store.notificar('Selecione o tanque e intervalo de datas inicial e final');
      } else {
        const csv = await apiMain.get(`/gerador/csv/relatorio/${store.propriedade?.uuid}/qualidade-agua/`, {
          params: {
            tanque: state.tanqueId,
            data_ini: state.data_ini ? Formatter.dateToString(state.data_ini, '/') : undefined,
            data_fim: state.data_fim ? Formatter.dateToString(state.data_fim, '/') : undefined,
            authori: store.token,
          },
        });

        window.open(csv.request.responseURL, '_blank');
      }
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }

  async function getPDF() {
    try {
      if (state.data_ini === null || state.data_fim === null || state.tanqueId === null) {
        store.notificar('Selecione o tanque e intervalo de datas inicial e final');
      } else {
        const pdf = await apiMain.get(`/gerador/pdf/relatorio/${store.propriedade?.uuid}/qualidade-agua/`, {
          params: {
            tanque: state.tanqueId,
            data_ini: state.data_ini ? Formatter.dateToString(state.data_ini, '/') : undefined,
            data_fim: state.data_fim ? Formatter.dateToString(state.data_fim, '/') : undefined,
            authori: store.token,
          },
        });
        window.open(pdf.request.responseURL, '_blank');
      }
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }

  function limparFiltros(): void {
    state.data_ini = null;
    state.data_fim = null;
    state.tanqueId = null;
    state.rows = [];
  }

  const columns: Column<object>[] = colunas.map((dataDaMedicao) => ({
    field: dataDaMedicao,
    title: dataDaMedicao,
    align: dataDaMedicao === 'Parâmetros' ? 'left' : 'right',
    render: (rowData: any) => {
      const parametro = rowData['Parâmetros'];
      const minimo = valoreMinimos[parametro];
      const maximo = valoresMaximos[parametro];
      const valor = getNumeroFormatado(rowData[dataDaMedicao]);
      const corVermelha =
        typeof valor === 'number' && ((Boolean(minimo) && valor < minimo) || (Boolean(maximo) && valor > maximo));
      const color = corVermelha ? 'red' : 'black';
      return <p style={{ color }}>{rowData[dataDaMedicao]}</p>;
    },
    ...(true && ({ width: 90, maxWidth: 100 } as object)),
  }));

  const data = dadosParametros
    .map((p) => {
      const nomeParametro = p.get('Parâmetros');
      if (nomeParametro && parametrosAtivados[nomeParametro] === true) {
        return Object.fromEntries(p);
      } else {
        return undefined;
      }
    })
    .filter((p) => p !== undefined) as object[];

  function getNumeroFormatado(formatado: string | number): number | null {
    if (typeof formatado === 'number') return null;

    const valorFinal = Number(String(formatado).replace(',', '.'));
    if (isNaN(valorFinal)) return null;

    return valorFinal;
  }

  function formatarDadosGrafico(obj: Record<string, string | number>): number[] {
    const datas = Object.keys(obj).slice(1); // pega as chaves do objeto, removendo a primeira que é "Parâmetros"

    // ordena as datas
    datas.sort((a, b) => {
      const dataA = dateFns.parse(a, 'dd/MM/yy', new Date());
      const dataB = dateFns.parse(b, 'dd/MM/yy', new Date());
      return dateFns.compareAsc(dataA, dataB);
    });

    return datas.map((data) => {
      const valor = obj[data];
      return valor === '-' ? 0 : getNumeroFormatado(valor) || 0;
    });
  }

  const chartStateOptions: ApexOptions = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        export: {
          png: {
            filename: 'grafico_comparativo_analises',
          },
          svg: {
            filename: 'grafico_comparativo_analises',
          },
          csv: {
            filename: 'grafico_comparativo_analises',
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      width: [3, 3, 3, 3, 3, 3],
      curve: 'straight',
    },
    title: {
      text: 'Gráfico comparativo de análises',
      align: 'center',
    },
    // colors: ['#00C853', '#049CE7', '#FC7467', '#FFC107', '#999', '#4caf50'],
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>';
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: colunas.slice(1),
    },
  };

  const chartStateSeries = dadosParametros
    .map((p) => {
      const nomeParametro = p.get('Parâmetros');
      if (nomeParametro && parametrosAtivados[nomeParametro] === true) {
        return {
          name: nomeParametro,
          data: formatarDadosGrafico(Object.fromEntries(p)) as number[],
        };
      } else {
        return undefined;
      }
    })
    .filter((p) => p !== undefined) as ApexOptions['series'];

  useEffect(() => store.setTitulo('Relatório de Análises'), []);

  useEffect(() => {
    store.propriedade && sync();
  }, [store.propriedade]);

  return state.sync ? (
    <NewBody>
      <Flex style={{ justifyContent: 'space-between' }}>
        {/* Navegacao Anterior */}
        <Link to={'/qualidade-da-agua'} style={{ color: '#049CE7', textDecoration: 'none', fontFamily: 'Roboto' }}>
          <Flex>
            <img src={'/images/returnArrow.svg'} />
            <div style={{ paddingLeft: '5px', fontFamily: 'Roboto', color: '#049CE7' }}>Qualidade da água</div>
          </Flex>
        </Link>

        <Flex>
          <FormGroup row>
            {Object.keys(parametrosAtivados).map((parametroNome) => (
              <FormControlLabel
                key={parametroNome}
                control={
                  <Checkbox
                    checked={parametrosAtivados[parametroNome]}
                    onChange={handleChangeParametros}
                    name={parametroNome}
                    color="primary"
                  />
                }
                label={parametroNome}
              />
            ))}
          </FormGroup>
          <Tooltip title="Exportar para CSV">
            <IconButton className="blue" onClick={getCSV}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Baixar PDF">
            <IconButton className="blue" onClick={getPDF}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sincronizar">
            <IconButton
              className="blue"
              onClick={() => {
                sync();
                getRelatorio();
              }}
            >
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>

      <div style={{ display: 'grid', gridTemplateColumns: '300px 1fr', gap: 10, height: '360px', overflow: 'hidden' }}>
        <Conteudo>
          <div style={{ width: '100%' }}>
            <Botao
              // disabled={state.lote !== null}
              onClick={() => modalTanques.open()}
            >
              Escolha um Tanque
            </Botao>
            {state.tanqueNome && (
              <Box pl={1}>
                <Typography variant="body2" color="textPrimary">
                  Tanque selecionado: {state.tanqueNome}
                </Typography>
              </Box>
            )}
          </div>

          <Box display="flex" justifyContent="center" mt={1}>
            <Typography variant="body2" color="textSecondary">
              Selecione um período no máximo 30 dias:
            </Typography>
          </Box>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Inicial"
            minDate={state.data_fim ? dateFns.sub(state.data_fim, { days: 30 }) : undefined}
            maxDate={state.data_fim ?? undefined}
            value={state.data_ini}
            onChange={(date) => (state.data_ini = date!)}
            style={{ width: '100%', marginTop: '1px' }}
          />

          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Final"
            minDate={state.data_ini ?? undefined}
            maxDate={state.data_ini ? dateFns.add(state.data_ini, { days: 30 }) : undefined}
            value={state.data_fim}
            onChange={(date) => (state.data_fim = date!)}
            style={{ width: '100%' }}
          />

          <Botao onClick={getRelatorio} cor="#00C853">
            Gerar Relatório
          </Botao>
          <Botao onClick={limparFiltros} cor="#FC7467">
            Limpar Filtros
          </Botao>
        </Conteudo>
        <Conteudo style={{ padding: 0, maxWidth: '1fr', overflow: 'auto' }}>
          <MaterialTable
            columns={columns}
            data={data}
            options={{
              ...newConfigRacoes.options,
              paging: false,
              maxBodyHeight: 350,
              minBodyHeight: 340,
              sorting: false,
              headerStyle: {
                paddingTop: '8px ',
                paddingBottom: '8px ',
              },
              cellStyle: {
                paddingTop: '8px ',
                paddingBottom: '8px ',
              },
            }}
            localization={newConfigRacoes.localization}
            style={{ ...newConfigRacoes.style, maxHeight: 360, overflowX: 'scroll' }}
          />
        </Conteudo>
      </div>
      <Box bgcolor="white" mt={2} pt={1}>
        <Chart options={chartStateOptions} series={chartStateSeries} height={250} width="100%" type="line" />
      </Box>

      <SelecionarTanque
        open={modalTanques.state}
        handleClose={modalTanques.close}
        onItemClick={(tanque) => {
          state.tanqueId = tanque.value;
          state.tanqueNome = tanque.label;
        }}
      />
    </NewBody>
  ) : null;
};

export default observer(RelatorioAnalises);
