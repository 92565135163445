import styled, { css } from 'styled-components';

export const Lista = styled.div`
  display: flex;
  flex-direction: column;

  .MuiListItem-root {
    justify-content: space-between;
  }
`;

type Props = {
  disabled?: boolean;
};
export const ListaItem = styled.span<Props>`
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 45px;
  width: 100%;
  padding: 0 45px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  ${(props) =>
    props.disabled &&
    css`
      span {
        color: gray;
      }
    `};

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

export const ListaIcone = styled.img`
  width: 40px;
  height: 40px;
`;

export const ListaTexto = styled.span`
  font-family: 'Roboto';
  font-size: 14px;
  color: black;
  min-width: 130px;
  transition: 0.05s;
  opacity: 0;
  pointer-events: none !important;
`;

export const DrawerShadow = styled.div`
  position: fixed;
  top: 50px;
  width: 100vw;
  height: 100vh;

  transition: 0.3s;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;

  cursor: pointer;

  ${(props: DrawerProps) =>
    props.state &&
    css`
      pointer-events: all;
      opacity: 1;
    `};
`;

type DrawerProps = {
  state: boolean;
};
export const VerticalBar = styled.div`
  background-color: white;
  width: ${(props) => props.theme.sizes.drawer}px;
  height: calc(100vh - ${(props) => props.theme.sizes.navbar}px);
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;
  transition: 0.3s;

  svg {
    opacity: 0;
  }

  ${(props: DrawerProps) =>
    props.state &&
    css`
      width: 260px;

      svg {
        opacity: 1;
      }

      ${ListaTexto} {
        transition: 0.5s;
        transition-timing-function: cubic-bezier(0.57, -0.17, 0.98, 0.51);
        opacity: 1;
        pointer-events: all;
      }
    `};
`;
