import React from 'react';
import { Modal, ModalHeader, ModalBody, Scroll } from '../../../components/Modais/styles';
import Select from '../../../components/Select';
import { useLocalStore, observer } from 'mobx-react-lite';
import useStore from '../../../services/hooks/useStore';
import Botao from '../../../components/Botoes/Botao';
import { apiV2 } from '../../../services/utils';
import {
  FormControlLabel,
  TextField,
  FormControl,
  FormLabel,
  FormGroup,
  Switch,
  Paper,
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import useModal from '../../../services/hooks/useModal';
import FormDia from './FormDia';
import FormSemanalCA from './FormSemanalCA';
import FormSemanalBiomassa from './FormSemanalBiomassa';

type Props = ModalProps & {
  programa?: any;
  tipos: GenericType[];
  especies: GenericType[];
  racoes: GenericType[];
  marcas: GenericType[];
  fases: GenericType[];
  onConfirm(): void;
};
const FormProgramaAlimentar: React.FC<Props> = ({
  handleClose,
  open,
  programa,
  onConfirm,
  tipos,
  especies,
  racoes,
  marcas,
  fases,
}) => {
  const store = useStore();
  const modalSemana = useModal();
  const state = useLocalStore(() => ({
    nome: programa ? programa.nome : '',
    tipo: programa ? { label: programa.tipo.nome, value: programa.tipo.id.toString() } : null,
    especie: programa ? { label: programa.especie.nome, value: programa.especie.id.toString() } : null,
    ativo: programa ? programa.ativo : true,
    semanas: programa ? programa.semanas.map(formatSemana) : [],

    selectedSemana: null as any,

    async handleSemana(semana: any) {
      const semanas = [...this.semanas];

      if (programa) {
        if (this.selectedSemana) {
          try {
            await apiV2.patch(
              '/programas-alimentares/semanas/' + semana.id,
              { ...semana, racao: Number(semana.racao.value), fase: Number(semana.fase.value) },
              {
                params: {
                  prop: store.propriedade!.id,
                },
              },
            );
          } catch (err) {
            store.notificar(`Ocorreu um erro ao editar o dia/semana '${semana.codigo}'. Tente novamente!`);
          }
        } else {
          try {
            await apiV2
              .post(
                '/programas-alimentares/semanas/',
                { ...semana, racao: Number(semana.racao.value), fase: Number(semana.fase.value) },
                {
                  params: {
                    programa: programa.id,
                    prop: store.propriedade!.id,
                  },
                },
              )
              .then((res: any) => {
                semana.id = res.data.id;
              });
          } catch (err) {
            alert(`Ocorreu um erro ao cadastrar o dia/semana '${semana.codigo}'. Tente novamente!`);
          }
        }
        onConfirm();
      }

      if (this.selectedSemana) {
        const index = semanas.findIndex((item) => item.codigo === semana.codigo);
        semanas[index] = semana;
      } else {
        semanas.push(semana);
      }

      this.semanas = semanas;
    },
  }));

  async function enviar(): Promise<void> {
    if (!state.nome) store.notificar('Informe o nome do programa');
    else if (!state.tipo) store.notificar('Informe o tipo do programa');
    else if (!state.especie) store.notificar('Informe a espécie do programa');
    else if (!state.semanas.length) store.notificar('Informe os dias/semanas do programa');
    else {
      store.toggleLoader();
      handleClose();

      const semanas = state.semanas.map((semana: any) => {
        return {
          ...semana,
          racao: Number(semana.racao.value),
          marca: semana.marca ? Number(semana.marca.value) : null,
          fase: Number(semana.fase.value),
        };
      });

      const data = {
        nome: state.nome,
        tipo: Number(state.tipo!.value),
        especie: Number(state.especie!.value),
        ativo: state.ativo,
        semanas,
        propriedade_id: store.propriedade!.id,
      };

      // Checa se o usuário edtá editando ou cadastrando
      if (programa) {
        await apiV2
          .patch(
            '/programas-alimentares/' + programa.id + '/',
            {
              ...data,
              semanas: undefined,
            },
            {
              params: {
                prop: store.propriedade!.id,
              },
            },
          )
          .then(() => {
            store.notificar('Programa alimentar editado com sucesso!');
            onConfirm();
          })
          .catch(() => store.notificar('Ocorreu um erro ao editar o programa!'));
      } else {
        await apiV2
          .post('/programas-alimentares/', data, {
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `${store.token}`, 
            },
            params: {
              prop: store.propriedade!.id,
            },
          })
          .then(() => {
            store.notificar('Programa alimentar cadastrado com sucesso!');
            onConfirm();
          })
          .catch(() => store.notificar('Ocorreu um erro ao cadastrar o programa!'));
      }

      store.toggleLoader();
    }
  }

  function formatSemana(semana: any): any {
    const marca = marcas.find((marca) => semana.racao.marca_id === marca.id);
    const racao = semana.racao;
    const fase = semana.fase;

    return {
      id: semana.id || undefined,
      codigo: semana.codigo,
      marca: { label: marca?.nome, value: marca?.id.toString() },
      racao: { label: racao?.nome, value: racao?.id.toString() },
      fase: { label: fase?.nome, value: fase?.id.toString() },
      pm_ini: semana.pm_ini,
      pm_fim: semana.pm_fim,
      sobrevivencia: semana.sobrevivencia,
      num_tratos: semana.num_tratos,
      perc_biomassa: semana.perc_biomassa,
      consumo_g: semana.consumo_g,
      ca_esperado: semana.ca_esperado,
    };
  }

  function Semana({ data, excluir }: any): JSX.Element {
    return (
      <Paper elevation={3} style={{ padding: 10, marginBottom: 10 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={1} style={{ textAlign: 'center' }}>
            <Typography variant="caption">{data.codigo}</Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center', display: 'flex' }} direction="column">
            <Typography variant="caption">{data.fase?.label}</Typography>
          </Grid>
          <Grid item xs={5} style={{ textAlign: 'center', display: 'flex' }} direction="column">
            <Typography variant="caption">{data.racao?.label}</Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            {!programa?.instituicao_id ? (
              <>
                <IconButton
                  size="small"
                  onClick={() => {
                    state.selectedSemana = data;
                    modalSemana.open();
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton size="small" onClick={excluir}>
                  <DeleteIcon />
                </IconButton>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{programa ? 'Editar' : 'Novo'} programa</ModalHeader>
      <ModalBody>
        <TextField label="Nome do programa" value={state.nome} onChange={(e) => (state.nome = e.target.value)} />

        <div style={{ width: 400 }}>
          <Select
            value={state.tipo}
            placeholder="Tipo de programa"
            onChange={(e: SelectRow) => (state.tipo = e)}
            options={tipos.map((tipo: GenericType) => ({ label: tipo.nome, value: tipo.id.toString() }))}
          />
        </div>

        <div style={{ width: 400 }}>
          <Select
            value={state.especie}
            placeholder="Espécie"
            onChange={(e: SelectRow) => (state.especie = e)}
            options={especies.map((especie: GenericType) => ({
              label: especie.nome,
              value: especie.id.toString(),
            }))}
          />
        </div>

        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">Semanas/Dias</FormLabel>
          <FormGroup>
            <Scroll style={{ maxHeight: 300, marginBottom: 10 }}>
              {state.semanas.map((semana: any) => (
                <Semana
                  key={semana.codigo}
                  data={semana}
                  excluir={() => {
                    state.semanas = state.semanas.filter((item: any) => item.codigo !== semana.codigo);
                    apiV2
                      .delete('/programas-alimentares/semanas/' + semana.id +'/', {
                        params: {
                          prop: store.propriedade!.id,
                        },
                      })
                      .then(onConfirm);
                  }}
                />
              ))}
            </Scroll>
          </FormGroup>
        </FormControl>
        <Botao
          fullWidth
          cor="#42a5f5"
          onClick={() => {
            state.selectedSemana = null;
            modalSemana.open();
          }}
          variant="contained"
        >
          <AddIcon />
          Adicionar Dia/Semana
        </Botao>

        <div
          style={{
            width: '100%',
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={!state.ativo}
                onChange={(e) => {
                  state.ativo = !e.target.checked;
                }}
                color="primary"
              />
            }
            label="Inativo"
          />
        </div>

        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao onClick={enviar} variant="contained" cor="#00C853">
            Salvar
          </Botao>
        </div>
      </ModalBody>

      {modalSemana.state ? (
        tipos.find((tipo) => Number(state.tipo?.value) === tipo.id)?.slug === 'SEMANAL' ? (
          state.tipo?.label.includes('CA') ? (
            <FormSemanalCA
              open={modalSemana.state}
              handleClose={modalSemana.close}
              semana={state.selectedSemana}
              codigo={state.selectedSemana ? state.selectedSemana.codigo : 'S' + (state.semanas.length + 1)}
              onConfirm={state.handleSemana}
              racoes={racoes}
              marcas={marcas}
              fases={fases}
            />
          ) : (
            <FormSemanalBiomassa
              open={modalSemana.state}
              handleClose={modalSemana.close}
              semana={state.selectedSemana}
              codigo={state.selectedSemana ? state.selectedSemana.codigo : 'S' + (state.semanas.length + 1)}
              onConfirm={state.handleSemana}
              racoes={racoes}
              marcas={marcas}
              fases={fases}
            />
          )
        ) : (
          <FormDia
            open={modalSemana.state}
            handleClose={modalSemana.close}
            semana={state.selectedSemana}
            codigo={state.selectedSemana ? state.selectedSemana.codigo : 'D' + (state.semanas.length + 1)}
            onConfirm={state.handleSemana}
            racoes={racoes}
            marcas={marcas}
            fases={fases}
          />
        )
      ) : null}
    </Modal>
  );
};

export default observer(FormProgramaAlimentar);
