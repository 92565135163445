import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useHistory } from 'react-router-dom';
import { Conteudo, NewBody } from '../../../components/Body';
import Botao from '../../../components/Botoes/Botao';
import Select from '../../../components/Select';
import pt from 'apexcharts/dist/locales/pt.json';
import useStore from '../../../services/hooks/useStore';
import { apiV2, aqReports } from '../../../services/utils';
import { Flex } from '../../../components/NavBar/styles';

import useModal from '../../../services/hooks/useModal';
import SelecionarLote from './crescimento/SelecionarLote';
import SelecionarLoteTanques from './crescimento/SelecionarLoteTanques';
import PainelZootecnicoTab from '../PainelZootecnico/PainelZootecnicoTab';
import { permissoes } from '../../../services/utils/permissoes';
// import syncModel from '../../../services/models/Sync';

const Crescimento: React.FC = () => {
  const modalSelecionarLote = useModal();
  const modalTanques = useModal();
  const store = useStore();
  const [programas, setProgramas] = useState<any[]>([]);
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.PAINEL_ZOOTECNICO_LEITURA, history);

  const state = useLocalStore(() => ({
    filtroRapidoFinalizado: false,
    filtroRapidoTanqueFinalizado: false,
    filtroRapidoFase: null as SelectRow | null,
    lote: null as SelectRow | null,
    programa: undefined as SelectRow | undefined,
    tanques: [] as SelectedTanque[],

    tanque: null as { label: string; value: string; loteTanque: string } | null,

    loteOuTanque: true as boolean | null,
    lotes: [] as SelectRow[],
    props: store.propriedade?.uuid,
    rows: [] as object[],
    totais: [] as object[],
    sync: false,
    renderKey: 0,
  }));

  const graficoMortalidade = useLocalStore(() => ({
    filtro: 0,
    series: [
      {
        name: 'PM Programa:',
        data: [],
      },
      {
        name: 'PM Real:',
        data: [],
      },
    ],
    options: {
      chart: {
        locales: [pt],
        defaultLocale: 'pt',
        type: 'line',
        id: 'CurvaCrescimento',
        dataLabels: {
          enabled: false,
        },
      },

      legend: {
        tooltipHoverFormatter: function (val: any, opts: any) {
          return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '(g)';
        },
      },
      markers: {
        size: 0,
      },
      stroke: {
        curve: 'smooth',
      },
      dataLabels: {
        enabled: true,
      },

      tooltip: {
        y: [
          {
            title: {
              formatter: function (val: number) {
                return val + ' (g)';
              },
            },
          },
          {
            title: {
              formatter: function (val: number) {
                return val + '(g)';
              },
            },
          },
        ],
      },
      title: {
        text: `Lote:
        ${state.lote ? `${state.lote.label}` : state.tanque ? '' : '----'}
        ${state.tanque ? `${state.tanque.loteTanque}` : ''}
        ${'  '} - Tanque:
        ${state.tanque ? `${state.tanque.label}` : '----'} - Programa:
        ${state.programa ? `${state.programa.label}` : '----'}`,
        align: 'left',
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
    },
    async update() {
      /**
       * Pelo o que entendi é necessário  recarregar a página para aplicar
       * qualquer alteração nessa função
       */
      store.toggleLoader();
      try {
        const response = await aqReports.get(`/curva/${store.propriedade!.uuid}/crescimento`, {
          params: {
            programa: state.programa?.value,
            lote: state.lote?.value,
            lotetanque: state.tanque?.value,
          },
        });
        const finalResults = response.data?.results;

        if (response) {
          ApexCharts.exec('CurvaCrescimento', 'updateSeries', [
            {
              data: finalResults.map((historico: any) => {
                return { x: historico.semana, y: historico.pm_ini };
              }),
            },
            {
              data: finalResults.map((historico: any) => {
                let dado = null;
                // eslint-disable-next-line
                historico.dados.map((item: any) => {
                  if (item.media > 0) {
                    dado = item.media;
                  }
                });
                return {
                  x: historico.semana,
                  y: dado,
                };
              }),
            },
          ]);
        }
      } catch (err) {
        store.notificar('Não encontrado Povoamento ou Transferência para esse Lote ou Tanque');
      } finally {
        store.toggleLoader();
      }
    },
  }));

  async function sync(): Promise<void> {
    store.toggleLoader();

    let programas = [] as any;
    await store.lotes.populate();
    await store.tanques.populate();
    await store.loteTanque.populate();

    //await syncModel.painelManejo()

    await apiV2
      .get('/programas-alimentares/', {
        params: { prop: store.propriedade!.id, page_size: 50, page: 1 },
      })
      .then(async (res) => {
        const data = res.data;
        if (res.data.next) {
          do {
            const request = await apiV2.get(data.next);

            data.results = [...data.results, ...request.data.results];
            data.next = request.data.next;
          } while (data.next !== null);
        }
        if (!data.results) {
          programas.push(...data);
        } else {
          programas.push(...data.results);
        }
      });

    setProgramas(programas);
    state.lotes = store.lotes.map((item) => {
      return {
        label: item.nome,
        value: item.uuid,
      };
    });

    state.sync = true;

    store.toggleLoader();
  }

  //limpar os campos/filtros
  function limparFiltros(): void {
    state.lote = null;
    state.tanque = null;
    state.rows = [];
    state.totais = [];
    state.programa = undefined;
    state.renderKey = Math.random();
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Curva de Crescimento'), []);

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  useEffect(() => {
    if (state.lote || state.tanque || state.programa) {
      ApexCharts.exec(
        'CurvaCrescimento',
        'updateOptions',
        {
          title: {
            text: `Lote:
          ${state.lote ? `${state.lote.label}` : state.tanque ? '' : '----'}
          ${state.tanque ? `${state.tanque.loteTanque}` : ''}
          ${'  '} - Tanque:
          ${state.tanque ? `${state.tanque.label}` : '----'} - Programa:
          ${state.programa ? `${state.programa.label}` : '----'}`,
            align: 'left',
          },
        },
        false,
        true,
      );
    }
  }, [state.lote, state.tanque, state.programa]);

  return state.sync ? (
    <>
      <NewBody>
        <Flex style={{ justifyContent: 'space-between' }}>
          <PainelZootecnicoTab />
        </Flex>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 310px', gap: 10 }}>
          <Conteudo style={{ padding: 0 }}>
            <ReactApexChart
              options={graficoMortalidade.options as any}
              series={graficoMortalidade.series}
              type="line"
              height={500}
            />
          </Conteudo>

          <Conteudo>
            <div style={{ width: '100%', marginBottom: 10 }}>
              <Select
                key={state.renderKey}
                placeholder="Escolha o Programa Alimentar"
                value={state.programa}
                options={programas.map((programa) => {
                  const isSemanal = programa.tipo.slug === 'SEMANAL';
                  const isUnique = programa.semanas.length === 1;
                  const strings = ['semanas', 'semana', 'dias', 'dia'];
                  const dias = `${programa.semanas.length} ${
                    isSemanal ? (isUnique ? strings[1] : strings[0]) : isUnique ? strings[3] : strings[2]
                  }`;

                  return { label: `${programa.nome} - ${dias}`, value: programa.uuid };
                })}
                onChange={(e: any) => {
                  state.programa = e;
                }}
              />
            </div>

            <div style={{ width: '100%', marginBottom: 10 }}>
              <Botao disabled={state.tanque !== null} onClick={() => modalSelecionarLote.open()}>
                Adicionar Lote
              </Botao>
            </div>

            <div style={{ width: '100%', marginBottom: 10 }}>
              <Botao disabled={state.lote !== null} onClick={() => modalTanques.open()}>
                Adicionar Tanque
              </Botao>
            </div>

            <Botao
              cor="#00C853"
              onClick={() => {
                graficoMortalidade.update();
              }}
            >
              Aplicar
            </Botao>
            <Botao onClick={limparFiltros} cor="#FC7467">
              Limpar Filtros
            </Botao>
          </Conteudo>
        </div>
      </NewBody>

      {/* Modais */}
      <SelecionarLote
        state={state}
        open={modalSelecionarLote.state}
        handleClose={modalSelecionarLote.close}
        onItemClick={(lote) => {
          state.tanques = [];
          state.lote = { label: lote.codigo, value: lote.uuid };
        }}
        loteID={state.lote ? parseInt(state.lote.value) : null}
      />

      <SelecionarLoteTanques
        open={modalTanques.state}
        handleClose={modalTanques.close}
        setTanque={(tanque) => {
          state.tanque = { label: tanque.label, value: tanque.value, loteTanque: tanque.tanque };
        }}
        setLote={(lote) => console.log(lote)}
        state={state}
        exclude={[]}
      />
    </>
  ) : null;
};

export default observer(Crescimento);
