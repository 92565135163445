/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Header from '../../../components/Header';
import { Flex } from '../../../components/NavBar/styles';
import AddBtn from '../../../components/Botoes/AddBtn';
import { Body } from '../../../components/Body';
import { config } from '../../../components/Tabela';
import useStore from '../../../services/hooks/useStore';
import Filtro from '../../../components/Filtro';
import MaterialTable, { Column } from 'material-table';
import { useLocalStore, observer } from 'mobx-react-lite';
import useModal from '../../../services/hooks/useModal';
import ActionButton from '../../../components/Tabela/ActionButton';
import Confirmar from '../../../components/Modais/Confirmar';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FormConta from './FormConta';
import { useHistory } from 'react-router-dom';
import syncModel from '../../../services/models/Sync';
import Formatter from '../../../services/Formatter';
import { permissoes } from '../../../services/utils/permissoes';
import X9Logger from '../../../services/X9Logger';

const Conta: React.FC = () => {
  const state = useLocalStore(() => ({
    pageSize: 0,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
    selectedConta: 0,

    sync: false,
  }));
  const modalConta = useModal();
  const modalExclusao = useModal();
  const store = useStore();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.FINANCEIRO_CONTA_BANCARIA_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.FINANCEIRO_CONTA_BANCARIA_ESCRITA);

  const columns: Column<object>[] = [
    {
      field: 'nome',
      title: 'Nome',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'acoes',
      render: (rowData: any) => (
        <ActionButton
          editar={
            temPermissaoEscrita
              ? () => {
                  state.selectedConta = rowData.id;
                  modalConta.open();
                }
              : undefined
          }
          excluir={
            temPermissaoEscrita && rowData.padrao
              ? undefined
              : () => {
                  state.selectedConta = rowData.id;
                  modalExclusao.open();
                }
          }
        />
      ),
      ...(true && ({ width: 25 } as object)),
    },
    {
      field: 'saldoInicial',
      title: 'Saldo Inicial (R$)',
      type: 'numeric',
      render: ({ saldoInicial }: any) => {
        return Formatter.formatNumber(2, saldoInicial);
      },
      ...(true && ({ width: 170 } as object)),
    },
    {
      field: 'saldoAtual',
      title: 'Saldo Atual (R$)',
      type: 'numeric',
      render: ({ saldoAtual }: any) => {
        return <span style={{ color: saldoAtual < 0 ? 'red' : 'green' }}>{Formatter.formatNumber(2, saldoAtual)}</span>;
      },
      ...(true && ({ width: 170 } as object)),
    },
    {
      field: 'status',
      title: 'Status',
      render: ({ status }: any) => {
        return status ? <b style={{ color: 'red' }}>Inativo</b> : <b style={{ color: 'green' }}>Ativo</b>;
      },
      ...(true && ({ width: 200 } as object)),
    },
    {
      field: 'padrao',
      title: 'Conta Padrão',
      type: 'numeric',
      render: ({ padrao }: any) => {
        return padrao ? (
          <CheckCircleOutlineIcon style={{ color: 'green' }} />
        ) : (
          <HighlightOffIcon style={{ color: 'red' }} />
        );
      },
      ...(true && ({ width: 150 } as object)),
    },
  ];
  const rows = (contas: Conta[]): object[] => {
    if (contas.length) {
      if (contas.length >= 50) {
        state.setPageSize(50);
      } else {
        state.setPageSize(contas.length);
      }
    } else {
      state.setPageSize(0);
    }

    return contas.map((conta) => {
      return {
        id: conta.id,
        nome: conta.titulo,
        saldoInicial: conta.saldo_inicial,
        saldoAtual: conta.saldo_atual,
        status: conta.inativo,
        padrao: conta.conta_padrao,
      };
    });
  };

  async function sync(): Promise<void> {
    await syncModel.contas();

    state.sync = true;
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Contas'), []);

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  async function filtrar(): Promise<void> {
    store.toggleLoader();
    await store.contas.populate();
    store.toggleLoader();
  }

  const ExcluirConta: React.FC = () => {
    const conta = store.contas.get(state.selectedConta);

    if (conta) {
      return (
        <div style={{ width: '100%' }}>
          <b>Nome: </b> {conta.titulo}
          <br />
          <b>Saldo Inicial: </b> R$ {Formatter.formatNumber(2, conta.saldo_inicial)}
          <br />
          <b>Saldo Atual: </b> R$ {Formatter.formatNumber(2, conta.saldo_atual)}
          <br />
          <b>Status: </b>{' '}
          {conta.inativo ? <b style={{ color: 'red' }}>Inativo</b> : <b style={{ color: 'green' }}>Ativo</b>}
          <br />
        </div>
      );
    }
    return null;
  };

  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn
            disabled={!temPermissaoEscrita}
            onClick={() => {
              state.selectedConta = 0;
              modalConta.open();
            }}
            text="ADICIONAR"
            Icon={AddIcon}
          />
        </Flex>
        <Flex>
          <Filtro sync={filtrar} clear={() => null}></Filtro>
        </Flex>
      </Header>

      <Body grid={true}>
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          {store.contas.arr && rows && (
            <MaterialTable
              columns={columns}
              data={rows(store.contas.arr!)}
              options={{ ...config.options, pageSize: state.pageSize }}
              localization={config.localization}
              style={config.style}
            />
          )}
        </div>
      </Body>
      {modalConta.state && (
        <FormConta open={modalConta.state} handleClose={modalConta.close} contaID={state.selectedConta} />
      )}
      <Confirmar
        open={modalExclusao.state}
        content={<ExcluirConta />}
        title="Tem certeza que deseja excluir?"
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={async () => {
          modalExclusao.close();
          store.toggleLoader();
          try {
            await store.contas.excluir(state.selectedConta);
            store.toggleLoader();
          } catch (err: any) {
            X9Logger.report(err as Error);
            if (err.response && err.response.data) {
              Object.values(err.response.data).forEach((value) => {
                store.notificar(`${value}`);
              });
            }
            store.toggleLoader();
          }
        }}
      />
    </>
  ) : null;
};

export default observer(Conta);
