import { stateContext } from './../hooks/useStore';
import { getThingsV2 } from '../utils';
import { observable, computed } from 'mobx';
import { CompraInsumo } from '../../@types/Insumo';
import { apiV2 } from '../utils';

class CompraDeInsumos {
  @observable comprasDeInsumos: CompraInsumo[] | null = null;
  @observable totalRes: number = 0;
  @computed
  get length(): number {
    return this.comprasDeInsumos ? this.comprasDeInsumos.length : 0;
  }

  async populate(): Promise<void> {
    this.comprasDeInsumos = await getThingsV2<CompraInsumo>('/comprar-insumo/', {
      propriedade: stateContext.state.propriedade!.id,
    });
    // this.comprasDeInsumos = mocComprasDeInsumos;//TODO: trocar url aqui também, colocar url de compra de produto
  }

  async getEverything(params?: object): Promise<CompraInsumo[]> {
    // return await getThingsV2<CompraInsumo>('/produto/', params);
    return await getThingsV2<CompraInsumo>('/comprar-insumo/', params);
  }

  async getPaginated(page: number, pageSize: number, propriedadeId: number, filterURL?: string): Promise<void> {
    const baseUrl = `/produto/?propriedade=${propriedadeId}`;
    const urlArray = [];
    urlArray.push(baseUrl);

    if (filterURL) {
      urlArray.push(filterURL);
    }

    const requestUrl = urlArray.join('');

    apiV2
      .get(requestUrl, {
        params: {
          page_size: pageSize,
          page: page + 1,
          prop: propriedadeId,
        },
      })
      .then((res: any) => {
        this.totalRes = res.data.count;
        this.comprasDeInsumos = res.data.results;
      });
  }

  get(id: number): CompraInsumo {
    return this.comprasDeInsumos!.filter((item) => item.id === id)[0];
  }

  // eslint-disable-next-line no-unused-vars
  filter(callback: (item: CompraInsumo, index: number, array: CompraInsumo[]) => void): CompraInsumo[] {
    return this.comprasDeInsumos!.filter(callback);
  }

  // eslint-disable-next-line no-unused-vars
  map(callback: (item: CompraInsumo, index: number, array: CompraInsumo[]) => any): any {
    return this.comprasDeInsumos!.map(callback);
  }
}

export default CompraDeInsumos;

//-----------------------------------------------------------

// const mocComprasDeInsumos = [
//     {
//         id: 1,
//         uuid: '74370428-de7b-4ea2-91b1-f89111598293',
//         propriedade: 1007,
//         nome_insumo: 'tropipoca',
//         tipo: 'EQUIPAMENTO',
//         data: new Date,
//         estoque: 200,
//         valor_total: 200
//     },
//     {
//         id: 1,
//         uuid: '74370428-de7b-4ea2-91b1-f89111598293',
//         propriedade: 1007,
//         nome_insumo: 'tropipoca',
//         tipo: 'EQUIPAMENTO',
//         data: new Date,
//         estoque: 2000,
//         valor_total: 150
//     },
//     {
//         id: 1,
//         uuid: '74370428-de7b-4ea2-91b1-f89111598293',
//         propriedade: 1007,
//         nome_insumo: 'tropipoca',
//         tipo: 'EQUIPAMENTO',
//         data: new Date,
//         estoque: 155,
//         valor_total: 75
//     },
// ]
