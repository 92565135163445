import styled from 'styled-components';

export const PDFContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  table {
    page-break-inside: auto;
    tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }
  }
`;
