// import React, { useEffect } from 'react';
import React from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../../components/Modais/styles';
import Botao from '../../../../components/Botoes/Botao';
import InputNumero from '../../../../components/InputNumero';
import { useLocalStore, observer } from 'mobx-react-lite';
// import Select from '../../../../components/Select';
// import useStore from '../../../../services/hooks/useStore';
// import { returnQuantidade } from '../../../../services/utils/producao';
// import Formatter from '../../../../services/Formatter';
import { AplicacaoTanqueData } from '../../../../@types/Insumo';

type Props = ModalProps & {
  onConfirm(tanqueData: AplicacaoTanqueData): void;
  loteTanque: LoteTanque;
  diasDiferenca: number | undefined;
  quantidadeAnimais: number;
};
const QuantidadeModal: React.FC<Props> = ({
  open,
  handleClose,
  onConfirm,
  loteTanque,
  // diasDiferenca,
  quantidadeAnimais,
}) => {
  // const store = useStore();
  const state = useLocalStore(() => ({
    insumo: 0,
    kgPorAnimal: quantidadeAnimais,
    pct_sobrevivencia: 100,
  }));

  const loteTanqueQuantidade = {
    tanque_id: loteTanque.tanque.id,
    lote_tanque_id: loteTanque.id,
    quantidade: parseFloat(state.insumo.toFixed(3)),
    uuid: loteTanque.uuid,
    ativo: true,
  };

  function confirmar(): void {
    onConfirm(loteTanqueQuantidade);
    // if (state.racao === 0) store.notificar('Informe o peso da ração');
    // else {
    //   const qtd_racao = parseFloat(state.racao.toFixed(3));
    //   const tipo = (): TanqueData['tipo'] => {
    //     if (state.select.value === '0' || state.select.value === '3') {
    //       return 'racao_por_animal';
    //     } else if (state.select.value === '1') {
    //       return 'biomassa';
    //     } else {
    //       return 'programa';
    //     }
    //   };

    //   if (state.select.value === '2') {
    //     const tanqueData = {
    //       tanque_id: 0,
    //       lote_tanque_id: 0,
    //       quantidade: parseFloat(state.totalRacao.toFixed(3)),
    //       uuid: undefined,
    //       ativo: true,
    //     };
    //     onConfirm(tanqueData);
    //     handleClose();
    //   } else {
    //     const tanqueData = {
    //       tanque_id: 0,
    //       lote_tanque_id: 0,
    //       quantidade: 0,
    //       uuid: undefined,
    //       ativo: true,
    //     };
    //     onConfirm(tanqueData);
    //     handleClose();
    //   }
    // }
  }

  // function Calcular(): number {
  //   state.racao = (((quantidadeAnimais * state.pct_sobrevivencia) / 100) * state.kgPorAnimal * state.pct_temperatura) / 100;
  //   return (state.totalRacao = state.racao * diasDiferenca!);
  // }

  // useEffect(() => {
  //   if (state.pct_biomassa || state.pct_temperatura) {
  //     const lote = store.lotes.get(loteTanque?.lote.id);
  //     const biomassaLT = ((lote.peso_medio_atual || lote.peso_medio) * returnQuantidade(loteTanque, false)) / 1000;
  //     state.racao = biomassaLT * (state.pct_biomassa / 100);
  //     state.racao = state.racao * (state.pct_temperatura / 100);
  //   }
  //   // eslint-disable-next-line
  // }, [state.pct_biomassa, state.pct_temperatura]);

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>Informe a quantidade</ModalHeader>
      <ModalBody>
        <div style={{ width: 400 }}>
          <InputNumero
            value={state.insumo}
            onChange={(value) => (state.insumo = value)}
            precision="3"
            label="Quantidade"
          />
        </div>

        {/* {state.select.value === '2' ? (
          <>
            <InputNumero
              value={state.kgPorAnimal}
              onChange={(value) => (state.kgPorAnimal = value)}
              precision="3"
              label="Kg por animal (kg)"
            />
            <InputNumero
              value={state.pct_temperatura}
              onChange={(value) => (state.pct_temperatura = value)}
              precision="0"
              label="% Temperatura"
            />
            <InputNumero
              value={state.pct_sobrevivencia}
              onChange={(value) => (state.pct_sobrevivencia = value)}
              precision="0"
              label="% Sobrevivência"
            />

            <Botao onClick={Calcular} fullWidth>
              Calcular
            </Botao>

            <InputNumero
              value={state.racao}
              onChange={(value) => (state.racao = value)}
              precision="3"
              label="Ração por tanque dia (kg)"
              disabled
            />

            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
              <div style={{ margin: 5, textAlign: 'center' }}>
                <h4>Total de dias</h4>
                <h1 style={{ color: '#42a5f5' }}>{Formatter.formatNumber(0, diasDiferenca)}</h1>
              </div>
              <div style={{ margin: 5, textAlign: 'center' }}>
                <h4>Total de ração no período (kg)</h4>
                <h1 style={{ color: '#42a5f5' }}>{Formatter.formatNumber(3, state.totalRacao)}</h1>
              </div>
            </div>
          </>
        ) : null} */}
        <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
          <Botao cor="gray" onClick={handleClose}>
            Voltar
          </Botao>
          <Botao cor="#00C853" onClick={confirmar}>
            Confirmar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(QuantidadeModal);
