import { createGlobalStyle, css } from 'styled-components';

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    font-family: "Roboto";
  }

  body {
    background-color: ${(props) => props.theme.colors.background};
    overflow: hidden;
  }

  #root {
    width: 100vw;
  }

  .MuiInput-underline:after {
    border-bottom: ${(props) => `2px solid ${props.theme.colors.primary} !important`};
  }

  .blue svg {
      color: ${(props) => props.theme.colors.primary};
  }

  .loader {
    z-index: 100000000!important;
    svg {
      color: white;
    }
  }
  .flex {
    display: flex;
  }
  .content {
    height: ${(props) => css`
      calc(100vh - ${props.theme.sizes.navbar}px)
    `};
    max-height: ${(props) => css`
      calc(100vh - ${props.theme.sizes.navbar}px)
    `};
    width: ${(props) => css`
      calc(100vw - ${props.theme.sizes.drawer}px)
    `};

    position: absolute;
    right: 0;
    z-index: -1;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  /* Tabela */
  /* #42a5f5 */
  .MTableToolbar-root-118 {
    background-color: ${(props) => props.theme.colors.primary};
    color: white;
    button {
      color: white;
    }
  }
  .MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-193.MuiToolbar-gutters {
    .MuiInputBase-root {
      color: white!important;
    }
    .MuiInput-underline:before {
      border-bottom: 1px solid rgba(247, 247, 247, 0.73);
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 2px solid rgba(247, 247, 247, 0.73);
    }
    .MuiInput-underline::after {
      border-bottom: 2px solid #0d7aaf!important;
    }
  }

  ::-webkit-scrollbar-track
  {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar
  {
    width: 5px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    background-color: #8f8f8f;
  }

  .MuiTableCell-paddingNone {
    width: 40px;
  }

  .sel__menu {
    z-index: 1000000000!important;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2)!important;
    color: #313131 !important;
  }
  .sel__indicator {
    color: #313131 !important;
    svg {
      color: #313131 !important;
    }
  }

  .MuiPickersBasePicker-container {
    .MuiPickersToolbar-toolbar {
      background-color: ${(props) => props.theme.colors.primary};
    }
    .MuiPickersBasePicker-pickerView {
      .MuiPickersDay-daySelected {
        background-color: ${(props) => props.theme.colors.primary};
      }
      .MuiPickersCalendarHeader-switchHeader {
        background-color: ${(props) => props.theme.colors.primary};
        color: white;
        margin: 0;
        padding: 7px;

        button {
          background-color: transparent;
        }
        svg {
          color: white;
        }
      }
      .MuiPickersCalendarHeader-daysHeader {
        background-color: ${(props) => props.theme.colors.primary};
        span {
          color: white;
        }
      }
    }
  }
`;
