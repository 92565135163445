import styled from 'styled-components';

export const ErrorContainer = styled.div`
  width: 100vw;
  height: 100vh;

  padding: 30px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    color: ${(props) => props.theme.colors.primary};
    font-weight: normal;
    font-size: 50px;
    margin: 15px 0;
  }

  p {
    color: #8f8f8f;
    font-size: 16px;
    margin: 5px 0;
  }

  img {
    width: 280px;
    height: 280px;
  }
`;
