import { Checkbox, FormControlLabel, IconButton, Tooltip } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CachedIcon from '@material-ui/icons/Cached';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MaterialTable, { Column } from 'material-table';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Conteudo, NewBody } from '../../../components/Body';
import Botao from '../../../components/Botoes/Botao';
import { Flex } from '../../../components/NavBar/styles';
import Select from '../../../components/Select';
import { newConfigRacoes } from '../../../components/Tabela';
import GetAppIcon from '@material-ui/icons/GetApp';
import Formatter from '../../../services/Formatter';
import useStore from '../../../services/hooks/useStore';
import { apiMain, aqReports } from '../../../services/utils';
import useModal from '../../../services/hooks/useModal';
import SelecionarLoteTanques from './crescimento/SelecionarLoteTanques';
import SelecionarLote from './crescimento/SelecionarLote';
import { permissoes } from '../../../services/utils/permissoes';

const RacaoTanque: React.FC = () => {
  const store = useStore();

  const modalSelecionarLote = useModal();
  const modalTanques = useModal();
  const [financeChecked, setFinanceChecked] = useState(false);
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.PAINEL_ZOOTECNICO_LEITURA, history);

  const state = useLocalStore(() => ({
    lote: null as SelectRow | null,
    tanque: null as SelectRow | null,
    racao: null as SelectRow | null,
    codigo: '',
    setRacao(e: SelectRow) {
      this.racao = e;
    },
    id: {
      lote: null as any,
      tanque: null as any,
    },
    data_ini: null as MaterialUiPickersDate,
    data_fim: null as MaterialUiPickersDate,

    lotes: [] as SelectRow[],
    tanques: [] as SelectRow[],

    rows: [] as object[],
    totais: 0,
    sync: false,
  }));

  const columns: Column<object>[] = [
    {
      field: 'data',
      title: 'DATA',
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'tanque',
      title: 'TANQUES',
      ...(true && ({ width: 120 } as object)),
    },
    {
      field: 'lote',
      title: 'LOTE',
      ...(true && ({ width: 120 } as object)),
    },
    {
      field: 'estoque',
      title: 'ESTOQUE ATUAL',
      type: 'numeric',
      render: (rowData: any) => {
        return Formatter.formatNumber(0, rowData.qtd_racao);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'programa_alimentar',
      title: 'PROGRAMA ALIMENTAR',
      ...(true && ({ width: 200 } as object)),
    },
    {
      field: 'pm_atual',
      title: 'PM ATUAL(G)',
      render(rowData: any) {
        return Formatter.formatNumber(3, rowData.pm_atual);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'racao',
      title: 'RAÇÃO',
      ...(true && ({ width: 120 } as object)),
    },
    {
      field: 'qtd_racao',
      title: 'QTDE RAÇÃO/DIA',
      type: 'numeric',
      render: (rowData: any) => {
        return Formatter.formatNumber(3, rowData.qtd_racao);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'qtd_tratos',
      title: 'QTDE TRATOS',
      type: 'numeric',
      render: (rowData: any) => {
        return Formatter.formatNumber(0, rowData.qtd_tratos);
      },
      ...(true && ({ width: 180 } as object)),
    },
  ];

  async function sync(): Promise<void> {
    store.toggleLoader();
    await store.racoes.populate();
    await store.lotes.populate();
    await store.loteTanque.populate();

    state.lotes = store.lotes.map((item) => {
      return {
        label: item.nome,
        value: item.uuid,
      };
    });

    state.tanques = store.loteTanque.map((item) => {
      return {
        label: item.tanque.nome ? item.tanque.nome : item.tanque.codigo,
        value: item.uuid,
      };
    });

    state.sync = true;

    store.toggleLoader();
  }

  async function getRelatorio(): Promise<void> {
    store.toggleLoader();

    if (state.lote === null && state.tanque === null && state.racao === null && state.data_ini === null) {
      store.notificar('Deve ser escolhido pelo menos uma das opções, lote, tanque, ração ou Data Inicial!');
    } else {
      const response = await aqReports.get(`/relatorio/${store.propriedade!.id}/arracoamento-tanque`, {
        params: {
          lancado: financeChecked,
          lote_id: state.lote ? state.lote.value : undefined,
          racao_id: state.racao ? state.racao.value : undefined,
          tanque_id: state.tanque ? state.tanque.value : undefined,
          data_ini: state.data_ini ? Formatter.dateToString(state.data_ini, '/') : undefined,
          data_fim: state.data_fim ? Formatter.dateToString(state.data_fim, '/') : undefined,
        },
      });

      state.rows = response.data.results;
      state.totais = response.data.total_racao;
    }

    store.toggleLoader();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getCSV() {
    try {
      if (state.lote === null && state.tanque === null && state.racao === null && state.data_ini === null) {
        store.notificar('Deve ser escolhido pelo menos uma das opções, lote, tanque, ração ou Data Inicial!');
      } else {
        const pdf = await apiMain.get(`/gerador/csv/relatorio/${store.propriedade?.id}/arracoamento-tanque/`, {
          params: {
            authori: store.token,
            lancado: financeChecked,
            racao_id: state.racao ? state.racao.value : '',
            lote_id: state.lote ? state.lote.value : '',
            tanque_id: state.tanque ? state.tanque.value : '',
            data_ini: state.data_ini ? Formatter.dateToString(state.data_ini, '/') : undefined,
            data_fim: state.data_fim ? Formatter.dateToString(state.data_fim, '/') : undefined,
          },
        });

        window.open(pdf.request.responseURL, '_blank');
      }
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getPDF() {
    try {
      if (state.lote === null && state.tanque === null && state.racao === null && state.data_ini === null) {
        store.notificar('Deve ser escolhido pelo menos uma das opções, lote, tanque, ração ou Data Inicial!');
      } else {
        const pdf = await apiMain.get(`/gerador/pdf/relatorio/${store.propriedade?.id}/arracoamento-tanque/`, {
          params: {
            authori: store.token,
            lancado: financeChecked,
            racao_id: state.racao ? state.racao.value : '',
            lote_id: state.lote ? state.lote.value : '',
            tanque_id: state.tanque ? state.tanque.value : '',
            data_ini: state.data_ini ? Formatter.dateToString(state.data_ini, '/') : undefined,
            data_fim: state.data_fim ? Formatter.dateToString(state.data_fim, '/') : undefined,
          },
        });
        window.open(pdf.request.responseURL, '_blank');
      }
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }

  function limparFiltros(): void {
    state.lote = null;
    state.racao = null;
    state.tanque = null;
    state.data_ini = null;
    state.data_fim = null;
    state.rows = [];
    state.totais = 0;
  }
  const handleFinanceChecked = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFinanceChecked(event.target.checked);
  };
  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Listagem de Ração por Tanque'), []);

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  return state.sync ? (
    <NewBody>
      <Flex style={{ justifyContent: 'space-between' }}>
        {/* Navegacao Anterior */}
        <Link
          to={'/relatorios/relatorios-zootecnicos'}
          style={{ color: '#049CE7', textDecoration: 'none', fontFamily: 'Roboto' }}
        >
          <Flex>
            <img src={'/images/returnArrow.svg'} />
            <div style={{ paddingLeft: '5px', fontFamily: 'Roboto', color: '#049CE7' }}>Lista de Relatórios</div>
          </Flex>
        </Link>

        {/* Titulo */}
        <h2>
          Lote/Tanque: {state.lote ? `Lote:  ${state.lote.label}` : ''}{' '}
          {state.tanque ? `Lote:  ${state.codigo} ${'-'} Tanque:  ${state.tanque.label}` : ''}{' '}
        </h2>

        <Flex>
          <Tooltip title="Exportar para CSV">
            <IconButton className="blue" onClick={getCSV}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Baixar PDF">
            <IconButton className="blue" onClick={getPDF}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sincronizar">
            <IconButton
              className="blue"
              onClick={() => {
                sync();
                getRelatorio();
              }}
            >
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>

      <div style={{ display: 'grid', gridTemplateColumns: '300px 1fr', gap: 10 }}>
        <Conteudo>
          <div style={{ width: '100%', marginBottom: 10 }}>
            <Botao disabled={state.tanque !== null} onClick={() => modalSelecionarLote.open()}>
              Escolha um Lote
            </Botao>
          </div>

          <div style={{ width: '100%', marginBottom: 10 }}>
            <Botao disabled={state.lote !== null} onClick={() => modalTanques.open()}>
              Escolha um Tanque
            </Botao>
          </div>
          <Select
            value={state.racao}
            placeholder="Ração"
            onChange={state.setRacao}
            options={store.racoes.getSelectRows()}
          />

          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Inicial"
            value={state.data_ini}
            onChange={(date) => (state.data_ini = date!)}
            style={{ width: '100%' }}
          />

          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Final"
            value={state.data_fim}
            onChange={(date) => (state.data_fim = date!)}
            style={{ width: '100%' }}
          />

          <FormControlLabel
            control={<Checkbox color="default" checked={financeChecked} onChange={handleFinanceChecked} />}
            label="Lançado"
          />

          <Botao onClick={getRelatorio} cor="#00C853">
            Gerar Relatório
          </Botao>
          <Botao onClick={limparFiltros} cor="#FC7467">
            Limpar Filtros
          </Botao>
        </Conteudo>
        <Conteudo style={{ padding: 0, maxWidth: '1fr', overflow: 'auto' }}>
          <MaterialTable
            columns={columns}
            data={state.rows}
            options={{
              ...newConfigRacoes.options,
              paging: false,
            }}
            localization={newConfigRacoes.localization}
            style={newConfigRacoes.style}
          />
          <Flex
            style={{
              width: '100%',
              padding: '1rem',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0 auto',
              marginBottom: '-15px',
              border: '1px solid #999',
            }}
          >
            <p>TOTAL RAÇÕES</p>

            <strong>{Formatter.formatNumber(3, state.totais)} </strong>
          </Flex>
        </Conteudo>

        {/* <Conteudo overflowY>
          <ReactApexChart options={graficoDonut.options} series={graficoDonut.series} type="donut" height="300px" />
        </Conteudo> */}
      </div>

      <SelecionarLote
        state={state}
        open={modalSelecionarLote.state}
        handleClose={modalSelecionarLote.close}
        onItemClick={(lote) => {
          state.tanques = [];
          state.lote = { label: lote.codigo, value: lote.id };
          state.id = {
            lote: lote.id,
            tanque: '',
          };
        }}
        loteID={state.lote ? parseInt(state.lote.value) : null}
      />

      <SelecionarLoteTanques
        open={modalTanques.state}
        handleClose={modalTanques.close}
        setTanque={(tanque) => {
          state.tanque = { label: tanque.label, value: tanque.tanqueId2 };
          state.id = {
            lote: '',
            tanque: tanque.loteTanque,
          };
          state.codigo = tanque.tanque;
        }}
        setLote={(lote) => console.log(lote)}
        state={state}
        exclude={[]}
      />
    </NewBody>
  ) : null;
};

export default observer(RacaoTanque);
