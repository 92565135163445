import styled from 'styled-components';

export const PerfilForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 100px;
  height: 100%;

  .MuiInputBase-root {
    margin: 15px 0;
  }
  .MuiFormControl-root {
    width: 100%;
  }
`;

export const FormTitle = styled.div`
  display: flex;
  color: #42b5f5;
  font-weight: normal;
  font-size: 35px;

  svg {
    font-size: 38px;
  }
`;
