import React, { useEffect } from 'react';
import useStore from '../../../services/hooks/useStore';
import { useLocalStore, observer } from 'mobx-react-lite';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import Botao from '../../../components/Botoes/Botao';
import InputNumero from '../../../components/InputNumero';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Select from '../../../components/Select';
import { BotoesContainer } from '../../Login/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Formatter from '../../../services/Formatter';
import useModal from '../../../services/hooks/useModal';
import SelecionarFornecedor from '../Movimentacoes/SelecionarFornecedor';
type Props = ModalProps & {
  chequeID: number;
};
const FormCheque: React.FC<Props> = ({ open, handleClose, chequeID }) => {
  const store = useStore();
  const cheque = chequeID ? store.cheques.get(chequeID) : null;
  const modalFornecedor = useModal();
  const sintuation = [
    { label: 'Aguardando', value: 'aguardando' },
    { label: 'Depositado', value: 'depositado' },
    { label: 'Reapresentado', value: 'reapresentado' },
  ];

  const state = useLocalStore(() => ({
    propriedade: cheque ? cheque.propriedade : 0,
    data_recebido: cheque?.data_recebido ? new Date(cheque?.data_recebido) : null,
    cliente: cheque ? { label: cheque.cliente.nome, id: cheque.cliente.id, value: cheque.cliente.id } : null,
    banco: cheque ? cheque.banco : '',
    n_folha: cheque ? cheque.n_folha : '',
    nome_emitente: cheque ? cheque.nome_emitente : '',
    valor: cheque ? cheque.valor : 0,
    data_deposito: cheque?.data_deposito ? new Date(cheque?.data_deposito) : null,
    fornecedor: cheque?.fornecedor
      ? { label: cheque.fornecedor.nome, id: cheque.fornecedor.id, value: cheque.fornecedor.id }
      : (null as SelectRow | null),
    voltou: cheque ? cheque.voltou : false,
    situacao: cheque
      ? { label: cheque.situacao, value: cheque.situacao }
      : { label: 'Aguardando', value: 'aguardando' },
    data_situacao: cheque?.data_situacao ? new Date(cheque?.data_situacao) : null,
    observacao: cheque ? cheque.observacao : '',
  }));

  async function cadastrar(): Promise<void> {
    let error = false;
    if (state.cliente === null) {
      store.notificar('Informe o cliente');
      error = true;
    }
    if (state.banco === '') {
      error = true;
      store.notificar('Informe o Codigo do banco');
    }
    if (state.n_folha === '') {
      error = true;
      store.notificar('Informe o numero da folha');
    }
    if (state.nome_emitente === '') {
      error = true;
      store.notificar('Informe o nome Eminente');
    }
    if (state.valor === 0) {
      error = true;
      store.notificar('Informe o valor do cheque');
    }
    if (error) {
      return;
    }

    store.toggleLoader();

    const data: any = {
      id: chequeID,
      propriedade: store.propriedade?.id,
      data_recebido: Formatter.dateToString(state.data_recebido as MaterialUiPickersDate),
      cliente: state.cliente?.id,
      banco: state.banco,
      n_folha: state.n_folha,
      nome_emitente: state.nome_emitente,
      valor: state.valor,
      data_deposito:
        state.data_deposito !== null ? Formatter.dateToString(state.data_deposito as MaterialUiPickersDate) : undefined,
      fornecedor: state.fornecedor !== null ? parseInt(state.fornecedor?.value as any) : null,
      voltou: state.voltou,
      situacao: state.situacao?.value,
      data_situacao:
        state.data_situacao !== null ? Formatter.dateToString(state.data_situacao as MaterialUiPickersDate) : undefined,
      observacao: state.observacao,
    };

    chequeID ? await store.cheques.editar(chequeID, data) : await store.cheques.criar(data);

    store.toggleLoader();

    handleClose();
  }

  useEffect(() => {
    store.clientes.populate();
    store.fornecedores.populate();
  }, []);

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{chequeID ? 'Editar' : 'Novo'} cheque</ModalHeader>

      <ModalBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data do recebimento"
          value={state.data_recebido}
          onChange={(date) => (state.data_recebido = date!)}
        />

        {store.clientes?.arr && (
          <Select
            placeholder="Escolha um Cliente"
            value={state.cliente as any}
            options={store.clientes?.arr.map((programa) => {
              return { label: programa.nome, value: programa.uuid, id: programa.id };
            })}
            onChange={(e: any) => {
              state.cliente = e;
            }}
          />
        )}

        <TextField
          fullWidth
          label="Código do banco"
          variant="standard"
          name="codigo_banco"
          value={state.banco}
          onChange={(e) => (state.banco = e.target.value)}
        />

        <TextField
          fullWidth
          label="Número da folha"
          variant="standard"
          name="n_folha"
          value={state.n_folha}
          onChange={(e) => (state.n_folha = e.target.value)}
        />

        <TextField
          fullWidth
          label="Nome Emitente"
          variant="standard"
          name="nome_emitente"
          value={state.nome_emitente}
          onChange={(e) => (state.nome_emitente = e.target.value)}
        />

        <InputNumero
          value={state.valor}
          onChange={(e) => (state.valor = e)}
          precision="2"
          label="Valor do cheque"
          id={'teste'}
        />

        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data para depósito"
          value={state.data_deposito}
          onChange={(date) => (state.data_deposito = date!)}
        />

        <Select
          placeholder="Foi Para Quem?"
          value={state.fornecedor as any}
          onFocus={(e) => {
            e.target.blur();
            modalFornecedor.open();
          }}
          options={[]}
        />

        <FormControlLabel
          labelPlacement="start"
          control={
            <Checkbox checked={state.voltou} onChange={(e) => (state.voltou = e.target.checked)} color="primary" />
          }
          label="Voltou?"
        />

        <Select
          placeholder="Situação "
          value={state.situacao as any}
          options={sintuation}
          onChange={(e: any) => {
            state.situacao = e;
          }}
        />

        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data da situação"
          value={state.data_situacao}
          onChange={(date) => (state.data_situacao = date!)}
        />

        <TextField
          fullWidth
          label="Observação"
          variant="standard"
          name="obs"
          value={state.observacao}
          onChange={(e) => (state.observacao = e.target.value)}
        />

        {modalFornecedor.state ? (
          <SelecionarFornecedor
            open={modalFornecedor.state}
            handleClose={modalFornecedor.close}
            onConfirm={(fornecedor) => {
              state.fornecedor = fornecedor;
              modalFornecedor.close();
            }}
          />
        ) : null}
        <BotoesContainer>
          <div style={{ width: 400, display: 'flex' }}>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao onClick={cadastrar} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          </div>
        </BotoesContainer>
      </ModalBody>
    </Modal>
  );
};

export default observer(FormCheque);
