import { getThings } from '../utils';
import { observable, computed } from 'mobx';

class Arracoamentos {
  @observable arr: Arracoamento[] | null = null;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    this.arr = await getThings<Arracoamento>('/arracoamento/');
  }

  async getEverything(params?: object): Promise<Arracoamento[]> {
    return await getThings<Arracoamento>('/arracoamento/', params);
  }

  get(id: number): Arracoamento {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  filter(callback: (item: Arracoamento, index: number, array: Arracoamento[]) => void): Arracoamento[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: Arracoamento, index: number, array: Arracoamento[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default Arracoamentos;
