import React, { useEffect, useState  } from 'react';
import { observer } from 'mobx-react-lite';
import { Body } from '../../../../components/Body';
import MaterialTable, { Column } from 'material-table';
import Formatter from '../../../../services/Formatter';
import useStore from '../../../../services/hooks/useStore';
//import { useHistory } from 'react-router-dom';
import { newConfig } from '../../../../components/Tabela';
import { aqReports, apiMain } from '../../../../services/utils';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Flex } from '../../../../components/NavBar/styles';
import { Button, makeStyles } from '@material-ui/core';
import { ReportButton } from '../styles';
import { Link } from 'react-router-dom';

type RelLista = {
    data: string;
    documento: string;
    nome: string;
    quantidade: number;
    valor_total: number;
    valor_unitario: number;
}


type RelCompras = {
    data: string;
    lista: Array<RelLista>;
    qtd_total: number;
    total: number;
}

const useInputStyles = makeStyles({
    root: {
      width: '12vw',
      verticalAlign: "middle",
      fontSize: "14px",
      border: "solid 1px #303030",
      borderRadius: '5px',
      paddingLeft: '5px',
    }
});

const RelatorioCompraRacoes: React.FC = () => {
    const store = useStore();
    //const history = useHistory();
    //const tableRef = useRef(null);
    
    const [tableData, setTableData] = useState<RelLista[]>([]);
    const [relData, setRelData] = useState<RelCompras[]>([]);

    const [filtroApply, setFiltroApply] = useState(true);
    const currentDate = new Date();
    const initialDate = new Date();
    initialDate.setDate(1);
    const [filtroDate, setFiltroDate] = useState({
        dataInicial: {
            value: initialDate as MaterialUiPickersDate,
            valueFormatted: Formatter.getData(Formatter.dateToString(initialDate)),
        },
        dataFinal: {
            value: currentDate as MaterialUiPickersDate,
            valueFormatted: Formatter.getData(Formatter.dateToString(currentDate)),
        },
    })
    
    
    const columns: Column<RelLista>[] = [
        {
            field: 'nome',
            title: 'Fornecedor',
            type: 'string',
            headerStyle: {
                textAlign: 'left',
                backgroundColor: '#F5F6F8',
                borderRight: '1px solid #CFCFCF',
            },
            cellStyle: {
                textAlign: 'left',
                backgroundColor: '#F5F6F8',
                borderRight: '1px solid #CFCFCF',
            },
            ...(true && ({ width: 140 } as object)),
        },
        {
            field: 'quantidade',
            title: 'Quant.',
            type: 'numeric',
            headerStyle: {
                textAlign: 'center',
                backgroundColor: '#F5F6F8',
                borderRight: '1px solid #CFCFCF',
            },
            cellStyle: {
                textAlign: 'center',
                backgroundColor: '#F5F6F8',
                borderRight: '1px solid #CFCFCF',
            },
            render: (rowData) => {
                return Formatter.formatNumber(0, rowData.quantidade);
            },
            ...(true && ({ width: 60 } as object)),
        },
        {
            field: 'valor_unitario',
            title: 'Vr. Unit. (R$)',
            type: 'numeric',
            headerStyle: {
                textAlign: 'center',
                backgroundColor: '#F5F6F8',
                borderRight: '1px solid #CFCFCF',
            },
            cellStyle: {
                textAlign: 'center',
                backgroundColor: '#F5F6F8',
                borderRight: '1px solid #CFCFCF',
            },
            render: (rowData) => {
                return Formatter.formatNumber(2, rowData.valor_unitario);
            },
            ...(true && ({ width: 100 } as object)),
        },
        {
            field: 'valor_total',
            title: 'Valor (R$)',
            type: 'numeric',
            headerStyle: {
                textAlign: 'center',
                backgroundColor: '#F5F6F8',
                borderRight: '1px solid #CFCFCF',
            },
            cellStyle: {
                textAlign: 'center',
                backgroundColor: '#F5F6F8',
                borderRight: '1px solid #CFCFCF',
            },
            render: (rowData) => {
                return Formatter.formatNumber(2, rowData.valor_total);
            },
            ...(true && ({ width: 100 } as object)),
        },
        {
            field: 'documento',
            title: 'Num. Documento',
            type: 'string',
            headerStyle: {
                textAlign: 'center',
                backgroundColor: '#F5F6F8',
                borderRight: '1px solid #CFCFCF',
            },
            cellStyle: {
                textAlign: 'center',
                backgroundColor: '#F5F6F8',
                borderRight: '1px solid #CFCFCF',
            },
            ...(true && ({ width: 100 } as object)),
        },
        {
            field: 'data',
            title: 'Data',
            type: 'string',
            headerStyle: {
                textAlign: 'left',
                backgroundColor: '#F5F6F8',
            },
            cellStyle: {
                textAlign: 'left',
                backgroundColor: '#F5F6F8',
            },
            ...(true && ({ width: 100 } as object)),
        },
    ]

    const inputClasses = useInputStyles();
    
     // eslint-disable-next-line
    useEffect(() => store.setTitulo('Relatório Financeiro'), []);

    useEffect(() => {
        async function syncTable(){
            aqReports.get('/financeiro/' + store.propriedade?.uuid + '/compra-racao', {
                params: {
                    data_inicio: filtroDate.dataInicial.valueFormatted,
                    data_fim: filtroDate.dataFinal.valueFormatted,
                }
            }).then((res) => {
                let tableList: RelLista[] = [];
                let relList: RelCompras[] = [];
                const results = res.data.results;
                results.forEach((element: RelCompras) => {
                    relList.push(element);
                    element.lista.forEach((lista: RelLista) => {
                        tableList.push(lista)
                    })
                });
                setTableData(tableList);
                setRelData(relList);
            });
        }
        
        if(filtroApply && store.propriedade){
            syncTable();
            setFiltroApply(false);
        }
        
        // eslint-disable-next-line
    }, [store.propriedade, filtroApply])

    
    useEffect(() => {
        console.log(tableData);
        console.log(relData);
    }, [tableData, relData])

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function getPDF() {
        try {
        const pdf = await apiMain.get(
            `/gerador/pdf/financeiro/${store.propriedade?.id}/compra-racao/`,
            {
            params: {
                data_inicio: filtroDate.dataInicial.valueFormatted,
                data_fim: filtroDate.dataFinal.valueFormatted,
                authori: store.token,
            },
            },
        );
        window.open(pdf.request.responseURL, '_blank');
        } catch {
        store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function getCSV() {
        try {
        const pdf = await apiMain.get(
            `/gerador/csv/financeiro/${store.propriedade?.id}/compra-racao/`,
            {
            params: {
                data_inicio: filtroDate.dataInicial.valueFormatted,
                data_fim: filtroDate.dataFinal.valueFormatted,
                authori: store.token,
            },
            },
        );
        window.open(pdf.request.responseURL, '_blank');
        } catch {
        store.notificar('Houve algum problema ao gerar o CSV! Favor entrar em contato com o Suporte.');
        }
    }
    

    return(
        <>
            <Body style={{backgroundColor: 'white'}}>
                <Link to={"/financeiro/painel/relatorio-financeiro"} style={{color: '#049CE7', textDecoration: 'none', fontFamily: 'Roboto'}}>
                    <Flex>
                        <img src={"/images/returnArrow.svg"}/>
                        <div style={{paddingLeft: '5px', fontFamily: 'Roboto', color: '#049CE7'}}>
                            Lista de Relatórios
                        </div>
                    </Flex>
                </Link>
                <div style={{fontWeight: 'bold' , fontSize: '24px', height: '30px'}}>
                    Relatório de Compras de Ração
                </div>
                <Flex style={{width: '100vw', height: '10vh'}}>
                    <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={filtroDate.dataInicial.value}
                    onChange={(date) => {setFiltroDate({
                        ...filtroDate, 
                        dataInicial: {
                            value: date,
                            valueFormatted: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                        },
                    })}}
                    InputProps={{ classes: inputClasses }}
                    />
                    <div style={{fontSize: '18px', textAlign: 'center', width: '5vw',  height: '10vh', padding: '4vh 0'}}>
                        até
                    </div>
                    <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={filtroDate.dataFinal.value}
                    onChange={(date) => {setFiltroDate({
                        ...filtroDate, 
                        dataFinal: {
                            value: date,
                            valueFormatted: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                        },
                    })}}
                    InputProps={{ classes: inputClasses }}
                    />
                    <div style={{justifyContent: 'right', textAlign: 'center',  height: '10vh', padding: '3vh 20px'}}>
                    <ReportButton onClick={() => setFiltroApply(true)}>
                            Gerar Relatório
                    </ReportButton>
                    </div>
                    <div style={{justifyContent: 'right', padding: '220px'}}>
                        <Button className="blue" onClick={getPDF}>
                        <Flex>
                            <div style={{paddingRight: '10px', fontFamily: 'Roboto', color: '#303030', fontSize: '12px'}}>
                                Exportar PDF
                            </div>
                            <img src={"/images/exportPDF.svg"}/>
                        </Flex>
                        </Button>
                        <Button className="blue" onClick={getCSV}>
                        <Flex>
                            <div style={{paddingRight: '10px', fontFamily: 'Roboto', color: '#303030', fontSize: '12px'}}>
                                Exportar CSV
                            </div>
                            <img src={"/images/exportCSV.svg"}/>
                        </Flex>
                        </Button>
                    </div>
                </Flex>
            </Body>        
            <Body style={{padding:'0px'}}>
                <MaterialTable
                    columns={columns}
                    data={tableData}
                    options={{
                        ...newConfig.options,
                        maxBodyHeight: window.innerHeight - 300,
                        minBodyHeight: window.innerHeight - 300,
                        paging: true,
                        sorting: false,
                        pageSize: 10,
                      }}
                    localization={newConfig.localization}
                    style={{...newConfig.style, backgroundColor: '#F5F6F8'}}
                />    
            </Body>              
        </>
    );
}

export default observer(RelatorioCompraRacoes)