import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { observer, useLocalStore } from 'mobx-react-lite';
import Botao from '../../../components/Botoes/Botao';
import useStore from '../../../services/hooks/useStore';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { api } from '../../../services/utils';

type State = {
  faseLote: number;
  novaFase: number;
  loteTanques: LoteTanque[];
};
type Props = ModalProps & {
  onConfirm(): void;
};
const AtualizarFase: React.FC<Props> = ({ open, handleClose, onConfirm }) => {
  const store = useStore();
  const state = useLocalStore(
    (): State => ({
      faseLote: 0,
      novaFase: 0,
      loteTanques: [],
    }),
  );


  useEffect(() => {
    if (store.biometrias.arr) {
      const lastBiometria = store.biometrias.arr[0];

      if (lastBiometria) {
        const fase = store.lotes.get(lastBiometria.lote.id)?.fase_lote.id;
        const newLoteTanques: LoteTanque[] = [];

        fase && (state.faseLote = fase);
        fase && (state.novaFase = fase);

        lastBiometria.lote_tanques.map((loteTanqueGenerico) => {
          const loteTanque = store.loteTanque.get(loteTanqueGenerico.id);

          if (loteTanque) {
            return newLoteTanques.push(loteTanque);
          } else return null;
        });
        state.loteTanques = newLoteTanques;
      }
    }
    // eslint-disable-next-line
  }, [store.biometrias.arr]);

  function refreshTable(){
    store.toggleLoader();
    store.notificar('Biometria cadastrada !')
    store.toggleLoader();
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Deseja atualizar a fase do lote?</ModalHeader>
      <ModalBody style={{ minWidth: '400px' }}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Selecione a nova fase abaixo:</FormLabel>
          <RadioGroup
            value={state.novaFase}
            onChange={(e) => {
              state.novaFase = parseInt(e.target.value);
            }}
          >
            {store.fasesLote.getSelectRows().map((fase, indice) => {
              const disabled = (): boolean => {
                if (state.faseLote) {
                  return indice < store.fasesLote.arr!.findIndex((fase) => fase.id === state.faseLote);
                } else {
                  return false;
                }
              };
              return (
                <FormControlLabel
                  key={fase.value}
                  disabled={disabled()}
                  checked={disabled() || state.novaFase === parseInt(fase.value)}
                  value={fase.value}
                  control={<Radio color="primary" />}
                  label={fase.label}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={() => {
            handleClose(),
            refreshTable()
          }} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao
            onClick={async () => {
              handleClose();
              store.toggleLoader();
              await api.patch(`/lote/${state.loteTanques[0].lote.uuid}/?propriedade=${store.propriedade!.id}`, {
                fase_lote: store.fasesLote.get(state.novaFase)?.id,
              });
              state.loteTanques?.map((loteTanque) => {
                api.put(`/lote-tanque/${loteTanque.id}/?propriedade=${store.propriedade?.id}`, {
                  fase_lote: state.novaFase,
                  id: loteTanque.id,
                  tanque: loteTanque.tanque.id,
                  lote: loteTanque.lote.id,
                });
              });
              await store.lotes.populate();
              store.toggleLoader();
              window.location.reload();
              onConfirm();
            }}
            variant="contained"
            cor="#00C853"
          >
            Salvar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(AtualizarFase);
