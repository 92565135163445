import React, { useState } from 'react';
import { Popper, Grow, Paper, ClickAwayListener, SvgIconTypeMap } from '@material-ui/core';
import { LeftButton, RightButton, RoundedButton, BtnGroup } from './styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

type Props = {
  children?: JSX.Element;
  text: string;
  dropdown?: boolean;
  onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  disabled?: boolean;
};
const AddBtn: React.FC<Props> = ({ children, text, dropdown, onClick, Icon, disabled }) => {
  const [state, setState] = useState(false);
  const anchorRef = React.useRef(null);

  if (dropdown) {
    const handleToggle = (): void => {
      setState((prevState) => !prevState);
    };

    const handleClose = (): void => {
      setState(false);
    };
    return (
      <>
        <BtnGroup variant="contained" color="primary" ref={anchorRef}>
          <LeftButton disabled={disabled} onClick={onClick}>
            {Icon && <Icon />}
            <p>{text}</p>
          </LeftButton>
          <RightButton disabled={disabled} color="primary" size="small" onClick={handleToggle}>
            <ArrowDropDownIcon />
          </RightButton>
        </BtnGroup>
        <Popper
          open={state}
          style={{ zIndex: 100 }}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>{children}</ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  } else {
    return (
      <RoundedButton disabled={disabled} onClick={onClick}>
        {Icon && <Icon />}
        {text}
      </RoundedButton>
    );
  }
};

export default AddBtn;
