import { getThingsV2 } from '../utils';
import { observable, computed } from 'mobx';

class Marcas {
  @observable arr: Marca[] = [];
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    this.arr  = await getThingsV2<Marca>('/marcas/'); 
  }

  async getEverything(params?: object): Promise<Marca[]> {
    return await getThingsV2<Marca>('/marcas/', params);
  }

  get(id: number): Marca | undefined {
    return this.arr.find((item) => item.id === id);
  }

  getSelectRows(): SelectRow[] {
    return this.arr!.slice()
      .sort((a: Marca, b: Marca) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      })
      .map((item: Marca) => {
        return { label: item.nome, value: item.id.toString() };
      });
  }

  sort(callback: (a: any, b: any) => any): any {
    return this.arr!.sort(callback);
  }

  filter(callback: (item: Marca, index: number, array: Marca[]) => void): Marca[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: Marca, index: number, array: Marca[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default Marcas;
