import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from './styles';
import { apiV2 } from '../../services/utils';
import useStore from '../../services/hooks/useStore';
import { TextField } from '@material-ui/core';
import Formatter from '../../services/Formatter';
import { BotoesContainer, LabelCheched, LoginChecked } from '../../pages/Login/styles';
import { Flex } from '../NavBar/styles';
import useModal from '../../services/hooks/useModal';
import CadastroEfetuado from './CadastroEfetuado';
import Select from '../Select';
import { observer, useLocalStore } from 'mobx-react-lite';
import Botao from '../Botoes/Botao';

type Props = ModalProps & {
  data: any;
  options: any;
};

type State = {
  estado: SelectRow | null;
  cidade: SelectRow | null;
};

const labelCheched = ['Engorda', 'Alevino / Juvenil', 'Reproducao', 'Carnicultura', 'Agricultura / Familiar'];

const NovaPropriedade: React.FC<Props> = ({ open, handleClose, data, options }) => {
  const modal = useModal();
  const store = useStore();

  const state = useLocalStore((): State => ({ estado: null, cidade: null }));

  const [newProperty, setNewProperty] = useState({
    name: '',
    state: '',
    city: '',
  });

  const [checkActivity, setCheckActivity] = useState({
    engorda: false,
    alevinojuvenil: false,
    reproducao: false,
    carnicultura: false,
    agriculturafamiliar: false,
  });

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setNewProperty({
      ...newProperty,
      [e.target.name]: e.target.value,
    });
  }

  function closeModais(): void {
    localStorage.removeItem('dadosCadastro');
    handleClose();
    modal.close();
  }

  async function enviarCadastro(): Promise<void> {
    let i = 0;
    let check = true;
    let checkField: any = checkActivity;
    const activity: Number[] = [];
    store.toggleLoader();

    for (let field in checkField) {
      if (checkField[field]) {
        activity.push(i + 1);
      }

      i++;
    }

    // checar se os campos estão preenchidos
    checkField = newProperty;

    for (let field in checkField) {
      if (!checkField[field]) {
        check = false;
        store.notificar('Insira os dados corretamente');
        break;
      }
    }

    if (check) {
      const dadosCadastro = { usuario: data, propriedade: newProperty };

      const registerData = {
        usuario: {
          nome: dadosCadastro.usuario.name,
          inscricao_federal: Formatter.getNumeros(dadosCadastro.usuario.inscricaoFederal),
          email: dadosCadastro.usuario.email,
          telefone: Formatter.getNumeros(dadosCadastro.usuario.tel),
          tipo_de_usuario_id: parseInt(dadosCadastro.usuario.perfil),
          use_terms: dadosCadastro.usuario.terms,
          senha: dadosCadastro.usuario.password,
        },
        propriedade: {
          nome: dadosCadastro!.propriedade.name,
          estado_id: parseInt(dadosCadastro!.propriedade.state),
          cidade_id: parseInt(dadosCadastro!.propriedade.city),
          atividades: activity,
        },
      };

      try {
        await apiV2
          .post('/usuario/', registerData)
          .then(() => {
            store.notificar('Verifique seu e-mail');
            modal.open();
          })
          .catch((e) => {
            e.response.data.inscricao_federal !== '' &&
              store.notificar(e.response.data.inscricao_federal.replace('_', ' '));
          });
      } catch {
        store.notificar('Usuario com email ja cadastrado');
      }
    }

    store.toggleLoader();
  }

  return (
    <Modal onClose={handleClose} open={open} style={{ display: modal.state ? 'none' : 'block' }}>
      <ModalHeader>Nova Propriedade</ModalHeader>
      <ModalBody>
        <TextField
          fullWidth
          label="Nome da Propriedade"
          variant="standard"
          name="name"
          value={newProperty.name}
          onChange={handleChange}
        />
        <Select
          value={state.estado}
          placeholder="Estados"
          onChange={(e: any) => {
            state.cidade = null;
            state.estado = e;
            setNewProperty({
              ...newProperty,
              state: e.value,
            });
          }}
          options={options!.estados.map((estado: { nome: any; id: any }) => {
            return {
              label: estado.nome,
              value: `${estado.id}`,
            };
          })}
        />
        <Select
          value={state.cidade}
          placeholder="Cidades"
          onChange={(e: any) => {
            state.cidade = e;
            setNewProperty({
              ...newProperty,
              city: e.value,
            });
          }}
          options={options!.cidades
            .filter((cidade: { estado: { id: any } }) => `${cidade.estado.id}` === newProperty.state)
            .sort((a: any, b: any) => (a.nome > b.nome ? 1 : a.nome < b.nome ? -1 : 0))
            .map((cidade: { nome: any; estado: { id: any } }) => {
              return {
                label: cidade.nome,
                value: `${cidade.estado.id}`,
              };
            })}
        />
        <BotoesContainer style={{ marginTop: 10 }}>
          <p>Quais os tipos de atividades?</p>
          {labelCheched.map((el, i) => {
            const check: any = checkActivity;
            const nameCheck = el.replace(' / ', '').toLocaleLowerCase();
            const value = () => {
              for (let field in check) {
                if (nameCheck === `${check[field]}`) {
                  return check[field];
                }
              }
            };

            return (
              <LabelCheched style={{ margin: '6px 0 0 10px' }} key={i}>
                <LoginChecked
                  type="checkbox"
                  name={nameCheck}
                  checked={value()}
                  onChange={(e) => setCheckActivity({ ...checkActivity, [e.target.name]: e.target.checked })}
                />
                <span>{el}</span>
              </LabelCheched>
            );
          })}
          <Flex style={{ justifyContent: 'space-around', marginTop: 22 }}>
            <Botao variant="contained" cor="#FC7467" onClick={handleClose}>
              Cancelar
            </Botao>
            <Botao variant="contained" cor="#00C853" onClick={enviarCadastro}>
              Avançar
            </Botao>
          </Flex>
        </BotoesContainer>
      </ModalBody>

      <CadastroEfetuado open={modal.state} handleClose={closeModais} />
    </Modal>
  );
};

export default observer(NovaPropriedade);
