import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import Formatter from '../services/Formatter';

type Props = {
  numero: number;
  precision: number;
  unidade: string;
  bold?: boolean;
  red?: boolean;
  small?: boolean;
};
const LargeNumber: React.FC<Props> = ({ numero, unidade, precision, bold, red, small }) => {
  const result = Formatter.formatNumber(precision, numero);
  const maxWidth = unidade ? '76%' : '100%';
  const color = red ? 'red' : 'white';
  return (
    <FlexBox small={small}>
      <Tooltip title={result}>
        {bold ? <h2 style={{ maxWidth, color }}>{result}</h2> : <p style={{ maxWidth, color }}>{result}</p>}
      </Tooltip>
      <p>{unidade}</p>
    </FlexBox>
  );
};

export default LargeNumber;

type FlexProps = {
  small?: boolean;
};
const FlexBox = styled.div<FlexProps>`
  width: 100%;
  display: flex;
  justify-content: center;

  p {
    font-weight: 500;
    font-size: ${(props) => (props.small ? '16px' : '32px')};
    font-family: 'liberation-sans', 'Roboto', sans-serif;
    margin: 0 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
