import React from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import useStore from '../services/hooks/useStore';
import CloseIcon from '@material-ui/icons/Close';
import { observer } from 'mobx-react-lite';

const Notificacao: React.FC = () => {
  const store = useStore();

  function handleClose(): void {
    store.notificar('');
  }

  return (
    <Snackbar
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      key={'bottom, center'}
      open={store.notification ? true : false}
      onClose={handleClose}
      message={store.notification}
      style={{ zIndex: 99999999999 }}
      action={
        <>
          <IconButton size="small" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};

export default observer(Notificacao);
