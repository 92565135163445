import React, { useState } from 'react';
import { FiltroContainer, FiltroHeader, FiltroContent, FiltroShadow } from './styles';
import BotaoFiltro from '../Botoes/BotaoFiltro';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';
import { BtnGroup, LeftButton, RightButton } from '../Botoes/styles';

type Props = {
  clear(): void;
  sync(): void;
  display?: boolean;
};
const Filtro: React.FC<Props> = ({ clear, sync, children, display }) => {
  const [state, setState] = useState(false);
  const [visibility, setVisibility] = useState(false);

  function handleClose(): void {
    setState(false);
    setTimeout(() => {
      setVisibility(false);
    }, 200);
  }
  return (
    <>
      <BotaoFiltro sync={sync} clear={clear} setState={setState} setVisibility={setVisibility} display={display} />
      <FiltroContainer visibility={visibility ? visibility.toString() : undefined} open={state}>
        <FiltroHeader>
          <IconButton onClick={handleClose}>
            <ArrowBackIcon />
          </IconButton>
          <h2>Filtros</h2>
        </FiltroHeader>
        <FiltroContent>{children}</FiltroContent>
        <BtnGroup
          className="filtro"
          variant="contained"
          color="primary"
          style={{ width: '100%', justifyContent: 'center' }}
        >
          <LeftButton
            cor="#21ba45"
            size="small"
            onClick={() => {
              sync();
              handleClose();
            }}
          >
            Aplicar
          </LeftButton>
          <RightButton cor="#e57373" onClick={clear}>
            Limpar Filtro
          </RightButton>
        </BtnGroup>
      </FiltroContainer>
      <FiltroShadow onClick={handleClose} state={state} />
    </>
  );
};

export default Filtro;
