import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { useLocalStore, observer } from 'mobx-react-lite';
import useStore from '../../../services/hooks/useStore';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Botao from '../../../components/Botoes/Botao';
import { api, apiV2 } from '../../../services/utils';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import useModal from '../../../services/hooks/useModal';
import Select from '../../../components/Select';
import SelecionarLote from '../../../components/Modais/SelecionarLote';
import { returnQuantidade } from '../../../services/utils/producao';
import Formatter from '../../../services/Formatter';
import InputNumero from '../../../components/InputNumero';
import SelecionarLoteTanquesBiometria from '../Arracoamento/SelecionarLoteTanquesBiometria';

type Props = ModalProps & {
  onConfirm(): void;
};
const FormBiometria: React.FC<Props> = ({ open, handleClose }) => {
  const store = useStore();
  const state = useLocalStore(() => ({
    lote: null as SelectRow | null,
    data: new Date() as MaterialUiPickersDate,
    dataUltima: new Date() as MaterialUiPickersDate,
    tanques: [] as SelectedTanque[],
    amostragens: [
      {
        tara: 0,
        qtd_peixes: 1,
        peso: 0,
        media: 0,
      },
    ],
    sync: false,
    media: 0,
    estoqueAtual: 0,
    ultimoPesoMedio: 0,
    racaoConsumidaPeriodo: 0,
    pesoMedioCalculado: 0,
    loteTanque: null as SelectRow | null,
    ca: 0,

    selectLote: false,
  }));
  const modalTanques = useModal();
  const modalSelecionarLote = useModal();
  const [loteTanques, setLoteTanques] = useState<LoteTanque[]>([] as LoteTanque[]);
  const [lote, setLote] = useState<Lote[]>([] as Lote[]);
  async function cadastrar(): Promise<void> {
    handleClose();
    store.toggleLoader();
    const loteTanques: number[] = [];
    state.tanques.map((tanque) => {
      const loteTanque = store.loteTanque
        .getByTanque(tanque.item.id)
        ?.filter((loteTanque) => loteTanque.lote.id === parseInt(state.lote!.value))[0];
      if (loteTanque) return loteTanques.push(loteTanque.id);
      else return null;
    });

    const amostragens: any[] = [];

    state.amostragens.map(() =>
      amostragens.push({
        tara: 0,
        peso: parseInt((state.pesoMedioCalculado * 1000).toFixed(3)),

        qtd_peixes: 1,
        media: (state.pesoMedioCalculado * 1000).toFixed(3),
      }),
    );

    const data = {
      lote_tanques: [state.loteTanque!.value],
      data: Formatter.dateToString(state.data),
      historico_amostragens: amostragens,
    };

    await api
      .post(`/biometrias/?propriedade=${store.propriedade!.id}`, data)
      .then(async () => {
        await store.biometrias.populate();
        store.notificar('Biometria cadastrada com sucesso!');
      })
      .catch(() => {
        store.notificar('Ocorreu um erro no cadastro, tente novamente!');
      });
    store.toggleLoader();
  }
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function searchLote() {
      store.toggleLoader();

      await apiV2
        .get('/tanque/modals/', {
          params: {
            prop: store.propriedade!.id,
          },
        })
        .then((res) => {
          setLote(res.data.results);
        });

      state.sync = true;
      store.toggleLoader();
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function searchTanque() {
      store.toggleLoader();
      if (state.lote) {
        await apiV2
          .get('/lote-tanque/modals/', {
            params: {
              propriedade: store.propriedade!.id,
              lote: state.lote.value,
            },
          })
          .then((res) => {
            setLoteTanques(res.data.results);
          });
      } else {
        await apiV2
          .get('/lote-tanque/modals/', {
            params: {
              propriedade: store.propriedade!.id,
            },
          })
          .then((res) => {
            setLoteTanques(res.data.results);
          });
      }
      state.sync = true;
      store.toggleLoader();
    }
    searchTanque();
    searchLote();
  }, [state, store.propriedade, store, state.loteTanque]);
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function Calcular() {
    store.toggleLoader();
    if (state.ca === 0) {
      store.notificar('Campo CA obrigatório');
    } else {
      await apiV2
        .post(`/biometria/ultima-racao/`, {
          lote_tanque: state.loteTanque!.value,
          data: Formatter.dateToString(state.data),
        })
        .then((response) => {
          if (response.data.historico_id === null) {
            store.notificar('Não foi possível realizar o cálculo, pois não existe biometria para esse lote e tanque');
          } else if (response.data.racao_consumida_periodo === 0) {
            store.notificar('Não foi possível realizar o cálculo, pois não existe arraçoamento nesse período');
          } else {
            state.estoqueAtual = response.data.estoque_atual;
            state.ultimoPesoMedio = response.data.ultimo_peso_medio / 1000; // grama pra kilo (kg)
            state.racaoConsumidaPeriodo = response.data.racao_consumida_periodo;

            state.pesoMedioCalculado =
              state.racaoConsumidaPeriodo / state.ca / state.estoqueAtual + state.ultimoPesoMedio;

            const data = response.data.ultima_data;
            const dataPartes = data.split('/');
            const dataObject = new Date(+dataPartes[2], dataPartes[1] - 1, +dataPartes[0]);
            const dataFormatada = dataObject;
            state.dataUltima = dataFormatada;
          }
        });
    }
    store.toggleLoader();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function atualizarEstadoLoteTanque(dado: any) {
    state.loteTanque = { label: dado.tanque.nome + ' ' + dado.lote.codigo, value: dado.id };
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Nova biometria pelo CA</ModalHeader>
      <ModalBody>
        <div style={{ width: '540px' }}>
          <div style={{ width: '100%' }}>
            {lote && (
              <Select
                value={state.loteTanque}
                placeholder="Escolha o tanque"
                onFocus={(e) => {
                  e.target.blur();
                  modalTanques.open();
                }}
                options={lote.map((lote) => {
                  return { value: lote.id.toString(), label: lote.codigo };
                })}
              />
            )}
          </div>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data da biometria"
            value={state.data}
            onChange={(date) => (state.data = date)}
          />

          <InputNumero
            value={state.ca}
            onChange={(value) => {
              state.ca = value;
            }}
            precision="2"
            label="Informe o CA"
          />

          <Botao onClick={Calcular} fullWidth>
            Calcular
          </Botao>

          <KeyboardDatePicker
            disabled
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data da última biometria"
            value={state.dataUltima}
            onChange={(date) => (state.dataUltima = date)}
          />

          <InputNumero value={state.ultimoPesoMedio * 1000} precision="3" disabled label="Ultimo Peso Médio (g)" />

          <InputNumero value={state.estoqueAtual} precision="0" disabled label="Estoque Atual" />

          <div style={{ width: '100%', display: 'flex' }}>
            <div style={{ margin: 10, textAlign: 'center' }}>
              <h2>Ração consumida no período (kg)</h2>
              <h1 style={{ color: '#42a5f5' }}>{Formatter.formatNumber(3, state.racaoConsumidaPeriodo)}</h1>
            </div>
            <div style={{ margin: 10, textAlign: 'center' }}>
              <h2>Peso Médio calculado (g)</h2>
              <h1 style={{ color: '#42a5f5' }}>{Formatter.formatNumber(3, state.pesoMedioCalculado * 1000)}</h1>
            </div>
          </div>

          <div style={{ width: '100%', display: 'flex' }}>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao onClick={cadastrar} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          </div>
        </div>
      </ModalBody>
      {modalTanques.state ? (
        <SelecionarLoteTanquesBiometria
          open={modalTanques.state}
          handleClose={modalTanques.close}
          onConfirm={() => ''}
          exclude={[]}
          atualizarEstadoLoteTanque={atualizarEstadoLoteTanque}
        />
      ) : null}
      {state.sync ? (
        <SelecionarLote
          open={modalSelecionarLote.state}
          handleClose={modalSelecionarLote.close}
          onItemClick={(lote) => {
            state.tanques = [];
            state.lote = { label: lote.codigo, value: lote.id.toString() };
            loteTanques.length > 0 &&
              loteTanques.map((loteTanque) => {
                const estoque = returnQuantidade(loteTanque, false);
                if (estoque) {
                  return state.tanques.push({ item: loteTanque.tanque });
                } else {
                  return null;
                }
              });
          }}
          loteID={state.lote ? parseInt(state.lote.value) : null}
        />
      ) : null}
    </Modal>
  );
};

export default observer(FormBiometria);
