import styled from 'styled-components';

const Header = styled.div`
  background-color: white;
  height: 50px;
  width: 100%;
  box-shadow: 0 0 6px 0 #0000004a;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px;

  h2 {
    font-weight: normal;
  }
`;
export default Header;
