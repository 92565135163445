import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Checkbox } from '@material-ui/core';

import { Modal, ModalHeader, ModalBody } from '../../components/Modais/styles';
import Botao from '../../components/Botoes/Botao';
import { api, apiV2 } from '../../services/utils';
import useStore from '../../services/hooks/useStore';

interface PermissaoUsuario {
  id: number;
  nome: string;
}

interface Permissao {
  id: number;
  uuid: string;
  nome: string;
  slug: string;
  selected: boolean;
}

type Props = ModalProps & {
  usuarioID: number;
};

const ModalAlterarPermissoes: React.FC<Props> = ({ open, handleClose, usuarioID }) => {
  const store = useStore();
  const [listaDePermissoes, setListaDePermissoes] = useState<Permissao[]>([]);
  const [permissoesAtivas, setPermissoesAtivas] = useState<number[]>([]);

  function marcarPermissoes(idPermissao: number) {
    setListaDePermissoes((prev) =>
      prev.map((p) => {
        if (p.id === idPermissao) {
          p.selected = !p.selected;
        }
        return p;
      }),
    );
  }

  async function buscarPermissoes() {
    await api.get('/permissoes/').then((response) => {
      setListaDePermissoes(response.data.map((p: Permissao) => ({ ...p, selected: false })));
    });
  }

  async function buscarPermissoesDoUsuario() {
    await apiV2
      .get(`/usuario/${usuarioID}/permissoes/`, { params: { propriedade: store.propriedade?.id } })
      .then((response) => {
        const IdsPermissoesUsuario: number[] = response.data?.map((p: PermissaoUsuario) => p.id);
        setPermissoesAtivas(IdsPermissoesUsuario);

        setListaDePermissoes((prev) =>
          prev.map((p) => {
            if (IdsPermissoesUsuario.includes(p.id)) {
              return { ...p, selected: true };
            }
            return { ...p, selected: false };
          }),
        );
      });
  }

  async function cancelarEdicao() {
    setListaDePermissoes((prev) =>
      prev.map((p) => {
        if (permissoesAtivas.includes(p.id)) {
          return { ...p, selected: true };
        }
        return { ...p, selected: false };
      }),
    );
    handleClose();
  }

  async function atualizarPermissoes() {
    await apiV2
      .post(
        `/usuario/${usuarioID}/permissoes/`,
        {
          propriedade: store.propriedade?.id,
          permissoes: listaDePermissoes.filter((p) => p.selected).map((p) => p.id),
        },
        {
          params: { propriedade: store.propriedade?.id },
        },
      )
      .then((res) => {
        const dados: PermissaoUsuario[] = res.data;
        const idsNovasPermissoes = dados.map((p) => p.id);
        setPermissoesAtivas(idsNovasPermissoes);
        handleClose();
        store.notificar('Permissões atualizadas com sucesso');
      })
      .catch(() => {
        cancelarEdicao();
        store.notificar('Não foi possível atualizar as permissões, por favor, tente novamente mais tarde.');
      });
  }

  async function sync() {
    await buscarPermissoes();
    await buscarPermissoesDoUsuario();
  }

  useEffect(() => {
    sync();
  }, []);

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Definição de permissões</ModalHeader>
      <ModalBody>
        {listaDePermissoes.map((p) => (
          <Item key={p.uuid}>
            <p>{p.nome}</p>
            <Checkbox color="primary" checked={p.selected} onChange={() => marcarPermissoes(p.id)} />
          </Item>
        ))}
      </ModalBody>
      <Footer>
        <Botao onClick={cancelarEdicao} variant="contained" cor="#FC7467">
          Voltar
        </Botao>
        <Botao onClick={atualizarPermissoes} variant="contained" cor="#00C853">
          Salvar
        </Botao>
      </Footer>
    </Modal>
  );
};

export default observer(ModalAlterarPermissoes);

const Item = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.2rem;
`;

const Footer = styled.footer`
  display: flex;
  width: 100%;
  padding: 1rem;
`;
