/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Header from '../../../components/Header';
import { Flex } from '../../../components/NavBar/styles';
import AddBtn from '../../../components/Botoes/AddBtn';
import { Body } from '../../../components/Body';
import { config, newConfig } from '../../../components/Tabela';
import useStore from '../../../services/hooks/useStore';
import { apiV2, api } from '../../../services/utils';
import Filtro from '../../../components/Filtro';
import MaterialTable, { Column } from 'material-table';
import { useLocalStore, observer } from 'mobx-react-lite';
import useModal from '../../../services/hooks/useModal';
import { returnTanque } from '../../../services/utils/propriedade';
import ActionButton from '../../../components/Tabela/ActionButton';
import FormArracoamento from './FormArracoamento';
import Confirmar from '../../../components/Modais/Confirmar';
import Display from '../../../components/Tabela/Display';
import ReactApexChart from 'react-apexcharts';
import { useHistory } from 'react-router-dom';
// import syncModel from '../../../services/models/Sync';
import X9Logger from '../../../services/X9Logger';
import { Tooltip, Checkbox, MenuList, MenuItem } from '@material-ui/core';
import VerTanques from './VerTanques';
import Formatter from '../../../services/Formatter';
import FormArracoamentoPeriodo from './FormArracoamentoPeriodo';
import { permissoes } from '../../../services/utils/permissoes';

const Arracoamento: React.FC = () => {
  const [arracoamentosPesquisados, setArracoamentosPesquisados] = useState<Arracoamento[] | undefined>(undefined);
  const state = useLocalStore(() => ({
    pageSize: 0,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
    rows: [] as Arracoamento[],
    selectedArracoamento: {} as Arracoamento,
    sync: false,
  }));
  const modalCadastro = useModal();
  const modalCadastroPeriodo = useModal();
  const modalEditar = useModal();
  const modalExclusao = useModal();
  const modalTanques = useModal();
  const tableRef = useRef();
  const store = useStore();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.CONTROLE_RACAO_ARRACOAMENTO_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.CONTROLE_RACAO_ARRACOAMENTO_ESCRITA);

  const garficoBarra = useLocalStore(() => ({
    series: [
      {
        name: 'Estoque',
        data: [10000],
      },
    ],
    options: {
      chart: {
        height: 180,
        type: 'bar',
        id: 'racoes',
        events: {},
      },
      colors: store.colors,
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          formatter: (val: string) => {
            return Formatter.formatNumber(2, val) + ' kg';
          },
        },
      },
    },
  }));

  const columns: Column<object>[] = [
    {
      field: 'racao',
      title: 'Ração',
      width: 150,
    },
    {
      field: 'acoes',
      render: (rowData: any) => (
        <ActionButton
          editar={
            temPermissaoEscrita && rowData.editavel
              ? () => {
                  state.selectedArracoamento = rowData.props;
                  setArracoamentosPesquisados(undefined);
                  modalEditar.open();
                }
              : undefined
          }
          verTanques={
            store.checkPermissions(permissoes.CONTROLE_RACAO_ARRACOAMENTO_LEITURA)
              ? () => {
                  state.selectedArracoamento = rowData.props;
                  modalTanques.open();
                }
              : undefined
          }
          excluir={
            temPermissaoEscrita
              ? () => {
                  state.selectedArracoamento = rowData.props;
                  modalExclusao.open();
                }
              : undefined
          }
        />
      ),
      width: 25,
    },
    {
      field: 'programa',
      title: 'Programa',
      width: 140,
    },
    {
      field: 'data',
      title: 'Data',
      type: 'date',
      width: 100,
    },
    {
      field: 'quantidade',
      title: 'Quantidade (kg)',
      type: 'numeric',
      render: ({ quantidade }: any) => {
        return Formatter.formatNumber(3, quantidade);
      },
      width: 180,
    },
    {
      field: 'lancado',
      title: 'Lançado',
      type: 'boolean',
      filtering: false,
      width: 100,
      render: (rowData: any) => {
        return <Checkbox disabled color="primary" checked={rowData.lancado} />;
      },
    },
    {
      field: 'tanques',
      title: 'Tanques',
      render: (rowData: any) => {
        const tanques = rowData.tanques.join(', ');
        return (
          <Tooltip title={tanques}>
            <span>{tanques.substr(0, 20) + '...'}</span>
          </Tooltip>
        );
      },
      width: 180,
    },
  ];

  async function sync(): Promise<void> {
    store.toggleLoader();
    await store.racoes.populate();
    await store.tanques.populate();
    await store.lotes.populate();
    // await store.loteTanque.populate();
    state.sync = true;
    ApexCharts.exec('racoes', 'updateSeries', [
      {
        data: store.racoes?.map((racao) => {
          const estoque = racao.estoque < 0 ? 0 : racao.estoque;
          return { x: racao.nome, y: estoque };
        }),
      },
    ]);
    store.toggleLoader();
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Arraçoamentos'), []);

  useEffect(() => {
    if (store.propriedade) {
      sync();
      (tableRef.current as any)?.onQueryChange();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  async function deletar(): Promise<void> {
    modalExclusao.close();
    store.toggleLoader();
    await api
      .delete(`/arracoamento/${state.selectedArracoamento?.id}/?propriedade=${store.propriedade?.id}`)
      .then(() => store.notificar('Arraçoamento excluído com sucesso!'));
    store.toggleLoader();

    sync();
    (tableRef.current as any).onQueryChange();
  }

  const ExcluirArracoamento: React.FC = () => {
    let quantidade = 0;
    const tanques: string[] = [];
    state.selectedArracoamento!.tanque_data.map((tanqueData) => {
      quantidade += state.selectedArracoamento?.lancado ? tanqueData.peso : tanqueData.qtd_racao;
      tanques.push(returnTanque(tanqueData.tanque));
      return null;
    });

    return (
      <div style={{ width: '100%' }}>
        <b>Ração: </b> {state.selectedArracoamento!.racao.nome}
        <br />
        <b>Programa: </b> Sem programa
        <br />
        <b>Data: </b> {Formatter.dateToString(new Date(state.selectedArracoamento!.data)).substr(0, 10)}
        <br />
        <b>Quantidade: </b>
        {quantidade && Formatter.formatNumber(2, quantidade)}
        <br />
        <b>Tanques: </b>
        {tanques.join(', ')}
      </div>
    );
  };

  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn disabled={!temPermissaoEscrita} dropdown onClick={modalCadastro.open} text="ADICIONAR" Icon={AddIcon}>
            <MenuList>
              <MenuItem onClick={modalCadastroPeriodo.open}>Por Período</MenuItem>
              <MenuItem onClick={() => history.push('/producao/v2-novo-arracoamento-programa-por-tanque')}>
                Usar Programa
              </MenuItem>
              <MenuItem onClick={() => history.push('/producao/importacao-csv')}>Importar CSV</MenuItem>
            </MenuList>
          </AddBtn>
        </Flex>
        <Flex>
          <Filtro
            sync={async () => {
              await sync();
              (tableRef.current as any).onQueryChange();
            }}
            clear={() => null}
          ></Filtro>
        </Flex>
      </Header>

      <Body gridColumns="1fr 500px">
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          <MaterialTable
            tableRef={tableRef}
            columns={columns}
            data={
              arracoamentosPesquisados
                ? arracoamentosPesquisados
                : (query) =>
                    new Promise((resolve) => {
                      try {
                        store.propriedade &&
                          apiV2
                            .get('/arracoamento/', {
                              params: {
                                page_size: 50,
                                page: query.page + 1,
                                prop: store.propriedade?.id,
                              },
                            })
                            .then((res: any) => {
                              const rows = (arracoamentos: Arracoamento[]): object[] => {
                                return arracoamentos.map((arracoamento) => {
                                  let quantidade = 0;
                                  const tanques: string[] = [];

                                  arracoamento.tanque_data.map((tanqueData) => {
                                    quantidade += arracoamento?.lancado ? tanqueData.peso : tanqueData.qtd_racao;
                                    return tanques.push(returnTanque(tanqueData.tanque));
                                  });

                                  const data = new Date(arracoamento.data);

                                  return {
                                    id: arracoamento?.id,
                                    racao: arracoamento.racao.nome,
                                    programa:
                                      (arracoamento.semana_programa_alimentar &&
                                        arracoamento.semana_programa_alimentar.programa_alimentar) ||
                                      'Sem Programa',
                                    data,
                                    quantidade,
                                    tanques,
                                    editavel: arracoamento.editavel,
                                    lancado: arracoamento.lancado,
                                    props: arracoamento,
                                  };
                                });
                              };

                              state.rows = res.data.results;

                              if (res.data.count) {
                                if (res.data.count >= 50) {
                                  state.setPageSize(50);
                                } else {
                                  state.setPageSize(res.data.count);
                                }
                              } else {
                                state.setPageSize(0);
                              }

                              resolve({
                                data: rows(res.data.results) as any,
                                page: query.page,
                                totalCount: res.data.count,
                              });
                            });
                      } catch (err) {
                        X9Logger.report(err as Error);
                        store.notificar('Ocorreu um erro ao adquirir informações. Tente novamente mais tarde!');
                      }
                    })
            }
            localization={newConfig.localization}
            style={config.style}
            options={{ ...config.options, pageSize: 50 }}
          />
        </div>
        <div>
          <Display style={{ marginBottom: '10px', height: 350 }} overflowY={false} title="Estoque de Ração">
            <ReactApexChart
              height={260}
              options={garficoBarra.options as any}
              series={garficoBarra.series}
              type="bar"
            />
          </Display>
        </div>
      </Body>
      {modalCadastro.state && (
        <FormArracoamento
          open={modalCadastro.state}
          handleClose={modalCadastro.close}
          onConfirm={() => {
            sync();
            (tableRef.current as any).onQueryChange();
          }}
        />
      )}
      {modalCadastroPeriodo.state && (
        <FormArracoamentoPeriodo
          open={modalCadastroPeriodo.state}
          handleClose={modalCadastroPeriodo.close}
          onConfirm={() => {
            sync();
            (tableRef.current as any).onQueryChange();
          }}
        />
      )}
      {modalEditar.state && (
        <FormArracoamento
          open={modalEditar.state}
          handleClose={modalEditar.close}
          arracoamento={state.selectedArracoamento}
          onConfirm={() => {
            sync();
            (tableRef.current as any).onQueryChange();
          }}
        />
      )}
      {modalTanques.state && (
        <VerTanques
          open={modalTanques.state}
          handleClose={modalTanques.close}
          arracoamento={state.selectedArracoamento!}
        />
      )}
      <Confirmar
        open={modalExclusao.state}
        content={<ExcluirArracoamento />}
        title="Tem certeza que deseja excluir?"
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={deletar}
      />
    </>
  ) : null;
};

export default observer(Arracoamento);
