import React , { useEffect, useState } from 'react';
import useStore from '../../../services/hooks/useStore';
import { useLocalStore, observer } from 'mobx-react-lite';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import Select from '../../../components/Select';
import { TextField, Collapse, ListItem } from '@material-ui/core';
import ReactInputMask from 'react-input-mask';
import Botao from '../../../components/Botoes/Botao';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Formatter from '../../../services/Formatter';
import { apiV2 } from '../../../services/utils';

type Props = ModalProps & {
  fornecedorID: number;
};
type State = {
  tipo: SelectRow | null;
  nome: string;
  responsavel: string;
  fone: string;
  estado: SelectRow | null;
  municipio: SelectRow | null;

  cpf_cnpj: string;
  whatsapp: string;
  email: string;
  endereco: string;

  collapse: boolean;
  isCPF: boolean;
  setCPFCNPJ(e: React.ChangeEvent<HTMLInputElement>): void;
};
const FormFornecedor: React.FC<Props> = ({ open, handleClose, fornecedorID }) => {

  const store = useStore();
  const [cidades, setCidades] = useState<Cidades[]>([] as Cidades[]);
  const fornecedor = fornecedorID ? store.fornecedores.get(fornecedorID) : null;
  const state = useLocalStore(
    (): State => ({
      tipo: fornecedor
        ? { value: fornecedor?.fornecedor_tipo.id.toString(), label: fornecedor?.fornecedor_tipo.nome }
        : null,
      nome: fornecedor?.nome || '',
      responsavel: fornecedor?.responsavel || '',
      fone: fornecedor?.fone || '',
      estado: fornecedor
        ? fornecedor.estado
          ? { value: fornecedor?.estado.id.toString(), label: fornecedor?.estado.nome }
          : null
        : null,
      municipio: fornecedor
        ? fornecedor.cidade
          ? { value: fornecedor?.cidade.id.toString(), label: fornecedor?.cidade.nome }
          : null
        : null,

      cpf_cnpj: fornecedor && fornecedor.cpf_cnpj ? Formatter.formatCPFCNPJ(fornecedor.cpf_cnpj) : '',
      whatsapp: fornecedor?.whatsapp || '',
      email: fornecedor?.email || '',
      endereco: fornecedor?.endereco || '',

      collapse: false,
      isCPF: false,

      setCPFCNPJ(e) {
        state.cpf_cnpj = e.target.value;
        if (Formatter.getNumeros(e.target.value).length <= 11) {
          state.isCPF = true;
        } else {
          state.isCPF = false;
        }
      },
    }),
  );

  async function cadastrar(): Promise<void> {
    if (!state.tipo) store.notificar('Informe o tipo do fornecedor');
    else if (!state.nome) store.notificar('Informe o nome do fornecedor');
    else if (!state.estado) store.notificar('Informe o estado do fornecedor');
    else if (!state.municipio) store.notificar('Informe o município do fornecedor');
    else if (!state.responsavel) store.notificar('Informe o responsável');
    else if (
      state.cpf_cnpj &&
      Formatter.getNumeros(state.cpf_cnpj).length !== 11 &&
      Formatter.getNumeros(state.cpf_cnpj).length !== 14
    ) {
      store.notificar('Informe um CPF ou CNPJ válido');
    } else {
      handleClose();
      store.toggleLoader();

      const data = {
        propriedade: store.propriedade!.id,
        fornecedor_tipo: state.tipo.value,
        nome: state.nome,
        cpf_cnpj: state.cpf_cnpj ? Formatter.getNumeros(state.cpf_cnpj) : null,
        responsavel: state.responsavel,
        fone: state.fone,
        whatsapp: state.whatsapp,
        email: state.email,
        endereco: state.endereco,
        estado: parseInt(state.estado.value!),
        cidade: parseInt(state.municipio.value!),
      };
      fornecedorID ? await store.fornecedores.editar(fornecedorID, data) : await store.fornecedores.criar(data);

      store.toggleLoader();
    }
  }
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function SearchApi() {
      const { data } = await apiV2.get<DadosClientes>(`/cliente/dados/`, {
        params: {
          propriedade: store.propriedade!.id,
        },
      });
      setCidades(data.results.cidades);
    }
    SearchApi();
  }, [store.propriedade]);

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{fornecedorID ? 'Editar' : 'Novo'} fornecedor</ModalHeader>
      <ModalBody>
        <div style={{ width: 400 }}>
          <div style={{ width: 400 }}>
            <Select
              value={state.tipo}
              placeholder="Tipos"
              onChange={(e: any) => (state.tipo = e)}
              options={store.tiposDeFornecedor.getSelectRows()}
            />
          </div>

          <div style={{ width: 400 }}>
            <Select
              value={state.estado}
              placeholder="Estado"
              onChange={(e: any) => {
                state.municipio = null;
                state.estado = e;
              }}
              options={store.estados?.map((estado) => {
                return {
                  label: estado.nome,
                  value: estado.id.toString(),
                };
              })}
            />
          </div>

          <div style={{ width: 400 }}>
          <Select
              disabled={!state.estado}
              value={state.municipio}
              placeholder="Município"
              onChange={(e: any) => (state.municipio = e)}
              options={cidades
                .filter((cidade) => state.estado && cidade.estado_id === parseInt(state.estado.value))
                .sort((a, b) => (a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0))
                .map((cidade) => {
                
                  return {
                    label: cidade.nome,
                    value: cidade.id.toString(),
                  };
                })}
            />
          </div>

          <TextField value={state.nome} onChange={(e) => (state.nome = e.target.value)} label="Nome/Razão Social" />
          <TextField
            value={state.responsavel}
            onChange={(e) => (state.responsavel = e.target.value)}
            label="Responsável"
          />

          <ReactInputMask
            value={state.fone}
            onChange={(e) => (state.fone = e.target.value)}
            mask="(99) 9 9999-9999"
            maskChar=""
          >
            {() => <TextField label="Telefone" />}
          </ReactInputMask>

          <ListItem
            style={{ justifyContent: 'center', marginTop: 10, marginBottom: state.collapse ? -10 : 10 }}
            button
            onClick={() => (state.collapse = !state.collapse)}
          >
            <b>Mais informações</b>
            {state.collapse ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={state.collapse}>
            <ReactInputMask
              value={state.cpf_cnpj}
              onChange={state.setCPFCNPJ}
              mask={state.isCPF ? '999.999.999-999' : '99.999.999/9999-99'}
              maskChar=""
              alwaysShowMask={false}
            >
              {() => <TextField label="CPF/CNPJ" />}
            </ReactInputMask>
            <ReactInputMask
              value={state.whatsapp}
              onChange={(e) => (state.whatsapp = e.target.value)}
              mask="(99) 9999-9999"
              maskChar=""
              alwaysShowMask={false}
            >
              {() => <TextField label="WhatsApp" />}
            </ReactInputMask>
            <TextField
              value={state.email}
              onChange={(e) => (state.email = e.target.value)}
              label="Email"
              type="email"
            />
            <TextField value={state.endereco} onChange={(e) => (state.endereco = e.target.value)} label="Endereço" />
          </Collapse>

          <div style={{ width: 400, display: 'flex' }}>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao onClick={cadastrar} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          </div>
        </div>
      </ModalBody>
    
    </Modal>
  );
};

export default observer(FormFornecedor);
