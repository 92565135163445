import { getThings, api, apiV2 } from '../utils';
import { observable, computed } from 'mobx';
import { stateContext } from '../hooks/useStore';

type TransferenciaData = {
  origem: string;
  destino: TanqueDestino | string;
  data: string;
  biomassa: number;
  peso: number;
  quantidade: number;
  tamanho: number;
  pls_grama: number;
  observacao: string;
};

class Transferencias {
  @observable arr: Transferencia[] | null = null;
  @observable totalRes: number = 0;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    this.arr = await getThings<Transferencia>('/transferencia/');
  }

  async getEverything(params?: object): Promise<Transferencia[]> {
    return await getThings<Transferencia>('/transferencia/', params);
  }

  async getPaginated(page: number, pageSize: number, propriedadeId: number, filterURL?: string): Promise<void> {
    const baseUrl = `/transferencia/?propriedade=${propriedadeId}`;
    const urlArray = [];
    urlArray.push(baseUrl);

    if (filterURL) {
      urlArray.push(filterURL);
    }

    const requestUrl = urlArray.join('');

    apiV2
      .get(requestUrl, {
        params: {
          page_size: pageSize,
          page: page + 1,
          prop: propriedadeId,
        },
      })
      .then((res: any) => {
        this.totalRes = res.data.count;
        this.arr = res.data.results;
      });
  }

  get(id: number): Transferencia {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  filter(callback: (item: Transferencia, index: number, array: Transferencia[]) => void): Transferencia[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: Transferencia, index: number, array: Transferencia[]) => any): any {
    return this.arr!.map(callback);
  }

  async criar(dados: TransferenciaData): Promise<void> {
    const store = stateContext.state;

    await api
      .post(`/transferencia/?propriedade=${store.propriedade!.id}`, dados)
      .then(async () => {
        store.notificar('Transferência registrada com sucesso!');
        await Promise.all([
          store.transferencias.populate(),
          store.tanques.populate(),
          store.lotes.populate(),
          store.loteTanque.populate(),
        ]);
      })
      .catch(() => store.notificar('Ocorreu um erro no cadastro da transferência!'));
  }

  async editar(transfID: number, dados: any): Promise<void> {
    const store = stateContext.state;

    await api
      .put(`/transferencia/${transfID}/?propriedade=${store.propriedade!.id}`, dados)
      .then(async () => {
        store.notificar('Transferência editada com sucesso!');
        await Promise.all([
          store.transferencias.populate(),
          store.tanques.populate(),
          store.lotes.populate(),
          store.loteTanque.populate(),
        ]);
      })
      .catch(() => store.notificar('Ocorreu um erro na edição da transferência!'));
  }
}

export default Transferencias;
