import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import Botao from '../../../components/Botoes/Botao';
import InputNumero from '../../../components/InputNumero';
import useStore from '../../../services/hooks/useStore';
import { useLocalStore, observer } from 'mobx-react-lite';

type Props = ModalProps & {
  tanque: Tanque | null;
  onConfirm(tanqueID: number, quantidade: string, plsGrama: number, biomassa: number): void;
  quantidadeDefault?: string;
  especie?: string;
};
const QuantidadeModal: React.FC<Props> = ({ open, handleClose, tanque, onConfirm, quantidadeDefault, especie }) => {
  const state = useLocalStore(() => ({
    biomassa: 0,
    plsGrama: 0,
    quantidade: 1,
  }));
  const store = useStore();

  useEffect(() => {
    if (open) state.quantidade = quantidadeDefault ? parseInt(quantidadeDefault) : 1;
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (state.biomassa || state.plsGrama) {
      state.quantidade = Number((state.plsGrama * state.biomassa * 1000).toFixed(0));
    }
    // eslint-disable-next-line
  }, [state.biomassa, state.plsGrama]);

  function addTanque(): void {
    if (state.quantidade === 0 || isNaN(state.quantidade)) store.notificar('Informe a quantidade');
    else {
      tanque && onConfirm(tanque.id, state.quantidade.toString(), state.plsGrama, state.biomassa);
      handleClose();
    }
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>{(tanque && tanque.nome) || (tanque && tanque.codigo)}</ModalHeader>
      <ModalBody>
        {especie === 'Camarão' && (
          <>
            <InputNumero
              value={state.biomassa}
              onChange={(value) => (state.biomassa = value)}
              precision="2"
              label="Biomassa (kg)"
            />
            <InputNumero
              value={state.plsGrama}
              onChange={(value) => (state.plsGrama = value)}
              precision="0"
              label="PLs/Grama"
            />
          </>
        )}
        <InputNumero
          value={state.quantidade}
          onChange={(value) => (state.quantidade = value)}
          precision="0"
          label="Quantidade (un)"
        />
        {tanque && (
          <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
            <Botao cor="gray" onClick={handleClose}>
              Voltar
            </Botao>
            <Botao cor="#00C853" onClick={addTanque}>
              Confirmar
            </Botao>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default observer(QuantidadeModal);
