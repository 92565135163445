import React from 'react';
import { TextField } from '@material-ui/core';
import { observer, useLocalStore } from 'mobx-react-lite';
import useStore from '../../../services/hooks/useStore';
import { Modal, ModalBody, ModalHeader } from '../../../components/Modais/styles';
import InputNumero from '../../../components/InputNumero';
import Botao from '../../../components/Botoes/Botao';
import Select from '../../../components/Select';

type CadastrarReservatorioProps = ModalProps & {
  reservatorioID: number;
};

type State = {
  nome: string;
  tamanho: number;
  volume: number;
  aguaOrigem?: SelectRow | null;
  situacaoTanque?: SelectRow | null;
};

const CadastrarReservatorio = ({ open, reservatorioID, handleClose }: CadastrarReservatorioProps) => {
  const store = useStore();
  const reservatorio = reservatorioID ? store.reservatorios.get(reservatorioID) : null;
  const state = useLocalStore(
    (): State => ({
      nome: reservatorio ? reservatorio.nome : '',
      tamanho: reservatorio ? reservatorio.tamanho : 0,
      volume: reservatorio ? reservatorio.volume : 0,
      aguaOrigem: reservatorio
        ? {
            label: reservatorio.origem.nome,
            value: reservatorio.origem.id.toString(),
          }
        : null,
      situacaoTanque: reservatorio
        ? { label: reservatorio.situacao.nome, value: reservatorio.situacao.id.toString() }
        : null,
    }),
  );

  const cadastrar = async () => {
    if (state.tamanho === 0) return store.notificar('Informe o tamanho do reservatorio');
    if (state.volume === 0) return store.notificar('Informe o volume do reservatorio');
    if (!state.aguaOrigem) return store.notificar('Informe o origem do reservatorio');
    if (!state.situacaoTanque) return store.notificar('Informe a situação do reservatorio');
    store.toggleLoader();
    const data: any = {
      id: reservatorioID,
      propriedade: store.propriedade?.id,
      situacao: parseInt(state.situacaoTanque.value),
      nome: state.nome,
      tamanho: state.tamanho,
      volume: state.volume,
      origem: parseInt(state.aguaOrigem.value),
    };
    try {
      if (reservatorio) {
        await store.reservatorios.editar(reservatorioID, data);
      } else {
        await store.reservatorios.criar(data);
      }
      await store.reservatorios.populate();
      handleClose();
    } finally {
      store.toggleLoader();
    }
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{reservatorioID ? 'Editar' : 'Novo'} Reservatório</ModalHeader>
      <ModalBody>
        <TextField label="Nome" value={state.nome} onChange={(e) => (state.nome = e.target.value)} />
        <InputNumero
          label="Tamanho total (m3)"
          value={state.tamanho}
          onChange={(value) => (state.tamanho = value)}
          precision="2"
        />
        <InputNumero
          label="Volume útil (lt/m3)"
          value={state.volume}
          onChange={(value) => (state.volume = value)}
          precision="2"
        />
        <div style={{ width: '100%' }}>
          <Select
            placeholder="Origem da água"
            value={state.aguaOrigem}
            onChange={(e) => (state.aguaOrigem = e)}
            options={store.origensDaAgua.getSelectRows()}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Select
            placeholder="Selecione a situação"
            value={state.situacaoTanque}
            onChange={(e) => (state.situacaoTanque = e)}
            options={store.situacoesDeTanque.getSelectRows()}
          />
        </div>

        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao onClick={cadastrar} variant="contained" cor="#00C853">
            Salvar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(CadastrarReservatorio);
