import React, { useState, useEffect } from 'react';
import useModal from '../../services/hooks/useModal';
import Select from '.';
import useStore from '../../services/hooks/useStore';
import { Lote, Modal, ModalHeader, ModalBody } from '../Modais/styles';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Flex } from '../NavBar/styles';
import Formatter from '../../services/Formatter';
import { apiV2 } from '../../services/utils';

interface Props {
  disabled?: boolean;
  onChange(value: string | null): void;
}
const SelectLote: React.FC<Props> = ({ disabled, onChange }) => {
  const [lotes, setLotes] = useState<SelectRow[]>([]);
  const [value, setValue] = useState<SelectRow | null>(null);

  const [lote, setLote] = useState<Lote[]>([] as Lote[]);
  const modal = useModal();
  const store = useStore();
  const state2 = useLocalStore(() => ({
    sync: false,
  }));

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function searchLote() {
    await apiV2
      .get('/lote/modals/', {
        params: {
          prop: store.propriedade!.id,
        },
      })
      .then((res) => {
        setLote(res.data.results);
      });
    state2.sync = true;
  }

  async function sync(): Promise<void> {
    store.toggleLoader();

    await searchLote();
    setLotes(lote.map((lote) => ({ label: lote.codigo, value: lote.id.toString() })));
    store.toggleLoader();
  }

  useEffect(() => {
    if (modal.state) sync();
    // eslint-disable-next-line
  }, [modal.state]);

  useEffect(() => {
    onChange(value ? value?.value : null);
    // eslint-disable-next-line
  }, [value]);

  return (
    <>
      <Select
        placeholder="Selecione o lote"
        disabled={disabled}
        value={value}
        onFocus={(e) => {
          e.target.blur();
          modal.open();
        }}
        options={lotes}
      />
      {modal.state ? (
        <Modal open={modal.state} onClose={modal.close}>
          <ModalHeader>Selecione um Lote</ModalHeader>
          <ModalBody>
            <Flex style={{ flexDirection: 'column' }}>
              {lote.map((lote: any) => {
                const selected = lote.id === Number(value?.value);

                if (lote.estoque) {
                  return (
                    <Lote
                      selected={selected || undefined}
                      onClick={() => {
                        if (!selected) {
                          setValue({ label: lote.codigo, value: lote.id.toString() });
                          modal.close();
                        }
                      }}
                      key={lote.id}
                    >
                      <div>
                        <p>{Formatter.dateToString(new Date(lote.data), '/')}</p>
                        <p>Qtde Tanques: {Formatter.formatNumber(0, lote.quantidade_tanques)}</p>
                        <p>Qtde Animais: {Formatter.formatNumber(0, lote.estoque)}</p>
                      </div>
                      <div style={{ textAlign: 'end' }}>
                        <b>{lote.codigo}</b>
                        <p style={{ color: 'rgb(0, 200, 83)' }}>
                          Peso Médio: {Formatter.formatNumber(3, lote.peso_medio_atual || lote.peso_medio)} g
                        </p>
                        <p>Biomassa: {Formatter.formatNumber(3, lote.biomassa)} kg</p>
                      </div>
                    </Lote>
                  );
                } else {
                  return null;
                }
              })}
            </Flex>
          </ModalBody>
        </Modal>
      ) : null}
    </>
  );
};

export default observer(SelectLote);
