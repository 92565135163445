/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import useStore from '../../services/hooks/useStore';
import Header from '../../components/Header';
import { ParametroNome, FlexList, HeaderList, MinValor, MaxValor } from './styles';
import { Body } from '../../components/Body';
import { Flex } from '../../components/NavBar/styles';
import { config } from '../../components/Tabela';
import AddBtn from '../../components/Botoes/AddBtn';
import AddIcon from '@material-ui/icons/Add';
import useModal from '../../services/hooks/useModal';
import Display from '../../components/Tabela/Display';
import { HeaderButton } from '../../components/Tabela/styles';
import MaterialTable, { Column } from 'material-table';
import { useLocalStore, observer } from 'mobx-react-lite';
import Filtro from '../../components/Filtro';
import EditarAguaReservatorio from './EditarAguaDeReservatorio';
import ListaTodosParametros from './ListaTodosParametros';
import CadastraAguaReservatorio from './CadastrarAguaDeReservatorio';
import Select from 'react-select';
import syncModel from '../../services/models/Sync';
import ActionButton from '../../components/Tabela/ActionButton';
import { returnTanque } from '../../services/utils/propriedade';
import Confirmar from '../../components/Modais/Confirmar';
import { api } from '../../services/utils';
import Formatter from '../../services/Formatter';
import X9Logger from '../../services/X9Logger';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ValorTabela } from './ValorTabela';
import CadastrarAguaReservatorioMuitosReservatorios from './CadastrarAguaReservatorioMuitosReservatorios';

type State = PageState & {
  filtroReservatorio: SelectRow | null;
  filtroParametro: SelectRow | null;
  selectedAnalise: number;
  parametroSelected: SelectRow | null;
  tanqueSelected: SelectRow | null;
  parametroSelect: SelectRow[];
  filtroDataInicio: MaterialUiPickersDate;
  filtroDataFim: MaterialUiPickersDate;

  rows: any[];
  showParametros: boolean;
  sync: boolean;
  colunas: Column<object>[];
};

const AguaReservatorio: React.FC = () => {
  const store = useStore();
  const modalCadastro = useModal();
  const modalCadastroParaMuitosReservatorios = useModal();
  const modalEditar = useModal();
  const modalExclusao = useModal();
  const modalVisualizar = useModal();
  // const history = useHistory();
  const state = useLocalStore(
    (): State => ({
      pageSize: 0,
      setPageSize(value: number) {
        this.pageSize = value + 1;
      },

      rows: [],
      filtroReservatorio: null,
      filtroParametro: null,

      selectedAnalise: 0,
      parametroSelected: null,
      tanqueSelected: null,
      parametroSelect: [{ label: 'Sem parâmetros ativos', value: '' }],
      filtroDataInicio: null,
      filtroDataFim: null,

      showParametros: false,
      sync: false,

      colunas: [],
    }),
  );

  const tableRef: any = React.createRef();

  useEffect(() => {
    tableRef.current?.onQueryChange();
  }, [store.aguaDeReservatorios.arr, tableRef]);

  useEffect(() => {
    if (store.propriedade) {
      store.aguaDeReservatorios.getPaginated();
    }
  }, [store.propriedade, store.aguaDeReservatorios]);

  async function sync(): Promise<void> {
    try {
      await syncModel.aguaDeReservatorios();

      state.sync = true;
    } catch (error) {
      store.notificar('Ocorreu um erro no carregamento dos seus dados, atualize a página!');
    }
  }

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

 


  useEffect(() => {
    if (store.parametroValor.arr) {
      state.colunas = [
        {
          field: 'reservatorio',
          title: 'Reservatório',
          ...(true && ({ width: 140 } as object)),
        },
        {
          field: 'acoes',
          disableClick: true,
          render: (rowData: any) => (
            <ActionButton
              editar={() => {
                state.selectedAnalise = rowData.id;
                modalEditar.open();
              }}
              excluir={() => {
                state.selectedAnalise = rowData.id;
                modalExclusao.open();
              }}
            />
          ),
          ...(true && ({ width: 25 } as object)),
        },
        {
          field: 'data',
          title: 'Data',
          type: 'date',
          ...(true && ({ width: 100 } as object)),
        },
      ];

      store.parametroValor.map((item: any) => {
        if (item.used) {
          const valorMinimo = item.parametro.valor_minimo;
          const valorMaximo = item.parametro.valor_maximo;

          state.colunas.push({
            field: item.parametro.uuid,
            title: `${item.parametro.nome} (${item.parametro.simbolo})`,
            render: (rowData: any) => (
              <ValorTabela value={rowData[item.parametro.uuid]} min={valorMinimo} max={valorMaximo} />
            ),
            type: 'numeric',
            ...(true && ({ width: 200 } as object)),
          });
        }

        return null;
      });
    }
    // eslint-disable-next-line
  }, [store.parametroValor.arr]);

  


  

  function limparFiltro(): void {
    state.filtroReservatorio = null;
    state.filtroParametro = null;
    state.filtroDataInicio = null;
    state.filtroDataFim = null;
    sync();
  }

  async function deletar(): Promise<void> {
    modalExclusao.close();
    store.toggleLoader();
    await api
      .delete(`/analise-agua-reservatorio/${state.selectedAnalise}/?propriedade=${store.propriedade!.id}`)
      .then(() => {
        store.notificar('A análise foi excluída!');
      })
      .catch((error) => {
        console.log(error);
        store.notificar('Ocorreu um erro na exclusão, tente novamente!');
      });
    store.toggleLoader();

    sync();
  }

  async function filter(): Promise<void> {
    store.toggleLoader();
    await store.qualidadeDaAgua.getPaginated({
      //TODO: pegar filtros para agua de reservatorios
      page: 0,
      page_size: 25,
      propriedade: store.propriedade?.id ?? 0,
      tanque: state.filtroReservatorio?.value,
      parametro_valor: state.filtroParametro?.value,
      data_inicial: state.filtroDataInicio ? Formatter.dateToISOString(state.filtroDataInicio) : null,
      data_final: state.filtroDataFim ? Formatter.dateToISOString(state.filtroDataFim) : null,
    });
    store.toggleLoader();
  }

  const ExcluirAguaDeReservatorio: React.FC = observer(() => {
    const analises = store.aguaDeReservatorios.get(state.selectedAnalise);
    console.log(JSON.stringify(analises));
    return (
      <div style={{ width: '100%' }}>
        <b>Reservatório: </b> {analises && analises.reservatorio.nome}
        <br />
        <b>Data: </b>{' '}
        {analises && analises.data.substr(3, 2) + '/' + analises.data.substr(0, 2) + '/' + analises.data.substr(6, 4)}
        <br />
        {analises?.parametros_utilizados.map((item: any) => {
          return (
            <>
              <b>{item && item.parametro.nome}: </b> {item && Formatter.formatNumber(3, item.value)}
              <br />
            </>
          );
        })}
      </div>
    );
  });

  const OpenParametros = <HeaderButton onClick={() => modalVisualizar.open()}>Ver Todos</HeaderButton>;

  useEffect(() => {
    if (store.parametroValor.arr !== null) {
      const returner: SelectRow[] = [];
      store.parametroValor?.map((item) => {
        if (item.used) {
          returner.push({
            label: item.parametro.nome,
            value: item.parametro.id.toString(),
          });
        }
        return null;
      });

      state.parametroSelect = returner;
    }
    // eslint-disable-next-line
  }, [store.parametroValor.arr]);

  useEffect(() => {
    if (state.parametroSelect.length > 0) {
      state.parametroSelected = {
        label: state.parametroSelect[0].label,
        value: state.parametroSelect[0].value,
      };
    } else {
      state.parametroSelected = {
        label: 'Sem parâmetro ativo',
        value: '',
      };
    }

    if (store.tanques.arr?.length) {
      const tanque = store.tanques.arr![0];

      state.tanqueSelected = {
        label: returnTanque(tanque),
        value: tanque.id.toString(),
      };
    }
    // eslint-disable-next-line
  }, [store.tanques.arr, state.parametroSelect]);

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Água de Reservatórios'), []);

  const ListaParametros: React.FC = () => (
    <>
      <HeaderList>
        <span>MIN</span>
        <span>MÁX</span>
      </HeaderList>
      {store.parametroValor?.map((parametro) => {
        if (parametro?.used) {
          return (
            <FlexList key={parametro?.id}>
              <ParametroNome>{parametro?.parametro?.nome}</ParametroNome>
              <MinValor>{Formatter.formatNumber(3, parametro?.valor_minimo)}</MinValor>
              <MaxValor>{Formatter.formatNumber(3, parametro?.valor_maximo)}</MaxValor>
            </FlexList>
          );
        }
      })}
    </>
  );

  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn onClick={modalCadastro.open} text="Nova Análise" Icon={AddIcon} />
          <AddBtn onClick={modalCadastroParaMuitosReservatorios.open} text="Análise por Hora" Icon={AddIcon} />
        </Flex>
        <Flex>
          <Filtro sync={filter} clear={limparFiltro}>
            <div style={{ width: '90%', marginTop: 10 }}>
              <Select
                value={state.filtroReservatorio}
                classNamePrefix="sel"
                placeholder="Selecione o Reservatório"
                onChange={(e: any) => (state.filtroReservatorio = e)}
                options={store.reservatorios?.arr?.map((reservatorio) => {
                  return {
                    label: reservatorio.nome,
                    value: reservatorio.id,
                  };
                })}
              />
            </div>
            <div style={{ width: '90%', marginTop: 10 }}>
              <Select
                value={state.filtroParametro}
                classNamePrefix="sel"
                placeholder="Selecione o Parâmetro"
                onChange={(e: any) => (state.filtroParametro = e)}
                options={state.parametroSelect}
              />
            </div>
            <div style={{ width: '90%', marginTop: 10 }}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data Início"
                value={state.filtroDataInicio}
                maxDate={state.filtroDataFim}
                onChange={(date) => (state.filtroDataInicio = date)}
              />
            </div>
            <div style={{ width: '90%', marginTop: 10 }}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data Fim"
                value={state.filtroDataFim}
                minDate={state.filtroDataInicio}
                onChange={(date) => (state.filtroDataFim = date)}
              />
            </div>
          </Filtro>
        </Flex>
      </Header>
      <Body gridColumns="1fr 500px">
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          {store.aguaDeReservatorios.arr && (
            <MaterialTable
              tableRef={tableRef}
              columns={state.colunas}
              onChangePage={() => {
                if (store.propriedade) {
                  store.aguaDeReservatorios.getPaginated();
                }
              }}
              data={(query) =>
                new Promise((resolve) => {
                  try {
                    resolve({
                      data: rows(store.aguaDeReservatorios.arr as any),
                      page: query.page,
                      totalCount: store.aguaDeReservatorios.totalResults,
                    });
                  } catch (err: any) {
                    X9Logger.report(err);
                    store.notificar('Ocorreu um erro ao adquirir informações. Tente novamente mais tarde!');
                  }
                })
              }
              options={{ ...config.options, pageSize: 25 }}
              localization={config.localization}
              style={config.style}
            />
          )}
        </div>
        <div>
          {store.parametroValor.arr ? (
            <Display style={{ height: '40vh' }} overflowY title="Parâmetros" HeaderButtonEdit={OpenParametros}>
              <ListaParametros />
            </Display>
          ) : null}
        </div>

        {modalCadastro.state ? (
          <CadastraAguaReservatorio
            parametroValor={store.parametroValor.arr}
            open={modalCadastro.state}
            handleClose={modalCadastro.close}
          />
        ) : null}
        {modalCadastroParaMuitosReservatorios.state && (
          <CadastrarAguaReservatorioMuitosReservatorios
            parametroValor={store.parametroValor.arr}
            open={modalCadastroParaMuitosReservatorios.state}
            handleClose={modalCadastroParaMuitosReservatorios.close}
          />
        )}

        {modalEditar.state ? (
          <EditarAguaReservatorio
            open={modalEditar.state}
            aguaReservatorioID={state.selectedAnalise}
            handleClose={modalEditar.close}
          />
        ) : null}
        {modalVisualizar.state && store.parametroValor.arr ? (
          <ListaTodosParametros open={modalVisualizar.state} handleClose={modalVisualizar.close} />
        ) : null}
      </Body>
      <Confirmar
        open={modalExclusao.state}
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={deletar}
        content={<ExcluirAguaDeReservatorio />}
        title="Tem certeza que deseja excluir?"
      />
    </>
  ) : null;
};

export default observer(AguaReservatorio);

// ----------------------------------------------

const rows = (agua_de_reservatorios: any[]): object[] => {
  return agua_de_reservatorios.map((agua) => {
    const data = new Date(agua.data.substr(3, 2) + '/' + agua.data.substr(0, 2) + '/' + agua.data.substr(6, 4));

    const row: { [key: string]: any } = {
      id: agua.id,
      uuid: agua.uuid,
      // nome: returnTanque(agua?.tanque),
      reservatorio: agua.reservatorio.nome,
      parametros_utilizados: agua.parametros_utilizados,
      data,
    };

    try {
      agua.parametros_utilizados.map((parametro: any) => {
        row[parametro?.parametro?.uuid] = parametro.value;

        return null;
      });
    } catch (error) {
      console.log(error);
    }

    return row;
  });
};
