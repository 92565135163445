import { getThings, api, apiV2, getThingsV2 } from '../utils';
import { observable, computed } from 'mobx';
import CategoriasFinanceiras from './CategoriasFinanceiras';
import { stateContext } from '../hooks/useStore';
// import Formatter from '../Formatter';

export type MovimentacaoData = {
  propriedade: number;
  documento: string;
  data: string;
  conta: number;
  categoria: number;
  situacao?: boolean;
  lote_tanque_movimentacao: any[] | undefined;
  fornecedor?: number;
  cliente?: number;
  parcelas?: ParcelaData[];
  itens?: DespesaItemCriacao[];
  valor_frete?: number;
  valor_outros?: number;
  descricao?: string;
  quantidade?: number;
  valor_total?: number;
  valor_unitario?: number;
};

type Despesas = {
  conta: string;
  total: string;
};
type Contas = {
  id: number;
  ativo: boolean;
  propriedade_id: number;
  titulo: string;
  saldo_atual: number;
  saldo_inicial: number;
};

type Receitas = {
  vendas: number;
  despesas: number;
  outras: number;
  saldo: number;
};

class Movimentacoes {
  @observable arr: Movimentacao[] | null = null;
  @observable despesas: Despesas[] | null = null;
  @observable receitas: Receitas | null = null;
  @observable contas: Contas[] | null = null;
  @observable totalRes: number = 0;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    this.arr = await getThingsV2<Movimentacao>('/movimentacao/');
  }

  async ano(): Promise<void> {
    this.arr = await getThingsV2<Movimentacao>('/movimentacao/anos/');
  }

  async getEverything(params?: object): Promise<Movimentacao[]> {
    return await getThings<Movimentacao>('/movimentacao/', params);
  }

  async criar(dados: MovimentacaoData): Promise<void> {
    const store = stateContext.state;

    await api
      .post(`/movimentacao/?propriedade=${store.propriedade!.id}`, dados)
      .then(async () => {
        store.notificar('Movimentação cadastrada com sucesso!');
      })
      .catch(() => store.notificar('Ocorreu um erro ao cadastrar a movimentação!'));
  }

  async editarDespesa(movimentacaoID: number, dados: MovimentacaoData, parcelas: ParcelaData[]): Promise<void> {
    const store = stateContext.state;

    await api
      .patch(`/movimentacao/${movimentacaoID}/?propriedade=${store.propriedade!.id}`, dados)
      .then(async () => {
        store.notificar('Despesa editada com sucesso!');
      })
      .catch(() => store.notificar('Ocorreu um erro ao editar a despesa!'));

    await Promise.all(
      parcelas.map(async (parcela) => {
        await apiV2
          .patch(`/parcela/${parcela.id}/?propriedade=${store.propriedade!.id}`, {
            num_parcela: parcela.num_parcela,
            valor: parcela.valor,
            conta: parcela.conta,
            pagamento_tipo: parcela.pagamento_tipo,
            // data_pagamento: Formatter.dateToString(parcela.data_pagamento),
            data_pagamento: parcela.data_pagamento,
            recebido: parcela.recebido,
            parcial: false,
          })
          .then(() => {
            store.notificar('Parcela editada com sucesso!');
          })
          .catch(() => store.notificar('Ocorreu um erro ao editar a parcela!'));
      }),
    );
  }

  async editarEntrada(movimentacaoID: number, situacao: boolean, dados: MovimentacaoData): Promise<void> {
    const store = stateContext.state;

    await api
      .put(`/movimentacao/${movimentacaoID}/?propriedade=${store.propriedade!.id}`, dados)
      .then(async (res) => {
        await apiV2.patch(`/parcela/${res.data.parcelas[0].id}/?propriedade=${store.propriedade!.id}`, {
          recebido: situacao,
        });

        store.notificar('Entrada editada com sucesso!');
      })
      .catch(() => store.notificar('Ocorreu um erro ao editar a entrada!'));
  }

  async excluir(movimentacaoID: number): Promise<void> {
    const store = stateContext.state;

    await api
      .delete(`/movimentacao/${movimentacaoID}/?propriedade=${store.propriedade!.id}`)
      .then(() => store.notificar('Movimentação excluída com sucesso!'));
  }

  get(id: number): Movimentacao {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  getByAno(ano: string): Movimentacao[] {
    return this.arr!.filter((item) => item.data.substr(6, 4) === ano);
  }

  getByTipo(tipo: string, categorias: CategoriasFinanceiras): Movimentacao[] {
    return this.arr!.filter((item) => categorias.get(item.categoria.id).tipo === tipo);
  }

  getByStatus(status: boolean): Movimentacao[] {
    if (status) {
      return this.arr!.filter((item) => item.situacao === status);
    } else {
      return this.arr!.filter((item) => item.parcelas.some((parcela) => !parcela.recebido));
    }
  }

  filter(callback: (item: Movimentacao, index: number, array: Movimentacao[]) => void): Movimentacao[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: Movimentacao, index: number, array: Movimentacao[]) => any): any {
    return this.arr!.map(callback);
  }

  async getContas(propriedadeId: number): Promise<void> {
    apiV2
      .get('/movimentacao/contas/', {
        params: {
          prop: propriedadeId,
        },
      })
      .then((res: any) => {
        this.contas = res.data.results;
      });
  }
  async getReceitas(propriedadeId: number): Promise<void> {
    apiV2
      .get('/movimentacao/receitas/', {
        params: {
          prop: propriedadeId,
        },
      })
      .then((res: any) => {
        this.receitas = res.data;
      });
  }
  async getDespesas(propriedadeId: number): Promise<void> {
    apiV2
      .get('/movimentacao/despesas-pagar-conta/', {
        params: {
          prop: propriedadeId,
        },
      })
      .then((res: any) => {
        this.despesas = res.data.results;
      });
  }
  async getPaginated(page: number, pageSize: number, propriedadeId: number, filterURL?: string): Promise<void> {
    const baseUrl = `/movimentacao/?propriedade=${propriedadeId}`;
    const urlArray = [];
    urlArray.push(baseUrl);

    if (filterURL) {
      urlArray.push(filterURL);
    }

    const requestUrl = urlArray.join('');

    api
      .get(requestUrl, {
        params: {
          page_size: pageSize,
          page: page + 1,
          prop: propriedadeId,
        },
      })
      .then((res: any) => {
        this.totalRes = res.data.count;
        this.arr = res.data.results;
      });
  }
}

export default Movimentacoes;
