/* eslint-disable react/display-name */
import React, { useEffect, useRef } from 'react';
import useStore from '../../../services/hooks/useStore';
import useModal from '../../../services/hooks/useModal';
import { useLocalStore, observer } from 'mobx-react-lite';
import ActionButton from '../../../components/Tabela/ActionButton';
import MaterialTable, { Column } from 'material-table';
import { api, apiV2, getPdfV2 } from '../../../services/utils';
import Header from '../../../components/Header';
import { Flex } from '../../../components/NavBar/styles';
import AddBtn from '../../../components/Botoes/AddBtn';
import Filtro from '../../../components/Filtro';
import AddIcon from '@material-ui/icons/Add';
import { Body } from '../../../components/Body';
import { config } from '../../../components/Tabela';
import Select from '../../../components/Select';
import FormVenda from './FormVenda';
import Confirmar from '../../../components/Modais/Confirmar';
import VisualizarVenda from './VisualizarVenda';
import { ListItem, Collapse } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { TanqueInfo } from '../../../components/TanqueInfo';
import { returnTanque } from '../../../services/utils/propriedade';
import Display from '../../../components/Tabela/Display';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import pt from 'apexcharts/dist/locales/pt.json';
import GerarRelatorio from './GerarRelatorio';
import { getRelatorio2 } from '../../../services/utils/relatorios';
import { useHistory } from 'react-router-dom';
import syncModel from '../../../services/models/Sync';
import Formatter from '../../../services/Formatter';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Botao from '../../../components/Botoes/Botao';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import X9Logger from '../../../services/X9Logger';
import FormProdutos from '../../Financeiro/Movimentacoes/FormProdutos';
import { permissoes } from '../../../services/utils/permissoes';

type State = PageState & {
  filtroRapidoCliente: SelectRow | null;
  filtroCliente: SelectRow | null;
  filtroLote: SelectRow | null;
  data_ini: MaterialUiPickersDate;
  data_fim: MaterialUiPickersDate;

  selectedVenda: Venda;

  collapse: boolean;
  filterUrlString: string;
  sync: boolean;
};

const PAGE_SIZE = 50;
const MinhasVendas: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  const tableRef = useRef();
  const modalVenda = useModal();
  const modalVisualizar = useModal();
  const modalProduto = useModal();
  const modalExclusao = useModal();
  const modalGerarRelatorio = useModal();
  const state = useLocalStore(
    (): State => ({
      data_ini: new Date(),
      data_fim: new Date(),
      pageSize: 0,
      setPageSize(value: number) {
        this.pageSize = value + 1;
      },
      filtroRapidoCliente: null,
      filtroCliente: null,
      filtroLote: null,
      selectedVenda: {} as Venda,
      filterUrlString: '',
      collapse: false,
      sync: false,
    }),
  );
  const graficoBarra = useLocalStore(() => ({
    filtro: 0,
    series: [
      {
        name: 'Total de vendas',
        data: [],
      },
    ],
    options: {
      title: {},
      chart: {
        locales: [pt],
        defaultLocale: 'pt',
        type: 'area',
        id: 'vendas',
        stacked: false,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      yaxis: {
        labels: {
          formatter: (val: number) => {
            return 'R$ ' + Formatter.formatNumber(2, val);
          },
        },
      },
      xaxis: {
        categories: [],
      },
      tooltip: {
        shared: false,
      },
    },

    updateData: async () => {
      ApexCharts.exec('vendas', 'updateSeries', [
        {
          data: store.vendas.meses!.reverse().map((data) => {
            return {
              x:
                data.mes === 1
                  ? 'Janeiro'
                  : data.mes === 2
                  ? 'Fevereiro'
                  : data.mes === 3
                  ? 'Março'
                  : data.mes === 4
                  ? 'Abril'
                  : data.mes === 5
                  ? 'Maio'
                  : data.mes === 6
                  ? 'Junho'
                  : data.mes === 7
                  ? 'Julho'
                  : data.mes === 8
                  ? 'Agosto'
                  : data.mes === 9
                  ? 'Setembro'
                  : data.mes === 10
                  ? 'Outubro'
                  : data.mes === 11
                  ? 'Novembro'
                  : 'Dezembro',

              y: data.total,
            };
          }),
        },
      ]);
    },
  }));
  store.propriedade && store.validarPermissao(permissoes.VENDAS_MINHAS_VENDAS_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.VENDAS_MINHAS_VENDAS_ESCRITA);

  async function sync(): Promise<void> {
    await syncModel.vendas2();
    state.sync = true;
    graficoBarra.updateData();
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Vendas'), []);

  useEffect(() => {
    if (store.propriedade) {
      sync();
      (tableRef.current as any)?.onQueryChange();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  useEffect(() => {
    if (store.propriedade) {
      store.vendas.getPaginated(0, PAGE_SIZE, store.propriedade.id);
    }
  }, [store.propriedade, store.vendas]);

  async function filtrar(): Promise<void> {
    if (state.filtroLote || state.filtroCliente) {
      state.filtroRapidoCliente = null;
      (tableRef.current as any).onQueryChange();
    } else {
      sync();
    }
  }
  function limparFiltro(): void {
    state.filtroRapidoCliente = null;
    state.filtroLote = null;
    (tableRef.current as any).onQueryChange();
  }

  async function deletar(): Promise<void> {
    modalExclusao.close();
    store.toggleLoader();
    await api.delete(`/venda/${state.selectedVenda.id}/?propriedade=${store.propriedade!.id}`);
    store.notificar('Venda excluída com sucesso!');
    store.toggleLoader();

    sync();
    (tableRef.current as any).onQueryChange();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getPDF() {
    store.toggleLoader();
    try {
      const pdf = await getPdfV2.get(`/vendas/${store.propriedade!.id}/relatorio/`, {
        params: {
          authori: store.token,
          data_inicio: state.data_ini ? Formatter.dateToString(state.data_ini, '-') : '',
          data_fim: state.data_fim ? Formatter.dateToString(state.data_fim, '-') : '',
        },
      });
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
    store.toggleLoader();
  }

  const columns: Column<object>[] = [
    {
      field: 'cliente',
      title: 'Cliente',
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'acoes',
      disableClick: true,
      render: (rowData: any) => (
        <ActionButton
          recibo={() => {
            state.selectedVenda = rowData.props;
            getRelatorio2(`/gerador/vendas/recibo/${state.selectedVenda.id}/`, store.propriedade!.id, store.token);
          }}
          excluir={
            temPermissaoEscrita
              ? () => {
                  state.selectedVenda = rowData.props;
                  modalExclusao.open();
                }
              : undefined
          }
        />
      ),
      ...(true && ({ width: 25 } as object)),
    },
    {
      field: 'data',
      title: 'Data',
      type: 'date',
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'lote',
      title: 'Lote',
      ...(true && ({ width: 130 } as object)),
    },
    {
      field: 'tanques',
      title: 'Tanques',
      render: (rowData: any) => {
        let tanques = rowData.tanques.join(', ').substr(0, 50);

        if (tanques.length > 50) {
          tanques += '...';
        }

        return tanques;
      },
      ...(true && ({ width: 130 } as object)),
    },
    {
      field: 'peso',
      title: 'Biomassa (kg)',
      type: 'numeric',
      render: ({ peso }: any) => {
        return Formatter.formatNumber(2, peso / 1000);
      },
      ...(true && ({ width: 160 } as object)),
    },
    {
      field: 'quantidade',
      title: 'Quantidade',
      type: 'numeric',
      render: ({ quantidade }: any) => {
        return Formatter.formatNumber(0, quantidade);
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'valor_total',
      title: 'Valor Total (R$)',
      type: 'numeric',
      render: ({ valor_total }: any) => {
        return Formatter.formatNumber(2, valor_total);
      },
      ...(true && ({ width: 170 } as object)),
    },
  ];
  const rows = (vendas: Venda[]): object[] => {
    if (vendas.length) {
      if (vendas.length >= PAGE_SIZE) {
        state.setPageSize(PAGE_SIZE);
      } else {
        state.setPageSize(vendas.length);
      }
    } else {
      state.setPageSize(0);
    }

    return vendas.map((venda) => {
      let quantidade = 0;
      venda.lote_tanque_vendas.map((item) => {
        return (quantidade += item.quantidade);
      });

      const data = new Date(venda.data.substr(3, 2) + '/' + venda.data.substr(0, 2) + '/' + venda.data.substr(6, 4));

      return {
        id: venda.id,
        cliente: venda.cliente.nome,
        data,
        lote: venda.lote_tanque_vendas[0].lote_tanque.lote.codigo,
        tanques: venda.lote_tanque_vendas.map((loteTanque) => returnTanque(loteTanque.lote_tanque.tanque)),
        peso: venda.peso,
        quantidade,
        valor_total: venda.valor_total,
        props: venda,
      };
    });
  };
  const ExcluirVenda: React.FC = observer(() => {
    const vendaEncontrada = store.vendas.get(state.selectedVenda.id);
    const venda = vendaEncontrada || state.selectedVenda;
    const loteTanqueVendas = venda?.lote_tanque_vendas?.length > 0 ? venda?.lote_tanque_vendas[0] : null;
    const pesoMedio = venda?.movimentacao
      ? venda?.peso / 1000 / venda?.movimentacao.quantidade
      : loteTanqueVendas?.peso_medio || 0;
    return (
      <div style={{ width: '100%' }}>
        <b>Cliente: </b> {venda && venda?.cliente?.nome}
        <br />
        <b>Data: </b> {venda && Formatter.getData(venda?.data)}
        <br />
        <b>Lote: </b>
        {loteTanqueVendas && loteTanqueVendas.lote_tanque.lote.codigo}
        <br />
        <b>Peso: </b> {venda && Formatter.formatNumber(2, venda?.peso / 1000)} kg
        <br />
        <b>Peso Médio: </b> {venda && Formatter.formatNumber(2, pesoMedio)} kg
        <br />
        <b>Valor Total: </b> R$ {venda && Formatter.formatNumber(2, venda?.valor_total)}
        <br />
        <ListItem
          style={{ justifyContent: 'center', marginTop: 10, marginBottom: state.collapse ? -10 : 10 }}
          button
          onClick={() => (state.collapse = !state.collapse)}
        >
          <b>Tanques Selecionados</b>
          {state.collapse ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={state.collapse}>
          {venda?.lote_tanque_vendas.map((item) => {
            const estoque = store.tanques.getEstoqueByID(item?.lote_tanque.tanque?.id);
            return (
              <TanqueInfo key={item.lote_tanque?.tanque?.id}>
                <div>
                  <b className="left">{returnTanque(item.lote_tanque?.tanque)}</b>
                  <p>{Formatter.formatNumber(0, estoque)} un</p>
                  <p className="right" style={{ color: '#00C853' }}>
                    {Formatter.formatNumber(
                      2,
                      estoque ? store.tanques.get(item.lote_tanque?.tanque?.id).biomassa / 1000 : 0,
                    )}{' '}
                    Kg
                  </p>
                </div>
                <div>
                  <b className="left" style={{ color: '#C80000' }}>
                    Nova venda
                  </b>
                  <p style={{ color: '#C80000' }}>+{Formatter.formatNumber(0, item.quantidade)} un</p>
                  <p className="right" style={{ color: '#C80000' }}>
                    {Formatter.formatNumber(2, item.biomassa / 1000)} kg
                  </p>
                </div>
              </TanqueInfo>
            );
          })}
        </Collapse>
      </div>
    );
  });

  useEffect(() => {
    if (state.filtroRapidoCliente) {
      (tableRef.current as any).onQueryChange();
    }
  }, [state.filtroRapidoCliente]);

  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn
            disabled={!temPermissaoEscrita}
            onClick={() => {
              state.selectedVenda = {} as Venda;
              modalVenda.open();
            }}
            text="ADICIONAR"
            Icon={AddIcon}
          />

          <div style={{ width: 200 }}>
            <Select
              value={state.filtroRapidoCliente}
              placeholder="Cliente"
              onChange={(e: any) => (state.filtroRapidoCliente = e)}
              options={store.clientes.getSelectRows()}
            />
          </div>
        </Flex>

        <Flex>
          {/* <IconButton onClick={modalGerarRelatorio.open} className="blue">
            <PrintIcon />
          </IconButton> */}
          <Filtro sync={filtrar} clear={limparFiltro}>
            <div style={{ width: '90%' }}>
              <Select
                value={state.filtroLote}
                placeholder="Lote"
                onChange={(e: any) => (state.filtroLote = e)}
                options={store.lotes?.map((lote) => {
                  return { label: lote.codigo, value: lote.id.toString() };
                })}
              />
            </div>
            <div style={{ width: '90%' }}>
              <Select
                disabled
                value={state.filtroCliente}
                placeholder="Cliente"
                onChange={(e: any) => (state.filtroCliente = e)}
                options={store.clientes.getSelectRows()}
              />
            </div>
          </Filtro>
        </Flex>
      </Header>

      <Body gridColumns="1fr 500px">
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          <MaterialTable
            tableRef={tableRef}
            columns={columns}
            data={(query) =>
              new Promise((resolve) => {
                try {
                  store.propriedade &&
                    apiV2
                      .get('/venda/', {
                        params: {
                          page_size: PAGE_SIZE,
                          page: query.page + 1,
                          prop: store.propriedade!.id,
                          cliente:
                            state.filtroRapidoCliente === null
                              ? state.filtroCliente?.value
                              : state.filtroRapidoCliente?.value,
                          lote: state.filtroLote?.value,
                        },
                      })
                      .then((res) => {
                        if (res.data.count) {
                          if (res.data.count >= PAGE_SIZE) {
                            state.setPageSize(PAGE_SIZE);
                          } else {
                            state.setPageSize(res.data.count);
                          }
                        } else {
                          state.setPageSize(0);
                        }

                        resolve({
                          data: rows(res.data.results),
                          page: query.page,
                          totalCount: res.data.count,
                        });
                      });
                } catch (err) {
                  X9Logger.report(err as Error);
                  store.notificar('Ocorreu um erro ao adquirir informações. Tente novamente mais tarde!');
                }
              })
            }
            options={{ ...config.options, pageSize: PAGE_SIZE, paging: true }}
            localization={config.localization}
            style={config.style}
            onRowClick={(_e, rowData: any) => {
              state.selectedVenda = rowData.props;
              modalVisualizar.open();
            }}
          />
        </div>
        <div>
          <Display style={{ height: 260 }} overflowY={false} title="Gerar Relatório">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data Inicial"
                value={state.data_ini}
                onChange={(date) => (state.data_ini = date!)}
              />

              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data Final"
                value={state.data_fim}
                onChange={(date) => (state.data_fim = date)}
              />
              <Botao onClick={getPDF} cor="#00C853">
                Gerar Relatório
              </Botao>
            </div>
          </Display>

          <div>
            <Display style={{ marginTop: '20px', height: 250 }} overflowY={false} title="Vendas dos últimos 6 meses">
              {graficoBarra.series && graficoBarra.options && (
                <ReactApexChart
                  options={graficoBarra.options as any}
                  series={graficoBarra.series}
                  type="bar"
                  height={170}
                />
              )}
            </Display>
          </div>
        </div>
      </Body>

      {modalProduto.state ? (
        <FormProdutos
          onConfirm={() => {
            sync();
            (tableRef.current as any).onQueryChange();
          }}
          open={modalProduto.state}
          handleClose={modalProduto.close}
        />
      ) : null}
      {modalVenda.state ? (
        <FormVenda
          onConfirm={() => {
            sync();
            (tableRef.current as any).onQueryChange();
          }}
          open={modalVenda.state}
          handleClose={modalVenda.close}
        />
      ) : null}
      {modalVisualizar.state ? (
        <VisualizarVenda open={modalVisualizar.state} handleClose={modalVisualizar.close} venda={state.selectedVenda} />
      ) : null}
      {modalGerarRelatorio.state ? (
        <GerarRelatorio open={modalGerarRelatorio.state} handleClose={modalGerarRelatorio.close} />
      ) : null}
      <Confirmar
        open={modalExclusao.state}
        content={<ExcluirVenda />}
        title="Tem certeza que deseja excluir?"
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={deletar}
      />
    </>
  ) : null;
};

export default observer(MinhasVendas);
