import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Body, Conteudo } from '../../../../components/Body';
import MaterialTable, { Column } from 'material-table';
import Formatter from '../../../../services/Formatter';
import useStore from '../../../../services/hooks/useStore';
import { newConfigFornecedor } from '../../../../components/Tabela';
import { aqReports, apiMain } from '../../../../services/utils';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Flex } from '../../../../components/NavBar/styles';
import { Button, makeStyles } from '@material-ui/core';
import { ReportButton } from '../styles';
import Select from '../../../../components/Select';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ModalDespesasFornecedor from './ModalDespesasFornecedor';
import useModal from '../../../../services/hooks/useModal';

type ResumoDespesaFornecedor = {
  id: number;
  nome: string;
  valor: string;
};

const useInputStyles = makeStyles({
  root: {
    width: '10vw',
    verticalAlign: 'middle',
    fontSize: '14px',
    border: 'solid 1px #303030',
    borderRadius: '5px',
    paddingLeft: '5px',
    margin: '0 15px',
  },
});

const DepesaFornecedor: React.FC = () => {
  const store = useStore();
  const modalDespesasFornecedor = useModal();

  const [despesaSelecionada, setDespesaSelecionada] = useState<ResumoDespesaFornecedor | null>(null);
  const [tableData, setTableData] = useState<ResumoDespesaFornecedor[]>([]);
  const [totais, setTotais] = useState<number>(0);

  const currentDate = new Date();
  const initialDate = new Date();
  initialDate.setDate(1);
  const [filtroDate, setFiltroDate] = useState({
    dataInicial: {
      value: initialDate as MaterialUiPickersDate,
      valueFormatted: Formatter.getData(Formatter.dateToString(initialDate)),
    },
    dataFinal: {
      value: currentDate as MaterialUiPickersDate,
      valueFormatted: Formatter.getData(Formatter.dateToString(currentDate)),
    },
  });
  const [cliente, setCliente] = useState({
    label: '',
    value: '',
    id: '',
  });
  const [fornecedorTipo, setFornecedorTipo] = useState({
    label: '',
    value: '',
    id: '',
  });
  const columns: Column<ResumoDespesaFornecedor>[] = [
    {
      field: 'nome',
      title: 'Fonecedor',
      type: 'string',
      headerStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      cellStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      ...(true && ({ width: '30w' } as object)),
    },
    {
      field: '',
      title: '',
      type: 'string',
      headerStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      cellStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      render: (rowData) => {
        return (
          <Button onClick={() => handleOpenModalDespesasFornecedor(rowData)}>
            <VisibilityIcon /> Ver despesas
          </Button>
        );
      },
      ...(true && ({ width: '33w' } as object)),
    },

    {
      field: 'valor',
      title: 'Valor (R$)',
      type: 'numeric',
      headerStyle: {
        textAlign: 'right',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      cellStyle: {
        textAlign: 'right',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.valor);
      },
      ...(true && ({ width: '33vw' } as object)),
    },
  ];

  const inputClasses = useInputStyles();

  async function syncTable() {
    store.tiposDeFornecedor.populate(); //passar o store depois da req
    aqReports
      .get('/financeiro/' + store.propriedade?.uuid + '/resumo-listagem-despesas', {
        params: {
          data_inicio: filtroDate.dataInicial.valueFormatted,
          data_fim: filtroDate.dataFinal.valueFormatted,
          fornecedor: cliente.id !== '' ? cliente.id : undefined,
          fornecedor_tipo: fornecedorTipo.id !== '' ? fornecedorTipo.id : undefined,
        },
      })
      .then((res) => {
        const results = res.data.results;
        setTotais(res.data.total as number);
        setTableData(results);
      });
    store.fornecedores.populate();
  }

  function handleOpenModalDespesasFornecedor(despesa: ResumoDespesaFornecedor) {
    setDespesaSelecionada(despesa);
    modalDespesasFornecedor.open();
  }
  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Relatório Financeiro'), []);

  useEffect(() => {
    if (store.propriedade) {
      syncTable();
    }

    // eslint-disable-next-line
  }, [store.propriedade]);

  async function getPDF() {
    try {
      const pdf = await apiMain.get(`/gerador/pdf/financeiro/${store.propriedade?.id}/resumo-listagem-despesas/`, {
        params: {
          data_inicio: filtroDate.dataInicial.valueFormatted,
          data_fim: filtroDate.dataFinal.valueFormatted,
          authori: store.token,
          fornecedor: cliente.id !== '' ? cliente.id : undefined,
        },
      });
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }

  async function getCSV() {
    try {
      const pdf = await apiMain.get(`/gerador/csv/financeiro/${store.propriedade?.id}/resumo-listagem-despesas/`, {
        params: {
          data_inicio: filtroDate.dataInicial.valueFormatted,
          data_fim: filtroDate.dataFinal.valueFormatted,
          authori: store.token,
          fornecedor: cliente.id !== '' ? cliente.id : undefined,
        },
      });
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o CSV! Favor entrar em contato com o Suporte.');
    }
  }

  return (
    <>
      <Body style={{ backgroundColor: 'white' }}>
        <Link
          to={'/financeiro/painel/relatorio-financeiro'}
          style={{ color: '#049CE7', textDecoration: 'none', fontFamily: 'Roboto' }}
        >
          <Flex>
            <img src={'/images/returnArrow.svg'} />
            <div style={{ paddingLeft: '5px', fontFamily: 'Roboto', color: '#049CE7' }}>Lista de Relatórios</div>
          </Flex>
        </Link>
        <div style={{ fontWeight: 'bold', fontSize: '24px', height: '30px' }}>Resumo de Despesas por Fornecedor</div>
        <Flex
          style={{
            width: '100%',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            value={filtroDate.dataInicial.value}
            onChange={(date) => {
              setFiltroDate({
                ...filtroDate,
                dataInicial: {
                  value: date,
                  valueFormatted: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                },
              });
            }}
            InputProps={{ classes: inputClasses }}
          />
          <p>até</p>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            value={filtroDate.dataFinal.value}
            onChange={(date) => {
              setFiltroDate({
                ...filtroDate,
                dataFinal: {
                  value: date,
                  valueFormatted: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                },
              });
            }}
            InputProps={{ classes: inputClasses }}
          />

          {store.fornecedores?.arr && (
            <div style={{ width: '200px', marginLeft: '20px' }}>
              <Select
                placeholder="Escolha um fornecedor"
                value={cliente}
                options={store.fornecedores?.arr.map((programa) => {
                  return { label: programa.nome, value: programa.uuid, id: programa.id };
                })}
                onChange={(e: any) => {
                  setCliente(e);
                }}
                width={false}
              />
            </div>
          )}
          {store.tiposDeFornecedor?.arr && (
            <div style={{ width: '200px', marginLeft: '8px' }}>
              <Select
                placeholder="Tipo de Fornecedor"
                value={fornecedorTipo}
                options={store.tiposDeFornecedor?.map((f: any) => {
                  return {
                    label: f.nome,
                    value: f.uuid,
                    id: f.id,
                  };
                })}
                onChange={(e: any) => {
                  setFornecedorTipo(e);
                }}
                width={false}
              />
            </div>
          )}

          <ReportButton style={{ marginLeft: '10px' }} onClick={() => syncTable()}>
            Gerar Relatório
          </ReportButton>

          <Button className="blue" onClick={getPDF}>
            <Flex>
              <div style={{ paddingRight: '10px', fontFamily: 'Roboto', color: '#303030', fontSize: '12px' }}>
                Exportar PDF
              </div>
              <img src={'/images/exportPDF.svg'} />
            </Flex>
          </Button>
          <Button className="blue" onClick={getCSV}>
            <Flex>
              <div style={{ paddingRight: '10px', fontFamily: 'Roboto', color: '#303030', fontSize: '12px' }}>
                Exportar CSV
              </div>
              <img src={'/images/exportCSV.svg'} />
            </Flex>
          </Button>
        </Flex>
      </Body>

      <Conteudo style={{ padding: 0 }}>
        <MaterialTable
          columns={columns}
          data={tableData}
          options={{
            ...newConfigFornecedor.options,
            paging: true,
            sorting: false,
            pageSize: 10,
          }}
          localization={newConfigFornecedor.localization}
          style={{ ...newConfigFornecedor.style }}
        />
        <Flex
          style={{
            width: '100%',
            padding: '1rem',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '0 auto',
            marginBottom: '-15px',
            border: '1px solid #999',
          }}
        >
          <p>TOTAL GERAL</p>

          <strong>R$ {Formatter.formatNumber(2, totais)} </strong>
        </Flex>
      </Conteudo>

      {despesaSelecionada && (
        <ModalDespesasFornecedor
          open={modalDespesasFornecedor.state}
          handleClose={modalDespesasFornecedor.close}
          despesaFornecedor={despesaSelecionada}
          dataFinal={filtroDate.dataFinal.value}
          dataInicial={filtroDate.dataInicial.value}
        />
      )}
    </>
  );
};

export default observer(DepesaFornecedor);
