import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { useLocalStore, observer } from 'mobx-react-lite';
import { TextField } from '@material-ui/core';
import useStore from '../../../services/hooks/useStore';
import Botao from '../../../components/Botoes/Botao';
import { KeyboardDatePicker } from '@material-ui/pickers';
import InputNumero from '../../../components/InputNumero';
import { api } from '../../../services/utils';
import { returnTanque } from '../../../services/utils/propriedade';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import useModal from '../../../services/hooks/useModal';
import Select from '../../../components/Select';
import { returnQuantidade } from '../../../services/utils/producao';
import Formatter from '../../../services/Formatter';
import SelecionarLoteTanques from './SelecionarLoteTanques';

type State = {
  causa: SelectRow | null;
  lote: SelectRow | null;
  tanque: SelectRow | null;
  data: MaterialUiPickersDate;
  quantidade: number;
  observacao: string;
  tanqueNome: string;
  selectTanque: boolean;
  selectLote: boolean;
};
type Props = ModalProps & { mortalidadeID: number };
const FormMortalidade: React.FC<Props> = ({ open, handleClose, mortalidadeID }) => {
  const store = useStore();
  const mortalidade = store.mortalidades?.get(mortalidadeID);
  const loteTanque = mortalidade ? store.loteTanque.getByUUID(mortalidade.lote_tanque) : null;
  const state = useLocalStore(
    (): State => ({
      causa: mortalidade ? { value: mortalidade.tipo_morte.id.toString(), label: mortalidade.tipo_morte.nome } : null,
      lote:
        mortalidade && loteTanque
          ? {
              value: loteTanque.lote.id.toString(),
              label: loteTanque.lote.codigo,
            }
          : null,
      tanque:
        mortalidade && loteTanque
          ? {
              value: loteTanque.tanque.id.toString(),
              label: returnTanque(loteTanque!.tanque!),
            }
          : null,
      data: mortalidade
        ? new Date(
            mortalidade.data.substr(3, 2) + '/' + mortalidade.data.substr(0, 2) + '/' + mortalidade.data.substr(6, 4),
          )
        : new Date(),
      quantidade: mortalidade ? mortalidade.quantidade : 0,
      observacao: mortalidade ? mortalidade.observacao : '',
      tanqueNome: mortalidade ? returnTanque(loteTanque!.tanque!) : '',

      selectTanque: false,
      selectLote: false,
    }),
  );
  const modalSelecionarTanque = useModal();
  // const modalSelecionarLote = useModal();

  useEffect(() => {
    if (state.tanque) {
      state.tanqueNome = state.tanque.label;
    }
    // eslint-disable-next-line
  }, [state.tanque]);

  async function cadastrar(): Promise<void> {
    if (!state.causa) store.notificar('Selecione a causa da morte');
    else if (!state.lote) store.notificar('Selecione um lote');
    else if (!state.tanque) store.notificar('Selecione um tanque');
    else if (state.quantidade === 0) store.notificar('Informe a quantidade');
    else if (
      !mortalidade &&
      state.quantidade >
        returnQuantidade(
          store.loteTanque.getByLoteTanque(parseInt(state.lote!.value), parseInt(state.tanque.value)),
          false,
        )
    )
      store.notificar('A quantidade excede o total de animais do tanque');
    else if (
      mortalidade &&
      state.quantidade >
        returnQuantidade(
          store.loteTanque.getByLoteTanque(parseInt(state.lote!.value), parseInt(state.tanque.value)),
          false,
        ) +
          mortalidade.quantidade
    )
      store.notificar('A quantidade excede o total de animais do tanque');
    else {
      handleClose();
      store.toggleLoader();

      const loteTanque = store.loteTanque.getByLoteTanque(parseInt(state.lote!.value), parseInt(state.tanque.value));

      if (loteTanque) {
        const data = {
          lote_tanque: loteTanque.id,
          data: Formatter.dateToString(state.data),
          quantidade: state.quantidade,

          tipo_morte: parseInt(state.causa.value),
          observacao: state.observacao,
        };

        if (mortalidade) {
          await api
            .put(`/mortalidade/${mortalidade.id}/?propriedade=${store.propriedade!.id}`, data)
            .then(async () => {
              await Promise.all([store.mortalidades.populate(), store.loteTanque.populate(), store.lotes.populate()]);
              store.notificar('Mortalidade editada com sucesso!');
            })
            .catch(() => {
              store.notificar('Ocorreu um erro ao editar a mortalidade. Tente novamente.');
            });
        } else {
          await api
            .post(`/mortalidade/?propriedade=${store.propriedade!.id}`, data)
            .then(async () => {
              await Promise.all([store.mortalidades.populate(), store.loteTanque.populate(), store.lotes.populate()]);
              store.notificar('Mortalidade cadastrada com sucesso!');
            })
            .catch(() => {
              store.notificar('Ocorreu um erro ao cadastrar a mortalidade. Tente novamente.');
            });
        }
      } else {
        store.notificar('O lote_tanque não foi encontrado!');
      }
      store.toggleLoader();
    }
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{mortalidade ? 'Edição' : 'Cadastro'} de Mortalidade</ModalHeader>
      <ModalBody>
        <div style={{ width: '540px' }}>
          <div style={{ width: '100%' }}>
            {store.tiposDeMorte.arr && (
              <Select
                value={state.causa}
                placeholder="Causa da morte"
                onChange={(e: any) => (state.causa = e)}
                options={store.tiposDeMorte.getSelectRows()}
              />
            )}
          </div>
          <div style={{ width: '100%' }}>
            <Select
              value={state.tanque}
              placeholder="Tanque"
              onFocus={(e) => {
                e.target.blur();
                modalSelecionarTanque.open();
              }}
              options={[]}
            />
          </div>

          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data"
            value={state.data}
            onChange={(date) => (state.data = date)}
          />

          <InputNumero
            value={state.quantidade}
            onChange={(value) => (state.quantidade = value)}
            precision="0"
            label="Quantidade"
          />
          <TextField
            label="Observação *Opcional"
            value={state.observacao}
            onChange={(e) => (state.observacao = e.target.value)}
          />

          <div style={{ width: '100%', display: 'flex' }}>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao onClick={cadastrar} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          </div>
        </div>
      </ModalBody>
      <SelecionarLoteTanques
        open={modalSelecionarTanque.state}
        handleClose={modalSelecionarTanque.close}
        setTanque={(tanque) => (state.tanque = tanque)}
        setLote={(lote) => (state.lote = { label: lote.codigo, value: lote.id.toString() })}
        exclude={[]}
      />
    </Modal>
  );
};

export default observer(FormMortalidade);
