import { observable, computed } from 'mobx';
import { api } from '../utils';

class QualidadesDaAgua {
  @observable arr: QualidadeDaAgua[] | null = null;
  @observable totalRes: number = 0;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async getPaginated({
    page,
    page_size,
    propriedade,
    tanque,
    parametro_valor,
    data_final,
    data_inicial,
  }: QualidadeDaAguaParametrosRequsicao): Promise<void> {
    api
      .get<PaginacaoQualidadeDaAgua>(`/analise-agua/?propriedade=${propriedade}`, {
        params: {
          page_size,
          page: page + 1,
          propriedade,
          tanque,
          parametro_valor,
          data_final,
          data_inicial,
        },
      })
      .then((res) => {
        this.totalRes = res.data.count;
        this.arr = res.data.results;
      });
  }

  get(id: number): QualidadeDaAgua {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  filter(callback: (item: QualidadeDaAgua, index: number, array: QualidadeDaAgua[]) => void): QualidadeDaAgua[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: QualidadeDaAgua, index: number, array: QualidadeDaAgua[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default QualidadesDaAgua;
