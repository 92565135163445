import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GlobalStyles from './styles/global';
import checkMobile from './middlewares/checkMobile';
import Mobile from './pages/Login/Mobile';
import * as serviceWorker from './serviceWorker';
import './styles/font.css';
import { ThemeProvider } from 'styled-components';
import lightTheme from './styles/themes/light';

async function init(): Promise<void> {
  const isMobile = checkMobile();

  if (!isMobile) {
    ReactDOM.render(
      <React.StrictMode>
        <ThemeProvider theme={lightTheme}>
          <GlobalStyles />
          <App />
        </ThemeProvider>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  } else {
    ReactDOM.render(
      <React.StrictMode>
        <ThemeProvider theme={lightTheme}>
          <GlobalStyles />
          <Mobile />
        </ThemeProvider>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  }
}

init();
serviceWorker.register();
