/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Header from '../../../components/Header';
import { Flex } from '../../../components/NavBar/styles';
import AddBtn from '../../../components/Botoes/AddBtn';
import { Body } from '../../../components/Body';
import { config } from '../../../components/Tabela';
import useStore from '../../../services/hooks/useStore';
import Filtro from '../../../components/Filtro';
import MaterialTable, { Column } from 'material-table';
import { useLocalStore, observer } from 'mobx-react-lite';
import useModal from '../../../services/hooks/useModal';
import ActionButton from '../../../components/Tabela/ActionButton';
import Confirmar from '../../../components/Modais/Confirmar';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Select from '../../../components/Select';
import FormCategoria from './FormCategoria';
import { useHistory } from 'react-router-dom';
import syncModel from '../../../services/models/Sync';
import { permissoes } from '../../../services/utils/permissoes';
import X9Logger from '../../../services/X9Logger';

export const tipos = [
  { label: 'Vendas', value: 'vendas' },
  { label: 'Despesas', value: 'despesas' },
  { label: 'Outras Entradas', value: 'outras' },
  { label: 'Compras', value: 'compras' },
  { label: 'Produtos', value: 'produtos' },
];

const Categorias: React.FC = () => {
  const [categoriasPesquisadas, setCategoriasPesquisadas] = useState<Categoria[] | undefined>();
  const state = useLocalStore(() => ({
    selectedCategoria: 0,
    filtroRapidoTipo: null as SelectRow | null,
    rateio: false,
    sync: false,

    pageSize: 0,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
  }));
  const modalCategoria = useModal();
  const modalExclusao = useModal();
  const store = useStore();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.FINANCEIRO_CATEGORIA_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.FINANCEIRO_CATEGORIA_ESCRITA);

  const columns: Column<object>[] = [
    {
      field: 'nome',
      title: 'Nome',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'acoes',
      render: ({ id, editavel, rateio }: any) => (
        <ActionButton
          onClick={() => {
            if (!editavel || !temPermissaoEscrita) {
              state.rateio = false;
              store.notificar('Não pode editar ou excluir essa categoria!');
            }
          }}
          editar={
            !temPermissaoEscrita || !editavel
              ? undefined
              : () => {
                  state.selectedCategoria = id;
                  state.rateio = rateio;
                  modalCategoria.open();
                }
          }
          excluir={
            !temPermissaoEscrita || !editavel
              ? undefined
              : () => {
                  state.selectedCategoria = id;
                  modalExclusao.open();                 
                }
          }
        />
      ),
      ...(true && ({ width: 25 } as object)),
    },
    {
      field: 'tipo',
      title: 'Tipo',
      type: 'numeric',
      render: ({ tipo }: any) => {
        if (tipo === 'despesas') {
          return <span style={{ color: 'red' }}>Despesas</span>;
        } else if (tipo === 'vendas') {
          return <span style={{ color: 'green' }}>Vendas</span>;
        } else if (tipo === 'compras') {
          return <span style={{ color: 'blue' }}>Compras</span>;
        } else if (tipo === 'produtos') {
          return <span style={{ color: 'orange' }}>Produtos</span>;
        } else return <span>Outras Entradas</span>;
      },
      ...(true && ({ width: 140 } as object)),
    },

    {
      field: 'editavel',
      title: 'Editável',
      type: 'numeric',
      render: ({ editavel }: any) => {
        return editavel ? (
          <CheckCircleOutlineIcon style={{ color: 'green' }} />
        ) : (
          <HighlightOffIcon style={{ color: 'red' }} />
        );
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      align: 'right',
      field: 'rateio',
      title: 'Rateio',
      render: ({ rateio }: any) => {
        return rateio ? 'Sim' : 'Não';
      },
      ...(true && ({ width: 140 } as object)),
    },
  ];
  const rows = (categorias: Categoria[]): object[] => {
    if (categorias.length) {
      if (categorias.length >= 50) {
        state.setPageSize(50);
      } else {
        state.setPageSize(categorias.length);
      }
    } else {
      state.setPageSize(0);
    }
    return categorias;
  };

  async function sync(): Promise<void> {
    await syncModel.categorias();

    state.sync = true;
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Categorias'), []);

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  // Filtro Rápido
  useEffect(() => {
    if (state.filtroRapidoTipo) {
      const categorias = store.categorias.getByTipo(state.filtroRapidoTipo!.value);
      setCategoriasPesquisadas(categorias);
    }
    // eslint-disable-next-line
  }, [state.filtroRapidoTipo]);

  function limparFiltro(): void {
    state.filtroRapidoTipo = null;
    setCategoriasPesquisadas(undefined);
  }

  async function deletar(): Promise<void> {
    modalExclusao.close();
    store.toggleLoader();
    try {
      await store.categorias.excluir(state.selectedCategoria);
      store.toggleLoader();
    } catch (err: any) {
      X9Logger.report(err as Error);
      if (err.response && err.response.data) {
        Object.values(err.response.data).forEach((value) => {
          store.notificar(`${value}`);
        });
      }
      store.toggleLoader();
    }
  }

  const ExcluirCategoria: React.FC = () => {
    const categoria = store.categorias.get(state.selectedCategoria);

    return (
      <div style={{ width: '100%' }}>
        <b>Nome: </b> {categoria.nome}
        <br />
        <b>Tipo: </b> {tipos.filter((tipo) => tipo.value === categoria.tipo)[0].label}
      </div>
    );
  };

  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn
            disabled={!temPermissaoEscrita}
            onClick={() => {
              state.selectedCategoria = 0;
              modalCategoria.open();
            }}
            text="ADICIONAR"
            Icon={AddIcon}
          />
          <div style={{ width: 180 }}>
            <Select
              value={state.filtroRapidoTipo}
              placeholder="Tipo"
              onChange={(e: any) => (state.filtroRapidoTipo = e)}
              options={tipos}
            />
          </div>
        </Flex>
        <Flex>
          <Filtro sync={sync} clear={limparFiltro}></Filtro>
        </Flex>
      </Header>

      <Body grid={true}>
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          {store.categorias.arr && (
            <MaterialTable
              columns={columns}
              data={rows(categoriasPesquisadas || store.categorias.arr!)}
              options={{ ...config.options, pageSize: state.pageSize }}
              localization={config.localization}
              style={config.style}
            />
          )}
        </div>
      </Body>
      {modalCategoria.state ? (
        <FormCategoria
          open={modalCategoria.state}
          handleClose={modalCategoria.close}
          categoriaID={state.selectedCategoria}
          rateio={state.rateio}
        />
      ) : null}
      <Confirmar
        open={modalExclusao.state}
        content={<ExcluirCategoria />}
        title="Tem certeza que deseja excluir?"
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={deletar}
      />
    </>
  ) : null;
};

export default observer(Categorias);
