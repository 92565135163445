export const fakeUsuario = {
  id: 0,
  email: '',
  inscricao_federal: '',
  nome: '',
  permissoes: [],
  plano: { id: 0, descricao_plano: '', observacao: '', subtitulo: '', titulo: '', valor: '' },
  propriedades: [],
  responsavel: false,
  telefone: '',
  uuid: '',
  use_terms: false,
  tipo_de_usuario: { id: 0, nome: '', slug: '', uuid: '' },
};

export const fakePropriedade: Propriedade = {
  id: 0,
  nome: '',
  uuid: '',
  cor:'',
  ativo: true,
  cidade: { estado_id: 0, id: 0, nome: '' },
  estado: { uuid: '', id: 0, nome: '' },
  cnpj: '',
  email: '',
  telefone: '',
  atividades: [],
  inscricao_estadual: '',
  licenca_ambiental: '',
  usuarios: [
    {
      id: 0,
      uuid: '',
      email: '',
      nome: '',
    },
  ],
};
