import { getThings, api } from '../utils';
import { observable, computed } from 'mobx';
import { stateContext } from '../hooks/useStore';

type ContaData = {
  propriedade: number;
  titulo: string;
  saldo_inicial: number;
  saldo_atual: number;
  conta_padrao: boolean;
  inativo: boolean;
};

class ContasBancarias {
  @observable arr: Conta[] | null = null;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    this.arr = await getThings<Conta>('/conta-bancaria/');
  }

  async getEverything(params?: object): Promise<Conta[]> {
    return await getThings<Conta>('/conta-bancaria/', params);
  }

  get(id: number): Conta | null {
    return this.arr?.filter((item) => item.id === id)[0] || null;
  }

  getContaPadrao(): Conta | null {
    return this.arr?.filter((item) => item.conta_padrao)[0] || null;
  }

  getAtivas(): Conta[] | null {
    return this.arr?.filter((item) => !item.inativo) || null;
  }

  getSelectRows(): SelectRow[] {
    const padrao = this.getContaPadrao();
    const selectRows: SelectRow[] = [];

    if (padrao) {
      selectRows.push({ label: padrao?.titulo, value: padrao?.id.toString() });
    }

    this.arr
      ?.filter((item) => !item.conta_padrao)
      .map((item) => {
        return selectRows.push({ label: item.titulo, value: item.id.toString() });
      });

    return selectRows;
  }

  getSelectRowsUUID(): SelectRow[] {
    const padrao = this.getContaPadrao();
    const selectRows: SelectRow[] = [];

    if (padrao) {
      selectRows.push({ label: padrao.titulo, value: padrao.uuid });
    }

    this.arr
      ?.filter((item) => !item.conta_padrao)
      .map((item) => {
        return selectRows.push({ label: item.titulo, value: item.uuid });
      });

    return selectRows;
  }

  async criar(dados: ContaData): Promise<void> {
    const store = stateContext.state;

    await api
      .post(`/conta-bancaria/?propriedade=${store.propriedade?.id}`, dados)
      .then(async () => {
        store.notificar('Conta cadastrada com sucesso!');
        await store.contas.populate();
      })
      .catch(() => store.notificar('Ocorreu um erro ao cadastrar a conta!'));
  }

  async editar(contaID: number, dados: ContaData): Promise<void> {
    const store = stateContext.state;

    await api
      .put(`/conta-bancaria/${contaID}/?propriedade=${store.propriedade!.id}`, dados)
      .then(async () => {
        store.notificar('Conta editada com sucesso!');
        await store.contas.populate();
      })
      .catch(() => store.notificar('Ocorreu um erro ao editar a conta!'));
  }

  async excluir(contaID: number): Promise<void> {
    const store = stateContext.state;

    if (store.movimentacoes.arr?.some((movimentacao) => movimentacao.conta.id === contaID))
      store.notificar('A conta contém movimentações, logo não pode ser excluída!');
    else {
      await api
        .delete(`/conta-bancaria/${contaID}/?propriedade=${store.propriedade?.id}`)
        .then(() => store.notificar('Conta excluída com sucesso!'));
      await store.contas.populate();
    }
  }

  filter(callback: (item: Conta, index: number, array: Conta[]) => void): Conta[] {
    return this.arr?.filter(callback) || [];
  }

  map(callback: (item: Conta, index: number, array: Conta[]) => any): any {
    return this.arr?.map(callback) || [];
  }
}

export default ContasBancarias;
