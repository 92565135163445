import { CompraInsumo } from '../../../../@types/Insumo';
import { stateContext } from '../../../../services/hooks/useStore';
import State from '../../../../services/State';
import { api, apiV2, getThingsV2 } from '../../../../services/utils';

async function excluirCompraInsumos(store: State, compra: CompraInsumo, handleClose: Function): Promise<void> {
  handleClose();
  store.toggleLoader();

  const compraInsumo = store.comprasDeInsumos.comprasDeInsumos?.filter((compraInsumo) => {
    return compraInsumo.id === compra?.id ? compraInsumo : null;
  });

  // busca a compra selecionada para verificar se ela possui movimentação definida
  await apiV2
    .get(`/comprar-insumo/${compra!.id}/?propriedade=${store.propriedade!.id}`)
    .then(async (compraSelecionada: any) => {
      // valida a condição da definição de movimentação, caso esteja definida, exclui primeiro a movimentação depois a compra
      if (compraSelecionada.data.movimentacao) {
        await api
          .delete(`/movimentacao/${compraInsumo![0].movimentacao}/?propriedade=${store.propriedade!.id}`)
          .then(async () => {
            await apiV2.delete(`/comprar-insumo/${compra!.id}/?propriedade=${store.propriedade!.id}`).catch(() => {
              store.notificar('compra não excluída!');
            });
          })
          .catch(() => {
            store.notificar('movimentação não excluida!');
          });
      } else {
        // caso não haja movimentação definida na compra, deleta-se apenas a compra
        await apiV2.delete(`/comprar-insumo/${compra!.id}/?propriedade=${store.propriedade!.id}`).catch(() => {
          store.notificar('compra não excluída!');
        });
      }
    })
    .catch((err) => {
      console.log(err);
      store.notificar('compra selecionada não identificada.');
    });
  store.comprasDeInsumos.comprasDeInsumos = await getThingsV2<CompraInsumo>('/comprar-insumo/', {
    propriedade: stateContext.state.propriedade!.id,
  });
  Promise.all([store.insumos.populate(), store.comprasDeInsumos.populate(), store.movimentacoes.populate()]).then(
    () => {
      store.toggleLoader();
    },
  );
}

export default excluirCompraInsumos;
