import styled, { css } from 'styled-components';
import { size } from 'polished';
import Button from '@material-ui/core/Button';

export const Container = styled.div`
  ${size('100vh', '100vw')};

  display: flex;
  justify-content: center;

  background-image: url('/images/login_background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: white;
`;

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50%;
`;

export const LoginBox = styled.form`
  padding: 20px;
  background-color: #fff;
  box-shadow: -3px 3px 6px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50%;
  margin: 15px 0;
`;

export const LoginButton = styled(Button)`
  width: 100% !important;
  // margin-top: 10px !important;
  background-color: #059ce6 !important;
`;
export const LoginSecButton = styled.button`
  color: #059ce6;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  /* width: 60%; */

  font-size: 14px;
  font-weight: 500;

  border-radius: 30%;

  &:focus {
    outline: none;
  }
`;

export const LoginMessage = styled.div`
  border: 1px solid #575757;
  border-radius: 5px;
  color: #575757;
  font-size: 14px;
  padding: 15px;
  text-align: center;
  width: 100%;
`;

export const ImageContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-end;

  img {
    width: 90%;
  }
`;

export const LoginFooter = styled.div`
  width: 50%;
  text-align: center;
  color: #575757;
  font-size: 12px;

  a {
    color: #059ce6;
    font-size: 16px;
    text-decoration: none;
  }
`;

type Props = {
  secondary?: boolean;
  disabled?: boolean;
};
export const BotaoApp = styled.a<Props>`
  background-color: #00557f;
  text-decoration: none;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 5px;
  width: 45%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  color: white;
  margin-top: 10px;

  border: 1px solid #00557f;

  ${(props) =>
    props.secondary &&
    css`
      color: #00557f;
      background-color: white;
    `}
`;

export const LoginCadastrar = styled.button<Props>`
  background-color: #059de7;
  text-decoration: none;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 8px 16px;
  width: 45%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: 500;
  color: white;

  border: 1px solid #00557f;
  cursor: pointer;

  ${(props) =>
    props.secondary &&
    css`
      color: #059ce6;
      background-color: white;
    `}
`;

export const BotaoCadastrar = styled.button<Props>`
  background-color: #059de7;
  text-decoration: none;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 8px 16px;
  width: 45%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: 500;
  color: white;

  border: 1px solid #059de7;
  cursor: pointer;

  ${(props) =>
    props.secondary &&
    css`
      background-color: #fc7467;
      border: 1px solid #fc7467;
    `}

  ${(props) =>
    props.disabled &&
    css`
      background-color: #ccc;
      border: 1px solid #ccc;
      cursor: inherit;
    `}
`;

export const Center = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100vw;
  height: 100vh;
  padding: 30px;
  h1 {
    text-align: center;
    margin-bottom: 15px;
  }
`;

export const BotoesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: star;
  flex-direction: column;

  width: 100%;
  margin: 8px 0 26px;
  padding: 0;
`;

export const LabelCheched = styled.label`
  display: flex;
  align-items: center;

  span {
    font-size: 14px !important;
    margin-left: 8px !important;
  }
`;

export const LoginChecked = styled.input`
  cursor: pointer !important;
`;

export const ListaTermos = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;

  font-size: 20px;
  font-weight: 500;

  li {
    list-style: none;
  }
`;

export const SelectContainer = styled.div`
  display: inline-block;
  position: relative;
  height: 33px;
  width: 100%;
  border-radius: 5px;
  background: white;
  border: 1px solid #000;
  overflow: hidden;
  vertical-align: middle;
  margin-top: 10px;

  &:before {
    content: '';
    width: 0;
    height: 0;
    margin-left: 5px;
    border-style: solid;
    border-width: 5px;
    border-color: #808080 transparent transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 10px;
    z-index: 2;
    pointer-events: none;
  }

  &:after {
    content: '';
    height: inherit;
    position: absolute;
    background-color: white;
    border-left: 1px solid #d8d8d8;
    pointer-events: none;
    width: 30px;
    right: 0;
    top: 0;
    z-index: 1;
  }
`;

export const SelectValues = styled.select`
  display: inline-block;
  position: relative;
  height: inherit;
  width: 100%;
  appearance: none;
  padding: 5px 42px 5px 10px;
  border: none;
  background-color: white;
  font-size: 14px;
  color: #404040;
  box-shadow: inset 0 1px 1px rgb(0, 0, 0, 0.08);
  cursor: pointer;
  z-index: 1;
`;
