import React from 'react';
import { ErrorContainer } from './styles';
import Botao, { BotaoSec } from '../../components/Botoes/Botao';
import { FallbackProps } from 'react-error-boundary';
import { Flex } from '../../components/NavBar/styles';
import { KeyboardArrowLeft } from '@material-ui/icons';

const ErroExecucao: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
  return (
    <ErrorContainer>
      <Flex style={{ flexDirection: 'column', alignItems: 'flex-start', maxWidth: 700 }}>
        <h1>Erro inesperado</h1>
        <p>Nossa equipe foi comunicada sobre o ocorrido e logo lançaremos a correção.</p>
        <p>
          Experimente clicar no botão VOLTAR para retornar à página anterior. Em caso de persistência do erro clique em
          VOLTAR AO INÍCIO e você será redirecionado à página inicial.
        </p>

        <Flex>
          <Botao variant="contained" onClick={resetErrorBoundary}>
            <KeyboardArrowLeft />
            VOLTAR
          </Botao>

          <BotaoSec cor="#42a5f5" onClick={() => (window.location.href = '/home')}>
            Voltar ao início
          </BotaoSec>
        </Flex>
      </Flex>

      <img src="/logo512.png" alt="Aquabit Web" />
    </ErrorContainer>
  );
};

export default ErroExecucao;
