import styled, { css } from 'styled-components';

type FiltroProps = {
  open: boolean;
  visibility: string | undefined;
};
export const FiltroContainer = styled.div<FiltroProps>`
  position: absolute;
  top: 0;
  z-index: 1000;
  right: -400px;
  display: none;
  transition: 0.2s;

  ${(props) =>
    props.visibility &&
    css`
      display: block;
    `}

  ${(props) =>
    props.open &&
    css`
      right: 0;
    `}

  background-color: white;
  box-shadow: 0 0 13px 0 #00000036;

  height: 100%;
  width: 400px;

  .MuiListItem-root {
    justify-content: space-between;
  }
  .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
    width: 100%;
  }
  .MuiSelect-root {
    width: 345px !important;
  }
`;

type ShadowProps = {
  state: boolean;
};
export const FiltroShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: 999;

  transition: 0.3s;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  cursor: pointer;

  ${(props: ShadowProps) =>
    props.state &&
    css`
      pointer-events: all;
      opacity: 1;
    `}
`;

export const FiltroHeader = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;

  display: flex;
  align-items: center;

  svg {
    color: white;
  }
`;

export const FiltroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  overflow-y: auto;
`;
