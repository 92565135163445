import React, { useEffect, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import useStore from '../../../services/hooks/useStore';
import { Modal, ModalHeader, ModalBody, Lote, Divisor } from '../../../components/Modais/styles';
import { Categoria, Categorias, Parcela } from '../../Vendas/MinhasVendas/styles';
import { Flex } from '../../../components/NavBar/styles';
import Botao from '../../../components/Botoes/Botao';
import useModal from '../../../services/hooks/useModal';
import { TextField, Checkbox, IconButton, Tooltip, FormControlLabel, Grid, Box } from '@material-ui/core';
import Select from '../../../components/Select';
import { KeyboardDatePicker } from '@material-ui/pickers';
import InputNumero from '../../../components/InputNumero';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import FormCliente from '../../Vendas/Clientes/FormCliente';
import AddIcon from '@material-ui/icons/Add';
import FinalizarLote from '../../../components/Modais/FinalizarLote';
import Formatter from '../../../services/Formatter';
import NewSelect from '../../../components/Select/newSelect';
import { Delete, Edit } from '@material-ui/icons';
import { ModalMovimentacaoItem } from './ModalMovimentacaoItem';

type State = {
  etapa: number;
  categoria: string;
  lote: number;
  descricao: string;
  valorTotais: number;
  valorUnitario: number;
  tanques: {
    bonificacao?: boolean;
    bonificacao_qtde?: number;
    bonificacao_porc?: number;
    item: Tanque;
    lote_tanque: number;
    quantidade?: number;
    biomassa: number;
    peso_medio: number;
    valorUnitario?: number;
    valorTotal?: number;
    use_qtde: boolean;
  }[];
  cliente: number;
  data: MaterialUiPickersDate;
  valor_unitario: number;
  valor_total: number;
  pesoMedio: number;
  dataEntrega: MaterialUiPickersDate;
  conta: SelectRow;
  documento: string;
  fornecedor: SelectRow | null;
  pagamento: SelectRow;
  qtdParcelas: number;
  parcelas: ParcelaData[];

  quantidade: number;
  biomassa: number;

  finalizarLote: boolean;
  finalizar: boolean;
  bonificacao: boolean;
  bonificacaoPorc: number;
  bonificacaoQtde: number;
  adicionar: boolean;
  collapse: boolean;
  sync: boolean;
  itens: DespesaItemCriacao[];
  valorFrete: number;
  outrosValores: number;

  gerarVenda(): Promise<void>;
  somaParcelas(): number;
  setPagamento(tipo: SelectRow): void;
  valorTotal(): number;
  setQtdParcelas(value: number): void;
  voltar(): void;
  avancar(): Promise<void>;
  somaValorItems(): number;
  somaValorItems(): number;
  somaValorTotal(): number;
  somaQuantidadeItems(): number;
};

type Props = ModalProps & {
  onConfirm(): void;
};

const FormProdutos: React.FC<Props> = ({ open, handleClose, onConfirm }) => {
  const store = useStore();
  const modalItem = useModal();

  const [pesquisaCliente, setPesquisaCliente] = useState('');
  const modalCliente = useModal();
  const modalFinalizarLote = useModal();
  const etapas = ['Selecione a categoria', 'Selecione o cliente', 'Detalhes da venda', 'Gerar financeiro'];
  const contaPadrao = store
    .contas!.filter((conta) => conta.conta_padrao)
    .map((conta) => {
      return {
        label: conta.titulo,
        value: conta.id.toString(),
      };
    });
  const state = useLocalStore(
    (): State => ({
      fornecedor: {} as SelectRow,
      valorTotais: 0,
      valorUnitario: 0,
      descricao: '',
      etapa: 0,
      sync: false,
      categoria: '',
      lote: 0,
      tanques: [],
      cliente: 0,
      data: new Date(),
      valor_unitario: 0,
      pesoMedio: 0,
      valor_total: 0,
      dataEntrega: new Date(),
      conta: contaPadrao[0],
      documento: '',

      pagamento: store.tiposDePagamento.getSelectRows()[0],
      qtdParcelas: 1,
      parcelas: [
        {
          num_parcela: 1,
          data_pagamento: Formatter.dateToString(new Date()),
          recebido: false,
          pagamento_tipo: store.tiposDePagamento.arr![0].id,
          valor: 0,
          conta: store.contas.arr![0].id,
        },
      ],

      quantidade: 0,
      biomassa: 0,

      finalizar: false,

      bonificacao: true,
      bonificacaoPorc: 0,
      bonificacaoQtde: 0,
      finalizarLote: false,
      adicionar: false,
      collapse: false,
      itens: [],
      valorFrete: 0,
      outrosValores: 0,

      setPagamento(tipo) {
        this.pagamento = tipo;
        this.parcelas.map((parcela) => {
          return (parcela.pagamento_tipo = tipo.value);
        });
      },
      setQtdParcelas(value) {
        this.qtdParcelas = value;
        this.parcelas = [];

        for (let num_parcela = 0; num_parcela < this.qtdParcelas; num_parcela++) {
          const data: MaterialUiPickersDate = new Date(state.data!);
          data.setMonth(data.getMonth() + num_parcela);
          const valorDaParcela = this.valorTotais / this.qtdParcelas;
          this.parcelas.push({
            num_parcela: num_parcela + 1,
            data_pagamento: Formatter.dateToString(data),
            recebido: false,
            pagamento_tipo: parseInt(this.pagamento.value),
            conta: parseInt(this.conta.value),
            valor: Number(valorDaParcela.toFixed(2)),
          });
        }
      },

      valorTotal() {
        if (this.categoria === 'Camarão') {
          return Number(this.valorTotais);
        } else {
          return Number(this.valorTotais);
        }
      },

      somaParcelas() {
        let valor = 0;
        this.parcelas.map((parcela) => {
          return (valor += parcela.valor as number);
        });
        return valor;
      },

      voltar() {
        if (this.etapa !== 0) this.etapa -= 1;
        else handleClose();
      },

      async avancar() {
        if (this.etapa === 0) {
          if (this.tanques.length === 0) {
            store.notificar('Informe os tanques');
          } else {
            this.etapa += 1;
          }
        } else if (this.etapa === 1) {
          if (!this.cliente) {
            store.notificar('Informe o cliente');
          } else this.etapa += 1;
        } else if (this.etapa === 2) {
          this.etapa += 1;
        } else if (this.etapa === 3) {
          if (!this.valorUnitario) {
            store.notificar('Informe o valor unitário');
          }
          if (!this.conta) {
            store.notificar('Informe a conta');
          } else if (this.parcelas.some((parcela) => !parcela.valor)) {
            store.notificar('O valor de uma parcela não pode ser 0 ou nulo');
          } else if (this.somaParcelas() !== this.valorTotal()) {
            store.notificar('A soma das parcelas deve coincidir com o valor total da venda');
          } else {
            if (state.finalizarLote) {
              await this.gerarVenda();
              modalFinalizarLote.open();
            } else {
              await this.gerarVenda();
              handleClose();
            }
          }
        }
      },

      async gerarVenda() {
        store.toggleLoader();

        const parcelas = state.parcelas.map((parcela) => {
          return {
            ...parcela,
            valor: Number(parcela.valor.toFixed(2)),
          };
        });

        const data = {
          propriedade: store.propriedade!.id,
          documento: state.documento,
          data: Formatter.dateToString(state.data),
          conta: parseInt(state.conta.value),
          categoria: store.categorias.getByTipo('produtos').filter((item) => item.nome === state.categoria)[0].id,
          // descricao: state.descricao,
          // quantidade: state.quantidade,
          // valor_unitario: Number(state.valorUnitario.toFixed(2)),
          // valor_total: state.valorTotais,
          lote_tanque_movimentacao: [],
          cliente: this.cliente,
          parcelas,
          valor_frete: state.valorFrete,
          valor_outros: state.outrosValores,
          itens: state.itens.map((item) => ({
            categoria: item.categoria,
            descricao: item.descricao,
            quantidade: item.quantidade,
            valor_uni_item: item.valor_uni_item,
            valor_total_item: item.valor_total_item,
          })) as any,
        };
        await store.movimentacoes.criar(data);
        onConfirm();
        store.toggleLoader();
      },

      somaValorItems() {
        let valor = 0;
        this.itens.map((item) => (valor += item.valor_total_item));
        return valor;
      },
      somaQuantidadeItems() {
        let valor = 0;
        this.itens.map((item) => (valor += item.quantidade));
        return valor;
      },
      somaValorTotal() {
        let total = this.somaValorItems() + this.valorFrete + this.outrosValores;
        return total;
      },
    }),
  );
  const [itemSelecionado, setItemSelecionado] = useState<DespesaItemCriacao | null>(null);
  const categoriasOptions = store.categorias.getByTipo('produtos').map((categoria) => {
    return { label: categoria.nome, value: categoria.id.toString() };
  });

  function fecharModalDespesasItem() {
    modalItem.close();
    setItemSelecionado(null);
  }

  function selecionarItem(item: DespesaItemCriacao) {
    setItemSelecionado(item);
    modalItem.open();
  }

  function adicionarItem(dados: DespesaItemCriacao) {
    state.itens = [...state.itens, dados];
  }

  function removerItem(index: number) {
    if (index >= 0 && index < state.itens.length) {
      const novosItens = [...state.itens];
      novosItens.splice(index, 1);
      state.itens = novosItens;
    }
  }

  function atualizarItem(id: number, dados: DespesaItemCriacao) {
    const index = state.itens.findIndex((item) => item.id === id);
    if (index !== -1) {
      const novosItens = [...state.itens];
      novosItens[index] = dados;
      state.itens = novosItens;
    }
  }

  // eslint-disable-next-line
  useEffect(() => {
    if (state.valorTotais && state.quantidade) state.valorUnitario = state.valorTotais / state.quantidade;
    if (state.valorTotais) {
      state.parcelas.map((_parcela, index) => {
        return (state.parcelas[index].valor = state.valorTotais / state.qtdParcelas);
      });
    }
    // eslint-disable-next-line
  }, [state.valorTotais, state.quantidade]);

  useEffect(() => {
    state.valorTotais = state.somaValorTotal();
    state.quantidade = state.somaQuantidadeItems();
  }, [state.itens, state.valorFrete, state.outrosValores]);

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>
        {state.etapa === 3 ? (
          <Flex style={{ justifyContent: 'space-between' }}>
            <span>{etapas[state.etapa]}</span>

            <Tooltip title="Adicionar cliente">
              <IconButton style={{ background: 'rgba(0,0,0,0.2)' }} onClick={modalCliente.open}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Flex>
        ) : (
          etapas[state.etapa]
        )}
      </ModalHeader>
      <ModalBody
        style={{
          minWidth: state.etapa === 2 ? '950px' : '488px',
        }}
      >
        {state.etapa === 0 ? (
          <Categorias>
            {store.categorias.arr &&
              store.categorias.getByTipo('produtos').map((categoria) => (
                <Categoria
                  onClick={() => {
                    state.categoria = categoria.nome;
                    state.etapa = 1;
                  }}
                  key={categoria.id}
                >
                  <div>
                    <img src="/images/peixe.png" alt={categoria.nome} />
                  </div>
                  <p>{categoria.nome}</p>
                </Categoria>
              ))}
          </Categorias>
        ) : null}

        {state.etapa === 1 ? (
          <Flex style={{ flexDirection: 'column', width: '100%' }}>
            <TextField
              label="Pesquisa Pelo Nome"
              value={pesquisaCliente}
              onChange={(e) => {
                setPesquisaCliente(e.target.value);
              }}
            />
            {store.clientes
              .filter((cliente) => {
                if (pesquisaCliente)
                  return cliente.nome.toLocaleLowerCase().includes(pesquisaCliente.toLocaleLowerCase());
                else return cliente;
              })
              .sort((a: any, b: any) => (a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0))
              .map((cliente: any) => {
                return (
                  <Lote
                    onClick={() => {
                      state.cliente = cliente.id;
                      state.avancar();
                    }}
                    key={cliente.id}
                  >
                    <b>{cliente.nome}</b>
                  </Lote>
                );
              })}
          </Flex>
        ) : null}
        {modalCliente.state ? (
          <FormCliente open={modalCliente.state} handleClose={modalCliente.close} clienteID={0} />
        ) : null}

        {state.etapa === 2 ? (
          <div style={{ width: '100%' }}>
            <Divisor>
              <p>Detalhes Financeiros</p>
            </Divisor>

            <div style={{ width: '100%', marginTop: 10 }}>
              {store.clientes.arr && (
                <Select
                  placeholder="Cliente"
                  value={{
                    label: store.clientes.get(state.cliente).nome,
                    value: '0',
                  }}
                  options={store.clientes.getSelectRows()}
                />
              )}
            </div>

            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Data"
              value={state.data}
              onChange={(date) => {
                state.data = date;
                state.setQtdParcelas(state.qtdParcelas);
              }}
              style={{
                marginTop: '5px',
              }}
            />

            <TextField
              value={state.documento}
              onChange={(e) => (state.documento = e.target.value)}
              label="Número do documento"
              style={{
                marginTop: '-5px',
              }}
            />

            <InputNumero
              value={state.valorFrete}
              onChange={(value) => (state.valorFrete = value)}
              precision="2"
              label="Valor do Frete"
            />
            <InputNumero
              value={state.outrosValores}
              onChange={(value) => (state.outrosValores = value)}
              precision="2"
              label="Outros Valores"
            />

            <Select
              placeholder="Categoria"
              value={{ label: state.categoria, value: state.categoria }}
              onChange={(e: any) => (state.categoria = e)}
              disabled
              options={store.categorias.getByTipo('produtos').map((categoria) => {
                return { label: categoria.nome, value: categoria.id.toString() };
              })}
            />
            {/* <TextField value={state.descricao} onChange={(e) => (state.descricao = e.target.value)} label="Descrição" /> */}
            {/* <InputNumero
              value={state.valorTotais}
              onChange={(value) => (state.valorTotais = value)}
              precision="2"
              label="Valor Total (R$)"
            /> */}
            {/* <InputNumero
              value={state.quantidade}
              onChange={(value) => (state.quantidade = value)}
              precision="3"
              label="Quantidade"
            /> */}

            {/* <Dados style={{ gridTemplateColumns: '1fr' }}>
              <div>
                <h2>Valor unitário</h2>
                <h1 style={{ color: '#42a5f5' }}>R$ {Formatter.formatNumber(2, state.valorUnitario)}</h1>
              </div>
            </Dados> */}

            <Box display="flex" flexDirection="column" alignItems="center" mt={2} mb={2}>
              <Box width="50%">
                <Botao onClick={modalItem.open}>Adicionar Item</Botao>
              </Box>

              <Box width="100%">
                <Grid container direction="column">
                  {state.itens.map((item, index) => (
                    <Grid key={item.descricao} container justifyContent="center" spacing={2}>
                      <Grid item sm={2} alignContent="center">
                        <TextField
                          value={
                            categoriasOptions?.find((cat) => cat.value === item.categoria.toString())?.label ??
                            item.categoria
                          }
                          label="Categoria"
                          disabled
                        />
                      </Grid>
                      <Grid item sm={2} alignContent="center">
                        <TextField value={item.descricao} label="Descrição" disabled />
                      </Grid>
                      <Grid item sm={2} alignContent="center">
                        <Box pt="10px">
                          <InputNumero value={item.valor_total_item} precision="2" label="Valor Total" disabled />
                        </Box>
                      </Grid>
                      <Grid item sm={2} alignContent="center">
                        <Box pt="10px">
                          <InputNumero value={item.quantidade} precision="3" label="Quantidade" disabled />
                        </Box>
                      </Grid>
                      <Grid item sm={2} alignContent="center">
                        <Box pt="10px">
                          <InputNumero value={item.valor_uni_item} precision="2" label="Valor Unitário" disabled />
                        </Box>
                      </Grid>
                      <Grid item sm={1} alignContent="center">
                        <IconButton onClick={() => selecionarItem(item)}>
                          <Edit />
                        </IconButton>
                      </Grid>
                      <Grid item sm={1} alignContent="center">
                        <IconButton onClick={() => removerItem(index)}>
                          <Delete htmlColor="red" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-around" alignItems="center" flexDirection="row">
              <Box display="flex" flexDirection="column" alignItems="center">
                <h2>Valor Total Itens</h2>
                <h1 style={{ color: '#42a5f5' }}>R$ {Formatter.formatNumber(2, state.somaValorItems())}</h1>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <h2>Valor Total Geral</h2>
                <h1 style={{ color: '#42a5f5' }}>R$ {Formatter.formatNumber(2, state.somaValorTotal())}</h1>
              </Box>
            </Box>
          </div>
        ) : null}

        {state.etapa === 3 ? (
          <div style={{ width: '100%' }}>
            <Divisor>
              <p>Detalhes Financeiros</p>
            </Divisor>

            <Box display="flex" justifyContent="space-around" alignItems="center" flexDirection="row">
              <Box display="flex" flexDirection="column" alignItems="center">
                <h2>Valor Total</h2>
                <h1 style={{ color: '#42a5f5' }}>R$ {Formatter.formatNumber(2, state.somaValorTotal())}</h1>
              </Box>
            </Box>

            <div style={{ width: '100%', marginTop: 10 }}>
              {store.contas.arr && (
                <Select
                  value={state.conta}
                  onChange={(e: any) => (state.conta = e)}
                  placeholder="Conta Bancaria"
                  options={store.contas.getSelectRows()}
                />
              )}
            </div>
            <div style={{ width: '100%', marginTop: 10 }}>
              <Select
                onChange={(e: any) => state.setPagamento(e)}
                placeholder="Forma de pagamento"
                defaultValue={store.tiposDePagamento.getSelectRows()[0]}
                options={store.tiposDePagamento.getSelectRows()}
              />
            </div>
            <TextField
              value={state.qtdParcelas}
              onChange={(e) => state.setQtdParcelas(parseInt(e.target.value))}
              type="number"
              label="Número de parcelas"
              inputProps={{ min: 1 }}
              onBlur={() => !state.qtdParcelas && state.setQtdParcelas(1)}
            />

            {/* Parcelas */}
            {state.parcelas.map((parcela, index) => (
              <Parcela key={index}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data"
                  value={
                    new Date(
                      parcela.data_pagamento.substr(3, 2) +
                        '/' +
                        parcela.data_pagamento.substr(0, 2) +
                        '/' +
                        parcela.data_pagamento.substr(6, 4),
                    )
                  }
                  onChange={(date) => (state.parcelas[index].data_pagamento = Formatter.dateToString(date))}
                />
                <InputNumero
                  value={typeof parcela.valor === 'string' ? parcela.valor : parcela.valor}
                  onChange={(value) => (state.parcelas[index].valor = value)}
                  precision="2"
                  label="Valor"
                  id={'parcela-' + index}
                  disabled={true}
                />

                <div
                  style={{
                    width: '600px',
                    marginTop: 18,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'bottom',
                  }}
                >
                  {store.contas.arr && (
                    //@typescript-eslint/no-unused-expressions
                    <NewSelect
                      value={
                        store.contas
                          .getSelectRows()
                          .filter((tipo) => parseInt(tipo.value) === state.parcelas[index].conta)[0]
                      }
                      onChange={(e: any) => (state.parcelas[index].conta = parseInt(e.value))}
                      placeholder="Conta Banco"
                      options={store.contas.getSelectRows()}
                    />
                  )}

                  {store.tiposDePagamento.arr && (
                    <NewSelect
                      value={
                        store.tiposDePagamento
                          .getSelectRows()
                          .filter((tipo) => parseInt(tipo.value) === parcela.pagamento_tipo)[0]
                      }
                      onChange={(e: any) => (state.parcelas[index].pagamento_tipo = parseInt(e.value))}
                      placeholder="Forma de pagamento"
                      options={store.tiposDePagamento.getSelectRows()}
                      menuPlacement={index + 1 === state.parcelas.length ? 'top' : 'bottom'}
                    />
                  )}

                  <FormControlLabel
                    labelPlacement="top"
                    control={
                      <Checkbox
                        checked={parcela.recebido}
                        onChange={(e) => (state.parcelas[index].recebido = e.target.checked)}
                        color="primary"
                        style={{ height: '1px' }}
                      />
                    }
                    style={{ marginBottom: '25px' }}
                    label="Pago"
                  />
                </div>
              </Parcela>
            ))}
          </div>
        ) : null}

        {/* Modais */}
        {modalFinalizarLote.state ? (
          <FinalizarLote
            open={modalFinalizarLote.state}
            handleClose={modalFinalizarLote.close}
            onConfirm={handleClose}
            async={() => onConfirm()}
            loteID={state.lote}
            povoamento={false}
          />
        ) : null}

        {modalItem.state ? (
          <ModalMovimentacaoItem
            open={modalItem.state}
            handleClose={fecharModalDespesasItem}
            handleAdd={adicionarItem}
            handleUpdate={atualizarItem}
            item={itemSelecionado}
            categoria={categoriasOptions.find((item) => item.label === state.categoria)?.value ?? ''}
            valorFrete={state.valorFrete}
            outrosValores={state.outrosValores}
            tipoCategoria="produtos"
          />
        ) : null}

        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={state.voltar} variant="contained" cor="gray">
            Voltar
          </Botao>
          <Botao onClick={state.avancar} variant="contained" cor="#00C853">
            {state.etapa === 3 ? 'Salvar' : 'Avançar'}
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(FormProdutos);
