import React from 'react';
import { LeftButton, RightButton, BtnGroup, RoundedButtonRed } from './styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import { IconButton } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';

type Props = {
  clear(): void;
  sync(): void;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  display?: boolean;
};
const BotaoFiltro: React.FC<Props> = ({ clear, sync, setState, setVisibility, display }) => {
  const handleToggle = (): void => {
    setVisibility(true);
    setTimeout(() => {
      setState(true);
    }, 100);
  };

  return (
    <>
      <BtnGroup className="filtro" variant="contained" color="primary" style={{color:'white !important'}}>
        {!display ? (
          <>
            <LeftButton cor="#9e9e9e" size="small" onClick={handleToggle}>
              <FilterListIcon />
            </LeftButton>
            <RightButton cor="#e57373" onClick={clear}>
              LIMPAR FILTRO
            </RightButton>
          </>
        ) :  (
          <>
            <RoundedButtonRed onClick={clear}>LIMPAR FILTRO</RoundedButtonRed>
          </>
        )}
        
      </BtnGroup>
      <IconButton className="blue" onClick={sync}>
        <CachedIcon />
      </IconButton>
    </>
  );
};

export default BotaoFiltro;
