/* eslint-disable react/display-name */
import React, { useEffect, useRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useHistory, useLocation } from 'react-router-dom';
import Header from '../../../components/Header';
import { Flex } from '../../../components/NavBar/styles';
import AddBtn from '../../../components/Botoes/AddBtn';
import { Body } from '../../../components/Body';
import { config } from '../../../components/Tabela';
import CadastrarLote from './CadastrarLote';
import AjustarLote from './AjustarLote';
import useStore from '../../../services/hooks/useStore';
import Filtro from '../../../components/Filtro';
import MaterialTable, { Column } from 'material-table';
import { useLocalStore, observer } from 'mobx-react-lite';
import useModal from '../../../services/hooks/useModal';
import Select from '../../../components/Select';
import ActionButton from '../../../components/Tabela/ActionButton';
import Confirmar from '../../../components/Modais/Confirmar';
import { deletarLote } from '../../../services/utils/producao';
import EditarLote from './EditarLote';
import syncModel from '../../../services/models/Sync';
import AtualizarFase from './AtualizarFase';
import FinalizarLote from '../../../components/Modais/FinalizarLote';
import { FormControlLabel, Checkbox, MenuList, MenuItem, TextField } from '@material-ui/core';

import Formatter from '../../../services/Formatter';
import X9Logger from '../../../services/X9Logger';
import { apiV2, getPdfV2 } from '../../../services/utils';
import { permissoes } from '../../../services/utils/permissoes';

const Povoamento: React.FC = () => {
  const tableRef = useRef(null);
  const location: any = useLocation();
  const state = useLocalStore(() => ({
    pageSize: 0,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },

    page: location.state ? location.state.page : 0,
    setPage(num: number) {
      this.page = num;
    },

    filtroEspecie: null as SelectRow | null,
    filtroFase: null as SelectRow | null,
    filtroRapidoFase: null as SelectRow | null,
    filtroRapidoFinalizado: false,
    filtroLote: '',

    selectedLote: {} as Lote,

    historico: null as Historico[] | null,

    sync: false,
  }));
  const modalCadastro = useModal();
  const modalEditar = useModal();
  const modalExclusao = useModal();
  const modalFase = useModal();
  const modalFinalizar = useModal();
  const modalAjustarLote = useModal();
  const store = useStore();
  const history = useHistory();

  store.propriedade && store.validarPermissao(permissoes.PRODUCAO_POVOAMENTO_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.PRODUCAO_POVOAMENTO_ESCRITA);

  function validaInteracao(historico: any) {
    if (historico && historico.filter((registro: any) => registro.tipo != 'povoamento').length === 0) {
      return true;
    } else {
      return false;
    }
  }

  async function getPDF(): Promise<void> {
    store.toggleLoader();
    const token = store.token;
    const lote = state.selectedLote;
    const propriedade = store.propriedade;

    await getPdfV2
      .get(`/relatorio/desempenho/${propriedade?.uuid}/lotes/${lote?.uuid}/?authori=${token}`)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${lote?.codigo}_relatorio_povoamento.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        store.notificar('Ocorreu um erro ao gerar relatório!');
      });

    store.toggleLoader();
  }

  const columns: Column<Lote>[] = [
    {
      field: 'codigo',
      title: 'Lote',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'acoes',
      disableClick: true,
      render: (rowData: any) => {
        return (
          <ActionButton
            editar={
              temPermissaoEscrita && rowData.fase_lote.id !== 6
                ? () => {
                    state.selectedLote = rowData;
                    modalEditar.open();
                  }
                : undefined
            }
            mudarFase={
              temPermissaoEscrita
                ? () => {
                    state.selectedLote = rowData;
                    modalFase.open();
                  }
                : undefined
            }
            finalizar={
              temPermissaoEscrita && rowData.fase_lote.id !== 6
                ? () => {
                    state.selectedLote = rowData;
                    modalFinalizar.open();
                  }
                : undefined
            }
            historico={() => history.push({ pathname: '/producao/lote/' + rowData.id, state: { page: state.page } })}
            relatorio={() => {
              state.selectedLote = rowData;
              getPDF();
            }}
            excluir={
              temPermissaoEscrita
                ? async () => {
                    const excluir = async (): Promise<void> => {
                      state.selectedLote = rowData;
                      const historico = await store.lotes.getHistorico(rowData.id);
                      validaInteracao(historico)
                        ? modalExclusao.open()
                        : store.notificar('O lote contém registros e não pode ser excluído!');

                      // (tableRef.current as any)?.onQueryChange();
                    };

                    excluir();
                  }
                : undefined
            }
          />
        );
      },
      ...(true && ({ width: 25 } as object)),
    },

    {
      field: 'especie_explorada',
      title: 'Espécie',
      render: ({ especie_explorada }) => {
        return especie_explorada.nome;
      },
      ...(true && ({ width: 140 } as object)),
    },

    {
      field: 'fornecedor',
      title: 'Fornecedor',
      render: ({ fornecedor }) => {
        return fornecedor.nome;
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'fase_lote',
      title: 'Fase do Lote',
      render: ({ fase_lote }) => {
        return <span style={{ color: fase_lote.nome === 'FINALIZADO' ? 'green' : 'inherit' }}>{fase_lote.nome}</span>;
      },
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'data_alojamento',
      title: 'Data',
      type: 'date',
      render: ({ data_alojamento }: any) => {
        return data_alojamento ? data_alojamento : '';
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'data_prevista',
      title: 'Previsão Despesca',
      type: 'date',
      render: ({ data_prevista }: any) => {
        return data_prevista ? data_prevista : '';
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'valor',
      title: 'Valor (R$)',
      type: 'numeric',
      render: ({ valor }: any) => {
        return Formatter.formatNumber(2, valor);
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'qtd_animais',
      title: 'Qtd de Animais',
      type: 'numeric',
      render: ({ qtd_animais }: any) => {
        return Formatter.formatNumber(0, qtd_animais);
      },
      ...(true && ({ width: 160 } as object)),
    },
    {
      field: 'peso_medio',
      type: 'numeric',
      title: 'Peso Médio (g)',
      render: ({ peso_medio }) => {
        return Formatter.formatNumber(3, peso_medio);
      },
      ...(true && ({ width: 160 } as object)),
    },
    {
      field: 'biomassa',
      title: 'Biomassa (Kg)',
      type: 'numeric',
      render: ({ biomassa }) => {
        return Formatter.formatNumber(3, biomassa);
      },
      ...(true && ({ width: 160 } as object)),
    },
  ];

  useEffect(() => {
    (tableRef.current as any)?.onQueryChange();
    // eslint-disable-next-line
  }, [state.filtroRapidoFase, state.filtroRapidoFinalizado]);

  async function sync(): Promise<void> {
    await syncModel.povoamento();
    state.sync = true;
  }

  async function filter(): Promise<void> {
    // Checagem de filtros avançados
    if (state.filtroEspecie || state.filtroFase) {
      (tableRef.current as any)?.onQueryChange();
      // Limpar filtroRápido
      state.filtroRapidoFase = null;
    } else {
      await sync();
      (tableRef.current as any)?.onQueryChange();
    }
  }

  function limparFiltro(): void {
    state.filtroFase = null;
    state.filtroEspecie = null;
    state.filtroRapidoFase = null;
    state.filtroLote = '';
    (tableRef.current as any)?.onQueryChange();
  }

  useEffect(() => {
    store.setTitulo('Povoamento');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (store.propriedade) {
      sync();
      (tableRef.current as any)?.onQueryChange();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  const ExcluirLote: React.FC = () => {
    const lote = state.selectedLote;

    return (
      <div style={{ width: '100%' }}>
        <b>Lote: </b> {lote && lote?.codigo}
        <br />
        <b>Data: </b> {lote?.data_alojamento && Formatter.getData(lote?.data_alojamento)}
        <br />
        <b>Espécie: </b>
        {lote && lote?.especie_explorada?.nome}
        <br />
        <b>Fase: </b>
        {lote && lote?.fase_lote?.nome}
        <br />
        <b>Valor: </b>
        R$ {lote?.valor && Formatter.formatNumber(2, lote?.valor)}
      </div>
    );
  };
  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn
            disabled={!temPermissaoEscrita}
            dropdown
            onClick={() => {
              modalCadastro.open();
            }}
            text="ADICIONAR"
            Icon={AddIcon}
          >
            <MenuList>
              <MenuItem
                onClick={() => {
                  modalAjustarLote.open();
                }}
              >
                Ajustar Lote
              </MenuItem>
            </MenuList>
          </AddBtn>
          <div style={{ width: 160 }}>
            <Select
              value={state.filtroRapidoFase}
              placeholder="Fase do lote"
              onChange={(e: any) => (state.filtroRapidoFase = e)}
              options={store.fasesLote.getSelectRows()}
            />
          </div>
          <FormControlLabel
            style={{ marginLeft: 15 }}
            control={
              <Checkbox
                checked={state.filtroRapidoFinalizado}
                onChange={(_e, checked) => {
                  state.filtroRapidoFinalizado = checked;
                  state.filtroRapidoFase = null;
                }}
                color="primary"
              />
            }
            label="Mostrar finalizados"
          />
        </Flex>
        <Flex>
          <Filtro sync={filter} clear={limparFiltro}>
            <div style={{ width: '90%' }}>
              <Select
                value={state.filtroEspecie}
                placeholder="Espécie"
                onChange={(e: any) => (state.filtroEspecie = e)}
                options={store.especies.getSelectRows()!}
              />
            </div>
            <div style={{ width: '90%' }}>
              <Select
                value={state.filtroFase}
                placeholder="Fase do lote"
                onChange={(e: any) => (state.filtroFase = e)}
                options={store.fasesLote.getSelectRows()}
              />
            </div>
            <TextField
              inputProps={{ style: { minWidth: '350px' } }}
              label="Pesquisa por lote"
              value={state.filtroLote}
              onChange={(e) => {
                state.filtroLote = e.target.value;
              }}
            />
          </Filtro>
        </Flex>
      </Header>

      <Body grid={true}>
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          {store.propriedade && (
            <MaterialTable
              tableRef={tableRef}
              columns={columns}
              data={(query) =>
                new Promise((resolve, reject) => {
                  const filtros: { [key: string]: any } = {
                    orderBy: query.orderBy?.field || 'nome',
                    orderDirection: query.orderDirection || 'asc',
                  };

                  if (!state.filtroEspecie && !state.filtroFase && state.filtroRapidoFase) {
                    filtros.fase_lote = parseInt(state.filtroRapidoFase.value);
                  } else {
                    filtros.especie_explorada = state.filtroEspecie ? parseInt(state.filtroEspecie.value) : undefined;
                    filtros.fase_lote = state.filtroFase ? parseInt(state.filtroFase.value) : undefined;
                  }

                  filtros.finalizado = state.filtroRapidoFinalizado ? state.filtroRapidoFinalizado : '';
                  filtros.search = state.filtroLote || undefined;

                  apiV2
                    .get('/lote/list-historico/', {
                      params: {
                        page: query.page + 1,
                        page_size: 50,
                        prop: store.propriedade?.id,
                        aquaponia: false,
                        ...filtros,
                      },
                    })
                    .then((res) => {
                      if (res.data.count) {
                        if (res.data.count >= 50) {
                          state.setPageSize(50);
                        } else {
                          state.setPageSize(res.data.count);
                        }
                      } else {
                        state.setPageSize(0);
                      }
                      resolve({
                        data: res.data.results,
                        page: query.page,
                        totalCount: res.data.count,
                      });
                    })
                    .catch((err) => {
                      X9Logger.report(err);
                      store.notificar('Ocorreu um erro ao gerar relatório. Tente novamente mais tarde!');
                      reject();
                    });
                })
              }
              options={{ ...config.options, pageSize: state.pageSize }}
              localization={config.localization}
              style={config.style}
              onRowClick={(_e, row: any) =>
                history.push({ pathname: '/producao/lote/' + row?.id, state: { page: state.page } })
              }
            />
          )}
        </div>

        <Confirmar
          title="Tem certeza que deseja excluir?"
          open={modalExclusao.state}
          content={<ExcluirLote />}
          handleClose={modalExclusao.close}
          onCancel={modalExclusao.close}
          onConfirm={async () => {
            modalExclusao.close();
            await deletarLote(state?.selectedLote);
            (tableRef.current as any)?.onQueryChange();
          }}
        />
        {modalEditar.state ? (
          <EditarLote
            open={modalEditar.state}
            lote={state.selectedLote!}
            handleClose={() => {
              modalEditar.close();
              (tableRef.current as any)?.onQueryChange();
            }}
          />
        ) : null}
        {modalAjustarLote.state ? (
          <AjustarLote
            open={modalAjustarLote.state}
            handleClose={() => {
              modalAjustarLote.close();
              (tableRef.current as any)?.onQueryChange();
            }}
          />
        ) : null}
        {modalCadastro.state ? (
          <CadastrarLote
            open={modalCadastro.state}
            handleClose={() => {
              modalCadastro.close();
              (tableRef.current as any)?.onQueryChange();
            }}
          />
        ) : null}
        {modalFase.state ? (
          <AtualizarFase
            open={modalFase.state}
            handleClose={() => {
              modalFase.close();
            }}
            atualizarFaseAsync={() => {
              modalFase.close();
              (tableRef.current as any)?.onQueryChange();
            }}
            lote={state.selectedLote}
          />
        ) : null}
        {modalFinalizar.state ? (
          <FinalizarLote
            open={modalFinalizar.state}
            handleClose={() => {
              modalFinalizar.close();
            }}
            loteID={state.selectedLote.id}
            povoamento={true}
            tanques={state.selectedLote.lote_tanques}
            async={() => {
              (tableRef.current as any)?.onQueryChange();
              store.toggleLoader();
            }}
          />
        ) : null}
      </Body>
    </>
  ) : null;
};

export default observer(Povoamento);
