import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DisplayBox, DisplayHead, DisplayContent, HeaderButton } from './styles';
import { IconButton, Tooltip } from '@material-ui/core';
import CachedIcon from '@mui/icons-material/Cached';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';
import { Flex } from '../NavBar/styles';
import { Print } from '@material-ui/icons';

type Props = {
  width?: string;
  heightOffset?: string;
  style?: React.CSSProperties;
  overflowY?: boolean;
  back?(): void;
  filter?(): void;
  add?(): void;
  verTudo?(): void;
  print?(): void;
  sync?(): void;
  title?: string;
  Select1?: React.ReactNode;
  Select2?: React.ReactNode;
  HeaderButtonEdit?: React.ReactNode;
};
const Display: React.FC<Props> = ({
  width,
  heightOffset,
  children,
  title,
  back,
  add,
  filter,
  print,
  verTudo,
  sync,
  style,
  overflowY,
  Select1,
  Select2,
  HeaderButtonEdit,
}) => {
  return (
    <DisplayBox style={style} heightOffset={heightOffset} width={width}>
      <DisplayHead>
        <Flex>
          {back && (
            <Tooltip title="Voltar">
              <IconButton onClick={back}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          )}
          {filter && (
            <Tooltip title="Filtrar">
              <IconButton onClick={filter}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
          {add && (
            <Tooltip title="Adicionar">
              <IconButton onClick={add}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
          {title && <h2>{title}</h2>}
        </Flex>
        {Select1 || Select2 ? (
          <Flex style={{ width: '100%', justifyContent: 'space-between' }}>
            {Select1 ? <div style={{ width: '45%' }}>{Select1}</div> : null}
            {Select2 ? <div style={{ width: '45%' }}>{Select2}</div> : null}
          </Flex>
        ) : null}
        {verTudo && <HeaderButton onClick={verTudo}>Ver Todos</HeaderButton>}
        {HeaderButtonEdit ? <div>{HeaderButtonEdit}</div> : null}
        {print && (
          <Tooltip title="Gerar relatório">
            <IconButton onClick={print}>
              <Print />
            </IconButton>
          </Tooltip>
        )}
        {sync && (
          <Tooltip title="Sync" onClick={sync}>
            <IconButton onClick={sync}>
              <CachedIcon onClick={sync} />
            </IconButton>
          </Tooltip>
        )}
      </DisplayHead>
      <DisplayContent overflowY={overflowY} heightOffset={heightOffset}>
        {children}
      </DisplayContent>
    </DisplayBox>
  );
};

export default Display;
