import styled from 'styled-components';

export const MinhaPropriedadeForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .MuiInputBase-root {
    margin: 15px 0;
  }
  .MuiFormControl-root {
    width: 100%;
  }
`;

export const PerfilPropriedade = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  .MuiInputBase-root {
    margin: 15px 0;
  }
  margin-top: 20px;
  .MuiFormControl-root {
    width: 100%;
  }
`;
export const FormTitle = styled.div`
  display: flex;
  color: #42b5f5;
  font-weight: normal;
  font-size: 35px;
  svg {
    font-size: 38px;
  }
`;

export const MeuPerfilForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 100px;
  height: 100%;
  .MuiInputBase-root {
    margin: 15px 0;
  }
  .MuiFormControl-root {
    width: 100%;
  }
`;
export const BuscarArquivo = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
