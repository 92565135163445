import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import useStore from '../../../services/hooks/useStore';
import { TanqueInfo } from '../../../components/TanqueInfo';
import { observer, useLocalStore } from 'mobx-react-lite';
import { returnTanque } from '../../../services/utils/propriedade';
import Formatter from '../../../services/Formatter';
import { apiV2 } from '../../../services/utils';
import { Box, TextField, Tooltip } from '@material-ui/core';

type Props = ModalProps & {
  onItemClick(tanque: SelectRow): void;
  loteID?: number | null;
};
const SelecionarTanque: React.FC<Props> = ({ open, handleClose, onItemClick }) => {
  const state = useLocalStore(() => ({
    sync: false,
  }));
  const [tanques, setTanques] = useState<Tanque[]>([] as Tanque[]);
  const [codigo, setCodigo] = useState<string>('');
  const store = useStore();
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function searchTanque() {
      store.toggleLoader();
      await apiV2
        .get('/tanque/modals/', {
          params: {
            prop: store.propriedade!.id,
            nome: codigo,
          },
        })
        .then((res) => {
          setTanques(res.data.results);
        });

      state.sync = true;
      store.toggleLoader();
    }
    searchTanque();

    // eslint-disable-next-line
  }, [codigo, setCodigo]);

  /*
  const TanquesDisponiveis: React.FC = () => (
    <>
      {tanques?.map((tanque) => {
        if (tanque) {
          return (
            <TanqueInfo
              onClick={() => {
                handleClose();
                onItemClick({ label: returnTanque(tanque), value: tanque.id.toString() });
              }}
              key={tanque.id}
            >
              <div>
                <b className="left">{tanque.nome || tanque.codigo}</b>
                <p>{Formatter.formatNumber(0, tanque.estoque)} un</p>
                <p className="right" style={{ color: '#00C853' }}>
                  {Formatter.formatNumber(2, tanque.estoque ? tanque.biomassa / 1000 : 0)} Kg
                </p>
              </div>
            </TanqueInfo>
          );
        }
      })}
    </>
  );
  */

  const TanquesDisponiveis: React.FC = () => (
    <Box minHeight="500px">
      {tanques
        ?.sort((a: any, b: any) => {
          const textA = a.nome ? a.nome.toUpperCase() : a.codigo;
          const textB = b.nome ? b.nome.toUpperCase() : b.codigo;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map((tanque) => {
          return (
            <TanqueInfo
              onClick={() => {
                handleClose();
                onItemClick({ label: returnTanque(tanque), value: tanque.id.toString() });
              }}
              key={tanque.id}
              style={{ width: '540px' }}
            >
              <p className="left">{tanque.nome || tanque.codigo}</p>
              <p className="right">
                {tanque.lotes.length > 0 ? (
                  <Tooltip
                    title={tanque.lotes.map((lote: any) => {
                      return `${lote.codigo || lote.nome}, `;
                    })}
                  >
                    <span>{tanque.lotes[0].codigo + '...'}</span>
                  </Tooltip>
                ) : (
                  ''
                )}{' '}
                ({Formatter.formatNumber(0, tanque.estoque)})
              </p>
              <b className="left" style={{ color: '#00C853' }}>
                {Formatter.formatNumber(3, tanque.biomassa)} Kg
              </b>
              <p className="right" style={{ color: '#00C853' }}>
                {tanque.lotes && tanque.lotes.length > 0 ? tanque.lotes[0].fase_lote.nome : tanque.situacao_tanque.nome}
              </p>
            </TanqueInfo>
          );
        })}
    </Box>
  );

  return state.sync ? (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>Selecione um Tanque</ModalHeader>
      <ModalBody>
        <div
          style={{ color: '#707070', width: '100%', padding: '10px', display: 'flex', justifyContent: 'space-between' }}
        >
          <TextField
            label="Pesquisa Pelo Nome"
            value={codigo}
            onChange={(e) => {
              setCodigo(e.target.value);
            }}
          />
        </div>

        <TanquesDisponiveis />
      </ModalBody>
    </Modal>
  ) : null;
};

export default observer(SelecionarTanque);
