import React, { useEffect } from 'react';
import { TextField, IconButton } from '@material-ui/core';
import { Modal, ModalHeader, ModalBody, Divisor } from '../../../components/Modais/styles';
import Botao from '../../../components/Botoes/Botao';
import useStore from '../../../services/hooks/useStore';
import SelecionarTanque from './SelecionarTanque';
import { TanqueItem } from './styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { useLocalStore, observer } from 'mobx-react-lite';
import InputNumero from '../../../components/InputNumero';
import SelecionarFornecedor from './SelecionarFornecedor';
import { api } from '../../../services/utils';
import useModal from '../../../services/hooks/useModal';
import Select from '../../../components/Select';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import add from 'date-fns/add';
import Formatter from '../../../services/Formatter';

import SelecionarLote from '../../../components/Modais/SelecionarLote';

type State = {
  codigo: string;
  especie: SelectRow | null;
  fornecedor: SelectRow | null;
  fornecedorNome: string;
  fase: SelectRow | null;
  data: MaterialUiPickersDate;
  valor: number;
  pesoMedio: number;
  tamanho: number;
  tanques: SelectedTanque[];
  quantidade: number;
  diasPrevistos: number;
  dataPrevista: MaterialUiPickersDate;
  selectFornecedor: boolean;
};

type SelectedLotState = {
  lote: SelectRow | null;
  data: MaterialUiPickersDate;
  tanques: SelectedTanque[];
  amostragens: object[];
  media: number;
  selectLote: boolean;
  lote_uuid: string;
  nome: string;
};

const startConfig = {
  state: (): State => ({
    codigo: '',
    especie: null,
    fornecedor: null,
    fornecedorNome: '',
    fase: null,
    valor: 0,
    data: new Date(),
    pesoMedio: 0,
    tamanho: 0,
    tanques: [],
    quantidade: 0,
    diasPrevistos: 0,
    dataPrevista: new Date(),
    selectFornecedor: false,
  }),
  state2: (): SelectedLotState => ({
    lote: null,
    data: new Date(),
    tanques: [],
    amostragens: [],
    media: 0,
    selectLote: true,
    lote_uuid: '',
    nome: '',
  }),
};

const AjustarLote: React.FC<ModalProps> = ({ open, handleClose }) => {
  const store = useStore();
  const state = useLocalStore(startConfig.state);
  const selectedLotState = useLocalStore(startConfig.state2);
  const modalTanques = useModal();
  const modalFornecedor = useModal();
  const modalSelecionarLote = useModal();

  async function checarCadastro(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    if (!state.especie) store.notificar('Selecione a espécie do lote');
    else if (!state.fornecedor) store.notificar('Selecione um fornecedor');
    else if (!state.fase) store.notificar('Selecione a fase do lote');
    else if (state.valor === 0) store.notificar('Informe o valor do lote');
    else if (state.pesoMedio === 0) store.notificar('Informe o peso médio do lote');
    else if (state.tanques.length === 0) store.notificar('Selecione um ou mais tanques');
    else {
      ajustarLote();
    }
  }

  function cancelarSelecao(): void {
    state.tanques = [];
    modalTanques.close();
  }

  function addTanque(tanqueID: number, quantidade: string, plsGrama: number, biomassa: number): void {
    if (state.tanques.filter((tanque) => tanque.item.id === tanqueID).length > 0) {
      state.tanques.filter((tanque) => tanque.item.id === tanqueID)[0].quantidade = quantidade;
    } else {
      const tanque = {
        item: store.tanques.get(tanqueID)!,
        quantidade,
        plsGrama,
        biomassa,
      };

      state.tanques.push(tanque);
      state.quantidade += parseInt(quantidade);
    }

    if (state.especie?.label === 'Camarão') {
      let total = 0;

      state.tanques.map((tanque) => {
        return (total += ((tanque.biomassa || 0) * 1000) / parseInt(tanque.quantidade || '0'));
      });

      state.pesoMedio = parseFloat((total / state.tanques.length).toFixed(3));
    }
  }

  function removerTanque(tanque: any): void {
    state.quantidade -= parseInt(tanque.quantidade);
    const newTanques = state.tanques.filter((tanqueArr) => tanqueArr !== tanque);
    state.tanques = newTanques;
  }

  async function ajustarLote(): Promise<void> {
    store.toggleLoader();
    modalTanques.close();
    handleClose();

    //declaração da coleção de tanques selecionados
    const loteTanque: any = [];

    //adicão de tanques selecionados a coleção
    state.tanques.map((tanque: any) => {
      return loteTanque.push({
        tanque: tanque.item.id,
        quantidade: parseInt(tanque.quantidade),
        fase_lote: parseInt(state.fase!.value),
      });
    });

    //bloco de dados a ser enviados a API
    const dataLote = {
      especie_explorada: parseInt(state.especie!.value),
      fornecedor: parseInt(state.fornecedor!.value),
      codigo: state.codigo,
      nome: null,
      peso_medio: state.pesoMedio,
      quantidade: state.quantidade,
      valor_total: state.valor,
      data: Formatter.dateToString(state.data),
      lote_tanque_lote: loteTanque,
      fase_lote: parseInt(state.fase!.value),
      tamanho_medio: state.tamanho,
      dias_previstos: state.diasPrevistos,
      data_prevista: Formatter.dateToString(state.dataPrevista),
    };

    //Service de chamada da API
    await api
      .patch(`/lote/${selectedLotState.lote_uuid}/?propriedade=${store.propriedade!.id}`, dataLote)
      .then(() => store.notificar('Povoamento cadastrado com sucesso!'))
      .catch((err) => {
        store.notificar('Ocorreu um erro no povoamento!');
        console.log(err);
      });

    //fecha o ícone de carregamento
    store.toggleLoader();
  }

  useEffect(() => {
    (async () => {
      store.toggleLoader();
      await store.tanques.populate();
      store.toggleLoader();
    })();
    // eslint-disable-next-line
  }, []);

  const TanquesSelecionados: React.FC = () => (
    <>
      {state.tanques.length > 0 &&
        state.tanques.map((tanque: any) => (
          <TanqueItem key={tanque.item.id}>
            <p>{tanque.item.nome || tanque.item.codigo}</p>
            <p>{Formatter.formatNumber(0, tanque.quantidade)}</p>
            <IconButton onClick={() => removerTanque(tanque)}>
              <DeleteIcon />
            </IconButton>
          </TanqueItem>
        ))}
    </>
  );

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Ajuste de Lote</ModalHeader>
      <ModalBody>
        <form onSubmit={checarCadastro}>
          <Botao onClick={modalSelecionarLote.open}>Selecione um lote existente</Botao>

          <TextField value={selectedLotState.nome} type="text" label="Código do Lote" />

          <div style={{ width: '100%' }}>
            {store.especies.arr && (
              <Select
                value={state.especie}
                placeholder="Espécie"
                onChange={(e: any) => (state.especie = e)}
                options={store.especies.getSelectRows()}
              />
            )}
          </div>
          <div style={{ width: '100%' }}>
            {store.fornecedores.arr && (
              <Select
                value={state.fornecedor}
                placeholder="Fornecedor"
                onChange={(e: any) => (state.fornecedor = e)}
                options={store.fornecedores.getSelectRows()}
                onFocus={(e) => {
                  e.target.blur();
                  state.selectFornecedor = false;
                  modalFornecedor.open();
                }}
              />
            )}
          </div>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data"
            value={state.data}
            onChange={(date) => {
              state.data = date;

              state.dataPrevista = add(state.data!, { days: state.diasPrevistos });
            }}
          />
          <Botao onClick={modalTanques.open}>Selecione o(s) tanque(s)</Botao>
          <TanquesSelecionados />
          <div style={{ width: '100%' }}>
            <Select
              key="faseKey"
              value={state.fase}
              placeholder="Fase"
              onChange={(e: any) => (state.fase = e)}
              options={store.fasesLote.getSelectRows()}
            />
          </div>

          <InputNumero disabled value={state.quantidade} precision="0" label="Quantidade" />
          <InputNumero
            value={state.valor}
            onChange={(value) => (state.valor = value)}
            precision="2"
            label="Valor do lote (R$)"
          />
          <InputNumero
            value={state.pesoMedio}
            onChange={(value) => (state.pesoMedio = value)}
            precision="3"
            label="Peso Médio (g)"
          />
          <InputNumero
            value={state.tamanho}
            onChange={(value) => (state.tamanho = value)}
            precision="3"
            label="Tamanho (cm) *Opcional"
          />

          <Divisor>
            <p>Previsão de Despesca</p>
          </Divisor>

          <TextField
            value={state.diasPrevistos}
            onChange={(e: any) => {
              state.diasPrevistos = parseInt(e.target.value);
              state.dataPrevista = add(state.data!, { days: parseInt(e.target.value) });
            }}
            type="number"
            label="Dias Previstos"
            inputProps={{ min: 0 }}
          />

          <div style={{ margin: 10, textAlign: 'center' }}>
            <h2>Data Prevista</h2>
            <h1 style={{ color: '#42a5f5' }}>{Formatter.dateToString(state.dataPrevista, '/')}</h1>
          </div>

          <div style={{ width: '100%', display: 'flex' }}>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao type="submit" variant="contained" cor="#00C853">
              Cadastrar
            </Botao>
          </div>
        </form>
      </ModalBody>
      {modalTanques.state ? (
        <SelecionarTanque
          especie={state.especie?.label as string}
          addTanque={addTanque}
          cancelar={cancelarSelecao}
          selectedTanques={state.tanques}
          handleClose={modalTanques.close}
          open={modalTanques.state}
        />
      ) : null}
      {modalSelecionarLote.state && (
        <SelecionarLote
          open={modalSelecionarLote.state}
          handleClose={modalSelecionarLote.close}
          onItemClick={(lote) => {
            state.codigo = lote.codigo;
            state.especie = { label: lote.especie_explorada.nome, value: lote.especie_explorada.id };
            state.fornecedor = { label: lote.fornecedor.nome, value: lote.fornecedor.id };
            state.data = new Date(lote.data);
            state.fase = { label: lote.fase_lote.nome, value: lote.fase_lote.id };
            state.quantidade = lote.quantidade;
            state.valor = lote.valor_total;
            state.pesoMedio = lote.peso_medio;
            state.tamanho = lote.tamanho_medio;
            state.diasPrevistos = lote.dias_previstos;
            selectedLotState.lote_uuid = lote.uuid;
            selectedLotState.nome = lote.codigo;
          }}
          loteID={selectedLotState.lote ? parseInt(selectedLotState.lote.value) : null}
        />
      )}
      <SelecionarFornecedor
        onConfirm={(fornecedor: SelectRow) => {
          modalFornecedor.close();
          state.fornecedor = fornecedor;
        }}
        open={modalFornecedor.state}
        handleClose={modalFornecedor.close}
      />
    </Modal>
  );
};

export default observer(AjustarLote);
