/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Divisor } from '../../components/Modais/styles';
import useStore from '../../services/hooks/useStore';
import { observer } from 'mobx-react-lite';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { returnTanque } from '../../services/utils/propriedade';
import Formatter from '../../services/Formatter';
import { Box, TextField, Typography } from '@material-ui/core';
import Botao from '../../components/Botoes/Botao';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import InputNumero from '../../components/InputNumero';
import { RequestData } from './CadastrarQualidadeMuitosTanques';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

type Props = ModalProps & {
  excluirTanqueIds: number[];
  parametroValor: ParametroValor[] | null;
  date: MaterialUiPickersDate;
  onConfirm(dados: RequestData): void;
  editData: RequestData | null;
};

const SelecionarTanques: React.FC<Props> = ({
  excluirTanqueIds,
  parametroValor,
  date: dateParam,
  open,
  handleClose,
  onConfirm,
  editData,
}) => {
  const store = useStore();

  const finalDate = editData?.data ? Formatter.stringToDate(editData?.data) : dateParam;
  const etapaInicial = editData ? 1 : 0;
  const [etapa, setEtapa] = useState(etapaInicial);
  const [tanqueSelecionado, setTanqueSelecionado] = useState<Tanque | null>(null);
  const [pesquisa, setPesquisa] = useState('');
  const [date, setDate] = useState<MaterialUiPickersDate>(finalDate);

  const tanquesFiltrados = useMemo(
    () =>
      store.tanques.arr
        ?.filter((t) => !excluirTanqueIds.includes(t.id))
        ?.filter((t) => t.nome?.includes(pesquisa) || t.codigo?.includes(pesquisa))
        ?.sort((a, b) => {
          const textA = a.nome ? a.nome.toUpperCase() : a.codigo;
          const textB = b.nome ? b.nome.toUpperCase() : b.codigo;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }),
    [pesquisa, store.tanques.arr],
  );

  function obterParametros() {
    // Obs.: copiado das funções já existentes no arquivo CadastraQualidade.tsx
    const parametro_input: any = document.querySelectorAll('.parametro_input[type=text]');
    const parametros_utilizados = [];

    for (let index = 0; index < parametro_input.length; index++) {
      if (parametro_input[index].value.replace('.', '').replace(',', '.') > 0) {
        parametros_utilizados.push({
          parametro: parametro_input[index].id,
          value: parametro_input[index].value.replace('.', '').replace(',', '.'),
        });
      }
    }
    return parametros_utilizados;
  }

  function avancarEtapa() {
    setEtapa((prev) => prev + 1);
  }

  function voltarEtapa() {
    if (etapa > 0) setEtapa((prev) => prev - 1);
  }

  function selecionarTanque(tanque: Tanque) {
    if (tanque.uuid !== tanqueSelecionado?.uuid) {
      setTanqueSelecionado(tanque);
      avancarEtapa();
    } else {
      setTanqueSelecionado(null);
    }
  }

  function handleConfirm() {
    if (tanqueSelecionado) {
      const parametros_utilizados = obterParametros();
      const dados = {
        tanque: tanqueSelecionado.id,
        data: Formatter.dateToString(date),
        parametros_utilizados,
      };
      onConfirm(dados);
      handleClose();
    }
  }

  useEffect(() => {
    const tanques = store.tanques.arr;
    if (!tanques || tanques?.length === 0) store.tanques.populate();
  }, []);

  useEffect(() => {
    if (editData) {
      const tanqueEncontrado = store.tanques.arr?.find((t) => t.id === editData.tanque);
      setTanqueSelecionado(tanqueEncontrado ?? null);
    }
  }, [editData, store.tanques.arr]);

  function renderizarTanques() {
    return (
      <>
        <Box width="100%" padding="10px" display="flex">
          <TextField label="Pesquisa Pelo Nome" value={pesquisa} onChange={(e) => setPesquisa(e.target.value)} />
        </Box>
        <Box style={{ overflowY: 'scroll' }} padding={1} width={600} height={400}>
          {tanquesFiltrados?.map((tanque: Tanque) => {
            let selecionado = tanque.uuid === tanqueSelecionado?.uuid;
            const lote = tanque.lotes.length > 0 ? tanque.lotes[0] : null;
            const PM = lote?.peso_medio || 0;

            return (
              <Box
                key={tanque.uuid}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="35rem"
                height="4rem"
                mb={1}
                pr={2}
                borderRadius={5}
                boxShadow={4}
                onClick={() => selecionarTanque(tanque)}
                style={{ cursor: 'pointer' }}
              >
                <Box display="flex" alignItems="center">
                  {selecionado && <CheckCircleIcon htmlColor="#00C853" />}
                  <Box ml={selecionado ? 0 : 3}>
                    <Typography>{returnTanque(tanque)}</Typography>
                    <Typography style={{ color: '#00C853' }}>
                      {lote ? Formatter.formatNumber(3, (PM * lote.estoque) / 1000) : '0,000'} Kg
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography align="right">
                    {lote?.codigo} ({Formatter.formatNumber(0, lote?.estoque ?? 0)})
                  </Typography>
                  <Typography align="right" style={{ color: '#00C853' }}>
                    {lote ? lote.fase_lote.nome : 'LIVRE'}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>

        <Box width="100%" display="flex" padding="10px">
          <Botao onClick={handleClose} variant="contained" cor="gray">
            Voltar
          </Botao>
          <Botao onClick={avancarEtapa} disabled={!tanqueSelecionado} variant="contained" cor="#00C853">
            Avançar
          </Botao>
        </Box>
      </>
    );
  }

  function renderizarParametros() {
    return (
      <Box width="540px">
        <TextField
          label="Tanques selecionados"
          disabled
          value={tanqueSelecionado?.nome ?? tanqueSelecionado?.codigo ?? ''}
        />
        <KeyboardDateTimePicker
          autoOk
          disableToolbar
          ampm={false}
          variant="inline"
          margin="normal"
          label="Data da análise"
          value={date}
          onChange={(d) => setDate(d)}
          onError={(error) => console.log(error)}
          format="dd/MM/yyyy HH:mm"
        />
        <Divisor>
          <p>Parâmetros</p>
        </Divisor>

        {!editData &&
          parametroValor?.map((parametro) => {
            return (
              <div key={parametro.id}>
                {parametro.used ? (
                  <InputNumero
                    value={0}
                    precision="3"
                    label={parametro.parametro.nome}
                    className="parametro_input"
                    id={parametro.parametro.id.toString()}
                  />
                ) : null}
              </div>
            );
          })}

        {editData &&
          store.parametroValor?.map((item) => {
            if (item.used) {
              const parametro_utilizado = editData.parametros_utilizados.find(
                (parametro) => parametro.parametro == item.parametro.id,
              );
              const valor = parametro_utilizado ? Number(parametro_utilizado.value) : 0;
              return (
                <div key={item.id}>
                  <InputNumero
                    value={valor}
                    precision="3"
                    label={item.parametro.nome}
                    className="parametro_input"
                    id={item.parametro.id.toString()}
                  />
                </div>
              );
            } else {
              return null;
            }
          })}

        <Box width="100%" display="flex" p="10px">
          <Botao onClick={editData ? handleClose : voltarEtapa} variant="contained" cor="gray">
            Voltar
          </Botao>
          <Botao onClick={handleConfirm} variant="contained" cor="#00C853">
            Salvar
          </Botao>
        </Box>
      </Box>
    );
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>{etapa === 0 ? 'Selecione um Tanque' : 'Parâmetros da análise'}</ModalHeader>
      <ModalBody>
        {etapa === 0 && renderizarTanques()}
        {etapa === 1 && renderizarParametros()}
      </ModalBody>
    </Modal>
  );
};

export default observer(SelecionarTanques);
