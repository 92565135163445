import React, { useEffect } from 'react';
import { Flex } from '../../../components/NavBar/styles';
import { NewBody, Conteudo } from '../../../components/Body';
import { IconButton, Tooltip } from '@material-ui/core';
import useStore from '../../../services/hooks/useStore';
import Select from '../../../components/Select';
import { relatoriosVendas } from '../../../services/utils/relatorios';
import { useHistory } from 'react-router-dom';
import { useLocalStore, observer } from 'mobx-react-lite';
import MaterialTable, { Column } from 'material-table';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Botao from '../../../components/Botoes/Botao';
import useModal from '../../../services/hooks/useModal';
import Formatter from '../../../services/Formatter';
import Filtro from '../../../components/Filtro';
import SelecionarLoteTanques from '../Producao/crescimento/SelecionarLoteTanques';
import SelecionarLote from '../Producao/crescimento/SelecionarLote';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { apiMain, aqReports } from '../../../services/utils';
import { newConfigVendas } from '../../../components/Tabela';
import { permissoes } from '../../../services/utils/permissoes';

interface Resumo {
  peso: number;
  data: string;
  cliente: string;
  documento: string;
  categoria: string;

  lote: {
    uuid: string;
    codigo: string;
  };
  lotetanque: string;
  quantidade: number;
  tanque: {
    uuid: string;
    nome: string;
  };
  valor_por_quilo: number;
  valor_total: number;
  valor_unit: number;
}

const ResumoVendas: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.FINANCEIRO_PAINEL_FINANCEIRO_LEITURA, history);

  const modalSelecionarLote = useModal();
  const modalTanques = useModal();
  const state = useLocalStore(() => ({
    totais: 0,
    quantidade: 0,
    pesos: 0,
    show: false,
    data_ini: null as MaterialUiPickersDate,
    data_fim: null as MaterialUiPickersDate,
    filtroRapidoFinalizado: false,
    filtroRapidoTanqueFinalizado: false,
    filtroRapidoFase: null as SelectRow | null,
    lote: null as SelectRow | null,
    pdfLote: null as SelectRow | null,
    cliente: null as { label: string; value: string; id: string } | null,
    programa: undefined as SelectRow | undefined,
    tanques: [] as SelectedTanque[],
    tanque: null as { label: string; value: string; loteTanque: string } | null,
    pdfTanque: null as { label: string; value: string } | null,
    loteOuTanque: true as boolean | null,
    lotes: [] as SelectRow[],

    pageSize: 50,
    setPageSize(value: number) {
      this.pageSize = value;
    },
    rows: [] as Resumo[],
    sync: false,
    selectedResumo: null as null | Resumo,
    setSelectedResumo(rowData: null | Resumo) {
      this.selectedResumo = rowData;
    },
  }));

  const rows = (contas: Resumo[]): object[] => {
    state.pageSize = contas.length;

    return contas.map((conta) => {
      return {
        cliente: conta.cliente,
        data: conta.data,
        documento: conta.documento,
        categoria: conta.categoria,
        valor_por_quilo: conta.valor_por_quilo,
        peso: conta.peso,
        quantidade: conta.quantidade,
        valor_total: conta.valor_total,
      };
    });
  };

  async function filter(): Promise<void> {
    store.toggleLoader();
    try {
      await aqReports
        .get(`/vendas/${store.propriedade?.uuid}/relatorio`, {
          params: {
            data_inicio: state.data_fim ? Formatter.dateToString(state.data_ini, '-') : '',
            data_fim: state.data_fim ? Formatter.dateToString(state.data_fim, '-') : '',
            cliente: state.cliente ? state.cliente.value : '',
            lote: state.lote ? state.lote.value : '',
            lotetanque: state.tanque ? state.tanque.value : '',
          },
        })
        .then((res) => {
          state.rows = res.data.results;
          state.totais = res.data.total.valor_total;
          state.pesos = res.data.total.total_peso;
          state.quantidade = res.data.total.quantidade;
        });
    } catch (err) {}

    store.toggleLoader();
  }
  function limparFiltro(): void {
    state.data_fim = null;
    state.data_ini = null;
    state.lote = null;
    state.tanque = null;
    state.pdfLote = null;
    state.pdfTanque = null;
    state.data_fim = null;
    state.cliente = null;
  }

  useEffect(() => {
    store.setTitulo('Listagem de Vendas');
  }, [store]);

  useEffect(() => {
    async function sync() {
      await store.lotes.populate();
      await store.tanques.populate();
      await store.loteTanque.populate();
      await store.clientes.populate();
    }
    if (store.propriedade) {
      sync();
      state.sync = true;
    }
    // eslint-disable-next-line
  }, [store.propriedade, state.sync]);

  const columns: Column<object>[] = [
    {
      field: 'cliente',
      title: 'Cliente',
      type: 'string',
      width: 150,
    },
    {
      field: 'data',
      title: 'Data',
      type: 'date',
      width: 160,
    },
    {
      field: 'documento',
      title: 'Documento',
      type: 'string',
      width: 120,
    },

    {
      field: 'categoria',
      title: 'Categoria',
      type: 'string',
      width: 120,
    },

    {
      field: 'valor',
      title: 'Valor/Kg (R$)',
      width: 150,
      render: ({ valor_por_quilo }: any) => {
        return Formatter.formatNumber(2, valor_por_quilo);
      },
      type: 'numeric',
    },
    {
      field: 'peso',
      title: 'Biomassa/Kg',
      width: 150,
      render: ({ peso }: any) => {
        return Formatter.formatNumber(2, peso);
      },
      type: 'numeric',
    },
    {
      field: 'quantidade',
      title: 'Quantidade',
      type: 'numeric',
      width: 150,
      render: ({ quantidade }: any) => {
        return Formatter.formatNumber(0, quantidade);
      },
    },
    {
      field: 'total',
      title: 'Total (R$)',
      type: 'numeric',
      width: 160,
      render: ({ valor_total }: any) => {
        return Formatter.formatNumber(2, valor_total);
      },
    },
  ];

  async function getCSV() {
    try {
      const pdf = await apiMain.get(`/gerador/csv/vendas/${store.propriedade?.id}/relatorio/`, {
        params: {
          authori: store.token,
          data_inicio: state.data_ini ? Formatter.dateToString(state.data_ini, '-') : '',
          data_fim: state.data_fim ? Formatter.dateToString(state.data_fim, '-') : '',
          cliente: state.cliente ? state.cliente.id : '',
          lote: state.pdfLote ? state.pdfLote.value : '',
          lotetanque: state.pdfTanque ? state.pdfTanque.value : '',
        },
      });
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o CSV! Favor entrar em contato com o Suporte.');
    }
  }
  async function getPDF() {
    try {
      const pdf = await apiMain.get(`/gerador/pdf/vendas/${store.propriedade?.id}/relatorio/`, {
        params: {
          authori: store.token,
          data_inicio: state.data_ini ? Formatter.dateToString(state.data_ini, '-') : '',
          data_fim: state.data_fim ? Formatter.dateToString(state.data_fim, '-') : '',
          cliente: state.cliente ? state.cliente.id : '',
          lote: state.pdfLote ? state.pdfLote.value : '',
          lotetanque: state.pdfTanque ? state.pdfTanque.value : '',
        },
      });
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }

  return state.sync ? (
    <NewBody>
      <Flex style={{ justifyContent: 'space-between' }}>
        <div style={{ width: 250, marginRight: 15 }}>
          <Select
            placeholder="Relatório"
            options={relatoriosVendas}
            defaultValue={relatoriosVendas[1]}
            onChange={(e: any) => {
              history.push('/relatorios/' + e.value);
            }}
          />
        </div>
        <Flex>
          <Tooltip title="Exportar para Excel">
            <IconButton className="blue" onClick={getCSV}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Baixar PDF">
            <IconButton className="blue" onClick={getPDF}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
          <Flex>
            <Filtro sync={filter} clear={limparFiltro}>
              <div style={{ width: '95%', marginTop: 10 }}>
                {store.clientes!.arr && (
                  <Select
                    placeholder="Escolha um Cliente"
                    value={state.cliente}
                    options={store.clientes!.arr.map((programa) => {
                      return { label: programa.nome, value: programa.uuid, id: programa.id };
                    })}
                    onChange={(e: any) => {
                      state.cliente = e;
                    }}
                  />
                )}
              </div>
              <div style={{ width: '95%', marginTop: 10 }}>
                <p>Lote: {state.lote ? `${state.lote.label}` : state.tanque ? state.tanque.loteTanque : '----'}</p>
              </div>

              <div style={{ width: '90%', marginTop: 10 }}>
                <Botao disabled={state.tanque !== null} onClick={() => modalSelecionarLote.open()}>
                  Adicionar Lote
                </Botao>
              </div>
              <div style={{ width: '95%', marginTop: 10 }}>
                <p>Tanque: {state.tanque ? `${state.tanque.label}` : '---'}</p>
              </div>

              <div style={{ width: '90%', marginBottom: 10 }}>
                <Botao disabled={state.lote !== null} onClick={() => modalTanques.open()}>
                  Adicionar Tanque
                </Botao>
              </div>

              <div style={{ width: '90%', marginBottom: 10 }}>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data Inicial"
                  value={state.data_ini}
                  onChange={(date) => (state.data_ini = date!)}
                />
              </div>
              <div style={{ width: '90%', marginBottom: 10 }}>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data Final"
                  value={state.data_fim}
                  onChange={(date) => (state.data_fim = date!)}
                />
              </div>
            </Filtro>
          </Flex>
        </Flex>
      </Flex>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 10 }}>
        {store.propriedade && (
          <Conteudo style={{ padding: 0 }}>
            <MaterialTable
              columns={columns}
              data={rows(state.rows)}
              options={{
                ...newConfigVendas.options,
                pageSize: 20,
              }}
              localization={newConfigVendas.localization}
              style={newConfigVendas.style}
            />

            <Flex
              style={{
                width: '100%',
                padding: '1rem',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '0 auto',
                marginBottom: '-15px',
                border: '1px solid #999',
              }}
            >
              <p>
                <strong>Valor Total(R$): </strong>

                {Formatter.formatNumber(2, state.totais)}
              </p>
              <p>
                <strong>Peso Total (Kg): </strong>

                {Formatter.formatNumber(3, state.pesos)}
              </p>
              <p>
                <strong>Quantidade Total: </strong>

                {Formatter.formatNumber(0, state.quantidade)}
              </p>
            </Flex>
          </Conteudo>
        )}
      </div>
      <SelecionarLote
        state={state}
        open={modalSelecionarLote.state}
        handleClose={modalSelecionarLote.close}
        onItemClick={(lote) => {
          state.tanque = null;
          state.pdfTanque = null;
          state.lote = { label: lote.codigo, value: lote.uuid };
          state.pdfLote = { label: lote.codigo, value: lote.id };
        }}
        loteID={state.lote ? parseInt(state.lote.value) : null}
      />

      <SelecionarLoteTanques
        open={modalTanques.state}
        handleClose={modalTanques.close}
        setTanque={(tanque) => {
          state.pdfLote = null;
          state.tanque = { label: tanque.label, value: tanque.value, loteTanque: tanque.tanque };
          state.pdfTanque = { label: tanque.label, value: tanque.loteTanque };
        }}
        setLote={(lote) => console.log(lote)}
        state={state}
        exclude={[]}
      />
    </NewBody>
  ) : null;
};

export default observer(ResumoVendas);
