/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { Modal, ModalHeader, ModalBody, Divisor } from '../../../components/Modais/styles';
import { apiV2 } from '../../../services/utils';
import Botao from '../../../components/Botoes/Botao';
import useStore from '../../../services/hooks/useStore';
import { TanqueItem } from './styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useLocalStore, observer } from 'mobx-react-lite';
import InputNumero from '../../../components/InputNumero';
import useModal from '../../../services/hooks/useModal';
import SelecionarFornecedor from './SelecionarFornecedor';
import Select from '../../../components/Select';
import add from 'date-fns/add';
import Formatter from '../../../services/Formatter';

type Props = ModalProps & {
  lote: Lote;
};
type State = {
  codigo: string;
  especie: SelectRow;
  fornecedor: SelectRow;
  fase: SelectRow;
  valor: number;
  pesoMedio: number;  
  pesoMedioEsperado: number;
  tamanho: number;
  data: MaterialUiPickersDate;
  previsao: number;
  dataPrevista: MaterialUiPickersDate;
  sync: boolean;
  selectFornecedor: boolean;
  tanque: Lote;
};
const EditarLote: React.FC<Props> = ({ open, handleClose, lote }) => {
  const modalFornecedor = useModal();
  const store = useStore();
  const state = useLocalStore(
    (): State => ({
      codigo: lote.nome || lote.codigo,
      especie: lote.especie_explorada
        ? { label: lote.especie_explorada.nome, value: lote.especie_explorada.id.toString() }
        : { label: '', value: '' },
      fornecedor: { label: lote.fornecedor.nome, value: lote.fornecedor.id.toString() },
      fase: { label: lote.fase_lote.nome, value: lote.fase_lote.id.toString() },
      valor: lote.valor,
      pesoMedio: lote.peso_medio,      
      pesoMedioEsperado:lote.pm_esperado,
      tamanho: lote.tamanho_medio ? lote.tamanho_medio : 0,
      data: new Date(lote.data_alojamento),
      previsao: lote.previsao ? lote.previsao : 0,

      dataPrevista: null,

      selectFornecedor: false,
      sync: false,
      tanque: {} as Lote,
    }),
  );
  async function editar(): Promise<void> {
    if (!state.fase) store.notificar('Selecione a fase do lote');
    else {
      store.toggleLoader();

      const loteEditado = {
        codigo: state.codigo,
        data_prevista: Formatter.dateToString(state.dataPrevista),
        fase_lote: store.fasesLote.get(parseInt(state.fase.value))?.id,
        dias_previstos: Number(state.previsao),
        peso_medio: Number(state.pesoMedio),
        pm_esperado: Number(state.pesoMedioEsperado),
      };

      await apiV2
        .patch('/lote/' + lote.id + '/?prop=' + store.propriedade!.id, loteEditado)
        .then(() => store.notificar('Lote editado com sucesso!'))
        .catch(() => store.notificar('Ocorreu um erro ao editar, tente novamente'));
      await Promise.all([store.lotes.populate(), store.loteTanque.populate()]);

      store.toggleLoader();
      handleClose();
    }
  }

  const TanquesSelecionados: React.FC = () => (
    <>
      {lote.lote_tanque &&
        lote.lote_tanque.map((tanque) => (
          <TanqueItem key={tanque.id_lotetanque}>
            <p>{tanque.tanque_codigo || tanque.tanque_nome}</p>
          </TanqueItem>
        ))}
    </>
  );
  async function sync(): Promise<void> {
    store.toggleLoader();

    await store.lotes.populate();

    await apiV2
      .get(`/lote/${lote.id}/`, {
        params: {
          prop: store.propriedade!.id,
        },
      })
      .then((res) => {
        state.tanque = res.data;

        state.codigo = state.tanque.nome || state.tanque.codigo;
        state.especie = state.tanque.especie_explorada
          ? { label: state.tanque.especie_explorada.nome, value: state.tanque.especie_explorada.id.toString() }
          : { label: '', value: '' };
        state.fornecedor = { label: state.tanque.fornecedor.nome, value: state.tanque.fornecedor.id.toString() };
        state.fase = { label: state.tanque.fase_lote.nome, value: state.tanque.fase_lote.id.toString() };
        state.valor = state.tanque.valor;
        state.pesoMedio = state.tanque.peso_medio;
        state.tamanho = state.tanque.tamanho_medio ? state.tanque.tamanho_medio : 0;
        state.data = new Date(
          state.tanque.data.substr(3, 2) + '/' + state.tanque.data.substr(0, 2) + '/' + state.tanque.data.substr(6, 4),
        );
        state.previsao = state.tanque.dias_previstos ? state.tanque.dias_previstos : 0;

        state.dataPrevista = add(state.data!, { days: state.previsao });
      });

    store.toggleLoader();
    state.sync = true;
  }

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  return state.sync ? (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Editar lote</ModalHeader>
      <ModalBody>
        <TextField label="Nome do Lote" value={state.codigo} onChange={(e) => (state.codigo = e.target.value)} />
        <div style={{ width: '100%', marginTop: 10 }}>
          {store.especies.arr && (
            <Select
              disabled
              value={state.especie}
              placeholder="Espécie"
              onChange={(e: any) => (state.especie = e)}
              options={store.especies.getSelectRows()}
            />
          )}
        </div>
        <div style={{ width: '100%', marginTop: 10 }}>
          {store.fornecedores.arr && (
            <Select
              disabled
              value={state.fornecedor}
              placeholder="Fornecedor"
              onChange={(e: any) => (state.fornecedor = e)}
              options={store.fornecedores.getSelectRows()}
              onFocus={(e) => {
                e.target.blur();
                state.selectFornecedor = false;
                modalFornecedor.open();
              }}
            />
          )}
        </div>

        <KeyboardDatePicker
          disabled
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          value={state.data}
          onChange={(date) => {
            state.data = date;

            state.dataPrevista = add(state.data!, { days: state.previsao });
          }}
        />

        <div style={{ width: '100%' }}>
          <h2>Tanques:</h2>
          <TanquesSelecionados />
        </div>

        <div style={{ width: '100%' }}>
          <Select
            value={state.fase}
            placeholder="Fase do Lote"
            onChange={(e: any) => (state.fase = e)}
            options={store.fasesLote.getSelectRows()}
          />
        </div>

        <InputNumero
          disabled
          value={state.valor}
          onChange={(value) => (state.valor = value)}
          precision="2"
          label="Valor do lote (R$)"
        />
        <InputNumero
          value={state.pesoMedio}
          onChange={(value) => (state.pesoMedio = value)}
          precision="2"
          label="Peso Médio (g)"
        />
        {/* <InputNumero
          value={state.tamanho}
          onChange={(value) => (state.tamanho = value)}
          precision="2"
          label="Tamanho (cm) *Opcional"
        /> */}

        <Divisor>
          <p>Previsão de Despesca</p>
        </Divisor>
        <InputNumero
            value={state.pesoMedioEsperado}
            onChange={(value) => (state.pesoMedioEsperado = value)}
            precision="3"
            label="Peso Médio Esperado (g)"
          />
        <TextField
          value={state.previsao}
          onChange={(e: any) => {
            state.previsao = parseInt(e.target.value);
            state.dataPrevista = add(state.data!, { days: parseInt(e.target.value) });
          }}
          type="number"
          label="Dias Previstos"
          inputProps={{ min: 0 }}
        />

        <div style={{ margin: 10, textAlign: 'center' }}>
          <h2>Data Prevista</h2>
          <h1 style={{ color: '#42a5f5' }}>
            {state.dataPrevista ? Formatter.dateToString(state.dataPrevista, '/') : 'Sem previsão'}
          </h1>
        </div>

        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao onClick={editar} variant="contained" cor="#00C853">
            Salvar
          </Botao>
        </div>
      </ModalBody>
      <SelecionarFornecedor
        onConfirm={(fornecedor: SelectRow) => (state.fornecedor = fornecedor)}
        open={modalFornecedor.state}
        handleClose={modalFornecedor.close}
      />
    </Modal>
  ) : null;
};

export default observer(EditarLote);
