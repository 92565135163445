import React from 'react';

import useStore from '../../services/hooks/useStore';
import Botao from '../../components/Botoes/Botao';
import { Modal, ModalHeader, ModalBody, Divisor } from '../../components/Modais/styles';
import { useLocalStore, observer } from 'mobx-react-lite';
import Select from 'react-select';
import InputNumero from '../../components/InputNumero';
import { returnTanque } from '../../services/utils/propriedade';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { api } from '../../services/utils';
import syncModel from '../../services/models/Sync';
import Formatter from '../../services/Formatter';

type Props = ModalProps & {
  qualidadeID: number;
};
type State = {
  etapa: number;
  dataAnalise: MaterialUiPickersDate;
  selectedTanque: SelectRow | null;

  salvarAnalise(): void;
};

const CadastraQualidade: React.FC<Props> = ({ open, handleClose, qualidadeID }) => {
  const store = useStore();

  const qualidadeSelected = store.qualidadeDaAgua.get(qualidadeID);
  const etapas = ['Editar Análise'];
  const state = useLocalStore(
    (): State => ({
      etapa: 0,
      dataAnalise: new Date(
        qualidadeSelected.data.substr(3, 2) +
          '/' +
          qualidadeSelected.data.substr(0, 2) +
          '/' +
          qualidadeSelected.data.substr(6, 4) +
          ' ' +
          qualidadeSelected.data.split(' ')[1],
      ),
      selectedTanque: {
        label: returnTanque(qualidadeSelected.tanque),
        value: qualidadeSelected.tanque.id.toString(),
      },

      async salvarAnalise() {
        handleClose();
        store.toggleLoader();

        const parametro_input: any = document.querySelectorAll('.parametro_input_edit[type=text]');
        const parametros_utilizados = [];

        for (let index = 0; index < parametro_input.length; index++) {
          parametros_utilizados.push({
            parametro: parametro_input[index].id,
            value: parametro_input[index].value.replace(',', '.'),
          });
        }

        const data = {
          tanque: this.selectedTanque?.value,
          data: Formatter.dateToString(this.dataAnalise),
          parametros_utilizados,
        };

        await api
          .patch(`/analise-agua/${qualidadeSelected.id}/?propriedade=${store.propriedade!.id}`, data)
          .then(() => {
            syncModel.qualidadeDaAgua();
          })
          .then(() => {
            store.notificar('Análise da água editada com sucesso!');
          })
          .catch(() => {
            store.notificar('Erro na edição da Análise da água, tente novamente!');
          });

        store.toggleLoader();
      },
    }),
  );

  const ParametroInput: React.FC = () => (
    <>
      {store.parametroValor?.map((item) => {
        if (item.used) {
          const parametro_utilizado = qualidadeSelected.parametros_utilizados.filter(
            (parametro) => parametro.parametro.id === item.parametro.id,
          )[0];
          const valor = parametro_utilizado ? parametro_utilizado.value : 0;
          return (
            <div key={item.id}>
              <InputNumero
                value={valor}
                precision="3"
                label={item.parametro.nome}
                className="parametro_input_edit"
                id={item.parametro.id.toString()}
              />
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  );

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{etapas ? etapas[state.etapa] : null}</ModalHeader>
      <ModalBody>
        <>
          <div style={{ width: '540px' }}>
            <Select
              placeholder="Selecione o Tanque"
              value={state.selectedTanque}
              options={store.tanques?.map((tanque) => {
                return {
                  label: returnTanque(tanque),
                  value: tanque.id,
                };
              })}
              classNamePrefix="sel"
            />
            <KeyboardDateTimePicker
              autoOk
              disableToolbar
              ampm={false}
              variant="inline"
              margin="normal"
              label="Data da análise"
              value={state.dataAnalise}
              onChange={(date) => {
                state.dataAnalise = date;
              }}
              onError={(error) => {
                console.log(error);
              }}
              format="dd/MM/yyyy HH:mm"
            />
            <Divisor>
              <p>Parâmetros</p>
            </Divisor>

            <ParametroInput />

            <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
              <Botao onClick={handleClose} variant="contained" cor="#FC7467">
                Cancelar
              </Botao>
              <Botao onClick={state.salvarAnalise} variant="contained" cor="#00C853">
                Salvar
              </Botao>
            </div>
          </div>
        </>
      </ModalBody>
    </Modal>
  );
};

export default observer(CadastraQualidade);
