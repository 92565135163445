import { IconButton, Paper } from '@material-ui/core';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from '../../../../components/Modais/styles';
// import { returnTanque } from '../../../../services/utils/propriedade';
import CloseIcon from '@material-ui/icons/Close';
import Formatter from '../../../../services/Formatter';
import { AplicacaoInsumo } from '../../../../@types/Insumo';

interface Props extends ModalProps {
  aplicacao: AplicacaoInsumo;
}

const VerTanques: React.FC<Props> = (props) => {
  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <ModalHeader>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span>Tanques do Insumo</span>
          <IconButton onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </ModalHeader>
      <ModalBody>
        {props.aplicacao.tanque_data.map((tanqueData: any) => {
          return (
            <Paper style={{ width: '90%', padding: 10, marginBottom: 15 }} elevation={3} key={tanqueData.id}>
              <p>
                <b>Tanque: </b> {tanqueData.tanque__nome ? tanqueData.tanque__nome : tanqueData.tanque__codigo}
              </p>
              <p>
                <b>Nome Insumo: </b> {props.aplicacao.insumo.nome}
              </p>
              <p>
                <b>Qtde: </b>
                {Formatter.formatNumber(3, tanqueData.quantidade)}
              </p>
            </Paper>
          );
        })}
      </ModalBody>
    </Modal>
  );
};

export default VerTanques;
