import { Button } from '@material-ui/core';
import MaterialTable, { Column } from 'material-table';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Link, useHistory } from 'react-router-dom';
import pt from 'apexcharts/dist/locales/pt.json';
import { Conteudo, NewBody } from '../../../components/Body';
import { Flex } from '../../../components/NavBar/styles';
import { config } from '../../../components/Tabela';
import Formatter from '../../../services/Formatter';
import useModal from '../../../services/hooks/useModal';
import useStore from '../../../services/hooks/useStore';
import { apiMain, aqReports } from '../../../services/utils';
import { ButtonDisable, ButtonOut, ReportButton } from '../../Financeiro/Painel/styles';
import SelecionarLote from '../Producao/crescimento/SelecionarLote';
import SelecionarLoteTanques from '../Producao/crescimento/SelecionarLoteTanques';
import Tooltip from '@mui/material/Tooltip';
import { permissoes } from '../../../services/utils/permissoes';

const HistoricoCa: React.FC = () => {
  const store = useStore();
  const modalSelecionarLote = useModal();
  const modalTanques = useModal();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.PAINEL_ZOOTECNICO_LEITURA, history);

  const state = useLocalStore(() => ({
    conta: null as SelectRow | null,
    contas: [] as SelectRow[],
    lote: null as SelectRow | null,
    tanques: [] as SelectedTanque[],
    tanque: null as { label: string; value: string; loteTanque: string } | null,
    loteOuTanque: true as boolean | null,
    lotes: [] as SelectRow[],
    movimentacoes: [] as ContaCorrente[],
    periodos: [] as SelectRow[],
    periodo: '',
    rows: [] as object[],
    sync: false,
    loteTanqueId: [] as object,
  }));

  const graficoMortalidade = useLocalStore(() => ({
    filtro: 0,
    series: [
      {
        name: 'Peso Médio (g)',
        data: [],
      },
      {
        name: 'CA',
        data: [],
      },
    ],
    options: {
      chart: {
        locales: [pt],
        defaultLocale: 'pt',
        type: 'line',
        height: 100,
        id: 'CurvaCrescimento',
        dataLabels: {
          enabled: false,
        },
      },

      legend: {
        tooltipHoverFormatter: function (val: any, opts: any) {
          return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '(g)';
        },
      },
      markers: {
        size: 0,
      },
      stroke: {
        curve: 'smooth',
      },
      dataLabels: {
        enabled: true,
      },

      tooltip: {
        y: [
          {
            title: {
              formatter: function (_: number) {
                return 'Peso Médio (g)';
              },
            },
          },
          {
            title: {
              formatter: function (_: number) {
                return 'CA (g)';
              },
            },
          },
        ],
      },
      title: {
        text: `Lote:
        ${state.lote ? `${state.lote.label}` : state.tanque ? '' : '----'}
        ${state.tanque ? `${state.tanque.loteTanque}` : ''}
        ${'  '} - Tanque:
        ${state.tanque ? `${state.tanque.label}` : '----'}`,
        align: 'left',
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
    },
    async update() {
      store.toggleLoader();
      const response = await aqReports.get(`/resumo/${store.propriedade!.uuid}/tanques/${state.tanque?.value}/ca`);

      if (response) {
        ApexCharts.exec('CurvaCrescimento', 'updateSeries', [
          {
            data: response.data.historico_biometria.map((historico: any) => {
              return { x: historico.data_historico, y: historico.peso_medio || 0 };
            }),
          },
          {
            data: response.data.historico_biometria.map((historico: any) => {
              return {
                x: historico.data_historico,
                y: historico.ca || 0,
              };
            }),
          },
        ]);
      }
      store.toggleLoader();
    },
  }));

  const columns: Column<object>[] = [
    {
      align: 'right',
      field: 'data',
      title: 'Data',
      type: 'date',
      render: (rowData: any) => {
        return rowData.data_historico;
      },
      ...(true && ({ width: 120 } as object)),
    },
    {
      align: 'right',
      field: 'peso_medio',
      title: 'Peso Médio(g)',
      render: (rowData: any) => {
        return Formatter.formatNumber(3, rowData.peso_medio);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      align: 'right',
      field: 'ciclo',
      title: 'Dias',
      ...(true && ({ width: 160 } as object)),
    },
    {
      align: 'right',
      field: 'gp',
      title: 'GP(g)',
      render: (rowData: any) => {
        return Formatter.formatNumber(3, rowData.gp);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      align: 'right',
      field: 'gpd',
      title: 'GPD(g)',
      render: (rowData: any) => {
        return Formatter.formatNumber(3, rowData.gpd);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      align: 'right',
      field: 'ca',
      title: 'CA',
      render: (rowData: any) => {
        return Formatter.formatNumber(2, rowData.ca);
      },
      ...(true && ({ width: 180 } as object)),
    },
  ];

  async function sync(): Promise<void> {
    store.toggleLoader();
    await store.lotes.populate();
    await store.tanques.populate();

    if (state.lote !== null || state.tanque !== null) {
      try {
        const response = await aqReports.get(`/resumo/${store.propriedade!.uuid}/tanques/${state.tanque?.value}/ca`);

        state.rows = response.data.historico_biometria;
        state.loteTanqueId = response.data.lote_tanque;
        graficoMortalidade.update();
      } catch (e) {}
    }
    state.sync = true;

    store.toggleLoader();
  }
  async function getPDF() {
    if (state.tanque === null) {
      store.notificar('Porfavor Selecione um tanque !');
      return;
    }
    try {
      const pdf = await apiMain.get(`/gerador/pdf/resumo/${store.propriedade?.id}/tanques/${state.loteTanqueId}/ca/`, {
        params: {
          //data: state.periodo.split('/')[0] + '/' + parseInt(state.periodo.split('/')[1]),
          authori: store.token,
        },
      });
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }
  async function getCSV() {
    if (state.tanque === null) {
      store.notificar('Porfavor Selecione um tanque !');
      return;
    }
    try {
      const pdf = await apiMain.get(`/gerador/csv/resumo/${store.propriedade?.id}/tanques/${state.loteTanqueId}/ca/`, {
        params: {
          //data: state.periodo.split('/')[0] + '/' + parseInt(state.periodo.split('/')[1]),
          authori: store.token,
        },
      });
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o CSV! Favor entrar em contato com o Suporte.');
    }
  }

  useEffect(() => store.setTitulo('Histórico de Peso Médio (g) e CA'), []);

  useEffect(() => {
    store.propriedade && sync();
  }, [store.propriedade]);

  useEffect(() => {
    if (state.lote || state.tanque) {
      ApexCharts.exec(
        'CurvaCrescimento',
        'updateOptions',
        {
          title: {
            text: `Lote:
          ${state.lote ? `${state.lote.label}` : state.tanque ? '' : '----'}
          ${state.tanque ? `${state.tanque.loteTanque}` : ''}
          ${'  '} - Tanque:
          ${state.tanque ? `${state.tanque.label}` : '----'}`,
            align: 'left',
          },
        },
        false,
        true,
      );
    }
  }, [state.lote, state.tanque]);

  return state.sync ? (
    <NewBody>
      <Flex
        style={{
          width: '100%',
          height: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <Link
          to={'/relatorios/relatorios-zootecnicos'}
          style={{ color: '#049CE7', textDecoration: 'none', fontFamily: 'Roboto' }}
        >
          <Flex>
            <img src={'/images/returnArrow.svg'} />
            <div style={{ paddingLeft: '5px', fontFamily: 'Roboto', color: '#049CE7', marginRight: '10px' }}>
              Lista de Relatórios
            </div>
          </Flex>
        </Link>

        <Tooltip title="Indisponivel">
          <ButtonDisable>Escolha um Lote</ButtonDisable>
        </Tooltip>

        <p style={{ margin: '0 .5rem' }}>ou</p>

        <ButtonOut disabled={state.lote !== null} onClick={() => modalTanques.open()}>
          Escolha um Tanque
        </ButtonOut>

        <ReportButton style={{ marginLeft: '2rem' }} onClick={() => sync()}>
          Gerar Relatório
        </ReportButton>
        <div
          style={{
            marginLeft: 'auto',
          }}
        >
          <Button className="blue" onClick={getCSV}>
            <Flex>
              <div style={{ paddingRight: '10px', fontFamily: 'Roboto', color: '#303030', fontSize: '12px' }}>
                Exportar Excel
              </div>
              <img src={'/images/exportCSV.svg'} />
            </Flex>
          </Button>
          <Button className="blue" onClick={getPDF}>
            <Flex>
              <div style={{ paddingRight: '10px', fontFamily: 'Roboto', color: '#303030', fontSize: '12px' }}>
                Exportar PDF
              </div>
              <img src={'/images/exportPDF.svg'} />
            </Flex>
          </Button>
        </div>
      </Flex>

      <div style={{ display: 'grid', gridTemplateColumns: '50% 50%', gap: 10 }}>
        <Conteudo style={{ padding: 0 }}>
          <MaterialTable
            columns={columns}
            data={state.rows}
            options={{
              ...config.options,
              paging: false,
            }}
            localization={config.localization}
            style={config.style}
          />
        </Conteudo>

        <Conteudo overflowY>
          <ReactApexChart
            options={graficoMortalidade.options as any}
            series={graficoMortalidade.series}
            type="line"
            height="400px"
          />
        </Conteudo>
      </div>

      <SelecionarLote
        state={state}
        open={modalSelecionarLote.state}
        handleClose={modalSelecionarLote.close}
        onItemClick={(lote) => {
          state.tanques = [];
          state.lote = { label: lote.codigo, value: lote.uuid };
        }}
        loteID={state.lote ? parseInt(state.lote.value) : null}
      />

      <SelecionarLoteTanques
        open={modalTanques.state}
        handleClose={modalTanques.close}
        setTanque={(tanque: any) => {
          state.tanque = { label: tanque.label, value: tanque.value, loteTanque: tanque.tanque };
        }}
        setLote={() => {}}
        state={state}
        exclude={[]}
      />
    </NewBody>
  ) : null;
};

export default observer(HistoricoCa);
