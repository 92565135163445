import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Divisor } from '../../components/Modais/styles';
import Botao from '../../components/Botoes/Botao';
import useStore from '../../services/hooks/useStore';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useLocalStore, observer } from 'mobx-react-lite';

import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import InputNumero from '../InputNumero';

import NewSelect from '../Select/newSelect';
import Select from '../Select';
import Formatter from '../../services/Formatter';
import { apiV2 } from '../../services/utils';

type Props = ModalProps & {
  onConfirm(): void;
  stateProps: any;
};

const ParcelaParcial: React.FC<Props> = ({ open, handleClose, stateProps, onConfirm }) => {
  const store = useStore();
  const state = useLocalStore(() => ({
    conta: {} as SelectRow,
    categoria: { value: '', label: '' },
    data: stateProps.data_pagamento,
    nova_data: new Date() as MaterialUiPickersDate,
    documento: '',
    pago: false,
    parcial: false,
    pagamento: { value: '', label: '' } as SelectRow,
    valor: stateProps.props.valor,
    valor_pago: 0,
  }));

  useEffect(() => {
    state.pagamento = store.tiposDePagamento
      .getSelectRows()
      .filter((tipo) => parseInt(tipo.value) === stateProps.props.pagamento_tipo)[0];
    state.conta = store.contas
      .getSelectRows()
      .filter((item: any) => parseInt(item.value) === parseInt(stateProps.props.conta))[0];
    state.categoria = stateProps.categoria;
    state.valor = stateProps.props.valor;
    state.data = stateProps.props.data_pagamento;
  }, []);

  async function registrarDespesa(): Promise<void> {
    if (state.valor < state.valor_pago) {
      return store.notificar('Valor parcial é maior que o valor da parcela!');
    }
    store.toggleLoader();
    await apiV2
      .patch(`/parcela/${stateProps.props.id}/?propriedade=${store.propriedade!.id}`, {
        num_parcela: stateProps.props.num_parcela,
        valor: state.valor_pago,
        conta: state.conta ? parseInt(state.conta.value) : undefined,
        pagamento_tipo: state.pagamento ? parseInt(state.pagamento.value) : undefined,
        nova_data: Formatter.dateToString(state.nova_data),
        data_pagamento: Formatter.dateToString(state.data),
        recebido: state.pago,
        parcial: state.parcial,
      })
      .then(() => {
        store.notificar('Parcela baixada com sucesso!');
      })
      .catch(() => store.notificar('Ocorreu um erro ao baixar a parcela!'));
    onConfirm();
    store.toggleLoader();
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>
        Editar Parcela{' '}
        {`${stateProps.props.num_parcela}${
          stateProps.props.num_parcela_parcial ? `-${stateProps.props.num_parcela_parcial}` : ''
        }`}
        /{stateProps.parcelas.length}
      </ModalHeader>
      <ModalBody style={{ width: '450px' }}>
        <div style={{ width: '100%', marginBottom: '15px' }}>
          <Select
            placeholder="Categoria"
            value={stateProps.categoria}
            disabled={true}
            options={store.categorias.getSelectRows()}
          />
        </div>

        <div style={{ width: '100%' }}>
          <Select
            placeholder="Fornecedor"
            value={stateProps.fornecedor}
            disabled
            options={store.clientes.getSelectRows()}
          />
        </div>

        <TextField value={stateProps.descricao} disabled />

        <TextField value={stateProps.documento} disabled />

        <Divisor>
          <p>Parcelas</p>
        </Divisor>

        <div
          style={{
            width: '100%',
            marginTop: 18,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data"
            value={state.data}
            onChange={(date) => (state.data = date)}
            style={{ marginTop: '-0px' }}
          />
          {store.contas.arr && (
            <NewSelect
              margin={true}
              value={state.conta}
              placeholder="Conta Banco"
              onChange={(e) => (state.conta = e as SelectRow)}
              options={store.contas.getSelectRows()}
            />
          )}
        </div>

        <div
          style={{
            width: '100%',
            marginTop: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <InputNumero
            value={state.valor}
            onChange={(value) => (state.valor = value)}
            precision="2"
            label="Valor"
            disabled
          />
          {store.tiposDePagamento.arr && (
            <NewSelect
              margin={true}
              value={state.pagamento}
              placeholder="Forma de pagamento"
              options={store.tiposDePagamento.getSelectRows()}
            />
          )}
        </div>
        <div
          style={{
            width: '100%',
            marginTop: 0,
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'start',
          }}
        >
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox checked={state.pago} onChange={(e) => (state.pago = e.target.checked)} color="primary" />
            }
            label="Pago ?"
            style={{ width: '49%' }}
          />
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox checked={state.parcial} onChange={(e) => (state.parcial = e.target.checked)} color="primary" />
            }
            label="Parcial ?"
            style={{ width: '48%' }}
          />
        </div>

        {state.parcial === true && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <InputNumero
              value={state.valor_pago}
              onChange={(value) => (state.valor_pago = value)}
              precision="2"
              label="Valor Pago"
            />
            <KeyboardDatePicker
              autoOk
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Data Do Restante"
              value={state.nova_data}
              onChange={(date) => (state.nova_data = date)}
              style={{ marginTop: '-0px', marginLeft: '5px' }}
            />
          </div>
        )}

        <div style={{ width: '100%', display: 'flex' }}>
          <Botao cor="gray" onClick={handleClose}>
            Voltar
          </Botao>
          <Botao
            cor="#00C853"
            onClick={() => {
              if (state.categoria) {
                if (state.categoria) {
                  registrarDespesa();
                } else store.notificar('Selecione uma categoria');
              } else {
                registrarDespesa();
              }
            }}
          >
            Salvar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(ParcelaParcial);
