import { useState } from 'react';

export type ModalHook = {
  state: boolean;
  open(): void;
  close(): void;
};
/**
 * Hook responsável por administrar o estado de um Modal
 * @example
 * modal.state
 * // false
 *
 * modal.open()
 * modal.state
 * // true
 *
 * modal.close()
 * modal.state
 * // false
 */
export default function useModal(): ModalHook {
  const [state, setState] = useState(false);

  return {
    get state() {
      return state;
    },
    open() {
      setState(true);
    },
    close() {
      setState(false);
    },
  };
}
