import { Checkbox, Grid, IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Alert } from '@material-ui/lab';
import { DatePicker } from '@material-ui/pickers';
import { Form, Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Conteudo, NewBody } from '../../../components/Body';
import Botao from '../../../components/Botoes/Botao';
import InputNumero from '../../../components/InputNumero';
import { Scroll } from '../../../components/Modais/styles';
import { Flex } from '../../../components/NavBar/styles';
import Select from '../../../components/Select';
import SelectLote from '../../../components/Select/SelectLote';
import Formatter from '../../../services/Formatter';
import useStore from '../../../services/hooks/useStore';
import { apiV2 } from '../../../services/utils';

import ListaTanque from './CadastroV2ListaTanque';

interface Values {
  programa: SelectRow | null;
  temperatura: SelectRow | null;
  lote: string;
  dataInicio: Date;
  dataFinal: Date;
  tanque_data: { lote_tanque: number; tanque: number }[];
  quantidadeRacao: number;
  quantidadeRacaoDia: number;
  quantidadeDias: number;
  sobrevivencia: number;
  semanas: any[];
  opcoesSemanas: any[];
  listaSemanaTanque: any[];
  lancado: Boolean;
}

function CadastroV2ArracoamentoProgramaTanque(): JSX.Element {
  const store = useStore();
  const history = useHistory();
  const [programas, setProgramas] = useState<any[]>([]);
  const [temperaturas, setTemperaturas] = useState<any[]>([]);
  const [tipo, setTipo] = useState([{ label: 'Por Tanque', value: 'novo-tanque' }]) as any;
  // eslint-disable-next-line no-unused-vars

  const initialValues: Values = {
    programa: null,
    lote: '',
    dataInicio: new Date(),
    dataFinal: new Date(),
    tanque_data: [],
    quantidadeRacaoDia: 0,
    quantidadeRacao: 0,
    quantidadeDias: 0,
    temperatura: null,
    sobrevivencia: 0,
    semanas: [],
    listaSemanaTanque: [],
    opcoesSemanas: [],
    lancado: true,
  };

  function handleBack(): void {
    history.replace('/producao/arracoamento');
  }

  async function sync(): Promise<void> {
    store.toggleLoader();

    let programas = [] as any;

    if (!store.lotes.arr || !store.tanques.arr || !store.loteTanque.arr)
      await Promise.all([store.loteTanque.populate(), store.lotes.populate(), store.tanques.populate()]);

    await apiV2
      .get('/programas-alimentares/', {
        params: { prop: store.propriedade?.id, page_size: 50, page: 1 },
      })
      .then(async (res) => {
        const data = res.data;
        if (res.data.next) {
          do {
            const request = await apiV2.get(data.next);

            data.results = [...data.results, ...request.data.results];
            data.next = request.data.next;
          } while (data.next !== null);
        }
        if (!data.results) {
          programas.push(...data);
        } else {
          programas.push(...data.results);
        }
      });

    const temperaturas = await apiV2.get('/temperatura/', {
      params: { prop: store.propriedade?.id },
    });

    setProgramas(programas);
    setTemperaturas(temperaturas.data.results);

    store.toggleLoader();
  }

  async function handleSubmit(values: Values, { setSubmitting }: FormikHelpers<any>): Promise<void> {
    if (!values.programa) {
      store.notificar('Informe o programa alimentar');
    } else if (!values.lote) {
      store.notificar('Informe o lote');
    } else if (!values.temperatura) {
      store.notificar('Informe a temperatura');
    } else if (!values.tanque_data.length) {
      store.notificar('Informe os tanques');
    } else if (values.quantidadeDias <= 0) {
      store.notificar('O intervalo entre as tadas deve ser maior que zero');
    } else {
      store.toggleLoader();

      try {
        const data = {
          dataInicio: Formatter.dateToString(values.dataInicio),
          dataFinal: Formatter.dateToString(values.dataFinal),
          lote: Number(values.lote),
          programa: Number(values.programa.value),
          tanque_data: values.tanque_data,
          temperatura_id: Number(values.temperatura.value),
          quantidade: values.quantidadeRacaoDia,
          semanas: values.semanas,
          lancado: values.lancado,
        };

        await apiV2.post('/arracoamento/programa-alimentar/', data, {
          params: {
            prop: store.propriedade!.id,
          },
        });

        store.notificar('Arraçoamentos cadastrados com sucesso!');
        handleBack();
      } catch (err) {
        store.notificar('Ocorreu um erro ao cadastrar o arraçoamento!');
      }

      store.toggleLoader();
      setSubmitting(false);
    }
  }

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  return (
    <>
      {programas.length > 0 ? (
        <NewBody>
          <Flex style={{ justifyContent: 'space-between' }}>
            <Grid container justify="flex-start">
              <Grid item>
                <IconButton onClick={handleBack}>
                  <ChevronLeftIcon />
                </IconButton>
              </Grid>

              <Grid item>
                <h1 style={{ color: '#383838' }}>Arraçoamento com programa por Tanque</h1>
              </Grid>
            </Grid>

            <Grid item xs={3}>
              <Select
                value={tipo}
                defaultValue={{ label: 'Novo Por Tanque', value: 'novo-tanque' }}
                placeholder="Tipo Arraçoamento"
                options={[
                  // { label: 'Por Lote', value: 'lote' },
                  { label: 'Por Tanque', value: 'novo-tanque' },
                ]}
                onChange={(value) => {
                  setTipo(value);
                  if (value?.value === 'lote') {
                    history.push('/producao/novo-arracoamento-programa');
                  } else if (value?.value === 'novo-tanque') {
                    history.push('/producao/v2-novo-arracoamento-programa-por-tanque');
                  }
                }}
              ></Select>
            </Grid>
          </Flex>

          <Conteudo overflowY>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({ values, setFieldValue }) => (
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <h2 style={{ color: '#383838' }}>Dados do arraçoamento</h2>
                      <br />
                      <Select
                        value={values.programa}
                        placeholder="Programa Alimentar"
                        onChange={(e) => {
                          setFieldValue('programa', e);
                          setFieldValue('semanas', []);
                          setFieldValue('quantidade', 0);
                        }}
                        options={programas.map((programa) => {
                          const isSemanal = programa.tipo.slug === 'SEMANAL';
                          const isUnique = programa.semanas.length === 1;
                          const strings = ['semanas', 'semana', 'dias', 'dia'];
                          const dias = `${programa.semanas.length} ${
                            isSemanal ? (isUnique ? strings[1] : strings[0]) : isUnique ? strings[3] : strings[2]
                          }`;

                          return { label: `${programa.nome} - ${dias}`, value: programa.id.toString() };
                        })}
                      />
                      <SelectLote onChange={(value) => setFieldValue('lote', Number(value))} />
                      <Select
                        value={values.temperatura}
                        placeholder="Temperatura"
                        onChange={(e) => {
                          setFieldValue('temperatura', e);
                        }}
                        options={temperaturas.map((temp) => ({
                          label: temp.temperatura + '° - ' + temp.racao + '%',
                          value: temp.id.toString(),
                        }))}
                      />

                      <DatePicker
                        autoOk
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        label="Data de início"
                        value={values.dataInicio}
                        disabled={!values.lote}
                        onChange={(date) => {
                          setFieldValue('dataInicio', date);
                          setFieldValue('dataFinal', date);
                        }}
                      />
                      <DatePicker
                        autoOk
                        fullWidth
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        label="Data final prevista"
                        disabled={!values.lote}
                        value={values.dataFinal}
                        onChange={(date) => {
                          setFieldValue('dataFinal', date);
                        }}
                      />
                      <InputNumero
                        value={values.quantidadeDias}
                        allowNegative
                        disabled
                        label="Quantidade de Dias/Semanas"
                        precision="0"
                      />
                      <div>
                        <p style={{ fontSize: 13, color: '#696969' }}>
                          Lançado:{' '}
                          <Checkbox
                            color="primary"
                            defaultChecked={true}
                            disabled={!values.lote}
                            onChange={(_e: any, checked: any) => {
                              checked ? setFieldValue('lancado', true) : setFieldValue('lancado', false);
                            }}
                          />
                        </p>
                      </div>
                      <div style={{ margin: 10, textAlign: 'center' }}>
                        <p style={{ fontSize: 16, color: '#383838' }}>Quantidade total de ração</p>
                        <b style={{ fontSize: 22, color: '#42a5f5' }}>
                          {Formatter.formatNumber(3, values.quantidadeRacaoDia)} kg
                        </b>
                      </div>
                    </Grid>

                    <Grid item xs={8}>
                      <h2 style={{ color: '#383838' }}>Seleção de tanques</h2>
                      <br />

                      <Scroll>
                        {!values.programa ? (
                          <Alert severity="info">Selecione um programa alimentar!</Alert>
                        ) : !values.lote ? (
                          <Alert severity="info">Selecione um lote!</Alert>
                        ) : (
                          <ListaTanque temperaturas={temperaturas} />
                        )}
                      </Scroll>
                    </Grid>
                  </Grid>

                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <Botao cor="gray" onClick={handleBack} variant="contained">
                      Voltar
                    </Botao>
                    <Botao type="submit" variant="contained" cor="#00C853">
                      Salvar
                    </Botao>
                  </div>
                </Form>
              )}
            </Formik>
          </Conteudo>
        </NewBody>
      ) : (
        <Flex style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <h3>Precisa ter Programa Alimentar Cadastrado !</h3>
        </Flex>
      )}
    </>
  );
}

export default observer(CadastroV2ArracoamentoProgramaTanque);
