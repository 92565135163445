import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { TextField, IconButton, FormControlLabel, Switch } from '@material-ui/core';
import Botao from '../../../components/Botoes/Botao';
import useStore from '../../../services/hooks/useStore';
import { apiV2 } from '../../../services/utils';
import DeleteIcon from '@material-ui/icons/Delete';
import { useLocalStore, observer } from 'mobx-react-lite';
import useModal from '../../../services/hooks/useModal';
import Confirmar from '../../../components/Modais/Confirmar';
import { CompraInsumo, TipoInsumo } from '../../../@types/Insumo';
import Select from '../../../components/Select';
import State from '../../../services/State';
import InputNumero from '../../../components/InputNumero';
import { KeyboardDatePicker } from '@material-ui/pickers';

type Props = ModalProps & {
  insumoID: number;
  handleUpdate?: () => void;
};

type StateType = {
  nome: string;
  ativo: boolean | undefined;
  insumoID: number;
  estoque_inicial: number;
  estoque_minimo: number;
  data_ini_estoque: string | null;
  editavel: string | boolean;
  estoque: number;
  tipo_insumo: SelectRow | null;
  tipo_unidade: SelectRow | null;
}

const AddInsumos: React.FC<Props> = ({ open, handleClose, insumoID, handleUpdate }) => {
  const [tiposInsumos, setTiposInsumos] = useState([]);
  const store = useStore();
  const insumo = store.insumos.get(insumoID);

  const state: StateType = useLocalStore(() => ({
    nome: insumo ? insumo!.nome : '',
    ativo: insumo ? insumo!.ativo : true,
    insumoID: insumoID,
    estoque_inicial: insumo ? insumo.estoque_inicial : 0,
    estoque_minimo: insumo ? insumo.estoque_minimo : 0,
    data_ini_estoque: insumo ? insumo.data_ini_estoque : null,
    editavel: insumo ? insumo.editavel : '',
    estoque: 0,
    tipo_insumo: insumo ? { value: insumo.tipo_produto.id, label: insumo.tipo_produto.nome } : null,
    tipo_unidade: insumo ? { value: insumo.tipo_unidade, label: insumo.tipo_unidade } : null, //TODO: enquanto se define os tipos de unidade
  }));

  const modalExclusao = useModal();
  const deletarInsumo = async ({ insumo, store, handleClose }: any) => {
    //TODO: trocar tipo da compra quando a mesma for definida
    if (store.comprasDeInsumos!.filter((compra: CompraInsumo) => compra.id === insumo!.id).length > 0) {
      store.notificar('Esse insumo já possui movimentações!');
    } else {
      handleClose();
      store.toggleLoader();
      await apiV2
        .delete('/produto/' + insumo!.id + '/')
        .then(async () => {
          store.notificar('Insumo excluído com sucesso!');
          if (handleUpdate) handleUpdate();
          await store.racoes.populate();
        })
        .catch(() => store.notificar('Ocorreu um erro na exclusão do insumo!'));
      store.toggleLoader();
    }
  };

  const atualizarInsumo = () => {
    apiV2
      .put(`/produto/${insumoID}/?prop=${store.propriedade!.id}`, {
        nome: state.nome,
        propriedade: store.propriedade!.id,
        estoque_inicial: state.estoque_inicial,
        estoque_minimo: state.estoque_minimo,
        data_ini_estoque: state.data_ini_estoque,
        ativo: state.ativo,
        estoque: state.estoque,
        tipo_produto: state.tipo_insumo?.value,
        tipo_unidade: state.tipo_unidade?.value,
      })
      .then(() => {
        store.notificar('Insumo editado com sucesso!');
        if (handleUpdate) handleUpdate();
      })
      .catch(() => store.notificar('Ocorreu um erro na edição do insumo!'));
  };

  const criarInsumo = ({ store, state }: any) => {
    apiV2
      .post('/produto/', {
        nome: state.nome,
        propriedade: store.propriedade!.id,
        estoque_inicial: state.estoque_inicial,
        estoque_minimo: state.estoque_minimo,
        data_ini_estoque: state.data_ini_estoque,
        ativo: state.ativo,
        estoque: 0,
        tipo_produto: state.tipo_insumo.value,
        tipo_unidade: state.tipo_unidade.value,
      })
      .then(() => {
        store.notificar('Insumo cadastrado com sucesso!');
        if (handleUpdate) handleUpdate();
      })
      .catch(() => store.notificar('Ocorreu um erro no cadastro do insumo!'));
  };

  const consultaTiposInsumos = (store: State, setter: Function): void => {
    apiV2
      .get<any>('/tipoproduto/', {
        params: {
          propriedade: store.propriedade!.id,
        },
      })
      .then((data) => {
        const response: any = [];

        data.data.forEach((data: TipoInsumo) => {
          response.push({
            label: data.nome,
            value: data.id,
          });
        });

        setter(response);
      });
  };

  const wrapRequest = async (callback: any) => {
    handleClose();
    store.toggleLoader();
    await callback();
    // await store.insumos.populate();
    if(handleUpdate) await handleUpdate();
    store.toggleLoader();
  };

  async function cadastrar() {
    if (state.nome !== '') {
      await wrapRequest(async () => {
        insumo ? atualizarInsumo() : criarInsumo({ store, state });
      });
    } else store.notificar('Informe o nome do insumo!');
  }

  async function deletar() {
    if (insumo.editavel ? false : true) {
      store.notificar('Esse insumo já possui movimentações!');
    } else {
      await wrapRequest(deletarInsumo({ racao: insumo, store, handleClose }));
    }
  }

  useEffect(() => {
    consultaTiposInsumos(store, setTiposInsumos);
  }, [store]);

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>
        {insumoID ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>Editar Insumo</p>
            <IconButton onClick={modalExclusao.open}>
              <DeleteIcon />
            </IconButton>
          </div>
        ) : (
          'Cadastro de insumos'
        )}
      </ModalHeader>
      <ModalBody>
        <TextField label="Nome do insumo" value={state.nome} onChange={(e) => (state.nome = e.target.value)} />
        {insumoID ? (
          <div style={{ width: '100%' }}>
            <FormControlLabel
              control={<Switch checked={state.ativo} onChange={() => (state.ativo = !state.ativo)} color="primary" />}
              label="Ativa"
            />
          </div>
        ) : (
          <div style={{ width: '100%' }}>
            <FormControlLabel
              control={<Switch checked={state.ativo} onChange={() => (state.ativo = !state.ativo)} color="primary" />}
              label="Ativa"
            />
          </div>
        )}
        <Select
          value={state.tipo_insumo}
          placeholder="Tipo de insumo"
          onChange={(e: any) => (state.tipo_insumo = e)}
          disabled={insumo ? (insumo.editavel ? false : true) : false}
          options={tiposInsumos}
        />
        <Select
          value={state.tipo_unidade}
          placeholder="Tipo de unidade"
          disabled={insumo ? (insumo.editavel ? false : true) : false}
          onChange={(e: any) => (state.tipo_unidade = e)}
          //TODO: desfazer moc do tipo de unidade
          options={tipos_unidade}
        />
        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data Início do Estoque"
          value={state.data_ini_estoque}
          onChange={(date) => (state.data_ini_estoque = (date as unknown as string))}
        />
        <InputNumero
          value={state.estoque_inicial}
          onChange={(e) => {
            state.estoque_inicial = e;
          }}
          precision="3"
          label="Estoque Inicial"
        />
        <InputNumero
          onChange={(e) => {
            state.estoque_minimo = e;
          }}
          value={state.estoque_minimo}
          precision="3"
          label="Estoque Mínimo"
        />
        <div style={{ width: '100%', display: 'flex', marginTop: '6rem' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          {!insumo ? (
            <Botao onClick={cadastrar} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          ) : (
            <Botao onClick={cadastrar} variant="contained" cor="#00C853">
              Atualizar
            </Botao>
          )}
        </div>
      </ModalBody>
      <Confirmar
        open={modalExclusao.state}
        title="Tem certeza que deseja excluir?"
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={deletar}
      />
    </Modal>
  );
};

export default observer(AddInsumos);

//-----------------------------------------------------------------------------

const tipos_unidade: SelectRow[] = [
  {
    label: 'KG',
    value: 'KG',
  },
  {
    label: 'UN',
    value: 'UN',
  },
  {
    label: 'LT',
    value: 'LT',
  },
  {
    label: 'MT',
    value: 'MT',
  },
];
