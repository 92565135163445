import axios, { AxiosResponse } from 'axios';
import { stateContext } from './hooks/useStore';

const API_URL_MAIN = process.env.REACT_APP_API_URL_MAIN ?? 'https://teste-api.aquabit.com.br';

const API_URL = process.env.REACT_APP_API_URL ?? 'https://teste-api.aquabit.com.br/api/v1';

// @todo: remove it after clean up "newApi" references
// deprecated: old Node api - this should not been used any more
const NEW_API_URL = process.env.REACT_APP_NEW_API_URL ?? 'https://sync.aquabit.com.br';

const API_URL_V2 = process.env.REACT_APP_API_URL_V2 ?? 'https://teste-api.aquabit.com.br/api/v2';

/**
 * Instância `axios` para requisições na API utilizando os `HEADERS`.
 * Para mudar a URL da API basta alterar o `index`
 */
export const api = axios.create({
  baseURL: API_URL,
});
export const newApi = axios.create({
  baseURL: NEW_API_URL,
});
export const apiV2 = axios.create({
  baseURL: API_URL_V2,
});
export const apiMain = axios.create({
  baseURL: API_URL_MAIN,
});
export const apiTest = axios.create({
  baseURL: 'http://9a5d-187-19-155-90.ngrok.io/api/v2',
});

/**
 * Instância `axios` para requisições na API sem `HEADERS`
 */
export const baseApi = axios.create({
  baseURL: API_URL,
});

/**
 * Instância `axios` para requisições no serviço de relatórios
 */
export const aqRelatorios = axios.create({
  baseURL: 'https://report.aquabitfarm.com',
});

const aqReportsUrl = (): string => {
  if (
    window.location.origin === 'https://web.aquabitfarm.com' ||
    window.location.origin === 'https://web.aquabit.com.br'
  ) {
    return 'https://report.aquabitfarm.com/newapi/api/v1';
  } else {
    return 'https://teste-report.aquabit.com.br/newapi/api/v1';
  }
};
export const aqReports = axios.create({
  baseURL: aqReportsUrl(),
});

//Requisição de PDF
const getPdfV2Url = (): string => {
  if (
    window.location.origin === 'https://web.aquabitfarm.com' ||
    window.location.origin === 'https://web.aquabit.com.br'
  ) {
    return 'https://producao.aquabitfarm.com/gerador/pdf';
  } else {
    return 'https://teste-api.aquabit.com.br/gerador/pdf';
  }
};
export const getPdfV2 = axios.create({
  baseURL: getPdfV2Url(),
  method: 'GET',
  responseType: 'blob',
});

const getPdfV2UrlRecibos = (): string => {
  if (
    window.location.origin === 'https://web.aquabitfarm.com' ||
    window.location.origin === 'https://web.aquabit.com.br'
  ) {
    return 'https://producao.aquabitfarm.com/gerador/';
  } else {
    return 'https://teste-api.aquabit.com.br/gerador/';
  }
};
export const getPdfV2Recibos = axios.create({
  baseURL: getPdfV2UrlRecibos(),
  method: 'GET',
});

/**
 * Função responsável por unir as informações por paginação
 * @param response
 */
export async function getEverything<T>(response: AxiosResponse): Promise<T[]> {
  try {
    const data = response.data;
    if (data.next) {
      do {
        const request = await api.get(data.next);

        data.results = [...data.results, ...request.data.results];
        data.next = request.data.next;
      } while (data.next !== null);
    }
    if (!data.results) {
      return data;
    } else {
      return data.results;
    }
  } catch {
    stateContext.state.notificar('Ocorreu um erro no servidor, tente novamente mais tarde.');
    return [];
  }
}

export async function getThings<T>(endpoint: string, params?: object | null): Promise<T[]> {
  if (params) {
    const request = await api.get(endpoint, { params });
    return await getEverything<T>(request);
  } else if (params === null) {
    const request = await api.get(endpoint);
    return await getEverything<T>(request);
  } else {
    const request = await api.get(endpoint, {
      params: {
        propriedade: stateContext.state.propriedade!.id,
        page_size: 50,
      },
    });
    return await getEverything<T>(request);
  }
}

// Criado exclusivamente para o tanque pois precisa da apiV2
export async function getEverythingV2<T>(response: AxiosResponse): Promise<T[]> {
  try {
    // const data = response.data.lista_tanque ? response.data.lista_tanque : response.data.results
    const data = response.data.lista_tanque ? response.data.lista_tanque : response.data;

    if (data.next) {
      do {
        const request = await apiV2.get(data.next);

        data.results = [...data.results, ...request.data.results];
        data.next = request.data.next;
      } while (data.next !== null);
    }

    if (!data.results) {
      return data;
    } else {
      return data.results;
    }
  } catch (error) {
    stateContext.state.notificar('Ocorreu um erro no servidor, tente novamente mais tarde.');
    return [];
  }
}
export async function getEverythingV21<T>(response: AxiosResponse): Promise<T> {
  try {
    // const data = response.data.lista_tanque ? response.data.lista_tanque : response.data.results
    const data = response.data;

    return data;
  } catch (error) {
    stateContext.state.notificar('Ocorreu um erro no servidor, tente novamente mais tarde.');
    return {} as T;
  }
}

// Criado exclusivamente para o tanque pois precisa da apiV2
export async function getThingsV2<T>(endpoint: string, params?: object | null): Promise<T[]> {
  if (params) {
    const request = await apiV2.get(endpoint, { params });
    return await getEverythingV2<T>(request);
  } else if (params === null) {
    const request = await apiV2.get(endpoint);
    return await getEverythingV2<T>(request);
  } else {
    const request = await apiV2.get(endpoint, {
      params: {
        propriedade: stateContext.state.propriedade!.id,
        page_size: 50,
      },
    });
    return await getEverythingV2<T>(request);
  }
}
export async function getThingsV21<T>(endpoint: string, params?: object | null): Promise<T> {
  if (params) {
    const request = await apiV2.get(endpoint, { params });
    return await getEverythingV21(request);
  } else if (params === null) {
    const request = await apiV2.get(endpoint);
    return await getEverythingV21<T>(request);
  } else {
    const request = await apiV2.get(endpoint, {
      params: {
        propriedade: stateContext.state.propriedade!.id,
        page_size: 50,
      },
    });
    return await getEverythingV21<T>(request);
  }
}

export async function getThings25<T>(endpoint: string, params?: object | null): Promise<T[]> {
  if (params) {
    const request = await api.get(endpoint, { params });
    return await getEverything<T>(request);
  } else if (params === null) {
    const request = await api.get(endpoint);
    return await getEverything<T>(request);
  } else {
    const request = await api.get(endpoint, {
      params: {
        propriedade: stateContext.state.propriedade!.id,
        page_size: 25,
      },
    });
    return await getEverything<T>(request);
  }
}

export async function newGetEverything<T>(response: AxiosResponse): Promise<T[]> {
  try {
    const data = response.data;
    if (data.next) {
      do {
        const request = await newApi.get(data.next, {
          params: {
            ...response.config.params,
          },
        });

        data.results = [...data.results, ...request.data.results];
        data.next = request.data.next;
      } while (data.next !== null);
    }
    if (!data.results) {
      return data;
    } else {
      return data.results;
    }
  } catch {
    stateContext.state.notificar('Ocorreu um erro no servidor, tente novamente mais tarde.');
    return [];
  }
}

export async function newGetThings<T>(endpoint: string, params?: { [key: string]: any } | null): Promise<T[]> {
  if (params) {
    params.prop = stateContext.state.propriedade!.id;
    params.page_size = 50;

    const request = await newApi.get(endpoint, { params });
    return await newGetEverything<T>(request);
  } else if (params === null) {
    const request = await newApi.get(endpoint);
    return await newGetEverything<T>(request);
  } else {
    const request = await newApi.get(endpoint, {
      params: {
        prop: stateContext.state.propriedade!.id,
        page_size: 50,
      },
    });
    return await newGetEverything<T>(request);
  }
}
