import styled, { css } from 'styled-components';
import { Button, ButtonGroup } from '@material-ui/core';

type Props = {
  cor?: string;
  disabled?: boolean;
};

export const RoundedButton = styled(Button)`
  box-shadow: 0 2px 4px 0 #00000063 !important;
  background-color: ${(props) => (props.disabled ? '#b5b5b5' : props.theme.colors.primary)} !important;
  color: white !important;

  border-radius: 20px !important;

  margin: 0 10px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;

  font-size: 12px !important;

  svg {
    margin-right: 7px;
  }
`;
export const RoundedButtonRed = styled(Button)<Props>`
  box-shadow: 0 2px 4px 0 #00000063 !important;
  background-color: #e57373 !important;
  color: white !important;

  border-radius: 20px !important;

  margin: 0 10px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;

  font-size: 12px !important;

  svg {
    margin-right: 7px;
  }
`;
export const LeftButton = styled(Button)<Props>`
  border-radius: 20px 0 0 20px !important;

  margin-left: 15px !important;

  box-shadow: 0 2px 4px 0 #00000063 !important;
  color: white !important;
  padding-left: 13px !important;
  padding-right: 13px !important;
  font-size: 12px !important;

  border-color: white !important;

  p {
    margin-left: 10px;
  }

  background-color: ${(props) => (props.disabled ? '#b5b5b5' : props.theme.colors.primary)} !important;
  ${(props) =>
    props.cor &&
    css`
      background-color: ${props.disabled ? '#b5b5b5' : props.cor} !important;
    `}
`;
export const RightButton = styled(Button)<Props>`
  border-radius: 0 20px 20px 0 !important;

  margin-right: 15px !important;
  color: white !important;
  box-shadow: 0 2px 4px 0 #00000063 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 12px !important;

  background-color: ${(props) => (props.disabled ? '#b5b5b5' : props.theme.colors.primary)} !important;
  ${(props) =>
    props.cor &&
    css`
      background-color: ${props.disabled ? '#b5b5b5' : props.cor} !important;
    `}
`;

export const BtnGroup = styled(ButtonGroup)`
  box-shadow: none !important;
`;
