import { getThings } from '../utils';
import { observable, computed } from 'mobx';

class GenericTypes {
  @observable arr: GenericType[] | null = null;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }
  endpoint = '';

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  async populate(): Promise<void> {
    // const request = await getThings<GenericType>(this.endpoint);
    // this.arr = request;
    // this.arr = JSON.parse(localStorage.getItem(this.endpoint)!);
    const request = await getThings<GenericType>(this.endpoint);
    this.arr = request;
    localStorage.setItem(this.endpoint, JSON.stringify(request));
  }

  async getEverything(params?: object): Promise<GenericType[]> {
    return await getThings<GenericType>(this.endpoint, params);
  }

  get(id: number): GenericType | null {
    return this.arr?.filter((item) => item.id === id)[0] || null;
  }

  getSelectRows(): SelectRow[] {
    if (this.arr?.length) {
      return this.arr.map((item: GenericType) => {
        return { label: item.nome, value: item.id.toString() };
      });
    }
    return [];
  }
  getSelectRows2(): SelectRow[] {
    if (this.arr?.length) {
      return this.arr.map((item: GenericType) => {
        return { label: item.nome, value: item.uuid.toString() };
      });
    }
    return [];
  }

  filter(callback: (item: GenericType, index: number, array: GenericType[]) => void): GenericType[] {
    return this.arr?.filter(callback) || [];
  }

  map(callback: (item: GenericType, index: number, array: GenericType[]) => any): any {
    return this.arr?.map(callback);
  }
}

export default GenericTypes;
