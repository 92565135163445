import React from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { observer } from 'mobx-react-lite';
import Botao from '../../../components/Botoes/Botao';
import { FormControl } from '@material-ui/core';
import InputNumero from '../../../components/InputNumero';
import useStore from '../../../services/hooks/useStore';

type Props = ModalProps & {
  onConfirm(): void;
  state:any
};
const EditPesoMedio: React.FC<Props> = ({ open, handleClose,onConfirm,state }) => {

  const store = useStore();

  function refreshTable(){
    store.toggleLoader();
    store.notificar('Cancelado !')
    store.toggleLoader();
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Peso Médio (g) para o Tanque</ModalHeader>
      <ModalBody style={{ minWidth: '400px' }}>
        <FormControl component="fieldset">
        <InputNumero
            value={state.select.peso_medio}
            onChange={(value) => (state.select.peso_medio = value)}
            precision="3"
            label="Peso Médio (g)"
          />
        </FormControl>
        <div style={{ width: '100%', display: 'flex' }}>
         <Botao onClick={() => {
            handleClose(),
            refreshTable()
          }} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao
            onClick={() => {   
              onConfirm()
              handleClose()
            }}
            variant="contained"
            cor="#00C853"
          >
            Salvar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(EditPesoMedio);
