import { stateContext } from './../hooks/useStore';
import { getThingsV2 } from '../utils';
import { observable, computed } from 'mobx';
import { Insumo } from '../../@types/Insumo';
import { apiV2 } from '../utils';

class Insumos {
  @observable insumos: Insumo[] | null = null;
  @observable totalRes: number = 0;
  @computed
  get length(): number {
    return this.insumos ? this.insumos.length : 0;
  }

  async populate(mostrarTodos?: boolean): Promise<void> {
    this.insumos = await getThingsV2<Insumo>('/produto/', {
      propriedade: stateContext.state.propriedade!.id,
      mostrar_todos: mostrarTodos ? 'true' : undefined,
    });
  }

  async getEverything(params?: object): Promise<Insumo[]> {
    return await getThingsV2<Insumo>('/produto/', params);
  }

  async getPaginated(page: number, pageSize: number, propriedadeId: number, filterURL?: string): Promise<void> {
    const baseUrl = `/produto/?propriedade=${propriedadeId}`;
    const urlArray = [];
    urlArray.push(baseUrl);

    if (filterURL) {
      urlArray.push(filterURL);
    }

    const requestUrl = urlArray.join('');

    apiV2
      .get(requestUrl, {
        params: {
          page_size: pageSize,
          page: page + 1,
        },
      })
      .then((res: any) => {
        this.totalRes = res.data.count;
        this.insumos = res.data.results;
      });
  }

  get(id: number): Insumo {
    return this.insumos!.filter((item) => item.id === id)[0];
  }

  getSelectRows(): SelectRow[] {
    return this.insumos!.map((item) => {
      return { label: item.nome, value: item.id.toString() };
    });
  }

  // eslint-disable-next-line no-unused-vars
  filter(callback: (item: Insumo, index: number, array: Insumo[]) => void): Insumo[] {
    return this.insumos!.filter(callback);
  }

  // eslint-disable-next-line no-unused-vars
  map(callback: (item: Insumo, index: number, array: Insumo[]) => any): any {
    return this.insumos!.map(callback);
  }
}

export default Insumos;
