import styled from 'styled-components';
import CurrencyInput from 'react-currency-input';

export const Container = styled.div`
  position: relative;
  margin-top: 26px;
  width: 100%;
`;

export const Input = styled(CurrencyInput)`
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  width: ${(props) => (props.half ? '50%' : '100% ')};
  height: 32px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
  padding-left: 1px;

  & + label {
    position: relative;
    top: 10px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    transition: all 0.2s ease-out;
  }

  &:hover {
    outline: none;
    border-width: 2px;
    border-color: black;
    transition: all 0.2s ease-out;
  }

  &:focus {
    outline: none;
    border-width: 2px;
    border-color: ${(props) => props.theme.colors.primary};
    transition: all 0.2s ease-out;

    &:hover {
      border-color: ${(props) => props.theme.colors.primary};
    }
  }

  &:focus + label,
  &.has-value + label {
    top: -50px;
    color: ${(props) => props.theme.colors.primary};
    font-size: 12px;
  }
  &.has-value:not(:focus) + label {
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const DisabledInput = styled(CurrencyInput)`
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  width: 100%;
  height: 32px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
  padding-left: 1px;
  opacity: 0.6;

  & + label {
    position: relative;
    top: 10px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    transition: all 0.2s ease-out;
  }

  &:hover {
    outline: none;
    border-width: 2px;
    border-color: black;
    transition: all 0.2s ease-out;
  }

  &:focus {
    outline: none;
    border-width: 2px;
    border-color: ${(props) => props.theme.colors.primary};
    transition: all 0.2s ease-out;

    &:hover {
      border-color: ${(props) => props.theme.colors.primary};
    }
  }

  &:focus + label,
  &.has-value + label {
    top: -50px;
    color: ${(props) => props.theme.colors.primary};
    font-size: 12px;
  }
  &.has-value:not(:focus) + label {
    color: rgba(0, 0, 0, 0.6);
  }
`;
