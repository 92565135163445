import styled from 'styled-components';
import Botao from '../../../components/Botoes/Botao';

export const Conta = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;

  width: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);

  .right {
    text-align: right;
  }
  .left {
    text-align: left;
  }

  &:hover {
    background: #eeeeee;
  }
`;

type Props = {
  cor?: string;
};
export const SaldoInfo = styled(Botao)<Props>`
  width: 100% !important;
  height: 90px !important;
  position: relative;

  .label {
    position: absolute;
    top: 3px;
    right: 3px;
    background-color: yellow;
    color: black;
    border-radius: 3px;
    padding: 0px 5px;
    font-size: 10px;
  }

  .MuiButton-label {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between;

    span {
      font-size: 12px;
      font-weight: normal;
    }
  }

  margin: 0 !important;

  background-color: ${(props) => props.cor || props.theme.colors.primary} !important;
`;

export const Saldos = styled.div`
  margin-bottom: 10px;
  padding: 0 3px;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const Parcela = styled.div`
  background: white;
`;
