import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { TextField, Grid } from '@material-ui/core';
import Botao from '../../../components/Botoes/Botao';
import { useLocalStore, observer } from 'mobx-react-lite';
import { api, getThings } from '../../../services/utils';
import useStore from '../../../services/hooks/useStore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

type Props = ModalProps & {
  racoesCadastradas?: any;
  nomeCsv: any;
  nomeRacao: any;
  racaoId: any;
};

const FormTiposRacoesEditar: React.FC<Props> = ({ open, handleClose, nomeCsv, nomeRacao, racaoId }) => {
  const [data, setData] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);
  const loading = options.length === 0;
  const store = useStore();
  const state = useLocalStore(() => ({ lista_racao: [{ racao_csv: nomeCsv, racao: nomeRacao }] as any }));

  React.useEffect(() => {
    // let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await getThings('/importe/arracoamento/racao-livre/').then((response: any) => {
        setOptions(response.lista_racao.map((currentValue: any) => currentValue.nome));
        setData(response);
      });
    })();
  }, [loading]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function salvar() {
    store.toggleLoader();
    handleClose();
    const data = state.lista_racao.map((currentValue: any) => {
      return {
        racao_csv: currentValue.racao_csv,
        racao_id: currentValue.racao,
      };
    });

    const dataObj = data[0];

    await api
      .patch(`/importe/arracoamento/racao-cadastrada/${racaoId}/?propriedade=${store.propriedade!.id}`, dataObj)
      .then(async () => {
        store.notificar('Parâmetro(s) editado(s) com sucesso!');
      })
      .catch(() => {
        store.notificar('Ocorreu um erro ao editar o parâmetro!');
      });
    store.toggleLoader();
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Configuração de Rações</ModalHeader>
      <ModalBody>
        <Grid justify="center" container alignItems="center" spacing={2}>
          <Grid item xs={4}>
            <TextField
              label={`Código no CSV`}
              value={state.lista_racao[0].racao_csv}
              onChange={(e) => (state.lista_racao[0].racao_csv = parseInt(e.target.value))}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              id={`asynchronous-demo`}
              style={{ width: 180 }}
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option: any) => option}
              options={options}
              onChange={(e) => {
                const element = e.target as HTMLInputElement;
                const racao = element.innerText;

                const racao_selecionada = data!.lista_racao.find((currentValue: any) => currentValue.nome === racao);

                const racao_id = racao_selecionada.racao_id;

                state.lista_racao[0].racao = racao_id;
              }}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ração"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <div style={{ width: 500, display: 'flex' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao variant="contained" cor="#00C853" onClick={() => salvar()}>
            Salvar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(FormTiposRacoesEditar);
