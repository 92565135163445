import { stateContext } from './../hooks/useStore';
import { getThingsV2 } from '../utils';
import { observable, computed } from 'mobx';
import { apiV2 } from '../utils';
import Formatter from '../Formatter';

class Rateios {
  @observable rateios: Rateio[] | null = null;
  @observable totalRes: number = 0;
  @computed
  get length(): number {
    return this.rateios ? this.rateios.length : 0;
  }

  async populate(mostrarTodos?: boolean): Promise<void> {
    this.rateios = await getThingsV2<Rateio>('/rateio/', {
      propriedade: stateContext.state.propriedade!.id,
      mostrar_todos: mostrarTodos ? 'true' : undefined,
    });
  }

  async getEverything(params?: object): Promise<Rateio[]> {
    return await getThingsV2<Rateio>('/rateio/', params);
  }

  async getPaginated(page: number, pageSize: number, propriedadeId: number, filterURL?: string): Promise<void> {
    const baseUrl = `/rateio/?propriedade=${propriedadeId}`;
    const urlArray = [];
    urlArray.push(baseUrl);

    if (filterURL) {
      urlArray.push(filterURL);
    }

    const requestUrl = urlArray.join('');

    await apiV2
      .get(requestUrl, {
        params: {
          page_size: pageSize,
          page: page + 1,
        },
      })
      .then((res: any) => {
        this.totalRes = res.data.count;
        this.rateios = res.data.results;
      });
  }

  get(id: number): Rateio {
    return this.rateios!.filter((item) => item.id === id)[0];
  }

  getSelectRows(): SelectRow[] {
    if (!this.rateios) return [];
    return this.rateios.map((item) => {
      return {
        label: Formatter.formatBRLCurrency(item.valor),
        value: item.id.toString(),
      };
    });
  }

  // eslint-disable-next-line no-unused-vars
  filter(callback: (item: Rateio, index: number, array: Rateio[]) => void): Rateio[] {
    if (!this.rateios) return [];
    return this.rateios.filter(callback);
  }

  // eslint-disable-next-line no-unused-vars
  map(callback: (item: Rateio, index: number, array: Rateio[]) => any): any {
    if (!this.rateios) return [];
    return this.rateios.map(callback);
  }
}

export default Rateios;
