/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import useStore from '../../../services/hooks/useStore';
import Botao from '../../../components/Botoes/Botao';
import { TanqueInfo } from '../../../components/TanqueInfo';
import { observer, useLocalStore } from 'mobx-react-lite';
import Formatter from '../../../services/Formatter';
import { apiV2 } from '../../../services/utils';
import { TextField, Tooltip } from '@material-ui/core';

type Props = ModalProps & {
  onItemClick(tanqueID: number, loteTanqueId?: number): void;
  selectedTanques: SelectedTanque[];
  loteID: number;
};
const SelecionarTanque: React.FC<Props> = ({ open, handleClose, onItemClick, selectedTanques, loteID }) => {
  const state = useLocalStore(() => ({
    sync: false,
  }));
  const store = useStore();
  const [biomassa, setBiomassa] = useState(0);
  const [codigo, setCodigo] = useState<string>('');
  const lote = store.lotes.get(loteID);
  const [tanques, setTanques] = useState<Tanque[]>([] as Tanque[]);

  useEffect(() => {
    let biomassa = 0;

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function searchTanque() {
      store.toggleLoader();
      await apiV2
        .get('/tanque/modals/', {
          params: {
            propriedade: store.propriedade!.id,
            lote: loteID ? loteID : undefined,
            nome: codigo,
          },
        })
        .then((res) => {
          setTanques(res.data.results);
          // eslint-disable-next-line
          res.data.results.map((tanque: any) => {
    
            biomassa = biomassa + tanque.biomassa;
          });
          setBiomassa(biomassa);
        });

      state.sync = true;
      store.toggleLoader();
    }

    searchTanque();

    // eslint-disable-next-line
  }, [selectedTanques, codigo, setCodigo]);

  const TanquesDisponiveis: React.FC = () => (
    <>
      {tanques?.map((tanque) => {
        const selected = selectedTanques.some((obj) => tanque.id === obj.item.id);
        if (tanque.estoque) {
          return (
            <TanqueInfo
              selected={selected || undefined}
              onClick={() => {
                if (!selected) onItemClick(tanque.id);
              }}
              key={tanque.id}
            >
              <p className="left">{tanque.nome || tanque.codigo}</p>
              <p className="right">
                {tanque.lotes.length > 0 ? (
                  <Tooltip
                    title={tanque.lotes.map((lote: any) => {
                      return `${lote.codigo || lote.nome}, `;
                    })}
                  >
                    <span>{tanque.lotes[0].codigo + '...'}</span>
                  </Tooltip>
                ) : (
                  ''
                )}{' '}
                ({Formatter.formatNumber(0, tanque.estoque)})
              </p>
              <b className="left" style={{ color: '#00C853' }}>
                {Formatter.formatNumber(3, tanque.biomassa)} Kg
              </b>
              <p className="right" style={{ color: '#00C853' }}>
                {tanque.situacao_tanque.nome}
              </p>
            </TanqueInfo>
          );
        } else {
          return null;
        }
      })}
    </>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>Selecione um Tanque</ModalHeader>
      <ModalBody>
        <div
          style={{
            color: '#707070',
            width: '100%',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <b>{lote.codigo}</b>
          <b>Biomassa: {Formatter.formatNumber(3, biomassa)} Kg</b>
        </div>
        <div
          style={{ color: '#707070', width: '100%', padding: '10px', display: 'flex', justifyContent: 'space-between' }}
        >
          <TextField
            label="Pesquisa Pelo Nome"
            value={codigo}
            onChange={(e) => {
              setCodigo(e.target.value);
            }}
          />
        </div>
        <TanquesDisponiveis />
      </ModalBody>
      <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
        <Botao cor="#00C853" onClick={handleClose}>
          Confirmar
        </Botao>
      </div>
    </Modal>
  );
};

export default observer(SelecionarTanque);
