import React, { useState, useRef } from 'react';
import { Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Tooltip, SvgIconTypeMap } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TimelineIcon from '@material-ui/icons/Timeline';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { FlatButton } from './styles';
import { Done } from '@material-ui/icons';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SecurityIcon from '@material-ui/icons/Security';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

type Props = {
  tooltip?: string;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  editar?(): void;
  editarCompra?(): void;
  visualizar?(): void;
  copiar?(): void;
  ajuste_estoque?(): void;
  mudarFase?(): void;
  finalizar?(): void;
  excluir?(): void;
  excluirCompra?(): void;
  historico?(): void;
  relatorio?(): void;
  recibo?(): void;
  voltou?(): void;
  verTanques?(): void;
  onClick?(): void;
  permissoes?(): void;
  parcelas?(): void;
};
const ActionButton: React.FC<Props> = ({
  editar,
  editarCompra,
  ajuste_estoque,
  copiar,
  visualizar,
  mudarFase,
  finalizar,
  excluir,
  excluirCompra,
  historico,
  relatorio,
  recibo,
  verTanques,
  voltou,
  onClick,
  Icon,
  tooltip,
  permissoes,
  parcelas,
}) => {
  const anchorRef = useRef(null);
  const [state, setState] = useState(false);

  function handleClose(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
    const value = event.currentTarget.id;

    if (value === 'Ajuste Estoque' && ajuste_estoque) {
      ajuste_estoque();
    }
    if (value === 'editar' && editar) {
      editar();
    }
    if (value === 'editarCompra' && editarCompra) {
      editarCompra();
    }
    if (value === 'copiar' && copiar) {
      copiar();
    }
    if (value === 'visualizar' && visualizar) {
      visualizar();
    }
    if (value === 'mudarFase' && mudarFase) {
      mudarFase();
    }
    if (value === 'finalizar' && finalizar) {
      finalizar();
    }
    if (value === 'excluir' && excluir) {
      excluir();
    }
    if (value === 'excluirCompra' && excluirCompra) {
      excluirCompra();
    }
    if (value === 'voltou' && voltou) {
      voltou();
    }
    if (value === 'historico' && historico) {
      historico();
    }
    if (value === 'relatorio' && relatorio) {
      relatorio();
    }
    if (value === 'recibo' && recibo) {
      recibo();
    }
    if (value === 'verTanques' && verTanques) {
      verTanques();
    }
    if (value === 'permissoes' && permissoes) {
      permissoes();
    }
    if (value === 'parcelas' && parcelas) {
      parcelas();
    }
    setState(false);
  }

  return (
    <>
      <Tooltip title={tooltip || 'Mais Ações'}>
        <FlatButton
          ref={anchorRef}
          onClick={(): void => {
            onClick && onClick();
            setState(!state);
          }}
          color="inherit"
          edge="start"
        >
          {Icon ? <Icon /> : <AddIcon />}
        </FlatButton>
      </Tooltip>
      <Popper
        style={{ zIndex: 10 }}
        open={state}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center bottom' }}>
            <Paper style={{ boxShadow: '0px 1px 20px 0px rgba(0,0,0,0.25)' }}>
              <ClickAwayListener onClickAway={() => setState(false)}>
                <MenuList autoFocusItem={state} id="menu-list-grow">
                  {ajuste_estoque && (
                    <MenuItem id="mudarFase" onClick={ajuste_estoque}>
                      <VisibilityIcon />
                      Ajuste Estoque
                    </MenuItem>
                  )}
                  {editar && (
                    <MenuItem id="editar" onClick={handleClose}>
                      <EditIcon />
                      Editar
                    </MenuItem>
                  )}
                  {editarCompra && (
                    <MenuItem id="editarCompra" onClick={handleClose}>
                      <EditIcon />
                      Editar Compra
                    </MenuItem>
                  )}
                  {voltou && (
                    <MenuItem id="voltou" onClick={handleClose}>
                      <EditIcon />
                      Voltou
                    </MenuItem>
                  )}
                  {visualizar && (
                    <MenuItem id="visualizar" onClick={handleClose}>
                      <VisibilityIcon />
                      Visualizar
                    </MenuItem>
                  )}
                  {copiar && (
                    <MenuItem id="copiar" onClick={handleClose}>
                      <FileCopyIcon />
                      Copiar
                    </MenuItem>
                  )}
                  {mudarFase && (
                    <MenuItem id="mudarFase" onClick={handleClose}>
                      <EditIcon />
                      Mudar Fase
                    </MenuItem>
                  )}
                  {finalizar && (
                    <MenuItem id="finalizar" onClick={handleClose}>
                      <Done />
                      Finalizar Lote
                    </MenuItem>
                  )}
                  {relatorio && (
                    <MenuItem id="relatorio" onClick={handleClose}>
                      <AssessmentIcon />
                      Relatório
                    </MenuItem>
                  )}
                  {recibo && (
                    <MenuItem id="recibo" onClick={handleClose}>
                      <AssignmentIcon />
                      Recibo
                    </MenuItem>
                  )}
                  {verTanques && (
                    <MenuItem id="verTanques" onClick={handleClose}>
                      <VisibilityIcon />
                      Ver Tanques
                    </MenuItem>
                  )}
                  {historico && (
                    <MenuItem id="historico" onClick={handleClose}>
                      <TimelineIcon />
                      Histórico
                    </MenuItem>
                  )}
                  {permissoes && (
                    <MenuItem id="permissoes" onClick={handleClose}>
                      <SecurityIcon />
                      Permissões
                    </MenuItem>
                  )}
                  {parcelas && (
                    <MenuItem id="parcelas" onClick={handleClose}>
                      <MonetizationOnIcon />
                      Parcelas
                    </MenuItem>
                  )}
                  {excluir && (
                    <MenuItem id="excluir" onClick={handleClose}>
                      <DeleteIcon color="error" />
                      Excluir
                    </MenuItem>
                  )}
                  {excluirCompra && (
                    <MenuItem id="excluirCompra" onClick={handleClose}>
                      <DeleteIcon color="error" />
                      Excluir Compra
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ActionButton;
