import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../components/Modais/styles';
import Botao from '../../components/Botoes/Botao';
import useStore from '../../services/hooks/useStore';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useLocalStore, observer } from 'mobx-react-lite';
import Select from 'react-select';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { Parcela } from '../../pages/Vendas/MinhasVendas/styles';
import InputNumero from '../InputNumero';
import Formatter from '../../services/Formatter';
import NewSelect from '../Select/newSelect';

type Props = ModalProps & {
  onConfirm(movimentacao: any): void;
  categoria?: boolean;
  data?: MaterialUiPickersDate;
  editar?: boolean;
  valorTotal: number;
  origem?: string;
};

const RegistrarDespesa: React.FC<Props> = ({
  open,
  handleClose,
  onConfirm,
  data,
  categoria,
  editar,
  valorTotal,
  origem,
}) => {
  const store = useStore();
  const state = useLocalStore(() => ({
    conta: store.contas.getSelectRows()[0],
    categoria: origem === 'insumos' ? { label: 'Insumos', value: '25' } : (null as SelectRow | null),
    data: data ? data : (new Date() as MaterialUiPickersDate),
    documento: '',
    qtdParcelas: 1,
    parcelas: [
      {
        data: new Date(),
        pago: false,
        conta: store.contas.getSelectRows()[0],
        tipo: store.tiposDePagamento.getSelectRows()?.filter((tipo) => tipo.value === '1')[0],
        valor: valorTotal,
      },
    ],
    pagamento: store.tiposDePagamento.getSelectRows()?.filter((tipo) => tipo.value === '1')[0],

    setPagamento(tipo: SelectRow) {
      this.pagamento = tipo;
      this.parcelas.map((parcela) => {
        return (parcela.tipo = tipo);
      }); 
    },
    setConta(tipo: SelectRow) {
      this.conta = tipo;
      this.parcelas.map((parcela) => {
        return (parcela.conta = tipo);
      });
    },
    setQtdParcelas(value: number) {
      this.qtdParcelas = value;
      this.parcelas = [];

      for (let parcela = 0; parcela < this.qtdParcelas; parcela++) {
        const data: MaterialUiPickersDate = new Date(this.data!);
        data.setMonth(data.getMonth() + parcela);
        this.parcelas.push({
          data,
          pago: false,
          conta: this.conta,
          tipo: this.pagamento,
          valor: valorTotal / this.qtdParcelas,
        });
      }
    },
    somaParcelas(): number {
      let valor = 0;
      this.parcelas.map((parcela) => {
        return (valor += parcela.valor as number);
      });
      return valor;
    },
  }));

  useEffect(() => {
    state.setQtdParcelas(state.qtdParcelas);
  }, [state.qtdParcelas]);

  useEffect(() => {
    if (!state.conta) {
      store.contas.populate();
    }
    if (!state.parcelas[0].tipo) {
      store.tiposDePagamento.populate();
    }
  }, [state]);

  async function registrarDespesa(): Promise<void> {
    if (state.qtdParcelas && state.parcelas.some((parcela) => !parcela.valor)) {
      store.notificar('O valor de uma parcela não pode ser 0 ou nulo');
    } else if (state.qtdParcelas && Number(state.somaParcelas().toFixed(2)) !== valorTotal) {
      store.notificar('A soma das parcelas deve coincidir com o valor total da venda');
    } else {
      const parcelas = state.parcelas.map((parcela, index) => {
        return {
          num_parcela: index + 1,
          data_pagamento: Formatter.dateToString(parcela.data),
          recebido: parcela.pago,
          conta: parseInt(parcela.conta?.value),
          pagamento_tipo: parseInt(parcela.tipo?.value),
          valor: Number(parcela.valor).toFixed(2),
        };
      });

      const pago = parcelas.every((parcela) => parcela.recebido);

      handleClose();

      onConfirm({
        conta: parseInt(state.conta?.value),
        data: Formatter.dateToString(state.data),
        categoria: state.categoria ? parseInt(state.categoria?.value) : null,
        parcelas: parcelas,
        documento: state.documento,
        pago,
      });
    }
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>{editar ? 'Edição' : 'Registro'} de despesa</ModalHeader>
      <ModalBody style={{ width: '950px' }}>
        <div style={{ width: '100%' }}>
          {store.contas.arr && (
            <Select
              value={state.conta}
              classNamePrefix="sel"
              placeholder="Conta a ser utilizada"
              onChange={(e: any) => state.setConta(e)}
              options={store.contas.getSelectRows()}
            />
          )}
        </div>

        <div style={{ width: '100%', marginTop: 10 }}>
          {store.categorias.arr && categoria && (
            <Select
              value={state.categoria}
              classNamePrefix="sel"
              placeholder="Selecione a categoria"
              onChange={(e: any) => {
                state.categoria = e;
              }}
              options={
                origem === 'insumos'
                  ? [{ label: 'insumos', value: '25' }]
                  : store.categorias
                      .getByTipo('despesas')
                      ?.filter(
                        (currentValue) => currentValue.id === 26 || currentValue.id === 7 || currentValue.id === 23,
                      )
                      .map((cat) => {
                        return { label: cat.nome, value: cat.id.toString() };
                      })
              }
            />
          )}
        </div>

        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data"
          value={state.data}
          onChange={(date) => {
            state.data = date;
            state.setQtdParcelas(state.qtdParcelas);
          }}
        />

        <TextField 
        onChange={(e) => (state.documento = e.target.value)} 
        label="Documento(Max: 20 Caracteres)" 
        inputProps={{ maxLength: 20 }}
        />

        {store.tiposDePagamento.arr && (
          <div style={{ width: '100%' }}>
            <Select
              styles={{
                control: (styles) => ({ ...styles, marginTop: 10 }),
              }}
              onChange={(e: any) => state.setPagamento(e)}
              placeholder="Forma de pagamento"
              classNamePrefix="sel"
              defaultValue={state.pagamento}
              options={store.tiposDePagamento.getSelectRows()}
            />
          </div>
        )}

        <TextField
          value={state.qtdParcelas}
          onChange={(e) => state.setQtdParcelas(parseInt(e.target.value))}
          type="number"
          label="Quantidade de parcelas"
          inputProps={{ min: 1 }}
          onBlur={() => !state.qtdParcelas && state.setQtdParcelas(1)}
        />
        {state.parcelas.map((parcela, index) => (
          <Parcela key={index}>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Data"
              value={parcela.data}
              onChange={(date) => (state.parcelas[index].data = date!)}
            />

            <InputNumero
              value={typeof parcela.valor === 'string' ? parcela.valor : parcela.valor}
              onChange={(value) => (state.parcelas[index].valor = value)}
              precision="2"
              label="Valor"
              id={'parcela-' + index}
            />

            <div
              style={{
                width: '100%',
                marginTop: 18,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {store.contas.arr && (
                <NewSelect
                  value={parcela.conta}
                  onChange={(e: any) => {
                    state.parcelas[index].conta = e;
                  }}
                  placeholder="Conta Banco"
                  options={store.contas.getSelectRows()}
                />
              )}
              {store.tiposDePagamento.arr && (
                <NewSelect
                  value={parcela.tipo}
                  onChange={(e: any) => {
                    state.parcelas[index].tipo = e;
                  }}
                  placeholder="Forma de pagamento"
                  options={store.tiposDePagamento.getSelectRows()}
                  menuPlacement={index + 1 === state.parcelas.length ? 'top' : 'bottom'}
                />
              )}
            </div>
            <FormControlLabel
              labelPlacement="top"
              control={
                <Checkbox
                  checked={parcela.pago}
                  onChange={(e) => (state.parcelas[index].pago = e.target.checked)}
                  color="primary"
                />
              }
              label="Pago"
            />
          </Parcela>
        ))}

        <div style={{ width: '100%', display: 'flex' }}>
          <Botao cor="gray" onClick={handleClose}>
            Voltar
          </Botao>
          <Botao
            cor="#00C853"
            onClick={() => {
              if (categoria) {
                if (state.categoria) {
                  registrarDespesa();
                } else store.notificar('Selecione uma categoria');
              } else {
                registrarDespesa();
              }
            }}
          >
            Salvar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(RegistrarDespesa);
