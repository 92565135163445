import { stateContext } from '../services/hooks/useStore';
import { api, apiV2, aqRelatorios, aqReports, newApi } from '../services/utils';

/**
 * Função que checa se o token salvo no `localStorage` é válido.
 */
export default async function checkUser(): Promise<void | null> {
  const store = stateContext.state;

  if (window.location.pathname !== '/') {
    try {
      // @ts-ignore
      api.defaults.headers.common['Authorization'] = store.token as string;
      apiV2.defaults.headers.common['Authorization'] = store.token as string;
      newApi.defaults.headers.common['Authorization'] = store.token as string;
      aqRelatorios.defaults.headers.common['Authorization'] = store.token as string;
      aqReports.defaults.headers.common['Authorization'] = store.token as string;
      newApi.defaults.headers.common['Authorization'] = store.token as string;

      const request = await api.get('/auth/me/');
      const userData: User = request.data;

      if (userData.plano.id === 4) {
        const propriedade = localStorage.getItem('propriedade');

        store.setLogado(userData);

        if (propriedade) {
          if (store.usuario.propriedades.filter((prop) => prop.ativo && prop.id === parseInt(propriedade)).length > 0) {
            await store.setPropriedade(parseInt(propriedade));
          } else {
            await store.setPropriedade(store.usuario.propriedades.filter((propriedade) => propriedade.ativo)[0].id);
            localStorage.removeItem('propriedade');
          }
        } else {
          await store.setPropriedade(store.usuario.propriedades.filter((propriedade) => propriedade.ativo)[0].id);
          localStorage.removeItem('propriedade');
        }
      } else {
        alert('Entre em contato conosco pelo whatsapp para liberar seu plano!');
      }
    } catch (error) {
      window.location.href = '/';
    }
  } else return null;

  store.toggleLoader();
}
