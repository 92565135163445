import React from 'react';
import { Modal, ModalHeader, ModalBody } from './styles';
import Botao from '../Botoes/Botao';

type Props = ModalProps & {
  onConfirm(): void;
  onCancel(): void;
  title: string;
  content?: React.ReactNode;
};

const Confirmar: React.FC<Props> = ({ open, handleClose, onConfirm, onCancel, title, content }) => {
  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        {content}
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 15, width: '100%' }}>
          <Botao onClick={onCancel} variant="contained" cor="#FC7467">
            Não
          </Botao>
          <Botao onClick={onConfirm} variant="contained" cor="#00C853">
            Sim
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default Confirmar;
