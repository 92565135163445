import React from 'react';
import { Localization } from 'material-table';

type TabelaConfig = {
  options: any;
  style: React.CSSProperties;
  localization: Localization;
};
export const config: TabelaConfig = {
  options: {
    toolbar: false,
    maxBodyHeight: window.innerHeight - 175,
    headerStyle: {
      background: '#42a5f5',
      color: 'white',
      height: '40px',
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: 14,
    },
    pageSizeOptions: [],
    tableLayout: 'fixed',
    paginationType: 'stepped',
  },
  style: {
    boxShadow: '0 0 13px 0 #00000036',
    borderRadius: '10px',
    width: '100%',
    fontSize: 12,
  },
  localization: {
    body: {
      emptyDataSourceMessage: 'Ainda não existem registros cadastrados!',
    },
    toolbar: {
      exportAriaLabel: 'Exportar como CSV',
    },
    pagination: {
      firstTooltip: 'Primeira',
      previousTooltip: 'Anterior',
      nextTooltip: 'Próxima',
      lastTooltip: 'Última',
    },
    header: {
      actions: 'Ações',
    },
  },
};

type NewTabelaConfig = {
  options: any;
  style: React.CSSProperties;
  localization: any;
};
export const newConfig: NewTabelaConfig = {
  options: {
    toolbar: false,
    maxBodyHeight: window.innerHeight - 200,
    minBodyHeight: window.innerHeight - 200,
    headerStyle: {
      color: '#828282',
      height: '40px',
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: 12,
    },
    pageSizeOptions: [],
    tableLayout: 'fixed',
    paginationType: 'stepped',
  },
  style: {
    boxShadow: 'none',
    borderColor: 'white',
    fontSize: 14,
  },
  localization: {
    body: {
      emptyDataSourceMessage: 'Ainda não existem registros cadastrados!',
    },
    toolbar: {
      exportCSVName: 'Baixar como CSV',
      exportPDFName: 'Baixar como PDF',
      exportTitle: 'Baixar',
      searchTooltip: 'Buscar',
      searchPlaceholder: 'Buscar',
    },
    pagination: {
      firstTooltip: 'Primeira',
      previousTooltip: 'Anterior',
      nextTooltip: 'Próxima',
      lastTooltip: 'Última',
    },
    header: {
      actions: 'Ações',
    },
  },
};

export const newConfigRacoes: NewTabelaConfig = {
  options: {
    toolbar: false,
    maxBodyHeight: window.innerHeight - 200,
    minBodyHeight: window.innerHeight - 200,
    headerStyle: {
      color: '#828282',
      height: '40px',
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: 12,
    },
    pageSizeOptions: [],
    pageSize: 50,
    tableLayout: 'fixed',
    paginationType: 'stepped',
  },
  style: {
    boxShadow: 'none',
    borderColor: 'white',
    fontSize: 14,
  },
  localization: {
    body: {
      emptyDataSourceMessage: 'Escolha as opções de filtro ao lado!',
    },
    toolbar: {
      exportCSVName: 'Baixar como CSV',
      exportPDFName: 'Baixar como PDF',
      exportTitle: 'Baixar',
      searchTooltip: 'Buscar',
      searchPlaceholder: 'Buscar',
    },
    pagination: {
      firstTooltip: 'Primeira',
      previousTooltip: 'Anterior',
      nextTooltip: 'Próxima',
      lastTooltip: 'Última',
    },
    header: {
      actions: 'Ações',
    },
  },
};
export const newConfigVendas: NewTabelaConfig = {
  options: {
    toolbar: false,
    maxBodyHeight: window.innerHeight - 350,
    minBodyHeight: window.innerHeight - 250,
    headerStyle: {
      color: '#828282',
      height: '40px',
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: 12,
    },
    pageSizeOptions: [],
    pageSize: 50,
    tableLayout: 'fixed',
    paginationType: 'stepped',
  },
  style: {
    boxShadow: 'none',
    borderColor: 'white',
    fontSize: 14,
  },
  localization: {
    body: {
      emptyDataSourceMessage: 'Selecione um lote ou um lote e tanque para gerar o relatório!',
    },
    toolbar: {
      exportCSVName: 'Baixar como CSV',
      exportPDFName: 'Baixar como PDF',
      exportTitle: 'Baixar',
      searchTooltip: 'Buscar',
      searchPlaceholder: 'Buscar',
    },
    pagination: {
      firstTooltip: 'Primeira',
      previousTooltip: 'Anterior',
      nextTooltip: 'Próxima',
      lastTooltip: 'Última',
    },
    header: {
      actions: 'Ações',
    },
  },
};
export const newConfigFornecedor: NewTabelaConfig = {
  options: {
    toolbar: false,
    maxBodyHeight: window.innerHeight - 350,
    minBodyHeight: window.innerHeight - 350,
    headerStyle: {
      color: '#828282',
      height: '40px',
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: 12,
    },
    pageSizeOptions: [],
    pageSize: 50,
    tableLayout: 'fixed',
    paginationType: 'stepped',
  },
  style: {
    boxShadow: 'none',
    borderColor: 'white',
    fontSize: 14,
  },
  localization: {
    body: {
      emptyDataSourceMessage: 'Escolha as opções de filtro ao lado!',
    },
    toolbar: {
      exportCSVName: 'Baixar como CSV',
      exportPDFName: 'Baixar como PDF',
      exportTitle: 'Baixar',
      searchTooltip: 'Buscar',
      searchPlaceholder: 'Buscar',
    },
    pagination: {
      firstTooltip: 'Primeira',
      previousTooltip: 'Anterior',
      nextTooltip: 'Próxima',
      lastTooltip: 'Última',
    },
    header: {
      actions: 'Ações',
    },
  },
};
