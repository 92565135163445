import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import Botao from '../../../components/Botoes/Botao';
import InputNumero from '../../../components/InputNumero';
import { useLocalStore, observer } from 'mobx-react-lite';
import useStore from '../../../services/hooks/useStore';
import { returnQuantidade } from '../../../services/utils/producao';
import Formatter from '../../../services/Formatter';

type Props = ModalProps & {
  onConfirm(tanqueData: TanqueData): void;
  loteTanque: any;
  diasDiferenca: number | undefined;
  quantidadeAnimais: number;
  states: any;
  periodo: boolean;
};
const QuantidadeModal: React.FC<Props> = ({
  open,
  handleClose,
  onConfirm,
  loteTanque,
  diasDiferenca,
  quantidadeAnimais,
  states,
  periodo,
}) => {
  const options = [
    { label: 'Quilograma', value: '0' },
    { label: '%Biomassa', value: '1' },
    { label: 'Kg por animal', value: '2' },
  ];
  const store = useStore();
  const state = useLocalStore(() => ({
    select: options[0],
    pct_biomassa: 0,
    pct_temperatura: 0,
    racao: loteTanque && loteTanque.qtd_racao ? loteTanque.qtd_racao : 0,
    kgPorAnimal: 0,
    pct_sobrevivencia: 100,
    totalRacao: loteTanque && loteTanque.qtd_trato ? loteTanque.qtd_trato : 0,
    racaoTrato: loteTanque && loteTanque.qtd_kg_trato ? loteTanque.qtd_kg_trato : 0,
  }));

  function confirmar(): void {
    if (state.racao === 0) store.notificar('Informe o peso da ração');
    else {
      const qtd_racao = parseFloat(state.racao.toFixed(3));
      const tipo = (): TanqueData['tipo'] => {
        if (state.select.value === '0' || state.select.value === '3') {
          return 'racao_por_animal';
        } else if (state.select.value === '1') {
          return 'biomassa';
        } else {
          return 'programa';
        }
      };
      if (state.select.value === '2') {
        const tanqueData = {
          tanque: 0,
          lote_tanque: 0,
          percentual_biomassa: state.pct_biomassa,
          percentual_temperatura: state.pct_temperatura,
          tipo: tipo(),
          qtd_racao: parseFloat(state.totalRacao.toFixed(3)),
          peso: 0,
          qtd_kg_trato: state.racaoTrato,
          qtd_trato: states.quantidade,
        };
        onConfirm(tanqueData);
        handleClose();
      } else {
        const tanqueData = {
          qtd_kg_trato: parseFloat(state.racaoTrato.toFixed(3)),
          qtd_trato: states.quantidade,
          tanque: 0,
          lote_tanque: 0,
          percentual_biomassa: state.pct_biomassa,
          percentual_temperatura: state.pct_temperatura,
          tipo: tipo(),
          qtd_racao,
          peso: 0,
        };
        onConfirm(tanqueData);
        handleClose();
      }
    }
  }

  function CalcularKg(e: number) {
    state.racaoTrato = e / states.quantidade;
  }
  function CalcularQuantidade(e: number) {
    state.racao = state.racaoTrato * e;
  }
  function CalcularQuantidadeCreate(e: number) {
    state.racaoTrato = state.racao / e;
  }

  function Calcular(): number {
    state.racao =
      (((quantidadeAnimais * state.pct_sobrevivencia) / 100) * state.kgPorAnimal * state.pct_temperatura) / 100;
    return (state.totalRacao = state.racao * diasDiferenca!);
  }

  useEffect(() => {
    if (state.pct_biomassa || state.pct_temperatura) {
      const lote = store.lotes.get(loteTanque?.lote.id);
      const biomassaLT = ((lote.peso_medio_atual || lote.peso_medio) * returnQuantidade(loteTanque, false)) / 1000;
      state.racao = biomassaLT * (state.pct_biomassa / 100);
      state.racao = state.racao * (state.pct_temperatura / 100);
    }
    // eslint-disable-next-line
  }, [state.pct_biomassa, state.pct_temperatura]);

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>Informe a quantidade</ModalHeader>
      <ModalBody>
        <div style={{ width: 400 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                margin: '.5rem 0 ',
                fontSize: '18px',
              }}
            >
              <p>
                <strong>{loteTanque.tanque.nome}</strong>
              </p>
              <p>
                PM: <strong style={{ color: '#059DE7' }}>{Formatter.formatNumber(3, loteTanque.peso_medio)}g</strong>
              </p>
            </span>
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                margin: '.5rem 0 ',
                fontSize: '18px',
              }}
            >
              <p>
                Quantidade: <strong style={{ color: '#059DE7' }}>{loteTanque.estoque}un</strong>
              </p>
              <p>
                Biomassa:{' '}
                <strong style={{ color: '#059DE7' }}>{Formatter.formatNumber(2, loteTanque.biomassa / 1000)}kg</strong>
              </p>
              <p>
                Total dias: <strong style={{ color: '#059DE7' }}>{diasDiferenca}</strong>
              </p>
            </span>
          </div>
          {state.select.value === '0' ? (
            <>
              <InputNumero
                value={state.racao}
                onChange={(value) => {
                  state.racao = value;
                  CalcularKg(value);
                }}
                precision="3"
                label={periodo ? 'Ração por tanque no período(Kg)' : 'Ração por Tanque (kg)'}
              />
              <InputNumero
                value={states.quantidade}
                onChange={(val) => {
                  states.quantidade = val;

                  if (loteTanque && loteTanque.qtd_racao) {
                    CalcularQuantidade(val);
                  } else {
                    CalcularQuantidadeCreate(val);
                  }
                }}
                label="Quantidade de Tratos"
                precision="0"
              />
              <InputNumero
                value={state.racaoTrato}
                onChange={(val) => (states.quantidade = val)}
                label="Ração Kg/Tratos"
                precision="3"
                disabled
              />
            </>
          ) : null}
        </div>

        {state.select.value === '1' ? (
          <>
            <InputNumero
              value={state.pct_biomassa}
              onChange={(value) => (state.pct_biomassa = value)}
              precision="2"
              label="Percentual sobre biomassa (%)"
            />
            <InputNumero
              value={state.pct_temperatura}
              onChange={(value) => (state.pct_temperatura = value)}
              precision="2"
              label="Percentual sobre temperatura (%)"
            />

            <InputNumero
              value={state.racao}
              onChange={(value) => (state.racao = value)}
              precision="2"
              label={periodo ? 'Ração por tanque no período(Kg)' : 'Ração por Tanque (kg)'}
            />
          </>
        ) : null}

        {state.select.value === '2' ? (
          <>
            <InputNumero
              value={state.kgPorAnimal}
              onChange={(value) => (state.kgPorAnimal = value)}
              precision="3"
              label="Kg por animal (kg)"
            />
            <InputNumero
              value={state.pct_temperatura}
              onChange={(value) => (state.pct_temperatura = value)}
              precision="0"
              label="% Temperatura"
            />
            <InputNumero
              value={state.pct_sobrevivencia}
              onChange={(value) => (state.pct_sobrevivencia = value)}
              precision="0"
              label="% Sobrevivência"
            />

            <Botao onClick={Calcular} fullWidth>
              Calcular
            </Botao>

            <InputNumero
              value={state.racao}
              onChange={(value) => (state.racao = value)}
              precision="3"
              label="Ração por tanque dia (kg)"
              disabled
            />

            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
              <div style={{ margin: 5, textAlign: 'center' }}>
                <h4>Total de dias</h4>
                <h1 style={{ color: '#42a5f5' }}>{Formatter.formatNumber(0, diasDiferenca)}</h1>
              </div>
              <div style={{ margin: 5, textAlign: 'center' }}>
                <h4>Total de ração no período (kg)</h4>
                <h1 style={{ color: '#42a5f5' }}>{Formatter.formatNumber(3, state.totalRacao)}</h1>
              </div>
            </div>
          </>
        ) : null}
        <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
          <Botao cor="gray" onClick={handleClose}>
            Voltar
          </Botao>
          <Botao cor="#00C853" onClick={confirmar}>
            Confirmar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(QuantidadeModal);
