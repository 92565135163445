import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { TextField, Grid } from '@material-ui/core';
import Botao from '../../../components/Botoes/Botao';
import { useLocalStore, observer } from 'mobx-react-lite';
import { api, getThings } from '../../../services/utils';
import useStore from '../../../services/hooks/useStore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

type Props = ModalProps & {
  tanquesCadastrados?: any;
};

const FormTanquesAdicionar: React.FC<Props> = ({ open, handleClose }) => {
  const [data, setData] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);
  const loading = options.length === 0;
  const store = useStore();
  const state = useLocalStore(() => ({ lista_tanque: [{ tanque_csv: '', tanque: '', lote_tanque: 0 }] as any }));

  React.useEffect(() => {
    // let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await getThings('/importe/arracoamento/tanque-livre/').then((response: any) => {
        setOptions(
          response.lista_lote_tanque.map(
            (currentValue: any) => currentValue.tanque_nome + ' / ' + currentValue.lote_nome,
          ),
        );
        setData(response);
      });
    })();
  }, [loading]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function salvar() {
    store.toggleLoader();
    handleClose();
    const data = state.lista_tanque.map((currentValue: any) => {
      return {
        tanque_csv: currentValue.tanque_csv,
        tanque: currentValue.tanque,
        lote_tanque: currentValue.lote_tanque,
      };
    });

    const dataObj = { lista_tanque: data };

    await api
      .post(`/importe/arracoamento/csv-tanque/?propriedade=${store.propriedade!.id}`, dataObj)
      .then(async () => {
        store.notificar('Parâmetro(s) cadastrado(s) com sucesso!');
      })
      .catch(() => {
        store.notificar('Ocorreu um erro ao cadastrar os parâmetros!');
      });
    store.toggleLoader();
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Configuração de Tanques/Viveiros</ModalHeader>
      <ModalBody>
        <Grid justify="center" container alignItems="center" spacing={2}>
          <Grid item xs={4}>
            <TextField
              label={`Código no CSV`}
              value={state.lista_tanque[0].tanque_csv}
              onChange={(e) => (state.lista_tanque[0].tanque_csv = parseInt(e.target.value))}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              id={`asynchronous-demo`}
              style={{ width: 180 }}
              getOptionSelected={(option, value) => option === value}
              getOptionLabel={(option: any) => option}
              options={options}
              onChange={(e) => {
                const element = e.target as HTMLInputElement;
                const tanque = element.innerText.split('/', 1).join(' ').trim();
                const lote = element.innerText.split('/', 2).pop()?.trim();

                const lote_tanque_selecionado = data!.lista_lote_tanque.find(
                  (currentValue: any) => currentValue.tanque_nome === tanque && currentValue.lote_nome === lote,
                );

                const lote_tanque_id = lote_tanque_selecionado.lote_tanque_id;
                const tanque_id = lote_tanque_selecionado.tanque_id;

                state.lista_tanque[0].tanque = tanque_id;
                state.lista_tanque[0].lote_tanque = lote_tanque_id;
              }}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tanque"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <div style={{ width: 500, display: 'flex' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao variant="contained" cor="#00C853" onClick={() => salvar()}>
            Salvar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(FormTanquesAdicionar);
