import React from 'react';
import { Container, Input, DisabledInput } from './styles';
type Props = {
  value: number;
  onChange?(value: number): void;
  precision: string;
  label: string;
  disabled?: boolean;
  id?: string;
  inputType?: string;
  allowNegative?: boolean;
  className?: string;
  half?: boolean;
};
const InputNumero: React.FC<Props> = ({
  value,
  onChange,
  precision,
  label,
  disabled,
  id,
  half,
  inputType,
  allowNegative,
  className,
}) => {
  function changeValue(e: React.ChangeEvent<HTMLInputElement>): void {
    const number = parseFloat(e.target.value.split('.').join('').replace(',', '.'));
    if (onChange) onChange(number);
  }

  return (
    <Container>
      {disabled ? (
        <DisabledInput
          disabled={disabled}
          className={className + ' has-value'}
          value={value}
          onChangeEvent={changeValue}
          decimalSeparator=","
          thousandSeparator="."
          precision={precision}
          id={id}
          inputType={inputType}
          allowNegative={allowNegative}
        />
      ) : (
        <Input
          disabled={disabled}
          className={className + ' has-value'}
          value={value}
          onChangeEvent={changeValue}
          decimalSeparator=","
          thousandSeparator="."
          precision={precision}
          id={id}
          inputType={inputType}
          allowNegative={allowNegative}
          half={half}
        />
      )}
      <label>{label}</label>
    </Container>
  );
};

export default InputNumero;
