/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import Header from '../../../../components/Header';
import { Flex } from '../../../../components/NavBar/styles';
import AddBtn from '../../../../components/Botoes/AddBtn';
import useModal from '../../../../services/hooks/useModal';
import AddIcon from '@material-ui/icons/Add';
import { useLocalStore, observer } from 'mobx-react-lite';
import useStore from '../../../../services/hooks/useStore';
import Filtro from '../../../../components/Filtro';
import { Body } from '../../../../components/Body';
import MaterialTable, { Column } from 'material-table';
import { config } from '../../../../components/Tabela';
import { MenuList, MenuItem } from '@material-ui/core';
import ActionButton from '../../../../components/Tabela/ActionButton';
import Confirmar from '../../../../components/Modais/Confirmar';
import AjusteEstoque from '../AjusteEstoque';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { KeyboardDatePicker } from '@material-ui/pickers';
// import FormCompra from '../FormCompra';
import { useHistory } from 'react-router-dom';
import sync from '../helpers/sync';
import Select from '../../../../components/Select';
import ReactSelect from 'react-select';
import Formatter from '../../../../services/Formatter';
import ReactApexChart from 'react-apexcharts';
import Display from '../../../../components/Tabela/Display';

import getAjuste from '../helpers/getAjuste';
import excluirCompraInsumos from '../services/excluirCompraInsumos';
import { CompraInsumo } from '../../../../@types/Insumo';
import ExcluirCompra from '../ExcluirCompra';
import { InsumoInfo } from '../styles';
import FormInsumos from '../FormInsumos';
import { permissoes } from '../../../../services/utils/permissoes';
import ModalCompraInsumo from './ModalCompraInsumo';

type State = PageState & {
  filtroRapidoInsumo: SelectRow | null;

  filtroInsumo: SelectRow | null;
  filtroFornecedor: SelectRow | null;
  filtroDataInicio: MaterialUiPickersDate;
  filtroDataFim: MaterialUiPickersDate;

  selectedInsumo: number;
  selectedCompra: number;

  filterUrlArray: string[];
  filterUrlString: string;

  sync: boolean;
};

const ComprasInsumos: React.FC = () => {
  const colors = ['#049CE7', '#FFE600', '#FE196B', '#465A65'];
  const modalCompra = useModal();
  const modalExclusao = useModal();
  const modalAjuste = useModal();
  const modalInsumo = useModal();
  const store = useStore();
  const history = useHistory();
  const state = useLocalStore(startConfig.state);
  const compra = store.comprasDeInsumos.comprasDeInsumos ? store.comprasDeInsumos.get(state.selectedCompra) : null;
  const tableRef: any = React.createRef();
  store.propriedade && store.validarPermissao(permissoes.CONTROLE_DE_INSUMOS_COMPRAS_INSUMOS_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.CONTROLE_DE_INSUMOS_COMPRAS_INSUMOS_ESCRITA);

  const tableListColumns: Column<object>[] = [
    {
      field: 'nome',
      title: 'Insumos',
      ...(true && ({ width: 170 } as object)),
    },
    {
      field: 'acoes',
      render: (rowData: any) => (
        <ActionButton
          editar={
            temPermissaoEscrita
              ? () => {
                  state.selectedCompra = rowData.id;
                  modalCompra.open();
                }
              : undefined
          }
          excluir={
            temPermissaoEscrita
              ? () => {
                  state.selectedCompra = rowData.id;
                  modalExclusao.open();
                }
              : undefined
          }
        />
      ),
      ...(true && ({ width: 25 } as object)),
    },
    {
      field: 'tipo',
      title: 'Tipo',
      render: (rowData: any) => {
        if (rowData.tipo === 'Compras') {
          return <b>{rowData.tipo}</b>;
        } else {
          return <b style={{ color: '#42a5f5' }}>{rowData.tipo}</b>;
        }
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'fornecedor',
      title: 'Fornecedor',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'data',
      title: 'Data',
      type: 'date',
      ...(true && ({ width: 100 } as object)),
    },

    {
      field: 'tipo_unidade',
      title: 'Un',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'total_kg',
      title: 'Quantidade',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'vlr_unit',
      title: 'Vr. Unitário',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'valor_total',
      title: 'Vr. Total',
      type: 'numeric',
      render: ({ valor_total }: any) => {
        return Formatter.formatNumber(2, valor_total);
      },
      ...(true && ({ width: 120 } as object)),
    },
  ];

  const garficoBarra = useLocalStore(() => ({
    series: [
      {
        name: 'Estoque',
        data: [10000],
      },
    ],
    options: {
      chart: {
        height: 180,
        type: 'bar',
        id: 'insumos',
        events: {},
        zoom: {
          enabled: false,
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: (val: string) => {
            return Formatter.formatNumber(3, val);
          },
        },
      },
    },
  }));

  useEffect(() => {
    if (state.filtroRapidoInsumo) {
      state.filterUrlArray.push(`&insumo=${state.filtroRapidoInsumo.value}`);
      state.filterUrlString = state.filterUrlArray.join('');
      store.insumos.getPaginated(0, 25, store.propriedade!.id, state.filterUrlString);
    }
    // eslint-disable-next-line
  }, [state.filtroRapidoInsumo]);

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Compras de Insumos'), []);

  useEffect(() => {
    store.propriedade && sync(state);
    // eslint-disable-next-line
  }, [store.propriedade]);

  useEffect(() => {
    if (state.sync) {
      ApexCharts.exec('insumos', 'updateSeries', [
        {
          data: store.insumos?.map((insumo) => {
            const estoque = insumo.estoque < 0 ? 0 : insumo.estoque;
            return { x: insumo.nome, y: estoque };
          }),
        },
      ]);
    }
    // eslint-disable-next-line
  }, [state.sync, store.insumos.insumos]);

  async function filtrar(): Promise<void> {
    if (state.filtroFornecedor || state.filtroInsumo || state.filtroDataInicio || state.filtroDataFim) {
      store.toggleLoader();

      if (state.filtroFornecedor) {
        state.filterUrlArray.push(`&fornecedor=${state.filtroFornecedor!.value}`);
      } else if (state.filtroInsumo) {
        state.filterUrlArray.push(`&insumo=${state.filtroInsumo!.value}`);
      } else if (state.filtroDataInicio) {
        const strDateInicio = Formatter.dateToString(state.filtroDataInicio);
        const requestDateInicio = strDateInicio.split(' ')[0];
        state.filterUrlArray.push(`&data_inicio=${requestDateInicio}`);
      } else if (state.filtroDataFim) {
        const strDateInicio = Formatter.dateToString(state.filtroDataInicio);
        const requestDateInicio = strDateInicio.split(' ')[0];
        state.filterUrlArray.push(`&data_fim=${requestDateInicio}`);
      }

      state.filterUrlString = state.filterUrlArray.join('');
      store.insumos.getPaginated(0, 25, store.propriedade!.id, state.filterUrlString);

      store.toggleLoader();
      state.filtroRapidoInsumo = null;
    } else {
      sync(state);
    }
  }
  function limparFiltro(): void {
    state.filtroInsumo = null;
    state.filtroFornecedor = null;
    state.filtroDataFim = null;
    state.filtroDataInicio = null;

    state.filterUrlArray = [];
    state.filterUrlString = '';
    store.insumos.getPaginated(0, 25, store.propriedade!.id);
  }

  async function deletar() {
    modalExclusao.close();
    store.toggleLoader();
    if (state.selectedCompra) {
      await store.comprasDeRacoes.excluir(state.selectedCompra);
      await sync(state);
    }
    store.toggleLoader();
  }

  return state.sync ? (
    <>
      <Header>
        <Flex>
          {/* botão de adição de nova compra e menu de compra */}
          <AddBtn
            disabled={!temPermissaoEscrita}
            dropdown
            onClick={() => {
              state.selectedCompra = 0;
              modalCompra.open();
            }}
            text="NOVA COMPRA"
            Icon={AddIcon}
          >
            <MenuList>
              <MenuItem
                onClick={() => {
                  state.selectedInsumo = 0;
                  modalInsumo.open();
                }}
              >
                Novo Insumo
              </MenuItem>
            </MenuList>
          </AddBtn>

          {/* select do nome de ração */}
          <div style={{ width: 200 }}>
            <ReactSelect
              value={state.filtroRapidoInsumo}
              classNamePrefix="sel"
              placeholder="Insumo"
              onChange={(e: any) => (state.filtroRapidoInsumo = e)}
              options={store.insumos.getSelectRows()}
            />
          </div>
        </Flex>
        <Flex>
          {/* filtros e limpa filtro */}
          <Filtro sync={filtrar} clear={limparFiltro}>
            <div style={{ width: '90%', margin: 10 }}>
              <Select
                value={state.filtroInsumo}
                placeholder="Insumos"
                onChange={(e: any) => (state.filtroInsumo = e)}
                options={store.insumos.getSelectRows()}
              />
              <Select
                value={state.filtroFornecedor}
                placeholder="Fornecedor"
                onChange={(e: any) => (state.filtroFornecedor = e)}
                options={store.fornecedores!.getSelectRows()}
              />
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data Início"
                value={state.filtroDataInicio}
                onChange={(date) => (state.filtroDataInicio = date)}
              />
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data Fim"
                value={state.filtroDataFim}
                onChange={(date) => (state.filtroDataFim = date)}
              />
            </div>
          </Filtro>
        </Flex>
      </Header>

      <Body gridColumns="1fr 500px">
        {/* tabela de listagem de insumos */}
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          {store.comprasDeInsumos.comprasDeInsumos && (
            <>
              <MaterialTable
                tableRef={tableRef}
                columns={tableListColumns}
                onChangePage={(page: number, pageSize: number) => {
                  if (store.propriedade) {
                    store.comprasDeInsumos.getPaginated(page, pageSize, store.propriedade.id, state.filterUrlString);
                  }
                }}
                data={tableListRows(state, store.comprasDeInsumos.comprasDeInsumos as any)}
                options={{ ...config.options, pageSize: state.pageSize }}
                localization={config.localization}
                style={config.style}
              />
            </>
          )}
        </div>

        <div>
          <Display style={{ marginBottom: '10px', height: 220 }} overflowY={false} title="Estoque de Insumo">
            <ReactApexChart
              height={150}
              options={garficoBarra.options as any}
              series={garficoBarra.series}
              type="bar"
            />
          </Display>

          {/* lista de minhas compras */}
          <Display
            verTudo={() => {
              history.push('/producao/insumos');
            }}
            style={{ marginBottom: '10px', height: '55vh' }}
            overflowY
            title="Meus Insumos"
          >
            {store.insumos?.map((insumo) => {
              const ultimaCompra = store.comprasDeInsumos!.filter(
                (compra) => insumo.id === compra.insumo.id && compra.ajuste_estoque === false,
              )[0];
              return (
                <InsumoInfo
                  key={insumo.id}
                  onClick={() => {
                    state.selectedInsumo = insumo.id;
                    modalInsumo.open();
                  }}
                >
                  <p className="left">{insumo.nome}</p>
                  <p className="right">
                    R$/kg{' '}
                    {ultimaCompra ? Formatter.formatNumber(2, ultimaCompra.valor_compra / ultimaCompra.total_kg) : 0}
                  </p>
                  <p className="left">{ultimaCompra ? ultimaCompra.fornecedor.label : 'Sem fornecedor'}</p>
                  <p className="right">
                    Estoque Atual: {Formatter.formatNumber(3, insumo.estoque < 0 ? 0 : insumo.estoque)} kg
                  </p>
                </InsumoInfo>
              );
            })}
          </Display>
        </div>
        {/* --------------------- */}
        <Confirmar
          open={modalExclusao.state}
          content={<ExcluirCompra compraID={compra?.id} />}
          title="Tem certeza que deseja excluir?"
          handleClose={modalExclusao.close}
          onCancel={modalExclusao.close}
          onConfirm={() => {
            excluirCompraInsumos(store, compra as CompraInsumo, modalExclusao.close);
          }}
        />
      </Body>

      {modalExclusao.state && (
        <ModalCompraInsumo
          open={modalExclusao.state}
          handleClose={modalExclusao.close}
          onDelete={() => {
            deletar();
            sync(state);
          }}
          compra={compra}
        />
      )}

      {/* {modalCompra.state ? (
        <FormCompra open={modalCompra.state} handleClose={modalCompra.close} compraID={state.selectedCompra} />
      ) : null} */}
      {modalCompra.state ? (
        <ModalCompraInsumo
          open={modalCompra.state}
          handleClose={modalCompra.close}
          onConfirm={() => sync(state)}
          compra={compra}
        />
      ) : null}

      {modalAjuste.state ? (
        <AjusteEstoque open={modalAjuste.state} handleClose={modalAjuste.close} ajuste={getAjuste(store, state)} />
      ) : null}
      {modalInsumo.state ? (
        <FormInsumos open={modalInsumo.state} handleClose={modalInsumo.close} insumoID={state.selectedInsumo} />
      ) : null}
    </>
  ) : null;
};

export default observer(ComprasInsumos);

// ---------------------------------

const startConfig = {
  state: (): State => ({
    pageSize: 0,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
    filtroRapidoInsumo: null,

    filtroInsumo: null,
    filtroFornecedor: null,
    filtroDataInicio: null,
    filtroDataFim: null,

    selectedInsumo: 0,
    selectedCompra: 0,

    filterUrlArray: [],
    filterUrlString: '',

    sync: false,
  }),
};

const tableListRows = (state: any, compras: CompraInsumo[]): object[] => {
  ajusteTamanhoTabela(state, compras);

  return compras.map((compra) => {
    return {
      id: compra.id,
      fornecedor: compra.fornecedor.label,
      nome: compra.insumo.nome,
      tipo: compra.ajuste_estoque ? 'Ajuste - ' + compra.tipo : 'Compra',
      tipo_unidade: compra.insumo.tipo_unidade,
      tipo_produto: compra.insumo.tipo_produto,
      data: compra.data.split(' ')[0],
      valor_total: compra.valor_compra,
      total_kg: Formatter.formatNumber(3, compra.total_kg),
      vlr_unit: Formatter.formatNumber(2, compra.valor_compra / compra.total_kg),
    };
  });
};

const ajusteTamanhoTabela = (state: State, compras: CompraInsumo[]): void => {
  if (compras.length) {
    if (compras.length >= 50) {
      state.setPageSize(50);
    } else {
      state.setPageSize(compras.length);
    }
  } else {
    state.setPageSize(0);
  }
};
