import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from './styles';
import Botao from '../Botoes/Botao';
import useStore from '../../services/hooks/useStore';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useLocalStore, observer } from 'mobx-react-lite';
import Formatter from '../../services/Formatter';
import { TanqueInfo2 } from '../TanqueInfo';
import X9Logger from '../../services/X9Logger';

type Props = ModalProps & {
  onConfirm?(): void;
  async(): void;
  tanques: any;
};

const FinalizarTanque: React.FC<Props> = ({ open, handleClose, onConfirm, async, tanques }) => {
  const store = useStore();
  const state = useLocalStore(() => ({
    data_mortalidade: new Date(),
    quantidade: 0,
  }));

  async function sync(): Promise<void> {
    store.toggleLoader();

    if (!store.tanques.arr) await store.tanques.populate();
    store.toggleLoader();
  }

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, []);

  async function lancarMortalidadeTanque(): Promise<void> {
    try {
      store.toggleLoader();
      await store.tanques.lancarMortalidadeVenda(tanques, state.data_mortalidade);
    } catch (err) {
      X9Logger.report(err as Error);
      store.notificar('Ocorreu um erro ao lançar mortalidade');
    }
    handleClose();
    store.toggleLoader();
    onConfirm ? onConfirm() : null;
    async();
  }

  const TanquesDisponiveis: React.FC = () => (
    <>
      {tanques.map((tanque: any) => {
        return (
          <TanqueInfo2 key={tanque.id}>
            <b>{tanque.item.nome === '' || tanque.item.nome === null ? tanque.item.codigo : tanque.item.nome}</b>
            <p>{Formatter.formatNumber(0, tanque.item.estoque - tanque.quantidade)} un</p>
            <p style={{ color: '#00C853' }}>{Formatter.formatNumber(2, tanque.biomassa)} Kg</p>
          </TanqueInfo2>
        );
      })}
    </>
  );
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>Lançar Mortalidade no Tanque</ModalHeader>
      <ModalBody>
        <p style={{ color: 'red' }}>Certifique-se de que a quantidade de mortalidade está correta.</p>
        <br />
        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data de Mortalidade"
          value={state.data_mortalidade}
          onChange={(date) => (state.data_mortalidade = date!)}
        />

        <div
          style={{
            color: '#707070',
            width: '100%',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        ></div>
        <TanquesDisponiveis />
      </ModalBody>
      <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
        <Botao cor="#FC7467" onClick={handleClose}>
          Cancelar
        </Botao>
        <Botao cor="#00C853" onClick={lancarMortalidadeTanque}>
          Lançar
        </Botao>
      </div>
    </Modal>
  );
};

export default observer(FinalizarTanque);
