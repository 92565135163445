import React, { useEffect, useState, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { useLocalStore, observer } from 'mobx-react-lite';
import { TextField, Paper, IconButton, FormControlLabel, Checkbox } from '@material-ui/core';
import useStore from '../../../services/hooks/useStore';
import Botao from '../../../components/Botoes/Botao';
import { KeyboardDatePicker } from '@material-ui/pickers';
import InputNumero from '../../../components/InputNumero';
import SelecionarTanqueOrigem from './SelecionarTanqueOrigem';
import useModal from '../../../services/hooks/useModal';
import Select from '../../../components/Select';
import SelecionarLote from '../../../components/Modais/SelecionarLote';
import SelecionarLoteDestino from '../../../components/Modais/SelecionarLoteDestino';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { Delete } from '@material-ui/icons';
import { api, apiV2 } from '../../../services/utils';
import Formatter from '../../../services/Formatter';
import ModalNovoLote from './ModalNovoLote';
import SelecionarTanqueDestino from './SelecionarTanqueDestino';

const FormTransferencia: React.FC<ModalProps & { porTanques?: boolean }> = ({
  open,
  handleClose,
  porTanques = false,
}) => {
  const store = useStore();
  const state = useLocalStore(() => ({
    lote: null as SelectRow | null,
    loteDestino: null as SelectRow | null,
    especie: '',
    especieID: 0,
    fornecedorNome: '',
    fornecedorID: 0,
    tqOrigem: null as {
      label: '';
      value: '';
      row: any;
    } | null,
    tqDestino: [] as TanqueDestino[],
    data: new Date() as MaterialUiPickersDate,
    biomassa: 0,
    pesoMedio: 0,
    quantidade: 0,
    tamanho: 0,
    observacao: '',
    finalizar: false,
    biometria: false,
  }));
  const modalSelecionarTanqueOrigem = useModal();
  const modalSelecionarTanqueDestino = useModal();
  const modalSelecionarLote = useModal();
  const modalSelecionarLoteDestino = useModal();
  const modalNovoLote = useModal();

  async function cadastrar(): Promise<void> {
    function check(): boolean {
      if (!state.lote) {
        store.notificar('Selecione o lote');
        return false;
      } else if (!state.tqOrigem) {
        store.notificar('Selecione o tanque origem');
        return false;
      } else if (!state.tqDestino.length) {
        store.notificar('Selecione um tanque destino');
        return false;
      } else return true;
    }

    if (check()) {
      const quantidadeTanque = state.tqOrigem!.row.estoque;

      const dataPovoamento = new Date(
        state.tqOrigem!.row.lote.data.substr(3, 2) +
          '/' +
          state.tqOrigem!.row.lote.data.substr(0, 2) +
          '/' +
          state.tqOrigem!.row.lote.data.substr(6, 4),
      );

      if (state.data!.getTime() < dataPovoamento.getTime())
        store.notificar('A data da transferência não pode ser anterior à data do Povoamento!');
      else if (!state.quantidade) store.notificar('Informe a quantidade');
      else if (state.quantidade > quantidadeTanque) store.notificar('Quantidade de animais maior que o estoque!');
      else {
        handleClose();
        store.toggleLoader();

        const loteTanqueOrigem = store.loteTanque.getByLoteTanque(
          Number(state.lote!.value),
          Number(state.tqOrigem!.value),
        );
        const promiseArr: Array<Promise<void>> = [];

        for (let transferencia = 0; transferencia < state.tqDestino.length; transferencia++) {
          const dataTransf = {
            origem: loteTanqueOrigem.uuid,
            destino: {
              tanque: state.tqDestino[transferencia].uuid,
              lote: state.loteDestino ? state.loteDestino.value : parseInt(state.lote!.value),
            },
            data: Formatter.dateToString(state.data),
            biomassa: state.tqDestino[transferencia].biomassa,
            peso:
              state.especie === 'Camarão'
                ? Number(state.tqDestino[transferencia].pmCamarao.toFixed(6)) / 1000
                : Number(state.tqDestino[transferencia].pesoMedio.toFixed(6)),
            pls_grama: state.especie === 'Camarão' ? state.tqDestino[transferencia].pesoMedio : 0,
            quantidade: state.tqDestino[transferencia].quantidade,
            tamanho: state.tqDestino[transferencia].tamanho,
            observacao: state.observacao,
            biometria: state.biometria,
          };

          const addTransferencia = async (): Promise<void> => {
            await apiV2.post('/transferencia/', dataTransf);
          };

          promiseArr.push(addTransferencia());
        }

        await Promise.all(promiseArr)
          .then(async () => {
            store.notificar('Transferências registradas com sucesso');

            const response = await apiV2.get(`/lote-tanque/estoque/`, {
              params: { prop: store.propriedade!.id, lote_tanque: loteTanqueOrigem.id },
            });

            if (response.data.estoque > 0 && state.finalizar === true) {
              await api.post(`/mortalidade/?propriedade=${store.propriedade!.id}`, {
                lote_tanque: loteTanqueOrigem.id,
                data: Formatter.dateToString(new Date()),
                quantidade: response.data.estoque,
                tipo_morte: 7,
                observacao: null,
              });

              store.notificar(
                `O saldo de (${response.data.estoque}) animais do tanque origem será registrado como mortalidade!`,
              );
            }

            await store.transferencias.populate();
          })
          .catch(() => {
            store.notificar('Ocorreu um erro ao registrar algumas das transferências!');
          });

        store.toggleLoader();
      }
    }
  }

  function updateInfo(): void {
    let biomassa = 0;
    let quantidade = 0;
    let pesoMedio = 0;
    let tamanho = 0;

    state.tqDestino.map((tanque) => {
      biomassa += tanque.biomassa;
      quantidade += tanque.quantidade;
      pesoMedio += tanque.pesoMedio;
      tamanho += tanque.tamanho;

      return null;
    });

    state.biomassa = biomassa;
    state.quantidade = quantidade;
    state.pesoMedio = pesoMedio / state.tqDestino.length;
    state.tamanho = tamanho;
  }

  function deletarTanque(tanqueUUID: string): void {
    const newTanquesDestino = state.tqDestino.filter((tanque) => tanque.uuid !== tanqueUUID);

    state.tqDestino = newTanquesDestino;

    updateInfo();
  }

  function novoLoteDestino(nome: any, id: any): void {
    state.loteDestino = { label: nome, value: id };
  }

  const [loteOrigem, setLoteOrigem] = useState(false);
  const [newLoteOrigem, setNewLoteOrigem] = useState(false);

  const pesoMedioEmGramas: boolean = useMemo(() => {
    return state.pesoMedio > 0 && state.pesoMedio < 0.001;
  }, [state.pesoMedio]);

  // eslint-disable-next-line
  useEffect(() => {}), [modalSelecionarTanqueDestino];
  useEffect(() => {
    if (porTanques && state.tqOrigem) {
      state.lote = {
        label: state.tqOrigem?.row?.lote?.codigo,
        value: state.tqOrigem?.row?.lote?.id,
      };
      if (state.tqOrigem?.row?.lote?.id) {
        const lote = store.lotes.get(state?.tqOrigem?.row.lote.id || 0)!;
        state.especie = lote.especie_explorada.nome;
        state.especieID = lote.especie_explorada.id;
        state.fornecedorNome = lote.fornecedor.nome;
        state.fornecedorID = lote.fornecedor.id;
        setLoteOrigem(true);
      }
    }
    
  }, [state.tqOrigem]);

  return (
    <>
      <Modal onClose={handleClose} open={open}>
        <ModalHeader>Nova transferência</ModalHeader>
        <ModalBody>
          {porTanques ? (
            <>
              <div style={{ width: '100%' }}>
                <Select
                  value={state.tqOrigem}
                  placeholder="Tanque Origem"
                  onFocus={(e) => {
                    e.target.blur();
                    modalSelecionarTanqueOrigem.open();
                  }}
                  options={[]}
                />
              </div>

              <div style={{ width: '100%', marginTop: 10 }}>
                {store.lotes.arr && (
                  <Select
                    disabled={porTanques}
                    value={state.lote}
                    placeholder="Lote Origem"
                    onFocus={(e) => {
                      e.target.blur();
                      setNewLoteOrigem(false);
                      modalSelecionarLote.open();
                    }}
                    options={[]}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <div style={{ width: '100%' }}>
                {store.lotes.arr && (
                  <Select
                    value={state.lote}
                    placeholder="Lote Origem"
                    onFocus={(e) => {
                      e.target.blur();
                      setNewLoteOrigem(false);
                      modalSelecionarLote.open();
                    }}
                    options={[]}
                  />
                )}
              </div>

              <div style={{ width: '100%', marginTop: 10 }}>
                <Select
                  value={state.tqOrigem}
                  placeholder="Tanque Origem"
                  onFocus={(e) => {
                    e.target.blur();
                    state.lote && modalSelecionarTanqueOrigem.open();
                  }}
                  options={[]}
                />
              </div>
            </>
          )}

          <Botao
            onClick={() => {
              modalNovoLote.open();
            }}
            disabled={loteOrigem ? false : true}
          >
            Adicionar novo lote destino
          </Botao>

          <br />

          <div style={{ width: '100%' }}>
            {store.lotes.arr && (
              <Select
                value={state.loteDestino ? state.loteDestino : state.lote}
                placeholder="Lote Destino"
                onFocus={(e) => {
                  e.target.blur();
                  modalSelecionarLoteDestino.open();
                }}
                options={[]}
                disabled={porTanques ? false : !loteOrigem}
              />
            )}
          </div>

          <Botao
            onClick={() => {
              if (state.tqOrigem) {
                modalSelecionarTanqueDestino.open();
              } else {
                store.notificar('Escolha o lote e um tanque origem');
              }
            }}
          >
            Adicionar tanque destino
          </Botao>

          <br />

          {state.tqDestino.map((tanque) => (
            <>
              <Paper
                key={tanque.uuid}
                elevation={3}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 5,
                  width: '100%',
                }}
              >
                <span>{tanque.nome}</span>
                <span>{Formatter.formatNumber(0, tanque.biomassa)}kg</span>
                <span>{Formatter.formatNumber(0, tanque.quantidade)}un</span>
                <IconButton onClick={() => deletarTanque(tanque.uuid)}>
                  <Delete />
                </IconButton>
              </Paper>
              <br />
            </>
          ))}

          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data"
            value={state.data}
            onChange={(date) => (state.data = date)}
          />

          <InputNumero disabled value={state.biomassa} precision="3" label="Biomassa Total (kg)" />
          <InputNumero
            disabled
            value={pesoMedioEmGramas ? state.pesoMedio * 1000 : state.pesoMedio}
            precision={'3'}
            label={state.especie === 'Camarão' ? 'PLs/Grama' : `Peso Médio (${pesoMedioEmGramas ? '' : 'k'}g)`}
          />
          {state.especie === 'Camarão' ? (
            <InputNumero
              disabled
              value={Number(((state.biomassa / state.quantidade) * 1000).toFixed(3))}
              precision="3"
              label="Peso Médio (g)"
            />
          ) : null}
          <InputNumero disabled value={state.quantidade} precision="0" label="Quantidade Total" />
          <InputNumero disabled value={state.tamanho} precision="2" label="Tamanho Médio (cm)" />

          <TextField
            label="Observação *Opcional"
            value={state.observacao}
            onChange={(e) => (state.observacao = e.target.value)}
          />

          <div style={{ width: '100%', display: 'flex' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.biometria}
                  onChange={(e) => (state.biometria = e.target.checked)}
                  color="primary"
                />
              }
              label="Gerar Biometria Tanque Origem"
            />
          </div>

          <div style={{ width: '100%', display: 'flex' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.finalizar}
                  onChange={(e) => (state.finalizar = e.target.checked)}
                  color="primary"
                />
              }
              label="Finalizar tanque origem"
            />
          </div>

          <div style={{ width: '100%', display: 'flex' }}>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao onClick={cadastrar} variant="contained" cor="#00C853">
              Cadastrar
            </Botao>
          </div>

          <br />
        </ModalBody>
        {state.lote && !porTanques ? (
          <SelecionarTanqueOrigem
            open={modalSelecionarTanqueOrigem.state}
            handleClose={modalSelecionarTanqueOrigem.close}
            onItemClick={(tanque) => (state.tqOrigem = tanque)}
            loteID={parseInt(state.lote.value)}
          />
        ) : null}

        {modalSelecionarTanqueOrigem.state && (
          <SelecionarTanqueOrigem
            open={modalSelecionarTanqueOrigem.state}
            handleClose={modalSelecionarTanqueOrigem.close}
            onItemClick={(tanque) => (state.tqOrigem = tanque)}
            all={porTanques}
            loteID={parseInt(state?.lote?.value)}
          />
        )}
        {!porTanques && (
          <SelecionarLote
            open={modalSelecionarLote.state}
            handleClose={() => {
              modalSelecionarLote.close();
              setNewLoteOrigem(true);
            }}
            onItemClick={(lote) => {
              state.loteDestino = null;
              state.lote = { label: lote.codigo, value: lote.id.toString() };
              state.especie = lote.especie_explorada.nome;
              state.especieID = lote.especie_explorada.id;
              state.fornecedorNome = lote.fornecedor.nome;
              state.fornecedorID = lote.fornecedor.id;
              state.tqOrigem = null;
              state.tqDestino = [];
              setLoteOrigem(true);
              setNewLoteOrigem(true);
            }}
            loteID={state.lote ? parseInt(state.lote.value) : null}
          />
        )}
        <SelecionarLoteDestino
          open={modalSelecionarLoteDestino.state}
          handleClose={modalSelecionarLoteDestino.close}
          onItemClick={(lote) => {
            state.loteDestino = { label: lote.codigo, value: lote.id.toString() };
            state.especie = lote.especie_explorada.nome;
            state.tqDestino = [];
          }}
          loteID={state.lote ? parseInt(state.lote.value) : null}
        />
        {newLoteOrigem === true || modalNovoLote.state ? (
          <ModalNovoLote
            open={modalNovoLote.state}
            handleClose={modalNovoLote.close}
            novoLoteDestino={novoLoteDestino}
            especieNome={state.especie}
            especieID={state.especieID.toString()}
            fornecedorNome={state.fornecedorNome}
            fornecedorID={state.fornecedorID}
            setLoteOrigem={setLoteOrigem}
          />
        ) : null}

        {modalSelecionarTanqueDestino.state ? (
          <SelecionarTanqueDestino
            open={modalSelecionarTanqueDestino.state}
            handleClose={modalSelecionarTanqueDestino.close}
            // exclude={state.tqOrigem ? parseInt(state.tqOrigem.value) : undefined}
            loteId={state.loteDestino?.value}
            tanquesSelecionados={state.tqDestino}
            onConfirm={(tanque) => {
              state.tqDestino.push(tanque);
              updateInfo();
            }}
            isCamarao={state.especie === 'Camarão'}
            tanqueUUID={state.tqDestino.length > 0 ? state.tqDestino[0].uuid : undefined}
            state2={state}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default observer(FormTransferencia);
