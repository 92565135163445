import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ErrorBoundary } from 'react-error-boundary';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Backdrop, CircularProgress } from '@material-ui/core';
import ptLocale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import useStore from './services/hooks/useStore';
import X9Logger from './services/X9Logger';
import Notificacao from './components/Notificacao';
import Drawer from './components/Drawer';
import NavBar from './components/NavBar';
import ModalAlterarSenha from './components/Modais/AlterarSenha';
import Login from './pages/Login';
import ErroExecucao from './pages/Erros/ErroExecucao';
import { Flex } from './components/NavBar/styles';
import checkUser from './middlewares/checkUser';
import Routes from './routes';
import ReactGa from 'react-ga';
// import startMockServer from './services/APIMock';

import { useClearCache } from 'react-clear-cache';

const App: React.FC = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const store = useStore();

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, [isLatestVersion, emptyCacheStorage]);

  useEffect(() => {
    if (window.location.pathname !== '/') {
      checkUser();
    }
    ReactGa.initialize('UA-148724062-2');
    ReactGa.pageview(window.location.pathname + window.location.search);
    // eslint-disable-next-line
    // startMockServer();
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErroExecucao} onError={X9Logger.report}>
      <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />

            <>
              <NavBar />
              <div className="flex">
                <Drawer />
                <div className="content">
                  <Routes />
                </div>
              </div>
            </>
          </Switch>

          <Notificacao />
          <ModalAlterarSenha open={store.modalAlterarSenha} handleClose={store.toggleModalAlterarSenha} />
          <Backdrop className="loader" open={store.loader}>
            <Flex style={{ flexDirection: 'column' }}>
              <CircularProgress />
              <br />
              {!store.logado ? (
                <p style={{ color: 'white' }}>Validando credenciais</p>
              ) : (
                <p style={{ color: 'white' }}>Carregando dados</p>
              )}
            </Flex>
          </Backdrop>
        </Router>
      </MuiPickersUtilsProvider>
    </ErrorBoundary>
  );
};

export default observer(App);
