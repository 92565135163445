/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { useLocalStore, observer } from 'mobx-react-lite';
import useStore from '../../../services/hooks/useStore';
import Botao from '../../../components/Botoes/Botao';
import Formatter from '../../../services/Formatter';
import { Checkbox, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import InputNumero from '../../../components/InputNumero';
import SelecionarTanque from './SelecionarTanque';
import useModal from '../../../services/hooks/useModal';
import Select from '../../../components/Select';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DatePicker } from '@material-ui/pickers';
import { apiV2 } from '../../../services/utils';
import { Box, FormControlLabel, FormGroup, Typography } from '@mui/material';
import SelecionarLoteRateio from './SelecionarLote';
import {
  ApportionmentItem,
  ApportionmentItemInfo,
  ApportionmentItemInfoCenter,
  ApportionmentItemsContainer,
  ButtonsContainer,
  Label,
  LotsContainer,
  Row,
  RowCenter,
  TanksContainer,
  TankItem,
  TextPrice,
} from './styles';

interface DescricaoRateio {
  id: number;
  descricao: string;
  valor: number;
  num_parcela: number;
  total_parcelas: number;
}

interface Props extends ModalProps {
  handleSuccess: () => void;
}

const FormTransferencia: React.FC<Props> = ({ open, handleClose, handleSuccess }) => {
  const store = useStore();
  const modalTanques = useModal();
  const modalSelecionarLote = useModal();
  const [rateioTotalLoteTanques, setRateioTotalLoteTanques] = useState(false);
  const [loteTanques, setLoteTanques] = useState<LoteTanque[]>([] as LoteTanque[]);
  const [descricaoRateio, setDescricaoRateio] = useState<DescricaoRateio[]>([]);

  const state = useLocalStore(() => ({
    categoria: null as SelectRow | null,
    categoriasOptions: [{ label: '', value: '' }] as SelectRow[],
    loteDestino: null as SelectRow | null,
    especie: '',
    especieID: 0,
    fornecedorNome: '',
    fornecedorID: 0,
    lote_tanque: [] as SelectedLoteTanque[],
    lotes: [] as SelectedLote[],
    parcelas: [] as number[],
    data: new Date() as MaterialUiPickersDate,
    setData(value: MaterialUiPickersDate) {
      this.data = value;
      if (value) {
        this.qtdDias = new Date(value.getFullYear(), value.getMonth() + 1, 0).getDate();
      }
    },
    qtdDias: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate(),
    setQtdDias(value: number) {
      this.qtdDias = value;
      if (this.qtdDias > 0 && this.percentRateio > 0 && this.valor_total > 0) {
        const percentInMonth = this.valor_total * (this.percentRateio / 100);
        this.valor_rateado = percentInMonth / this.qtdDias;
      }
    },
    percentRateio: 100,
    setPercentRateio(value: number) {
      this.percentRateio = value;
      if (this.qtdDias > 0 && this.percentRateio > 0 && this.valor_total > 0) {
        const percentInMonth = this.valor_total * (this.percentRateio / 100);
        this.valor_rateado = percentInMonth / this.qtdDias;
      }
    },
    tanquesDosLotesSelecionados: 0,
    quantidade: 0,
    tamanho: 0,
    observacao: '',
    finalizar: false,
    biometria: false,
    selectTanques: null as SelectRow | null,
    selectLotes: null as SelectRow | null,
    valor_total: 0,
    setValorTotal(value: number) {
      this.valor_total = value;
      if (this.qtdDias > 0 && this.percentRateio > 0 && this.valor_total > 0) {
        const percentInMonth = this.valor_total * (this.percentRateio / 100);
        this.valor_rateado = percentInMonth / this.qtdDias;
      }
    },

    valor_rateado: 0,
  }));

  useEffect(() => {
    if (store.propriedade) {
      apiV2
        .get(`/rateio/categoria-financeira/?prop=${store.propriedade?.id}`)
        .then((res) => {
          const categorias = res.data.map((item: any) => {
            if (item.rateio) {
              return {
                label: item.nome,
                value: item.id,
              };
            }
            return null;
          });
          state.categoriasOptions = categorias.length > 0 ? categorias : [{ label: '', value: '' }];
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [store.propriedade]);

  useEffect(() => {
    async function searchTanque() {
      store.toggleLoader();
      await apiV2
        .get('/lote-tanque/modals/', {
          params: {
            propriedade: store.propriedade!.id,
          },
        })
        .then((res) => {
          setLoteTanques(res.data.results);
        });
    }
    searchTanque();
    store.toggleLoader();
  }, [store.propriedade]);

  useEffect(() => {
    if (store.propriedade && state.categoria && state.data) {
      apiV2
        .get(`/rateio/valor-total/${state.categoria.value}/`, {
          params: {
            prop: store.propriedade?.id,
            mes: state.data.getMonth() + 1,
            ano: state.data.getFullYear(),
          },
        })
        .then((res) => {
          state.setValorTotal(res.data.valor_total);
          setDescricaoRateio(res.data.lista);

          state.parcelas = res.data.lista.map((despesa: DescricaoRateio) => despesa.id);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [state.categoria, state.data, store.propriedade]);

  function removerParcela(idParcela: number) {
    state.parcelas = state.parcelas.filter((id) => id !== idParcela);
  }

  function removerDespesa(index: number) {
    setDescricaoRateio((despesas) => {
      const despesaRemovida = despesas[index];
      const newDespesas = [...despesas];
      newDespesas.splice(index, 1);

      if (despesaRemovida) {
        const novoValorRateado = state.valor_rateado - despesaRemovida.valor / state.qtdDias;
        state.valor_rateado = novoValorRateado;
        const novoValorTotal = state.valor_total - despesaRemovida.valor;
        state.valor_total = novoValorTotal;

        removerParcela(despesaRemovida.id);
      }

      return newDespesas;
    });
  }
  const cadastrar = () => {
    if (state.lotes.length === 0 && state.lote_tanque.length === 0) {
      store.notificar('Precisa informar tanques para rateio!');
      return;
    }
    let tanques = [] as any;
    if (state.lotes.length > 0) {
      state.lotes.map((lote) => {
        return lote.item.tanques.map((tanque) => {
          if (tanque.estoque > 0) {
            const loteTanque = store.loteTanque.getByLoteTanque(lote.item.id, tanque.tanque_id);
            tanques.push(loteTanque.id);
          }
        });
      });
    }

    // state.lote_tanque.length > 0 ? state.lote_tanque.map((item) => item.item.id) : []
    const data = {
      propriedade: store.propriedade?.id,
      lote_tanques: state.lotes.length > 0 ? tanques : state.lote_tanque.map((item) => item.item.id),
      categoria: state.categoria?.value,
      mes: state.data ? state.data.getMonth() + 1 : null,
      ano: state.data?.getFullYear(),
      dias_mes: state.qtdDias,
      valor: state.valor_total,
      porcentagem: state.percentRateio,
      parcelas: state.parcelas,
    };

    apiV2
      .post(`/rateio/?propriedade=${store.propriedade?.id}`, data)
      .then(() => {
        store.notificar('Rateio cadastrado com sucesso!');

        handleSuccess();
        handleClose();
      })
      .catch((err) => {
        store.notificar('Ocorreu um erro no rateio!');
        console.error(err);
      });
  };

  function addTanque(tanqueID: number): void {
    const tanque = { item: store.loteTanque.get(tanqueID) };
    state.lote_tanque.push(tanque);
  }
  function addLote(loteID: number): void {
    const lote = { item: store.lotes.get(loteID) };

    state.lotes.push(lote);
  }
  //ajustar
  function addRateioTodosTanques() {
    setRateioTotalLoteTanques((prev) => !prev);
    state.lote_tanque = [];
    if (!rateioTotalLoteTanques) {
      const filteredLoteTanqueMaiorZero = loteTanques
        .filter((i) => {
          return i.estoque ? i.estoque > 0 : false;
        })
        .map((i) => {
          return { item: i };
        });
      state.lote_tanque.push(...filteredLoteTanqueMaiorZero);
    }
  }

  function cancelarSelecao(): void {
    modalTanques.close();
  }
  function cancelarSelecaoLote(): void {
    modalSelecionarLote.close();
  }

  function removerTanque(tanque: any): void {
    state.quantidade -= parseInt(tanque.quantidade);
    const newTanques = state.lote_tanque.filter((tanqueArr) => tanqueArr !== tanque);
    state.lote_tanque = newTanques;
  }

  function removerLote(lote: any): void {
    state.quantidade -= parseInt(lote.quantidade);
    const newLotes = state.lotes.filter((loteArr) => loteArr !== lote);
    state.lotes = newLotes;
  }
  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Novo Rateio</ModalHeader>
      <ModalBody style={{ width: '30vw' }}>
        <Row>
          <Select
            value={state.categoria}
            placeholder="Escolha a categoria"
            onChange={(e: any) => (state.categoria = e)}
            options={state.categoriasOptions}
          />
        </Row>
        <Row>
          <DatePicker
            variant="inline"
            views={['year', 'month']}
            label="Selecione o mês/ano"
            value={state.data}
            onChange={(e) => state.setData(e)}
            autoOk
          />
        </Row>
        <Row>
          <Label>Registros de Despesas</Label>
          <ApportionmentItemsContainer>
            {descricaoRateio.map((despesa: DescricaoRateio, index: number) => (
              <ApportionmentItem key={despesa.id}>
                <ApportionmentItemInfo>{despesa.descricao}</ApportionmentItemInfo>
                <ApportionmentItemInfoCenter>{Formatter.formatBRLCurrency(despesa.valor)}</ApportionmentItemInfoCenter>
                <ApportionmentItemInfo>
                  Parc: {despesa.num_parcela} / {despesa.total_parcelas}
                </ApportionmentItemInfo>
                <IconButton onClick={() => removerDespesa(index)}>
                  <DeleteIcon />
                </IconButton>
              </ApportionmentItem>
            ))}
          </ApportionmentItemsContainer>
        </Row>

        <InputNumero
          value={state.qtdDias}
          precision="0"
          label="Quantidade de dias"
          onChange={(value) => state.setQtdDias(value)}
        />
        <InputNumero
          value={state.percentRateio}
          precision="0"
          label="Percentual de Rateio"
          onChange={(value) => state.setPercentRateio(value)}
        />

        <RowCenter>
          <Label>Valor rateado por dia</Label>
          <TextPrice>{Formatter.formatBRLCurrency(state.valor_rateado)}</TextPrice>
        </RowCenter>

        <Row>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={rateioTotalLoteTanques} color="primary" onClick={addRateioTodosTanques} />}
              label="Todos os tanques povoados"
            />
          </FormGroup>
          {state.selectTanques === null && (
            <>
              <ButtonsContainer>
                <Botao onClick={modalTanques.open} variant="contained" cor="#059DE7">
                  Adicionar Tanques
                </Botao>
              </ButtonsContainer>
              <TanksContainer>
                {state.lote_tanque.length > 0 &&
                  state.lote_tanque
                    .slice()
                    .sort((tanqueA, tanqueB) => {
                      const nomeA = tanqueA.item.tanque.nome ?? tanqueA.item.tanque.codigo;
                      const nomeB = tanqueB.item.tanque.nome ?? tanqueB.item.tanque.codigo;
                      return nomeA.localeCompare(nomeB);
                    })
                    .map((tanque: any) => (
                      <TankItem key={tanque.item.id}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" gap='10px'>
                          <Typography sx={{minWidth: '100px'}}>
                            {tanque.item.tanque.nome ?? tanque.item.tanque.codigo}
                          </Typography>

                          <Typography sx={{minWidth: '150px'}}>{tanque.item.lote.nome || tanque.item.lote.codigo}</Typography>
                        </Box>

                        <p>{Formatter.formatNumber(0, tanque.item.quantidade)}</p>
                        <IconButton onClick={() => removerTanque(tanque)}>
                          <DeleteIcon />
                        </IconButton>
                      </TankItem>
                    ))}
              </TanksContainer>
            </>
          )}

          {state.selectLotes === null && (
            <>
              <ButtonsContainer>
                <Botao onClick={modalSelecionarLote.open} variant="contained" cor="#059DE7">
                  Adicionar Lote
                </Botao>
              </ButtonsContainer>
              {state.lotes.length > 0 &&
                state.lotes.map((lote: any) => (
                  <LotsContainer key={lote.item.id}>
                    <p>{lote.item.nome || lote.item.codigo}</p>
                    <p>{Formatter.formatNumber(0, lote.quantidade)}</p>
                    <IconButton onClick={() => removerLote(lote)}>
                      <DeleteIcon />
                    </IconButton>
                  </LotsContainer>
                ))}
            </>
          )}
        </Row>

        <ButtonsContainer>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao onClick={cadastrar} variant="contained" cor="#00C853">
            Cadastrar
          </Botao>
        </ButtonsContainer>

        <br />
      </ModalBody>
      {modalTanques.state && (
        <SelecionarTanque
          addTanque={addTanque}
          cancelar={cancelarSelecao}
          selectedTanques={state.lote_tanque}
          handleClose={modalTanques.close}
          open={modalTanques.state}
        />
      )}
      {modalSelecionarLote.state && (
        <SelecionarLoteRateio
          addLote={addLote}
          cancelar={cancelarSelecaoLote}
          handleClose={modalSelecionarLote.close}
          open={modalSelecionarLote.state}
          selectedLotes={state.lotes}
        />
      )}
    </Modal>
  );
};

export default observer(FormTransferencia);
