import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../components/Modais/styles';
import Botao from '../../components/Botoes/Botao';
import useStore from '../../services/hooks/useStore';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useLocalStore, observer } from 'mobx-react-lite';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { returnTanque } from '../../services/utils/propriedade';
import {  TanqueInfo2 } from '../TanqueInfo';
import Formatter from '../../services/Formatter';
import X9Logger from '../../services/X9Logger';

type Props = ModalProps & {
  tanques?:number[]
  povoamento?:boolean,
  loteID: number;
  onConfirm?(): void;
  async(): void;
};
const FinalizarLote: React.FC<Props> = ({ open, handleClose, loteID, onConfirm, async,povoamento}) => {
  const store = useStore();
  const lote = store.lotes.get(loteID);
 
  const state = useLocalStore(() => ({
    data: new Date(),
  }));

  async function sync(): Promise<void> {
    store.toggleLoader();
    
    if (!store.lotes.arr || !store.vendas.arr) await Promise.all([store.lotes.populate(), store.vendas.populate()]);
    store.toggleLoader();
  }

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, []);

async function finalizarLote(): Promise<void> {
  try {
    store.toggleLoader();
    const historico = await store.lotes.getHistorico(loteID);
    const vendas = store.vendas.getByLote(loteID);

    let dataRecente: MaterialUiPickersDate = new Date(
      historico[0].data.substr(3, 2) + '/' + historico[0].data.substr(0, 2) + '/' + historico[0].data.substr(6, 4),
    );

    vendas.map((venda) => {
      const data = new Date(venda.data.substr(3, 2) + '/' + venda.data.substr(0, 2) + '/' + venda.data.substr(6, 4));

      if (data.getTime() > dataRecente!.getTime()) {
        dataRecente = data;
      }

      return null;
    });

    if (state.data.getTime() < dataRecente!.getTime()) {

      store.notificar(
        'A data de finalização do lote deve ser posterior aos últimos registros do lote (vendas, biometrias, etc...)!',
      );
    } else {

      if (povoamento) {
        await store.lotes.finalizarLotePovoamento(loteID, state.data);
      } else {
        await store.lotes.finalizarLote(loteID, state.data);
      }     
    }
  } catch (err) {
      X9Logger.report(err as Error);
      store.notificar('Ocorreu um erro ao lançar mortalidade');
  }
  handleClose();
  store.toggleLoader();
  onConfirm ? onConfirm() : null;
  async();
}

  const TanquesDisponiveis: React.FC = () => (
    <>
      {lote?.tanques.map((tanque) => {
        return (
          <TanqueInfo2 key={tanque.id}>
     
              <b>{returnTanque(tanque)}</b>
              <p>{Formatter.formatNumber(0, tanque.estoque)} un</p>
              <p style={{ color: '#00C853' }}>
                {Formatter.formatNumber(2, tanque.biomassa / 1000)} Kg
              </p>

          </TanqueInfo2>
        );
      })}
    </>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>Tem certeza que deseja finalizar o lote?</ModalHeader>
      <ModalBody>
        <p style={{ color: 'red' }}>O saldo de animais nos tanques serão registrados como mortalidade!</p>
        <br />
        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data de Finalização"
          value={state.data}
          onChange={(date) => (state.data = date!)}
        />

        <div
          style={{
            color: '#707070',
            width: '100%',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <b>{lote.codigo}</b>
          <b>Biomassa: {Formatter.formatNumber(2, lote.biomassa / 1000)} Kg</b>
        </div>
        <TanquesDisponiveis />
      </ModalBody>
      <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
        <Botao cor="#FC7467" onClick={handleClose}>
          Cancelar
        </Botao>
        <Botao cor="#00C853" onClick={finalizarLote}>
          Finalizar
        </Botao>
      </div>
    </Modal>
  );
};

export default observer(FinalizarLote);
