/**
 * Código baseado do pacote cpf-cnpj-validator
 * https://www.npmjs.com/package/cpf-cnpj-validator
 * 
 */

const BLACKLIST_CNPJ: string[] = [
  '00000000000000',
  '11111111111111',
  '22222222222222',
  '33333333333333',
  '44444444444444',
  '55555555555555',
  '66666666666666',
  '77777777777777',
  '88888888888888',
  '99999999999999'
];

const STRICT_STRIP_REGEX_CNPJ = /[-\\/.]/g;
const LOOSE_STRIP_REGEX_CNPJ = /[^\d]/g;

function verifierDigitCNPJ(digits: string): number {
  let index = 2;
  const reverse = digits.split('').reduce<number[]>((buffer, number) => {
    return [parseInt(number, 10)].concat(buffer);
  }, []);
  const sum = reverse.reduce((buffer, number) => {
    buffer += number * index;
    index = index === 9 ? 2 : index + 1;
    return buffer;
  }, 0);
  const mod = sum % 11;
  return mod < 2 ? 0 : 11 - mod;
}

function stripCNPJ(number: string, strict: boolean = false): string {
  const regex = strict ? STRICT_STRIP_REGEX_CNPJ : LOOSE_STRIP_REGEX_CNPJ;
  return (number || '').replace(regex, '');
}

function formatCNPJ(number: string): string {
  return stripCNPJ(number).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
}

function isValidCNPJ(number: string, strict: boolean = false): boolean {
  const stripped = stripCNPJ(number, strict);
  if (!stripped) {
    return false;
  }
  if (stripped.length !== 14) {
    return false;
  }
  if (BLACKLIST_CNPJ.includes(stripped)) {
    return false;
  }
  let numbers = stripped.slice(0, 12);
  numbers += verifierDigitCNPJ(numbers);
  numbers += verifierDigitCNPJ(numbers);
  return numbers.slice(-2) === stripped.slice(-2);
}

function generateCNPJ(formatted: boolean = false): string {
  let numbers = '';
  for (let i = 0; i < 12; i += 1) {
    numbers += Math.floor(Math.random() * 9);
  }
  numbers += verifierDigitCNPJ(numbers);
  numbers += verifierDigitCNPJ(numbers);
  return formatted ? formatCNPJ(numbers) : numbers;
}

export const cnpjValidator = {
  verifierDigit: verifierDigitCNPJ,
  strip: stripCNPJ,
  format: formatCNPJ,
  isValid: isValidCNPJ,
  generate: generateCNPJ
};