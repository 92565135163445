import React from 'react';
import { PDFContainer } from './styles';
import { Flex } from '../../../components/NavBar/styles';
import MaterialTable, { Column } from 'material-table';
import { newConfig } from '../../../components/Tabela';
import Formatter from '../../../services/Formatter';

type Props = {
  propriedade: string;
  ano: number;
  rows: FluxoRow[];
};

const RelatorioFluxoDeCaixa: React.FC<Props> = ({ propriedade, ano, rows }) => {
  const columns: Column<FluxoRow>[] = [
    {
      field: 'name',
      title: 'Categorias',
      type: 'string',
    },
    {
      field: 'janeiro',
      title: 'Jan',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.janeiro);
      },
    },
    {
      field: 'fevereiro',
      title: 'Fev',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.fevereiro);
      },
    },
    {
      field: 'março',
      title: 'Mar',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.março);
      },
    },
    {
      field: 'abril',
      title: 'Abr',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.abril);
      },
    },
    {
      field: 'maio',
      title: 'Mai',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.maio);
      },
    },
    {
      field: 'junho',
      title: 'Jun',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.junho);
      },
    },
    {
      field: 'julho',
      title: 'Jul',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.julho);
      },
    },
    {
      field: 'agosto',
      title: 'Ago',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.agosto);
      },
    },
    {
      field: 'setembro',
      title: 'Set',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.setembro);
      },
    },
    {
      field: 'outubro',
      title: 'Out',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.outubro);
      },
    },
    {
      field: 'novembro',
      title: 'Nov',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.novembro);
      },
    },
    {
      field: 'dezembro',
      title: 'Dez',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.dezembro);
      },
    },
    {
      field: 'total',
      title: 'Total',
      type: 'numeric',
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.total);
      },
    },
  ];

  const Tables: React.FC = () => {
    return (
      <div>
        <MaterialTable
          columns={columns}
          data={rows}
          options={{
            ...newConfig.options,
            paging: false,
            maxBodyHeight: 'none',
            minBodyHeight: 'none',
            headerStyle: {
              color: '#000000',
              fontSize: 10,
            },
          }}
          localization={newConfig.localization}
          style={{ ...newConfig.style, fontSize: 10 }}
        />
        <br />
      </div>
    );
  };

  return (
    <PDFContainer>
      <Flex>
        <img src="/images/aqrelatorios.png" alt="Aquabit Relatórios" />
        <h2>Aquabit - Fluxo de Caixa - {ano}</h2>
      </Flex>

      <br />

      <div
        style={{
          width: '100%',
          display: 'grid',
          justifyContent: 'space-between',
          gridTemplateColumns: '1fr 1fr',
          gap: 10,
        }}
      >
        <p>
          <b>Propriedade:</b> {propriedade}
        </p>
      </div>

      <br />

      <Tables />
    </PDFContainer>
  );
};

export default RelatorioFluxoDeCaixa;
