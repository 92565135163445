import React from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import Botao from '../../../components/Botoes/Botao';
import useStore from '../../../services/hooks/useStore';
import { api } from '../../../services/utils';
import { useLocalStore, observer } from 'mobx-react-lite';
import useModal from '../../../services/hooks/useModal';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { KeyboardDatePicker } from '@material-ui/pickers';
import InputNumero from '../../../components/InputNumero';
import SelecionarFornecedor from './SelecionarFornecedor';
import RegistrarDespesa from '../../../components/Modais/RegistrarDespesa';
import Select from '../../../components/Select';
import Formatter from '../../../services/Formatter';

type State = {
  racao: SelectRow | null;
  fornecedor: SelectRow | null;
  data: MaterialUiPickersDate;
  valor: number;
  quantidade: number;

  selectFornecedor: boolean;
};
type Props = ModalProps & {
  compraID: number;
  handleUpdate?: () => void;
};
const AddCompra: React.FC<Props> = ({ open, handleClose, compraID, handleUpdate }) => {
  const store = useStore();
  const compra = store.comprasDeRacoes.arr ? store.comprasDeRacoes.get(compraID) : null;
  const modalDespesa = useModal();
  const modalFornecedor = useModal();
  const state = useLocalStore(
    (): State => ({
      racao: compra && compra.racao ? { label: compra.racao.nome, value: compra.racao.id.toString() } : null,
      fornecedor:
        compra && compra.fornecedor
          ? { label: compra?.fornecedor?.nome, value: compra.fornecedor.id.toString() }
          : null,
      data:
        compra && compra.data
          ? new Date(compra.data.substr(3, 2) + '/' + compra.data.substr(0, 2) + '/' + compra.data.substr(6, 4))
          : new Date(),
      valor: compra && compra.movimentacao ? compra.movimentacao.valor_total : 0,
      quantidade: compra && compra.total_kg ? compra.total_kg : 0,

      selectFornecedor: false,
    }),
  );

  async function cadastrar(despesa: any): Promise<void> {
    handleClose();
    store.toggleLoader();

    const valorTotal = Number(state.valor.toFixed(3));
    const data = {
      propriedade: store.propriedade!.id,
      racao: parseInt(state.racao!.value),
      fornecedor: parseInt(state.fornecedor!.value),
      data: Formatter.dateToString(state.data),
      valor_compra: valorTotal,
      total_kg: state.quantidade,
      justificativa: 'Compra',
    };

    await api
      .post(`/compra-racao/?propriedade=${store.propriedade!.id}`, data)
      .then(async (res) => {
        const movData = {
          propriedade: store.propriedade!.id,
          data: despesa.data,
          descricao: 'Compra de ração',
          conta: despesa.conta,
          categoria: despesa.categoria,
          situacao: despesa.pago,
          lote_tanque_movimentacao: [],
          fornecedor: parseInt(state.fornecedor!.value),
          quantidade: state.quantidade,
          valor_total: valorTotal,
          valor_unitario: parseFloat((valorTotal / state.quantidade).toFixed(3)),
          racao: parseInt(state.racao!.value),
          parcelas: despesa.parcelas,
          documento: despesa.documento,
        };

        await api.post(`/movimentacao/?propriedade=${store.propriedade!.id}`, movData).then(async (movRes) => {
          await api
            .patch(`/compra-racao/${res.data.id}/?propriedade=${store.propriedade!.id}`, {
              movimentacao: movRes.data.id,
            })
            .then(() => {
              store.notificar('Compra de ração registrada com sucesso!');
            })
            .catch(async () => {
              await api.delete(`/movimentacao/${movRes.data.id}/?propriedade=${store.propriedade!.id}`);
              await api.delete(`/compra-racao/${res.data.id}/?propriedade=${store.propriedade!.id}`);
              store.notificar('Ocorreu um erro ao registrar a compra, tente novamente ou contate nosso suporte.');
            });
        });
      })
      .catch(() => {
        store.notificar('Ocorreu um erro ao registrar a compra, tente novamente ou contate nosso suporte.');
      });

    if (handleUpdate) await handleUpdate();
    Promise.all([store.movimentacoes.populate(), store.contas.populate(), store.racoes.populate()]).then(() => {
      store.toggleLoader();
    });
  }

  async function editar(): Promise<void> {
    handleClose();
    store.toggleLoader();

    const valorTotal = Number(state.valor.toFixed(3));

    const data = {
      propriedade: store.propriedade!.id,
      racao: parseInt(state.racao!.value),
      fornecedor: parseInt(state.fornecedor!.value),
      data: Formatter.dateToString(state.data),
      valor_compra: valorTotal,
      total_kg: state.quantidade,
      justificativa: 'Compra',
      movimentacao: compra!.movimentacao.id,
    };

    await api
      .patch('/compra-racao/' + compra!.id + '/', data)
      .then(async () => {
        await api.patch(`/movimentacao/${compra!.movimentacao.id}/?propriedade=${store.propriedade!.id}`, {
          fornecedor: parseInt(state.fornecedor!.value),
        });
        store.notificar('Compra editada com sucesso!');
      })
      .catch(() => store.notificar('Ocorreu um erro ao editar a compra!'));

    if (handleUpdate) await handleUpdate();
    Promise.all([store.movimentacoes.populate(), store.contas.populate(), store.racoes.populate()]).then(() => {
      store.toggleLoader();
    });
  }
  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{compra ? 'Editar' : 'Nova'} compra de ração</ModalHeader>
      <ModalBody>
        <Select
          value={state.racao}
          placeholder="Nome da ração"
          onChange={(e: any) => (state.racao = e)}
          options={store.racoes.getSelectRows()}
        />
        <Select
          value={state.fornecedor}
          placeholder="Fornecedor"
          onChange={(e: any) => (state.fornecedor = e)}
          options={store.fornecedores!.getSelectRows()}
          onFocus={(e) => {
            e.target.blur();
            state.selectFornecedor = false;
            modalFornecedor.open();
          }}
        />

        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data"
          value={state.data}
          onChange={(date) => (state.data = date)}
        />

        <InputNumero
          disabled={!!compra}
          value={state.valor}
          onChange={(value) => (state.valor = value)}
          precision="2"
          label="Valor da compra (R$)"
        />
        <InputNumero
          disabled={!!compra}
          value={state.quantidade}
          onChange={(value) => (state.quantidade = value)}
          precision="2"
          label="Quantidade (kg)"
        />

        <div style={{ margin: 10, textAlign: 'center' }}>
          <h2>Valor do quilo</h2>
          <h1 style={{ color: '#42a5f5' }}>
            R${' '}
            {!isNaN(state.valor / state.quantidade) && isFinite(state.valor / state.quantidade)
              ? Formatter.formatNumber(2, state.valor / state.quantidade)
              : '0,00'}
          </h1>
        </div>

        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao
            onClick={() => {
              if (!state.racao) console.log(state.valor.toFixed(3));
              else if (!state.fornecedor) store.notificar('Informe o fornecedor!');
              else if (state.valor === 0) store.notificar('Informe o valor!');
              else if (state.quantidade === 0) store.notificar('Informe a quantidade!');
              else {
                if (compra) {
                  editar();
                } else {
                  modalDespesa.open();
                }
              }
            }}
            variant="contained"
            cor="#00C853"
          >
            Salvar
          </Botao>
        </div>
      </ModalBody>
      {modalDespesa.state && (
        <RegistrarDespesa
          data={state.data}
          onConfirm={cadastrar}
          open={modalDespesa.state}
          handleClose={modalDespesa.close}
          categoria
          editar={!!compraID}
          valorTotal={state.valor}
        />
      )}
      <SelecionarFornecedor
        onConfirm={(fornecedor: SelectRow) => {
          modalFornecedor.close();
          state.fornecedor = fornecedor;
        }}
        open={modalFornecedor.state}
        handleClose={modalFornecedor.close}
      />
    </Modal>
  );
};

export default observer(AddCompra);
