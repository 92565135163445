import React from 'react';
import Formatter from '../../services/Formatter';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import { Paper, Tooltip, Typography } from '@material-ui/core';

type Props = {
  historico: Historico3[];
};

const Historico: React.FC<Props> = ({ historico }) => {
  return (
    <Timeline align="alternate">
      {historico &&
        historico.map((item, index) => {
          switch (item.tipo) {
            case 'povoamento':
              return (
                <TimelineItem key={index}>
                  <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                      {item.data.split(' ')[0]}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: '#EC7722', padding: '15px' }}></TimelineDot>
                    {index !== historico.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper
                      elevation={3}
                      style={{
                        padding: '6px 16px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="h6" component="h1" style={{ color: '#EC7722' }}>
                          Povoamento
                        </Typography>
                        <Typography style={{ color: ' #808080', fontWeight: 'bold', fontSize: '.9rem' }}>
                          {item.tanque_nome || item.tanque_codigo}
                        </Typography>
                      </div>
                      <Typography>
                        {Formatter.formatNumber(0, item.quantidade)} un - {item.peso} g
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              );
            case 'biometria':
              return (
                <TimelineItem key={index}>
                  <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                      {item.data.split(' ')[0]}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: '#049CE7', padding: '15px' }}></TimelineDot>
                    {index !== historico.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper
                      elevation={3}
                      style={{
                        padding: '6px 16px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="h6" component="h1" style={{ color: '#049CE7' }}>
                          Biometria
                        </Typography>
                        <Typography style={{ color: ' #808080', fontWeight: 'bold', fontSize: '.9rem' }}>
                          <Tooltip
                            title={item.tanques.map((tanque) => {
                              return `${tanque.codigo || tanque.nome}, `;
                            })}
                          >
                            <p>
                              {item.tanques.length > 0
                                ? `${item.tanques[0].nome ? item.tanques[0].nome : item.tanques[0].codigo}, ...`
                                : ''}
                            </p>
                          </Tooltip>
                        </Typography>
                      </div>
                      <Typography>
                        {item.amostras} amostra(s) - {Formatter.formatNumber(3, item.peso_medio)} g
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              );
            case 'transferencia':
              return (
                <TimelineItem key={index}>
                  <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                      {item.data.split(' ')[0]}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: '#5F5D5B', padding: '15px' }}></TimelineDot>
                    {index !== historico.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper
                      elevation={3}
                      style={{
                        padding: '6px 16px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="h6" component="h1" style={{ color: " '#5F5D5B'" }}>
                          Transferência
                        </Typography>
                      </div>
                      <Typography>
                        {Formatter.formatNumber(0, item.quantidade)} un - Origem:{' '}
                        {item.tanque_origem_nome || item.tanque_origem_codigo} - Destino:{' '}
                        {item.tanque_destino_nome || item.tanque_destino_codigo}
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              );
            case 'transferencia_saida':
              return (
                <TimelineItem key={index}>
                  <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                      {item.data.split(' ')[0]}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: '#5F5D5B', padding: '15px' }}></TimelineDot>
                    {index !== historico.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper
                      elevation={3}
                      style={{
                        padding: '6px 16px',
                      }}
                    >
                      <Typography variant="h6" component="h1" style={{ color: " '#5F5D5B'" }}>
                        Transferência Saida
                      </Typography>
                      <Typography>
                        {Formatter.formatNumber(0, item.quantidade)} un - {item.tanque_nome || item.tanque_codigo}
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              );
            case 'morte':
              return (
                <TimelineItem key={index}>
                  <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                      {item.data.split(' ')[0]}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: '#049CE7', padding: '15px' }}></TimelineDot>
                    {index !== historico.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper
                      elevation={3}
                      style={{
                        padding: '6px 16px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="h6" component="h1" style={{ color: ' #F44336' }}>
                          Mortalidade
                        </Typography>
                        <Typography style={{ color: ' #808080', fontWeight: 'bold', fontSize: '.9rem' }}>
                          {item.tanque_nome || item.tanque_codigo}
                        </Typography>
                      </div>
                      <Typography>
                        {Formatter.formatNumber(0, item.quantidade)} un - {item.causa}
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              );
            case 'vendas':
              return (
                <TimelineItem key={index}>
                  <TimelineOppositeContent>
                    <Typography variant="body2" style={{ color: ' #808080', fontWeight: 'bold' }}>
                      {item.data.split(' ')[0]}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: '#00C853', padding: '15px' }}></TimelineDot>
                    {index !== historico.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper
                      elevation={3}
                      style={{
                        padding: '6px 10px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="h6" component="h1" style={{ color: ' #00C853' }}>
                          Vendas
                        </Typography>
                        <Typography style={{ color: ' #808080', fontWeight: 'bold', fontSize: '.9rem' }}>
                          {item.tanque_nome || item.tanque_codigo}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography style={{ fontSize: '.9rem' }}>
                          {Formatter.formatNumber(2, item.quantidade).replace(',00', '')} un (PM:{' '}
                          {Formatter.formatNumber(3, item.peso_medio)}g)
                        </Typography>
                        <Typography style={{ color: ' #808080', fontWeight: 'bold', fontSize: '.9rem' }}>
                          Biomassa: {Formatter.formatNumber(3, item.biomassa / 1000)}Kg
                        </Typography>
                      </div>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              );

            default:
              return null;
          }
        })}
    </Timeline>
  );
};

export default Historico;
