import { observable, action, computed } from 'mobx';
import { api } from './utils';
import { fakeUsuario } from './fakeData';
import LoteTanques from './models/LoteTanques';
import Mortalidades from './models/Mortalidades';
import Transferencias from './models/Transferencias';
import Movimentacoes from './models/Movimentacoes';
import GenericTypes from './models/GenericTypes';
import CategoriasFinanceiras from './models/CategoriasFinanceiras';
import ContasBancarias from './models/ContasBancarias';
import Fornecedores from './models/Fornecedores';
import Clientes from './models/Clientes';
import Cidades from './models/Cidades';
import CompraDeRacoes from './models/ComprasDeRacoes';
import CompraDeInsumos from './models/CompraDeInsumos';
import Tanques from './models/Tanques';
import Lotes from './models/Lotes';
import Vendas from './models/Vendas';
import Biometrias from './models/Biometrias';
import Arracoamentos from './models/Arracoamentos';
import Racoes from './models/Racoes';
import Parametros from './models/Parametros';
import QualidadesDaAgua from './models/QualidadesDaAgua';
import ListaSemanaTanque from './models/ListaSemanaTanques';
import Insumos from './models/Insumos';
import Temperatura from './models/Temperaturas';
import Cheques from './models/Cheques';
import Usuarios from './models/Usuarios';
import Rateios from './models/Rateios';
import Marcas from './models/Marcas';
import Reservatorios from './models/Reservatorios';
import PerfilPesoMedioDespesca from './models/PerfilPesoMedioDespesa';
import AguasDeReservatorios from './models/AguaDeReservatorios';
class State {
  @observable token = localStorage.getItem('device_token');
  @observable usuario: User = fakeUsuario;
  @observable logado = false;
  @action setLogado = (userData: User): void => {
    this.logado = true;
    this.usuario = userData;
  };

  @observable notification = '';
  /**
   * Função responsável por definir o conteúdo da notificaçao ou limpá-la
   * @param message Alertas, confirmações, etc...
   */
  @action notificar = (message: string): void => {
    this.notification = message;
  };

  @observable titulo = '';
  @action setTitulo = (value: string): void => {
    this.titulo = value;
  };

  @observable drawer = false;
  @action toggleDrawer = (): void => {
    this.drawer = !this.drawer;
  };
  /**
   * Variável que define se o ponteiro do mouse está no Drawer
   * @todo Implementar `ClickAway Listener`
   */
  @observable onDrawer = false;
  @action toggleOnDrawer = (): void => {
    this.onDrawer = !this.onDrawer;
  };
  /**
   * Função responsável por controlar o estado do Drawer de acordo com a posição do mouse.
   */
  @action checkClick = (): void => {
    if (!this.onDrawer && this.drawer) {
      this.toggleDrawer();
    }
    if (this.onDrawer && !this.drawer) {
      this.toggleDrawer();
    }
  };

  @observable loader = true;
  @action toggleLoader = (): void => {
    this.loader = !this.loader;
  };
  @observable modalAlterarSenha = false;
  @action toggleModalAlterarSenha = (): void => {
    this.modalAlterarSenha = !this.modalAlterarSenha;
  };

  @observable colors = ['#049CE7', '#FFE600', '#FE196B', '#465A65'];

  /**
   * Variável contendo os dados da propriedade atual
   */
  @observable propriedade: Propriedade | null = null;
  /**
   * Função responsável por pegar os dados da propriedade atual e expor no estado
   * @param id ID da propriedade
   */
  @action setPropriedade = async (id: number, token?: string): Promise<void> => {
    if (!token) {
      const request = await api.get('/propriedades/' + id + '/');

      this.propriedade = request.data;
    } else {
      const request = await api.get('/propriedades/' + id + '/', {
        headers: {
          Authorization: token,
        },
      });
      this.propriedade = request.data;
    }
  };

  /**
   * Puxa novas informações do usuário
   */
  @action async refreshUser(): Promise<void> {
    await api.get('/auth/me/').then(async (res) => {
      this.setLogado(res.data);
      await this.setPropriedade(this.usuario?.propriedades?.filter((propriedade) => propriedade.ativo)[0].id);
    });
  }

  @action checkPermissions = (perm: string): boolean => {
    const permissoesUsuario = this.usuario?.permissoes.filter(
      (permissao) => permissao?.propriedade?.id === this.propriedade?.id,
    );
    const permissao = permissoesUsuario.length > 0 ? permissoesUsuario[0] : null;
    const temPermissao = permissao ? permissao?.permissoes.some((permissao) => permissao.slug === perm) : false;

    return temPermissao;
  };

  @action validarPermissao = (perm: string, history: any): void => {
    if (!this.checkPermissions(perm)) {
      this.notificar('Você não tem permissão para acessar este módulo!');
      history.push('/home');
    }
  };

  /**
   * Requests fundamentais
   * Ex: Tanques, Movimentações, Transferências
   */

  @observable tanques = new Tanques();
  @observable lotes = new Lotes();
  @observable racoes = new Racoes();
  @observable comprasDeRacoes = new CompraDeRacoes();
  @observable arracoamentos = new Arracoamentos();
  @observable fornecedores = new Fornecedores();
  @observable loteTanque = new LoteTanques();
  @observable reservatorios = new Reservatorios();

  @observable listaSemanaTanque = new ListaSemanaTanque();

  @observable mortalidades = new Mortalidades();
  @observable transferencias = new Transferencias();
  @observable movimentacoes = new Movimentacoes();
  @observable biometrias = new Biometrias();
  @observable clientes = new Clientes();
  @observable temperatura = new Temperatura();
  @observable vendas = new Vendas();
  @observable categorias = new CategoriasFinanceiras();
  @observable contas = new ContasBancarias();
  @observable cheques = new Cheques();
  @observable cidades = new Cidades();
  @observable qualidadeDaAgua = new QualidadesDaAgua();
  @observable parametroValor = new Parametros();
  @observable perfilPesoMedioDespesca = new PerfilPesoMedioDespesca();  
  @observable aguaDeReservatorios = new AguasDeReservatorios();

  @observable tiposDeMorte = new GenericTypes('/tipo-morte/');
  @observable tiposDeFornecedor = new GenericTypes('/fornecedor-tipo/');
  @observable tiposDePagamento = new GenericTypes('/tipo-de-pagamento/');
  @observable tiposDeCliente = new GenericTypes('/tipo-cliente/');
  @observable tiposDeTanque = new GenericTypes('/tanque-tipo/');
  @observable tiposDeUsuario = new GenericTypes('/tipo-de-usuario/');
  @observable situacoesDeTanque = new GenericTypes('/situacao-tanque/');
  @observable origensDaAgua = new GenericTypes('/agua-origem/');
  @observable fasesLote = new GenericTypes('/fase-lote/');
  @observable especies = new GenericTypes('/especie-explorada/');
  @observable especiesAquaponia = new GenericTypes('/especie-explorada/?aquaponia=true');
  @observable atividades = new GenericTypes('/atividade-propriedade/');
  @observable estados = new GenericTypes('/estados/');
  @observable dados = new GenericTypes('/cliente/dados/');

  @observable comprasDeInsumos = new CompraDeInsumos();
  @observable insumos = new Insumos();
  @observable usuarios = new Usuarios();
  @observable rateios = new Rateios();
  @observable marcas = new Marcas();

  @computed
  get propriedades(): Propriedade[] {
    return this.usuario?.propriedades;
  }
}

export default State;
