import { stateContext } from './hooks/useStore';
import { newApi } from './utils';

class X9Logger {
  report(err: Error): void {
    if (
      window.location.origin === 'https://homologacao.aquabitfarm.com' ||
      window.location.origin === 'https://web.aquabitfarm.com' ||
      window.location.origin === 'https://web.aquabit.com.br'
    ) {
      const data = {
        origem: window.location.origin,
        client: 'aquabit-v3',
        errorName: err.name,
        errorMessage: err.message,
        usuario: stateContext.state.usuario.inscricao_federal,
        propriedade: stateContext.state.propriedade!.nome + ' - ' + stateContext.state.propriedade!.id,
        path: window.location.pathname,
      };

      newApi.post('/report/slack', data);
    }
  }
}

export default new X9Logger();
