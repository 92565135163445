import { api } from '../utils';
import { stateContext } from '../hooks/useStore';
import X9Logger from '../X9Logger';

/**
 * Função temporária para calulcar a quantidade de animais existentes em vários lote_tanque
 * @param loteTanqueLote
 * @param isSum Existe `__sum` nas propriedades?
 */
export function returnQuantidade(loteTanqueLote: LoteTanque[] | LoteTanque, isSum: boolean): number {
  let sum = 0;
  const index = isSum ? 'quantidade__sum' : 'quantidade';

  if (Array.isArray(loteTanqueLote)) {
    if (loteTanqueLote.length > 0) {
      loteTanqueLote.map((item) => {
        if (item.quantidade) {
          return (sum +=
            item.quantidade[index]! +
            item.recebido[index]! -
            item.transferido[isSum ? index : 'total']! -
            item.mortalidade[index]! -
            item.vendido[index]!);
        } else {
          return (sum +=
            item.povoamento[index]! +
            item.recebido[index]! -
            item.transferido[isSum ? index : 'total']! -
            item.mortalidade[index]! -
            item.vendido[index]!);
        }
      });
    }
  } else {
    const qtdOri = loteTanqueLote.quantidade ? loteTanqueLote.quantidade : loteTanqueLote.povoamento;
    sum +=
      qtdOri[index]! +
      loteTanqueLote.recebido[index]! -
      loteTanqueLote.transferido[isSum ? index : 'total']! -
      loteTanqueLote.mortalidade[index]! -
      loteTanqueLote.vendido[index]!;
  }

  return sum;
}

export function returnMediaAmostragens(amostragens: Amostragem[]): number {
  let sum = 0;
  const length = amostragens.length;

  amostragens.map((amostragem) => (sum += amostragem.media));

  return sum / length;
}

export async function deletarLote(lote: Lote): Promise<void> {
  const store = stateContext.state;

  try {
    store.toggleLoader();

    await api.delete(`/lote/${lote.uuid}/?propriedade=${store.propriedade?.id}`);
    await store.lotes.populate();
    store.notificar('Lote excluído com sucesso!');
  } catch (error: any) {
    X9Logger.report(error as Error);
    error.response && store.notificar(error.response.data.erro);
  } finally {
    store.toggleLoader();
  }
}
