import styled from 'styled-components';

export const NormalValue = styled.span`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  text-shadow: #ccc 1px 1px 1px;
`;

export const NoneValue = styled(NormalValue)`
  color: yellow;
`;

export const OutlierValue = styled(NormalValue)`
  color: red;
`;
