import React from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { IconButton } from '@material-ui/core';
import { useLocalStore, observer } from 'mobx-react-lite';
import useStore from '../../../services/hooks/useStore';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Botao from '../../../components/Botoes/Botao';
import SelecionarTanque from './SelecionarTanque';
import { Flex } from '../../../components/NavBar/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddAmostragem from './AddAmostragem';
import { apiV2 } from '../../../services/utils';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import useModal from '../../../services/hooks/useModal';
import Select from '../../../components/Select';
import SelecionarLote from '../../../components/Modais/SelecionarLote';
import { returnQuantidade } from '../../../services/utils/producao';
import Formatter from '../../../services/Formatter';
import EditPesoMedio from './EditPesoMedio';
import SelecionarTanqueUnico from './SelecionarTanqueUnico';

type Amostragem = {
  id?: number;
  lote_tanque: number;
  tara: number;
  qtd_peixes: number;
  peso: number;
  media: number;
};

type State = {
  tanque: SelectRow | null;
  lote: SelectRow | null;
  data: MaterialUiPickersDate;
  tanques: SelectedTanque[];
  resposta: Amostragem[];
  amostragens: object[];
  select: {
    index: number;
    peso_medio: number;
  };
  media: number;
  selectLote: boolean;
};
type Props = ModalProps & {
  onConfirm(): void;
  biometria: Biometria;
};
const FormBiometria: React.FC<Props> = ({ open, handleClose, onConfirm, biometria }) => {
  const store = useStore();

  const state = useLocalStore(
    (): State => ({
      lote: biometria && biometria.lote ? { value: biometria.lote.id.toString(), label: biometria.lote.codigo } : null,
      tanque:
        biometria && biometria.tanque
          ? { value: biometria.tanque.id.toString(), label: biometria.tanque.codigo }
          : null,
      resposta:
        biometria && biometria.historico_amostragens
          ? (biometria.historico_amostragens.map((lote_tanque) => {
              return {
                id: lote_tanque.id,
                lote_tanque:
                  biometria.lote_tanques.length > 0
                    ? biometria.lote_tanques.filter((item) => item.id === lote_tanque.lote_tanque)[0].tanque.id
                    : '',
                tara: lote_tanque.tara,
                qtd_peixes: lote_tanque.qtd_peixes,
                peso: lote_tanque.peso,
                media: lote_tanque.media,
              };
            }) as Amostragem[])
          : [],
      data:
        biometria && biometria.data
          ? new Date(
              biometria.data.substr(3, 2) + '/' + biometria.data.substr(0, 2) + '/' + biometria.data.substr(6, 4),
            )
          : new Date(),
      tanques:
        biometria && biometria.lote_tanques
          ? biometria.lote_tanques.map((loteTanque) => {
              return { item: store.tanques.filter((item) => item.id === loteTanque.tanque.id)[0] };
            })
          : [],
      amostragens: biometria && biometria.historico_amostragens ? biometria.historico_amostragens : [],
      media: biometria && biometria.peso_medio ? biometria.peso_medio.media__avg : 0,
      select: {
        index: 0,
        peso_medio: 0,
      },
      selectLote: false,
    }),
  );
  const modalAmostragem = useModal();
  const modalTanques = useModal();
  const modalSelecionarLote = useModal();
  const modalSelecionarTanqueUnico = useModal();
  const modalEditPesoMedio = useModal();

  function addTanque(tanqueID: number): void {
    if (state.tanques.filter((tanque) => tanque.item.id === tanqueID).length === 0) {
      const tanque = { item: store.tanques.get(tanqueID)! };

      const amostragem = {
        lote_tanque: store.tanques.get(tanqueID).id,
        tara: 0,
        qtd_peixes: 0,
        peso: 0,
        media: 0,
      };
      const tanques = [...state.tanques, tanque];
      const amostragens = [...state.resposta, amostragem] as Amostragem[];
      state.resposta = amostragens;
      state.tanques = tanques;
    }
  }
  function removeTanque(tanqueID: number): void {
    state.tanques = state.tanques.filter((tanque) => tanque.item.id !== tanqueID);
    state.resposta = state.resposta.filter((tanque: any) => tanque.lote_tanque !== tanqueID);
  }
  function calcularMedia(): void {
    let soma = 0;

    state.amostragens.map((amostragem: any) => (soma += amostragem.media));

    if (isNaN(soma / state.amostragens.length)) {
      state.media = 0;
    } else {
      state.media = soma / state.amostragens.length;

      state.resposta.map((amostragem) => (amostragem.media = state.media));
    }
  }
  function addAmostragem(tara: number, qtd_peixes: number, peso: number, media: number): void {
    const amostragem = {
      tara,
      qtd_peixes,
      peso,
      media,
    };

    state.resposta.map((item: any) => {
      item.tara = tara;
      item.peso = peso;
      item.qtd_peixes = qtd_peixes;
      item.media = media;
    });

    state.amostragens.push(amostragem);
    calcularMedia();
    modalAmostragem.close();
  }
  function removeAmostragem(amostragemDel: object): void {
    state.amostragens = state.amostragens.filter((amostragem) => amostragem !== amostragemDel);
    calcularMedia();
  }
  async function cadastrar(): Promise<void> {
    if (!state.lote && !state.tanque) store.notificar('Selecione um lote ou um tanque!');
    else if (state.tanques.length === 0 && !state.tanque) store.notificar('Selecione os tanques!');
    else if (state.amostragens.length === 0) store.notificar('Adicione uma amostragem!');
    else {
      handleClose();
      store.toggleLoader();

      const loteId = Number(state.lote?.value ?? loteDoTanqueSelecionadoOption?.value ?? -1);
      state.resposta.map((tanque) => {
        const loteTanque = store.loteTanque
          .getByTanque(tanque.lote_tanque)
          ?.filter((loteTanque) => loteTanque.lote.id === loteId)[0];
        if (loteTanque) return (tanque.lote_tanque = loteTanque.id);
        else return null;
      });

      const amostragens: Amostragem[] = [];

      // Checa se há biometria para editar
      if (biometria) {
        state.resposta.map((amostragem: any) => {
          return amostragens.push({
            id: amostragem.id,
            tara: Math.abs(amostragem.tara),
            // peso: parseFloat((amostragem.media * amostragem.qtd_peixes).toFixed(3)),
            peso: parseFloat(amostragem.peso.toFixed(3)),
            lote_tanque: Math.abs(amostragem.lote_tanque),
            qtd_peixes: Math.trunc(Math.abs(amostragem.qtd_peixes)),
            media: Math.abs(amostragem.media.toFixed(3)),
          });
        });
      } else {
        state.resposta.map((amostragem: any) => {
          // const peso = (): number => {
          //   if (typeof amostragem.peso === 'number' && typeof amostragem.tara === 'number') {
          //     return amostragem.peso - amostragem.tara;
          //   } else if (typeof amostragem.peso === 'string' && typeof amostragem.tara === 'string') {
          //     return amostragem.peso - amostragem.tara;
          //   } else return 0;
          // };
          amostragens.push({
            tara: Math.abs(amostragem.tara),
            // peso: parseFloat(Math.abs(peso()).toFixed(3)),
            peso: parseFloat(Math.abs(amostragem.peso).toFixed(3)),
            lote_tanque: Math.abs(amostragem.lote_tanque),
            qtd_peixes: Math.trunc(Math.abs(amostragem.qtd_peixes)),
            media: Math.abs(amostragem.media.toFixed(3)),
          });
        });
      }

      const data = {
        data: Formatter.dateToString(state.data),
        amostragem: amostragens,
      };

      if (biometria) {
        // eslint-disable-next-line
        await apiV2
          .patch(`/biometria/${biometria.id}/?propriedade=${store.propriedade?.id}`, data)
          .then(async () => {
            await store.biometrias.populate();
            store.notificar('Biometria editada com sucesso!');
            onConfirm();
          })
          .catch(() => {
            store.notificar('Ocorreu um erro na edição, tente novamente!');
          });
      } else {
        await apiV2
          .post(`/biometria/?propriedade=${store.propriedade?.id}`, data)
          .then(async () => {
            await store.biometrias.populate();
            store.notificar('Biometria cadastrada com sucesso!');
            onConfirm();
          })
          .catch(() => {
            store.notificar('Ocorreu um erro no cadastro, tente novamente!');
          });
      }
      store.toggleLoader();
    }
  }

  const TanquesSelecionados: React.FC = () => (
    <>
      {state.tanques && !biometria
        ? state.tanques.map((tanque: any) => {
            const index = state.resposta.findIndex((item: any) => item.lote_tanque === tanque.item.id);

            return (
              <Flex style={{ width: '100%', justifyContent: 'space-between' }} key={tanque.item.id}>
                <p>{tanque.item.nome || tanque.item.codigo}</p>
                <p>{Formatter.formatNumber(3, state.resposta[index].media)}</p>
                <div>
                  <IconButton
                    onClick={() => {
                      state.select = {
                        index: index,
                        peso_medio: state.resposta[index].media,
                      };
                      modalEditPesoMedio.open();
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => removeTanque(tanque.item.id)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Flex>
            );
          })
        : state.tanques.reverse().map((tanque: any) => {
            const index = state.resposta.findIndex((item: any) => item.lote_tanque === tanque.item.id);

            return (
              <Flex style={{ width: '100%', justifyContent: 'space-between' }} key={tanque.item.id}>
                <p>{tanque.item.nome || tanque.item.codigo}</p>
                <p>{Formatter.formatNumber(3, state.resposta[index].media)}</p>
                <div>
                  <IconButton
                    onClick={() => {
                      state.select = {
                        index: index,
                        peso_medio: state.resposta[index].media,
                      };
                      modalEditPesoMedio.open();
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => removeTanque(tanque.item.id)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Flex>
            );
          })}
    </>
  );

  const ocultarTanquesSelecionados = !state.tanque;
  const loteTanqueDoTanqueSelecionado = store.loteTanque.getByTanque(
    state.tanque?.value ? Number(state.tanque?.value) : -1,
  );
  let loteDoTanqueSelecionadoOption: SelectRow | null = null;
  if (loteTanqueDoTanqueSelecionado && loteTanqueDoTanqueSelecionado.length > 0) {
    loteDoTanqueSelecionadoOption = {
      value: loteTanqueDoTanqueSelecionado[0].lote.id.toString(),
      label: loteTanqueDoTanqueSelecionado[0].lote.codigo,
    };
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{biometria ? 'Editar' : 'Nova'} biometria</ModalHeader>
      <ModalBody>
        <div style={{ width: '540px' }}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data"
            value={state.data}
            onChange={(date) => (state.data = date)}
          />

          <div style={{ width: '100%' }}>
            {store.tanques.arr && (
              <Select
                value={state.tanque}
                placeholder="Escolha o tanque"
                onChange={(e: any) => (state.tanque = e)}
                onFocus={(e) => {
                  e.target.blur();
                  modalSelecionarTanqueUnico.open();
                }}
                options={store.tanques.map((lote) => {
                  return { value: lote.id.toString(), label: lote.codigo };
                })}
                disabled={Boolean(state.lote)}
              />
            )}
          </div>

          <div style={{ width: '100%' }}>
            {store.lotes.arr && (
              <Select
                value={state.lote}
                placeholder="Escolha o lote"
                onChange={(e: any) => (state.lote = e)}
                onFocus={(e) => {
                  e.target.blur();
                  modalSelecionarLote.open();
                }}
                options={store.lotes.map((lote) => {
                  return { value: lote.id.toString(), label: lote.codigo };
                })}
                disabled={Boolean(state.tanque)}
              />
            )}
          </div>

          <Botao
            disabled={Boolean(state.tanque)}
            onClick={() => (state.lote ? modalTanques.open() : store.notificar('Selecione um lote'))}
          >
            Adicionar tanque
          </Botao>
          {ocultarTanquesSelecionados && <TanquesSelecionados />}

          <Botao
            onClick={() => (state.lote || state.tanque ? modalAmostragem.open() : store.notificar('Selecione um lote'))}
          >
            Adicionar amostragem
          </Botao>
          {state.amostragens &&
            !biometria &&
            state.amostragens.map((amostragem: any) => {
              return (
                <Flex style={{ width: '100%', justifyContent: 'space-between' }} key={Math.random()}>
                  <p>{Formatter.formatNumber(3, amostragem.peso - amostragem.tara)} g</p>
                  <p>÷</p>
                  <p>{Formatter.formatNumber(0, amostragem.qtd_peixes)} un</p>
                  <p>=</p>
                  <p>
                    {biometria
                      ? Formatter.formatNumber(3, amostragem.media)
                      : Formatter.formatNumber(3, amostragem.media)}{' '}
                    g
                  </p>

                  <IconButton onClick={() => removeAmostragem(amostragem)}>
                    <DeleteIcon />
                  </IconButton>
                </Flex>
              );
            })}
          <div style={{ margin: 10, textAlign: 'center' }}>
            <h2>Média geral</h2>
            <h1 style={{ color: '#42a5f5' }}>{Formatter.formatNumber(3, state.media)} g</h1>
          </div>

          <div style={{ width: '100%', display: 'flex' }}>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao onClick={cadastrar} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          </div>
        </div>
      </ModalBody>
      <SelecionarLote
        open={modalSelecionarLote.state}
        handleClose={modalSelecionarLote.close}
        onItemClick={(lote) => {
          state.tanques = [];
          state.lote = { label: lote.codigo, value: lote.id.toString() };
          store.loteTanque.getByLote(lote.id).map((loteTanque) => {
            const estoque = returnQuantidade(loteTanque, false);
            if (estoque) {
              const amostragem = {
                lote_tanque: loteTanque.tanque.id,
                tara: 0,
                qtd_peixes: 0,
                peso: 0,
                media: 0,
              };

              const amostragens = [...state.resposta, amostragem];

              state.resposta = amostragens;

              return state.tanques.push({ item: loteTanque.tanque });
            } else {
              return null;
            }
          });
        }}
        loteID={state.lote ? parseInt(state.lote.value) : null}
      />
      <SelecionarTanqueUnico
        handleClose={modalSelecionarTanqueUnico.close}
        open={modalSelecionarTanqueUnico.state}
        onItemClick={(tanque: Tanque | null) => {
          if (tanque) {
            state.tanque = { value: tanque.id.toString(), label: tanque.nome };
            addTanque(tanque.id);
          } else {
            state.lote = null;
            state.tanque = null;
            state.tanques = [];
          }
        }}
        selecionadoId={+state.tanque?.value}
      />

      {state.lote ? (
        <SelecionarTanque
          open={modalTanques.state}
          handleClose={modalTanques.close}
          onItemClick={addTanque}
          selectedTanques={state.tanques}
          loteID={parseInt(state.lote.value)}
        />
      ) : null}
      {Boolean(state.lote || loteDoTanqueSelecionadoOption) && (
        <AddAmostragem
          onConfirm={addAmostragem}
          open={modalAmostragem.state}
          handleClose={modalAmostragem.close}
          lote={state.lote || (loteDoTanqueSelecionadoOption as SelectRow)}
        />
      )}
      {modalEditPesoMedio.state ? (
        <EditPesoMedio
          onConfirm={() => {
            state.resposta[state.select.index].media = state.select.peso_medio;
            modalEditPesoMedio.close;
          }}
          open={modalEditPesoMedio.state}
          handleClose={modalEditPesoMedio.close}
          state={state}
        />
      ) : null}
    </Modal>
  );
};

export default observer(FormBiometria);
