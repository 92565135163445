import styled from 'styled-components';

export const TankItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 50px;

  padding: 5px;

  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const TankListText = styled.p`
  padding: 0.3rem 0;
`;

export const Label = styled.label`
  top: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  transition: all 0.2s ease-out;
`;

export const TextPrice = styled.h2`
  color: #42a5f5;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  margin: 0.1rem 0 0 0;
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0.1rem 0 0 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ApportionmentItemsContainer = styled.div`
  width: 100%;
  min-height: 1rem;
`;

export const ApportionmentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ApportionmentItemInfo = styled.span`
  flex: 1;
`;

export const ApportionmentItemInfoCenter = styled.span`
  flex: 1;
  text-align: center;
`;

export const TanksContainer = styled.div`
  width: 95%;
`;

export const LotsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 50px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 95%;
`;
