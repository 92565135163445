import { apiV2, getThingsV2 } from '../utils';
import { observable, computed } from 'mobx';
import { stateContext } from '../hooks/useStore';

class Temperatura {
  @observable arr: Temperaturas[] | null = null;
  @observable totalRes: number = 0;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    this.arr = await getThingsV2<Temperaturas>('/temperatura/');
  }

  async getEverything(params?: object): Promise<Temperaturas[]> {
    return await getThingsV2<Temperaturas>('/temperatura/', params);
  }

  async getPaginated(page: number, pageSize: number, propriedadeId: number): Promise<void> {
    apiV2
      .get(`/temperatura/?propriedade=${propriedadeId}`, {
        params: {
          page_size: pageSize,
          page: page + 1,
          prop: propriedadeId,
        },
      })
      .then((res: any) => {
        this.totalRes = res.data.count;
        this.arr = res.data.results;
      });
  }
  async criar(dados: any): Promise<void> {
    const store = stateContext.state;

    await apiV2
      .post(`/temperatura/?prop=${store.propriedade?.id}`, dados)
      .then(async () => {
        store.notificar('Temperatura cadastrada com sucesso!');
        await store.temperatura.populate();
      })
      .catch(() => store.notificar('Ocorreu um erro ao cadastrar a Temperatura!'));
  }
  get(id: number): Temperaturas {
    return this.arr!.filter((item) => item.id === id)[0];
  }
  async editar(contaID: number, dados: any): Promise<void> {
    const store = stateContext.state;

    await apiV2
      .put(`/temperatura/${contaID}/?prop=${store.propriedade?.id}`, dados)
      .then(async () => {
        store.notificar('Temperatura editada com sucesso!');
        await store.temperatura.populate();
      })
      .catch(() => store.notificar('Ocorreu um erro ao editar a Temperatura!'));
  }

  async excluir(contaID: number): Promise<void> {
    const store = stateContext.state;
    await apiV2
      .delete(`/temperatura/${contaID}/?prop=${store.propriedade?.id}`)
      .then(() => store.notificar('Temperatura excluída com sucesso!'));
    await store.temperatura.populate();
  }
}

export default Temperatura;
