import React, { useEffect } from 'react';
import { Conteudo, NewBody } from '../../components/Body';

import useStore from '../../services/hooks/useStore';
import { api, apiV2 } from '../../services/utils';
import { useLocalStore, observer } from 'mobx-react-lite';
import Select from '../../components/Select';
import syncModel from '../../services/models/Sync';
import Formatter from '../../services/Formatter';
import { MinhaPropriedadeForm, PerfilPropriedade, BuscarArquivo } from './styles';
import { Button, FormLabel, Grid, IconButton, TextField, Typography } from '@mui/material';
import { CirclePicker } from 'react-color';
import ReactInputMask from 'react-input-mask';
import useModal from '../../services/hooks/useModal';
import AdicionarPesoMedio from './AdicionarPesoMedio';
import DeleteIcon from '@material-ui/icons/Delete';

import EditIcon from '@material-ui/icons/Edit';
import Confirmar from '../../components/Modais/Confirmar';
import X9Logger from '../../services/X9Logger';
// TODO: remover endpoint cidades da api de relatórios ao implementar o material ui autocomplete em todas as telas

const MinhaPropriedade: React.FC = () => {
  const store = useStore();

  const modalAdicionarPesoMedio = useModal();

  const state = useLocalStore(() => ({
    sync: false,
    selectedEspecie: 0,
    color: '',
    selectedFile: null as File | null,
    preview: '',
    fileName: '',
    logoMarcaExists: false,
    perfilId: 0,
    setColor(color: string) {
      this.color = color;
    },
    setSelectedFile(file: File | null) {
      this.selectedFile = file;
    },
    setPreview(preview: string) {
      this.preview = preview;
    },
    setFileName(name: string) {
      this.fileName = name;
    },
  }));

  const modalExclusao = useModal();
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      const file = files[0];
      if ((file.type === 'image/png' || file.type === 'image/jpeg') && file.size <= 2097152) {
        state.setSelectedFile(file);
        state.setFileName(file.name);
        const reader = new FileReader();
        reader.onloadend = () => {
          state.setPreview(reader.result as string);
        };
        reader.readAsDataURL(file);
      } else {
        store.notificar('Por favor, selecione um arquivo de imagem válido (png ou jpg) com tamanho até 2MB.');
      }
    }
  };

  const estadoRows = store.estados.arr && store.estados.getSelectRows();
  const atividadeRows = store.atividades.arr && store.atividades.getSelectRows();

  const cidadeRows = (estadoID: number): SelectRow[] =>
    store.cidades.arr
      ? store.cidades
          .getByEstado(estadoID)
          .map((cidade) => {
            return {
              label: cidade.nome,
              value: cidade.id.toString(),
            };
          })
          .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
      : [];

  const usuario = useLocalStore(() => ({
    nome: store.usuario.nome,
    inscricaoFederal: store.usuario.inscricao_federal,
    telefone: store.usuario.telefone,
    email: store.usuario.email,

    isCPF: false,

    populate() {
      this.nome = store.usuario.nome;
      this.inscricaoFederal = store.usuario.inscricao_federal;
      this.telefone = store.usuario.telefone;
      this.email = store.usuario.email;
    },
    setInscricaoFederal(e: React.ChangeEvent<HTMLInputElement>) {
      usuario.inscricaoFederal = e.target.value;
      if (Formatter.getNumeros(e.target.value).length <= 11) {
        usuario.isCPF = true;
      } else {
        usuario.isCPF = false;
      }
    },
    async salvar(e: React.FormEvent<HTMLFormElement>): Promise<void> {
      e.preventDefault();
      store.toggleLoader();
      await api
        .put(`/usuarios/${store.usuario.id}/?propriedade=${store.propriedade!.id}`, {
          nome: this.nome,

          inscricao_federal: Formatter.getNumeros(this.inscricaoFederal),
          telefone: Formatter.getNumeros(this.telefone),
          email: this.email,

          tipo_de_usuario: store.usuario.tipo_de_usuario.id,
          propriedade: store.propriedade!.id,
          permissoes: store.usuario.permissoes
            .filter((perm) => perm.propriedade.id === store.propriedade!.id)[0]
            .permissoes.map((perm) => perm.id),
        })
        .then(async () => {
          const request = await api.get('/auth/me/');
          store.setLogado(request.data);
          await store.refreshUser();
        });
      store.toggleLoader();
    },
  }));

  const propriedade = useLocalStore(() => ({
    estado: null as SelectRow | null,
    nome: '',
    atividade: null as SelectRow | null,
    inscricaoEstadual: '',
    licencaAmbiental: '',
    cidade: null as SelectRow | null,
    cor: '#000',
    tempo_pm_lote: 0,
    populate() {
      (this.cor = store.propriedade!.cor),
        (this.estado = {
          label: store.propriedade!.estado.nome,
          value: store.propriedade!.estado.id.toString(),
        });
      this.nome = store.propriedade!.nome;
      this.atividade = {
        label: store.propriedade!.atividades[0].nome,
        value: store.propriedade!.atividades[0].id.toString(),
      };
      this.inscricaoEstadual = store.propriedade!.inscricao_estadual;
      this.licencaAmbiental = store.propriedade!.licenca_ambiental;
      this.cidade = {
        label: store.propriedade!.cidade.nome,
        value: store.propriedade!.cidade.id.toString(),
      };
    },
    async salvar(e: React.FormEvent<HTMLFormElement>): Promise<void> {
      e.preventDefault();
      store.toggleLoader();
      await api.put('/propriedades/' + store.propriedade!.id + '/', {
        nome: this.nome,
        estado: (this.estado && parseInt(this.estado.value)) || null,
        cidade: (this.cidade && parseInt(this.cidade.value)) || null,
        cor: this.cor,
        licenca_ambiental: this.licencaAmbiental,

        inscricao_estadual: this.inscricaoEstadual,
        usuarios: store.propriedade!.usuarios.map((usuario) => usuario.id),
        atividades: [(this.atividade && parseInt(this.atividade.value)) || null],
        cnpj: store.propriedade!.cnpj,
      });
      await store.refreshUser();
      store.toggleLoader();
    },
  }));

  async function carregarLogoPropriedade() {
    try {
      const { data } = await apiV2.get(`/perfil-propriedade/?prop=${store.propriedade?.id}`);
      if (data[0]?.logo_marca) {
        state.logoMarcaExists = true;
        state.preview = data[0].logo_marca;
      } else {
        state.logoMarcaExists = false;
      }
      state.perfilId = data[0]?.id; // Armazena o perfilId para operações futuras
    } catch (error) {
      console.error('Erro ao carregar a logo da propriedade:', error);
    }
  }

  async function salvarPerfilPropriedade(): Promise<void> {
    const promiseArr: Promise<void>[] = [];
  
    // Upload de logo se houver um arquivo selecionado
    if (state.selectedFile) {
      const formData = new FormData();
      formData.append('logo_marca', state.selectedFile);
  
      const uploadLogoMarca = async (): Promise<void> => {
        if (state.logoMarcaExists) {
          await apiV2.put(`/perfil-propriedade/${state.perfilId}/?prop=${store.propriedade?.id}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
        } else {
          await apiV2.post(`/perfil-propriedade/?prop=${store.propriedade?.id}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
        }
      };
  
      promiseArr.push(uploadLogoMarca());
    }
  
    
    const newItems = store.perfilPesoMedioDespesca.arr?.filter((item) => item.isNew) || [];
    for (const item of newItems) {
      promiseArr.push(
        store.perfilPesoMedioDespesca.criar({
          especie: item.especie,
          peso_medio_despesca: item.peso_medio_despesca,
          tempo_pm_lote: item.tempo_pm_lote,
          isNew: false,
          isEdit: false,
        })
      );
    }
  
    
    const editItems = store.perfilPesoMedioDespesca.arr?.filter((item) => item.isEdit) || [];
    if (editItems.length > 0) {
      for (const item of editItems) {
        const updatePesoMedio = async (): Promise<void> => {
          if (!item.id) {
            return;
          }
          await apiV2.put(`/perfil-peso-medio-despesca/${item.id}/?prop=${store.propriedade?.id}`, {
            especie: item.especie?.value,
            peso_medio_despesca: item.peso_medio_despesca,
            tempo_pm_lote: item.tempo_pm_lote,
          });
          item.isEdit = false;
        };
        promiseArr.push(updatePesoMedio());
      }
    }
    
  
    store.toggleLoader();
    try {
      await Promise.all(promiseArr);
      store.notificar('Dados salvos com sucesso!');
    } catch (error) {
      store.notificar('Erro ao salvar dados.');
    } finally {
      store.toggleLoader();
    }
  }
  

  const salvarPropriedade = async () => {
    await salvarPerfilPropriedade();
  };

  const addPesoMedio = async (data: any) => {
    if (!data.especie) return;

    const especieExistente = store.perfilPesoMedioDespesca.arr?.some(
      (item) => item.especie?.value === data.especie?.value && !state.selectedEspecie,
    );

    if (especieExistente) {
      store.notificar('Essa espécie já foi adicionada à lista.');
      return;
    }

    if (state.selectedEspecie) {
      const item = store.perfilPesoMedioDespesca.arr?.find((item) => item.especie?.value === state.selectedEspecie);
      if (item) {
        await store.perfilPesoMedioDespesca.editar(item.id!, {
          especie: data.especie.value,
          peso_medio_despesca: data.peso_medio_despesca,
          tempo_pm_lote: data.tempo_pm_lote,
        });
      }
      state.selectedEspecie = 0;
    } else {
      await store.perfilPesoMedioDespesca.criar({
        especie: data.especie.value,
        peso_medio_despesca: data.peso_medio_despesca,
        tempo_pm_lote: data.tempo_pm_lote,
        isNew: true,
        isEdit: false,
      });
    }
  };

  const deletarPesoMedio = async () => {
    const selected = store.perfilPesoMedioDespesca.arr?.find((item) => item.especie?.value === state.selectedEspecie);
    store.toggleLoader();
    try {
      await store.perfilPesoMedioDespesca.excluir(selected?.id!);
      store.notificar('Peso médio excluído com sucesso!');
    } catch (error) {
      store.notificar('Ocorreu um erro ao excluir peso médio. Tente novamente mais tarde!');
    } finally {
      modalExclusao.close();
      store.toggleLoader();
    }
  };

  const handleDeletePesoMedio = (id: number) => {
    state.selectedEspecie = id;
    modalExclusao.open();
  };
  const handleEditPesoMedio = (id: number) => {
    const item = store.perfilPesoMedioDespesca.arr?.find((item) => item.especie?.value === id);
    if (item) {
      state.selectedEspecie = id; 
      modalAdicionarPesoMedio.open(); 
      item.isEdit = true; 
    }
  };
  const ExcluirEspecieExplorada: React.FC = () => {
    const store = useStore();

    const especie = store.especies.arr?.find((especie) => especie.id === state.selectedEspecie);
    const selected = store.perfilPesoMedioDespesca.arr?.find(
      (item: any) => item.especie?.value === state.selectedEspecie,
    );

    return (
      <div style={{ width: '100%' }}>
        <b>Nome da Espécie: </b> {especie?.nome}
        <br />
        <b>Peso Médio Despesca: </b> {`${Formatter.formatNumber(3, selected?.peso_medio_despesca)}g`}
        <br />
        <b>Tempo Peso Médio do Lote (dia): </b> {`${selected?.tempo_pm_lote} dias`}
      </div>
    );
  };
  async function sincronizar(): Promise<void> {
    store.toggleLoader();
    propriedade.populate();
    usuario.populate();
    try {
      if (!store.especies.arr) {
        await store.especies.populate();
      }
      await Promise.all([
        syncModel.perfil(),
        store.especies.populate(),
        store.perfilPesoMedioDespesca.populate(),
        carregarLogoPropriedade(),
      ]);
    } catch (error) {
      X9Logger.report(error as Error);
    } finally {
      store.toggleLoader();
      state.sync = true;
    }
  }

  useEffect(() => {
    store.propriedade && sincronizar();
    // eslint-disable-next-line
  }, [store.propriedade]);

  useEffect(() => {
    if (propriedade.estado && parseInt(propriedade.estado.value) !== store.propriedade?.estado.id) {
      const cidades = store.cidades?.getByEstado(parseInt(propriedade.estado!.value));
      propriedade.cidade =
        cidadeRows(parseInt(propriedade.estado.value)).filter((row) => parseInt(row.value) === cidades![0].id)[0] ||
        null;
    }

    // eslint-disable-next-line
  }, [propriedade.estado]);

  useEffect(() => {
    store.setTitulo('Editar perfil');
    // eslint-disable-next-line
  }, []);
  return state.sync ? (
    <NewBody>
      <Conteudo>
        <Grid container spacing={2}>
          <Grid item container xs={12} lg={6} spacing={2}>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '15px 100px' }}>
              <img src="/images/propriedade.svg" alt="Logo propriedade" />
              <h1>Minha Propriedade</h1>
            </Grid>
            <Grid item xs={12}>
              <MinhaPropriedadeForm onSubmit={propriedade.salvar}>
                <Grid item xs={12}>
                  <TextField
                    required
                    value={propriedade.nome}
                    onChange={(e) => (propriedade.nome = e.target.value)}
                    label="Nome"
                  />
                </Grid>
                {estadoRows && (
                  <Grid item xs={12}>
                    <Select
                      value={propriedade.estado}
                      placeholder="Estado"
                      onChange={(e: any) => (propriedade.estado = e)}
                      options={estadoRows}
                    />
                  </Grid>
                )}
                {estadoRows && (
                  <Grid item xs={12}>
                    <Select
                      value={propriedade.cidade}
                      placeholder="Município"
                      onChange={(e: any) => (propriedade.cidade = e)}
                      options={(propriedade.estado && cidadeRows(parseInt(propriedade.estado.value))) || []}
                    />
                  </Grid>
                )}
                {atividadeRows && (
                  <Grid item xs={12}>
                    <Select
                      value={propriedade.atividade}
                      placeholder="Atividade Principal"
                      onChange={(e: any) => (propriedade.atividade = e)}
                      options={atividadeRows}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <ReactInputMask
                    value={propriedade.inscricaoEstadual}
                    onChange={(e) => (propriedade.inscricaoEstadual = e.target.value)}
                    mask="99.999.999-9"
                    maskChar=""
                    alwaysShowMask={false}
                  >
                    {() => <TextField label="Inscrição Estadual" />}
                  </ReactInputMask>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={propriedade.licencaAmbiental}
                    onChange={(e) => (propriedade.licencaAmbiental = e.target.value)}
                    label="Licença Ambiental (Opcional)"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel style={{ marginBottom: '1rem' }}>Selecione uma cor?</FormLabel>
                  <CirclePicker
                    width="100%"
                    onChangeComplete={(e) => {
                      propriedade.cor = e.hex;
                      state.setColor(e.hex);
                    }}
                  />

                  <button
                    style={{
                      width: '60px',
                      height: '30px',
                      background: state.color,
                      marginTop: '1rem',
                      border: '1px solid #ccc',
                    }}
                  ></button>
                </Grid>
                <Grid item container xs={12} justifyContent={'center'}>
                  <Grid item xs={4} sm={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        backgroundColor: '#00C853',
                        textTransform: 'none',
                        width: '100%',
                        borderRadius: '16px',
                      }}
                    >
                      Salvar propriedade
                    </Button>
                  </Grid>
                </Grid>
              </MinhaPropriedadeForm>
            </Grid>
          </Grid>

          <Grid item container xs={12} lg={6} style={{ padding: '15px 100px', display: 'flex' }} spacing={2}>
            <Grid item xs={12}>
              <h1>Perfil da Propriedade</h1>
            </Grid>
            <PerfilPropriedade>
              <Grid item container xs={12}>
                <Grid item xs={12} alignItems={'flex-start'}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="caption">Logomarca (png ou jpg até 2Mb)</Typography>
                      <Button variant="contained" component="label" style={{ width: '100%', textTransform: 'none' }}>
                        Buscar Arquivo
                        <BuscarArquivo type="file" onChange={handleFileChange} />
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption">{state.fileName || 'Nome do arquivo'}</Typography>
                      <div
                        style={{
                          width: '100%',
                          height: '100px',
                          backgroundColor: state.preview ? 'none' : '#ccc',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundImage: `url(${state.preview})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                        }}
                      ></div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Button
                      onClick={() => {
                        state.selectedEspecie = 0;
                        modalAdicionarPesoMedio.open();
                      }}
                      variant="contained"
                      tabIndex={-1}
                      style={{ width: '100%', textTransform: 'none' }}
                    >
                      Adicionar Peso Médio Esperado por Espécie
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    {store.perfilPesoMedioDespesca!.arr!.length > 0 ? (
                      <div style={{ maxHeight: 'calc(50px * 5)', overflowY: 'auto' }}>
                        {store.perfilPesoMedioDespesca!.arr!.map((item, index) => (
                          <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                            <Typography>{item.especie?.label}</Typography>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                              <Typography>{`${item.tempo_pm_lote} dias | (${Formatter.formatNumber(
                                3,
                                item.peso_medio_despesca,
                              )}g)`}</Typography>
                              <div>
                                <IconButton onClick={() => handleEditPesoMedio(item.especie!.value)} sx={{ pr: 0 }}>
                                  <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDeletePesoMedio(item.especie!.value)} sx={{ px: 0 }}>
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item container xs={12} justifyContent={'center'} style={{ marginTop: '20px' }}>
                    <Grid item xs={4} sm={6}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: '#00C853',
                          textTransform: 'none',
                          width: '100%',
                          borderRadius: '16px',
                        }}
                        onClick={salvarPropriedade}
                      >
                        Salvar perfil
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </PerfilPropriedade>
          </Grid>
        </Grid>
      </Conteudo>
      {modalAdicionarPesoMedio.state && (
        <AdicionarPesoMedio
          handleClose={modalAdicionarPesoMedio.close}
          onConfirm={addPesoMedio}
          open={modalAdicionarPesoMedio.state}
          pesoMedioList={store.perfilPesoMedioDespesca.arr}
          especieID={state.selectedEspecie}
        />
      )}
      <Confirmar
        open={modalExclusao.state}
        content={<ExcluirEspecieExplorada />}
        title="Tem certeza que deseja excluir?"
        handleClose={() => {
          sincronizar();
          modalExclusao.close();
        }}
        onCancel={modalExclusao.close}
        onConfirm={deletarPesoMedio}
      />
    </NewBody>
  ) : null;
};

export default observer(MinhaPropriedade);
