import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { useLocalStore, observer } from 'mobx-react-lite';
import InputNumero from '../../../components/InputNumero';
import Botao from '../../../components/Botoes/Botao';
import Select from '../../../components/Select';
import useStore from '../../../services/hooks/useStore';
import Confirmar from '../../../components/Modais/Confirmar';
import useModal from '../../../services/hooks/useModal';
import Formatter from '../../../services/Formatter';

type NovaAmostragem = ModalProps & {
  onConfirm(tara: number, qtd_peixes: number, peso: number, media: number): void;
  lote: SelectRow;
};
const AddAmostragem: React.FC<NovaAmostragem> = ({ open, handleClose, onConfirm, lote }) => {
  const store = useStore();
  const state = useLocalStore(() => ({
    tara: 0,
    qtd_peixes: 0,
    peso: 0,
    media: 0,
    plsGrama: 0,
    pls_qtd: { label: 'Pls/Gramas', value: 'true' },
  }));
  const modalConfirmar = useModal();
  const loteObj = store.lotes.get(parseInt(lote.value));

  useEffect(() => {
    if (loteObj.especie_explorada.nome === 'Camarão') {
      if (state.pls_qtd.value === 'true') {
        if (state.peso > 0 && state.plsGrama > 0) {
          state.qtd_peixes = state.plsGrama * (state.peso * 1000 - state.tara);

          state.media = (state.peso * 1000 - state.tara) / state.qtd_peixes;
        }
      } else {
        if (state.peso > 0 && state.qtd_peixes > 0) {
          const media = (state.peso * 1000 - state.tara) / state.qtd_peixes;
          state.media = media;
        }
      }
    } else {
      if (state.peso > 0 && state.qtd_peixes > 0) {
        const media = (state.peso * 1000 - state.tara) / state.qtd_peixes;
        if (Math.sign(media) === 1 || Math.sign(media) === 0) {
          state.media = media;
        }
      }
    }

    // eslint-disable-next-line
  }, [state.tara, state.qtd_peixes, state.peso, state.plsGrama]);

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Nova amostragem</ModalHeader>
      <ModalBody>
        {loteObj.especie_explorada.nome === 'Camarão' ? (
          <Select
            value={state.pls_qtd}
            placeholder="Pls/Gramas ou Quantidade"
            onChange={(option: SelectRow) => (state.pls_qtd = option)}
            options={[
              { label: 'Pls/Gramas', value: 'true' },
              { label: 'Quantidade', value: 'false' },
            ]}
          />
        ) : null}

        <InputNumero
          value={state.tara / 1000}
          onChange={(val) => (state.tara = val * 1000)}
          label="Informe a tara (kg)"
          precision="3"
        />

        {loteObj.especie_explorada.nome === 'Camarão' ? (
          <InputNumero
            value={state.peso}
            onChange={(val) => (state.peso = val)}
            label="Peso recolhido (kg)"
            precision="3"
          />
        ) : (
          <InputNumero
            value={state.peso}
            onChange={(val) => (state.peso = val)}
            label="Peso recolhido (kg)"
            precision="3"
          />
        )}

        {loteObj.especie_explorada.nome === 'Camarão' && state.pls_qtd?.value === 'true' ? (
          <InputNumero
            value={state.plsGrama}
            onChange={(value) => (state.plsGrama = value)}
            precision="3"
            label="Pls/Gramas"
          />
        ) : null}

        <InputNumero
          value={state.qtd_peixes}
          onChange={(val) => (state.qtd_peixes = val)}
          label="Quantidade de animais (un)"
          precision="0"
        />

        <div style={{ margin: 10, textAlign: 'center' }}>
          <h2>Peso Médio Geral (g)</h2>
          <h1 style={{ color: '#42a5f5' }}>{Formatter.formatNumber(3, state.media)} g</h1>
        </div>

        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={modalConfirmar.open} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao
            onClick={() => {
              const media = (state.peso * 1000 - state.tara) / state.qtd_peixes;
              if (state.qtd_peixes !== 0 && state.peso !== 0) {
                onConfirm(state.tara, state.qtd_peixes, state.peso * 1000, state.media);
                
                state.tara = 0;
                state.qtd_peixes = 0;
                state.peso = 0;
                state.media = 0;
              } else if (Math.sign(media) === 1 || Math.sign(media) === 0) {
                store.notificar('O peso médio não pode ser negativo!');
              } else store.notificar('Informe os dados da amostragem');
            }}
            variant="contained"
            cor="#00C853"
          >
            Cadastrar
          </Botao>
        </div>
      </ModalBody>

      <Confirmar
        title="Tem certeza que deseja cancelar?"
        open={modalConfirmar.state}
        handleClose={modalConfirmar.close}
        onConfirm={() => {
          state.tara = 0;
          state.qtd_peixes = 0;
          state.peso = 0;
          state.media = 0;
          handleClose();
          modalConfirmar.close();
        }}
        onCancel={modalConfirmar.close}
      />
    </Modal>
  );
};

export default observer(AddAmostragem);
