import { IconButton, Paper } from '@material-ui/core';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from '../../../components/Modais/styles';
import { returnTanque } from '../../../services/utils/propriedade';
import CloseIcon from '@material-ui/icons/Close';
import Formatter from '../../../services/Formatter';

interface Props extends ModalProps {
  arracoamento: Arracoamento;
}

const VerTanques: React.FC<Props> = (props) => {
  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <ModalHeader>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span>Tanques do Arraçoamento</span>
          <IconButton onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </ModalHeader>
      <ModalBody>
        {props.arracoamento.tanque_data.map((tanqueData:any) => {
          return (
            <Paper
              style={{ width: '90%', padding: 10, marginBottom: 15 }}
              elevation={3}
              key={tanqueData.lote_tanque?.id}
            >
              <p>
                <b>Tanque: </b> {returnTanque(tanqueData?.tanque)}
              </p>
              <p>
                <b>Nome Ração: </b> {props.arracoamento?.racao.nome}
              </p>
              <p>
                <b>Qtde Ração: </b>
                {Formatter.formatNumber(3, tanqueData?.peso)} kg
              </p>
              <p>
                <b>Qtde Tratos: </b>
                {Formatter.formatNumber(0, tanqueData?.qtd_trato)}x
              </p>
              <p>
                <b>Qtde Ração Trato: </b>
                {Formatter.formatNumber(3, tanqueData?.qtd_kg_trato)}kg/1x
              </p>
            </Paper>
          );
        })}
      </ModalBody>
    </Modal>
  );
};

export default VerTanques;
