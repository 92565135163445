import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import useStore from '../../../services/hooks/useStore';
import { TanqueInfo } from '../../../components/TanqueInfo';
import { observer, useLocalStore } from 'mobx-react-lite';
import Formatter from '../../../services/Formatter';
import { apiV2 } from '../../../services/utils';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';

type Props = ModalProps & {
  onItemClick(tanque: any): void;
  loteID?: number;
  all?: boolean;
  exclude?: number;
};
const SelecionarTanqueOrigem: React.FC<Props> = ({ open, handleClose, onItemClick, loteID, all }) => {
  const state = useLocalStore(() => ({
    sync: false,
  }));
  const [codigo, setCodigo] = useState<string>('');
  const [filtro, setFiltro] = useState<boolean>(false);
  const store = useStore();
  const [tanques, setTanques] = useState<LoteTanque[]>([] as LoteTanque[]);

  useEffect(() => {
    store.toggleLoader();
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function searchTanque() {
      await apiV2
        .get('/lote-tanque/modals/', {
          params: {
            propriedade: store.propriedade!.id,
            lote: all ? null : loteID,
            nome: codigo,
          },
        })
        .then((res) => {
          setTanques(res.data.results);
        });

      state.sync = true;
      store.toggleLoader();
    }
    searchTanque();
    // eslint-disable-next-line
  }, [loteID, state.sync, store, codigo, setCodigo]);

  const TanquesDisponiveisFiltrados: React.FC = () => (
    <>
      {tanques?.length > 0 &&
        tanques
          .sort((tanqueA, tanqueB) => {
            const nomeA = tanqueA.tanque.nome ?? tanqueA.tanque.codigo;
            const nomeB = tanqueB.tanque.nome ?? tanqueB.tanque.codigo;
            return nomeA.localeCompare(nomeB);
          })
          .map((tanque: any) => {
            const PM = tanque.peso_medio_atual || tanque.peso_medio;
            if (tanque && tanque.estoque > 0) {
              return (
                <TanqueInfo
                  onClick={() => {
                    handleClose();
                    onItemClick({
                      label: tanque.tanque.nome || tanque.tanque.codigo,
                      value: tanque.tanque.id.toString(),
                      row: tanque,
                    });
                  }}
                  key={tanque.id}
                >
                  <p className="left">{tanque.tanque.nome || tanque.tanque.codigo}</p>
                  <p className="right">
                    {tanque.lote.codigo} ({Formatter.formatNumber(0, tanque.estoque)})
                  </p>
                  <b className="left" style={{ color: '#00C853' }}>
                    {PM ? Formatter.formatNumber(3, (PM * tanque.estoque) / 1000) : '00'} Kg
                  </b>
                  <p className="right" style={{ color: '#00C853' }}>
                    {tanque.fase_lote.nome}
                  </p>
                </TanqueInfo>
              );
            } else {
              return null;
            }
          })}
    </>
  );

  const TanquesDisponiveis: React.FC = () => (
    <>
      {tanques?.length > 0 &&
        tanques
          .sort((tanqueA, tanqueB) => {
            const nomeA = tanqueA.tanque.nome ?? tanqueA.tanque.codigo;
            const nomeB = tanqueB.tanque.nome ?? tanqueB.tanque.codigo;
            return nomeA.localeCompare(nomeB);
          })
          .map((tanque: any) => {
            const PM = tanque.peso_medio_atual || tanque.peso_medio;

            return (
              <TanqueInfo
                onClick={() => {
                  handleClose();
                  onItemClick({
                    label: tanque.tanque.nome || tanque.tanque.codigo,
                    value: tanque.tanque.id.toString(),
                  });
                }}
                key={tanque.id}
              >
                <p className="left">{tanque.tanque.nome || tanque.tanque.codigo}</p>
                <p className="right">
                  {tanque.lote.codigo} ({Formatter.formatNumber(0, tanque.estoque)})
                </p>
                <b className="left" style={{ color: '#00C853' }}>
                  {PM ? Formatter.formatNumber(3, (PM * tanque.estoque) / 1000) : '00'} Kg
                </b>
                <p className="right" style={{ color: '#00C853' }}>
                  {tanque.fase_lote.nome}
                </p>
              </TanqueInfo>
            );
          })}
    </>
  );

  return state.sync ? (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>
        Selecione um Tanque
        {!all && (
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setFiltro(e.target.checked);
                }}
                color="primary"
              />
            }
            label="Mostrar Todos"
          />
        )}
      </ModalHeader>
      <ModalBody>
        <div
          style={{ color: '#707070', width: '100%', padding: '10px', display: 'flex', justifyContent: 'space-between' }}
        >
          <TextField
            label="Pesquisa Pelo Nome"
            value={codigo}
            onChange={(e) => {
              setCodigo(e.target.value);
            }}
          />
        </div>
        {filtro ? <TanquesDisponiveis /> : <TanquesDisponiveisFiltrados />}
      </ModalBody>
    </Modal>
  ) : null;
};

export default observer(SelecionarTanqueOrigem);
