import styled from 'styled-components';

type Props = {
  items: number;
};
export const Item = styled.div<Props>`
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 4px 5px 0 #00000017;

  display: grid;
  gap: 25px;
  grid-template-columns: repeat(${(props) => props.items}, 1fr);
  justify-items: center;
`;

export const Info = styled.p`
  color: #665665;
  font-size: 18px;
  padding: 8px 0;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

export const Left = styled.b`
  justify-self: start;
`;

export const Right = styled.p`
  justify-self: end;
`;
