import React, { useEffect } from 'react';
import { Flex } from '../../../components/NavBar/styles';
import { NewBody } from '../../../components/Body';
import { GridDados } from '../../../components/Dados';
import useStore from '../../../services/hooks/useStore';
import Select from '../../../components/Select';
import { useHistory } from 'react-router-dom';
import { relatoriosProd } from '../../../services/utils/relatorios';

const Manejo: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Produção'), []);

  return (
    <NewBody>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Flex>
          <div style={{ width: 250, marginRight: 15 }}>
            <Select
              placeholder="Escolha o Relatório"
              options={relatoriosProd}
              onChange={(e: any) => {
                history.push('/relatorios/' + e.value);
              }}
            />
          </div>
        </Flex>
      </Flex>

      <GridDados style={{ marginLeft: 70 }}>
        <div
          style={{
            width: '80vw',
            height: '80vh',
            backgroundImage: 'url(/images/Preview.png)',
            backgroundRepeat: 'no-repeat',
            paddingTop: '25%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h2>
              Para exibir os dados, escolha o relatório
              <br />
              desejado na caixa de seleção acima!
            </h2>
          </div>
        </div>
      </GridDados>
    </NewBody>
  );
};

export default Manejo;
