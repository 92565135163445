import { Checkbox, Grid, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useFormikContext } from 'formik';
import { evaluate } from 'mathjs';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import Formatter from '../../../services/Formatter';
import useStore from '../../../services/hooks/useStore';
import { returnQuantidade } from '../../../services/utils/producao';
import { LoteTanqueItem } from './styles';

interface Values {
  programa: SelectRow | null;
  temperatura: SelectRow | null;
  lote: string;
  dataInicio: Date;
  dataFinal: Date;
  tanque_data: {
    lote_tanque: number;
    tanque: number;
    qtd_racao_dia: number;
  }[];
  quantidadeRacao: number;
  quantidadeRacaoDia: number;
  quantidadeDias: number;
  sobrevivencia: number;
  semanas: any[];
  opcoesSemanas: any[];
  listaSemanaTanque: any[];
  lancado: Boolean;
}

interface ListaTanqueProps {
  temperaturas: any[];
}

const ListaTanque: React.FC<ListaTanqueProps> = ({ temperaturas }) => {
  const store = useStore();
  const { values, setFieldValue } = useFormikContext<Values>();

  //Busca os dados do tanque
  useEffect(() => {
    async function getArracoamentoTanques(): Promise<void> {
      store.toggleLoader();

      const params = {
        programa: values.programa?.value,
        lote: values.lote,
        prop: store.propriedade!.id,
      };
      store.listaSemanaTanque.populate(params);

      store.toggleLoader();

      if (!store.listaSemanaTanque.arr) {
        await Promise.all([store.listaSemanaTanque.populate(params)]);
      }

      setFieldValue('dataInicio', new Date());
      setFieldValue('dataFinal', new Date());
    }
    if (values.programa && values.lote) {
      getArracoamentoTanques();
    }
    // eslint-disable-next-line
  }, [values.programa, values.lote]);

  //Traz os tanques como já selecionados
  useEffect(() => {
    const newTanqueData: any[] = [];
    if (store.listaSemanaTanque.arr) {
      toJS(
        store.listaSemanaTanque.arr!.forEach((tanqueSemana: any) => {
          const qtd = returnQuantidade(store.loteTanque.get(tanqueSemana.lote_tanque_id), false);

          if (qtd) {
            newTanqueData.push({
              lote_tanque: tanqueSemana.lote_tanque_id,
              tanque: tanqueSemana.tanque_id,
              qtd_racao_dia: tanqueSemana.qtd_racao_dia,
            });
          }
        }),
      );
    }
    setFieldValue('tanque_data', newTanqueData);
  }, [setFieldValue, store.listaSemanaTanque.arr, store.loteTanque]);

  //Calcula o intervalo entre as datas de inicio e fim. e seta no input de dias
  useEffect(() => {
    function getIntervalInDays(): any {
      const date1: any = values.dataInicio;
      const date2: any = values.dataFinal;

      if (values.dataFinal) {
        const datas = Math.abs(date2 - date1);
        const quantidadeDias = datas / (1000 * 60 * 60 * 24) + 1;

        if (!isNaN(quantidadeDias)) {
          const quantidadeDiasFormatted = Math.round(quantidadeDias);
          return setFieldValue('quantidadeDias', quantidadeDiasFormatted);
        }
      }
    }
    getIntervalInDays();
  }, [values.dataInicio, values.dataFinal, setFieldValue]);

  //Calcula a quantidade de ração por dia baseado no intervalo entre as datas
  useEffect(() => {
    if (values.lote) {
      const quantidadeRacaoDia = values.tanque_data.reduce((prev, next: any) => {
        return prev + evaluate(`${next.qtd_racao_dia} * ${values.quantidadeDias}`);
      }, 0);
      setFieldValue('quantidadeRacaoDia', quantidadeRacaoDia);
    }
  }, [values.quantidadeDias, values.tanque_data, values.lote, setFieldValue]);

  //Recebe a temperatura e seta no value do formik
  useEffect(() => {
    if (temperaturas.length) {
      const temp = temperaturas.find((temperatura: any) => temperatura.is_padrao);

      if (temp) {
        setFieldValue('temperatura', {
          label: temp.temperatura + '° - ' + temp.racao + '%',
          value: temp.id.toString(),
        });
      }
    }
    // eslint-disable-next-line
  }, [temperaturas]);

  return (
    <div>
      {!store.listaSemanaTanque.length ? (
        <Alert severity="warning">Não foram encontrados tanques compatíveis</Alert>
      ) : (
        store.listaSemanaTanque.map((tanqueSemana) => {
          const selected = values.tanque_data.some((tanque) => tanque.lote_tanque === tanqueSemana.lote_tanque_id);
          const quantidade = returnQuantidade(store.loteTanque.get(tanqueSemana.lote_tanque_id), false);

          function handleChange(_e: any, checked: boolean): void {
            let newTanqueData = [...values.tanque_data];

            if (checked) {
              newTanqueData.push({
                lote_tanque: tanqueSemana.lote_tanque_id,
                tanque: tanqueSemana.tanque_id,
                qtd_racao_dia: tanqueSemana.qtd_racao_dia,
              });
            } else {
              newTanqueData = newTanqueData.filter((item) => item.lote_tanque !== tanqueSemana.lote_tanque_id);
            }

            setFieldValue('tanque_data', newTanqueData);
          }

          return quantidade > 0 ? (
            <LoteTanqueItem style={{ gridTemplateColumns: '100px 1fr 1fr 1fr' }} key={tanqueSemana.lote_tanque_id}>
              <Checkbox color="primary" checked={selected} onChange={handleChange} />
              <Paper
                elevation={3}
                style={{
                  marginBottom: 10,
                  padding: 20,
                  width: '600px',
                  border: selected ? '3px solid green' : undefined,
                }}
              >
                <Grid container spacing={2} style={{ display: 'flex' }} direction="column">
                  <Grid style={{ display: 'flex' }} justify="space-between" direction="row">
                    <h3>{tanqueSemana.tanque_nome}</h3>
                    <p>
                      {Formatter.formatNumber(0, quantidade)}un - {Formatter.formatNumber(3, tanqueSemana.peso_medio)}g
                    </p>
                  </Grid>
                  <Grid style={{ display: 'flex', padding: '5px' }} justify="space-between" direction="row">
                    <p>
                      {tanqueSemana.semana_codigo} - PM: {Formatter.formatNumber(3, tanqueSemana.semana_pm_ini)}g a{' '}
                      {Formatter.formatNumber(3, tanqueSemana.semana_pm_fim)}g
                    </p>
                    <p>
                      {tanqueSemana.perc_biomassa}% Bio - {Formatter.formatNumber(3, tanqueSemana.qtd_racao_dia)}Kg/dia
                    </p>
                  </Grid>
                  <Grid style={{ display: 'flex', padding: '5px' }} justify="space-between" direction="row">
                    <p>{tanqueSemana.racao_nome}</p>
                    <p>
                      N. Tratos: {tanqueSemana.num_tratos}x{' '}
                      {Formatter.formatNumber(3, tanqueSemana.qtd_racao_dia / tanqueSemana.num_tratos)}Kg
                    </p>
                  </Grid>
                </Grid>
              </Paper>
            </LoteTanqueItem>
          ) : (
            <LoteTanqueItem style={{ gridTemplateColumns: '100px 1fr 1fr 1fr' }} key={tanqueSemana.lote_tanque_id}>
              <Checkbox disabled />
              <Paper
                elevation={3}
                style={{
                  marginBottom: 10,
                  padding: 20,
                  width: '600px',
                  border: selected ? '3px solid green' : undefined,
                }}
              >
                <Grid container spacing={2} style={{ display: 'flex' }} direction="column">
                  <Grid style={{ display: 'flex' }} justify="space-between" direction="row">
                    <h3>{tanqueSemana.tanque_nome}</h3>
                  </Grid>
                  <Grid style={{ display: 'flex', padding: '5px' }} justify="space-between" direction="row">
                    <Alert severity="warning">
                      Não foram encontrados Dias/Semanas compatíveis com o Peso Médio e data de arraçoamento do lote!
                    </Alert>
                  </Grid>
                </Grid>
              </Paper>
            </LoteTanqueItem>
          );
        })
      )}
    </div>
  );
};

export default observer(ListaTanque);
