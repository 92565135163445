import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Paper, TextField, Tooltip } from '@material-ui/core';

import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import useStore from '../../../services/hooks/useStore';
import { observer, useLocalStore } from 'mobx-react-lite';
import useModal from '../../../services/hooks/useModal';
import InputNumero from '../../../components/InputNumero';
import Botao from '../../../components/Botoes/Botao';
import Formatter from '../../../services/Formatter';
import { apiV2 } from '../../../services/utils';
import { LoteTanqueInfo } from '../../../components/LoteTanqueInfo';
import { calcularPLsPorGrama } from './helpers/calcularPLsPorGrama';
import { calcularPesoMedio } from './helpers/calcularPesoMedio';

type Props = ModalProps & {
  exclude?: number;
  tanquesSelecionados: TanqueDestino[];
  isCamarao: boolean;
  onConfirm(tanque: TanqueDestino): void;
  loteId: number;
  tanqueUUID?: string;
  state2: any;
};

const SelecionarTanqueDestino: React.FC<Props> = ({
  open,
  handleClose,
  exclude,
  tanquesSelecionados,
  isCamarao,
  onConfirm,
  state2,
}) => {
  const store = useStore();
  const modalInfo = useModal();
  const [codigo, setCodigo] = useState<string>('');
  const [filtroRapidoFinalizado, setfiltroRapidoFinalizado] = useState<boolean>(false);
  const state = useLocalStore(() => ({
    sync: false,
  }));

  const [tanquesProps, setTanquesProps] = useState<Tanque[]>([] as Tanque[]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function searchTanque() {
      store.toggleLoader();
      await apiV2
        .get('/tanque/modals/', {
          params: {
            propriedade: store.propriedade!.id,
            nome: codigo,
          },
        })
        .then((res) => {
          if (state2.tqOrigem.value) {
            const tanquesSemOrigem = res.data.results.filter((tanque: any) => tanque.id !== exclude);
            const tanquesSemSelecionados = tanquesSemOrigem.filter(
              (tanque: any) => !tanquesSelecionados.some((tanqueSelecionado) => tanque.uuid === tanqueSelecionado.uuid),
            );

            setTanquesProps(tanquesSemSelecionados);
          } else {
            setTanquesProps(res.data.results);
          }
        });
      store.toggleLoader();
    }

    searchTanque();

    state.sync = true;
    // eslint-disable-next-line
  }, [codigo, setCodigo]);

  const selectedTanque = useLocalStore(() => ({
    uuid: '',
    nome: '',
    quantidade: 0,
    biomassa: 0,
  }));

  function cadastrar(tanqueInfo: TanqueDestino): void {
    if (!tanqueInfo.biomassa) {
      store.notificar('Informe a biomassa');
    } else if (!tanqueInfo.pesoMedio) {
      store.notificar('Informe o peso médio ou PLs/Grama');
    } else if (!tanqueInfo.quantidade) {
      store.notificar('Informe a quantidade');
    } else {
      onConfirm(tanqueInfo);

      modalInfo.close();
      handleClose();
    }
  }

  const TanquesDisponiveis: React.FC = () => (
    <>
      {tanquesProps.map((tanque) => {
        if (filtroRapidoFinalizado) {
          return (
            <LoteTanqueInfo
              onClick={() => {
                selectedTanque.uuid = tanque.uuid;
                selectedTanque.nome = tanque.nome || tanque.codigo;
                selectedTanque.biomassa = tanque.biomassa / 1000;
                selectedTanque.quantidade = tanque.estoque;
                modalInfo.open();
              }}
              key={tanque.id}
            >
              <p className="left">{tanque.nome || tanque.codigo}</p>
              <p className="right">
                {tanque.lotes && tanque.lotes.length > 0 ? (
                  <Tooltip
                    title={tanque.lotes.map((lote: any) => {
                      return `${lote.nome || lote.codigo}, `;
                    })}
                  >
                    <span>{tanque.lotes[0].codigo + '...'}</span>
                  </Tooltip>
                ) : (
                  ''
                )}{' '}
                ({Formatter.formatNumber(0, tanque.estoque)})
              </p>
              <b className="left" style={{ color: '#00C853' }}>
                {Formatter.formatNumber(3, tanque.biomassa)} Kg
              </b>
              <p className="right" style={{ color: '#00C853' }}>
                {tanque.lotes && tanque.lotes.length > 0 ? tanque.lotes[0].fase_lote.nome : tanque.situacao_tanque.nome}
              </p>
            </LoteTanqueInfo>
          );
        } else {
          return tanque.estoque === 0 ? (
            <LoteTanqueInfo
              onClick={() => {
                selectedTanque.uuid = tanque.uuid;
                selectedTanque.nome = tanque.nome || tanque.codigo;
                selectedTanque.biomassa = tanque.biomassa / 1000;
                selectedTanque.quantidade = tanque.estoque;
                modalInfo.open();
              }}
              key={tanque.id}
            >
              <p className="left">{tanque.nome || tanque.codigo}</p>
              <p className="right">
                {tanque.lotes && tanque.lotes.length > 0 ? (
                  <Tooltip
                    title={tanque.lotes.map((lote: any) => {
                      return `${lote.nome || lote.codigo}, `;
                    })}
                  >
                    <span>{tanque.lotes[0].codigo + '...'}</span>
                  </Tooltip>
                ) : (
                  ''
                )}{' '}
                ({Formatter.formatNumber(0, tanque.estoque)})
              </p>
              <b className="left" style={{ color: '#00C853' }}>
                {Formatter.formatNumber(3, tanque.biomassa)} Kg
              </b>
              <p className="right" style={{ color: '#00C853' }}>
                {tanque.lotes && tanque.lotes.length > 0 ? tanque.lotes[0].fase_lote.nome : tanque.situacao_tanque.nome}
              </p>
            </LoteTanqueInfo>
          ) : null;
        }
      })}
    </>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Selecione um Tanque
        <FormControlLabel
          control={
            <Checkbox
              checked={filtroRapidoFinalizado}
              onChange={(_e, checked) => {
                if (filtroRapidoFinalizado) {
                  setfiltroRapidoFinalizado(false);
                } else {
                  setfiltroRapidoFinalizado(checked);
                }
              }}
              color="primary"
            />
          }
          label="Mostrar Todos"
        />
      </ModalHeader>
      <ModalBody>
        <div
          style={{
            color: '#707070',
            width: '100%',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: '450px',
          }}
        >
          <TextField
            label="Pesquisa Pelo Nome"
            value={codigo}
            onChange={(e) => {
              setCodigo(e.target.value);
            }}
          />
        </div>
        <TanquesDisponiveis />
      </ModalBody>

      {modalInfo.state ? (
        <ModalQuantidade
          open={modalInfo.state}
          handleClose={modalInfo.close}
          tanqueInfo={selectedTanque}
          isCamarao={isCamarao}
          cadastrar={cadastrar}
        />
      ) : null}
    </Modal>
  );
};

interface ModalQuantidadeProps extends ModalProps {
  tanqueInfo: {
    uuid: string;
    nome: string;
    quantidade: number;
    biomassa: number;
  };
  isCamarao: boolean;
  cadastrar(tanque: TanqueDestino): void;
}

const ModalQuantidade: React.FC<ModalQuantidadeProps> = observer(
  ({ open, handleClose, tanqueInfo, isCamarao, cadastrar }) => {
    const tanque = useLocalStore(() => ({
      uuid: tanqueInfo.uuid,
      nome: tanqueInfo.nome,
      biomassa: 0,
      pesoMedio: 0,
      quantidade: 0,
      tamanho: 0,
      grama: false,
      pmCamarao: 0,
    }));

    useEffect(() => {
      if (isCamarao) {
        tanque.quantidade = Number((tanque.pesoMedio * tanque.biomassa * 1000).toFixed(0));
      } else {
        if (tanque.grama) {
          tanque.quantidade = Number((tanque.biomassa / (tanque.pesoMedio / 1000)).toFixed(0));
        } else {
          tanque.quantidade = Number((tanque.biomassa / tanque.pesoMedio).toFixed(0));
        }
      }
      // eslint-disable-next-line
    }, [tanque.biomassa, tanque.pesoMedio]);

    function handleChangePesoMedio(value: number) {
      tanque.pesoMedio = value; // Representa PLs/Grama quando a espécie for camarão
      if (isCamarao) {
        tanque.pmCamarao = calcularPesoMedio(tanque.biomassa, tanque.pesoMedio);
      }
      if (tanque.biomassa > 0 && tanque.pesoMedio > 0) {
        tanque.quantidade = Number((tanque.biomassa / tanque.pesoMedio).toFixed(0));
      }  else if (tanque.quantidade > 0 && tanque.pesoMedio > 0) {
        tanque.biomassa = Number((tanque.pesoMedio * tanque.quantidade).toFixed(3));
      }
    }

    function handleChangePmCamarao(value: number) {
      tanque.pmCamarao = value; // Representa Peso Médio quando a espécie for camarão
      if (isCamarao) {
        tanque.pesoMedio = calcularPLsPorGrama(tanque.biomassa, tanque.pmCamarao);
       
      }
    }
    function handleChangeQuantidade(value: number) {
      tanque.quantidade = value;
      if (tanque.pesoMedio > 0 && tanque.quantidade > 0) {
        tanque.biomassa = Number((tanque.pesoMedio * tanque.quantidade).toFixed(3));
      }
    }


    
    return (
      <Modal open={open} onClose={handleClose}>
        <ModalHeader>Adicionar tanque destino</ModalHeader>
        <ModalBody style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', alignItems: 'start' }}>
          <Paper
            elevation={3}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              padding: 10,
            }}
          >
            <b>{tanque.nome}</b>

            <span> {Formatter.formatNumber(0, tanqueInfo.quantidade)}un</span>
            <span> {Formatter.formatNumber(0, tanqueInfo.biomassa)}kg</span>
          </Paper>

          <InputNumero
            value={tanque.biomassa}
            onChange={(value) => (tanque.biomassa = value)}
            precision="3"
            label="Biomassa Total (kg)"
          />
          {!isCamarao && (
            <FormControlLabel
              labelPlacement="end"
              control={
                <Checkbox checked={tanque.grama} onChange={(e) => (tanque.grama = e.target.checked)} color="primary" />
              }
              label="Peso Médio (g) ?"
            />
          )}
          <InputNumero
            value={tanque.pesoMedio}
            onChange={handleChangePesoMedio}
            precision="3"
            label={isCamarao ? 'PLs/Grama' : `Peso Médio${tanque.grama ? '(g)' : '(kg)'}`}
          />
          <InputNumero
            value={tanque.quantidade}
            onChange={handleChangeQuantidade}
            precision="0"
            label="Quantidade Total"
          />
          {isCamarao ? (
            <InputNumero
              value={tanque.pmCamarao}
              onChange={handleChangePmCamarao}
              precision="3"
              label="Peso Médio (g)"
            />
          ) : null}
          <InputNumero
            value={tanque.tamanho}
            onChange={(value) => (tanque.tamanho = value)}
            precision="2"
            label="Tamanho Médio (cm) *Opcional"
          />

          <div style={{ width: '100%', display: 'flex' }}>
            <Botao onClick={handleClose} variant="contained" cor="#707070">
              Voltar
            </Botao>
            <Botao
              onClick={() => {
                if (tanque.grama) {
                  tanque.pesoMedio = tanque.pesoMedio / 1000;
                }
                cadastrar(tanque);
              }}
              variant="contained"
              cor="#00C853"
            >
              Cadastrar
            </Botao>
          </div>
        </ModalBody>
      </Modal>
    );
  },
);

export default SelecionarTanqueDestino;
