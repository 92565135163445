import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import useStore from '../../../services/hooks/useStore';
import { Fornecedor } from '../../Producao/Biometrias/styles';
import Botao from '../../../components/Botoes/Botao';
import useModal from '../../../services/hooks/useModal';
import { Box, IconButton, TextField, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FormFornecedorMovimentacoes from '../Fornecedor/FormFornecedorMovimentacoes';

type Props = ModalProps & {
  onConfirm(fornecedor: SelectRow): void;
};
const SelecionarFornecedor: React.FC<Props> = ({ open, handleClose, onConfirm }) => {
  const store = useStore();
  const modalFornecedor = useModal();
  const [codigo, setCodigo] = useState<string>('');
  const [fornecedor, setFornecedor] = useState<Fornecedor[]>(store.fornecedores.arr as Fornecedor[]);

  function searchCode(e: string) {
    if (e !== '') {
      const result = store.fornecedores.arr!.filter((f) => f.nome.toLowerCase().includes(e.toLocaleLowerCase()));
      setFornecedor(result);
    } else {
      setFornecedor(store.fornecedores.arr!);
    }
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>
        <Box 
          display="flex"
          justifyContent="space-between"
          width="100%"
        >
          <span>Selecionar fornecedor</span>

          <Tooltip title="Adicionar fornecedor">
            <IconButton style={{ background: 'rgba(0,0,0,0.2)' }} onClick={modalFornecedor.open}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </ModalHeader>
      <ModalBody>
        <div
          style={{ color: '#707070', width: '100%', padding: '10px', display: 'flex', justifyContent: 'space-between' }}
        >
          <TextField
            label="Pesquisa Pelo Nome"
            value={codigo}
            onChange={(e) => {
              searchCode(e.target.value);
              setCodigo(e.target.value);
            }}
          />
        </div>
        {fornecedor
          .sort((a, b) => {
            const textA = a.nome;
            const textB = b.nome;
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .map((fornecedor) => {
            return (
              <Fornecedor
                onClick={() => onConfirm({ label: fornecedor.nome, value: fornecedor.id.toString() })}
                key={fornecedor.id}
              >
                <div>
                  <b className="left">{fornecedor.nome}</b>
                </div>
              </Fornecedor>
            );
          })}
      </ModalBody>
      <div style={{ width: '80%', margin: '10px' }}>
        <Botao onClick={handleClose} cor="gray">
          Voltar
        </Botao>
      </div>

      {modalFornecedor.state ? (
        <FormFornecedorMovimentacoes
          open={modalFornecedor.state}
          handleClose={modalFornecedor.close}
          fornecedorID={0}
        />
      ) : null}
    </Modal>
  );
};

export default SelecionarFornecedor;
