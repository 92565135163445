import { apiV2, getThingsV2} from '../utils';
import { observable, computed } from 'mobx';
import { stateContext } from '../hooks/useStore';

type ChequeData = {
  propriedade: number;
  id : number;
  banco : string,
  cliente: any, 
  data_deposito: Date,
  data_recebido: Date,
  data_situacao: Date,
  fornecedor: {
    nome:string,
    id:number;
    uuid:string,
    value:any
  },
  n_folha: string, 
  nome_emitente: string ,
  observacao: string | null,
  situacao: string, 
  valor: number,
  voltou: boolean
};

class Cheques {
  @observable arr: Cheque[] | null = null;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    this.arr = await getThingsV2<Cheque>('/cheque/');
  }

  async getEverything(params?: object): Promise<Cheque[]> {
    return await getThingsV2<Cheque>('/cheque/', params);
  }

  get(id: number): Cheque {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  getContaPadrao(): Cheque {
    return this.arr!.filter((item) => item.id)[0];
  }

  // getAtivas(): Cheque[] {
  //   return this.arr!.filter((item) => !item.inativo);
  // }

  getSelectRows(): SelectRow[] {
    const padrao = this.getContaPadrao();
    const selectRows: SelectRow[] = [];

    selectRows.push({ label: padrao.nome_emitente, value: padrao.id.toString() });

    this.arr!.filter((item) => !item.id).map((item) => {
      return selectRows.push({ label: item.nome_emitente, value: item.id.toString() });
    });

    return selectRows;
  }

  // getSelectRowsUUID(): SelectRow[] {
  //   const padrao = this.getContaPadrao();
  //   const selectRows: SelectRow[] = [];

  //   selectRows.push({ label: padrao.titulo, value: padrao.uuid });

  //   this.arr!.filter((item) => !item.conta_padrao).map((item) => {
  //     return selectRows.push({ label: item.titulo, value: item.uuid });
  //   });

  //   return selectRows;
  // }

  async criar(dados: ChequeData): Promise<void> {
    const store = stateContext.state;

    await apiV2
      .post(`/cheque/?propriedade=${store.propriedade!.id}`, dados)
      .then(async () => {
        store.notificar('Cheque cadastrado com sucesso!');
        await store.cheques.populate();
      })
      .catch(() => store.notificar('Ocorreu um erro ao cadastrar o cheque!'));
  }

  async editar(chequeID: number, dados: ChequeData): Promise<void> {
    const store = stateContext.state;

    await apiV2
      .put(`/cheque/${chequeID}/?propriedade=${store.propriedade!.id}`, dados)
      .then(async () => {
        store.notificar('Cheque editado com sucesso!');
        await store.cheques.populate();
      })
      .catch(() => store.notificar('Ocorreu um erro ao editar o cheque!'));
  }
  async voltou(chequeID: number, dados: ChequeData): Promise<void> {
    const store = stateContext.state;

    await apiV2
      .patch(`/cheque/${chequeID}/?propriedade=${store.propriedade!.id}`, dados)
      .then(async () => {
        store.notificar('Cheque editado com sucesso!');
        await store.cheques.populate();
      })
      .catch(() => store.notificar('Ocorreu um erro ao editar o cheque!'));
  }

  async excluir(chequeID: number): Promise<void> {
    const store = stateContext.state;

    // if (store.movimentacoes.arr!.some((movimentacao) => movimentacao.conta.id === contaID))
    //   store.notificar('A conta contém movimentações, logo não pode ser excluída!');
    // else {
      await apiV2
        .delete(`/cheque/${chequeID}/?propriedade=${store.propriedade!.id}`)
        .then(() => store.notificar('Cheque excluído com sucesso!'));
      await store.contas.populate();
    // }
  }

  filter(callback: (item: Cheque, index: number, array: Cheque[]) => void): Cheque[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: Cheque, index: number, array: Cheque[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default Cheques;
