import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import Select from '../../../components/Select';
import { useLocalStore, observer } from 'mobx-react-lite';
import useStore from '../../../services/hooks/useStore';
import InputNumero from '../../../components/InputNumero';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Botao from '../../../components/Botoes/Botao';
import useModal from '../../../services/hooks/useModal';
import SelecionarLoteTanques from './SelecionarLoteTanques';
import { returnTanque } from '../../../services/utils/propriedade';
import { apiV2 } from '../../../services/utils';
import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Formatter from '../../../services/Formatter';
import { returnQuantidade } from '../../../services/utils/producao';
import QuantidadeModal from './QuantidadeModal';
import { Flex } from '../../../components/NavBar/styles';

type Props = ModalProps & {
  arracoamento?: Arracoamento;
  onConfirm(): void;
};
const FormArracoamento: React.FC<Props> = ({ handleClose, open, arracoamento, onConfirm }) => {
  const store = useStore();
  const modalTanques = useModal();
  const modalQuantidade = useModal();
  const [selectedLoteTanque, setSelectedLoteTanque] = useState<any>(null);
  const state = useLocalStore(() => ({
    racao: arracoamento ? { value: arracoamento?.racao.id.toString(), label: arracoamento?.racao.nome } : null,
    quantidade: arracoamento?.vez_dia || 0,
    dataInicio: new Date() as MaterialUiPickersDate,
    dataFim: new Date() as MaterialUiPickersDate,
    diffDias: 0,
    lancado: arracoamento ? arracoamento?.lancado : true,
    tanque_data: (arracoamento?.tanque_data.map((tanqueData: any) => {
      return {
        uuid: tanqueData.uuid,
        tanque: tanqueData.tanque.id,
        lote_tanque: tanqueData.lote_tanque.id,
        tipo: tanqueData.tipo,
        percentual_biomassa: tanqueData.percentual_biomassa,
        percentual_temperatura: tanqueData.percentual_temperatura,
        peso: tanqueData.peso,
        qtd_racao: tanqueData.qtd_racao,
        qtd_trato: tanqueData.qtd_trato,
        qtd_kg_trato: tanqueData.qtd_kg_trato,
        estoque: tanqueData.estoque,
        biomassa: tanqueData.biomassa,
      };
    }) || []) as TanqueData[],

    setRacao(e: SelectRow) {
      this.racao = e;
    },
  }));

  async function enviar(): Promise<void> {
    if (!state.racao) store.notificar('Informe a ração');
    else if (state.quantidade === 0) store.notificar('Informe a quantidade de vezes');
    else if (state.tanque_data.length === 0) store.notificar('Selecione um ou mais tanques');
    else {
      store.toggleLoader();
      handleClose();

      const tanqueData = state.tanque_data.map((tanqueData) => {
        if (state.lancado) {
          tanqueData.peso = tanqueData.qtd_racao;
        }

        return tanqueData;
      });

      const data = {
        vezes_dia: state.quantidade,
        racao: parseInt(state.racao.value),
        lancado: state.lancado,
        data_inicio: Formatter.dateToString(state.dataInicio),
        data_fim: Formatter.dateToString(state.dataFim),
        tanque_data: tanqueData,
        editavel: true,
      };

      await apiV2
        .post('/arracoamento/periodo/', data, {
          params: {
            propriedade: store.propriedade!.id,
          },
        })
        .then(async () => {
          store.notificar('Arraçoamento cadastrado com sucesso!');
          await store.racoes.populate();
          onConfirm();
        })
        .catch(() => store.notificar('Ocorreu um erro ao cadastrar o arraçoamento!'));

      store.toggleLoader();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function calcularDiferencaDias() {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    const utc1 = Date.UTC(state.dataInicio!.getFullYear(), state.dataInicio!.getMonth(), state.dataInicio!.getDate());
    const utc2 = Date.UTC(state.dataFim!.getFullYear(), state.dataFim!.getMonth(), state.dataFim!.getDate());

    state.diffDias = Math.floor((utc2 - utc1) / _MS_PER_DAY) + 1;
    modalTanques.open();
  }

  useEffect(() => {
    (async () => {
      store.toggleLoader();
      if (!store.lotes.arr || !store.tanques.arr || !store.loteTanque.arr)
        await Promise.all([store.loteTanque.populate(), store.lotes.populate(), store.tanques.populate()]);
      store.toggleLoader();
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Novo arraçoamento</ModalHeader>
      <ModalBody style={{ width: '700px', overflowX: 'hidden' }}>
        <Select
          value={state.racao}
          placeholder="Ração"
          onChange={state.setRacao}
          options={store.racoes.getSelectRows()}
        />

        <InputNumero
          value={state.quantidade}
          onChange={(val) => (state.quantidade = val)}
          label="Quantidade de vezes"
          precision="0"
        />
        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data Inicial"
          value={state.dataInicio}
          onChange={(date) => (state.dataInicio = date)}
        />

        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data Final"
          value={state.dataFim}
          onChange={(date) => (state.dataFim = date)}
        />
        <div
          style={{
            width: '100%',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={state.lancado}
                onChange={(e) => {
                  if (!state.lancado) {
                    state.lancado = e.target.checked;
                  }
                }}
                color="primary"
              />
            }
            label="Lançado"
          />
        </div>
        <Botao onClick={calcularDiferencaDias}>Selecione o(s) tanque(s)</Botao>
        {state.tanque_data.length > 0
          ? state.tanque_data.map((tanqueData: any) => {
              const loteTanque = store.loteTanque.get(tanqueData.lote_tanque);
              const tanque = store.tanques.get(tanqueData.tanque);

              return (
                <div
                  key={tanqueData.lote_tanque}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <p>{returnTanque(tanque)}</p>
                  <p>{Formatter.formatNumber(0, returnQuantidade(loteTanque, false))} un</p>
                  <p>{Formatter.formatNumber(3, tanqueData.qtd_racao)} kg</p>
                  <p>{Formatter.formatNumber(0, tanqueData.qtd_trato)}x</p>
                  <p>{Formatter.formatNumber(3, tanqueData.qtd_kg_trato)}kg/1x</p>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                    }}
                  >
                    <IconButton
                      onClick={() =>
                        (state.tanque_data = state.tanque_data.filter(
                          (tanqueDataMap) => tanqueDataMap.lote_tanque !== tanqueData.lote_tanque,
                        ))
                      }
                    >
                      <DeleteIcon />
                    </IconButton>

                    <IconButton
                      id="editar"
                      onClick={() => {
                        setSelectedLoteTanque({
                          ...loteTanque,
                          estoque: tanqueData.estoque,
                          biomassa: tanqueData.biomassa,
                          qtd_trato: tanqueData.qtd_trato,
                          qtd_kg_trato: tanqueData.qtd_kg_trato,
                          qtd_racao: tanqueData.qtd_racao,
                        });
                        modalQuantidade.open();
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </div>
              );
            })
          : null}
      </ModalBody>
      <Flex style={{ width: '90%', margin: 10 }}>
        <Botao onClick={handleClose} variant="contained" cor="#FC7467">
          Cancelar
        </Botao>
        <Botao onClick={enviar} variant="contained" cor="#00C853">
          Salvar
        </Botao>
      </Flex>
      {modalTanques.state ? (
        <SelecionarLoteTanques
          periodo={true}
          open={modalTanques.state}
          handleClose={modalTanques.close}
          onConfirm={(tanqueData) => {
            if (state.tanque_data.some((tqData) => tqData.lote_tanque === tanqueData.lote_tanque)) {
              state.tanque_data.map((tqData, indice) => {
                if (tqData.lote_tanque === tanqueData.lote_tanque) {
                  const uuid = state.tanque_data[indice].uuid || undefined;
                  state.tanque_data[indice] = { ...tanqueData, uuid };
                }

                return null;
              });
            } else {
              state.tanque_data.push(tanqueData);
            }
          }}
          exclude={state.tanque_data.map((tanqueData) => tanqueData.lote_tanque)}
          diasDiferenca={state.diffDias}
          states={state}
        />
      ) : null}
      {modalQuantidade.state ? (
        <QuantidadeModal
          periodo={true}
          open={modalQuantidade.state}
          handleClose={modalQuantidade.close}
          onConfirm={(tanqueData) => {
            tanqueData.tanque = selectedLoteTanque!.tanque.id;
            tanqueData.lote_tanque = selectedLoteTanque!.id;
            if (state.tanque_data.some((tqData) => tqData.lote_tanque === tanqueData.lote_tanque)) {
              state.tanque_data.map((tqData, indice) => {
                if (tqData.lote_tanque === tanqueData.lote_tanque) {
                  const uuid = state.tanque_data[indice].uuid || undefined;
                  state.tanque_data[indice] = { ...tanqueData, uuid };
                }

                return null;
              });
            } else {
              state.tanque_data.push(tanqueData);
            }
          }}
          loteTanque={selectedLoteTanque!}
          states={state}
          diasDiferenca={0}
          quantidadeAnimais={0}
        />
      ) : null}
    </Modal>
  );
};

export default observer(FormArracoamento);
