import React from 'react';
import { TextField } from '@material-ui/core';
import { observer, useLocalStore } from 'mobx-react-lite';
import useStore from '../../../services/hooks/useStore';
import { Modal, ModalBody, ModalHeader } from '../../../components/Modais/styles';
import InputNumero from '../../../components/InputNumero';
import Botao from '../../../components/Botoes/Botao';
import Select from '../../../components/Select';
import { api } from '../../../services/utils';

type CadastrarBancadaProps = ModalProps & {};

type State = {
  nome: string;
  tamanho: number;
  capacidade: number;
  aguaOrigem?: SelectRow | null;
  situacaoTanque?: SelectRow | null;
  reservatorio?: SelectRow | null;
};

const CadastrarBancada = ({ open, handleClose }: CadastrarBancadaProps) => {
  const store = useStore();
  const state = useLocalStore(
    (): State => ({
      nome: '',
      tamanho: 0,
      capacidade: 0,
      aguaOrigem: null,
      situacaoTanque: { label: 'LIVRE', value: '2' },
      reservatorio: null,
    }),
  );

  const cadastrar = async () => {
    if (state.tamanho === 0) return store.notificar('Informe o tamanho da bancada');
    if (state.capacidade === 0) return store.notificar('Informe a capacidade da bancada');
    if (!state.aguaOrigem) return store.notificar('Informe o origem da bancada');
    if (!state.reservatorio) return store.notificar('Informe o reservatório da bancada');
    if (!state.situacaoTanque) return store.notificar('Informe a situação da bancada');    
    else if (!state.reservatorio) store.notificar('Informe o reservatorio da bancada');
    store.toggleLoader();
    const data = {
      propriedade: store.propriedade?.id,
      agua_origem: parseInt(state.aguaOrigem.value),
      situacao_tanque: parseInt(state.situacaoTanque.value),
      tanque_tipo: 6,
      nome: state.nome,
      tamanho: state.tamanho,
      capacidade: state.capacidade,
      codigo: 'Bancada',
      reservatorio:state.reservatorio.value
    };
    await api
      .post('/tanques/', data)
      .then(async () => {
        store.notificar('Bancada criado com sucesso');
        // await store.bancadas.populate();
      })
      .catch(() => store.notificar('Erro ao criar bancada'));
    store.toggleLoader();
    handleClose();
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Novo Reservatório</ModalHeader>
      <ModalBody>
        <TextField label="Nome" value={state.nome} onChange={(e) => (state.nome = e.target.value)} />
        <InputNumero
          label="Quantidade de Perfis"
          value={state.tamanho}
          onChange={(value) => (state.tamanho = value)}
          precision="0"
        />
        <InputNumero
          label="Quantidade de Furos na Bancada"
          value={state.capacidade}
          onChange={(value) => (state.capacidade = value)}
          precision="0"
        />
        <div style={{ width: '100%' }}>
          <Select
            placeholder="Origem da água"
            value={state.aguaOrigem}
            onChange={(e) => (state.aguaOrigem = e)}
            options={store.origensDaAgua.getSelectRows()}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Select
            placeholder="Selecione o reservatorio"
            value={state.reservatorio}
            onChange={(e) => (state.reservatorio = e)}
            options={store.reservatorios.getSelectRows()}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Select
            placeholder="Selecione a situação"
            value={state.situacaoTanque}
            onChange={(e) => (state.situacaoTanque = e)}
            options={store.situacoesDeTanque.getSelectRows()}
          />
        </div>

        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao onClick={cadastrar} variant="contained" cor="#00C853">
            Salvar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(CadastrarBancada);
