import React, { useEffect, useState } from 'react';
import MaterialTable, { Column } from 'material-table';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Link, useHistory } from 'react-router-dom';
import { IconButton, Tooltip } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CachedIcon from '@material-ui/icons/Cached';
import GetAppIcon from '@material-ui/icons/GetApp';

import { Conteudo, NewBody } from '../../../components/Body';
import Botao from '../../../components/Botoes/Botao';
import { Flex } from '../../../components/NavBar/styles';
import Select from '../../../components/Select';
import { newConfigRacoes } from '../../../components/Tabela';
import Formatter from '../../../services/Formatter';
import useStore from '../../../services/hooks/useStore';
import { apiMain, apiV2, aqReports } from '../../../services/utils';
import { permissoes } from '../../../services/utils/permissoes';
// import { responseData } from './data';

const ArracoamentoPrevistoXRealizado: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.PAINEL_ZOOTECNICO_LEITURA, history);

  const state = useLocalStore(() => ({
    data_ini: null as MaterialUiPickersDate,
    data_fim: null as MaterialUiPickersDate,
    prog_alimentar: null as SelectRow | null,

    rows: [] as object[],
    totais: {
      dif: 0,
      qtd_dias: 0,
      rac_prev: 0,
      rac_real: 0,
    },
    sync: false,
  }));

  const [programasAlimentaresOptions, setProgramasAlimentaresOptions] = useState<any[]>([]);
  function getProgramasAlimentares() {
    apiV2
      .get('/programas-alimentares/', {
        params: { prop: store.propriedade!.id, page_size: 50, page: 1 },
      })
      .then(async (res) => {
        const data = res.data;
        if (res.data.next) {
          do {
            const request = await apiV2.get(data.next);

            data.results = [...data.results, ...request.data.results];
            data.next = request.data.next;
          } while (data.next !== null);
        }
        const programas = data.results ? data.results : data;
        setProgramasAlimentaresOptions(
          programas.map((programa: any) => {
            const isSemanal = programa.tipo.slug === 'SEMANAL';
            const isUnique = programa.semanas.length === 1;
            const strings = ['semanas', 'semana', 'dias', 'dia'];
            const dias = `${programa.semanas.length} ${
              isSemanal ? (isUnique ? strings[1] : strings[0]) : isUnique ? strings[3] : strings[2]
            }`;
            return { label: `${programa.nome} - ${dias}`, value: programa.uuid };
          }),
        );
      });
  }

  const columns: Column<object>[] = [
    {
      field: 'tanque',
      title: 'TANQUES',
      render(rowData: any) {
        return rowData.tanque.nome;
      },
      ...(true && ({ width: 120 } as object)),
    },
    {
      field: 'lote',
      title: 'LOTE',
      render(rowData: any) {
        return rowData.lote.nome;
      },
      ...(true && ({ width: 120 } as object)),
    },
    {
      field: 'qtd_dias',
      title: 'QTD DIAS',
      ...(true && ({ width: 120 } as object)),
    },
    {
      field: 'nome_racao',
      title: 'NOME RAÇÃO',
      render(rowData: any) {
        return rowData.racao.nome;
      },
      ...(true && ({ width: 120 } as object)),
    },
    {
      field: 'rac_pre',
      title: 'RAÇÃO PREV (Kg)',
      render(rowData: any) {
        return Formatter.formatNumber(3, rowData.rac_pre);
      },
      ...(true && ({ width: 120 } as object)),
    },
    {
      field: 'rac_real',
      title: 'RAÇÃO REAL (Kg)',
      render(rowData: any) {
        return Formatter.formatNumber(3, rowData.rac_real);
      },
      ...(true && ({ width: 120 } as object)),
    },
    {
      field: 'dif',
      title: '% DIF',
      render(rowData: any) {
        return Formatter.formatNumber(2, rowData.dif ? (rowData.dif * 100) : 0);
      },
      ...(true && ({ width: 120 } as object)),
    },
    {
      field: 'gpd',
      title: 'GPD',
      ...(true && ({ width: 120 } as object)),
    },
    {
      field: 'gp',
      title: 'GP',
      ...(true && ({ width: 120 } as object)),
    },
    {
      field: 'ca',
      title: 'CA',
      ...(true && ({ width: 120 } as object)),
    },
  ];

  async function sync(): Promise<void> {
    store.toggleLoader();
    getProgramasAlimentares();
    state.sync = true;
    store.toggleLoader();
  }

  async function getRelatorio(): Promise<void> {
    store.toggleLoader();

    if (state.data_ini === null || state.data_fim === null || state.prog_alimentar === null) {
      store.notificar('Selecione o programa alimentar e intervalo de datas inicial e final');
    } else {
      let response: any = await aqReports.get(`/relatorio/${store.propriedade!.uuid}/racao/consumo/tanque/curva`, {
        params: {
          prog_alimentar: state.prog_alimentar.value,
          data_ini: state.data_ini ? Formatter.dateToString(state.data_ini, '/') : undefined,
          data_fim: state.data_fim ? Formatter.dateToString(state.data_fim, '/') : undefined,
        },
      });
      state.rows = response.data.results;
      state.totais = response.data.totalizadores;

      // response = responseData;
      // state.rows = response.results;
      // state.totais = response.totalizadores;
    }
    store.toggleLoader();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getCSV() {
    try {
      if (state.data_ini === null || state.data_fim === null || state.prog_alimentar === null) {
        store.notificar('Selecione o programa alimentar e intervalo de datas inicial e final');
      } else {
        const csv = await apiMain.get(`/gerador/csv/relatorio/${store.propriedade?.id}/racao/consumo/tanque/curva/`, {
          params: {
            authori: store.token,
            prog_alimentar: state.prog_alimentar.value,
            data_ini: state.data_ini ? Formatter.dateToString(state.data_ini, '/') : undefined,
            data_fim: state.data_fim ? Formatter.dateToString(state.data_fim, '/') : undefined,
          },
        });

        window.open(csv.request.responseURL, '_blank');
      }
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getPDF() {
    try {
      if (state.data_ini === null || state.data_fim === null || state.prog_alimentar === null) {
        store.notificar('Selecione o programa alimentar e intervalo de datas inicial e final');
      } else {
        const pdf = await apiMain.get(`/gerador/pdf/relatorio/${store.propriedade?.id}/racao/consumo/tanque/curva/`, {
          params: {
            authori: store.token,
            prog_alimentar: state.prog_alimentar.value,
            data_ini: state.data_ini ? Formatter.dateToString(state.data_ini, '/') : undefined,
            data_fim: state.data_fim ? Formatter.dateToString(state.data_fim, '/') : undefined,
          },
        });
        window.open(pdf.request.responseURL, '_blank');
      }
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }

  function limparFiltros(): void {
    state.data_ini = null;
    state.data_fim = null;
    state.prog_alimentar = null;
    state.rows = [];
    state.totais = {
      dif: 0,
      qtd_dias: 0,
      rac_prev: 0,
      rac_real: 0,
    };
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Arraçoamento Previsto X Realizado'), []);

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  return state.sync ? (
    <NewBody>
      <Flex style={{ justifyContent: 'space-between' }}>
        {/* Navegacao Anterior */}
        <Link
          to={'/relatorios/relatorios-zootecnicos'}
          style={{ color: '#049CE7', textDecoration: 'none', fontFamily: 'Roboto' }}
        >
          <Flex>
            <img src={'/images/returnArrow.svg'} />
            <div style={{ paddingLeft: '5px', fontFamily: 'Roboto', color: '#049CE7' }}>Lista de Relatórios</div>
          </Flex>
        </Link>

        <Flex>
          <Tooltip title="Exportar para CSV">
            <IconButton className="blue" onClick={getCSV}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Baixar PDF">
            <IconButton className="blue" onClick={getPDF}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sincronizar">
            <IconButton
              className="blue"
              onClick={() => {
                sync();
                getRelatorio();
              }}
            >
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>

      <div style={{ display: 'grid', gridTemplateColumns: '300px 1fr', gap: 10 }}>
        <Conteudo>
          <div style={{ width: '100%', marginBottom: 10 }}>
            <Select
              placeholder="Programa Alimentar"
              value={state.prog_alimentar}
              options={programasAlimentaresOptions}
              onChange={(e: any) => (state.prog_alimentar = e)}
            />
          </div>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Inicial"
            value={state.data_ini}
            onChange={(date) => (state.data_ini = date!)}
            style={{ width: '100%' }}
          />

          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Final"
            value={state.data_fim}
            onChange={(date) => (state.data_fim = date!)}
            style={{ width: '100%' }}
          />

          <Botao onClick={getRelatorio} cor="#00C853">
            Gerar Relatório
          </Botao>
          <Botao onClick={limparFiltros} cor="#FC7467">
            Limpar Filtros
          </Botao>
        </Conteudo>
        <Conteudo style={{ padding: 0, maxWidth: '1fr', overflow: 'auto' }}>
          <MaterialTable
            columns={columns}
            data={state.rows}
            options={{
              ...newConfigRacoes.options,
              paging: false,
            }}
            localization={newConfigRacoes.localization}
            style={newConfigRacoes.style}
          />
          <Flex
            style={{
              width: '100%',
              padding: '1rem',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0 auto',
              marginBottom: '-15px',
              border: '1px solid #999',
            }}
          >
            <div>
              <p>TOTAL RAÇÃO PRE. (Kg)</p>
              <strong>{Formatter.formatNumber(3, state.totais?.rac_prev ?? 0)} </strong>
            </div>
            <div>
              <p>TOTAL RAÇÃO REAL (Kg)</p>
              <strong>{Formatter.formatNumber(3, state.totais?.rac_real ?? 0)} </strong>
            </div>
            <div>
              <p>DIF. TOTAL (%):</p>
              <strong>{Formatter.formatNumber(2, state.totais?.dif ? state.totais.dif * 100 : 0)} </strong>
            </div>
          </Flex>
        </Conteudo>

        {/* <Conteudo overflowY>
          <ReactApexChart options={graficoDonut.options} series={graficoDonut.series} type="donut" height="300px" />
        </Conteudo> */}
      </div>
    </NewBody>
  ) : null;
};

export default observer(ArracoamentoPrevistoXRealizado);
