import styled from 'styled-components';

export const RacaoInfo = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 5px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;

  width: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);

  .right {
    text-align: right;
  }
  .left {
    text-align: left;
  }

  &:hover {
    background: #eeeeee;
  }
`;
