import React, { useEffect } from 'react';
import useStore from '../../services/hooks/useStore';
import { MenuGrade, HomeLink, Body, SquareContainer } from './styles';
import { Flex } from '../../components/NavBar/styles';
import { useHistory } from 'react-router-dom';
import { permissoes } from '../../services/utils/permissoes';

const Home: React.FC = () => {
  const store = useStore();
  const history = useHistory();

  // eslint-disable-next-line
  useEffect(() => store.setTitulo(''), []);

  return (
    <Body>
      <p>Olá, {store.usuario.nome || 'Usuário'}</p>
      <h2>O que deseja fazer hoje?</h2>
      <Flex style={{ height: '95%' }}>
        <MenuGrade>
          <HomeLink
            onClick={() => {
              if (store.checkPermissions(permissoes.PROPRIEDADE_LEITURA)) history.push('/propriedade/tanques');
              else store.notificar('Você não tem permissão para acessar este módulo!');
            }}
          >
            <SquareContainer>
              <img src="/images/propriedade.svg" alt="Tanques/Viveiros" />
            </SquareContainer>
            Tanques/Viveiros
          </HomeLink>
          <HomeLink
            onClick={() => {
              if (store.checkPermissions(permissoes.PRODUCAO_POVOAMENTO_LEITURA)) history.push('/producao/povoamento');
              else store.notificar('Você não tem permissão para acessar este módulo!');
            }}
          >
            <SquareContainer>
              <img src="/images/producao.svg" alt="Produção" />
            </SquareContainer>
            Produção
          </HomeLink>
          <HomeLink
            onClick={() => {
              if (store.checkPermissions(permissoes.CONTROLE_RACAO_ARRACOAMENTO_LEITURA))
                history.push('/producao/arracoamento');
              else store.notificar('Você não tem permissão para acessar este módulo!');
            }}
          >
            <SquareContainer>
              <img src="/images/arracoamento.svg" alt="Arraçoamento" />
            </SquareContainer>
            Arraçoamento
          </HomeLink>
          <HomeLink
            onClick={() => {
              if (store.checkPermissions(permissoes.VENDAS_MINHAS_VENDAS_LEITURA)) history.push('/vendas');
              else store.notificar('Você não tem permissão para acessar este módulo!');
            }}
          >
            <SquareContainer>
              <img src="/images/venda.svg" alt="Vendas" />
            </SquareContainer>
            Vendas
          </HomeLink>
          <HomeLink
            onClick={() => {
              if (store.checkPermissions(permissoes.FINANCEIRO_PAINEL_FINANCEIRO_LEITURA))
                history.push('/financeiro/painel/dashboard');
              else store.notificar('Você não tem permissão para acessar este módulo!');
            }}
          >
            <SquareContainer>
              <img src="/images/financeiro.svg" alt="Financeiro" />
            </SquareContainer>
            Financeiro
          </HomeLink>
          <HomeLink
            onClick={() => {
              if (store.checkPermissions(permissoes.QUALIDADE_E_SANIDADE_QUALIDADE_DA_AGUA_LEITURA))
                history.push('/qualidade-da-agua');
              else store.notificar('Você não tem permissão para acessar este módulo!');
            }}
          >
            <SquareContainer>
              <img src="/images/qualidade_da_agua.svg" alt="Qualidade da Água" />
            </SquareContainer>
            Qualidade da Água
          </HomeLink>
        </MenuGrade>
      </Flex>
    </Body>
  );
};

export default Home;
