import { getThings, api } from '../utils';
import { observable, computed } from 'mobx';
import { stateContext } from '../hooks/useStore';

type FornecedorData = {
  propriedade: number;
  fornecedor_tipo: string;
  nome: string;
  cpf_cnpj: string | null;
  responsavel: string;
  fone: string;
  whatsapp: string;
  email: string;
  endereco: string;
  estado: number;
  cidade: number;
};

class Fornecedores {
  @observable arr: Fornecedor[] | null = null;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    this.arr = await getThings<Fornecedor>('/fornecedor/');
  }

  async getEverything(params?: object): Promise<Fornecedor[]> {
    return await getThings<Fornecedor>('/fornecedor/', params);
  }

  get(id: number): Fornecedor {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  getByTipo(tipo: number): Fornecedor[] {
    return this.arr!.filter((item) => item.fornecedor_tipo.id === tipo);
  }

  getSelectRows(): SelectRow[] {
    return this.arr!.slice()
      .sort((a: Fornecedor, b: Fornecedor) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      })
      .map((item: Fornecedor) => {
        return { label: item.nome, value: item.id.toString() };
      });
  }

  async criar(dados: FornecedorData): Promise<void> {
    const store = stateContext.state;

    await api
      .post('/fornecedor/', dados)
      .then(async () => {
        store.notificar('Fornecedor cadastrado com sucesso!');
        await store.fornecedores.populate();
      })
      .catch(() => store.notificar('Ocorreu um erro ao cadastrar o fornecedor!'));
  }

  async editar(fornecedorID: number, dados: FornecedorData): Promise<void> {
    const store = stateContext.state;

    await api
      .put(`/fornecedor/${fornecedorID}/?propriedade=${store.propriedade!.id}`, dados)
      .then(async () => {
        store.notificar('Fornecedor editado com sucesso!');
        await store.fornecedores.populate();
      })
      .catch(() => store.notificar('Ocorreu um erro ao editar o fornecedor!'));
  }

  async excluir(fornecedorID: number): Promise<void> {
    const store = stateContext.state;  
    try {
      await api.delete(`/fornecedor/${fornecedorID}/?propriedade=${store.propriedade!.id}`);
      store.notificar('Fornecedor excluído com sucesso!');
      await store.fornecedores.populate();
    } catch (error: any) {
      const errorMessage = error?.response?.data?.msg;
      store.notificar(errorMessage);
    }
  }
  

  filter(callback: (item: Fornecedor, index: number, array: Fornecedor[]) => void): Fornecedor[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: Fornecedor, index: number, array: Fornecedor[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default Fornecedores;
