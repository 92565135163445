import React, { useEffect, useState } from 'react';
import useStore from '../../../services/hooks/useStore';
import { useLocalStore, observer } from 'mobx-react-lite';
import { Modal, ModalHeader, ModalBody, Lote } from '../../../components/Modais/styles';
import Botao from '../../../components/Botoes/Botao';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Flex } from '../../../components/NavBar/styles';
import { TextField, Checkbox, FormControlLabel, Box, IconButton, Grid, Tooltip } from '@material-ui/core';
import InputNumero from '../../../components/InputNumero';
import { KeyboardDatePicker } from '@material-ui/pickers';
import useModal from '../../../services/hooks/useModal';
import SelecionarFornecedor from './SelecionarFornecedor';
import { Categoria, Categorias, Parcela } from '../../Vendas/MinhasVendas/styles';
import Select from '../../../components/Select';
import Formatter from '../../../services/Formatter';
import syncModel from '../../../services/models/Sync';
import NewSelect from '../../../components/Select/newSelect';
import Edit from '@material-ui/icons/Edit';
import { Delete } from '@material-ui/icons';
import { ModalMovimentacaoItem } from './ModalMovimentacaoItem';
import { MovimentacaoData } from '../../../services/models/Movimentacoes';
import { apiV2 } from '../../../services/utils';
import ParcelaParcial from '../../../components/Modais/ParcelaParcial';
import AddIcon from '@material-ui/icons/Add';
import FormCliente from '../../Vendas/Clientes/FormCliente';

type State = {
  descricao: string;
  valorTotal: number;
  quantidade: number;
  valorUnitario: number;
  valorFrete: number;
  outrosValores: number;
  data: MaterialUiPickersDate;
  documento: string;
  categoria: SelectRow | null;
  conta: SelectRow;
  fornecedor: SelectRow | null;
  qtdParcelas: number;
  parcelas: ParcelaData[];
  pagamento: SelectRow;
  sync: boolean;
  setPagamento(tipo: SelectRow): void;
  setConta(tipo: SelectRow): void;
  setQtdParcelas(value: number): void;
  somaParcelas(): number;
  somaValorItems(): number;
  somaValorTotal(): number;
  somaQuantidadeItems(): number;
  itens: DespesaItemCriacao[];
  props: ParcelaData;
  cliente: SelectRow | null;
  estrangeira: boolean;
};

type Props = ModalProps & {
  onConfirm: () => void;
  despesa?: Movimentacao | null;
  tipo?: MovimentacaoTipo;
  soParcelas?: boolean;
};

const ModalMovimentacao: React.FC<Props> = ({
  open,
  handleClose,
  onConfirm,
  despesa: editMovimentacao,
  soParcelas = false,
  tipo,
}) => {
  const store = useStore();
  const modalFornecedor = useModal();
  const modalDepesaItem = useModal();
  const modalParceal = useModal();

  const tipoCategoria = (editMovimentacao?.categoria?.tipo ?? tipo) as MovimentacaoTipo;
  const categoriasOptions = store.categorias.getByTipo(tipoCategoria).map((categoria) => {
    return { label: categoria.nome, value: categoria.id.toString() };
  });

  const eProduto = tipoCategoria === 'produtos';
  const eVenda = tipoCategoria === 'vendas';
  const eEdicao = Boolean(editMovimentacao);
  const titulo = (eEdicao ? 'Editar' : 'Criar') + ' ' + tipoCategoria;
  const tituloProduto = (eEdicao ? 'Editar' : 'Nova') + ' Venda de Produto';
  const tituloFinal = eProduto ? tituloProduto : titulo;

  const mostrarFornecedor = !eProduto && !eVenda;
  const temParcelaPagar = eEdicao && Boolean(editMovimentacao?.parcelas.find((m) => m.recebido));

  const etapaInicial = soParcelas ? 3 : 0;
  const [etapa, setEtapa] = useState(etapaInicial);
  const [itemSelecionado, setItemSelecionado] = useState<DespesaItemCriacao | null>(null);

  const [pesquisaCliente, setPesquisaCliente] = useState('');
  const modalCliente = useModal();

  const state = useLocalStore(
    (): State => ({
      descricao: '',
      valorTotal: 0,
      quantidade: 0,
      valorUnitario: 0,
      valorFrete: 0,
      outrosValores: 0,
      data: new Date(),
      documento: '',
      categoria: null,
      conta: {
        label: store.contas.getContaPadrao()?.titulo || '',
        value: store.contas.getContaPadrao()?.id.toString(),
      },
      fornecedor: null,
      sync: false,
      // Parcelas
      qtdParcelas: 1,
      parcelas: [
        {
          num_parcela: 1,
          data_pagamento: Formatter.dateToString(new Date()),
          recebido: false,
          pagamento_tipo: store.tiposDePagamento.arr![0].id,
          valor: 0,
          conta: store.contas.arr![0].id,
        },
      ],
      pagamento: store.tiposDePagamento.getSelectRows()[0],
      itens: [],
      cliente: null,
      estrangeira: false,

      props: {} as ParcelaData,

      setPagamento(tipo) {
        this.pagamento = tipo;
        this.parcelas.map((parcela) => {
          return (parcela.pagamento_tipo = parseInt(tipo.value));
        });
      },
      setConta(tipo: any) {
        this.conta = tipo;
        this.parcelas.map((parcela) => {
          return (parcela.conta = parseInt(tipo.value));
        });
      },
      setQtdParcelas(value) {
        this.qtdParcelas = value;
        this.parcelas = [];

        for (let num_parcela = 0; num_parcela < this.qtdParcelas; num_parcela++) {
          const data: MaterialUiPickersDate = new Date(state.data!);
          data.setMonth(data.getMonth() + num_parcela);
          const valorDaParcela = this.valorTotal / this.qtdParcelas;
          this.parcelas.push({
            num_parcela: num_parcela + 1,
            data_pagamento: Formatter.dateToString(data),
            recebido: false,
            pagamento_tipo: parseInt(this.pagamento.value),
            conta: parseInt(this.conta.value),
            valor: Number(valorDaParcela.toFixed(2)),
          });
        }
      },

      somaParcelas() {
        let valor = 0;
        this.parcelas.map((parcela) => {
          return (valor += parcela.valor as number);
        });
        return valor;
      },
      somaValorItems() {
        let valor = 0;
        this.itens.map((item) => (valor += item.valor_total_item));
        return valor;
      },
      somaQuantidadeItems() {
        let valor = 0;
        this.itens.map((item) => (valor += item.quantidade));
        return valor;
      },
      somaValorTotal() {
        let total = this.somaValorItems() + this.valorFrete + this.outrosValores;
        return total;
      },
    }),
  );

  function fecharModalDespesasItem() {
    modalDepesaItem.close();
    setItemSelecionado(null);
  }

  function selecionarItem(item: DespesaItemCriacao) {
    setItemSelecionado(item);
    modalDepesaItem.open();
  }

  function adicionarItem(dados: DespesaItemCriacao) {
    state.itens = [...state.itens, dados];
  }

  function removerItem(index: number) {
    if (index >= 0 && index < state.itens.length) {
      const novosItens = [...state.itens];
      novosItens.splice(index, 1);
      state.itens = novosItens;
    }
  }

  function atualizarItem(id: number, dados: DespesaItemCriacao) {
    const index = state.itens.findIndex((item) => item.id === id);
    if (index !== -1) {
      const novosItens = [...state.itens];
      novosItens[index] = dados;
      state.itens = novosItens;
    }
  }

  async function cadastrar(): Promise<void> {
    const conta = store.contas.get(parseInt(state.conta.value));

    if (!conta) return;
    let dataConta;

    if (conta.data_inicio) {
      dataConta = new Date(
        conta.data_inicio!.substr(3, 2) + '/' + conta.data_inicio!.substr(0, 2) + '/' + conta.data_inicio!.substr(6, 4),
      );
    } else {
      dataConta = new Date(
        conta.created_at!.substr(3, 2) + '/' + conta.created_at!.substr(0, 2) + '/' + conta.created_at!.substr(6, 4),
      );
    }

    // if (!state.descricao) store.notificar('Descreva a despesa'); else
    if (!state.valorTotal) store.notificar('Informe o valor total');
    else if (!state.quantidade) store.notificar('Informe a quantidade');
    else if (!state.categoria) store.notificar('Informe a categoria');
    else if (!state.fornecedor && !eProduto) store.notificar('Informe o fornecedor');
    else if (!state.cliente && eProduto) store.notificar('Informe o cliente');
    else if (state.data!.getTime() < dataConta.getTime()) {
      store.notificar('A data da moviemntação não pode ser anterior a da criação da conta');
    } else if (!state.qtdParcelas) {
      store.notificar('Informe a quantidade de parcelas');
    } else if (state.parcelas.some((parcela) => !parcela.valor)) {
      store.notificar('O valor de uma parcela não pode ser 0 ou nulo');
    } else if (
      state.valorTotal - Number(state.somaParcelas().toFixed(2)) > 1 ||
      state.valorTotal - Number(state.somaParcelas().toFixed(2)) < -1
    ) {
      store.notificar('A soma das parcelas deve coincidir com o valor total da venda.');
    } else {
      store.toggleLoader();
      const diferenca = state.valorTotal - state.somaParcelas();
      if (diferenca >= -1 && diferenca <= 1 && diferenca !== 0) {
        Number((state.parcelas[state.qtdParcelas - 1].valor += diferenca).toFixed(2));
        store.notificar(
          'A soma das parcelas deve coincidir com o valor total da venda. Ajustamos o valor da última parcela!',
        );
      }

      const parcelas = state.parcelas.map((parcela) => {
        return {
          ...parcela,
          valor: Number(parcela.valor.toFixed(2)),
        };
      });
      const fornecedor = !eProduto && state.fornecedor ? parseInt(state.fornecedor.value) : undefined;
      const cliente = eProduto && state.cliente ? parseInt(state.cliente.value) : undefined;
      let data: MovimentacaoData = {
        propriedade: store.propriedade!.id,
        documento: state.documento,
        data: Formatter.dateToString(state.data),
        conta: parseInt(state.conta.value),
        categoria: parseInt(state.categoria.value),
        lote_tanque_movimentacao: [],
        fornecedor,
        cliente,
        valor_frete: state.valorFrete,
        valor_outros: state.outrosValores,
      };

      const itensFinal = state.itens.map((item) => ({
        id: item.id,
        categoria: item.categoria,
        descricao: item.descricao,
        quantidade: item.quantidade,
        valor_uni_item: item.valor_uni_item,
        valor_total_item: item.valor_total_item,
      }));

      if (editMovimentacao) {
        await store.movimentacoes.editarDespesa(editMovimentacao.id, data, parcelas);
        Promise.all([
          ...itensFinal.map((item) => {
            apiV2.put(`/movimentacao-item/${item.id}/?propriedade=${store.propriedade!.id}`, item);
          }),
        ]);
      } else {
        data = {
          ...data,
          parcelas,
          itens: itensFinal as any,
        };
        await store.movimentacoes.criar(data);
      }

      store.toggleLoader();
      handleClose();
      onConfirm();
    }
  }

  useEffect(() => {
    if (state.valorTotal && state.quantidade) state.valorUnitario = state.valorTotal / state.quantidade;

    if (state.valorTotal) {
      const somaParcelas = state.parcelas.reduce((total, parcela) => {
        return total + parcela.valor;
      }, 0);
      const deveAtualizarParcelas = Math.abs(somaParcelas - state.valorTotal) > 1;
      if (deveAtualizarParcelas) {
        state.parcelas.map((_parcela, index) => {
          return (state.parcelas[index].valor = +(state.valorTotal / state.qtdParcelas).toFixed(3));
        });
      }
    }
  }, [state.valorTotal, state.quantidade]);

  async function sync(): Promise<void> {
    await syncModel.movimentacoesModal();
    state.sync = true;
  }

  useEffect(() => {
    store.propriedade && sync();
  }, [store.propriedade]);

  useEffect(() => {
    state.valorTotal = state.somaValorTotal();
    state.quantidade = state.somaQuantidadeItems();
  }, [state.itens, state.valorFrete, state.outrosValores]);

  useEffect(() => {
    if (editMovimentacao) {
      state.descricao = editMovimentacao.descricao;
      state.valorTotal = editMovimentacao.valor_total;
      state.quantidade = editMovimentacao.quantidade;
      state.valorUnitario = editMovimentacao.valor_unitario;
      state.documento = editMovimentacao.documento;
      state.qtdParcelas = editMovimentacao.parcelas.length || 1;
      state.outrosValores = editMovimentacao.valor_outros || 0;
      state.valorFrete = editMovimentacao.valor_frete || 0;
      state.parcelas = editMovimentacao.parcelas as ParcelaData[];
      state.pagamento = store.tiposDePagamento.getSelectRows()[0];
      state.data = new Date(
        editMovimentacao.data.substr(3, 2) +
          '/' +
          editMovimentacao.data.substr(0, 2) +
          '/' +
          editMovimentacao.data.substr(6, 4),
      );

      state.itens = editMovimentacao.itens;
      state.conta = {
        label: editMovimentacao.conta.titulo,
        value: editMovimentacao.conta.id,
      };
      state.fornecedor = editMovimentacao.fornecedor
        ? {
            label: editMovimentacao.fornecedor!.nome,
            value: editMovimentacao.fornecedor!.id.toString(),
          }
        : {
            label: '',
            value: '',
          };
      state.conta = {
        label: store.contas.getContaPadrao()?.titulo || '',
        value: store.contas.getContaPadrao()?.id.toString(),
      };
      state.cliente = editMovimentacao.cliente
        ? {
            label: editMovimentacao.cliente!.nome,
            value: editMovimentacao.cliente!.id.toString(),
          }
        : {
            label: '',
            value: '',
          };
      state.categoria = categoriasOptions.find((cat) => cat.value === editMovimentacao.categoria.id.toString()) ?? null;
    }
  }, [editMovimentacao]);

  useEffect(() => {
    if (editMovimentacao) {
      if (
        editMovimentacao.categoria!.nome === 'Insumos' ||
        editMovimentacao.descricao?.includes('Povoamento') ||
        editMovimentacao.descricao?.includes('Compra de ração')
      ) {
        state.estrangeira = true;
      } else {
        state.estrangeira = false;
      }
    }
  }, [editMovimentacao]);

  if (!state.sync) return null;

  function renderizarEtapa0() {
    if (!eProduto || eEdicao) {
      setEtapa(1);
      return;
    }
    return (
      <Modal onClose={handleClose} open={open}>
        <ModalHeader>Selecionar Produto</ModalHeader>
        <ModalBody style={{ width: 950 }}>
          <Box width="100%">
            <Categorias>
              {store.categorias.arr &&
                store.categorias.getByTipo('produtos').map((categoria) => (
                  <Categoria
                    onClick={() => {
                      state.categoria = {
                        value: categoria.id.toString(),
                        label: categoria.nome,
                      };
                      setEtapa(1);
                    }}
                    key={categoria.id}
                  >
                    <div>
                      <img src="/images/peixe.png" alt={categoria.nome} />
                    </div>
                    <p>{categoria.nome}</p>
                  </Categoria>
                ))}
            </Categorias>
            <Flex>
              <Botao onClick={handleClose} variant="contained" cor="#FC7467">
                Cancelar
              </Botao>
              <Botao disabled={!state.categoria} onClick={() => setEtapa(1)} variant="contained" cor="#00C853">
                Avançar
              </Botao>
            </Flex>
          </Box>
        </ModalBody>
      </Modal>
    );
  }

  function renderizarEtapa1() {
    if (!eProduto || eEdicao) {
      setEtapa(2);
      return;
    }
    return (
      <Modal onClose={handleClose} open={open}>
        <ModalHeader>
          <Box display="flex" justifyContent="space-between">
            <span>Selecionar Cliente</span>
            {eProduto && !eEdicao && (
              <Tooltip title="Adicionar cliente">
                <IconButton style={{ background: 'rgba(0,0,0,0.2)' }} onClick={modalCliente.open}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </ModalHeader>
        <ModalBody style={{ width: 950 }}>
          <Box width="100%">
            <Flex style={{ flexDirection: 'column', width: '100%' }}>
              <TextField
                label="Pesquisa Pelo Nome"
                value={pesquisaCliente}
                onChange={(e) => {
                  setPesquisaCliente(e.target.value);
                }}
              />
              {store.clientes
                .filter((cliente) => {
                  if (pesquisaCliente)
                    return cliente.nome.toLocaleLowerCase().includes(pesquisaCliente.toLocaleLowerCase());
                  else return cliente;
                })
                .sort((a: any, b: any) => (a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0))
                .map((cliente: any) => {
                  return (
                    <Lote
                      onClick={() => {
                        state.cliente = {
                          label: cliente.nome,
                          value: cliente.id.toString(),
                        };
                        setEtapa(2);
                      }}
                      key={cliente.id}
                    >
                      <b>{cliente.nome}</b>
                    </Lote>
                  );
                })}
            </Flex>
            <Flex>
              <Botao onClick={() => setEtapa(0)} variant="contained" cor="#FC7467">
                Voltar
              </Botao>
              <Botao disabled={!state.cliente} onClick={() => setEtapa(2)} variant="contained" cor="#00C853">
                Avançar
              </Botao>
            </Flex>
          </Box>
        </ModalBody>
        {modalCliente.state && <FormCliente open={modalCliente.state} handleClose={modalCliente.close} clienteID={0} />}
      </Modal>
    );
  }

  function renderizarEtapa2() {
    return (
      <Modal onClose={handleClose} open={open}>
        <ModalHeader>{tituloFinal}</ModalHeader>
        <ModalBody style={{ width: 950 }}>
          <Box width="100%">
            {mostrarFornecedor && (
              <Select
                placeholder="Fornecedor"
                value={state.fornecedor}
                onFocus={(e) => {
                  e.target.blur();
                  modalFornecedor.open();
                }}
                options={[]}
                disabled={temParcelaPagar || eVenda}
              />
            )}

            {!mostrarFornecedor && store.clientes.arr && (
              <Select
                placeholder="Cliente"
                value={state.cliente}
                onChange={(e: any) => (state.cliente = e)}
                options={store.clientes.getSelectRows()}
                disabled={temParcelaPagar || eVenda}
              />
            )}

            <KeyboardDatePicker
              autoOk
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Data"
              value={state.data}
              onChange={(date) => {
                state.data = date;
                state.setQtdParcelas(state.qtdParcelas);
              }}
              disabled={temParcelaPagar || eVenda}
            />
            <TextField
              value={state.documento}
              onChange={(e) => (state.documento = e.target.value)}
              label="Documento"
              inputProps={{ maxLength: 30 }}
              disabled={temParcelaPagar}
            />
            <InputNumero
              value={state.valorFrete}
              onChange={(value) => (state.valorFrete = value)}
              precision="2"
              label="Valor do Frete"
              disabled={temParcelaPagar}
            />
            <InputNumero
              value={state.outrosValores}
              onChange={(value) => (state.outrosValores = value)}
              precision="2"
              label="Outros Valores"
              disabled={temParcelaPagar}
            />

            <Select
              disabled={state.estrangeira || eProduto || temParcelaPagar || eVenda}
              placeholder="Selecione a Categoria"
              value={state.categoria}
              onChange={(e: any) => (state.categoria = e)}
              options={categoriasOptions}
            />

            <Box display="flex" flexDirection="column" alignItems="center" mt={2} mb={2}>
              {!editMovimentacao && (
                <Box width="50%">
                  <Botao onClick={modalDepesaItem.open}>Adicionar Item</Botao>
                </Box>
              )}

              <Box width="100%">
                <Grid container direction="column">
                  {state.itens.map((item, index) => (
                    <Grid key={item.descricao} container justifyContent="center" spacing={2}>
                      <Grid item sm={2} alignContent="center">
                        <TextField
                          value={
                            categoriasOptions?.find((cat) => cat.value === item.categoria.toString())?.label ??
                            item.categoria
                          }
                          label="Categoria"
                          disabled
                        />
                      </Grid>
                      <Grid item sm={2} alignContent="center">
                        <TextField value={item.descricao} label="Descrição" disabled />
                      </Grid>
                      <Grid item sm={2} alignContent="center">
                        <Box pt="10px">
                          <InputNumero value={item.valor_total_item} precision="2" label="Valor Total" disabled />
                        </Box>
                      </Grid>
                      <Grid item sm={2} alignContent="center">
                        <Box pt="10px">
                          <InputNumero value={item.quantidade} precision="3" label="Quantidade" disabled />
                        </Box>
                      </Grid>
                      <Grid item sm={2} alignContent="center">
                        <Box pt="10px">
                          <InputNumero value={item.valor_uni_item} precision="2" label="Valor Unitário" disabled />
                        </Box>
                      </Grid>
                      <Grid item sm={1} alignContent="center">
                        <IconButton onClick={() => selecionarItem(item)} disabled={temParcelaPagar || eVenda}>
                          <Edit />
                        </IconButton>
                      </Grid>
                      {!editMovimentacao && (
                        <Grid item sm={1} alignContent="center">
                          <IconButton onClick={() => removerItem(index)}>
                            <Delete htmlColor="red" />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-around" alignItems="center">
              <Box display="flex" flexDirection="column" alignItems="center">
                <h2>Valor Total Itens</h2>
                <h1 style={{ color: '#42a5f5' }}>R$ {Formatter.formatNumber(2, state.somaValorItems())}</h1>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <h2>Valor Total Geral</h2>
                <h1 style={{ color: '#42a5f5' }}>R$ {Formatter.formatNumber(2, state.somaValorTotal())}</h1>
              </Box>
            </Box>

            <Flex>
              <Botao onClick={handleClose} variant="contained" cor="#FC7467">
                Cancelar
              </Botao>
              <Botao onClick={() => setEtapa(3)} variant="contained" cor="#00C853">
                Avançar
              </Botao>
            </Flex>
          </Box>
        </ModalBody>

        <SelecionarFornecedor
          open={modalFornecedor.state}
          handleClose={modalFornecedor.close}
          onConfirm={(fornecedor) => {
            state.fornecedor = fornecedor;
            modalFornecedor.close();
          }}
        />
        <ModalMovimentacaoItem
          open={modalDepesaItem.state}
          handleClose={fecharModalDespesasItem}
          handleAdd={adicionarItem}
          handleUpdate={atualizarItem}
          item={itemSelecionado}
          categoria={state.categoria?.value}
          valorFrete={state.valorFrete}
          outrosValores={state.outrosValores}
          tipoCategoria={tipoCategoria}
        />
      </Modal>
    );
  }

  function renderizarEtapa3() {
    return (
      <Modal onClose={handleClose} open={open}>
        <ModalHeader>
          <Box display="flex" justifyContent="space-between">
            <span>Pagamento de Parcelas</span>
          </Box>
        </ModalHeader>
        <ModalBody style={{ width: 950 }}>
          <Box width="100%">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box display="flex" flexDirection="column" alignItems="center">
                <h2>Valor Total</h2>
                <h1 style={{ color: '#42a5f5' }}>R$ {Formatter.formatNumber(2, state.somaValorTotal())}</h1>
              </Box>
            </Box>
            <br />
            <br />

            <Select
              placeholder="Conta Bancária"
              value={state.conta}
              onChange={(e: any) => state.setConta(e)}
              options={store.contas.getSelectRows()}
              disabled={temParcelaPagar}
            />
            <br />
            <Select
              onChange={(e: any) => state.setPagamento(e)}
              placeholder="Forma de pagamento"
              value={state.pagamento}
              defaultValue={store.tiposDePagamento.getSelectRows()[0]}
              options={store.tiposDePagamento.getSelectRows()}
              disabled={temParcelaPagar}
            />
            <TextField
              value={state.qtdParcelas}
              onChange={(e) => state.setQtdParcelas(parseInt(e.target.value))}
              type="number"
              label="Quantidade de parcelas"
              inputProps={{ min: 1 }}
              onBlur={() => !state.qtdParcelas && state.setQtdParcelas(1)}
              disabled={Boolean(editMovimentacao)}
            />

            {/* Criação de parcelas */}
            {!editMovimentacao
              ? state.parcelas.map((parcela, index) => (
                  <Parcela key={index}>
                    <Box mt="18px">
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        label="Data"
                        value={Formatter.stringToDate(parcela.data_pagamento)}
                        onChange={(date) => (state.parcelas[index].data_pagamento = Formatter.dateToString(date))}
                      />
                    </Box>

                    <Box mt="18px">
                      <InputNumero
                        value={typeof parcela.valor === 'string' ? parcela.valor : parcela.valor}
                        onChange={(value) => (state.parcelas[index].valor = value)}
                        precision="2"
                        label="Valor"
                        id={'parcela-' + index}
                      />
                    </Box>

                    <div
                      style={{
                        width: '100%',
                        marginTop: 18,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {store.contas.arr && (
                        <NewSelect
                          value={
                            store.contas.getSelectRows().filter((tipo) => parseInt(tipo.value) === parcela.conta)[0]
                          }
                          onChange={(e: any) => (state.parcelas[index].conta = parseInt(e.value))}
                          placeholder="Conta Banco"
                          options={store.contas.getSelectRows()}
                        />
                      )}
                      {store.tiposDePagamento.arr && (
                        <NewSelect
                          value={
                            store.tiposDePagamento
                              .getSelectRows()
                              .find((tipo) => parseInt(tipo.value) === parcela.pagamento_tipo) || null
                          }
                          onChange={(e: any) => (state.parcelas[index].pagamento_tipo = Number(e.value))}
                          placeholder="Forma de pagamento"
                          options={store.tiposDePagamento.getSelectRows()}
                          menuPlacement={index + 1 === state.parcelas.length ? 'top' : 'bottom'}
                        />
                      )}
                    </div>

                    <FormControlLabel
                      labelPlacement="top"
                      control={
                        <Checkbox
                          checked={parcela.recebido}
                          onChange={(e) => (state.parcelas[index].recebido = e.target.checked)}
                          color="primary"
                        />
                      }
                      label="Pago"
                    />
                  </Parcela>
                ))
              : null}

            {/* Edição de parcelas   */}
            {editMovimentacao
              ? state.parcelas.map((parcela, index) => (
                  <Parcela key={index}>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      label="Data"
                      value={Formatter.stringToDate(parcela.data_pagamento)}
                      onChange={(date) => (state.parcelas[index].data_pagamento = date!)}
                      disabled={parcela.recebido}
                    />

                    <InputNumero
                      value={typeof parcela.valor === 'string' ? parcela.valor : parcela.valor}
                      onChange={(value) => (state.parcelas[index].valor = value)}
                      precision="2"
                      label="Valor"
                      id={'parcela-' + index}
                      disabled
                    />

                    <div
                      style={{
                        width: '600px',
                        marginTop: 18,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'bottom',
                      }}
                    >
                      {store.contas.arr && (
                        //@typescript-eslint/no-unused-expressions
                        <NewSelect
                          margin={false}
                          value={
                            store.contas
                              .getSelectRows()
                              .filter((tipo) => parseInt(tipo.value) === state.parcelas[index].conta)[0]
                          }
                          onChange={(e: any) => (state.parcelas[index].conta = parseInt(e.value))}
                          placeholder="Conta Banco"
                          options={store.contas.getSelectRows()}
                          disabled={parcela.recebido}
                        />
                      )}

                      {store.tiposDePagamento.arr && (
                        <NewSelect
                          margin={false}
                          value={
                            store.tiposDePagamento
                              .getSelectRows()
                              .filter((tipo) => parseInt(tipo.value) === parcela.pagamento_tipo)[0]
                          }
                          onChange={(e: any) => (state.parcelas[index].pagamento_tipo = parseInt(e.value))}
                          placeholder="Forma de pagamento"
                          options={store.tiposDePagamento.getSelectRows()}
                          menuPlacement={index + 1 === state.parcelas.length ? 'top' : 'bottom'}
                          disabled={parcela.recebido}
                        />
                      )}

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            checked={parcela.recebido}
                            onChange={(e) => (state.parcelas[index].recebido = e.target.checked)}
                            color="primary"
                            style={{ height: '1px' }}
                          />
                        }
                        style={{ marginBottom: '25px' }}
                        label="Pago"
                      />

                      <Box>
                        <IconButton
                          disabled={parcela.recebido}
                          onClick={() => {
                            state.props = {
                              ...state.parcelas[index],
                              data_pagamento: Formatter.stringToDate(state.parcelas[index].data_pagamento),
                            };
                            modalParceal.open();
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Box>
                    </div>
                  </Parcela>
                ))
              : null}

            {modalParceal.state ? (
              <ParcelaParcial
                open={modalParceal.state}
                handleClose={modalParceal.close}
                onConfirm={() => {
                  modalParceal.close();
                  handleClose();
                }}
                stateProps={state}
              />
            ) : null}

            <Flex>
              <Botao onClick={() => (soParcelas ? handleClose() : setEtapa(2))} variant="contained" cor="#FC7467">
                {soParcelas ? 'Cancelar' : 'Voltar'}
              </Botao>
              <Botao onClick={cadastrar} variant="contained" cor="#00C853">
                Salvar
              </Botao>
            </Flex>
          </Box>
        </ModalBody>
      </Modal>
    );
  }

  function renderizarModal() {
    if (etapa === 0) return renderizarEtapa0();
    if (etapa === 1) return renderizarEtapa1();
    if (etapa === 2) return renderizarEtapa2();
    if (etapa === 3) return renderizarEtapa3();
    return null;
  }

  return <>{renderizarModal()}</>;
};

export default observer(ModalMovimentacao);
