import React from 'react';
import useStore from '../../services/hooks/useStore';
import Botao from '../../components/Botoes/Botao';
import { Modal, ModalHeader, ModalBody, Divisor } from '../../components/Modais/styles';
import { useLocalStore, observer } from 'mobx-react-lite';
import Select from 'react-select';
import { TanqueInfo } from '../../components/TanqueInfo';
import InputNumero from '../../components/InputNumero';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { api,  } from '../../services/utils';
import syncModel from '../../services/models/Sync';
import Formatter from '../../services/Formatter';
import { Box } from '@material-ui/core';

type Props = ModalProps & {
  parametroValor: ParametroValor[] | null;
};
type State = {
  etapa: number;
  dataAnalise: MaterialUiPickersDate;
  sync: boolean;
  salvarAnalise(): void;
  reservatorio: any;
};

const CadastrarAguaDeReservatorio: React.FC<Props> = ({ parametroValor, open, handleClose }) => {
  const store = useStore();

  const etapas = ['Selecione o reservátorio', 'Nova Análise'];
  const state = useLocalStore(
    (): State => ({
      etapa: 0,
      dataAnalise: new Date(),
      reservatorio: null,
      sync: false,
      async salvarAnalise() {
        handleClose();
        store.toggleLoader();

        const parametro_input: any = document.querySelectorAll('.parametro_input[type=text]');
        const parametros_utilizados = [];

        for (let index = 0; index < parametro_input.length; index++) {
          if (parametro_input[index].value.replace('.', '').replace(',', '.') > 0) {
            parametros_utilizados.push({
              parametro: parametro_input[index].id,
              value: parametro_input[index].value.replace('.', '').replace(',', '.'),
            });
          }
        }

        const data = {
          reservatorio: this.reservatorio?.id,
          data: Formatter.dateToString(this.dataAnalise),
          parametros_utilizados,
        };

        await api
          .post(`/analise-agua-reservatorio/?propriedade=${store.propriedade!.id}`, data)
          .then(() => {
            syncModel.aguaDeReservatorios();
          })
          .then(() => {
            store.notificar('Análise da água realizada com sucesso!');
          })
          .catch((err) => {
            store.notificar('Erro no cadastro da Análise da água, tente novamente!');
            console.log(err );
          });

        store.toggleLoader();
      },
    }),
  );


  

  const ReservatoriosDisponiveis: React.FC = () => (
    <Box minHeight="500px">
      {store.reservatorios.arr
        ?.sort((a: any, b: any) => {
          const textA = a.nome ? a.nome.toUpperCase() : a.codigo;
          const textB = b.nome ? b.nome.toUpperCase() : b.codigo;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map((reservatorio) => {
          return (
            <TanqueInfo
              onClick={() => {
                state.reservatorio = reservatorio;
                state.etapa = 1;
              }}
              key={reservatorio.id}
              style={{ width: '540px' }}
            >
              <p className="left">{reservatorio.nome}</p>
              <p className="right">{reservatorio.origem.nome}</p>
              <b className="left" style={{ color: '#00C853' }}>
                {Formatter.formatNumber(3, reservatorio.volume)} (lt/m³)
              </b>
              <p className="right" style={{ color: '#00C853' }}>
                {reservatorio.situacao.nome}
              </p>
            </TanqueInfo>
          );
        })}
    </Box>
  );

  const ParametroInput: React.FC = () => (
    <>
      {parametroValor?.map((parametro) => {
        return (
          <div key={parametro.id}>
            {parametro.used ? (
              <InputNumero
                value={0}
                precision="3"
                label={parametro.parametro.nome}
                className="parametro_input"
                id={parametro.parametro.id.toString()}
              />
            ) : null}
          </div>
        );
      })}
    </>
  );

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{etapas ? etapas[state.etapa] : null}</ModalHeader>
      <ModalBody>
        {state.etapa === 0 ? (
          <div>
            <ReservatoriosDisponiveis />
          </div>
        ) : null}
        {state.etapa === 1 && state.reservatorio !== null ? (
          <>
            <div style={{ width: '540px' }}>
              {state.reservatorio.nome !== null && state.reservatorio.nome !== '' ? (
                <Select
                  isDisabled
                  placeholder="Selecione o Tanque"
                  value={{
                    label: state.reservatorio.nome,
                    value: state.reservatorio.id,
                  }}
                  classNamePrefix="sel"
                />
              ) : (
                <Select
                  isDisabled
                  placeholder="Selecione o Tanque"
                  value={{
                    label: state.reservatorio.codigo,
                    value: state.reservatorio.id,
                  }}
                  classNamePrefix="sel"
                />
              )}
              <KeyboardDateTimePicker
                autoOk
                disableToolbar
                ampm={false}
                variant="inline"
                margin="normal"
                label="Data da análise"
                value={state.dataAnalise}
                onChange={(date) => {
                  state.dataAnalise = date;
                }}
                onError={(error) => {
                  console.log(error);
                }}
                format="dd/MM/yyyy HH:mm"
              />
              <Divisor>
                <p>Parâmetros</p>
              </Divisor>

              <ParametroInput />

              <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
                <Botao onClick={() => (state.etapa = 0)} variant="contained" cor="gray">
                  Voltar
                </Botao>
                <Botao onClick={state.salvarAnalise} variant="contained" cor="#00C853">
                  Salvar
                </Botao>
              </div>
            </div>
          </>
        ) : null}
      </ModalBody>
    </Modal>
  );
};

export default observer(CadastrarAguaDeReservatorio);
