import React from 'react';
import { Modal, ModalHeader, ModalBody } from './styles';
import Botao from '../Botoes/Botao';
import { observer } from 'mobx-react-lite';

type Props = ModalProps & {
  onConfirm(): void;
  onClear(): void;
};
const FiltroAvancado: React.FC<Props> = ({ open, handleClose, onConfirm, onClear, children }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>Filtros Avançados</ModalHeader>
      <ModalBody style={{ width: 400 }}>{children}</ModalBody>
      <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
        <Botao
          cor="#00C853"
          onClick={() => {
            onConfirm();
            handleClose();
          }}
        >
          Aplicar
        </Botao>
        <Botao
          cor="#FC7467"
          onClick={() => {
            onClear();
            handleClose();
          }}
        >
          Limpar Filtros
        </Botao>
      </div>
    </Modal>
  );
};

export default observer(FiltroAvancado);
