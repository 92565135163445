/* eslint-disable react/display-name */
/* eslint-disable react/display-name */
import React, { useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Flex } from '../../../components/NavBar/styles';
import { NewBody, Conteudo } from '../../../components/Body';
import { IconButton, Tooltip } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import useStore from '../../../services/hooks/useStore';
import Select from '../../../components/Select';
import { relatoriosVendas } from '../../../services/utils/relatorios';
import { useHistory } from 'react-router-dom';
import { useLocalStore, observer } from 'mobx-react-lite';
import MaterialTable, { Column } from 'material-table';
import { newConfig } from '../../../components/Tabela';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PrintIcon from '@material-ui/icons/Print';
import html2pdf from 'html3pdf';
import FilterListIcon from '@material-ui/icons/FilterList';
import useModal from '../../../services/hooks/useModal';
import X9Logger from '../../../services/X9Logger';
import { aqReports } from '../../../services/utils';
import RelatorioResumoVendas from '../templates/ResumoVendas';
import ActionButton from '../../../components/Tabela/ActionButton';
import { Modal, ModalBody, ModalHeader } from '../../../components/Modais/styles';
import RelatorioResumoVendasDetalhado from '../templates/ResumoVendasDetalhado';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import Formatter from '../../../services/Formatter';
import { permissoes } from '../../../services/utils/permissoes';

interface Resumo {
  biomassa: number;
  data: string;
  especie: string;
  estoque: number;
  lote: {
    uuid: string;
    codigo: string;
  };
  lotetanque: string;
  quantidade: number;
  tanque: {
    uuid: string;
    nome: string;
  };
  valor: number;
  valor_unit: number;
}

const ResumoVendas: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.FINANCEIRO_PAINEL_FINANCEIRO_LEITURA, history);
  const tableRef = useRef(null);
  const modalFiltros = useModal();
  const modalDetalhes = useModal();
  const state = useLocalStore(() => ({
    rows: [] as object[],
    sync: false,
    pageSize: 0,

    selectedResumo: null as null | Resumo,
    setSelectedResumo(rowData: null | Resumo) {
      this.selectedResumo = rowData;
    },

    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
  }));

  async function sync(): Promise<void> {
    const tabela: any = tableRef.current;
    tabela.onQueryChange();
  }

  useEffect(() => {
    store.setTitulo('Vendas');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getThings(): Promise<void> {
      state.sync = true;
    }
    if (store.propriedade) {
      getThings();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  const columns: Column<Resumo>[] = [
    {
      field: 'tanque.nome',
      title: 'Tanque',
      type: 'string',
      width: 150,
    },
    {
      field: 'lote.codigo',
      title: 'Lote',
      type: 'string',
      width: 120,
    },
    {
      field: 'acoes',
      disableClick: true,
      render: (rowData) => (
        <ActionButton
          tooltip="Visualizar"
          Icon={VisibilityIcon}
          onClick={() => {
            state.setSelectedResumo(rowData);
            modalDetalhes.open();
          }}
        />
      ),
      width: 25,
    },
    {
      field: 'especie',
      title: 'Espécie',
      type: 'string',
      width: 120,
    },
    {
      field: 'estoque',
      title: 'Estoque Atual',
      type: 'numeric',
      width: 160,
      render: ({ estoque }) => {
        return Formatter.formatNumber(0, estoque);
      },
    },
    {
      field: 'data',
      title: 'Data Última Venda',
      type: 'date',
      width: 160,
    },
    {
      field: 'biomassa',
      title: 'Biomassa (kg)',
      width: 150,
      render: ({ biomassa }) => {
        return Formatter.formatNumber(2, biomassa);
      },
      type: 'numeric',
    },
    {
      field: 'quantidade',
      title: 'Quantidade',
      type: 'numeric',
      width: 150,
      render: ({ quantidade }) => {
        return Formatter.formatNumber(0, quantidade);
      },
    },
    {
      field: 'valor_unit',
      title: 'Valor Unit. (R$/kg)',
      type: 'numeric',
      width: 160,
      render: ({ valor_unit }) => {
        return Formatter.formatNumber(2, valor_unit);
      },
    },
    {
      field: 'valor',
      title: 'Valor (R$)',
      type: 'numeric',
      width: 150,
      render: ({ valor }) => {
        return Formatter.formatNumber(2, valor);
      },
    },
  ];

  function getPDF(): void {
    const maxRows = 10;
    const tableLength = Math.ceil(state.rows.length / maxRows);
    const rows = [];

    for (let tableNum = 0; tableNum < tableLength; tableNum++) {
      rows.push(state.rows.slice(maxRows * tableNum, maxRows * tableNum + (maxRows - tableNum)));
    }

    const htmlString = ReactDOMServer.renderToString(
      <RelatorioResumoVendas propriedade={store.propriedade!.nome} rows={rows} />,
    );

    const data = Formatter.dateToString(new Date(), '/');

    const opt = {
      margin: 6,
      filename: `Aquabit_${data}_ResumoVendas.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'l' },
    };

    html2pdf().set(opt).from(htmlString).save();
  }

  let DetalhesModal: React.FC<ModalProps> = (props) => {
    const detailsState = useLocalStore(() => ({
      rows: [] as object[],
      pageSize: 0,
      setPageSize(value: number) {
        this.pageSize = value + 1;
      },
      setRows(array: object[]) {
        this.rows = array;
      },
    }));
    const detailColumns: Column<object>[] = [
      {
        field: 'cliente',
        title: 'Cliente',
        type: 'string',
        width: 150,
      },
      {
        field: 'data',
        title: 'Data',
        type: 'date',
        width: 120,
      },
      {
        field: 'quantidade',
        title: 'Quantidade',
        type: 'numeric',
        width: 150,
        render: ({ quantidade }: any) => {
          return Formatter.formatNumber(0, quantidade);
        },
      },
      {
        field: 'biomassa',
        title: 'Biomassa (kg)',
        width: 150,
        render: ({ biomassa }: any) => {
          return Formatter.formatNumber(2, biomassa);
        },
        type: 'numeric',
      },
      {
        field: 'valor_unit',
        title: 'Valor Unit. (R$)',
        type: 'numeric',
        width: 150,
        render: ({ valor_unit }: any) => {
          return Formatter.formatNumber(2, valor_unit);
        },
      },
      {
        field: 'valor',
        title: 'Valor Total (R$)',
        type: 'numeric',
        width: 150,
        render: ({ valor }: any) => {
          return Formatter.formatNumber(2, valor);
        },
      },
    ];

    function getPDF(): void {
      const maxRows = 10;
      const tableLength = Math.ceil(detailsState.rows.length / maxRows);
      const rows = [];

      for (let tableNum = 0; tableNum < tableLength; tableNum++) {
        rows.push(detailsState.rows.slice(maxRows * tableNum, maxRows * tableNum + (maxRows - tableNum)));
      }

      const htmlString = ReactDOMServer.renderToString(
        <RelatorioResumoVendasDetalhado
          propriedade={store.propriedade!.nome}
          rows={rows}
          tanque={state.selectedResumo!.tanque.nome}
          lote={state.selectedResumo!.lote.codigo}
          especie={state.selectedResumo!.especie}
          estoque={state.selectedResumo!.estoque}
        />,
      );

      const data = Formatter.dateToString(new Date(), '/');

      const opt = {
        margin: 6,
        filename: `Aquabit_${data}_ResumoVendas.pdf`,
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 4 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'l' },
      };

      html2pdf().set(opt).from(htmlString).save();
    }

    return (
      <Modal onClose={props.handleClose} open={props.open} maxWidth="xl">
        <ModalHeader>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>Detalhamento de Vendas por Tanque - {state.selectedResumo!.tanque.nome}</span>
            <div>
              <Tooltip title="Gerar relatório">
                <IconButton onClick={getPDF}>
                  <PrintIcon />
                </IconButton>
              </Tooltip>
              <IconButton style={{ marginLeft: 15 }} onClick={modalDetalhes.close}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <MaterialTable
            columns={detailColumns}
            data={(query) =>
              new Promise((resolve) => {
                try {
                  aqReports
                    .get(
                      `/relatorio/vendas/${store.propriedade?.uuid}/lotetanques/${state.selectedResumo!.lotetanque}`,
                      {
                        params: {
                          page: query.page + 1,
                          item_count: 50,
                        },
                      },
                    )
                    .then((res) => {
                      res.data.count++;

                      res.data.results.push({
                        cliente: 'TOTAL',
                        quantidade: res.data.total.quantidade,
                        biomassa: res.data.total.biomassa,
                        valor_unit: res.data.total.valor_unit,
                        valor: res.data.total.valor,
                      });

                      detailsState.rows = res.data.results;

                      resolve({
                        data: res.data.results,
                        page: res.data.page - 1,
                        totalCount: res.data.count_total,
                      });
                    });
                } catch (err) {
                  X9Logger.report(err);
                  store.notificar('Ocorreu um erro ao gerar relatório. Tente novamente mais tarde!');
                }
              })
            }
            options={{
              ...newConfig.options,
              pageSize: 50,
              rowStyle: (rowData): any => {
                if (rowData.cliente === 'TOTAL') return { fontWeight: 'bold' };
              },
            }}
            localization={newConfig.localization}
            style={newConfig.style}
          />
        </ModalBody>
      </Modal>
    );
  };
  DetalhesModal = observer(DetalhesModal);

  return state.sync ? (
    <NewBody>
      <Flex style={{ justifyContent: 'space-between' }}>
        <div style={{ width: 250, marginRight: 15 }}>
          <Select
            placeholder="Relatório"
            options={relatoriosVendas}
            defaultValue={relatoriosVendas[0]}
            onChange={(e: any) => {
              history.push('/relatorios/' + e.value);
            }}
          />
        </div>
        <Flex>
          <Tooltip title="Exportar para Excel">
            <IconButton className="blue" onClick={() => store.notificar('Em Breve')}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Baixar PDF">
            <IconButton className="blue" onClick={getPDF}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Filtro Avançado">
            <IconButton className="blue" onClick={modalFiltros.open}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sincronizar">
            <IconButton className="blue" onClick={sync}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>

      <Conteudo style={{ padding: 0 }}>
        {store.propriedade && (
          <MaterialTable
            tableRef={tableRef}
            columns={columns}
            data={(query) =>
              new Promise((resolve) => {
                try {
                  aqReports
                    .get('/relatorio/vendas/' + store.propriedade?.uuid, {
                      params: {
                        page: query.page + 1,
                        item_count: 50,
                      },
                    })
                    .then((res) => {
                      const sortData = res.data.results.sort((a: any, b: any) =>
                        a.tanque.nome > b.tanque.nome ? 1 : b.tanque.nome > a.tanque.nome ? -1 : 0,
                      );
                      if (res.data.count) {
                        if (res.data.count >= 50) {
                          state.setPageSize(50);
                        } else {
                          state.setPageSize(res.data.count);
                        }
                      } else {
                        state.setPageSize(0);
                      }

                      state.rows = res.data.results;

                      resolve({
                        data: sortData,
                        page: query.page,
                        totalCount: res.data.count,
                      });
                    });
                } catch (err) {
                  X9Logger.report(err);
                  store.notificar('Ocorreu um erro ao gerar relatório. Tente novamente mais tarde!');
                }
              })
            }
            options={{ ...newConfig.options, pageSize: state.pageSize }}
            localization={newConfig.localization}
            style={newConfig.style}
          />
        )}
      </Conteudo>

      {modalDetalhes.state ? <DetalhesModal open={modalDetalhes.state} handleClose={modalDetalhes.close} /> : null}
    </NewBody>
  ) : null;
};

export default observer(ResumoVendas);
