import { WhatsApp } from '@material-ui/icons';
import React from 'react';
import { BotaoCadastrar, BotoesContainer } from '../../pages/Login/styles';
import { Flex } from '../NavBar/styles';
import { Modal, ModalBody, ModalHeader } from './styles';

const CadastroEfetuado: React.FC<ModalProps> = ({ open, handleClose }) => {
  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Parabéns! Cadastro Efetuado</ModalHeader>
      <ModalBody style={{ maxWidth: 400, textAlign: 'center', gap: 20 }}>
        <h1 style={{ marginTop: 20 }}>Parabéns! Você concluiu seu cadastro.</h1>
        <p>Seu cadastro está em análise e o mais breve possível entraremos em contato para liberar os acessos!</p>
        <BotoesContainer>
          <Flex style={{ marginTop: 20, gap: 40, justifyContent: 'center', flexDirection: 'column' }}>
            <BotaoCadastrar
              style={{
                width: '80%',
                backgroundColor: '#00C853',
                border: '1px solid #00C853',
                gap: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <a
                href="https://api.whatsapp.com/send/?phone=%2B5511950765158&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  color: '#fff',
                  textDecoration: 'none',
                }}
              >
                {' '}
                <p style={{ marginRight: '.5rem' }}>Chamar no Whatsapp!</p>
                <WhatsApp />
              </a>
            </BotaoCadastrar>
            <BotaoCadastrar secondary onClick={handleClose}>
              Fechar
            </BotaoCadastrar>
          </Flex>
        </BotoesContainer>
      </ModalBody>
    </Modal>
  );
};

export default CadastroEfetuado;
