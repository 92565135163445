import { getThings } from '../utils';
import { observable, computed } from 'mobx';

class Mortalidades {
  @observable arr: Mortalidade[] | null = null;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    this.arr = await getThings<Mortalidade>('/mortalidade/');
  }

  async getEverything(params?: object): Promise<Mortalidade[]> {
    return await getThings<Mortalidade>('/mortalidade/', params);
  }

  get(id: number): Mortalidade {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  getByTipo(tipoID: number): Mortalidade[] {
    return this.arr!.filter((item) => item.tipo_morte.id === tipoID);
  }

  filter(callback: (item: Mortalidade, index: number, array: Mortalidade[]) => void): Mortalidade[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: Mortalidade, index: number, array: Mortalidade[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default Mortalidades;
