/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../../components/Modais/styles';
import useStore from '../../../../services/hooks/useStore';
import { LoteTanqueInfo } from '../../../../components/LoteTanqueInfo';
import { observer, useLocalStore } from 'mobx-react-lite';
import { returnTanque } from '../../../../services/utils/propriedade';
import useModal from '../../../../services/hooks/useModal';
import QuantidadeModal from './QuantidadeModal';
import Formatter from '../../../../services/Formatter';
import { AplicacaoTanqueData } from '../../../../@types/Insumo';
import { apiV2 } from '../../../../services/utils';

type Props = ModalProps & {
  onConfirm(tanqueData: AplicacaoTanqueData): void;
  exclude: number[];
  diasDiferenca?: number;
};

const SelecionarLoteTanques: React.FC<Props> = ({ open, handleClose, onConfirm, diasDiferenca }) => {
  const store = useStore();
  const state = useLocalStore(() => ({
    sync: false,
  }));
  const modalQuantidade = useModal();
  const [selectedLoteTanque, setSelectedLoteTanque] = useState<LoteTanque | null>(null);
  const [selectedQuantidade, setSelectedQuantidade] = useState<any | null>(null);
  const [loteTanques, setLoteTanques] = useState<LoteTanque[]>([] as LoteTanque[]);

  function reunirDados(tanqueData: AplicacaoTanqueData): void {
    tanqueData.tanque_id = selectedLoteTanque!.tanque.id;
    tanqueData.lote_tanque_id = selectedLoteTanque!.id;

    onConfirm(tanqueData);
    handleClose();
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function searchTanque() {
      store.toggleLoader();
      await apiV2
        .get('/lote-tanque/modals/', {
          params: {
            propriedade: store.propriedade!.id,
          },
        })
        .then((res) => {
          setLoteTanques(res.data.results);

          state.sync = true;
        });

      store.toggleLoader();
    }
    searchTanque();

    // eslint-disable-next-line
  }, []);

  const LoteTanquesDisponiveis: React.FC = () => (
    <>
      {loteTanques
        ?.sort((a: any, b: any) => {
          const textA = a.tanque.nome ? a.tanque.nome.toUpperCase() : a.tanque.codigo;
          const textB = b.tanque.nome ? b.tanque.nome.toUpperCase() : b.tanque.codigo;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map((loteTanque: any) => {
          if (loteTanque && loteTanque.fase_lote.nome !== 'FINALIZADO') {
            const PM = loteTanque.peso_medio_atual || loteTanque.peso_medio;

            return loteTanque.estoque > 0 ? (
              <LoteTanqueInfo
                onClick={() => {
                  setSelectedQuantidade(loteTanque.estoque);
                  setSelectedLoteTanque(loteTanque);
                  modalQuantidade.open();
                }}
                key={loteTanque.id}
              >
                <p className="left">{returnTanque(loteTanque.tanque)}</p>
                <p className="right">
                  {loteTanque.lote.codigo} ({Formatter.formatNumber(0, loteTanque.estoque)})
                </p>
                <b className="left" style={{ color: '#00C853' }}>
                  {Formatter.formatNumber(3, (PM * loteTanque.estoque) / 1000)} Kg
                </b>
                <p className="right" style={{ color: '#00C853' }}>
                  {loteTanque.fase_lote.nome}
                </p>
              </LoteTanqueInfo>
            ) : null;
          }
        })}
    </>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>Selecione um Tanque</ModalHeader>
      <ModalBody>
        <LoteTanquesDisponiveis />
        {modalQuantidade.state ? (
          <QuantidadeModal
            open={modalQuantidade.state}
            handleClose={modalQuantidade.close}
            onConfirm={reunirDados}
            loteTanque={selectedLoteTanque!}
            diasDiferenca={diasDiferenca}
            quantidadeAnimais={selectedQuantidade}
          />
        ) : null}
      </ModalBody>
    </Modal>
  );
};

export default observer(SelecionarLoteTanques);
