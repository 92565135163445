import styled from 'styled-components';
import Select from 'react-select';

export const NavBox = styled.div`
  height: ${(props) => props.theme.sizes.navbar}px;
  width: 100vw;
  background: linear-gradient(90deg, #049ce6, #40c4ff 50%, #049ce6);

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: white;
  padding: 0 24px;

  a {
    display: flex;
    align-items: center;
  }

  label {
    color: white !important;
  }

  .user-menu {
    transform: translate3d(calc(100vw - 195px), 55px, 0px) !important;
  }

  .MuiList-root.MuiList-padding svg {
    color: ${(props) => props.theme.colors.primary};
  }
  .MuiList-root {
    box-shadow: 0 8px 20px 2px #00000017;
  }
  .MuiPaper-elevation1 {
    box-shadow: 0px 5px 20px 7px rgba(0, 0, 0, 0), 0 0 20px 0 #3bc0fd21;
  }
  .label {
    transform: translate(14px, 3px) scale(0.75) !important;
    color: rgba(255, 255, 255, 0.58) !important;
    font-size: 14px;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectPropriedade = styled(Select)`
  width: 200px;
  margin-right: 10px;

  .navSel__control {
    background-color: rgba(255, 255, 255, 0.3);
    border: none;

    .navSel__value-container .navSel__single-value {
      color: white;
    }
  }

  .navSel__menu {
    color: black;
    z-index: 1000000000 !important;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2) !important;
  }

  .navSel__indicators {
    .navSel__indicator-separator {
      background-color: hsla(0, 0%, 100%, 0.68);
    }
    .navSel__dropdown-indicator {
      color: hsla(0, 0%, 100%, 0.68);
    }
  }
`;
