import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { TextField, Grid } from '@material-ui/core';
import Botao from '../../../components/Botoes/Botao';
import { useLocalStore, observer } from 'mobx-react-lite';
import { api, getThings } from '../../../services/utils';
import useModal from '../../../services/hooks/useModal';
import ActionButton from '../../../components/Tabela/ActionButton';
import useStore from '../../../services/hooks/useStore';
import FormTiposRacoesAdicionar from './FormTiposRacoesAdicionar';
import FormTiposRacoesEditar from './FormTiposRacoesEditar';
import { permissoes } from '../../../services/utils/permissoes';

type Props = ModalProps & {
  racoesCadastradas?: any;
};

const FormTiposRacao: React.FC<Props> = ({ open, handleClose, racoesCadastradas }) => {
  const [, setData] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);
  const loading = options.length === 0;
  const modalAdicionar = useModal();
  const modalEditar = useModal();
  const store = useStore();
  const state = useLocalStore(
    () =>
      ({
        lista_racao: {
          racao_csv: 0,
          racao: 0,
          racao_id: 0,
        },
      } as any),
  );
  const temPermissaoEscrita = store.checkPermissions(permissoes.CONTROLE_RACAO_ARRACOAMENTO_ESCRITA);

  React.useEffect(() => {
    // let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await getThings('/importe/arracoamento/racao-livre/').then((response: any) => {
        setOptions(response.lista_racao.map((currentValue: any) => currentValue.nome));
        setData(response);
      });
    })();
  }, [loading]);

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Configuração de Rações</ModalHeader>
      <ModalBody>
        {racoesCadastradas.lista_racao.length > 0 ? (
          Array.from(racoesCadastradas.lista_racao).map((currentValue: any, idx) => {
            return (
              <Grid justify="flex-end" container alignItems="center" spacing={4} key={idx}>
                <Grid item xs={4}>
                  <TextField label={`Código no CSV`} value={currentValue.racao_csv} disabled />
                </Grid>

                <Grid item xs={4}>
                  <TextField label={'Ração'} value={currentValue.nome} disabled />
                </Grid>

                <Grid container justify="space-around" item xs={3}>
                  <ActionButton
                    // editar={
                    //   temPermissaoEscrita
                    //     ? () => {
                    //         state.lista_racao.racao_csv = currentValue.racao_csv;
                    //         state.lista_racao.racao = currentValue.nome;
                    //         state.lista_racao.racao_id = currentValue.id
                    //         modalEditar.open();
                    //       }
                    //     : undefined
                    // }
                    excluir={
                      temPermissaoEscrita
                        ? () => {
                            api
                              .delete(`/importe/arracoamento/racao-cadastrada/` + currentValue.id + '/', {
                                params: {
                                  propriedade: store.propriedade!.id,
                                },
                              })
                              .then(() => store.notificar('Parâmetro excluído com sucesso!'))
                              .catch(() => store.notificar('Ocorreu ao excluir o parâmetro!'));
                          }
                        : undefined
                    }
                  />
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid justify="center" container alignItems="center" spacing={2}>
            <Grid item xs={4}>
              <TextField label={`Código no CSV`} disabled />
            </Grid>

            <Grid item xs={4}>
              <TextField label={'Ração'} disabled />
            </Grid>
          </Grid>
        )}
      </ModalBody>
      {modalAdicionar.state && (
        <FormTiposRacoesAdicionar
          open={modalAdicionar.state}
          handleClose={modalAdicionar.close}
          racoesCadastradas={racoesCadastradas}
        />
      )}
      {modalEditar.state && (
        <FormTiposRacoesEditar
          open={modalEditar.state}
          handleClose={modalEditar.close}
          nomeCsv={state.lista_racao.racao_csv}
          nomeRacao={state.lista_racao.racao}
          racaoId={state.lista_racao.racao_id}
        />
      )}
      <Grid container>
        <Botao onClick={() => modalAdicionar.open()} variant="contained" cor="#42a5f4">
          Adicionar Ração
        </Botao>
        {/* <Botao variant="contained" cor="#00C853" onClick={() => salvar()}>
          Salvar
        </Botao> */}
      </Grid>
    </Modal>
  );
};

export default observer(FormTiposRacao);
