import React, { useEffect, useMemo, useState } from 'react';
import { useLocalStore, observer } from 'mobx-react-lite';
import ReactInputMask from 'react-input-mask';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { cpfValidator, cnpjValidator, emailValidator, passwordValidator } from '../../services/validators';

import { apiV2 } from '../../services/utils';
import Formatter from '../../services/Formatter';
import useStore from '../../services/hooks/useStore';
import { Modal, ModalHeader, ModalBody } from '../../components/Modais/styles';
import Select from '../../components/Select';
import Botao from '../../components/Botoes/Botao';

type Props = ModalProps & {
  usuarioID: number;
};

type State = {
  nome: string;
  cpf_cnpj: string;
  email: string;
  fone: string;
  tipo_de_usuario: SelectRow | null;
  senha: string;

  // collapse: boolean;
  isCPF: boolean;
  setCPFCNPJ(e: React.ChangeEvent<HTMLInputElement>): void;
};

const CPF_LENGTH_WITH_MASK = 14;
const PHONE_LENGTH = 11;

const FormUsuario: React.FC<Props> = ({ open, handleClose, usuarioID }) => {
  const store = useStore();
  const usuario = usuarioID ? store.usuarios.get(usuarioID) : null;
  const [tiposUsuarios, setTiposUsuarios] = useState<UserType[]>([]);
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [ativo, setAtivo] = useState(usuario?.ativo ?? true);

  const tipoUsuarioID = useMemo(() => usuario?.tipo_de_usuario ?? -1, [usuario]);
  const tipoUsuarioNome = useMemo(() => {
    if (usuario?.tipo_de_usuario) {
      const tipoUsuarioObj = store.tiposDeUsuario.get(usuario?.tipo_de_usuario);
      return String(tipoUsuarioObj?.nome ?? '');
    }
    return '';
  }, [usuario, store.tiposDeUsuario]);

  const tipoUsuarioOptionSelect = {
    value: tipoUsuarioID,
    label: tipoUsuarioNome,
  };

  const state = useLocalStore(
    (): State => ({
      nome: usuario ? usuario.nome : '',
      cpf_cnpj: usuario ? Formatter.formatCPFCNPJ(usuario.inscricao_federal) : '',
      email: usuario ? usuario.email : '',
      fone: usuario ? usuario.telefone : '',
      tipo_de_usuario: tipoUsuarioID ? tipoUsuarioOptionSelect : null,
      senha: '',
      isCPF: usuario ? cpfValidator.isValid(usuario.inscricao_federal) : false,
      setCPFCNPJ(e) {
        const value = e.target.value;
        state.cpf_cnpj = value;
        state.isCPF = value.length <= CPF_LENGTH_WITH_MASK ? true : false;
      },
    }),
  );

  async function cadastrarUsuario(): Promise<void> {
    if (!state.nome) store.notificar('Informe o nome do usuário');
    else if (!state.email || !emailValidator.validate(state.email))
      store.notificar('Informe um email do usuário válido');
    else if (!state.fone || Formatter.getNumeros(state.fone).length !== PHONE_LENGTH)
      store.notificar('Informe um telefone do usuário válido');
    else if (!state.tipo_de_usuario?.value || state.tipo_de_usuario.value <= 0)
      store.notificar('Informe a tipo do usuário');
    else if (!state.senha || !passwordValidator.validateSimple(state.senha))
      store.notificar('Informe uma senha do usuário maior que 6 dígitos');
    else if (
      !state.cpf_cnpj ||
      (Formatter.getNumeros(state.cpf_cnpj).length !== 11 && Formatter.getNumeros(state.cpf_cnpj).length !== 14) ||
      !(cpfValidator.isValid(state.cpf_cnpj) || cnpjValidator.isValid(state.cpf_cnpj))
    )
      store.notificar('Informe um CPF ou CNPJ válido');
    else {
      store.toggleLoader();

      const data = {
        usuario: {
          senha: state.senha,
          inscricao_federal: state.cpf_cnpj ? Formatter.getNumeros(state.cpf_cnpj) : null,
          email: state.email,
          telefone: state.fone,
          nome: state.nome,
          tipo_de_usuario_id: parseInt(state.tipo_de_usuario?.value),
          responsavel: true,
          use_terms: false,
          propriedade_id: store.propriedade?.id,
          adicional: true,
        },
      };

      apiV2
        .post(`/usuario/criar-novo-usuario/`, data, {
          params: {
            propriedade: store.propriedade?.id,
          },
        })
        .then(async () => {
          handleClose();
          store.notificar('Usuário cadastrado com sucesso!');
          await store.usuarios.populate();
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            Object.values(err.response.data).forEach((value) => {
              store.notificar(`${value}`);
            });
          } else {
            store.notificar('Não foi possível cadastrar usuário. Verifique os dados e tente novamente.');
          }
        })
        .finally(() => store.toggleLoader());
    }
  }

  async function atualizarUsuario(): Promise<void> {
    if (!state.nome) store.notificar('Informe o nome do usuário');
    else if (!state.email || !emailValidator.validate(state.email))
      store.notificar('Informe um email do usuário válido');
    else if (!state.fone || Formatter.getNumeros(state.fone).length !== PHONE_LENGTH)
      store.notificar('Informe um telefone do usuário válido');
    else if (!state.tipo_de_usuario?.value || state.tipo_de_usuario.value <= 0)
      store.notificar('Informe a tipo do usuário');
    else if (state.senha && !passwordValidator.validateSimple(state.senha))
      store.notificar('Informe uma senha do usuário maior que 6 dígitos');
    else if (
      !state.cpf_cnpj ||
      (Formatter.getNumeros(state.cpf_cnpj).length !== 11 && Formatter.getNumeros(state.cpf_cnpj).length !== 14) ||
      !(cpfValidator.isValid(state.cpf_cnpj) || cnpjValidator.isValid(state.cpf_cnpj))
    )
      store.notificar('Informe um CPF ou CNPJ válido');
    else {
      store.toggleLoader();

      const data = {
        usuario: {
          senha: state.senha !== '' ? state.senha : undefined,
          inscricao_federal: state.cpf_cnpj ? Formatter.getNumeros(state.cpf_cnpj) : null,
          email: state.email,
          telefone: state.fone,
          nome: state.nome,
          tipo_de_usuario: parseInt(state.tipo_de_usuario.value),
          responsavel: true,
          use_terms: false,
          ativo,
        },
      };

      await apiV2
        .post(`/usuario/editar-usuario/${usuarioID}/`, data, {
          params: {
            propriedade: store.propriedade?.id,
          },
        })
        .then(async () => {
          handleClose();
          store.notificar('Usuario editado com sucesso!');
          await store.usuarios.populate();
        })
        .catch(() => store.notificar('Não foi possível atualizar usuário. Verifique os dados e tente novamente.'))
        .finally(() => {
          store.toggleLoader();
        });
    }
  }

  async function handleSubmit() {
    usuarioID ? atualizarUsuario() : cadastrarUsuario();
  }

  async function buscarTiposDeUsuarios() {
    if (store.tiposDeUsuario?.arr?.length === 0) await store.tiposDeUsuario.populate();
    if (store.tiposDeUsuario?.arr) {
      const tiposUsuariosArr = store.tiposDeUsuario?.arr as UserType[];
      setTiposUsuarios(tiposUsuariosArr);
    }
  }

  useEffect(() => {
    buscarTiposDeUsuarios();
  }, []);

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{usuarioID ? 'Editar' : 'Novo'} usuário</ModalHeader>
      <ModalBody>
        <div style={{ width: 400 }}>
          <TextField value={state.nome} onChange={(e) => (state.nome = e.target.value)} label="Nome/Razão Social" />
          <ReactInputMask
            value={state.cpf_cnpj}
            onChange={state.setCPFCNPJ}
            mask={state.isCPF ? '999.999.999-999' : '99.999.999/9999-99'}
            maskChar=""
            alwaysShowMask={false}
          >
            {() => <TextField label="CPF/CNPJ" />}
          </ReactInputMask>

          <TextField value={state.email} onChange={(e) => (state.email = e.target.value)} label="Email" type="email" />

          <ReactInputMask
            value={state.fone}
            onChange={(e) => (state.fone = e.target.value)}
            mask="(99) 99999-9999"
            maskChar=""
          >
            {() => <TextField label="Telefone/Whatsapp" />}
          </ReactInputMask>

          <div style={{ width: 400 }}>
            <Select
              value={state.tipo_de_usuario}
              placeholder="Qual é o perfil?"
              onChange={(e: any) => (state.tipo_de_usuario = e)}
              options={tiposUsuarios!
                .sort((a, b) => (a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0))
                .map((tipo) => {
                  return {
                    label: tipo.nome,
                    value: tipo.id.toString(),
                  };
                })}
            />
          </div>

          <TextField
            value={state.senha}
            onChange={(e) => (state.senha = e.target.value)}
            label="Senha"
            type={mostrarSenha ? 'text' : 'password'}
          />

          <FormControlLabel
            control={
              <Checkbox checked={mostrarSenha} onChange={() => setMostrarSenha((prev) => !prev)} color="primary" />
            }
            label="Mostrar senha"
          />

          {usuarioID ? (
            <>
              <FormControlLabel
                control={<Checkbox checked={ativo} onChange={() => setAtivo((prev) => !prev)} color="primary" />}
                label="Ativo"
              />
            </>
          ) : null}

          <div style={{ width: 400, display: 'flex' }}>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao onClick={handleSubmit} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(FormUsuario);
