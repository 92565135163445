import { getThings } from '../utils';
import { observable, computed } from 'mobx';

class Parametros {
  @observable arr: ParametroValor[] | null = null;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    this.arr = await getThings<ParametroValor>('/parametro-valor/');
  }

  async getEverything(params?: object): Promise<ParametroValor[]> {
    return await getThings<ParametroValor>('/parametro-valor/', params);
  }

  get(id: number): ParametroValor {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  filter(callback: (item: ParametroValor, index: number, array: ParametroValor[]) => void): ParametroValor[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: ParametroValor, index: number, array: ParametroValor[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default Parametros;
