/**
 * Enum para centralizar as permissões, dessa forma fica mais
 * fácil na hora de fazer alguma modificação
 */
export enum permissoes {
  // permissões originais
  // INSUMO_LEITURA = 'insumo-leitura',
  // INSUMO_ESCRITA = 'insumo-escrita',
  // VENDAS_ESCRITA = 'vendas-escrita',
  // VENDAS_LEITURA = 'vendas-leitura',
  // FINANCEIRO_ESCRITA = 'financeiro-escrita',
  // FINANCEIRO_LEITURA = 'financeiro-leitura',
  // PRODUCAO_ESCRITA = 'producao-escrita',
  // PRODUCAO_LEITURA = 'producao-leitura',
  // QUALIDADE_DA_AGUA_ESCRITA = 'qualidade-da-agua-escrita',
  // QUALIDADE_DA_AGUA_LEITURA = 'qualidade-da-agua-leitura',
  PROPRIEDADE_ESCRITA = 'propriedade-escrita',
  PROPRIEDADE_LEITURA = 'propriedade-leitura',
  // TESTE_ESCRITA = 'teste-escrita',
  PROPRIEDADE_TANQUES_ESCRITA = 'propriedade-tanques-escrita',
  PROPRIEDADE_TANQUES_LEITURA = 'propriedade-tanques-leitura',
  // VENDAS_PRODUTOS_ESCRITA = 'vendas-produtos-escrita',
  // VENDAS_PRODUTOS_LEITURA = 'vendas-produtos-leitura',

  // permissões novas
  PAINEL_ZOOTECNICO_LEITURA = 'painel-zootecnico-leitura',
  // PAINEL_ZOOTECNICO_ESCRITA = 'painel-zootecnico-escrita',
  CONTROLE_RACAO_ESTOQUE_ESCRITA = 'controle-racao-estoque-escrita',
  CONTROLE_RACAO_ESTOQUE_LEITURA = 'controle-racao-estoque-leitura',
  CONTROLE_RACAO_COMPRA_ESCRITA = 'controle-racao-compra-escrita',
  CONTROLE_RACAO_COMPRA_LEITURA = 'controle-racao-compra-leitura',
  CONTROLE_RACAO_ARRACOAMENTO_LEITURA = 'controle-racao-arracoamento-leitura',
  CONTROLE_RACAO_ARRACOAMENTO_ESCRITA = 'controle-racao-arracoamento-escrita',
  CONTROLE_RACAO_PROGRAMA_ALIMENTAR_LEITURA = 'controle-racao-programa-alimentar-leitura',
  CONTROLE_RACAO_PROGRAMA_ALIMENTAR_ESCRITA = 'controle-racao-programa-alimentar-escrita',
  PRODUCAO_POVOAMENTO_LEITURA = 'producao-povoamento-leitura',
  PRODUCAO_POVOAMENTO_ESCRITA = 'producao-povoamento-escrita',
  PRODUCAO_BIOMETRIA_ESCRITA = 'producao-biometria-escrita',
  PRODUCAO_BIOMETRIA_LEITURA = 'producao-biometria-leitura',
  PRODUCAO_MORTALIDADE_LEITURA = 'producao-mortalidade-leitura',
  PRODUCAO_MORTALIDADE_ESCRITA = 'producao-mortalidade-escrita',
  PRODUCAO_TRANSFERENCIA_LEITURA = 'producao-transferencia-leitura',
  PRODUCAO_TRANSFERENCIA_ESCRITA = 'producao-transferencia-escrita',
  CONTROLE_DE_INSUMOS_ESTOQUE_DE_INSUMOS_LEITURA = 'controle-de-insumos-estoque-de-insumos-leitura',
  CONTROLE_DE_INSUMOS_ESTOQUE_DE_INSUMOS_ESCRITA = 'controle-de-insumos-estoque-de-insumos-escrita',
  CONTROLE_DE_INSUMOS_COMPRAS_INSUMOS_LEITURA = 'controle-de-insumos-compras-insumos-leitura',
  CONTROLE_DE_INSUMOS_COMPRAS_INSUMOS_ESCRITA = 'controle-de-insumos-compras-insumos-escrita',
  CONTROLE_DE_INSUMOS_APLICACAO_DE_INSUMOS_LEITURA = 'controle-de-insumos-aplicacao-de-insumos-leitura',
  CONTROLE_DE_INSUMOS_APLICACAO_DE_INSUMOS_ESCRITA = 'controle-de-insumos-aplicacao-de-insumos-escrita',
  VENDAS_MINHAS_VENDAS_LEITURA = 'vendas-minhas-vendas-leitura',
  VENDAS_MINHAS_VENDAS_ESCRITA = 'vendas-minhas-vendas-escrita',
  VENDAS_CLIENTES_LEITURA = 'vendas-clientes-leitura',
  VENDAS_CLIENTES_ESCRITA = 'vendas-clientes-escrita',
  VENDAS_VENDAS_PRODUTOS_LEITURA = 'vendas-vendas-produtos-leitura',
  VENDAS_VENDAS_PRODUTOS_ESCRITA = 'vendas-vendas-produtos-escrita',
  FINANCEIRO_PAINEL_FINANCEIRO_LEITURA = 'financeiro-painel-financeiro-leitura',
  // FINANCEIRO_PAINEL_FINANCEIRO_ESCRITA = 'financeiro-painel-financeiro-escrita',
  FINANCEIRO_MOVIMENTACOES_LEITURA = 'financeiro-movimentacoes-leitura',
  FINANCEIRO_MOVIMENTACOES_ESCRITA = 'financeiro-movimentacoes-escrita',
  FINANCEIRO_CONTA_BANCARIA_LEITURA = 'financeiro-conta-bancaria-leitura',
  FINANCEIRO_CONTA_BANCARIA_ESCRITA = 'financeiro-conta-bancaria-escrita',
  FINANCEIRO_CATEGORIA_LEITURA = 'financeiro-categoria-leitura',
  FINANCEIRO_CATEGORIA_ESCRITA = 'financeiro-categoria-escrita',
  FINANCEIRO_FORNECEDORES_LEITURA = 'financeiro-fornecedores-leitura',
  FINANCEIRO_FORNECEDORES_ESCRITA = 'financeiro-fornecedores-escrita',
  FINANCEIRO_RATEIO_DE_CUSTO_FIXO_LEITURA = 'financeiro-rateio-de-custo-fixo-leitura',
  FINANCEIRO_RATEIO_DE_CUSTO_FIXO_ESCRITA = 'financeiro-rateio-de-custo-fixo-escrita',
  FINANCEIRO_CONTROLE_DE_CHEQUES_LEITURA = 'financeiro-controle-de-cheques-leitura',
  FINANCEIRO_CONTROLE_DE_CHEQUES_ESCRITA = 'financeiro-controle-de-cheques-escrita',
  QUALIDADE_E_SANIDADE_QUALIDADE_DA_AGUA_LEITURA = 'qualidade-e-sanidade-qualidade-da-agua-leitura',
  QUALIDADE_E_SANIDADE_QUALIDADE_DA_AGUA_ESCRITA = 'qualidade-e-sanidade-qualidade-da-agua-escrita',
  QUALIDADE_E_SANIDADE_TEMPERATURA_LEITURA = 'qualidade-e-sanidade-temperaturas-leitura',
  QUALIDADE_E_SANIDADE_TEMPERATURA_ESCRITA = 'qualidade-e-sanidade-temperaturas-escrita',
}
