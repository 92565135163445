import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../components/Modais/styles';
import Botao from '../../components/Botoes/Botao';
import useStore from '../../services/hooks/useStore';
import { observer, useLocalStore } from 'mobx-react-lite';
import { ParametroNome, FlexList, HeaderList, MinValorInput, MaxValorInput } from './styles';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import syncModel from '../../services/models/Sync';
import { api } from '../../services/utils';

type Props = ModalProps & {
  // qualidadeID: number;
};

const ListaTodosParametros: React.FC<Props> = ({ open, handleClose }) => {
  const store = useStore();
  const parametros = useLocalStore((): { [key: string]: { min: number; max: number; id: number } } => ({}));

  useEffect(() => {
    store.parametroValor!.map((parametro) => {
      return (parametros[parametro.parametro.uuid] = {
        min: parametro.valor_minimo,
        max: parametro.valor_maximo,
        id: parametro.parametro.id,
      });
    });

    // eslint-disable-next-line
  }, []);

  // const state = useLocalStore(() => ({
  // data: qualidade.data,
  // tanque: qualidade.tanque
  // }));

  async function salvarParametros(): Promise<void> {
    store.toggleLoader();

    const parametro_list: any = document.querySelectorAll('.parametro_list');
    const parametro_checkbox: any = document.querySelectorAll('.checkbox_parametro span input');

    const keysParametros = Object.keys(parametros);

    for (let index = 0; index < keysParametros.length; index++) {
      const data = {
        valor_maximo: parametros[keysParametros[index]].max,
        valor_minimo: parametros[keysParametros[index]].min,
        used: parametro_checkbox[index].checked,
        parametro: parametros[keysParametros[index]].id,
        propriedade: store.propriedade!.id,
      };

      await api
        .put(`/parametro-valor/${parametro_list[index].id}/?propriedade=${store.propriedade!.id}`, data)
        .then(() => {
          store.notificar('Parâmetros atualizados com sucesso!');
        })
        .catch((error) => {
          console.log(error);
          store.notificar('Erro na atualização dos parâmetros, tente novamente!');
        });
    }

    syncModel.qualidadeDaAgua();
    handleClose();
    store.toggleLoader();
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Selecione os parâmetros</ModalHeader>
      <ModalBody>
        <div style={{ width: '500px' }}>
          <HeaderList>
            <span>MIN</span>
            <span>MÁX</span>
          </HeaderList>
          {store.parametroValor?.map((parametro) => {
            return (
              <FlexList key={parametro.id} className="parametro_list" id={parametro.id.toString()}>
                <ParametroNome className="labeled">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={parametro.used}
                        onClick={() => (parametro.used = !parametro.used)}
                        color="primary"
                        className="checkbox_parametro"
                      />
                    }
                    id={parametro.parametro.id.toString()}
                    label={parametro.parametro.nome}
                  />
                </ParametroNome>
                <MinValorInput
                  value={parametro.valor_minimo}
                  onChange={(value) => {
                    parametros[parametro.parametro.uuid].min = value;
                  }}
                  precision="3"
                  className="parametro_min_input"
                  label=""
                />
                <MaxValorInput
                  value={parametro.valor_maximo}
                  onChange={(value) => (parametros[parametro.parametro.uuid].max = value)}
                  precision="3"
                  className="parametro_max_input"
                  label=""
                />
              </FlexList>
            );
          })}
          <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Voltar
            </Botao>
            <Botao onClick={salvarParametros} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(ListaTodosParametros);
