import styled from 'styled-components';

export const TanqueItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 50px;

  padding: 5px;

  justify-content: center;
  align-items: center;
  gap: 10px;
`;
