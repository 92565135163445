import React, { useEffect, useState } from 'react';
import useStore from '../../../services/hooks/useStore';
import { useLocalStore, observer } from 'mobx-react-lite';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import Select from '../../../components/Select';
import { TextField, Collapse, ListItem } from '@material-ui/core';
import ReactInputMask from 'react-input-mask';
import Botao from '../../../components/Botoes/Botao';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { api, apiV2 } from '../../../services/utils';
import Formatter from '../../../services/Formatter';

type Props = ModalProps & {
  clienteID: number;
};
type State = {
  tipo: SelectRow | null;
  nome: string;
  responsavel: string;
  fone: string;
  estado: SelectRow | null;
  municipio: SelectRow | null;

  cpf_cnpj: string;
  email: string;
  endereco: string;

  collapse: boolean;
  isCPF: boolean;
  setCPFCNPJ(e: React.ChangeEvent<HTMLInputElement>): void;
};
const FormCliente: React.FC<Props> = ({ open, handleClose, clienteID }) => {
  const [estados, setEstados] = useState<Estados[]>([] as Estados[]);
  const [cidades, setCidades] = useState<Cidades[]>([] as Cidades[]);
  const [tipoCliente, setTipoClientes] = useState<Clientes[]>([] as Clientes[]);
  const store = useStore();
  const cliente = clienteID ? store.clientes.get(clienteID) : null;
  const state = useLocalStore(
    (): State => ({
      tipo: cliente ? { value: cliente?.tipo.id.toString(), label: cliente?.tipo.nome } : null,
      nome: cliente?.nome || '',
      responsavel: cliente ? cliente?.responsavel : '',
      fone: cliente ? cliente?.fone : '',
      estado: cliente
        ? cliente.estado
          ? { value: cliente!.estado.id.toString(), label: cliente!.estado.nome }
          : null
        : null,
      municipio: cliente
        ? cliente!.cidade
          ? { value: cliente!.cidade.id.toString(), label: cliente!.cidade.nome }
          : null
        : null,

      cpf_cnpj: cliente && cliente.cpf_cnpj ? Formatter.formatCPFCNPJ(cliente.cpf_cnpj) : '',
      email: cliente ? cliente?.email : '',
      endereco: cliente ? cliente?.endereco : '',

      collapse: false,
      isCPF: false,

      setCPFCNPJ(e) {
        state.cpf_cnpj = e.target.value;
        if (Formatter.getNumeros(e.target.value).length <= 11) {
          state.isCPF = true;
        } else {
          state.isCPF = false;
        }
      },
    }),
  );

  async function cadastrar(): Promise<void> {
    if (!state.tipo) store.notificar('Informe o tipo de cliente');
    else if (!state.nome) store.notificar('Informe o nome do cliente');
    else if (!state.estado) store.notificar('Informe o estado do cliente');
    else if (!state.municipio) store.notificar('Informe a cidade do cliente');
    else if (
      state.cpf_cnpj &&
      Formatter.getNumeros(state.cpf_cnpj).length !== 11 &&
      Formatter.getNumeros(state.cpf_cnpj).length !== 14
    )
      store.notificar('Informe um CPF ou CNPJ válido');
    else {
      handleClose();
      store.toggleLoader();

      const data = {
        propriedade: store.propriedade!.id,
        tipo: parseInt(state.tipo.value),
        nome: state.nome,
        cpf_cnpj: state.cpf_cnpj ? Formatter.getNumeros(state.cpf_cnpj) : null,
        responsavel: state.responsavel,
        fone: state.fone,
        whatsapp: null,
        email: state.email,
        endereco: state.endereco,
        estado: parseInt(state.estado!.value),
        cidade: parseInt(state.municipio!.value),
      };
      clienteID
        ? await api
            .put(`/clientes/${clienteID}/?propriedade=${store.propriedade!.id}`, data)
            .then(async () => {
              store.notificar('Cliente editado com sucesso!');
              await store.clientes.populate();
            })
            .catch(() => store.notificar('Ocorreu um erro ao editar cliente!'))
        : await api
            .post(`/clientes/?propriedade=${store.propriedade!.id}`, data)
            .then(async () => {
              store.notificar('Cliente cadastrado com sucesso!');
              await store.clientes.populate();
            })
            .catch(() => store.notificar('Ocorreu um erro ao cadastrar cliente!'));

      store.toggleLoader();
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function SearchApi() {
      const { data } = await apiV2.get<DadosClientes>(`/cliente/dados/`, {
        params: {
          propriedade: store.propriedade!.id,
        },
      });
      setTipoClientes(data.results.tipo_cliente);
      setCidades(data.results.cidades);
      setEstados(data.results.estados);
    }
    SearchApi();
  }, [store.propriedade]);

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{clienteID ? 'Editar' : 'Novo'} cliente</ModalHeader>
      <ModalBody>
        <div style={{ width: 400 }}>
          <div style={{ width: 400 }}>
            <Select
              value={state.tipo}
              placeholder="Tipo"
              onChange={(e: any) => (state.tipo = e)}
              options={tipoCliente.map((item) => {
                return { label: item.nome, value: item.id.toString() };
              })}
            />
          </div>

          <div style={{ width: 400 }}>
            <Select
              value={state.estado}
              placeholder="Estado"
              onChange={(e: any) => {
                state.municipio = null;
                state.estado = e;
              }}
              options={estados?.map((estado) => {
                return {
                  label: estado.nome,
                  value: estado.id.toString(),
                };
              })}
            />
          </div>

          <div style={{ width: 400 }}>
            <Select
              disabled={!state.estado}
              value={state.municipio}
              placeholder="Município"
              onChange={(e: any) => (state.municipio = e)}
              options={cidades!
                .filter((cidade) => state.estado && cidade.estado_id === parseInt(state.estado.value))
                .sort((a, b) => (a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0))
                .map((cidade) => {
                  return {
                    label: cidade.nome,
                    value: cidade.id.toString(),
                  };
                })}
            />
          </div>

          <TextField value={state.nome} onChange={(e) => (state.nome = e.target.value)} label="Nome/Razão Social" />
          <TextField
            value={state.responsavel}
            onChange={(e) => (state.responsavel = e.target.value)}
            label="Responsável"
          />

          <ReactInputMask
            value={state.fone}
            onChange={(e) => (state.fone = e.target.value)}
            mask="(99) 99999-9999"
            maskChar=""
          >
            {() => <TextField label="Telefone" />}
          </ReactInputMask>

          <ListItem
            style={{ justifyContent: 'center', marginTop: 10, marginBottom: state.collapse ? -10 : 10 }}
            button
            onClick={() => (state.collapse = !state.collapse)}
          >
            <b>Mais informações</b>
            {state.collapse ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={state.collapse}>
            <ReactInputMask
              value={state.cpf_cnpj}
              onChange={state.setCPFCNPJ}
              mask={state.isCPF ? '999.999.999-999' : '99.999.999/9999-99'}
              maskChar=""
              alwaysShowMask={false}
            >
              {() => <TextField label="CPF/CNPJ" />}
            </ReactInputMask>

            <TextField
              value={state.email}
              onChange={(e) => (state.email = e.target.value)}
              label="Email"
              type="email"
            />
            <TextField value={state.endereco} onChange={(e) => (state.endereco = e.target.value)} label="Endereço" />
          </Collapse>

          <div style={{ width: 400, display: 'flex' }}>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao onClick={cadastrar} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(FormCliente);
