import React from 'react';
import useStore from '../../services/hooks/useStore';
import Botao from '../../components/Botoes/Botao';
import { Modal, ModalHeader, ModalBody } from '../../components/Modais/styles';
import { TextField } from '@material-ui/core';
import { useLocalStore, observer } from 'mobx-react-lite';
import InputNumero from '../../components/InputNumero';
import Select from '../../components/Select';

type Props = ModalProps & {
  varios: boolean;
  quantidade: number;
};
type State = {
  nome: string;
  tamanho: number;
  capacidade: number;
  aguaOrigem: SelectRow | null;
  tipoTanque: SelectRow | null;
  situacaoTanque: SelectRow;
};
const CadastrarTanque: React.FC<Props> = ({ open, handleClose, varios, quantidade }) => {
  const store = useStore();
  const state = useLocalStore(
    (): State => ({
      nome: '',
      tamanho: 0,
      capacidade: 0,
      aguaOrigem: null,
      tipoTanque: null,
      situacaoTanque: { label: 'LIVRE', value: '2' },
    }),
  );

  async function cadastrar(): Promise<void> {
    if (state.tamanho === 0) store.notificar('Informe o tamanho do tanque');
    else if (state.capacidade === 0) store.notificar('Informe a capacidade do tanque');
    else if (!state.aguaOrigem) store.notificar('Informe a origem da água');
    else if (!state.tipoTanque) store.notificar('Informe o tipo do tanque');
    else if (!state.situacaoTanque) store.notificar('Informe a situação do tanque');
    else {
      store.toggleLoader();

      const data = {
        propriedade: store.propriedade!.id,
        tanque_tipo: parseInt(state.tipoTanque.value),
        agua_origem: parseInt(state.aguaOrigem.value),
        codigo: '',
        tamanho: state.tamanho,
        nome: state.nome?.trim() || null,
        capacidade: state.capacidade,
        situacao_tanque: parseInt(state.situacaoTanque.value),
      };

      if (varios) {
        await store.tanques.criarVarios(data, quantidade);
      } else {
        await store.tanques.criar(data);
      }

      store.toggleLoader();
      handleClose();
    }
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{varios ? 'Cadastro de vários tanques' : 'Cadastro de tanque'}</ModalHeader>
      <ModalBody>
        {!varios && store.tanques.arr && <p style={{ width: '100%' }}>Código: TQ - {store.tanques.length + 1}</p>}
        <div style={{ width: '540px' }}>
          {!varios && <TextField label="Nome" value={state.nome} onChange={(e) => (state.nome = e.target.value)} />}

          <div style={{ width: '100%' }}>
            {store.origensDaAgua.arr && (
              <Select
                value={state.aguaOrigem}
                placeholder="Selecione a origem da água"
                onChange={(e: any) => (state.aguaOrigem = e)}
                options={store.origensDaAgua.getSelectRows()}
              />
            )}
          </div>
          <div style={{ width: '100%' }}>
            {store.tiposDeTanque.arr && (
              <Select
                value={state.tipoTanque}
                placeholder="Selecione o tipo de Tanque/Viveiro"
                onChange={(e: any) => (state.tipoTanque = e)}
                options={store.tiposDeTanque.getSelectRows()}
              />
            )}
          </div>
          <div style={{ width: '100%' }}>
            {store.situacoesDeTanque.arr && (
              <Select
                value={state.situacaoTanque}
                placeholder="Selecione a situação do tanque"
                onChange={(e: any) => (state.situacaoTanque = e)}
                options={store.situacoesDeTanque.getSelectRows()}
              />
            )}
          </div>

          <InputNumero
            value={state.tamanho}
            onChange={(value) => (state.tamanho = value)}
            precision="2"
            label="Tamanho (m²/m³)"
          />
          <InputNumero
            value={state.capacidade}
            onChange={(value) => (state.capacidade = value)}
            precision="0"
            label="Capacidade (Kg)"
          />

          <div style={{ width: '100%', display: 'flex' }}>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao onClick={cadastrar} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(CadastrarTanque);
