import styled from 'styled-components';

export const Item = styled.div`
  .accordion {
    border-bottom: '1px solid #808080';
    border-bottom-left-radius: '0px';
    border-bottom-right-radius: '0px';
    &::-webkit-scrollbar {
      width: 0px !important;
      background: #fff !important;
    }
  }
`;

export const Info = styled.p`
  color: #665665;
  font-size: 18px;
  padding: 8px 0;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

export const Left = styled.b`
  justify-self: start;
`;

export const Right = styled.p`
  justify-self: end;
`;
