import React, { useState, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Checkbox, FormControlLabel } from '@mui/material';
import { TextField } from '@material-ui/core';

import { Lote, Modal, ModalBody, ModalHeader } from '../../../../components/Modais/styles';
import useStore from '../../../../services/hooks/useStore';
import { Flex } from '../../../../components/NavBar/styles';
import Formatter from '../../../../services/Formatter';
import { apiV2 } from '../../../../services/utils';

type Props = ModalProps & {
  onItemClick(lote: Lote): void;
  loteID: number | null;
  state: any;
};

const SelecionarLote: React.FC<Props> = ({ open, handleClose, onItemClick, state, loteID }) => {
  const store = useStore();
  const state2 = useLocalStore(() => ({
    sync: false,
  }));
  const [codigo, setCodigo] = useState<string>('');
  const [lotes, setLotes] = useState<Lote[]>([] as Lote[]);

  useEffect(() => {
    async function sync(): Promise<void> {
      state.sync = true;
    }
    if (store.propriedade) {
      sync();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  // BUSCA TODOS OS LOTES
  async function searchLote() {
    await apiV2
      .get('/lote/modals/', {
        params: {
          prop: store.propriedade!.id,
          finalizado: state.filtroRapidoFinalizado ? true : undefined,
          nome: codigo,
        },
      })
      .then((res) => {
        let arrayObjetos: Lote[] = res.data.results;
        let idsUnicos = new Set();
        let arraySemRepetidos = arrayObjetos.filter((obj) => {
          if (idsUnicos.has(obj.id)) {
            return false;
          } else {
            idsUnicos.add(obj.id);
            return true;
          }
        });
        setLotes(arraySemRepetidos);
        state2.sync = true;
      });
  }

  useEffect(() => {
    searchLote();
  }, [state2, store.propriedade, codigo, setCodigo, state.filtroRapidoFinalizado]);

  const LotesDisponiveis: React.FC = () => (
    <Flex style={{ flexDirection: 'column', width: '100%', marginTop: '2rem' }}>
      {/* listando os lotes */}
      {lotes
        // ordenando lotes em ordem alfabética
        ?.sort((a: any, b: any) => {
          const textA = a.codigo ? a.codigo.toUpperCase() : a.codigo;
          const textB = b.codigo ? b.codigo.toUpperCase() : b.codigo;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .filter((lote) => {
          if (codigo) {
            return lote?.codigo?.toLocaleLowerCase().includes(codigo?.toLocaleLowerCase());
          } else {
            return lote;
          }
        })
        .map((lote: Lote) => {
          const selected = lote.id === loteID;
          if (state.filtroRapidoFinalizado) {
            if (lote.fase_lote.nome === 'FINALIZADO') {
              return (
                <Lote
                  selected={selected || undefined}
                  onClick={() => {
                    if (!selected) onItemClick(lote);
                    handleClose();
                  }}
                  key={lote.id}
                >
                  <div>
                    <p>{lote.data_alojamento ? lote.data_alojamento.split(' ')[0] : ''}</p>
                    <p>
                      Qtde Tanques: {Formatter.formatNumber(0, lote.quantidade_tanques ? lote.quantidade_tanques : 0)}
                    </p>
                    <p>Qtde Animais: {Formatter.formatNumber(0, lote.estoque ? lote.estoque : 0)}</p>
                  </div>
                  <div style={{ textAlign: 'end' }}>
                    <b>{lote.codigo}</b>
                    <p style={{ color: 'rgb(0, 200, 83)' }}>
                      Peso Médio: {Formatter.formatNumber(3, lote.peso_medio_atual || lote.peso_medio)} g
                    </p>
                    <p>Biomassa: {Formatter.formatNumber(3, lote.biomassa ? lote.biomassa : 0)} kg</p>
                  </div>
                </Lote>
              );
            } else {
              return null;
            }
          } else {
            if (lote.fase_lote.nome !== 'FINALIZADO') {
              return (
                <Lote
                  selected={selected || undefined}
                  onClick={() => {
                    if (!selected) onItemClick(lote);
                    handleClose();
                  }}
                  key={lote.id}
                >
                  <div>
                    <p>{lote.data_alojamento ? lote.data_alojamento.split(' ')[0] : ''}</p>
                    <p>Qtde Tanques: {Formatter.formatNumber(0, lote.quantidade_tanques)}</p>
                    <p>Qtde Animais: {Formatter.formatNumber(0, lote.estoque)}</p>
                  </div>
                  <div style={{ textAlign: 'end' }}>
                    <b>{lote.codigo}</b>
                    <p style={{ color: 'rgb(0, 200, 83)' }}>
                      Peso Médio: {Formatter.formatNumber(3, lote.peso_medio_atual || lote.peso_medio)} g
                    </p>
                    <p>Biomassa: {Formatter.formatNumber(3, lote.biomassa)} kg</p>
                  </div>
                </Lote>
              );
            } else {
              return null;
            }
          }
        })}
    </Flex>
  );

  return state2.sync ? (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>
        Selecione um Lote{' '}
        <FormControlLabel
          style={{ marginLeft: 100 }}
          control={
            <Checkbox
              checked={state.filtroRapidoFinalizado}
              onChange={(_e, checked) => {
                state.filtroRapidoFinalizado = checked;
                state.filtroRapidoFase = null;
              }}
              color="primary"
            />
          }
          label="Mostrar finalizados"
        />
      </ModalHeader>
      <ModalBody
        style={{
          height: '700px',
          width: '500px',
          marginTop: '40px',
        }}
      >
        <TextField
          label="Pesquisa Pelo Nome"
          value={codigo}
          onChange={(e) => {
            setCodigo(e.target.value);
          }}
        />
        <LotesDisponiveis />
      </ModalBody>
    </Modal>
  ) : null;
};

export default observer(SelecionarLote);
