import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { TextField, Grid } from '@material-ui/core';
import Botao from '../../../components/Botoes/Botao';
import { useLocalStore, observer } from 'mobx-react-lite';
import { api, getThings } from '../../../services/utils';
import useModal from '../../../services/hooks/useModal';
import useStore from '../../../services/hooks/useStore';
import ActionButton from '../../../components/Tabela/ActionButton';
import FormTanquesEditar from './FormTanquesEditar';
import FormTanquesAdicionar from './FormTanquesAdicionar';
import { permissoes } from '../../../services/utils/permissoes';

type Props = ModalProps & {
  tanquesCadastrados?: any;
};

const FormTanques: React.FC<Props> = ({ open, handleClose, tanquesCadastrados }) => {
  const [, setData] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);
  const loading = options.length === 0;
  const modalEditar = useModal();
  const modalAdicionar = useModal();
  const store = useStore();
  const state = useLocalStore(() => ({ lista_tanque: { nomeCsv: '', nomeTanque: '', tanque_id: '' } } as any));
  const temPermissaoEscrita = store.checkPermissions(permissoes.CONTROLE_RACAO_ARRACOAMENTO_ESCRITA);

  React.useEffect(() => {
    // let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await getThings('/importe/arracoamento/tanque-livre/').then((response: any) => {
        setOptions(
          response.lista_lote_tanque.map(
            (currentValue: any) => currentValue.tanque_nome + ' / ' + currentValue.lote_nome,
          ),
        );
        setData(response);
      });
    })();
    // eslint-disable-next-line
  }, [loading]);

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Configuração de Tanques/Viveiros</ModalHeader>
      <ModalBody>
        {tanquesCadastrados.lista_lote_tanque.length > 0 ? (
          Array.from(tanquesCadastrados.lista_lote_tanque).map((currentValue: any, idx) => {
            return (
              <Grid justify="flex-end" container alignItems="center" spacing={4} key={idx}>
                <Grid item xs={4}>
                  <TextField label={`Código no CSV`} value={currentValue.tanque_csv} disabled />
                </Grid>

                <Grid item xs={4}>
                  <TextField label={'Tanque'} value={currentValue.tanque_nome} disabled />
                </Grid>

                <Grid container justify="space-around" item xs={3}>
                  <ActionButton
                    // editar={
                    //   temPermissaoEscrita
                    //     ? () => {
                    //         state.lista_tanque.nomeCsv = currentValue.tanque_csv;
                    //         state.lista_tanque.nomeTanque = currentValue.tanque_nome;
                    //         state.lista_tanque.tanque_id = currentValue.id
                    //         modalEditar.open();
                    //       }
                    //     : undefined
                    // }
                    excluir={
                      temPermissaoEscrita
                        ? () => {
                            api
                              .delete(`/importe/arracoamento/tanque-cadastrado/${currentValue.id}/`, {
                                params: {
                                  propriedade: store.propriedade!.id,
                                },
                              })
                              .then(() => {
                                store.toggleLoader();

                                (async () => {
                                  await getThings('/importe/arracoamento/tanque-livre/').then((response: any) => {
                                    setOptions(
                                      response.lista_lote_tanque.map(
                                        (currentValue: any) =>
                                          currentValue.tanque_nome + ' / ' + currentValue.lote_nome,
                                      ),
                                    );
                                    setData(response);
                                  });
                                })();

                                store.toggleLoader();
                                store.notificar('Parâmetro excluído com sucesso!');
                              })
                              .catch(() => store.notificar('Ocorreu ao excluir o parâmetro!'));
                          }
                        : undefined
                    }
                  />
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid justify="center" container alignItems="center" spacing={2}>
            <Grid item xs={4}>
              <TextField label={`Código no CSV`} disabled />
            </Grid>
            <Grid item xs={4}>
              <TextField label={'Tanque'} disabled />
            </Grid>
          </Grid>
        )}
      </ModalBody>
      {modalEditar.state && (
        <FormTanquesEditar
          open={modalEditar.state}
          handleClose={modalEditar.close}
          nomeCsv={state.lista_tanque.nomeCsv}
          nomeTanque={state.lista_tanque.nomeTanque}
          tanqueId={state.lista_tanque.tanque_id}
        />
      )}
      {modalAdicionar.state && (
        <FormTanquesAdicionar
          open={modalAdicionar.state}
          handleClose={modalAdicionar.close}
          tanquesCadastrados={tanquesCadastrados}
        />
      )}
      <Grid container>
        <Botao onClick={() => modalAdicionar.open()} variant="contained" cor="#42a5f4">
          Adicionar Tanque
        </Botao>
        {/* <Botao variant="contained" cor="#00C853" onClick={() => salvar()}>
          Salvar
        </Botao> */}
      </Grid>
    </Modal>
  );
};

export default observer(FormTanques);
