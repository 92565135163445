import { stateContext } from '../hooks/useStore';
import { api, getThings } from '../utils';
import { observable, computed } from 'mobx';

class CompraDeRacoes {
  @observable arr: CompraRacao[] = [];
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    this.arr = await getThings<CompraRacao>('/compra-racao/');
  }

  async getEverything(params?: object): Promise<CompraRacao[]> {
    return await getThings<CompraRacao>('/compra-racao/', params);
  }

  get(id: number): CompraRacao | null {
    const racao = this.arr.find((item) => item.id === id);
    return racao || null;
  }

  // eslint-disable-next-line no-unused-vars
  filter(callback: (item: CompraRacao, index: number, array: CompraRacao[]) => void): CompraRacao[] {
    return this.arr.filter(callback) || [];
  }

  // eslint-disable-next-line no-unused-vars
  map(callback: (item: CompraRacao, index: number, array: CompraRacao[]) => any): any {
    return this.arr.map(callback) || [];
  }

  async excluir(compraId: number): Promise<void> {
    const store = stateContext.state;

    if (store.propriedade?.id) {
      await api
        .delete(`/compra-racao/${compraId}/?propriedade=${store.propriedade.id}`)
        .then(() => store.notificar('Compra de ração excluída com sucesso!'))
        .catch(() => store.notificar('Ocorreu um erro ao excluir a compra de ração!'));
    }
  }
}

export default CompraDeRacoes;
