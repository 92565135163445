import React, { useEffect, useState } from 'react';
import useStore from '../../services/hooks/useStore';
import Botao from '../../components/Botoes/Botao';
import { Modal, ModalHeader, ModalBody, Divisor } from '../../components/Modais/styles';
import { useLocalStore, observer } from 'mobx-react-lite';
import Select from 'react-select';
import { TanqueInfo } from '../../components/TanqueInfo';
import InputNumero from '../../components/InputNumero';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { api, apiV2 } from '../../services/utils';
import syncModel from '../../services/models/Sync';
import Formatter from '../../services/Formatter';
import { Box, TextField, Tooltip } from '@material-ui/core';

type Props = ModalProps & {
  parametroValor: ParametroValor[] | null;
};
type State = {
  etapa: number;
  dataAnalise: MaterialUiPickersDate;
  selectedTanque: Tanque | null;
  sync: boolean;
  salvarAnalise(): void;
};

const CadastraQualidade: React.FC<Props> = ({ parametroValor, open, handleClose }) => {
  const store = useStore();

  const etapas = ['Selecione o tanque', 'Nova Análise'];
  const [tanques, setTanques] = useState<Tanque[]>([] as Tanque[]);
  const [codigo, setCodigo] = useState<string>('');
  const state = useLocalStore(
    (): State => ({
      etapa: 0,
      dataAnalise: new Date(),
      selectedTanque: null,
      sync: false,
      async salvarAnalise() {
        handleClose();
        store.toggleLoader();

        const parametro_input: any = document.querySelectorAll('.parametro_input[type=text]');
        const parametros_utilizados = [];

        for (let index = 0; index < parametro_input.length; index++) {
          if (parametro_input[index].value.replace('.', '').replace(',', '.') > 0) {
            parametros_utilizados.push({
              parametro: parametro_input[index].id,
              value: parametro_input[index].value.replace('.', '').replace(',', '.'),
            });
          }
        }

        const data = {
          tanque: this.selectedTanque?.id,
          data: Formatter.dateToString(this.dataAnalise),
          parametros_utilizados,
        };

        await api
          .post(`/analise-agua/?propriedade=${store.propriedade!.id}`, data)
          .then(() => {
            syncModel.qualidadeDaAgua();
          })
          .then(() => {
            store.notificar('Análise da água realizada com sucesso!');
          })
          .catch((err) => {
            store.notificar('Erro no cadastro da Análise da água, tente novamente!');
            console.log(err);
          });

        store.toggleLoader();
      },
    }),
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function searchTanque() {
      await apiV2
        .get('/tanque/modals/', {
          params: {
            propriedade: store.propriedade!.id,
            nome: codigo,
          },
        })
        .then((res) => {
          setTanques(res.data.results);
        });
      state.sync = true;
    }
    searchTanque();
    // eslint-disable-next-line
  }, [codigo, setCodigo]);

  const TanquesDisponiveis: React.FC = () => (
    <Box minHeight="500px">
      {tanques
        ?.sort((a: any, b: any) => {
          const textA = a.nome ? a.nome.toUpperCase() : a.codigo;
          const textB = b.nome ? b.nome.toUpperCase() : b.codigo;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map((tanque) => {
          return (
            <TanqueInfo
              onClick={() => {
                state.selectedTanque = tanque;
                state.etapa = 1;
              }}
              key={tanque.id}
              style={{ width: '540px' }}
            >
              <p className="left">{tanque.nome || tanque.codigo}</p>
              <p className="right">
                {tanque.lotes.length > 0 ? (
                  <Tooltip
                    title={tanque.lotes.map((lote: any) => {
                      return `${lote.codigo || lote.nome}, `;
                    })}
                  >
                    <span>{tanque.lotes[0].codigo + '...'}</span>
                  </Tooltip>
                ) : (
                  ''
                )}{' '}
                ({Formatter.formatNumber(0, tanque.estoque)})
              </p>
              <b className="left" style={{ color: '#00C853' }}>
                {Formatter.formatNumber(3, tanque.biomassa)} Kg
              </b>
              <p className="right" style={{ color: '#00C853' }}>
                {tanque.lotes && tanque.lotes.length > 0 ? tanque.lotes[0].fase_lote.nome : tanque.situacao_tanque.nome}
              </p>
            </TanqueInfo>
          );
        })}
    </Box>
  );

  const ParametroInput: React.FC = () => (
    <>
      {parametroValor?.map((parametro) => {
        return (
          <div key={parametro.id}>
            {parametro.used ? (
              <InputNumero
                value={0}
                precision="3"
                label={parametro.parametro.nome}
                className="parametro_input"
                id={parametro.parametro.id.toString()}
              />
            ) : null}
          </div>
        );
      })}
    </>
  );

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{etapas ? etapas[state.etapa] : null}</ModalHeader>
      <ModalBody>
        {state.etapa === 0 ? (
          <div>
            <Box minWidth="550px" width="100%">
              <TextField label="Pesquisa Pelo Nome" value={codigo} onChange={(e) => setCodigo(e.target.value)} />
            </Box>
            <TanquesDisponiveis />
          </div>
        ) : null}
        {state.etapa === 1 && state.selectedTanque !== null ? (
          <>
            <div style={{ width: '540px' }}>
              {state.selectedTanque.nome !== null && state.selectedTanque.nome !== '' ? (
                <Select
                  isDisabled
                  placeholder="Selecione o Tanque"
                  value={{
                    label: state.selectedTanque.nome,
                    value: state.selectedTanque.id,
                  }}
                  classNamePrefix="sel"
                />
              ) : (
                <Select
                  isDisabled
                  placeholder="Selecione o Tanque"
                  value={{
                    label: state.selectedTanque.codigo,
                    value: state.selectedTanque.id,
                  }}
                  classNamePrefix="sel"
                />
              )}
              <KeyboardDateTimePicker
                autoOk
                disableToolbar
                ampm={false}
                variant="inline"
                margin="normal"
                label="Data da análise"
                value={state.dataAnalise}
                onChange={(date) => {
                  state.dataAnalise = date;
                }}
                onError={(error) => {
                  console.log(error);
                }}
                format="dd/MM/yyyy HH:mm"
              />
              <Divisor>
                <p>Parâmetros</p>
              </Divisor>

              <ParametroInput />

              <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
                <Botao onClick={() => (state.etapa = 0)} variant="contained" cor="gray">
                  Voltar
                </Botao>
                <Botao onClick={state.salvarAnalise} variant="contained" cor="#00C853">
                  Salvar
                </Botao>
              </div>
            </div>
          </>
        ) : null}
      </ModalBody>
    </Modal>
  );
};

export default observer(CadastraQualidade);
