/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField } from '@material-ui/core';
import { FormControlLabel, Checkbox } from '@mui/material';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { LoteTanqueInfo } from '../../../../components/LoteTanqueInfo';
import { Modal, ModalBody, ModalHeader } from '../../../../components/Modais/styles';
import Formatter from '../../../../services/Formatter';
import useStore from '../../../../services/hooks/useStore';
import { apiV2 } from '../../../../services/utils';
import { returnTanque } from '../../../../services/utils/propriedade';

type Props = ModalProps & {
  setTanque(tanque: {
    label: string;
    value: string;
    tanque: string;
    tanqueId: string;
    tanqueId2: number;
    loteTanque: any;
  }): void;
  setLote(lote: Lote): void;
  exclude: number[];
  diasDiferenca?: number;
  state: any;
};
const SelecionarLoteTanques: React.FC<Props> = ({ open, handleClose, setTanque, setLote, state }) => {
  const store = useStore();
  const state2 = useLocalStore(() => ({
    sync: false,
  }));
  const [, setSelectedLoteTanque] = useState<LoteTanque | null>(null);
  const [, setSelectedQuantidade] = useState<any | null>(null);
  const [loteTanques, setLoteTanques] = useState<LoteTanque[]>([] as LoteTanque[]);
  const [codigo, setCodigo] = useState<string>('');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function searchTanque() {
      store.toggleLoader();
      await apiV2
        .get('/lote-tanque/modals/', {
          params: {
            propriedade: store.propriedade!.id,
            nome: codigo,
          },
        })
        .then((res) => {
          setLoteTanques(res.data.results);
          state2.sync = true;
        });

      store.toggleLoader();
    }
    searchTanque();

    // eslint-disable-next-line
  }, [state2, store.propriedade, codigo, setCodigo]);

  const LoteTanquesDisponiveis: React.FC = () => (
    <>
      {loteTanques
        ?.sort((a: any, b: any) => {
          const textA = a.tanque.nome ? a.tanque.nome.toUpperCase() : a.tanque.codigo;
          const textB = b.tanque.nome ? b.tanque.nome.toUpperCase() : b.tanque.codigo;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map((loteTanque: LoteTanque) => {
          /* verifica se tem o lote e se lote.value === lotetanque.lote.id */
          if (state.lote) {
            if (state.lote.value != loteTanque.lote.id) return;
          }
          //caso 1 condicao nao exista cai na parte de baixo
          if (state.filtroRapidoTanqueFinalizado) {
            if (loteTanque.fase_lote.nome === 'FINALIZADO') {
              const PM = loteTanque.peso_medio_atual || loteTanque.peso_medio;
              return (
                <LoteTanqueInfo
                  onClick={() => {
                    setTanque({
                      label: returnTanque(loteTanque.tanque),
                      value: loteTanque.uuid,
                      tanque: loteTanque.lote.codigo,
                      tanqueId: loteTanque.tanque.uuid,
                      tanqueId2: loteTanque.tanque.id,
                      loteTanque: loteTanque.id,
                    });
                    setSelectedQuantidade(loteTanque.estoque);
                    setSelectedLoteTanque(loteTanque);
                    setLote(loteTanque.lote);
                    handleClose();
                  }}
                  key={loteTanque.id}
                >
                  <p className="left">{returnTanque(loteTanque.tanque)}</p>
                  <p className="right">{loteTanque.lote.codigo}</p>
                  <b className="left" style={{ color: '#00C853' }}>
                    {Formatter.formatNumber(3, (PM * loteTanque.estoque) / 1000)} Kg
                  </b>
                  <p className="right" style={{ color: '#00C853' }}>
                    {loteTanque.fase_lote.nome}
                  </p>
                </LoteTanqueInfo>
              );
            }
          } else {
            if (loteTanque.fase_lote.nome !== 'FINALIZADO') {
              const PM = loteTanque.peso_medio_atual || loteTanque.peso_medio;
              return (
                <LoteTanqueInfo
                  onClick={() => {
                    setTanque({
                      label: returnTanque(loteTanque.tanque),
                      value: loteTanque.uuid,
                      tanque: loteTanque.lote.codigo,
                      tanqueId: loteTanque.tanque.uuid,
                      tanqueId2: loteTanque.tanque.id,
                      loteTanque: loteTanque.id,
                    });
                    setSelectedQuantidade(loteTanque.estoque);
                    setSelectedLoteTanque(loteTanque);
                    setLote(loteTanque.lote);
                    handleClose();
                  }}
                  key={loteTanque.id}
                >
                  <p className="left">{returnTanque(loteTanque.tanque)}</p>
                  <p className="right">{loteTanque.lote.codigo}</p>
                  <b className="left" style={{ color: '#00C853' }}>
                    {Formatter.formatNumber(3, (PM * loteTanque.estoque) / 1000)} Kg
                  </b>
                  <p className="right" style={{ color: '#00C853' }}>
                    {loteTanque.fase_lote.nome}
                  </p>
                </LoteTanqueInfo>
              );
            }
          }
        })}
    </>
  );

  return state2.sync ? (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>
        Selecione um Tanque
        <FormControlLabel
          style={{ marginLeft: 70 }}
          control={
            <Checkbox
              checked={state.filtroRapidoTanqueFinalizado}
              onChange={(_e, checked) => {
                state.filtroRapidoTanqueFinalizado = checked;
                state.filtroRapidoFase = null;
              }}
              color="primary"
            />
          }
          label="Mostrar finalizados"
        />
      </ModalHeader>
      <ModalBody
        style={{
          height: '700px',
          width: '500px',
          marginTop: '40px',
        }}
      >
        <TextField
          label="Pesquisa Pelo Nome"
          value={codigo}
          onChange={(e) => {
            setCodigo(e.target.value);
          }}
        />
        <LoteTanquesDisponiveis />
      </ModalBody>
    </Modal>
  ) : null;
};

export default observer(SelecionarLoteTanques);
