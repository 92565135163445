import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../../components/Modais/styles';
import Select from '../../../../components/Select';
import { useLocalStore, observer } from 'mobx-react-lite';
import useStore from '../../../../services/hooks/useStore';
// import InputNumero from '../../../../components/InputNumero';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Botao from '../../../../components/Botoes/Botao';
import useModal from '../../../../services/hooks/useModal';
import SelecionarLoteTanques from './SelecionarLoteTanques';
import { returnTanque } from '../../../../services/utils/propriedade';
import { apiV2 } from '../../../../services/utils';
// import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { LoteTanqueItem } from './styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Formatter from '../../../../services/Formatter';
import { returnQuantidade } from '../../../../services/utils/producao';
import { AplicacaoInsumo, AplicacaoTanqueData } from '../../../../@types/Insumo';

type Props = ModalProps & {
  aplicacaoInsumo?: AplicacaoInsumo;
  onConfirm(): void;
};

const FormAplicacao: React.FC<Props> = ({ handleClose, open, aplicacaoInsumo, onConfirm }) => {
  const store = useStore();
  const modalTanques = useModal();
  const state = useLocalStore(() => ({
    insumo: aplicacaoInsumo
      ? { value: aplicacaoInsumo?.insumo.id.toString(), label: aplicacaoInsumo?.insumo.nome }
      : null,
    quantidade: aplicacaoInsumo?.quantidade || 0,
    data: (aplicacaoInsumo ? new Date(aplicacaoInsumo?.data) : new Date()) as MaterialUiPickersDate,
    lancado: aplicacaoInsumo?.lancado || false,
    tanque_data:
      aplicacaoInsumo?.tanque_data.map((tanqueData): AplicacaoTanqueData => {
        return {
          uuid: tanqueData.uuid,
          tanque_id: tanqueData.tanque_id,
          lote_tanque_id: tanqueData.lote_tanque_id,
          quantidade: tanqueData.quantidade,

          ativo: tanqueData.ativo,
          insumo_aplicacao_id: tanqueData.insumo_aplicacao_id,
        };
      }) || [],

    setInsumo(e: SelectRow) {
      this.insumo = e;
    },
  }));

  async function enviar(): Promise<void> {
    if (!state.insumo) store.notificar('Informe a ração');
    // else if (state.quantidade === 0) store.notificar('Informe a quantidade de vezes');
    else if (state.tanque_data.length === 0) store.notificar('Selecione um ou mais tanques');
    else {
      store.toggleLoader();
      handleClose();

      const tanqueDataModificado = state.tanque_data.map((tnqData) => {
        return {
          tanque: tnqData.tanque_id,
          lote_tanque: tnqData.lote_tanque_id,
          quantidade: tnqData.quantidade,
        };
      });

      const data = {
        insumo: state.insumo.value,
        data: Formatter.dateToString(state.data),
        tipo: 2,
        propriedade: store.propriedade!.id,
        lancado: true,
        tanque_data: tanqueDataModificado,
      };

      if (aplicacaoInsumo) {
        await apiV2
          .put('/insumo-aplicacao/' + aplicacaoInsumo.id + '/?prop=1007', data)
          .then(async () => {
            store.notificar('Aplicação editada com sucesso!');
            await store.insumos.populate();
            onConfirm();
          })
          .catch(() => store.notificar('Ocorreu um erro ao editar o aplicação!'));
      } else {
        await apiV2
          .post('/insumo-aplicacao/?prop=1007', data)
          .then(async () => {
            store.notificar('Aplicação cadastrada com sucesso!');
            await store.insumos.populate();
            onConfirm();
          })
          .catch(() => store.notificar('Ocorreu um erro ao cadastrar o aplicação!'));
      }

      store.toggleLoader();
    }
  }

  useEffect(() => {
    (async () => {
      store.toggleLoader();
      if (!store.lotes.arr || !store.tanques.arr || !store.loteTanque.arr)
        await Promise.all([store.loteTanque.populate(), store.lotes.populate(), store.tanques.populate()]);
      store.toggleLoader();
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{aplicacaoInsumo ? 'Editar' : 'Nova'} aplicação</ModalHeader>
      <ModalBody
        style={{
          height: 350,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: 400,
          }}
        >
          <Select
            value={state.insumo}
            placeholder="Insumo"
            onChange={state.setInsumo}
            options={store.insumos.getSelectRows()}
          />
        </div>

        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data"
          value={state.data}
          onChange={(date) => (state.data = date)}
        />

        <Botao onClick={modalTanques.open}>Selecione o(s) tanque(s)</Botao>
        {state.tanque_data.length > 0
          ? state.tanque_data.map((tanqueData) => {
              const loteTanque = store.loteTanque.get(tanqueData.lote_tanque_id);
              const tanque = store.tanques.get(tanqueData.tanque_id);
              return (
                <LoteTanqueItem key={tanqueData.lote_tanque_id}>
                  <p>{returnTanque(tanque)}</p>
                  <p>{Formatter.formatNumber(0, returnQuantidade(loteTanque, false))} un</p>
                  <p>{Formatter.formatNumber(3, tanqueData.quantidade)} kg</p>

                  <IconButton
                    disabled={!!aplicacaoInsumo}
                    onClick={() =>
                      (state.tanque_data = state.tanque_data.filter(
                        (tanqueDataMap) => tanqueDataMap.lote_tanque_id !== tanqueData.lote_tanque_id,
                      ))
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </LoteTanqueItem>
              );
            })
          : null}

        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao onClick={enviar} variant="contained" cor="#00C853">
            Salvar
          </Botao>
        </div>
      </ModalBody>
      {modalTanques.state ? (
        <SelecionarLoteTanques
          open={modalTanques.state}
          handleClose={modalTanques.close}
          onConfirm={(tanqueData) => {
            //abre condicional de se algum selecionado no filtro de ids iguais
            if (state.tanque_data.some((tqData) => tqData.lote_tanque_id === tanqueData.lote_tanque_id)) {
              //realiza um mapeamento em tanque_data onde:
              state.tanque_data.map((tqData, indice) => {
                //se os ids forem iguais captura o uuid de um e atribui no outro
                if (tqData.lote_tanque_id === tanqueData.lote_tanque_id) {
                  const uuid = state.tanque_data[indice].uuid || undefined;
                  state.tanque_data[indice] = { ...tanqueData, uuid };
                }

                return null;
              });
            } else {
              state.tanque_data.push(tanqueData);
            }
          }}
          exclude={state.tanque_data.map((tanqueData) => tanqueData.lote_tanque_id)}
        />
      ) : null}
    </Modal>
  );
};

export default observer(FormAplicacao);
