import styled, { css } from 'styled-components';
import { Dialog } from '@material-ui/core';

export const Modal = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 400px;
    max-width: 1000px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-weight: normal;
    }
  }
`;

type Props = {
  cor?: string;
};
export const ModalHeader = styled.div<Props>`
  position: absolute;
  top: 0;
  width: 100%;
  padding: 10px;
  color: white;
  font-size: 20px;
  background-color: ${(props) => props.cor || props.theme.colors.primary};
  z-index: 2;
  display: flex;

  button {
    margin-left: auto;
    padding: 0 !important;
    svg {
      color: white !important;
    }
  }
`;

export const ModalBody = styled.div`
  margin-top: 30px;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;

  .MuiFormControl-root {
    margin: 10px 0;
    width: 100%;
  }
`;

type LoteProps = {
  selected?: boolean;
};
export const Lote = styled.div<LoteProps>`
  cursor: pointer;
  width: 100%;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  padding: 10px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 5px;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  ${(props) =>
    props.selected &&
    css`
      box-shadow: 0 0 0 2px rgba(86, 196, 35, 0.75), 0px 4px 4px rgba(0, 0, 0, 0.25);
    `}
`;

export const Divisor = styled.div`
  position: relative;
  width: 100%;
  height: 18px;
  margin: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #000;
    display: flex;
    margin: auto 0;
  }
  p {
    text-align: center;
    width: max-content;
    background-color: #fff;
    padding: 0 16px;
    z-index: 0;
  }
`;

export const Scroll = styled.div`
  max-height: calc(100vh - 150px);
  padding: 15px 10px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
