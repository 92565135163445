/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Header from '../../../../components/Header';
import { Flex } from '../../../../components/NavBar/styles';
import AddBtn from '../../../../components/Botoes/AddBtn';
import { Body } from '../../../../components/Body';
import { config, newConfig } from '../../../../components/Tabela';
import useStore from '../../../../services/hooks/useStore';
import { apiV2 } from '../../../../services/utils';
import Filtro from '../../../../components/Filtro';
import MaterialTable, { Column } from 'material-table';
import { useLocalStore, observer } from 'mobx-react-lite';
import useModal from '../../../../services/hooks/useModal';
import ActionButton from '../../../../components/Tabela/ActionButton';
import Confirmar from '../../../../components/Modais/Confirmar';
import Display from '../../../../components/Tabela/Display';
import ReactApexChart from 'react-apexcharts';
import { useHistory } from 'react-router-dom';
import X9Logger from '../../../../services/X9Logger';
import { Tooltip } from '@material-ui/core';
import Formatter from '../../../../services/Formatter';
import FormArracoamentoPeriodo from '../../Arracoamento/FormArracoamentoPeriodo';
import { AplicacaoInsumo } from '../../../../@types/Insumo';
import FormAplicacao from './FormAplicacao';
import VerTanques from './VerTanques';
import { permissoes } from '../../../../services/utils/permissoes';
// import { returnTanque } from '../../../../services/utils/propriedade';

const AplicacaoInsumos: React.FC = () => {
  const [aplicacoesPesquisadas, setAplicacoesPesquisadas] = useState<AplicacaoInsumo[] | undefined>(undefined);
  console.log(setAplicacoesPesquisadas);
  const state = useLocalStore(() => ({
    pageSize: 0,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
    rows: [] as AplicacaoInsumo[],
    selectedAplicacaoInsumo: null as AplicacaoInsumo | null,
    sync: false,
  }));
  const modalCadastro = useModal();
  const modalCadastroPeriodo = useModal();
  const modalEditar = useModal();
  const modalExclusao = useModal();
  const modalTanques = useModal();
  const tableRef = useRef();
  const store = useStore();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.CONTROLE_DE_INSUMOS_APLICACAO_DE_INSUMOS_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.CONTROLE_DE_INSUMOS_APLICACAO_DE_INSUMOS_ESCRITA);
  const temPermissaoLeitura = store.checkPermissions(permissoes.CONTROLE_DE_INSUMOS_APLICACAO_DE_INSUMOS_LEITURA);

  const garficoBarra = useLocalStore(() => ({
    series: [
      {
        name: 'Estoque',
        data: [10000],
      },
    ],
    options: {
      chart: {
        height: 180,
        type: 'bar',
        id: 'insumos',
        events: {},
      },
      colors: store.colors,
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          formatter: (val: string) => {
            return Formatter.formatNumber(3, val) + ' kg';
          },
        },
      },
    },
  }));

  const columns: Column<object>[] = [
    {
      field: 'insumo',
      title: 'Insumo',
      width: 150,
    },
    {
      field: 'acoes',
      render: (rowData: any) => (
        //retirar editar
        <ActionButton
          // editar={
          //     temPermissaoEscrita && !rowData.lancado
          //         ? () => {
          //             state.selectedAplicacaoInsumo = state.rows.filter((arracoamento) => arracoamento.id === rowData.id)[0];
          //             setAplicacoesPesquisadas(undefined);
          //             modalEditar.open();
          //         }
          //         : undefined
          // }
          verTanques={
            temPermissaoLeitura
              ? () => {
                  state.selectedAplicacaoInsumo = state.rows.filter((aplicacao) => aplicacao.id === rowData.id)[0];
                  modalTanques.open();
                }
              : undefined
          }
          excluir={
            temPermissaoEscrita
              ? () => {
                  state.selectedAplicacaoInsumo = state.rows.filter((aplicacao) => aplicacao.id === rowData.id)[0];
                  modalExclusao.open();
                }
              : undefined
          }
        />
      ),
      width: 25,
    },
    {
      field: 'tipo',
      title: 'Tipo',
      width: 100,
    },
    {
      field: 'data',
      title: 'Data',
      type: 'date',
      width: 100,
    },
    {
      field: 'quantidade',
      title: 'Quantidade (kg)',
      type: 'numeric',
      render: ({ quantidade }: any) => {
        return Formatter.formatNumber(3, quantidade);
      },
      width: 180,
    },
    {
      field: 'unidade',
      title: 'Un',
      width: 100,
    },
    {
      field: 'tanques',
      title: 'Tanques',
      render: (rowData: any) => {
        const tanques = rowData.tanques.join(', ');
        return (
          <Tooltip title={tanques}>
            <span>{tanques.substr(0, 20) + '...'}</span>
          </Tooltip>
        );
      },
      width: 180,
    },
  ];

  async function sync(): Promise<void> {
    store.toggleLoader();
    await store.racoes.populate();
    await store.tanques.populate();
    await store.lotes.populate();
    await store.insumos.populate();
    await store.loteTanque.populate();
    state.sync = true;
    ApexCharts.exec('insumos', 'updateSeries', [
      {
        data: store.insumos?.map((insumos) => {
          const estoque = insumos.estoque < 0 ? 0 : insumos.estoque;
          return { x: insumos.nome, y: estoque };
        }),
      },
    ]);
    store.toggleLoader();
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Aplicações de Insumos'), []);

  useEffect(() => {
    if (store.propriedade) {
      sync();
      (tableRef.current as any)?.onQueryChange();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  async function deletar(): Promise<void> {
    modalExclusao.close();
    store.toggleLoader();
    await apiV2
      .delete('/insumo-aplicacao/' + state.selectedAplicacaoInsumo!.id + '/', {
        params: {
          prop: store.propriedade!.id,
        },
      })
      .then(() => store.notificar('Excluído com sucesso!'));
    store.toggleLoader();

    sync();
    (tableRef.current as any).onQueryChange();
  }

  const ExcluirAplicacao: React.FC = () => {
    let quantidade = 0;
    const tanques: string[] = [];

    state.selectedAplicacaoInsumo!.tanque_data.map((tanqueData: any) => {
      quantidade += tanqueData.quantidade;
      tanques.push(tanqueData.tanque__nome);
      return null;
    });

    return (
      <div style={{ width: '100%' }}>
        <b>Insumo: </b> {state.selectedAplicacaoInsumo!.insumo.nome}
        <br />
        <b>Data: </b> {Formatter.dateToString(new Date(state.selectedAplicacaoInsumo!.data)).substr(0, 10)}
        <br />
        <b>Quantidade: </b>
        {quantidade && Formatter.formatNumber(3, quantidade)}
        <br />
        <b>Tanques: </b>
        {tanques.join(', ')}
      </div>
    );
  };

  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn disabled={!temPermissaoEscrita} onClick={modalCadastro.open} text="ADICIONAR" Icon={AddIcon} />
        </Flex>
        <Flex>
          <Filtro
            sync={async () => {
              await sync();
              (tableRef.current as any).onQueryChange();
            }}
            clear={() => null}
          ></Filtro>
        </Flex>
      </Header>

      <Body gridColumns="1fr 500px">
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          <MaterialTable
            tableRef={tableRef}
            columns={columns}
            data={
              aplicacoesPesquisadas
                ? aplicacoesPesquisadas
                : (query) =>
                    new Promise((resolve) => {
                      try {
                        store.propriedade &&
                          apiV2
                            .get('/insumo-aplicacao/', {
                              params: {
                                page_size: 50,
                                page: query.page + 1,
                                prop: store.propriedade!.id,
                              },
                            })
                            .then((res) => {
                              //Acertar tipagem...
                              const rows = (aplicacoes: any): object[] => {
                                return aplicacoes.map((aplicacao: any) => {
                                  // const quantidade = 0;
                                  let tq: string;
                                  const tanques: string[] = []; // nomes dos tanques ou codigo, vindo da api
                                  aplicacao.tanque_data.map((tanqueData: any) => {
                                    if (tanqueData.tanque__nome === '' || tanqueData.tanque__nome === null) {
                                      tq = tanqueData.tanque__codigo;
                                    } else {
                                      tq = tanqueData.tanque__nome;
                                    }
                                    return tanques.push(tq);
                                  });
                                  const data = new Date(aplicacao.data);

                                  return {
                                    id: aplicacao.id,
                                    insumo: aplicacao.insumo.nome,
                                    tipo: aplicacao.insumo.tipo_produto_nome,
                                    data,
                                    quantidade: aplicacao.quantidade,
                                    tanques,
                                    unidade: aplicacao.insumo.tipo_unidade,
                                  };
                                });
                              };

                              state.rows = res.data.results;

                              if (res.data.count) {
                                if (res.data.count >= 50) {
                                  state.setPageSize(50);
                                } else {
                                  state.setPageSize(res.data.count);
                                }
                              } else {
                                state.setPageSize(0);
                              }

                              resolve({
                                data: rows(res.data.results) as any,
                                page: query.page,
                                totalCount: res.data.count,
                              });
                            });
                      } catch (err) {
                        X9Logger.report(err as Error);
                        store.notificar('Ocorreu um erro ao adquirir informações. Tente novamente mais tarde!');
                      }
                    })
            }
            localization={newConfig.localization}
            style={config.style}
            options={{ ...config.options, pageSize: state.pageSize }}
          />
        </div>
        <div>
          <Display style={{ marginBottom: '10px', height: 350 }} overflowY={false} title="Estoque de Insumos">
            <ReactApexChart
              height={260}
              options={garficoBarra.options as any}
              series={garficoBarra.series}
              type="bar"
            />
          </Display>
        </div>
      </Body>
      {modalCadastro.state && (
        <FormAplicacao
          open={modalCadastro.state}
          handleClose={modalCadastro.close}
          onConfirm={() => {
            sync();
            (tableRef.current as any).onQueryChange();
          }}
        />
      )}
      {modalCadastroPeriodo.state && (
        <FormArracoamentoPeriodo
          open={modalCadastroPeriodo.state}
          handleClose={modalCadastroPeriodo.close}
          onConfirm={() => {
            sync();
            (tableRef.current as any).onQueryChange();
          }}
        />
      )}
      {modalEditar.state && (
        <FormAplicacao
          open={modalEditar.state}
          handleClose={modalEditar.close}
          aplicacaoInsumo={state.selectedAplicacaoInsumo || undefined}
          onConfirm={() => {
            sync();
            (tableRef.current as any).onQueryChange();
          }}
        />
      )}
      {modalTanques.state && (
        <VerTanques
          open={modalTanques.state}
          handleClose={modalTanques.close}
          aplicacao={state.selectedAplicacaoInsumo!}
        />
      )}

      {modalExclusao.state && (
        <Confirmar
          open={modalExclusao.state}
          content={<ExcluirAplicacao />}
          title="Tem certeza que deseja excluir?"
          handleClose={modalExclusao.close}
          onCancel={modalExclusao.close}
          onConfirm={deletar}
        />
      )}
    </>
  ) : null;
};

export default observer(AplicacaoInsumos);

// ------------------------------------------

// const data = {
//     vez_dia: 10,
//     insumo: 1530,
//     lancado: false,
//     data: new Date(),
//     tanque_data: {
//         uuid: "4228c7be-d818-4ca7-a0ae-83f23cf875dc",
//         tanque: { codigo: "TQ - 3", id: 6492, nome: "poço fundo" },
//         lote_tanque: { id: 5147 },
//         tipo: "racao_por_animal",
//         percentual_biomassa: 0,
//         percentual_temperatura: 0,
//         peso: 0,
//         qtd_racao: 0.426,
//     }
// };
