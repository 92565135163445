import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../components/Modais/styles';
import Botao from '../../components/Botoes/Botao';
import InputNumero from '../../components/InputNumero';
import useStore from '../../services/hooks/useStore';

type Props = ModalProps & {
  confirmar(quantidade: number): void;
};
const QuantidadeModal: React.FC<Props> = ({ open, handleClose, confirmar }) => {
  const [quantidade, setQuantidade] = useState(2);
  const store = useStore();

  function checar(): void {
    if (quantidade < 2 || isNaN(quantidade)) store.notificar('Informe a quantidade');
    else confirmar(quantidade);
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Quantidade de tanques</ModalHeader>
      <ModalBody>
        <InputNumero
          value={quantidade}
          onChange={(value) => setQuantidade(value)}
          precision="0"
          label="Quantidade de tanques"
        />
        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao onClick={checar} variant="contained" cor="#00C853">
            Confirmar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default QuantidadeModal;
