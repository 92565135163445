import React from 'react';
import useStore, { stateContext } from '../../../services/hooks/useStore';
import { api } from '../../../services/utils';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Modal, ModalBody, ModalHeader } from '../../../components/Modais/styles';
import { TextField } from '@material-ui/core';
import Select from '../../../components/Select';
import InputNumero from '../../../components/InputNumero';
import Botao from '../../../components/Botoes/Botao';

type Props = ModalProps & {
  bancada: any;
  sync?: () => void;
};

const EditarBancada: React.FC<Props> = ({ open, handleClose, bancada, sync }) => {
  const store = useStore();
  const state = useLocalStore(() => ({
    nome: bancada.nome,
    tamanho: bancada.tamanho,
    capacidade: bancada.capacidade,
    aguaOrigem: { label: bancada.agua_origem.nome, value: bancada.agua_origem.id.toString() },
    tipoTanque: { label: bancada.tanque_tipo.nome, value: bancada.tanque_tipo.id.toString() },
    situacaoBancada: { label: bancada.situacao_tanque.nome, value: bancada.situacao_tanque.id.toString() },
    reservatorio: bancada.reservatorio,
  }));

  async function editarApi(dados: TanqueData, bancadaID: number, sync?: () => void): Promise<void> {
    const store = stateContext.state;

    await api
      .put(`/tanques/${bancadaID}/?propriedade=${store.propriedade!.id}`, dados)
      .then(async () => {
        store.notificar('Bancada editada com sucesso!');
        await Promise.all([
          store.tanques.populate(),
          store.lotes.populate(),
          store.loteTanque.populate(),
          sync && sync(),
        ]);
      })
      .catch(() => store.notificar('Ocorreu um erro ao editar, tente novamente'));
  }
  async function editar(): Promise<void> {
    if (state.tamanho === 0) store.notificar('Informe o tamanho da bancada');
    else if (state.capacidade === 0) store.notificar('Informe a capacidade da bancada');
    else if (!state.aguaOrigem) store.notificar('Informe a origem da água');
    else if (!state.tipoTanque) store.notificar('Informe o tipo da bancada');
    else if (!state.situacaoBancada) store.notificar('Informe a situação da bancada');
    else if (!state.reservatorio) store.notificar('Informe o reservatorio da bancada');
    else {
      store.toggleLoader();

      const data = {
        codigo: bancada.codigo,
        propriedade: store.propriedade!.id,
        tanque_tipo: parseInt(state.tipoTanque.value),
        agua_origem: parseInt(state.aguaOrigem.value),
        tamanho: state.tamanho,
        nome: state.nome || null,
        capacidade: state.capacidade,
        situacao_tanque: parseInt(state.situacaoBancada.value),
        reservatorio: state.reservatorio.value || null,
      };

      await editarApi(data as any, bancada.id, sync && (sync() as any));

      store.toggleLoader();
      handleClose();
    }
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Editar Bancada</ModalHeader>
      <ModalBody>
        <div style={{ width: '500px' }}>
          <TextField label="Nome" value={state.nome} onChange={(e) => (state.nome = e.target.value)} />

          <div style={{ width: '100%' }}>
            {store.origensDaAgua.arr && (
              <Select
                value={state.aguaOrigem}
                placeholder="Selecione a origem da água"
                onChange={(e: any) => (state.aguaOrigem = e)}
                options={store.origensDaAgua.getSelectRows()}
              />
            )}
          </div>
          <div style={{ width: '100%' }}>
            <Select
              placeholder="Selecione o reservatorio"
              value={state.reservatorio}
              onChange={(e) => (state.reservatorio = e)}
              options={store.reservatorios.getSelectRows()}
            />
          </div>
          {/* <div style={{ width: '100%' }}>
            {store.tiposDeTanque.arr && (
              <Select
                value={state.tipoTanque}
                placeholder="Selecione o tipo de bancada"
                onChange={(e: any) => (state.tipoTanque = e)}
                options={store.tiposDeTanque.getSelectRows()}
              />
            )}
          </div> */}
          <div style={{ width: '100%' }}>
            {store.situacoesDeTanque.arr && (
              <Select
                value={state.situacaoBancada}
                placeholder="Selecione a situação do bancada"
                onChange={(e: any) => (state.situacaoBancada = e)}
                options={store.situacoesDeTanque.getSelectRows()}
              />
            )}
          </div>

          <InputNumero
            value={state.tamanho}
            onChange={(value) => (state.tamanho = value)}
            precision="2"
            label="Tamanho (m²/m³)"
          />
          <InputNumero
            value={state.capacidade}
            onChange={(value) => (state.capacidade = value)}
            precision="0"
            label="Capacidade (Kg)"
          />

          <div style={{ width: '100%', display: 'flex' }}>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao onClick={editar} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(EditarBancada);
