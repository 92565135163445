/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import useStore from '../../../services/hooks/useStore';
import { LoteTanqueInfo } from '../../../components/LoteTanqueInfo';
import { observer, useLocalStore } from 'mobx-react-lite';
import { returnTanque } from '../../../services/utils/propriedade';
import Formatter from '../../../services/Formatter';
import { apiV2 } from '../../../services/utils';

type Props = ModalProps & {
  setTanque(tanque: { label: string; value: string; loteTanque: number }): void;
  setLote(lote: Lote): void;
  exclude: number[];
  diasDiferenca?: number;
};
const SelecionarLoteTanques: React.FC<Props> = ({ open, handleClose, setTanque, setLote }) => {
  const store = useStore();
  const state = useLocalStore(() => ({
    sync: false,
  }));
  const [loteTanques, setLoteTanques] = useState<LoteTanque[]>([] as LoteTanque[]);
  const [, setSelectedQuantidade] = useState<any | null>(null);
  const [, setSelectedLoteTanque] = useState<LoteTanque | null>(null);

  useEffect(() => {
    store.toggleLoader();
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function searchTanque() {
      await apiV2
        .get('/lote-tanque/modals/', {
          params: {
            propriedade: store.propriedade!.id,
          },
        })
        .then((res) => {
          setLoteTanques(res.data.results);

          state.sync = true;
        });
    }
    searchTanque();
    store.toggleLoader();
    // eslint-disable-next-line
  }, []);

  const LoteTanquesDisponiveis: React.FC = () => (
    <>
      {loteTanques
        ?.sort((a: any, b: any) => {
          const textA = a.tanque.nome ? a.tanque.nome.toUpperCase() : a.tanque.codigo;
          const textB = b.tanque.nome ? b.tanque.nome.toUpperCase() : b.tanque.codigo;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map((loteTanque: any) => {
          if (loteTanque) {
            const PM = loteTanque.peso_medio_atual || loteTanque.peso_medio;

            return loteTanque.estoque > 0 ? (
              <LoteTanqueInfo
                onClick={() => {
                  setTanque({
                    label: returnTanque(loteTanque.tanque),
                    value: loteTanque.tanque.id.toString(),
                    loteTanque: loteTanque.id,
                  });
                  setSelectedQuantidade(loteTanque.estoque);
                  setSelectedLoteTanque(loteTanque);
                  setLote(loteTanque.lote);
                  handleClose();
                }}
                key={loteTanque.id}
              >
                <p className="left">{returnTanque(loteTanque.tanque)}</p>
                <p className="right">
                  {loteTanque.lote.codigo} ({Formatter.formatNumber(0, loteTanque.estoque)})
                </p>
                <b className="left" style={{ color: '#00C853' }}>
                  {Formatter.formatNumber(2, (PM * loteTanque.estoque) / 1000)} Kg
                </b>
                <p className="right" style={{ color: '#00C853' }}>
                  {loteTanque.fase_lote.nome}
                </p>
              </LoteTanqueInfo>
            ) : null;
          }
        })}
    </>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>Selecione um Tanque</ModalHeader>
      <ModalBody>
        <LoteTanquesDisponiveis />
      </ModalBody>
    </Modal>
  );
};

export default observer(SelecionarLoteTanques);
