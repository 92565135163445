import { observable, computed } from 'mobx';
import { api } from '../utils';

class AguasDeReservatorios {
  @observable arr: AguaDeReservatorios[] | null = null;
  @observable totalResults: number = 0;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async getPaginated(): Promise<void> {
    api
      .get(`/analise-agua-reservatorio/`, {
        // params: {
        //   page_size,
        //   page: page + 1,
        //   propriedade,
        //   tanque,
        //   parametro_valor,
        //   data_final,
        //   data_inicial,
        // },
      })
      .then((res) => {
        this.totalResults = res.data.totalResults;
        this.arr = res.data.results;
      });
  }

  get(id: number): AguaDeReservatorios {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  filter(
    callback: (item: AguaDeReservatorios, index: number, array: AguaDeReservatorios[]) => void,
  ): AguaDeReservatorios[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: AguaDeReservatorios, index: number, array: AguaDeReservatorios[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default AguasDeReservatorios;
