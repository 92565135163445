import { getThings, api } from '../utils';
import { observable, computed } from 'mobx';
import { stateContext } from '../hooks/useStore';

type CategoriaData = {
  propriedade: number;
  nome: string;
  tipo: string;
  editavel: boolean;
};

class CategoriasFinanceiras {
  @observable arr: Categoria[] | null = null;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    this.arr = await getThings<Categoria>('/categoria-financeira/');
  }

  async getEverything(params?: object): Promise<Categoria[]> {
    return await getThings<Categoria>('/categoria-financeira/', params);
  }

  get(id: number): Categoria {
    return this.arr!.filter((item) => item.id === id)[0];
  }
  getNome(nome: string): Categoria {
    return this.arr!.filter((item) => item.nome === nome)[0];
  }

  getByTipo(tipo: string): Categoria[] {
    return this.arr!.filter((item) => item.tipo === tipo);
  }

  getSelectRows(): SelectRow[] {
    return this.arr!.slice()
      .sort((a: Categoria, b: Categoria) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      })
      .map((item: Categoria) => {
        return { label: item.nome, value: item.id.toString() };
      });
  }

  async criar(dados: CategoriaData): Promise<void> {
    const store = stateContext.state;

    await api
      .post(`/categoria-financeira/?propriedade=${store.propriedade!.id}`, dados)
      .then(async () => {
        store.notificar('Categoria cadastrada com sucesso!');
        await store.categorias.populate();
      })
      .catch(() => store.notificar('Ocorreu um erro ao cadastrar a catgeoria!'));
  }

  async editar(categoriaID: number, dados: CategoriaData): Promise<void> {
    const store = stateContext.state;

    await api
      .put(`/categoria-financeira/${categoriaID}/?propriedade=${store.propriedade!.id}`, dados)
      .then(async () => {
        store.notificar('Categoria editada com sucesso!');
        await store.categorias.populate();
      })
      .catch(() => store.notificar('Ocorreu um erro ao editar a categoria!'));
  }

  async excluir(categoriaID: number): Promise<void> {
    const store = stateContext.state;

    await api
      .delete(`/categoria-financeira/${categoriaID}/?propriedade=${store.propriedade!.id}`)
      .then(() => store.notificar('Categoria excluída com sucesso!'));
    await store.categorias.populate();
  }

  filter(callback: (item: Categoria, index: number, array: Categoria[]) => void): Categoria[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: Categoria, index: number, array: Categoria[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default CategoriasFinanceiras;
