import React from 'react';
import useStore from '../../../services/hooks/useStore';
import { useLocalStore, observer } from 'mobx-react-lite';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { TextField, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import Botao from '../../../components/Botoes/Botao';

type Props = ModalProps & {
  contaID: number;
};
const FormConta: React.FC<Props> = ({ open, handleClose, contaID }) => {
  const store = useStore();
  const temperatura = contaID ? store.temperatura.get(contaID) : null;
  const state = useLocalStore(() => ({
    temperatura: temperatura ? temperatura.temperatura : '',
    racao: temperatura ? temperatura.racao : '',
    padrao: temperatura ? temperatura.is_padrao : false,
    inativo: temperatura ? (temperatura.ativo === true ? false : true) : false,
  }));

  async function cadastrar(): Promise<void> {
    if (!state.temperatura) {
      store.notificar('Informe o título da conta');
    } else {
      handleClose();
      store.toggleLoader();

      const data = {
        propriedade: store.propriedade?.id,
        temperatura: parseInt(state.temperatura as string),
        racao: parseInt(state.racao as string),
        is_padrao: state.padrao,
        ativo: state.inativo === false ? true : false,
      };

      contaID ? await store.temperatura.editar(contaID, data) : await store.temperatura.criar(data);
      state.padrao &&
        store.temperatura.arr!.some((temperatura) => temperatura.is_padrao) &&
        store.notificar('Essa temperatura passou a ser padrão agora!');
      store.toggleLoader();
    }
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{contaID ? 'Editar' : 'Nova '} Temperatura</ModalHeader>
      <ModalBody>
        <div style={{ width: 400, textAlign: 'end' }}>
          <TextField
            value={state.temperatura}
            onChange={(e) => (state.temperatura = e.target.value)}
            label="Informe a Temperatura"
          />
          <TextField
            value={state.racao}
            onChange={(e) => (state.racao = e.target.value)}
            label="% Ração"
            disabled={temperatura && !temperatura.removivel ? true : false}
          />
          <FormGroup row={false}>
            <FormControlLabel
              control={
                <Checkbox checked={state.padrao} onChange={(e) => (state.padrao = e.target.checked)} color="primary" />
              }
              label="Temperatura Padrão"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.inativo}
                  onChange={(e) => (state.inativo = e.target.checked)}
                  color="primary"
                />
              }
              label="Desativar"
            />
          </FormGroup>
          <small
            style={{
              width: '100%',
            }}
          >
            Apenas uma temperatua ficará como padrão. *
          </small>

          <div style={{ width: 400, display: 'flex' }}>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao onClick={cadastrar} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(FormConta);
