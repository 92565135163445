import React from 'react';
import { Modal, ModalHeader, ModalBody } from './styles';
import { api } from '../../services/utils';
import useStore from '../../services/hooks/useStore';
import { TextField } from '@material-ui/core';
import Botao from '../Botoes/Botao';
import { useLocalStore, observer } from 'mobx-react-lite';

const ModalAlterarSenha: React.FC<ModalProps> = ({ open, handleClose }) => {
  const store = useStore();

  const local = useLocalStore(() => ({
    senha: '',
    novaSenha: '',
    confirmarSenha: '',
  }));

  function alterarSenha(): void {
    if (local.senha.length >= 6) {
      if (local.novaSenha.length >= 6) {
        if (local.novaSenha === local.confirmarSenha) {
          api
            .post('/auth/change-pass/', {
              senha_nova: local.novaSenha,

              senha_antiga: local.senha,
            })
            .then(() => {
              store.toggleModalAlterarSenha();
              store.notificar('Senha alterada!');
            })
            .catch(() => {
              store.notificar('Senha atual incorreta!');
            });
        } else {
          store.notificar('A nova senha não coincide com a confirmação!');
        }
      } else {
        store.notificar('A nova senha deve ter, no mínimo, 6 caracteres!');
      }
    } else {
      store.notificar('A senha deve ter, no mínimo, 6 caracteres!');
    }
  }
  function cancelar(): void {
    local.senha = '';
    local.novaSenha = '';
    local.confirmarSenha = '';
    store.toggleModalAlterarSenha();
  }
  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Alterar senha</ModalHeader>
      <ModalBody>
        <TextField
          value={local.senha}
          onChange={(e) => (local.senha = e.target.value)}
          label="Senha atual"
          type="password"
        />
        <TextField
          value={local.novaSenha}
          onChange={(e) => (local.novaSenha = e.target.value)}
          label="Nova senha"
          type="password"
        />
        <TextField
          value={local.confirmarSenha}
          onChange={(e) => (local.confirmarSenha = e.target.value)}
          label="Confirme a nova senha"
          type="password"
        />

        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={cancelar} variant="contained" cor="#FC7467">
            Voltar
          </Botao>
          <Botao onClick={alterarSenha} variant="contained" cor="#00C853">
            Alterar senha
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(ModalAlterarSenha);
