/* eslint-disable react/display-name */
import React, { useEffect, useRef } from 'react';
import { Flex } from '../../../components/NavBar/styles';
import { NewBody, Conteudo } from '../../../components/Body';
import { IconButton, Tooltip, FormControlLabel, Checkbox } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import useStore from '../../../services/hooks/useStore';
import Select from '../../../components/Select';
import { Link, useHistory } from 'react-router-dom';
import { useLocalStore, observer } from 'mobx-react-lite';
import MaterialTable, { Column } from 'material-table';
import { newConfig } from '../../../components/Tabela';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import syncModel from '../../../services/models/Sync';
import FilterListIcon from '@material-ui/icons/FilterList';
import useModal from '../../../services/hooks/useModal';
import FiltroAvancado from '../../../components/Modais/FiltroAvancado';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import X9Logger from '../../../services/X9Logger';
import { Divisor } from '../../../components/Modais/styles';
import { apiMain, aqReports } from '../../../services/utils';
import Formatter from '../../../services/Formatter';
import { permissoes } from '../../../services/utils/permissoes';

const DesempenhoLotes: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.PAINEL_ZOOTECNICO_LEITURA, history);
  const tableRef = useRef(null);
  const modalFiltros = useModal();
  const state = useLocalStore(() => ({
    pageSize: 0,
    rows: [] as object[],
    sync: false,

    filtroFase: null as SelectRow | null,
    filtroDataInicio: null as MaterialUiPickersDate,
    filtroDataFim: null as MaterialUiPickersDate,

    filtroRapidoFinalizado: true,
    filtroRapidoFase: null as SelectRow | null,

    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
  }));

  async function sync(): Promise<void> {
    const tabela: any = tableRef.current;
    tabela.onQueryChange();
  }

  function limparFiltros(): void {
    state.filtroFase = null;

    state.filtroRapidoFase = null;
    state.filtroRapidoFinalizado = false;

    sync();
  }

  useEffect(() => {
    store.setTitulo('Desempenho dos Lotes');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getThings(): Promise<void> {
      await syncModel.desempenhoLotes();
      state.sync = true;
    }
    if (store.propriedade) {
      getThings();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  const columns: Column<object>[] = [
    {
      field: 'lote',
      title: 'Lote',
      render: (rowData: any) => {
        return rowData.lote.nome;
      },
      width: 150,
    },
    {
      field: 'tanques',
      title: 'Tanques',
      render: (rowData: any) => {
        const tanques: DesempenhoLote['tanques'] = rowData.tanques;
        const string = tanques
          .map((tanque) => {
            return tanque.nome || tanque.codigo;
          })
          .join(', ');

        if (string.length > 20) {
          return string.substr(0, 20) + '...';
        } else {
          return string;
        }
      },
      width: 200,
    },
    {
      field: 'fase',
      title: 'Fase',
      render: ({ fase }: any) => {
        const color = fase === 'FINALIZADO' ? 'green' : 'black';
        return <span style={{ color: color }}>{fase}</span>;
      },
      width: 140,
    },
    {
      field: 'data_inicio',
      title: 'Data Início',
      type: 'date',
      width: 120,
    },
    {
      field: 'ultima_atualizacao',
      title: 'Última Atualização',
      type: 'date',
      width: 180,
    },
    {
      field: 'ciclo',
      title: 'Ciclo (D)',
      type: 'numeric',
      render: ({ ciclo }: any) => {
        return Formatter.formatNumber(0, ciclo);
      },
      width: 120,
    },
    {
      field: 'qtd_inicial',
      title: 'Qtd Animais Inicial',
      type: 'numeric',
      render: ({ qtd_inicial }: any) => {
        return Formatter.formatNumber(0, qtd_inicial);
      },
      width: 180,
    },
    {
      field: 'qtd_atual',
      title: 'Qtd Animais Atual',
      type: 'numeric',
      render: ({ qtd_atual }: any) => {
        return Formatter.formatNumber(0, qtd_atual);
      },
      width: 160,
    },
    {
      field: 'qtd_mortalidade',
      title: 'Qtd Mortalidade',
      type: 'numeric',
      render: ({ qtd_mortalidade }: any) => {
        return Formatter.formatNumber(0, qtd_mortalidade);
      },
      width: 160,
    },
    {
      field: 'qtd_vendida',
      title: 'Qtd Vendida',
      type: 'numeric',
      render: ({ qtd_vendida }: any) => {
        return Formatter.formatNumber(0, qtd_vendida);
      },
      width: 140,
    },
    {
      field: 'biomassa_vendida',
      title: 'Biomassa Vendida (kg)',
      type: 'numeric',
      render: ({ biomassa_vendida }: any) => {
        return Formatter.formatNumber(2, biomassa_vendida);
      },
      width: 200,
    },
    {
      field: 'valor_kg',
      title: 'Valor Venda (R$/Kg)',
      type: 'numeric',
      render: ({ valor_kg }: any) => {
        return Formatter.formatNumber(2, valor_kg);
      },
      width: 140,
    },
    {
      field: 'valor_total',
      title: 'Valor Total Venda (R$)',
      type: 'numeric',
      render: ({ valor_total }: any) => {
        return Formatter.formatNumber(2, valor_total);
      },
      width: 200,
    },
    {
      field: 'pm_inicial',
      title: 'PM Inicial (g)',
      type: 'numeric',
      render: ({ pm_inicial }: any) => {
        return Formatter.formatNumber(3, pm_inicial);
      },
      width: 140,
    },
    {
      field: 'pm_atual',
      title: 'PM Atual (g)',
      type: 'numeric',
      render: ({ pm_atual }: any) => {
        return Formatter.formatNumber(3, pm_atual);
      },
      width: 140,
    },
    {
      field: 'gp',
      title: 'GP (g)',
      type: 'numeric',
      render: ({ gp }: any) => {
        return Formatter.formatNumber(3, gp);
      },
      width: 140,
    },
    {
      field: 'bg',
      title: 'BG (kg)',
      type: 'numeric',
      render: ({ bg }: any) => {
        return Formatter.formatNumber(2, bg);
      },
      width: 140,
    },
    {
      field: 'be',
      title: 'BE (kg)',
      type: 'numeric',
      render: ({ be }: any) => {
        return Formatter.formatNumber(2, be);
      },
      width: 140,
    },
    {
      field: 'racao',
      title: 'Ração Consumida (kg)',
      type: 'numeric',
      render: ({ racao }: any) => {
        return Formatter.formatNumber(2, racao);
      },
      width: 200,
    },
    {
      field: 'ca',
      title: 'CA',
      type: 'numeric',
      render: ({ ca }: any) => {
        return Formatter.formatNumber(3, ca);
      },
      width: 140,
    },
    {
      field: 'gpd',
      title: 'GPD (g)',
      type: 'numeric',
      render: ({ gpd }: any) => {
        return Formatter.formatNumber(3, gpd);
      },
      width: 140,
    },
    {
      field: 'sv',
      title: 'SV',
      type: 'numeric',
      render: (rowData: any) => {
        return Formatter.formatNumber(2, rowData.sv) + '%';
      },
      width: 140,
    },
  ];
  async function exportToExcel() {
    try {
      store.toggleLoader();
      const response = await apiMain.get(
        `/gerador/csv/relatorio/desempenho/${store.propriedade?.uuid}/lotes/`,

        {
          params: {
            finalizados: state.filtroRapidoFinalizado,
          },
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${store.token}`,
          },
        },
      );
      if (response.status !== 200) {
        throw new Error('Erro ao gerar o arquivo.');
      }
      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const filename = `Aquabit_${Formatter.dateToString(new Date(), '/')}_DesempenhoLotes.csv`;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      X9Logger.report(error as Error);
      store.notificar('Ocorreu um erro ao gerar o arquivo. Tente novamente mais tarde!');
    } finally {
      store.toggleLoader();
    }
  }
  async function exportToPDF() {
    try {
      store.toggleLoader();

      const response = await apiMain.get(`/gerador/pdf/relatorio/desempenho/${store.propriedade?.uuid}/lotes/`, {
        params: {
          finalizados: state.filtroRapidoFinalizado,
        },
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${store.token}`,
        },
      });

      if (response.status !== 200) {
        throw new Error('Erro ao gerar o arquivo.');
      }

      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const filename = `Aquabit_${Formatter.dateToString(new Date(), '/')}_DesempenhoLotes.pdf`;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      X9Logger.report(error as Error);
      store.notificar('Ocorreu um erro ao gerar o arquivo. Tente novamente mais tarde!');
    } finally {
      store.toggleLoader();
    }
  }

  return state.sync ? (
    <NewBody>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Link
          to={'/relatorios/relatorios-zootecnicos'}
          style={{ color: '#049CE7', textDecoration: 'none', fontFamily: 'Roboto' }}
        >
          <Flex>
            <img src={'/images/returnArrow.svg'} />
            <div style={{ paddingLeft: '5px', fontFamily: 'Roboto', color: '#049CE7' }}>Lista de Relatórios</div>
          </Flex>
        </Link>
        <Flex>
          <FormControlLabel
            style={{ marginLeft: 15 }}
            control={
              <Checkbox
                checked={state.filtroRapidoFinalizado}
                onChange={(_e, checked) => {
                  state.filtroRapidoFinalizado = checked;
                  sync();
                }}
                color="primary"
              />
            }
            label="Somente finalizados"
          />
          <div style={{ width: 160 }}>
            <Select
              value={state.filtroRapidoFase}
              placeholder="Fase do lote"
              onChange={(e: any) => {
                state.filtroRapidoFase = e;
                sync();
              }}
              options={store.fasesLote.getSelectRows()}
            />
          </div>

          <Tooltip title="Exportar para Excel">
            <IconButton className="blue" onClick={exportToExcel}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Baixar PDF">
            <IconButton className="blue" onClick={exportToPDF}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Filtro Avançado">
            <IconButton className="blue" onClick={modalFiltros.open}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sincronizar">
            <IconButton className="blue" onClick={sync}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>

      <Conteudo style={{ padding: 0 }}>
        {store.propriedade && (
          <MaterialTable
            tableRef={tableRef}
            columns={columns}
            data={(query) =>
              new Promise((resolve, reject) => {
                aqReports
                  .get('/relatorio/desempenho/' + store.propriedade?.uuid + '/lotes', {
                    params: {
                      fase: state.filtroFase?.value || state.filtroRapidoFase?.value,
                      data_inicio: state.filtroDataInicio
                        ? Formatter.dateToString(state.filtroDataInicio, '-')
                        : undefined,
                      data_fim: state.filtroDataFim ? Formatter.dateToString(state.filtroDataFim, '-') : undefined,
                      finalizados: state.filtroRapidoFinalizado,
                      orderBy: query.orderBy?.field,
                      orderDirection: query.orderDirection || null,
                      page: query.page + 1,
                    },
                  })
                  .then((res) => {
                    if (res.data.count) {
                      state.setPageSize(res.data.count);
                    } else {
                      state.setPageSize(0);
                    }

                    state.rows = res.data.results;

                    resolve({
                      data: res.data.results,
                      page: query.page,
                      totalCount: res.data.count_total,
                    });
                  })
                  .catch((err) => {
                    X9Logger.report(err);
                    store.notificar('Ocorreu um erro ao gerar relatório. Tente novamente mais tarde!');
                    reject();
                  });
              })
            }
            options={{ ...newConfig.options, pageSize: state.pageSize }}
            localization={newConfig.localization}
            style={newConfig.style}
          />
        )}
      </Conteudo>

      <FiltroAvancado
        open={modalFiltros.state}
        handleClose={modalFiltros.close}
        onConfirm={sync}
        onClear={limparFiltros}
      >
        <div style={{ width: '90%' }}>
          <Select
            value={state.filtroFase}
            placeholder="Fase do lote"
            onChange={(e: any) => {
              state.filtroFase = e;
              state.filtroRapidoFase = null;
            }}
            options={store.fasesLote.getSelectRows()}
          />
        </div>
        <Divisor>
          <p>Período da Última Atualização</p>
        </Divisor>
        <div style={{ width: '90%' }}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Início"
            value={state.filtroDataInicio}
            onChange={(date) => (state.filtroDataInicio = date)}
          />
        </div>
        <div style={{ width: '90%' }}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Fim"
            value={state.filtroDataFim}
            onChange={(date) => (state.filtroDataFim = date)}
          />
        </div>
      </FiltroAvancado>
    </NewBody>
  ) : null;
};

export default observer(DesempenhoLotes);
