import { aqReports } from '../utils';
import { observable, computed } from 'mobx';

class Cidades {
  @observable arr: Cidade[] | null = null;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    if (localStorage.getItem('/cidades/')) {
      this.arr = JSON.parse(localStorage.getItem('/cidades/')!);
    } else {
      const request = await aqReports.get('/static/cidades');
      this.arr = request.data;
      localStorage.setItem('/cidades/', JSON.stringify(request.data));
    }
  }

  async getEverything(): Promise<Cidade[]> {
    return await (
      await aqReports.get('/static/cidades')
    ).data;
  }

  get(id: number): Cidade {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  getByEstado(estadoID: number): Cidade[] {
    return this.arr!.filter((item) => item.estado_id === estadoID);
  }

  getSelectRows(): SelectRow[] {
    return this.arr!.slice()
      .sort((a: Cidade, b: Cidade) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      })
      .map((item: Cidade) => {
        return { label: item.nome, value: item.id.toString() };
      });
  }

  filter(callback: (item: Cidade, index: number, array: Cidade[]) => void): Cidade[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: Cidade, index: number, array: Cidade[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default Cidades;
