import styled, { css } from 'styled-components';
import { Button } from '@material-ui/core';

type Props = {
  cor?: string;
  nomargin?: boolean;
  disabled?: boolean;
};
const Botao = styled(Button)<Props>`
  width: 100% !important;
  margin-top: 10px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  background-color: ${(props) => (props.disabled ? '#b5b5b5' : '#029be5')} !important;

  border-radius: 4px !important;
  text-transform: none !important;

  color: white !important;
  ${(props) =>
    props.cor &&
    css`
      background-color: ${props.disabled ? '#b5b5b5' : props.cor}!important;
    `};
  ${(props) =>
    props.nomargin &&
    css`
      margin: 0 !important;
    `};
`;

export default Botao;

export const BotaoSec = styled(Button)<Props>`
  width: 130% !important;
  margin-top: 10px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  background-color: ${(props) => (props.disabled ? '#b5b5b5' : 'transparent')} !important;

  border-radius: 4px !important;
  text-transform: none !important;

  color: ${(props) => (props.cor ? props.cor : 'white')} !important;
  ${(props) =>
    props.cor &&
    css`
      background-color: ${props.disabled ? '#b5b5b5' : 'transparent'}!important;
    `};
  ${(props) =>
    props.nomargin &&
    css`
      margin: 0 !important;
    `};
`;
