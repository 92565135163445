/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Lote } from '../../../components/Modais/styles';
import useStore from '../../../services/hooks/useStore';
import Botao from '../../../components/Botoes/Botao';
import Formatter from '../../../services/Formatter';
import { apiV2 } from '../../../services/utils';
import { observer, useLocalStore } from 'mobx-react-lite';
import { TextField } from '@material-ui/core';
import { Flex } from '../../../components/NavBar/styles';
// import { Checkbox, FormControlLabel } from '@mui/material';

type Props = ModalProps & {
  cancelar(): void;
  addLote(loteID: number): void;
  selectedLotes: SelectedLote[];
};
const SelecionarLoteRateio: React.FC<Props> = ({ open, handleClose, selectedLotes, cancelar, addLote }) => {
  const state = useLocalStore(() => ({
    sync: false,
  }));
  const store = useStore();
  const [codigo, setCodigo] = useState<string>('');
  const [lotes, setLotes] = useState<Lote[]>([] as Lote[]);

  const [, setSelectedLote] = useState<Lote | null>(null);

  useEffect(() => {
    async function searchLote() {
      store.toggleLoader();
      await apiV2
        .get('/lote/modals/', {
          params: {
            propriedade: store.propriedade?.id,       
            nome: codigo,
          },
        })
        .then((res) => {
          setLotes(res.data.results);
        });

      state.sync = true;
      store.toggleLoader();
    }
    searchLote();
  }, [selectedLotes, codigo, setCodigo]);

  const LotesDisponiveis: React.FC = () => (
    <Flex style={{ flexDirection: 'column' }}>
      {lotes
        ?.sort((a: any, b: any) => {
          const textA = a.nome ? a.nome.toUpperCase() : a.codigo;
          const textB = b.nome ? b.nome.toUpperCase() : b.codigo;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map((lote) => {
          const selected = selectedLotes.some((obj) => lote.id === obj.item.id);

          if (lote.estoque && lote.fase_lote.nome !== 'FINALIZADO') {
          return (
            <Lote
              selected={selected || undefined}
              onClick={() => {
                if (!selected) setSelectedLote(lote);
                addLote(lote.id);
              }}
              key={lote.id}
            >
              <div>
                <p>{Formatter.dateToString(new Date(lote.data), '/')}</p>
                <p>Qtde Tanques: {Formatter.formatNumber(0, lote.quantidade_tanques)}</p>
                <p>Qtde Animais: {Formatter.formatNumber(0, lote.estoque)}</p>
              </div>
              <div style={{ textAlign: 'end' }}>
                <b>{lote.codigo}</b>
                <p style={{ color: 'rgb(0, 200, 83)' }}>
                  Peso Médio: {Formatter.formatNumber(3, lote.peso_medio_atual || lote.peso_medio)} g
                </p>
                <p>Biomassa: {Formatter.formatNumber(3, lote.biomassa)} kg</p>
              </div>
            </Lote>
          );
        } else {
          return  null ;
        }
       } )}
    </Flex>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>
        Selecione um Lote{'  '}
        {/* <FormControlLabel
          style={{ marginLeft: 100 }}
          control={
            <Checkbox
              checked={state.filtroRapidoFinalizado}
              onChange={(_e, checked) => {
                state.filtroRapidoFinalizado = checked;
                state.filtroRapidoFase = null;
              }}
              color="primary"
            />
          }
          label="Mostrar finalizados"
        /> */}
        
      </ModalHeader>
      <ModalBody>
        <div
          style={{ color: '#707070', width: '100%', padding: '10px', display: 'flex', justifyContent: 'space-between' }}
        >
          <TextField
            label="Pesquisa Pelo Nome"
            value={codigo}
            onChange={(e) => {
              setCodigo(e.target.value);
            }}
          />
        </div>
        <LotesDisponiveis />
      </ModalBody>
      <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
        <Botao cor="gray" onClick={cancelar}>
          Voltar
        </Botao>
        <Botao cor="#00C853" onClick={handleClose}>
          Confirmar
        </Botao>
      </div>
    </Modal>
  ) 
};

export default observer(SelecionarLoteRateio);
