import { getThings } from '../utils';
import { observable, computed } from 'mobx';

class Clientes {
  @observable arr: Cliente[] | null = null;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    this.arr = await getThings<Cliente>('/clientes/');
  }

  async getEverything(params?: object): Promise<Cliente[]> {
    return await getThings<Cliente>('/clientes/', params);
  }

  get(id: number): Cliente {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  getSelectRows(): SelectRow[] {
    return this.arr!.slice()
      .sort((a: Cliente, b: Cliente) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      })
      .map((item: Cliente) => {
        return { label: item.nome, value: item.id.toString() };
      });
  }

  sort(callback: (a: any, b: any) => any): any {
    return this.arr!.sort(callback);
  }

  filter(callback: (item: Cliente, index: number, array: Cliente[]) => void): Cliente[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: Cliente, index: number, array: Cliente[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default Clientes;
