/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import { Flex } from '../../../components/NavBar/styles';
import AddBtn from '../../../components/Botoes/AddBtn';
import AddIcon from '@material-ui/icons/Add';
import useStore from '../../../services/hooks/useStore';
import { useLocalStore, observer } from 'mobx-react-lite';
import Filtro from '../../../components/Filtro';
import ActionButton from '../../../components/Tabela/ActionButton';
import { Body } from '../../../components/Body';
import MaterialTable, { Column } from 'material-table';
import { config } from '../../../components/Tabela';
import useModal from '../../../services/hooks/useModal';
import FormCliente from './FormCliente';
import Confirmar from '../../../components/Modais/Confirmar';
import { api } from '../../../services/utils';
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import syncModel from '../../../services/models/Sync';
import Formatter from '../../../services/Formatter';
import { permissoes } from '../../../services/utils/permissoes';

const Clientes: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  const modalCadastro = useModal();
  const modalExclusao = useModal();
  const [clientesPesquisados, setClientesPesquisados] = useState<Cliente[] | null>();
  const state = useLocalStore(() => ({
    pageSize: 0,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
    rowData: 0,
    filtroRapidoNome: '',
    selectedCliente: 0,
    sync: false,
  }));
  store.propriedade && store.validarPermissao(permissoes.VENDAS_CLIENTES_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.VENDAS_CLIENTES_LEITURA);

  const columns: Column<object>[] = [
    {
      field: 'nome',
      title: 'Nome',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'acoes',
      render: (rowData: any) => (
        <ActionButton
          editar={
            temPermissaoEscrita
              ? () => {
                  state.selectedCliente = rowData.id;
                  modalCadastro.open();
                }
              : undefined
          }
          excluir={
            temPermissaoEscrita
              ? () => {
                  if (store.vendas.arr!.some((venda) => venda.cliente.id === rowData.id)) {
                    store.notificar('Não pode ser excluído, existe vendas!');
                  } else {
                    state.selectedCliente = rowData.id;
                    modalExclusao.open();
                  }
                }
              : undefined
          }
        />
      ),
      ...(true && ({ width: 25 } as object)),
    },
    {
      field: 'tipo',
      title: 'Tipo de cliente',
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'email',
      title: 'Email',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'cpf_cnpj',
      title: 'CPF/CNPJ',
      render: (rowData: any) => {
        return rowData.cpf_cnpj ? Formatter.formatCPFCNPJ(rowData.cpf_cnpj) : null;
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'fone',
      title: 'Telefone',
      ...(true && ({ width: 150 } as object)),
    },
  ];

  const rows = (clientes: Cliente[]): object[] => {
    if (clientes.length) {
      if (clientes.length >= 50) {
        state.setPageSize(50);
      } else {
        state.setPageSize(clientes.length);
      }
    } else {
      state.setPageSize(0);
    }

    return clientes.map((cliente) => {
      return {
        id: cliente.id,
        nome: cliente.nome,
        tipo: cliente.tipo.nome,
        email: cliente.email,
        cpf_cnpj: cliente.cpf_cnpj,
        fone: cliente.fone,
      };
    });
  };

  async function sync(): Promise<void> {
    await syncModel.clientes();

    state.sync = true;
  }

  async function filtrar(): Promise<void> {
    sync();
    state.filtroRapidoNome = '';
  }
  function limparFiltro(): void {
    state.filtroRapidoNome = '';
    setClientesPesquisados(undefined);
  }

  function fastSearch(e: React.ChangeEvent<HTMLInputElement>): void {
    const value = e.target.value;
    state.filtroRapidoNome = value;
    const clientes = store.clientes?.filter((cliente) => {
      if (cliente.nome) {
        const nomeCliente = cliente.nome.toLowerCase();
        return nomeCliente.includes(value.toLowerCase());
      } else return false;
    });
    setClientesPesquisados(clientes);
  }

  async function deletar(): Promise<void> {
    if (store.vendas.arr!.some((venda) => venda.cliente.id === state.selectedCliente)) {
      modalExclusao.close();
      store.notificar('Contém registros com o respectivo cliente, logo não pode ser excluído');
    } else {
      modalExclusao.close();
      store.toggleLoader();
      await api
        .delete(`/clientes/${state.selectedCliente}/?propriedade=${store.propriedade!.id}`)
        .then(() => store.notificar('Cliente excluído com sucesso!'));
      await store.clientes.populate();
      store.toggleLoader();
    }
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Clientes'), []);

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  const ExcluirCliente: React.FC = () => {
    const cliente = store.clientes.get(state.selectedCliente);

    return (
      <div style={{ width: '100%' }}>
        <b>Nome: </b> {cliente && cliente.nome}
        <br />
        <b>Tipo: </b> {cliente && cliente.tipo.nome}
        <br />
        <b>CPF/CNPJ: </b>
        {(cliente && Formatter.formatCPFCNPJ(cliente.cpf_cnpj)) || 'Sem CPF ou CNPJ'}
        <br />
        <b>Cidade: </b>
        {cliente && cliente.cidade.nome}
        <br />
        <b>Estado: </b>
        {cliente && cliente.estado.nome}
      </div>
    );
  };

  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn
            disabled={!temPermissaoEscrita}
            onClick={() => {
              state.selectedCliente = 0;
              modalCadastro.open();
            }}
            text="ADICIONAR"
            Icon={AddIcon}
          />
          <TextField value={state.filtroRapidoNome} onChange={fastSearch} placeholder="Nome/Razão Social" />
        </Flex>
        <Flex>
          <Filtro sync={filtrar} clear={limparFiltro}></Filtro>
        </Flex>
      </Header>

      <Body grid={true}>
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          {rows && (
            <MaterialTable
              columns={columns}
              data={rows(clientesPesquisados || store.clientes.arr!)}
              options={{ ...config.options, pageSize: state.pageSize }}
              localization={config.localization}
              style={config.style}
            />
          )}
        </div>
      </Body>

      {modalCadastro.state ? (
        <FormCliente open={modalCadastro.state} handleClose={modalCadastro.close} clienteID={state.selectedCliente} />
      ) : null}
      <Confirmar
        open={modalExclusao.state}
        content={<ExcluirCliente />}
        title="Tem certeza que deseja excluir?"
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={deletar}
      />
    </>
  ) : null;
};

export default observer(Clientes);
