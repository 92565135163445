import React from 'react';
import { observer } from 'mobx-react-lite';
import Header from '../../../components/Header';
import { Body } from '../../../components/Body';
import PainelNavigationTab from './PainelNavigationTab';
import MaterialTable, { Column } from 'material-table';
import { newConfig } from '../../../components/Tabela';
import { NavbarLink } from './styles';

type RelList = {
  relName: string;
  relType: string;
  link: string;
};

const FormRelatorioFinanceiro: React.FC = () => {
  const tableData: RelList[] = [
    {
      relName: 'Compra de Animais',
      relType: 'Compra',
      link: '/financeiro/painel/relatorio-financeiro/compra-animais',
    },
    {
      relName: 'Quanto Compramos de Ração',
      relType: 'Compra',
      link: '/financeiro/painel/relatorio-financeiro/compra-racao',
    },
    {
      relName: 'Extrato de Vendas de Produtos',
      relType: 'Vendas',
      link: '/financeiro/painel/relatorio-financeiro/venda-produtos',
    },
    {
      relName: 'Extrato de Vendas por Clientes',
      relType: 'Vendas',
      link: '/financeiro/painel/relatorio-financeiro/compra-clientes',
    },
    {
      relName: 'Resumo de Despesas por Fornecedor',
      relType: 'Financeiro',
      link: '/financeiro/painel/relatorio-financeiro/despesa-fornecedor',
    },
    {
      relName: 'Conta Corrente',
      relType: 'Financeiro',
      link: '/relatorios/conta-corrente',
    },
  ];

  function linkFormatter(rowData: RelList, text: string) {
    return (
      <NavbarLink
        to={rowData.link}
        style={{ color: 'black', textDecoration: 'none', fontFamily: 'Roboto', fontSize: 'inherit', fontWeight: 400 }}
      >
        {text}
      </NavbarLink>
    );
  }

  const columns: Column<RelList>[] = [
    {
      field: 'relName',
      type: 'string',
      headerStyle: {
        backgroundColor: '#F5F6F8',
      },
      cellStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      render: (rowData) => linkFormatter(rowData, rowData.relName),
      ...(true && ({ width: '40%' } as object)),
    },
    {
      field: 'relType',
      type: 'string',
      headerStyle: {
        backgroundColor: '#F5F6F8',
      },
      cellStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
        paddingLeft: '5vw',
      },
      render: (rowData) => linkFormatter(rowData, rowData.relType),
      ...(true && ({ width: '80%' } as object)),
    },
  ];

  return (
    <>
      <Header style={{ fontWeight: 'bold', fontSize: '24px' }}>Painel Financeiro e Vendas</Header>
      <PainelNavigationTab />
      <Body style={{ display: 'flex' }}>
        <MaterialTable
          columns={columns}
          data={tableData}
          options={{
            ...newConfig.options,
            paging: false,
            sorting: false,
            toolbar: false,
            tableLayout: 'fixed',
            headerStyle: {
              height: '1px',
              paddingTop: 0,
              paddingBottom: 0,
              fontSize: 0,
            },
          }}
          style={{
            ...newConfig.style,
            backgroundColor: '#F5F6F8',
          }}
          localization={newConfig.localization}
        />
      </Body>
    </>
  );
};

export default observer(FormRelatorioFinanceiro);
