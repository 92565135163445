import React from 'react';
import { PDFContainer } from './styles';
import { Flex } from '../../../components/NavBar/styles';
import MaterialTable, { Column } from 'material-table';
import { newConfig } from '../../../components/Tabela';
import Formatter from '../../../services/Formatter';

type Props = {
  propriedade: string;
  rows: object[][];
};

const RelatorioResumoVendas: React.FC<Props> = ({ propriedade, rows }) => {
  const columns: Column<object>[] = [
    {
      field: 'tanque.nome',
      title: 'Tanque',
      type: 'string',
      // width:120,
    },
    {
      field: 'lote.codigo',
      title: 'Lote',
      type: 'string',
      // width:120,
    },
    {
      field: 'especie',
      title: 'Espécie',
      type: 'string',
      // width:100,
    },
    {
      field: 'estoque',
      title: 'Estoque Atual',
      type: 'numeric',
      // width:140,
      render: ({ estoque }: any) => {
        return Formatter.formatNumber(0, estoque);
      },
    },
    {
      field: 'data',
      title: 'Última Venda',
      type: 'date',
      // width:140,
    },
    {
      field: 'biomassa',
      title: 'Biomassa (kg)',
      // width:100,
      render: ({ biomassa }: any) => {
        return Formatter.formatNumber(2, biomassa);
      },
      type: 'numeric',
    },
    {
      field: 'quantidade',
      title: 'Quantidade',
      type: 'numeric',
      // width:120,
      render: ({ quantidade }: any) => {
        return Formatter.formatNumber(0, quantidade);
      },
    },
    {
      field: 'valor_unit',
      title: 'Valor Unit. (R$/kg)',
      type: 'numeric',
      // width:140,
      render: ({ valor_unit }: any) => {
        return Formatter.formatNumber(2, valor_unit);
      },
    },
    {
      field: 'valor',
      title: 'Valor (R$)',
      type: 'numeric',
      // width:120,
      render: ({ valor }: any) => {
        return Formatter.formatNumber(2, valor);
      },
    },
  ];

  const Tables: React.FC = () => {
    return (
      <>
        {rows.map((tableRows, index) => {
          return (
            <div key={'table-' + index}>
              <MaterialTable
                columns={columns}
                data={tableRows}
                options={{
                  ...newConfig.options,
                  paging: false,
                  maxBodyHeight: 'none',
                  minBodyHeight: 'none',
                  headerStyle: {
                    color: '#000000',
                    fontSize: 12,
                  },
                }}
                localization={newConfig.localization}
                style={{ ...newConfig.style, fontSize: 12 }}
              />
              <br />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <PDFContainer>
      <Flex>
        <img src="/images/aqrelatorios.png" alt="Aquabit Relatórios" />
        <h2>Aquabit - Resumo de Vendas</h2>
      </Flex>

      <br />

      <div
        style={{
          width: '100%',
          display: 'grid',
          justifyContent: 'space-between',
          gridTemplateColumns: '1fr 1fr',
          gap: 10,
        }}
      >
        <p>
          <b>Propriedade:</b> {propriedade}
        </p>
      </div>

      <br />

      <Tables />
    </PDFContainer>
  );
};

export default RelatorioResumoVendas;
