import { observer } from 'mobx-react-lite';
import React from 'react';
import Header from '../../../components/Header';
import PainelNavigationTab from './PainelNavigationTab';
import FormPainelDashboard from './FormPainelDashboard';
import AddBtn from '../../../components/Botoes/AddBtn';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import { permissoes } from '../../../services/utils/permissoes';
import useStore from '../../../services/hooks/useStore';

const PainelFinanceiro: React.FC = () => {
  const history = useHistory();
  const store = useStore();

  store.propriedade && store.validarPermissao(permissoes.FINANCEIRO_PAINEL_FINANCEIRO_LEITURA, history);

  return (
    <>
      <Header style={{ fontWeight: 'bold', fontSize: '24px' }}>
        <h2> Painel Financeiro e Vendas</h2>

        <div>
          <AddBtn
            onClick={() => {
              history.push('/financeiro/controle-cheque');
            }}
            text="CHEQUES"
            Icon={AddIcon}
          />
          <AddBtn
            onClick={() => {
              history.push('/financeiro/movimentacao/');
            }}
            text="MOVIMENTAÇÕES"
            Icon={AddIcon}
          />
        </div>
      </Header>
      <PainelNavigationTab />

      <FormPainelDashboard />
    </>
  );
};

export default observer(PainelFinanceiro);
