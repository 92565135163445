/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/Header';
import AddBtn from '../../components/Botoes/AddBtn';
import AddIcon from '@material-ui/icons/Add';
import { MenuList, MenuItem, TextField, Checkbox } from '@material-ui/core';
import Filtro from '../../components/Filtro';
import { Flex } from '../../components/NavBar/styles';
import useStore from '../../services/hooks/useStore';
import { Body } from '../../components/Body';
import Display from '../../components/Tabela/Display';
import { DisplayInfo } from '../../components/Tabela/styles';
import CadastrarTanque from './CadastrarTanque';
import { useHistory, useLocation } from 'react-router-dom';
import { returnTanque } from '../../services/utils/propriedade';
import QuantidadeModal from './QuantidadeModal';
import MaterialTable, { Column } from 'material-table';
import { config } from '../../components/Tabela';
import { useLocalStore, observer } from 'mobx-react-lite';
import useModal from '../../services/hooks/useModal';
import ActionButton from '../../components/Tabela/ActionButton';
import Confirmar from '../../components/Modais/Confirmar';
import EditarTanque from './EditarTanque';
import Select from '../../components/Select';
import LargeNumber from '../../components/LargeNumber';
import syncModel from '../../services/models/Sync';
import Formatter from '../../services/Formatter';
import { api, apiV2 } from '../../services/utils';
import X9Logger from '../../services/X9Logger';
import { FormControlLabel } from '@mui/material';
import { permissoes } from '../../services/utils/permissoes';

const Propriedade: React.FC = () => {
  const tableRef = useRef(null);
  const store = useStore();
  const history = useHistory();
  const location: any = useLocation();
  store.propriedade && store.validarPermissao(permissoes.PROPRIEDADE_TANQUES_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.PROPRIEDADE_TANQUES_ESCRITA);

  const [mostrarTodosTanques, setMostrarTodosTanques] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState('');

  const state = useLocalStore(() => ({
    pageSize: 0,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
    page: location.state ? location.state.page : 0,
    setPage(num: number) {
      this.page = num;
    },
    nomeTanque: '',
    setNomeTanque(value: string) {
      this.nomeTanque = value;
    },

    varios: false,
    quantidade: 1,

    filtroTipo: null as SelectRow | null,
    filtroRapidoNome: '',

    selectedTanque: null as Tanque | null,

    totais: {
      count: 0,
      lamina: {
        m2: 0,
        m3: 0,
      },
      capacidade: 0,
      producao: 0,
    },
    async setTotais() {
      store.toggleLoader();
      const response = await apiV2.get('/tanque/totais/', {
        params: {
          prop: store.propriedade!.id,
        },
      });

      this.totais = response.data;
      store.toggleLoader();
    },

    sync: false,
  }));
  const modalCadastro = useModal();
  const modalQuantidade = useModal();
  const modalEditar = useModal();
  const modalExclusao = useModal();

  const columns: Column<Tanque>[] = [
    {
      field: 'nome',
      title: 'Tanque',
      render(rowData) {
        return returnTanque(rowData);
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'acoes',
      disableClick: true,
      sorting: false,
      render: (rowData: any) => (
        <ActionButton
          editar={
            temPermissaoEscrita
              ? () => {
                  state.selectedTanque = rowData;
                  modalEditar.open();
                }
              : undefined
          }
          historico={() =>
            history.push({ pathname: '/propriedade/tanque/' + rowData?.id, state: { page: state.page } })
          }
          excluir={
            temPermissaoEscrita
              ? () => {
                  state.selectedTanque = rowData;
                  modalExclusao.open();
                }
              : undefined
          }
        />
      ),
      ...(true && ({ width: 25 } as object)),
    },
    {
      field: 'tamanho',
      title: 'Tamanho (m²/m³)',
      render(rowData: any) {
        return Formatter.formatNumber(2, rowData.tamanho);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'capacidade',
      title: 'Capacidade (Kg)',
      render(rowData: any) {
        return Formatter.formatNumber(3, rowData.capacidade);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'estoque',
      title: 'Estoque',
      render({ estoque }) {
        return Formatter.formatNumber(0, estoque);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'peso_medio',
      title: 'Peso Médio (g)',
      sorting: false,
      render({ peso_medio }: any) {
        return Formatter.formatNumber(3, peso_medio);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'biomassa',
      title: 'Produção Atual (Kg)',
      ...(true && ({ width: 200 } as object)),
      render(rowData: any) {
        return Formatter.formatNumber(3, rowData.biomassa);
      },
    },
    {
      field: 'core_tanque_tipos',
      title: 'Tipo de Tanque',
      render(rowData: any) {
        return rowData.tanque_tipo.nome;
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'lotes',
      title: 'Lote',
      sorting: false,
      render(rowData) {
        return rowData.lotes ? rowData.lotes : 'Sem Lote';
      },
      ...(true && ({ width: 200 } as object)),
    },
    {
      field: 'especie_explorada',
      title: 'Espécie',
      sorting: false,
      render(rowData: any) {
        return rowData.especie_explorada ? rowData.especie_explorada : 'Sem Espécie';
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'fase_lote',
      title: 'Fase',
      sorting: false,
      render(rowData: any) {
        return rowData.fase_lote ? rowData.fase_lote : 'Livre';
      },
      ...(true && ({ width: 180 } as object)),
    },
  ];

  async function sync(): Promise<void> {
    await syncModel.tanques();
    state.sync = true;

    await state.setTotais();
  }

  async function excluir(tanqueID: number): Promise<void> {
    const historico = await store.tanques.getHistorico(tanqueID);

    if (historico) {
      if (historico?.length > 0) {
        store.notificar('O tanque possui histórico e não pode ser deletado!');
      } else {
        store.toggleLoader();
        await api.delete(`/tanques/${tanqueID}/?propriedade=${store.propriedade!.id}`);
        store.notificar('Tanque excluído com sucesso!');
        store.toggleLoader();
        (tableRef.current as any)?.onQueryChange();
      }
    }
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Tanques/Viveiros'), []);

  useEffect(() => {
    if (store.propriedade) {
      sync();
      (tableRef.current as any)?.onQueryChange();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (state.nomeTanque === '' || state.filtroTipo) {
        state.filtroRapidoNome = debouncedValue;
        (tableRef.current as any)?.onQueryChange();
      }
    }, 100); // Ajuste o valor do timeout conforme necessário

    return () => {
      clearTimeout(timeoutId);
    };
  }, [debouncedValue]);

  function limparFiltro(): void {
    state.nomeTanque = '';
    state.filtroRapidoNome = '';
    state.filtroTipo = null;
    (tableRef.current as any)?.onQueryChange();
  }
  function refreshTable() {
    (tableRef.current as any)?.onQueryChange();
  }
  async function filter(): Promise<void> {
    // Checagem de filtros avançados
    if (state.nomeTanque !== '' || state.filtroTipo) {
      (tableRef.current as any)?.onQueryChange();
      state.filtroRapidoNome = '';
    } else {
      await syncModel.tanques();
      (tableRef.current as any)?.onQueryChange();
    }
  }
  // function fastSearch(e: React.ChangeEvent<HTMLInputElement>): void {
  //   if (state.nomeTanque === '' || state.filtroTipo) {
  //     const value = e.target.value;
  //     state.filtroRapidoNome = value;
  //     (tableRef.current as any)?.onQueryChange();
  //   }
  // }
  const ExcluirTanque: React.FC = () => {
    return (
      <div style={{ width: '100%' }}>
        <b>Nome: </b> {state.selectedTanque?.nome || state.selectedTanque?.codigo}
        <br />
        <b>Tipo: </b> {state.selectedTanque?.tanque_tipo.nome}
        <br />
        <b>Tamanho (m²/m³): </b> {Formatter.formatNumber(2, state.selectedTanque?.tamanho)}
        <br />
        <b>Capacidade: </b> {Formatter.formatNumber(3, state.selectedTanque?.capacidade)}
        <br />
        <b>Situação: </b> {state.selectedTanque?.situacao_tanque.nome}
        <br />
      </div>
    );
  };
  return state.sync ? (
    <>
      <Header>
        {/* _______________________________________________________________________ */}
        <Flex>
          <AddBtn
            disabled={!temPermissaoEscrita}
            dropdown
            onClick={() => {
              state.varios = false;
              modalCadastro.open();
            }}
            text="ADICIONAR"
            Icon={AddIcon}
          >
            <MenuList>
              <MenuItem
                onClick={() => {
                  state.varios = false;
                  modalCadastro.open();
                }}
              >
                Um tanque
              </MenuItem>
              <MenuItem
                onClick={() => {
                  state.varios = true;
                  modalQuantidade.open();
                }}
              >
                Vários tanques
              </MenuItem>
            </MenuList>
          </AddBtn>
          <TextField
            value={state.filtroRapidoNome}
            onChange={(e) => setDebouncedValue(e.target.value)}
            placeholder="Nome do tanque"
          />

          {/* Botao Mostrar Todos */}
          <FormControlLabel
            style={{ marginLeft: 15 }}
            control={
              <Checkbox
                checked={mostrarTodosTanques}
                onChange={(e: any) => {
                  setMostrarTodosTanques(e.target.checked);
                  refreshTable();
                }}
                color="primary"
              />
            }
            label="Mostrar Todos"
          />
        </Flex>
        {/* _______________________________________________________________________ */}
        <Flex>
          <Filtro sync={filter} clear={limparFiltro}>
            <div style={{ width: '90%' }}>
              {store.tiposDeTanque.arr && (
                <Select
                  value={state.filtroTipo}
                  placeholder="Tipo de tanque/viveiro"
                  onChange={(e: any) => (state.filtroTipo = e)}
                  options={store.tiposDeTanque.getSelectRows()}
                />
              )}
            </div>
            <div style={{ width: '90%', marginTop: 10 }}>
              <TextField
                fullWidth
                value={state.nomeTanque}
                onChange={(e) => state.setNomeTanque(e.target.value)}
                label="Nome do tanque"
              />
            </div>
          </Filtro>
        </Flex>
      </Header>

      <Body gridColumns="270px 1fr">
        <Display style={{ height: 510 }} title="Sua Produção">
          <Flex style={{ flexDirection: 'column', justifyContent: 'center', height: 430 }}>
            <DisplayInfo>
              <LargeNumber unidade="" precision={0} numero={state.totais.count} />
              <p>Quantidade de Tanques</p>
            </DisplayInfo>
            <DisplayInfo>
              <LargeNumber unidade="m²" precision={1} numero={state.totais.lamina.m2} />
              <LargeNumber unidade="m³" precision={1} numero={state.totais.lamina.m3} />
              <p>Total Lâmina D&apos;agua</p>
            </DisplayInfo>
            <DisplayInfo>
              <LargeNumber unidade="" precision={1} numero={state.totais.capacidade} />
              <p>Capacidade total (Ton)</p>
            </DisplayInfo>
            <DisplayInfo>
              <LargeNumber unidade="" precision={1} numero={state.totais.producao} />
              <p>Produção atual (Ton)</p>
            </DisplayInfo>
          </Flex>
        </Display>
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          {store.propriedade && (
            <MaterialTable
              tableRef={tableRef}
              columns={columns}
              data={(query) =>
                new Promise((resolve, reject) => {
                  const filtros: { [key: string]: any } = {
                    orderBy: query.orderBy?.field || 'nome',
                    orderDirection: query.orderDirection || 'asc',
                    nome: state.nomeTanque,
                    tanque_tipo: state.filtroTipo ? parseInt(state.filtroTipo.value) : null,
                  };

                  if (!state.nomeTanque && state.filtroRapidoNome) {
                    filtros.nome = state.filtroRapidoNome;
                    query.page = 0;
                  }

                  apiV2
                    .get('/tanque/list-historico/', {
                      params: {
                        page: query.page + 1,
                        page_size: 50,
                        propriedade: store.propriedade?.id,
                        todos: mostrarTodosTanques,
                        ...filtros,
                        bancadas: false,
                      },
                    })
                    .then((res) => {
                      if (res.data.count) {
                        if (res.data.count >= 50) {
                          state.setPageSize(50);
                        } else {
                          state.setPageSize(res.data.count);
                        }
                      } else {
                        state.setPageSize(0);
                      }
                      // Mostra os tanques filtrados estoque > 0
                      // let data = res.data.results?.filter((row:any) => row.estoque > 0)
                      // if(mostrarTodosTanques){
                      //   data = res.data.results
                      // }
                      resolve({
                        data: res.data.results,
                        page: query.page,
                        totalCount: res.data.count,
                      });
                    })
                    .catch((err) => {
                      X9Logger.report(err);
                      store.notificar('Ocorreu um erro ao gerar relatório. Tente novamente mais tarde!');
                      reject();
                    });
                })
              }
              onRowClick={(_e, row: any) =>
                history.push({ pathname: '/propriedade/tanque/' + row?.id, state: { page: state.page } })
              }
              options={{ ...config.options, pageSize: state.pageSize }}
              localization={config.localization}
              style={config.style}
            />
          )}
        </div>
        {modalCadastro.state ? (
          <CadastrarTanque
            quantidade={state.quantidade}
            varios={state.varios}
            open={modalCadastro.state}
            handleClose={() => {
              (tableRef.current as any)?.onQueryChange();
              modalCadastro.close();
            }}
          />
        ) : null}
        <QuantidadeModal
          open={modalQuantidade.state}
          handleClose={() => {
            (tableRef.current as any)?.onQueryChange();
            modalQuantidade.close();
          }}
          confirmar={(quantidade): void => {
            modalQuantidade.close();
            state.quantidade = quantidade;
            modalCadastro.open();
          }}
        />
        <Confirmar
          title="Tem certeza que deseja excluir?"
          open={modalExclusao.state}
          content={<ExcluirTanque />}
          handleClose={() => {
            modalExclusao.close();
          }}
          onCancel={modalExclusao.close}
          onConfirm={() => {
            modalExclusao.close();
            excluir(state.selectedTanque!.id);
          }}
        />
        {modalEditar.state ? (
          <EditarTanque
            open={modalEditar.state}
            tanque={state.selectedTanque!}
            handleClose={() => {
              modalEditar.close();
            }}
            sync={(tableRef.current as any)?.onQueryChange()}
          />
        ) : null}
      </Body>
    </>
  ) : null;
};

export default observer(Propriedade);
