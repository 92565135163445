import { useContext } from 'react';
import { createContext } from 'react';
import State from '../State';

/**
 * Instância do estado geral
 */
const state = new State();

/**
 * Contexto de estado
 */
const context = createContext(state);
export const stateContext = { context: context, state };

/**
 * Hook de estado geral
 */
const useStore = (): State => useContext(stateContext.context);
export default useStore;
