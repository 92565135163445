import { observable, computed } from 'mobx';
import { stateContext } from '../hooks/useStore';
import { apiV2 } from '../utils';

type ReservatorioData = {
  id: number;
  propriedade: number;
  situacao: number;
  nome: string;
  tamanho: number;
  volume: number;
  origem: number;
};
class Reservatorios {
  @observable arr: Reservatorio[] | null = null;
  @observable totalRes: number = 0;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    const store = stateContext.state;
    await apiV2
      .get('/reservatorio/', {
        params: {
          propriedade: store.propriedade?.id,
        },
      })
      .then((res) => {
        this.arr = res.data;
      })
      .catch(() => {});
  }

  getSelectRows(): SelectRow[] {
    return this.arr!.map((item: any) => {
      return { value: item.id.toString(), label: item.nome };
    });
  }
  get(id: number): Reservatorio {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  async getPaginated(page: number, pageSize: number, propriedadeId: number): Promise<void> {
    const baseUrl = `/reservatorio/?propriedade=${propriedadeId}`;

    await apiV2
      .get(baseUrl, {
        params: {
          page_size: pageSize,
          page: page + 1,
        },
      })
      .then((res: any) => {
        this.totalRes = res.data.count;
        this.arr = res.data.results;
      })
      .catch((error) => {
        console.error('Erro ao carregar os reservatórios:', error);
      });
  }

  async editar(reservatorioID: number, dados: ReservatorioData): Promise<void> {
    const store = stateContext.state;

    await apiV2
      .put(`/reservatorio/${reservatorioID}/?propriedade=${store.propriedade!.id}`, dados)
      .then(async () => {
        await store.reservatorios.populate();
        store.notificar('Reservatorio editado com sucesso!');
      })
      .catch(() => store.notificar('Ocorreu um erro ao editar o reservatorio!'));
  }

  async criar(dados: ReservatorioData): Promise<void> {
    const store = stateContext.state;

    await apiV2
      .post(`/reservatorio/?propriedade=${store.propriedade!.id}`, dados)
      .then(async () => {
        store.notificar('Reservatorio cadastrado com sucesso!');
        await store.reservatorios.populate();
      })
      .catch(() => store.notificar('Ocorreu um erro ao cadastrar o reservatorio!'));
  }

  async excluir(reservatorioID: number): Promise<void> {
    const store = stateContext.state;

    try {
      await apiV2.delete(`/reservatorio/${reservatorioID}/?propriedade=${store.propriedade!.id}`).then(() => {
        store.notificar('Reservatório excluído com sucesso!');
      });
      await store.reservatorios.populate();
    } catch (error) {
      console.error('Erro ao excluir o reservatório:', error);
      store.notificar('Ocorreu um erro ao excluir o reservatório. Tente novamente mais tarde.');
    }
  }
}

export default Reservatorios;
