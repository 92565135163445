import React, { useMemo, useState } from 'react';
import useStore from '../../services/hooks/useStore';
import Botao from '../../components/Botoes/Botao';
import { Modal, ModalHeader, ModalBody } from '../../components/Modais/styles';
import { useLocalStore, observer } from 'mobx-react-lite';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { api } from '../../services/utils';
import syncModel from '../../services/models/Sync';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import useModal from '../../services/hooks/useModal';
import { Delete, Edit } from '@material-ui/icons';
import SelecionarReservatorios from './SelecionarReservatorios';

type Props = ModalProps & {
  parametroValor: ParametroValor[] | null;
};
type State = {
  dataAnalise: MaterialUiPickersDate;
  reservatoriosSelecionados: any[];
  sync: boolean;
};

export interface RequestData {
  reservatorio: number;
  data: string;
  parametros_utilizados: {
    parametro: any;
    value: any;
  }[];
}

const CadastrarAguaReservatorioMuitosReservatorios: React.FC<Props> = ({ parametroValor, open, handleClose }) => {
  const store = useStore();

  const modalReservatorios = useModal();
  const state = useLocalStore(
    (): State => ({
      dataAnalise: new Date(),
      reservatoriosSelecionados: [],
      sync: false,
    }),
  );
  const [requestData, setRequestData] = useState<RequestData[]>([]);
  const [editData, setEditData] = useState<RequestData | null>(null);

  function handleClosemodalReservatorios() {
    setEditData(null);
    modalReservatorios.close();
  }

  function handleEditarData(reservatorioId: number) {
    const data = requestData.find((d) => d.reservatorio == reservatorioId);
    if (data) {
      setEditData(data);
      modalReservatorios.open();
    }
  }

  function adicionarDados(dados: RequestData) {
    setRequestData((prev) => {
      const prevFiltered = prev.filter((d) => d.reservatorio != dados.reservatorio);
      return [...prevFiltered, dados];
    });
  }

  function removerDados(reservatorio: Reservatorio) {
    setRequestData((prev) => prev.filter((d) => d.reservatorio !== reservatorio.id));
  }

  const reservatoriosMemo = useMemo(
    () => store.reservatorios.arr?.filter((t) => requestData.find((d) => d.reservatorio === t.id)) ?? [],
    [requestData, store.reservatorios.arr],
  );
  const reservatoriosIdsSelecionados = reservatoriosMemo.map((t) => t.id);

  async function salvarAnalise() {
    handleClose();
    store.toggleLoader();

    const parametro_input: any = document.querySelectorAll('.parametro_input[type=text]');
    const parametros_utilizados: any[] = [];

    for (let index = 0; index < parametro_input.length; index++) {
      if (parametro_input[index].value.replace('.', '').replace(',', '.') > 0) {
        parametros_utilizados.push({
          parametro: parametro_input[index].id,
          value: parametro_input[index].value.replace('.', '').replace(',', '.'),
        });
      }
    }

    await Promise.all(
      requestData.map((dados) => {
        const data = {
          reservatorio: dados.reservatorio,
          data: dados.data,
          parametros_utilizados: dados.parametros_utilizados,
        };

        return api
          .post(`/analise-agua-reservatorio/`, data)
          .catch((e) => console.log('Erro ao cadastrar análise de água: ', e, 'data: ', data));
      }),
    )
      .then(() => {
        syncModel.aguaDeReservatorios();
      })
      .then(() => {
        store.notificar('Análises da água cadastradas com sucesso!');
      })
      .catch((err) => {
        store.notificar('Erro no cadastro da Análises da água, tente novamente!');
        console.log(err);
      });

    store.toggleLoader();
  }

  function getTooltipText(data?: RequestData) {
    if (!data) return '';
    return (
      <div>
        {store.parametroValor?.map((item) => {
          if (item.used) {
            const parametro_utilizado = data.parametros_utilizados.find(
              (parametro) => parametro.parametro == item.parametro.id,
            );
            const valor = parametro_utilizado ? Number(parametro_utilizado.value) : 0;
            return (
              <div key={item.id}>
                <p>
                  {item.parametro.nome}: {valor}
                </p>
              </div>
            );
          }
        })}
      </div>
    );
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Nova Análise</ModalHeader>
      <ModalBody>
        <Box minHeight="500px">
          <KeyboardDateTimePicker
            autoOk
            disableToolbar
            ampm={false}
            variant="inline"
            margin="normal"
            label="Data da análise"
            value={state.dataAnalise}
            onChange={(date) => {
              state.dataAnalise = date;
            }}
            onError={(error) => {
              console.log(error);
            }}
            format="dd/MM/yyyy HH:mm"
          />
          <Box width="20rem" display="flex" justifyContent="center">
            <Typography>Reservatórios selecionados</Typography>
          </Box>
          <Botao onClick={modalReservatorios.open}>Selecione o(s) reservatório(s)</Botao>
          <Box display="flex" flexDirection="column" mt={2}>
            {!!reservatoriosMemo.length &&
              reservatoriosMemo.map((reservatorio) => {
                const data = requestData.find((d) => d.reservatorio == reservatorio.id);
                const tooltipText = getTooltipText(data);
                return (
                  <Box
                    key={reservatorio.uuid}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection="row"
                    mb={2}
                    borderRadius={5}
                    boxShadow={3}
                    p={1}
                  >
                    <Tooltip title={tooltipText} arrow>
                      <Typography>{reservatorio.nome}</Typography>
                    </Tooltip>
                    <Box>
                      <IconButton size="small" onClick={() => handleEditarData(reservatorio.id)}>
                        <Edit />
                      </IconButton>
                      <IconButton size="small" onClick={() => removerDados(reservatorio)}>
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}
          </Box>
          <Box display="flex" width="20rem" position="absolute" bottom={20}>
            <Botao onClick={handleClose} variant="contained" cor="gray">
              Cancelar
            </Botao>
            <Botao onClick={salvarAnalise} disabled={requestData.length === 0} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          </Box>
        </Box>
      </ModalBody>

      {modalReservatorios.state && (
        <SelecionarReservatorios
          editData={editData}
          parametroValor={parametroValor}
          date={state.dataAnalise as Date}
          excluirReservatorioIds={reservatoriosIdsSelecionados}
          open={modalReservatorios.state}
          handleClose={handleClosemodalReservatorios}
          onConfirm={adicionarDados}
        />
      )}
    </Modal>
  );
};

export default observer(CadastrarAguaReservatorioMuitosReservatorios);
