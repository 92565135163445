import styled, { css } from 'styled-components';
import { TableContainer, TableHead, IconButton } from '@material-ui/core';

type Props = {
  width?: string;
  heightOffset?: string;
  overflowY?: boolean;
};

export const TabelaContainer = styled(TableContainer)`
  height: calc(100vh - 180px);
`;
export const TabelaHead = styled(TableHead)`
  .MuiTableCell-root {
    font-size: 14px;
    color: white;
    white-space: nowrap;
  }

  .MuiTableCell-stickyHeader {
    background-color: ${(props) => props.theme.colors.primary};
  }
`;
export const Box = styled.div<Props>`
  width: ${(props) => (props.width ? props.width : '100%')};
  background-color: white;
  border-radius: 10px;
  overflow-x: auto;

  box-shadow: 0 0 13px 0 #00000036;
  margin: 0 10px;
`;

export const DisplayBox = styled.div<Props>`
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  background-color: white;
  border-radius: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  box-shadow: 0 0 13px 0 #00000036;
  margin: 0 10px;
  height: calc(100vh - 125px);

  ${(props) =>
    props.heightOffset &&
    css`
      height: calc(100vh - ${props.heightOffset});
    `}
`;

export const DisplayHead = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  font-size: 14px;
  padding: 10px;
  height: 45px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    color: white;
  }

  h2 {
    margin-left: 3px;
  }
`;

export const DisplayContent = styled.div<Props>`
  padding: 10px;
  overflow-y: auto;
  height: calc(100% - 45px);
  ${(props) =>
    props.heightOffset &&
    css`
      height: calc(100% - ${props.heightOffset} - 57px);
    `}

  ${(props) =>
    props.overflowY !== undefined &&
    css`
      overflow-y: ${props.overflowY ? 'auto' : 'hidden'};
    `}
`;

export const DisplayInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${(props) => props.theme.colors.primary};

  border-radius: 15px;

  margin: 5px 0;
  padding: 10px;

  width: 100%;

  h1 {
    font-weight: 500;
    font-family: 'liberation-sans', 'Roboto', sans-serif;
    margin: -3px 0;
  }

  p {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0.00735em;
    font-family: 'liberation-sans', 'Roboto', sans-serif;
  }
`;

export const FlatButton = styled(IconButton)`
  height: 24px;
  width: 24px;
  padding: 0 !important;
  margin-left: 0 !important;

  background-color: #474747 !important;
  color: white !important;
`;

export const HeaderButton = styled.button`
  padding: 8px 16px;
  margin-left: auto;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  border: 1px solid #fff;
  cursor: pointer;
  outline: none;
`;
