import styled from 'styled-components';
import InputNumero from '../../components/InputNumero';

export const ParametroNome = styled.p`
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.labeled {
    margin-bottom: -26px;
    width: 1000px;
  }
`;

export const FlexList = styled.div`
  display: flex;
  width: 100%;
  margin: 8px 0;
  align-items: center;
`;

export const HeaderList = styled.div`
  color: #6d6d6d;
  font-size: 14px;
  display: flex;
  margin-left: auto;
  width: 40%;
  justify-content: space-around;
`;

export const MinValor = styled.div`
  width: 20%;
  text-align: center;
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  border-radius: 10px 0 0 10px;
  border: 1px solid #ccc;
`;
export const MaxValor = styled.div`
  width: 20%;
  text-align: center;
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  border-radius: 0 10px 10px 0;
  border: 1px solid #ccc;
  border-left: none;
`;

export const MinValorInput = styled(InputNumero)`
  text-align: center;
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  border-radius: 10px 0 0 10px;
  border: 1px solid #ccc;
  width: 100%;
  &:hover {
    border-color: #42a5f4;
  }
  &:focus {
    border-color: #42a5f4;
  }
`;
export const MaxValorInput = styled(InputNumero)`
  text-align: center;
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  border-radius: 0 10px 10px 0;
  border: 1px solid #ccc;
  border-left: none;
  width: 100%;
  &:hover {
    border-color: #42a5f4;
  }
  &:focus {
    border-color: #42a5f4;
  }
`;
