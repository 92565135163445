import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import Botao from '../../../components/Botoes/Botao';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { getRelatorio } from '../../../services/utils/relatorios';
import useStore from '../../../services/hooks/useStore';

const GerarRelatorio: React.FC<ModalProps> = ({ open, handleClose }) => {
  const store = useStore();
  const [dataInicio, setDataInicio] = useState<MaterialUiPickersDate>(new Date());
  const [dataFim, setDataFim] = useState<MaterialUiPickersDate>(new Date());

  useEffect(() => {
    dataInicio?.setMonth(dataInicio.getMonth() - 1);
    // eslint-disable-next-line
  }, []);

  function gerarRelatorio(): void {
    handleClose();
    getRelatorio('/vendas/relatorio', {
      propriedadeID: store.propriedade!.id,
      dataInicio,
      dataFim,
    });
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>Gerar relatório</ModalHeader>
      <ModalBody>
        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data Início"
          value={dataInicio}
          onChange={(date) => setDataInicio(date)}
        />
        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data Fim"
          value={dataFim}
          onChange={(date) => setDataFim(date)}
        />

        <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
          <Botao cor="gray" onClick={handleClose}>
            Voltar
          </Botao>
          <Botao cor="#00C853" onClick={gerarRelatorio}>
            Gerar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default GerarRelatorio;
