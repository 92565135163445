/* eslint-disable react/display-name */
import React, { useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Flex } from '../../../components/NavBar/styles';
import { NewBody, Conteudo } from '../../../components/Body';
import { IconButton, Tooltip, Grid } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import useStore from '../../../services/hooks/useStore';

import { Link } from 'react-router-dom';
import { useLocalStore, observer } from 'mobx-react-lite';
import MaterialTable, { Column } from 'material-table';
import { newConfig } from '../../../components/Tabela';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import html2pdf from 'html3pdf';
import syncModel from '../../../services/models/Sync';
import FilterListIcon from '@material-ui/icons/FilterList';
import useModal from '../../../services/hooks/useModal';
import FiltroAvancado from '../../../components/Modais/FiltroAvancado';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import X9Logger from '../../../services/X9Logger';
import { Divisor } from '../../../components/Modais/styles';
import { aqReports, getPdfV2 } from '../../../services/utils';
import RelatorioResumoLotes from '../templates/ResumoLotes';
import InputNumero from '../../../components/InputNumero';
import ActionButton from '../../../components/Tabela/ActionButton';
import Formatter from '../../../services/Formatter';
import { permissoes } from '../../../services/utils/permissoes';

const ResumoLotes: React.FC = () => {
  const store = useStore();
  store.propriedade && store.validarPermissao(permissoes.PAINEL_ZOOTECNICO_LEITURA, history);
  const tableRef = useRef(null);
  const modalFiltros = useModal();
  const state = useLocalStore(() => ({
    pageSize: 0,
    rows: [] as object[],
    sync: false,

    setPageSize(value: number) {
      this.pageSize = value + 1;
    },

    filtroCA: [0, 0],
    filtroBiomassa: [0, 0],
    filtroDataInicio: null as MaterialUiPickersDate,
    filtroDataFim: null as MaterialUiPickersDate,
  }));

  async function sync(): Promise<void> {
    const tabela: any = tableRef.current;
    tabela.onQueryChange();
  }

  function limparFiltros(): void {
    state.filtroCA = [0, 0];
    state.filtroBiomassa = [0, 0];
    state.filtroDataInicio = null;
    state.filtroDataFim = null;
    sync();
  }

  useEffect(() => {
    store.setTitulo('Resumo de Lotes Finalizados');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getThings(): Promise<void> {
      await syncModel.desempenhoLotes();
      state.sync = true;
    }
    if (store.propriedade) {
      getThings();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  async function getResumoIndividual(
    _e: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData: any,
  ): Promise<void> {
    store.toggleLoader();

    const token = store.token;
    const propriedade = store.propriedade;

    await getPdfV2
      .get(`/relatorio/desempenho/${propriedade?.uuid}/lotes/${rowData.lote.uuid}/?authori=${token}`)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${rowData.lote?.nome}_relatorio_lotes_finalizados.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        store.notificar('Ocorreu um erro ao gerar relatório!');
      });

    store.toggleLoader();
  }

  const columns: Column<object>[] = [
    {
      field: 'lote',
      title: 'Lote',
      render: (rowData: any) => {
        return rowData.lote.nome;
      },
    },
    {
      field: 'acoes',
      disableClick: true,
      render: (rowData: any) => (
        <ActionButton
          relatorio={() => {
            getResumoIndividual(undefined, rowData);
          }}
        />
      ),
    },
    {
      field: 'tanques',
      title: 'Tanques',
      render: (rowData: any) => {
        const tanques: DesempenhoLote['tanques'] = rowData.tanques;
        const string = tanques.join(', ');

        if (string.length > 20) {
          return string.substr(0, 20) + '...';
        } else {
          return string;
        }
      },
    },
    {
      field: 'qtd_vendida',
      title: 'Qtde Vendida',
      type: 'numeric',
      render: ({ qtd_vendida }: any) => {
        return Formatter.formatNumber(0, qtd_vendida);
      },
    },
    {
      field: 'biomassa',
      title: 'Biomassa',
      type: 'numeric',
      render: ({ biomassa }: any) => {
        return Formatter.formatNumber(0, biomassa);
      },
    },
    {
      field: 'vr_venda',
      title: 'Valor Venda (R$)',
      type: 'numeric',
      render: ({ vr_venda }: any) => {
        return Formatter.formatNumber(2, vr_venda);
      },
    },
    {
      field: 'ca',
      title: 'CA',
      type: 'numeric',
      render: ({ ca }: any) => {
        return Formatter.formatNumber(3, ca);
      },
    },
    {
      field: 'gpd',
      title: 'GPD (g)',
      type: 'numeric',
      render: ({ gpd }: any) => {
        return Formatter.formatNumber(3, gpd);
      },
    },
    {
      field: 'custo_geral',
      title: 'Custo Geral (R$)',
      type: 'numeric',
      render: ({ custo_geral }: any) => {
        return Formatter.formatNumber(2, custo_geral);
      },
    },
    {
      field: 'lucro_bruto',
      title: 'Lucro Bruto (R$)',
      type: 'numeric',
      render: ({ lucro_bruto }: any) => {
        return Formatter.formatNumber(2, lucro_bruto);
      },
    },
  ];

  function getPDF(): void {
    const maxRows = 10;
    const tableLength = Math.ceil(state.rows.length / maxRows);
    const rows = [];

    for (let tableNum = 0; tableNum < tableLength; tableNum++) {
      rows.push(state.rows.slice(maxRows * tableNum, maxRows * tableNum + (maxRows - tableNum)));
    }

    const htmlString = ReactDOMServer.renderToString(
      <RelatorioResumoLotes propriedade={store.propriedade!.nome} rows={rows} />,
    );

    const data = Formatter.dateToString(new Date(), '/');

    const opt = {
      margin: 6,
      filename: `Aquabit_${data}_ResumoLotes.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'l' },
    };

    html2pdf().set(opt).from(htmlString).save();
  }

  return state.sync ? (
    <NewBody>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Link
          to={'/relatorios/relatorios-zootecnicos'}
          style={{ color: '#049CE7', textDecoration: 'none', fontFamily: 'Roboto', marginRight: '10px' }}
        >
          <Flex>
            <img src={'/images/returnArrow.svg'} />
            <div style={{ paddingLeft: '5px', fontFamily: 'Roboto', color: '#049CE7' }}>Lista de Relatórios</div>
          </Flex>
        </Link>
        <Flex>
          <Tooltip title="Exportar para Excel">
            <IconButton className="blue" onClick={() => store.notificar('Em Breve')}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Baixar PDF">
            <IconButton className="blue" onClick={getPDF}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Filtro Avançado">
            <IconButton
              className="blue"
              onClick={() => null}
              //  onClick={modalFiltros.open}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sincronizar">
            <IconButton className="blue" onClick={sync}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>

      <Conteudo style={{ padding: 0 }}>
        {store.propriedade && (
          <MaterialTable
            tableRef={tableRef}
            columns={columns}
            data={(query) =>
              new Promise((resolve, reject) => {
                aqReports
                  .get('/resumo/' + store.propriedade?.uuid + '/lotes', {
                    params: {
                      // ca: state.filtroCA.length ? state.filtroCA : undefined,
                      // biomassa: state.filtroBiomassa.length ? state.filtroBiomassa : undefined,
                      dataInicio: state.filtroDataInicio
                        ? Formatter.dateToString(state.filtroDataInicio, '-')
                        : undefined,
                      dataFim: state.filtroDataFim ? Formatter.dateToString(state.filtroDataFim, '-') : undefined,
                      orderBy: query.orderBy?.field,
                      orderDirection: query.orderDirection || null,
                      page: query.page + 1,
                    },
                  })
                  .then((res) => {
                    if (res.data.count) {
                      if (res.data.count >= 50) {
                        state.setPageSize(50);
                      } else {
                        state.setPageSize(res.data.count);
                      }
                    } else {
                      state.setPageSize(0);
                    }

                    let data = res.data.results;

                    // Filtrar por lotes finalizados
                    data = data.filter((resumo: any) => resumo.fase === 'FINALIZADO');

                    if (query.orderBy?.field === 'lote') {
                      if (query.orderDirection === 'asc') {
                        data.sort((a: any, b: any) => {
                          if (a.lote.codigo < b.lote.codigo) {
                            return -1;
                          }
                          if (a.lote.codigo > b.lote.codigo) {
                            return 1;
                          }
                          return 0;
                        });
                      } else if (query.orderDirection === 'desc') {
                        data.sort((a: any, b: any) => {
                          if (a.lote.codigo < b.lote.codigo) {
                            return 1;
                          }
                          if (a.lote.codigo > b.lote.codigo) {
                            return -1;
                          }
                          return 0;
                        });
                      }
                    }

                    state.rows = data;

                    resolve({
                      data: data,
                      page: query.page,
                      totalCount: res.data.count,
                    });
                  })
                  .catch((err) => {
                    X9Logger.report(err);
                    store.notificar('Ocorreu um erro ao gerar relatório. Tente novamente mais tarde!');
                    reject();
                  });
              })
            }
            onRowClick={getResumoIndividual}
            options={{ ...newConfig.options, pageSize: state.pageSize }}
            localization={newConfig.localization}
            style={newConfig.style}
          />
        )}
      </Conteudo>

      <FiltroAvancado
        open={modalFiltros.state}
        handleClose={modalFiltros.close}
        onConfirm={sync}
        onClear={limparFiltros}
      >
        <Divisor>
          <p>CA (g)</p>
        </Divisor>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputNumero
              value={state.filtroCA[0]}
              onChange={(value) => (state.filtroCA[0] = value)}
              precision="2"
              label="Valor Mínimo"
            />
          </Grid>
          <Grid item xs={6}>
            <InputNumero
              value={state.filtroCA[1]}
              onChange={(value) => (state.filtroCA[1] = value)}
              precision="2"
              label="Valor Máximo"
            />
          </Grid>
        </Grid>
        <Divisor>
          <p>Biomassa (kg)</p>
        </Divisor>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputNumero
              value={state.filtroBiomassa[0]}
              onChange={(value) => (state.filtroBiomassa[0] = value)}
              precision="2"
              label="Valor Mínimo"
            />
          </Grid>
          <Grid item xs={6}>
            <InputNumero
              value={state.filtroBiomassa[1]}
              onChange={(value) => (state.filtroBiomassa[1] = value)}
              precision="2"
              label="Valor Máximo"
            />
          </Grid>
        </Grid>
        <Divisor>
          <p>Data de finalização</p>
        </Divisor>
        <div style={{ width: '90%' }}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Início"
            value={state.filtroDataInicio}
            onChange={(date) => (state.filtroDataInicio = date)}
          />
        </div>
        <div style={{ width: '90%' }}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data Fim"
            value={state.filtroDataFim}
            onChange={(date) => (state.filtroDataFim = date)}
          />
        </div>
      </FiltroAvancado>
    </NewBody>
  ) : null;
};

export default observer(ResumoLotes);
