import React from 'react';
import { Modal, ModalHeader, ModalBody } from '../../components/Modais/styles';
import Botao from '../../components/Botoes/Botao';
import { TextField } from '@material-ui/core';
import useStore, { stateContext } from '../../services/hooks/useStore';
import { useLocalStore, observer } from 'mobx-react-lite';
import InputNumero from '../../components/InputNumero';
import Select from '../../components/Select';
import { api } from '../../services/utils';

type Props = ModalProps & {
  tanque: Tanque;
  sync?: () => void;
};

async function editarApi(dados: TanqueData, tanqueID: number, sync?: () => void): Promise<void> {
  const store = stateContext.state;

  await api
    .put(`/tanques/${tanqueID}/?propriedade=${store.propriedade!.id}`, dados)
    .then(async () => {
      store.notificar('Tanque editado com sucesso!');
      await Promise.all([
        store.tanques.populate(),
        store.lotes.populate(),
        store.loteTanque.populate(),
        sync && sync(),
      ]);
    })
    .catch(() => store.notificar('Ocorreu um erro ao editar, tente novamente'));
}

const EditarTanque: React.FC<Props> = ({ open, handleClose, tanque, sync }) => {
  const store = useStore();
  const state = useLocalStore(() => ({
    nome: tanque.nome,
    tamanho: tanque.tamanho,
    capacidade: tanque.capacidade,
    aguaOrigem: { label: tanque.agua_origem.nome, value: tanque.agua_origem.id.toString() },
    tipoTanque: { label: tanque.tanque_tipo.nome, value: tanque.tanque_tipo.id.toString() },
    situacaoTanque: { label: tanque.situacao_tanque.nome, value: tanque.situacao_tanque.id.toString() },
  }));

  async function editar(): Promise<void> {
    if (state.tamanho === 0) store.notificar('Informe o tamanho do tanque');
    else if (state.capacidade === 0) store.notificar('Informe a capacidade do tanque');
    else if (!state.aguaOrigem) store.notificar('Informe a origem da água');
    else if (!state.tipoTanque) store.notificar('Informe o tipo do tanque');
    else if (!state.situacaoTanque) store.notificar('Informe a situação do tanque');
    else {
      store.toggleLoader();

      const data = {
        codigo: tanque.codigo,
        propriedade: store.propriedade!.id,
        tanque_tipo: parseInt(state.tipoTanque.value),
        agua_origem: parseInt(state.aguaOrigem.value),
        tamanho: state.tamanho,
        nome: state.nome || null,
        capacidade: state.capacidade,
        situacao_tanque: parseInt(state.situacaoTanque.value),
      };

      await editarApi(data as any, tanque.id, sync && (sync() as any));

      store.toggleLoader();
      handleClose();
    }
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Editar Tanque</ModalHeader>
      <ModalBody>
        <div style={{ width: '500px' }}>
          <TextField label="Nome" value={state.nome} onChange={(e) => (state.nome = e.target.value)} />

          <div style={{ width: '100%' }}>
            {store.origensDaAgua.arr && (
              <Select
                value={state.aguaOrigem}
                placeholder="Selecione a origem da água"
                onChange={(e: any) => (state.aguaOrigem = e)}
                options={store.origensDaAgua.getSelectRows()}
              />
            )}
          </div>
          <div style={{ width: '100%' }}>
            {store.tiposDeTanque.arr && (
              <Select
                value={state.tipoTanque}
                placeholder="Selecione o tipo de Tanque/Viveiro"
                onChange={(e: any) => (state.tipoTanque = e)}
                options={store.tiposDeTanque.getSelectRows()}
              />
            )}
          </div>
          <div style={{ width: '100%' }}>
            {store.situacoesDeTanque.arr && (
              <Select
                value={state.situacaoTanque}
                placeholder="Selecione a situação do tanque"
                onChange={(e: any) => (state.situacaoTanque = e)}
                options={store.situacoesDeTanque.getSelectRows()}
              />
            )}
          </div>

          <InputNumero
            value={state.tamanho}
            onChange={(value) => (state.tamanho = value)}
            precision="2"
            label="Tamanho (m²/m³)"
          />
          <InputNumero
            value={state.capacidade}
            onChange={(value) => (state.capacidade = value)}
            precision="0"
            label="Capacidade (Kg)"
          />

          <div style={{ width: '100%', display: 'flex' }}>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao onClick={editar} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(EditarTanque);
