import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from './styles';
import { LoginButton } from '../../pages/Login/styles';
import { baseApi } from '../../services/utils';
import useStore from '../../services/hooks/useStore';
import ReactInputMask from 'react-input-mask';
import { TextField } from '@material-ui/core';
import Formatter from '../../services/Formatter';

const ModalResetarSenha: React.FC<ModalProps> = ({ open, handleClose }) => {
  const [usuario, setUsuario] = useState('');
  const [isCPF, setIsCPF] = useState(false);

  const store = useStore();

  function mudarUsuario(e: React.ChangeEvent<HTMLInputElement>): void {
    setUsuario(e.target.value);
    if (Formatter.getNumeros(e.target.value).length <= 11) {
      setIsCPF(true);
    } else {
      setIsCPF(false);
    }
  }
  function recuperarSenha(): void {
    const inscricaoFederal = Formatter.getNumeros(usuario);
    baseApi

      .post('/auth/recovery/', { inscricao_federal: inscricaoFederal, senha_nova: '1234567' })
      .then(() => store.notificar('Verifique seu e-mail'))
      .catch(() => {
        store.notificar('Aconteceu um erro! Verifique as informações');
      });
  }
  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Recuperação de Senha</ModalHeader>
      <ModalBody>
        <p>Para recuperar a senha, insira abaixo seu CPF/CNPJ</p>
        <ReactInputMask
          value={usuario}
          onChange={mudarUsuario}
          mask={isCPF ? '999.999.999-999' : '99.999.999/9999-99'}
          maskChar=""
        >
          {() => <TextField style={{ margin: '10px 0' }} label="CPF/CNPJ" />}
        </ReactInputMask>
        <LoginButton onClick={recuperarSenha} variant="contained" color="primary" style={{ marginTop: 10 }}>
          RECUPERAR SENHA
        </LoginButton>
      </ModalBody>
    </Modal>
  );
};

export default ModalResetarSenha;
