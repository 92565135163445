import { observable, computed } from 'mobx';
import { apiV2 } from '../utils';
import { stateContext } from '../hooks/useStore';

type PesoMedioData = {
  id?: number;
  especie: { value: number; label: string } | null;
  peso_medio_despesca: number;
  tempo_pm_lote: number;
  isNew?: boolean;
  isEdit?: boolean;
};

class PerfilPesoMedioDespesca {
  @observable arr: PesoMedioData[] | null = null;

  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  // Populate the array with data from the API
  async populate(): Promise<void> {
    try {
      const { data } = await apiV2.get(`/perfil-peso-medio-despesca/?prop=${stateContext.state.propriedade?.id}`);
      if (Array.isArray(data) && data.length > 0) {
        this.arr = data.map((item: any) => {
          const especie = stateContext.state.especies.arr?.find((esp) => esp.id === item.especie);
          return {
            id: item.id,
            especie: especie ? { value: especie.id, label: especie.nome || '' } : null,
            peso_medio_despesca: item.peso_medio_despesca,
            tempo_pm_lote: item.tempo_pm_lote,
            isNew: false,
            isEdit: false,
          };
        });
      } else {
        this.arr = [];
      }
    } catch (error) {
      stateContext.state.notificar('Erro ao carregar a lista de pesos médios.');
    }
  }

  async criar(dados: PesoMedioData): Promise<void> {
    const store = stateContext.state;
    try {
      await apiV2.post(`/perfil-peso-medio-despesca/?prop=${store.propriedade?.id}`, dados);
      store.notificar('Peso médio criado com sucesso!');
      await this.populate();
    } catch (error) {
      store.notificar('Erro ao criar peso médio.');
    }
  }

  async editar(id: number, dados: PesoMedioData): Promise<void> {
    const store = stateContext.state;
    try {
      await apiV2.put(`/perfil-peso-medio-despesca/${id}/?prop=${store.propriedade?.id}`, dados);
      store.notificar('Peso médio atualizado com sucesso!');
      await this.populate();
    } catch (error) {
      store.notificar('Erro ao atualizar peso médio.');
    }
  }

  async excluir(id: number): Promise<void> {
    const store = stateContext.state;
    try {
      await apiV2.delete(`/perfil-peso-medio-despesca/${id}/?prop=${store.propriedade?.id}`);
      store.notificar('Peso médio excluído com sucesso!');
      await this.populate();
    } catch (error) {
      store.notificar('Erro ao excluir peso médio.');
    }
  }

  filter(callback: (item: PesoMedioData, index: number, array: PesoMedioData[]) => boolean): PesoMedioData[] {
    return this.arr?.filter(callback) || [];
  }

  map(callback: (item: PesoMedioData, index: number, array: PesoMedioData[]) => any): any {
    return this.arr?.map(callback) || [];
  }

  // Get select rows
  getSelectRows(): { label: string; value: string }[] {
    return (
      this.arr?.map((item) => ({
        label: item.especie?.label || 'Sem nome',
        value: item.especie?.value.toString() || '',
      })) || []
    );
  }
}

export default PerfilPesoMedioDespesca;
