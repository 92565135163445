/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useMemo } from 'react';
import { useLocalStore } from 'mobx-react-lite';
import { TextField, Typography, Button as ButtonMUI } from '@material-ui/core';

import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import useStore from '../../../services/hooks/useStore';
import Botao from '../../../components/Botoes/Botao';
import useModal from '../../../services/hooks/useModal';
import { returnTanque } from '../../../services/utils/propriedade';
import Formatter from '../../../services/Formatter';
import { TanqueInfoVendas } from '../../../components/TanqueInfoVendas';
import { apiV2 } from '../../../services/utils';
import QuantidadeModalAlevino from './QuantidadeModalAlevino';

type Props = ModalProps & {
  adicionarOutro: boolean;
  categoria: string;
  cancelar(): void;
  addTanque(
    tanqueID: number,
    quantidade: number,
    biomassa: number,
    pesoMedio: number,
    finalizar?: boolean,
    bonificacao?: boolean,
    bonificacaoPorc?: number,
    bonificacaoQtde?: number,
    valorUnitario?: number,
    valorTotal?: number,
    lancarMortalidadeTanque?: boolean,
  ): void;
  setLote(lote: number): void;
  selectedTanques: {
    item: Tanque;
    lote_tanque: number;
    quantidade?: number;
    biomassa: number;
    peso_medio: number;
  }[];
  exclude: number[];
};
const SelecionarTanque: React.FC<Props> = ({
  adicionarOutro,
  categoria,
  open,
  handleClose,
  selectedTanques,
  cancelar,
  addTanque,
  setLote,
}) => {
  const state = useLocalStore(() => ({
    sync: false,
  }));
  const store = useStore();
  const modalQuantidade = useModal();
  const [codigo, setCodigo] = useState<string>('');
  const [tanqueSelecionado, setTanqueSelecionado] = useState<LoteTanqueModal | null>(null);
  const [loteTanques, setLoteTanques] = useState<LoteTanqueModal[]>([] as LoteTanqueModal[]);
  const mostrarFiltroPorCategoria = useMemo(() => categoria === 'Camarão', []);
  const [filtrarPorCategoria, setFiltrarPorCategoria] = useState(mostrarFiltroPorCategoria || false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function searchTanque() {
      store.toggleLoader();
      await apiV2
        .get('/lote-tanque/modals/', {
          params: {
            propriedade: store.propriedade!.id,
            nome: codigo,
          },
        })
        .then((res) => {
          setLoteTanques(res.data.results);

          state.sync = true;
        });

      store.toggleLoader();
    }
    searchTanque();

    // eslint-disable-next-line
  }, [codigo, setCodigo]);
  const TanquesDisponiveis: React.FC = () => (
    <>
      {loteTanques
        ?.filter((tanque) => {
          if (filtrarPorCategoria) return tanque.especie_explorada.nome === categoria;
          return tanque;
        })
        ?.sort((a, b) => {
          const textA = a.tanque.nome ? a.tanque.nome.toUpperCase() : a.tanque.codigo;
          const textB = b.tanque.nome ? b.tanque.nome.toUpperCase() : b.tanque.codigo;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map((loteTanque) => {
          let selected = selectedTanques.some((obj) => loteTanque.id === obj.lote_tanque);

          if (adicionarOutro) {
            selected = false;
          }

          const PM = loteTanque.peso_medio;

          return loteTanque.estoque > 0 && loteTanque.fase_lote.nome !== 'FINALIZADO' ? (
            <TanqueInfoVendas
              selected={selected || undefined}
              onClick={() => {
                setLote(loteTanque.lote.id);
                setTanqueSelecionado(loteTanque);
                modalQuantidade.open();
              }}
              key={loteTanque.tanque.id}
            >
              <div>
                <b className="left">{returnTanque(loteTanque.tanque)}</b>
                <p className="right">
                  {loteTanque.lote.codigo} ({Formatter.formatNumber(0, loteTanque.estoque)})
                </p>
                <p className="left" style={{ color: '#00C853' }}>
                  {Formatter.formatNumber(2, (PM * loteTanque.estoque) / 1000)} Kg
                </p>
                <p className="right" style={{ color: '#00C853' }}>
                  {loteTanque.fase_lote.nome}
                </p>
              </div>
              {selected && (
                <div>
                  <b className="left" style={{ color: '#C80000' }}>
                    Nova venda
                  </b>
                  <p className="right" style={{ color: '#C80000' }}>
                    +
                    {Formatter.formatNumber(
                      0,
                      selectedTanques.filter((obj) => loteTanque.tanque.id === obj.item.id)[0].quantidade,
                    )}{' '}
                    un
                  </p>
                </div>
              )}
            </TanqueInfoVendas>
          ) : null;
        })}
    </>
  );

  function handleToggleFiltrarPorCategoria() {
    setFiltrarPorCategoria((prev) => !prev);
  }

  useEffect(() => {
    console.log(tanqueSelecionado);
    console.log('categoria', categoria);
  }, [tanqueSelecionado]);

  return (
    <Modal open={open} onClose={handleClose} maxWidth="xl">
      <ModalHeader>Selecione um Tanque</ModalHeader>
      <ModalBody>
        <TextField
          label="Pesquisa Pelo Nome"
          value={codigo}
          onChange={(e) => {
            setCodigo(e.target.value);
          }}
        />
        <TanquesDisponiveis />
      </ModalBody>

      {mostrarFiltroPorCategoria ? (
        <div style={{ display: 'flex', justifyContent: 'center', width: '550px' }}>
          <ButtonMUI onClick={handleToggleFiltrarPorCategoria}>
            <Typography variant="subtitle2" style={{ color: '#42a5f4' }}>
              {filtrarPorCategoria ? '+ Mostrar todos os tanques' : `— Mostrarsomente tanques de ${categoria}`}
            </Typography>
          </ButtonMUI>
        </div>
      ) : null}

      <div style={{ width: '100%', display: 'flex', padding: '10px' }}>
        <Botao cor="gray" onClick={cancelar}>
          Voltar
        </Botao>
        <Botao cor="#00C853" onClick={handleClose}>
          Confirmar
        </Botao>
      </div>
      {modalQuantidade.state ? (
        <QuantidadeModalAlevino
          onConfirm={addTanque}
          loteTanque={tanqueSelecionado as any}
          open={modalQuantidade.state}
          handleClose={() => modalQuantidade.close()}
          categoria={categoria}
        />
      ) : null}
    </Modal>
  );
};

export default SelecionarTanque;
