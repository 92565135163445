import { DefaultTheme } from 'styled-components';

const lightTheme: DefaultTheme = {
  colors: {
    primary: '#42a5f4',
    background: '#F5F6F8',
  },
  sizes: {
    navbar: 50,
    drawer: 68,
  },
};

export default lightTheme;
