import React, { useEffect, useRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable, { Column } from 'material-table';
import useStore from '../../../services/hooks/useStore';
import { config } from '../../../components/Tabela';
import Header from '../../../components/Header';
import AddBtn from '../../../components/Botoes/AddBtn';
import { Flex } from '../../../components/NavBar/styles';
import { Body } from '../../../components/Body';
import { useLocalStore } from 'mobx-react-lite';
import { api, apiV2 } from '../../../services/utils';
import X9Logger from '../../../services/X9Logger';
import Formatter from '../../../services/Formatter';
import ActionButton from '../../../components/Tabela/ActionButton';
import Filtro from '../../../components/Filtro';
import { useHistory, useLocation } from 'react-router-dom';
import syncModel from '../../../services/models/Sync';
import useModal from '../../../services/hooks/useModal';
import Select from '../../../components/Select';
import { TextField } from '@material-ui/core';
import CadastrarPlantio from './CadastrarPlantio';
import EditarPlantio from './EditarPlantio';
import Confirmar from '../../../components/Modais/Confirmar';

const Plantio = () => {
  const tableRef = useRef(null);
  const location: any = useLocation();
  const store = useStore();
  const history = useHistory();
  const state = useLocalStore(() => ({
    pageSize: 50,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },

    page: location.state ? location.state.page : 0,
    setPage(num: number) {
      this.page = num;
    },

    filtroEspecie: null as SelectRow | null,
    filtroFase: null as SelectRow | null,
    filtroRapidoFase: null as SelectRow | null,
    filtroRapidoFinalizado: false,
    filtroLote: '',

    selectedPlantio: {} as Lote,//TODO:Criar tipo Plantio

    historico: null as Historico[] | null,

    sync: false,
  }));
  const modalCadastro = useModal();
  const modalEditar = useModal();
  const modalExclusao = useModal();
  const modalFase = useModal();
  const modalFinalizar = useModal();

  function validaInteracao(historico: any) {
    if (historico && historico.filter((registro: any) => registro.tipo != 'povoamento').length === 0) {
      return true;
    } else {
      return false;
    }
  }

  const columns: Column<Lote>[] = [
    {
      field: 'codigo',
      title: 'Lote',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'acoes',
      disableClick: true,
      render: (rowData: any) => {
        return (
          <ActionButton
            editar={() => {
              state.selectedPlantio = rowData;
              modalEditar.open();
            }}
            mudarFase={() => {
              state.selectedPlantio = rowData;
              modalFase.open();
            }}
            finalizar={() => {
              state.selectedPlantio = rowData;
              modalFinalizar.open();
            }}
            excluir={async () => {
              const excluir = async (): Promise<void> => {
                state.selectedPlantio = rowData;
                const historico = await store.lotes.getHistorico(rowData.id);
                validaInteracao(historico)
                  ? modalExclusao.open()
                  : store.notificar('O lote contém registros e não pode ser excluído!');
                // (tableRef.current as any)?.onQueryChange();
              };
              excluir();
            }}
            historico={() => history.push({ pathname: '/producao/lote/' + rowData.id, state: { page: state.page } })}
          />
        );
      },
      ...(true && ({ width: 25 } as object)),
    },

    {
      field: 'especie_explorada',
      title: 'Espécie',
      render: ({ especie_explorada }) => {
        return especie_explorada.nome;
      },
      ...(true && ({ width: 140 } as object)),
    },

    {
      field: 'fornecedor',
      title: 'Fornecedor',
      render: ({ fornecedor }) => {
        return fornecedor.nome;
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'fase_lote',
      title: 'Fase ',
      render: ({ fase_lote }) => {
        return <span style={{ color: fase_lote.nome === 'FINALIZADO' ? 'green' : 'inherit' }}>{fase_lote.nome}</span>;
      },
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'data_alojamento',
      title: 'Data',
      type: 'date',
      render: ({ data_alojamento }: any) => {
        return data_alojamento ? data_alojamento : '';
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'data_prevista',
      title: 'Previsão de Colheita',
      type: 'date',
      render: ({ data_prevista }: any) => {
        return data_prevista ? data_prevista : '';
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'valor',
      title: 'Valor (R$)',
      type: 'numeric',
      render: ({ valor }: any) => {
        return Formatter.formatNumber(2, valor);
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'qtd_animais',
      title: 'Qtd de Hortaliças',
      type: 'numeric',
      render: ({ qtd_animais }: any) => {
        return Formatter.formatNumber(0, qtd_animais);
      },
      ...(true && ({ width: 160 } as object)),
    },
    {
      field: 'peso_medio',
      type: 'numeric',
      title: 'Peso Médio (g)',
      render: ({ peso_medio }) => {
        return Formatter.formatNumber(3, peso_medio);
      },
      ...(true && ({ width: 160 } as object)),
    },
    {
      field: 'biomassa',
      title: 'Produção Total (Kg)',
      type: 'numeric',
      render: ({ biomassa }) => {
        return Formatter.formatNumber(3, biomassa);
      },
      ...(true && ({ width: 160 } as object)),
    },
  ];

  async function sync(): Promise<void> {
    await syncModel.plantio();
    state.sync = true;
  }

  async function filtrarPlantio(): Promise<void> {
    // Checagem de filtros avançados
    if (state.filtroEspecie || state.filtroFase) {
      (tableRef.current as any)?.onQueryChange();
      // Limpar filtroRápido
      state.filtroRapidoFase = null;
    } else {
      await sync();
      (tableRef.current as any)?.onQueryChange();
    }
  }

  function limparFiltro(): void {
    state.filtroFase = null;
    state.filtroEspecie = null;
    state.filtroRapidoFase = null;
    state.filtroLote = '';
    (tableRef.current as any)?.onQueryChange();
  }


  const ExcluirLote: React.FC = () => {
    const plantio = state.selectedPlantio;

    return (
      <div style={{ width: '100%' }}>
        <b>Lote: </b> {plantio && plantio?.codigo}
        <br />
        <b>Data: </b> {plantio?.data_alojamento && Formatter.getData(plantio?.data_alojamento)}
        <br />
        <b>Espécie: </b>
        {plantio && plantio?.especie_explorada?.nome}
        <br />
        <b>Fase: </b>
        {plantio && plantio?.fase_lote?.nome}
        <br />
        <b>Valor: </b>
        R$ {plantio?.valor && Formatter.formatNumber(2, plantio?.valor)}
      </div>
    );
  };

  async function deletarPlantio(plantio: Lote): Promise<void> {
  
    try {
      store.toggleLoader();
  
      await api.delete(`/lote/${plantio.uuid}/?propriedade=${store.propriedade?.id}`);
      await store.lotes.populate();
      store.notificar('Plantio excluído com sucesso!');
    } catch (error: any) {
      X9Logger.report(error as Error);
      error.response && store.notificar(error.response.data.erro);
    } finally {
      store.toggleLoader();
    }
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Plantio'), []);

  useEffect(() => {
    (tableRef.current as any)?.onQueryChange();
    // eslint-disable-next-line
  }, [state.filtroRapidoFase, state.filtroRapidoFinalizado]);

  useEffect(() => {
    if (store.propriedade) {
      sync();
      (tableRef.current as any)?.onQueryChange();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  return (
    <>
      <Header>
        <Flex>
          <AddBtn
            onClick={() => {
              modalCadastro.open();
            }}
            text="ADICIONAR"
            Icon={AddIcon}
          />
        </Flex>
        <Flex>
          <Filtro sync={filtrarPlantio} clear={limparFiltro}>
            <div style={{ width: '90%' }}>
              <Select
                value={state.filtroEspecie}
                placeholder="Espécie"
                onChange={(e: any) => (state.filtroEspecie = e)}
                options={store.especies.getSelectRows()!}
              />
            </div>
            <div style={{ width: '90%' }}>
              <Select
                value={state.filtroFase}
                placeholder="Fase do lote"
                onChange={(e: any) => (state.filtroFase = e)}
                options={store.fasesLote.getSelectRows()}
              />
            </div>
            <TextField
              inputProps={{ style: { minWidth: '350px' } }}
              label="Pesquisa por lote"
              value={state.filtroLote}
              onChange={(e) => {
                state.filtroLote = e.target.value;
              }}
            />
          </Filtro>
        </Flex>
      </Header>

      <Body>
        <div style={{ overflowX: 'hidden', borderRadius: 10 }}>
          {store.propriedade ? (
            <MaterialTable
              tableRef={tableRef}
              columns={columns}
              data={(query) =>
                new Promise((resolve, reject) => {
                  const filtros: { [key: string]: any } = {
                    orderBy: query.orderBy?.field || 'nome',
                    orderDirection: query.orderDirection || 'asc',
                  };

                  if (!state.filtroEspecie && !state.filtroFase && state.filtroRapidoFase) {
                    filtros.fase_lote = parseInt(state.filtroRapidoFase.value);
                  } else {
                    filtros.especie_explorada = state.filtroEspecie ? parseInt(state.filtroEspecie.value) : undefined;
                    filtros.fase_lote = state.filtroFase ? parseInt(state.filtroFase.value) : undefined;
                  }

                  filtros.finalizado = state.filtroRapidoFinalizado ? state.filtroRapidoFinalizado : '';
                  filtros.search = state.filtroLote || undefined;

                  apiV2
                    .get('/lote/list-historico/', {
                      params: {
                        page: query.page + 1,
                        page_size: 50,
                        prop: store.propriedade?.id,
                        aquaponia: true,
                        ...filtros,
                      },
                    })
                    .then((res) => {
                      if (res.data.count) {
                        if (res.data.count >= 50) {
                          state.setPageSize(50);
                        } else {
                          state.setPageSize(res.data.count);
                        }
                      } else {
                        state.setPageSize(0);
                      }
                      resolve({
                        data: res.data.results,
                        page: query.page,
                        totalCount: res.data.count,
                      });
                    })
                    .catch((err) => {
                      X9Logger.report(err);
                      store.notificar('Ocorreu um erro ao carregar os plantios. Tente novamente mais tarde!');
                      reject();
                    });
                })
              }
              options={{ ...config.options, pageSize: state.pageSize }}
              localization={config.localization}
              style={config.style}
            />
          ) : null}
        </div>

        {modalCadastro.state ? (
          <CadastrarPlantio
            open={modalCadastro.state}
            handleClose={() => {
              modalCadastro.close();
              (tableRef.current as any)?.onQueryChange();
            }}
          />
        ) : null}
        {
          modalEditar.state ? (
            <EditarPlantio
              open={modalEditar.state}
              plantio={state.selectedPlantio}
              handleClose={() => {
                modalEditar.close();
                (tableRef.current as any)?.onQueryChange();
              }}
            />
          ) : null
        }
        <Confirmar
          title="Tem certeza que deseja excluir?"
          open={modalExclusao.state}
          content={<ExcluirLote />}
          handleClose={modalExclusao.close}
          onCancel={modalExclusao.close}
          onConfirm={async () => {
            modalExclusao.close();
            await deletarPlantio(state?.selectedPlantio);
            (tableRef.current as any)?.onQueryChange();
          }}
        />
      </Body>
    </>
  );
};

export default Plantio;
