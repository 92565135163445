import { CompraInsumo } from '../../../../@types/Insumo';
import State from '../../../../services/State';

function getAjuste(store: State, state: any): CompraInsumo | undefined {
  //TODO: consertar os tipos de store e state aqui
  const ajuste = store.comprasDeInsumos!.get(state.selectedCompra);
  if (ajuste?.ajuste_estoque) {
    return ajuste;
  } else {
    return undefined;
  }
}

export default getAjuste;
