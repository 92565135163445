import styled, { css } from 'styled-components';

type Props = {
  gridColumns?: string;
  grid?: boolean;
};
export const Body = styled.div<Props>`
  width: calc(100vw - ${(props) => props.theme.sizes.drawer});
 
  display: ${(props) => (props.grid ? 'normal' : 'grid')};
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 20px;
  padding-top: 8px;
  grid-template-columns: ${(props) => props.gridColumns};

  tbody {
    td {
      text-align: end;
    }
  }
`;
export const ContainerBody = styled.div`
  height: 700px;
  overflow: auto;
  margin-top: 8px;
  margin-bottom: -200px;

  :last-child{
    margin-bottom: 40px;
  }

  
`
export const NewBody = styled.div`
  width: calc(100vw - ${(props) => props.theme.sizes.drawer}px);
  max-height: calc(100vh - ${(props) => props.theme.sizes.navbar}px);
  padding: 15px;

  overflow-y: auto;
`;

type ConteudoProps = {
  overflowY?: boolean;
};
export const Conteudo = styled.div<ConteudoProps>`
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid rgba(201, 201, 201, 0.5);
  padding: 15px;
  margin-top: 15px;

  ${(props) =>
    props.overflowY &&
    css`
      max-height: calc(100vh - 100px - ${(props) => props.theme.sizes.navbar}px);
      overflow-y: scroll;
    `}

  h2 {
    color: ${(props) => props.theme.colors.primary};
  }
`;
