/**
 * Código baseado do pacote email-validator
 * https://www.npmjs.com/package/email-validator
 * 
 */

/**
 * Validate an email address.
 * @param {string} email - The email address to validate.
 * @returns {boolean}
 */
function validate(email: string): boolean {
  if (!email) {
    return false;
  }

  if (email.length > 254) {
    return false;
  }

  let tester = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  let valid = tester.test(email);
  if (!valid) {
    return false;
  }

  // Further checking of some things regex can't handle
  let parts = email.split("@");
  if (parts[0].length > 64) {
    return false;
  }

  let domainParts = parts[1].split(".");
  if (domainParts.some(function (part) {
    return part.length > 63;
  })) {
    return false;
  }

  return true;
}

/**
 * Async email validation.
 * @param {string} email - The email address to validate.
 * @param {AsyncCallback} callback - The callback to execute.
 */
function validateAsync(email: string, callback: AsyncCallback): void {
  const isValid = validate(email);
  callback(null, isValid);
}

export interface AsyncCallback {
  (err: any, isValidEmail: boolean): any;
}

export const emailValidator = { validate, validateAsync };
