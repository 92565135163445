import React, { useEffect, useRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable, { Column } from 'material-table';
import useStore from '../../../services/hooks/useStore';
import { config } from '../../../components/Tabela';
import Header from '../../../components/Header';
import AddBtn from '../../../components/Botoes/AddBtn';
import { Flex } from '../../../components/NavBar/styles';
import { Body } from '../../../components/Body';
import { useLocalStore } from 'mobx-react-lite';
import { api, apiV2 } from '../../../services/utils';
import X9Logger from '../../../services/X9Logger';
import { returnTanque } from '../../../services/utils/propriedade';
import useModal from '../../../services/hooks/useModal';
import CadastrarBancada from './CadastrarBancada';
import ActionButton from '../../../components/Tabela/ActionButton';
import Formatter from '../../../services/Formatter';
import syncModel from '../../../services/models/Sync';
import { useHistory, useLocation } from 'react-router-dom';
import EditarBancada from './EditarBancada';
import { permissoes } from '../../../services/utils/permissoes';
import Confirmar from '../../../components/Modais/Confirmar';

const Bancadas = () => {
  const store = useStore();
  const history = useHistory();
  const location: any = useLocation();

  store.propriedade && store.validarPermissao(permissoes.PROPRIEDADE_TANQUES_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.PROPRIEDADE_TANQUES_ESCRITA);

  const tableRef = useRef(null);
  const modalCadastro = useModal();
  const modalEditar = useModal();
  const modalExclusao = useModal();
  const state = useLocalStore(() => ({
    pageSize: 50,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },

    page: location.state ? location.state.page : 0,
    selectedBancada: null as any | null,
    sync: false,
  }));

  const columns: Column<any>[] = [
    {
      field: 'nome',
      title: 'Bancadas',
      render(rowData) {
        return returnTanque(rowData);
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'acoes',
      disableClick: true,
      render: (rowData) => {
        return (
          <ActionButton
            editar={
              temPermissaoEscrita
                ? () => {
                    state.selectedBancada = rowData;
                    modalEditar.open();
                  }
                : undefined
            }
            historico={() =>
              history.push({ pathname: '/propriedade/bancada/' + rowData?.id, state: { page: state.page } })
            }
            excluir={
              temPermissaoEscrita
                ? () => {
                    state.selectedBancada = rowData;
                    modalExclusao.open();
                  }
                : undefined
            }
          />
        );
      },
      ...(true && ({ width: 25 } as object)),
    },
    {
      field: 'tamanho',
      title: 'Quantidade de perfis',
      render(rowData: any) {
        return Formatter.formatNumber(0, rowData.tamanho);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'capacidade',
      title: 'Quantidade de furos',
      render(rowData: any) {
        return Formatter.formatNumber(0, rowData.capacidade);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'reservatorio',
      title: 'Reservatórios',
      render(rowData: any) {
        return rowData.reservatorio ? rowData.reservatorio.nome : 'Sem reservatório';
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'estoque',
      title: 'Estoque',
      render(rowData: any) {
        return Formatter.formatNumber(0, rowData.estoque);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'peso_medio',
      title: 'Peso Médio (g)',
      render(rowData: any) {
        return Formatter.formatNumber(0, rowData.peso_medio);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'producao_atual',
      title: 'Produção Atual (Kg)',
      render(rowData: any) {
        return Formatter.formatNumber(3, rowData.biomassa);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'lote',
      title: 'Lote',
      render(rowData: any) {
        return rowData.lotes ? rowData.lotes : 'Sem Lote';
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'especie',
      title: 'Espécie',
      render(rowData: any) {
        return rowData.especie_explorada ? rowData.especie_explorada : 'Sem Espécie';
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'fase',
      title: 'Fase',
      render(rowData: any) {
        return rowData.fase_lote ? rowData.fase_lote : 'Livre';
      },
      ...(true && ({ width: 180 } as object)),
    },
  ];

  async function recarregarTabela() {
    await (tableRef.current as any).onQueryChange();
  }
  async function sync(): Promise<void> {
    store.toggleLoader();
    await syncModel.bancadas();
    state.sync = true;
    store.toggleLoader();
  }

  async function excluir(bancadaID: number): Promise<void> {
    const historico = await store.tanques.getHistorico(bancadaID);

    if (historico) {
      if (historico?.length > 0) {
        store.notificar('A bancada possui histórico e não pode ser excluída!');
      } else {
        store.toggleLoader();
        await api.delete(`/tanques/${bancadaID}/?propriedade=${store.propriedade!.id}`);
        store.notificar('Bancada excluída com sucesso!');
        store.toggleLoader();
        (tableRef.current as any)?.onQueryChange();
      }
    }
  }
  const ExcluirBancada: React.FC = () => {
    return (
      <div style={{ width: '100%' }}>
        <b>Bancada: </b> {state.selectedBancada?.nome}
        <br />
        <b>Lote: </b> {state.selectedBancada?.lotes}
        <br />
        <b>Especie:</b> {state.selectedBancada?.especie_explorada}
        <br />
        <b>Fase:</b> {state.selectedBancada?.fase_lote}
        <br />
      </div>
    );
  };
  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Bancadas'), []);

  useEffect(() => {
    if (store.propriedade) {
      sync();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  return (
    <>
      <Header>
        <Flex>
          <AddBtn
            onClick={() => {
              modalCadastro.open();
            }}
            text="ADICIONAR"
            Icon={AddIcon}
          />
        </Flex>
      </Header>

      <Body>
        <div style={{ overflowX: 'hidden', borderRadius: 10 }}>
          {store.propriedade ? (
            <MaterialTable
              tableRef={tableRef}
              columns={columns}
              data={(query) =>
                new Promise((resolve, reject) => {
                  apiV2
                    .get('/tanque/list-historico/', {
                      params: {
                        page: query.page + 1,
                        page_size: 50,
                        propriedade: store.propriedade?.id,
                        todos: true,
                        orderBy: query.orderBy?.field || 'nome',
                        orderDirection: query.orderDirection || 'asc',
                        nome: '',
                        tanque_tipo: 6,
                        bancadas: true,
                      },
                    })
                    .then((res) => {
                      if (res.data.count) {
                        if (res.data.count >= 50) {
                          state.setPageSize(50);
                        } else {
                          state.setPageSize(res.data.count);
                        }
                      } else {
                        state.setPageSize(0);
                      }
                      resolve({
                        data: res.data.results,
                        page: query.page,
                        totalCount: res.data.count,
                      });
                    })
                    .catch((err) => {
                      X9Logger.report(err);
                      store.notificar('Ocorreu um erro ao carregar as bancadas. Tente novamente mais tarde!');
                      reject();
                    });
                })
              }
              onRowClick={(_e, row: any) =>
                history.push({ pathname: '/propriedade/bancada/' + row?.id, state: { page: state.page } })
              }
              options={{ ...config.options, pageSize: state.pageSize }}
              localization={config.localization}
              style={config.style}
            />
          ) : null}
        </div>

        {modalCadastro.state && (
          <CadastrarBancada
            open={modalCadastro.state}
            handleClose={async () => {
              await recarregarTabela();
              modalCadastro.close();
            }}
          />
        )}
        {modalEditar.state ? (
          <EditarBancada
            open={modalEditar.state}
            bancada={state.selectedBancada!}
            handleClose={() => {
              modalEditar.close();
            }}
            sync={(tableRef.current as any)?.onQueryChange()}
          />
        ) : null}
        <Confirmar
          title="Tem certeza que deseja excluir?"
          open={modalExclusao.state}
          content={<ExcluirBancada />}
          handleClose={() => {
            modalExclusao.close();
          }}
          onCancel={modalExclusao.close}
          onConfirm={() => {
            modalExclusao.close();
            excluir(state.selectedBancada!.id);
          }}
        />
      </Body>
    </>
  );
};

export default Bancadas;
