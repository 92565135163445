import React, { useMemo } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { observer } from 'mobx-react-lite';
// import Formatter from '../../../services/Formatter';
import InputNumero from '../../../components/InputNumero';
import Select from '../../../components/Select';
import { DatePicker } from '@material-ui/pickers';

import { Label, Row, RowCenter, TankListText, TextPrice } from './styles';
import Formatter from '../../../services/Formatter';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface Props extends ModalProps {
  transferencia: Rateio | null;
}
const VisualizarRateio: React.FC<Props> = ({ open, handleClose, transferencia }) => {
  const nomesTanques = useMemo(() => {
    return transferencia && transferencia?.lote_tanques?.length > 0
      ? transferencia?.lote_tanques
          .slice()
          .sort((tanqueA: any, tanqueB: any) => {
            const nomeA = tanqueA.tanque__nome ?? tanqueA.tanque__codigo;
            const nomeB = tanqueB.tanque__nome ?? tanqueB.item.tanque__codigo;
            return nomeA.localeCompare(nomeB);
          })
          .map((tanque: any) => tanque.tanque__nome ?? tanque.tanque__codigo)
          .join(', ')
      : '';
  }, []);

  if (!transferencia) return null;

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>
        Visualizar Rateio
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </ModalHeader>
      <ModalBody style={{ width: '30vw' }}>
        <Row>
          <Select
            value={{
              label: transferencia.categoria.nome ?? '',
              value: transferencia.categoria.nome ?? '',
            }}
            placeholder="Escolha a categoria"
            options={[]}
            disabled
          />
        </Row>
        <Row>
          <Label>Registros de Despesas</Label>
        </Row>
        <Row>
          <DatePicker
            variant="inline"
            views={['year', 'month']}
            label="Selecione o mês/ano"
            value={new Date(transferencia.ano, transferencia.mes - 1)}
            onChange={() => {}}
            autoOk
            disabled
          />
        </Row>
        <InputNumero value={transferencia.dias_mes} precision="0" label="Quantidade de dias" disabled />
        <InputNumero value={transferencia.porcentagem} precision="0" label="Percentual de Rateio" disabled />
        <InputNumero value={transferencia.area} precision="2" label="Área (m²/m³)" disabled />
        <InputNumero value={transferencia.valor} precision="2" label="Valor total do mês (R$)" disabled />
        <InputNumero value={transferencia.valor_area} precision="5" label="Valor por área" disabled />{' '}
        <RowCenter>
          <Label>Valor rateado por dia</Label>
          <TextPrice>R$ {Formatter.formatNumber(5, transferencia.valor_dia_area)}</TextPrice>
        </RowCenter>
        <Row>
          <Label>Tanques</Label>
          <TankListText>{nomesTanques}</TankListText>
        </Row>
        <br />
      </ModalBody>
    </Modal>
  );
};

export default observer(VisualizarRateio);
