import styled from 'styled-components';

export const Categorias = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
`;

export const Categoria = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    width: 120px;
    height: 120px;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }

    img {
      width: 90px;
      height: 90px;
    }
  }

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: flex-end;

    margin-top: 10px;

    color: #059de7;
  }
`;

export const Dados = styled.div`
  margin: 10;
  text-align: center;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const Parcela = styled.div`
  margin: 10;
  width: 100%;
  display: grid;
  gap: 5px;
  grid-template-columns: 143px 140px 1fr 50px;
`;
