import React, { useEffect, useRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable, { Column, QueryResult } from 'material-table';
import { observer, useLocalStore } from 'mobx-react-lite';
import useStore from '../../../services/hooks/useStore';
import { config } from '../../../components/Tabela';
import Header from '../../../components/Header';
import AddBtn from '../../../components/Botoes/AddBtn';
import { Flex } from '../../../components/NavBar/styles';
import { Body } from '../../../components/Body';
import useModal from '../../../services/hooks/useModal';
import CadastrarReservatorio from './CadastrarReservatorio';
import Formatter from '../../../services/Formatter';
import syncModel from '../../../services/models/Sync';
import ActionButton from '../../../components/Tabela/ActionButton';
import Confirmar from '../../../components/Modais/Confirmar';
import X9Logger from '../../../services/X9Logger';

const Reservatorios = () => {
  const store = useStore();
  const tableRef = useRef(null);
  const modalCadastro = useModal();
  const modalExclusao = useModal();

  const state = useLocalStore(() => ({
    pageSize: 50,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
    selectedReservatorio: 0,
    sync: false,
  }));

  const columns: Column<any>[] = [
    {
      field: 'nome',
      title: 'Reservatórios',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'acoes',
      disableClick: true,
      render: (rowData: any) => {
        return (
          <ActionButton
            editar={() => {
              state.selectedReservatorio = rowData.id;
              modalCadastro.open();
            }}
            excluir={() => {
              state.selectedReservatorio = rowData.id;
              modalExclusao.open();
            }}
          />
        );
      },
      ...(true && ({ width: 25 } as object)),
    },
    {
      field: 'tamanho',
      title: 'Tamanho total (m³)',
      render(rowData: any) {
        return Formatter.formatNumber(2, rowData.tamanho);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'volume',
      title: 'Volume útil (lt/m³)',
      render(rowData: any) {
        return Formatter.formatNumber(2, rowData.volume);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'origem',
      title: 'Origem da água',
      render(rowData: any) {
        return rowData.origem?.nome;
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'situacao',
      title: 'Situação',
      render(rowData: any) {
        return rowData.situacao.nome;
      },
      ...(true && ({ width: 180 } as object)),
    },
  ];

  async function buscarDadosParaTabela(page: number): Promise<QueryResult<any>> {
    let data: Reservatorio[] = [];
    let totalCount = 0;

    if (store.propriedade) {
      try {
        await store.reservatorios.getPaginated(page, 25, store.propriedade!.id);

        if (store.reservatorios.totalRes) {
          if (store.reservatorios.totalRes >= 50) {
            state.setPageSize(50);
          } else {
            state.setPageSize(store.reservatorios.totalRes);
          }
        } else {
          state.setPageSize(0);
        }

        data = store.reservatorios.arr ? store.reservatorios.arr : [];
        totalCount = store.reservatorios.totalRes;
      } catch (err) {
        store.notificar('Ocorreu um erro ao adquirir informações. Tente novamente mais tarde!');
      }
    }

    return {
      data,
      page,
      totalCount,
    };
  }

  async function sync(): Promise<void> {
    store.toggleLoader();
    await syncModel.reservatorios();
    if (!store.reservatorios.arr) await Promise.all([store.reservatorios.populate()]);
    state.sync = true;
    store.toggleLoader();
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Reservatórios'), []);

  useEffect(() => {
    if (store.propriedade) {
      sync();
      (tableRef.current as any)?.onQueryChange();
    }
  }, [store.propriedade]);

  async function deletar(): Promise<void> {
    modalExclusao.close();
    store.toggleLoader();

    try {
      await store.reservatorios.excluir(state.selectedReservatorio);
      await (tableRef.current as any)?.onQueryChange();
      store.toggleLoader();
    } catch (err: any) {
      X9Logger.report(err as Error);

      if (err.response && err.response.data) {
        Object.values(err.response.data).forEach((value: any) => {
          store.notificar(`${value}`);
        });
      }

      store.toggleLoader();
    }
  }

  const ExcluirReservatorio: React.FC = () => {
    const reservatorio = store.reservatorios.get(state.selectedReservatorio);

    return (
      <div style={{ width: '100%' }}>
        <b>Nome: </b> {reservatorio.nome}
        <br />
        <br />
        <b>Tamanho total (m³): </b> {reservatorio.tamanho}
        <br />
        <br />
        <b>Volume útil (lt/m³): </b> {reservatorio.volume}
        <br />
        <br />
        <b>Origem da água: </b> {reservatorio.origem?.nome}
        <br />
        <br />
        <b>Situação: </b> {reservatorio.situacao.nome}
        <br />
        <br />
      </div>
    );
  };
  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn
            onClick={() => {
              modalCadastro.open();
            }}
            text="ADICIONAR"
            Icon={AddIcon}
          />
        </Flex>
      </Header>

      <Body>
        <div style={{ overflowX: 'hidden', borderRadius: 10 }}>
          {store.propriedade ? (
            <MaterialTable
              tableRef={tableRef}
              columns={columns}
              data={(query) => buscarDadosParaTabela(query.page)}
              options={{ ...config.options, pageSize: state.pageSize }}
              localization={config.localization}
              style={config.style}
            />
          ) : null}
        </div>

        {modalCadastro.state ? (
          <CadastrarReservatorio
            open={modalCadastro.state}
            handleClose={async () => {
              await (tableRef.current as any)?.onQueryChange();
              state.selectedReservatorio = 0;
              modalCadastro.close();
            }}
            reservatorioID={state.selectedReservatorio}
          />
        ) : null}
        <Confirmar
          open={modalExclusao.state}
          content={<ExcluirReservatorio />}
          title="Tem certeza que deseja excluir?"
          handleClose={() => {
            modalExclusao.close();
          }}
          onCancel={modalExclusao.close}
          onConfirm={deletar}
        />
      </Body>
    </>
  ) : null;
};

export default observer(Reservatorios);
