import React from 'react';
import { PDFContainer } from './styles';
import { Flex } from '../../../components/NavBar/styles';
import MaterialTable, { Column } from 'material-table';
import { newConfig } from '../../../components/Tabela';
import Formatter from '../../../services/Formatter';

type Props = {
  propriedade: string;
  rows: object[][];
};

const RelatorioResumoLotes: React.FC<Props> = ({ propriedade, rows }) => {
  const columns: Column<object>[] = [
    {
      field: 'lote',
      title: 'Lote',
      render: (rowData: any) => {
        return rowData.lote.nome;
      },
    },
    {
      field: 'tanques',
      title: 'Tanques',
      render: (rowData: any) => {
        const tanques: DesempenhoLote['tanques'] = rowData.tanques;
        const string = tanques.join(', ');

        if (string.length > 20) {
          return string.substr(0, 20) + '...';
        } else {
          return string;
        }
      },
    },
    {
      field: 'qtd_vendida',
      title: 'Qtde Vendida',
      type: 'numeric',
      render: ({ qtd_vendida }: any) => {
        return Formatter.formatNumber(0, qtd_vendida);
      },
    },
    {
      field: 'biomassa',
      title: 'Biomassa',
      type: 'numeric',
      render: ({ biomassa }: any) => {
        return Formatter.formatNumber(0, biomassa);
      },
    },
    {
      field: 'vr_venda',
      title: 'Valor Venda (R$)',
      type: 'numeric',
      render: ({ vr_venda }: any) => {
        return Formatter.formatNumber(2, vr_venda);
      },
    },
    {
      field: 'ca',
      title: 'CA',
      type: 'numeric',
      render: ({ ca }: any) => {
        return Formatter.formatNumber(3, ca);
      },
    },
    {
      field: 'gpd',
      title: 'GPD (g)',
      type: 'numeric',
      render: ({ gpd }: any) => {
        return Formatter.formatNumber(3, gpd);
      },
    },
    {
      field: 'custo_geral',
      title: 'Custo Geral (R$)',
      type: 'numeric',
      render: ({ custo_geral }: any) => {
        return Formatter.formatNumber(2, custo_geral);
      },
    },
    {
      field: 'lucro_bruto',
      title: 'Lucro Bruto (R$)',
      type: 'numeric',
      render: ({ lucro_bruto }: any) => {
        return Formatter.formatNumber(2, lucro_bruto);
      },
    },
  ];

  const Tables: React.FC = () => {
    return (
      <>
        {rows.map((tableRows, index) => {
          return (
            <div key={'table-' + index}>
              <MaterialTable
                columns={columns}
                data={tableRows}
                options={{
                  ...newConfig.options,
                  paging: false,
                  maxBodyHeight: 'none',
                  minBodyHeight: 'none',
                  headerStyle: {
                    color: '#000000',
                    fontSize: 12,
                  },
                }}
                localization={newConfig.localization}
                style={{ ...newConfig.style, fontSize: 12 }}
              />
              <br />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <PDFContainer>
      <Flex>
        <img src="/images/aqrelatorios.png" alt="Aquabit Relatórios" />
        <h2>Aquabit - Resumo de Lotes</h2>
      </Flex>

      <br />

      <div
        style={{
          width: '100%',
          display: 'grid',
          justifyContent: 'space-between',
          gridTemplateColumns: '1fr 1fr',
          gap: 10,
        }}
      >
        <p>
          <b>Propriedade:</b> {propriedade}
        </p>
      </div>

      <br />

      <Tables />
    </PDFContainer>
  );
};

export default RelatorioResumoLotes;
