import { apiV2, getThings } from '../utils';
import { observable, computed } from 'mobx';

type Meses = {
  mes: number;
  ano: number;
  total: number;
};
class Vendas {
  @observable arr: Venda[] | null = null;
  @observable meses: Meses[] | null = null;
  @observable totalRes: number = 0;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    this.arr = await getThings<Venda>('/venda/');
  }
  async getPaginated(page: number, pageSize: number, propriedadeId: number, filterURL?: string): Promise<void> {
    const baseUrl = `/venda/?propriedade=${propriedadeId}`;
    const urlArray = [];
    urlArray.push(baseUrl);

    if (filterURL) {
      urlArray.push(filterURL);
    }

    const requestUrl = urlArray.join('');

    apiV2
      .get(requestUrl, {
        params: {
          page_size: pageSize,
          page: page + 1,
          prop: propriedadeId,
        },
      })
      .then((res: any) => {
        this.totalRes = res.data.count;
        this.arr = res.data.results;
      });
  }

  async getMeses(propriedadeId: number): Promise<void> {
    apiV2
      .get('/venda/resultados/', {
        params: {
          prop: propriedadeId,
        },
      })
      .then((res: any) => {
        this.meses = res.data.result;
      });
  }
  async getEverything(params?: object): Promise<Venda[]> {
    return await getThings<Venda>('/venda/', params);
  }

  get(id: number): Venda {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  getByLote(loteID: number): Venda[] {
    return this.arr!.filter((item) => item.lote_tanque_vendas[0].lote_tanque.lote.id === loteID);
  }

  getByCliente(clienteID: number): Venda[] {
    return this.arr!.filter((item) => item.cliente.id === clienteID);
  }

  filter(callback: (item: Venda, index: number, array: Venda[]) => void): Venda[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: Venda, index: number, array: Venda[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default Vendas;
