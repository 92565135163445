import { getThings25 } from '../utils';
import { observable, computed } from 'mobx';

class Biometrias {
  @observable arr: Biometria[] | null = null;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    this.arr = await getThings25<Biometria>('/biometrias/');
  }

  async getEverything(params?: object): Promise<Biometria[]> {
    return await getThings25<Biometria>('/biometrias/', params);
  }


  get(id: number): Biometria {
    
    return this.arr!.filter((item) => item.id === id)[0];
  }

  filter(callback: (item: Biometria, index: number, array: Biometria[]) => void): Biometria[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: Biometria, index: number, array: Biometria[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default Biometrias;
