/* eslint-disable react/display-name */
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import ReactSelect from 'react-select';
import { useLocalStore, observer } from 'mobx-react-lite';
import MaterialTable, { Column, QueryResult } from 'material-table';
import { ApexOptions } from 'apexcharts';
import AddIcon from '@material-ui/icons/Add';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MenuList, MenuItem } from '@material-ui/core';
import Header from '../../../components/Header';
import { Flex } from '../../../components/NavBar/styles';
import AddBtn from '../../../components/Botoes/AddBtn';
import useModal from '../../../services/hooks/useModal';
import useStore from '../../../services/hooks/useStore';
import Filtro from '../../../components/Filtro';
import { Body } from '../../../components/Body';
import { config } from '../../../components/Tabela';
import { api } from '../../../services/utils';
import Display from '../../../components/Tabela/Display';
import { RacaoInfo } from './styles';
import ActionButton from '../../../components/Tabela/ActionButton';
import Confirmar from '../../../components/Modais/Confirmar';
import AjusteEstoque from './AjusteEstoque';
import FormCompra from './FormCompra';
import FormRacao from './FormRacao';
import syncModel from '../../../services/models/Sync';
import Select from '../../../components/Select';
import Formatter from '../../../services/Formatter';
import X9Logger from '../../../services/X9Logger';
import { permissoes } from '../../../services/utils/permissoes';
import ModalCompras from './ModalCompras';

type Row = {
  id: number;
  nome: string;
  fornecedor: string;
  data: string;
  quantidade: number;
  tipo: string;
  ajuste_estoque?: boolean;
  valor_unitario?: number;
  valor_total?: number;
  compraDeRacao?: CompraRacao;
};

type BarChart = {
  series: ApexOptions['series'];
  options: ApexOptions;
};

type State = PageState & {
  filtroRacao: SelectRow | null;
  filtroFornecedor: SelectRow | null;
  filtroDataInicio: MaterialUiPickersDate;
  filtroDataFim: MaterialUiPickersDate;

  selectedRacao: number;
  selectedCompra: Row | null;

  sync: boolean;
};

const Racao: React.FC = () => {
  const colors = ['#049CE7', '#FFE600', '#FE196B', '#465A65'];

  const tableRef = useRef();
  const modalCompra = useModal();
  const modalExclusao = useModal();
  const modalAjuste = useModal();
  const modalRacao = useModal();
  const store = useStore();
  const history = useHistory();

  const modalEditarCompra = useModal();
  const modalExcluirCompra = useModal();
  const modalCriarCompra = useModal();

  store.propriedade && store.validarPermissao(permissoes.CONTROLE_RACAO_COMPRA_LEITURA, history);

  const state = useLocalStore(
    (): State => ({
      pageSize: 0,
      setPageSize(value: number) {
        this.pageSize = value + 1;
      },

      filtroRacao: null,
      filtroFornecedor: null,
      filtroDataInicio: null,
      filtroDataFim: null,

      selectedRacao: 0,
      selectedCompra: null,

      sync: false,
    }),
  );

  const graficoBarra: BarChart = useLocalStore(() => ({
    series: [
      {
        name: 'Estoque',
        data: [10000],
      },
    ],
    options: {
      chart: {
        height: 180,
        type: 'bar',
        id: 'racoes',
        events: {},
        zoom: {
          enabled: false,
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: (val: number) => {
            return Formatter.formatNumber(2, val) + ' kg';
          },
        },
      },
    },
  }));

  const columns: Column<Row>[] = [
    {
      field: 'nome',
      title: 'Ração',
      ...(true && ({ width: 170 } as object)),
    },
    {
      field: 'acoes',
      render: (rowData) => (
        <ActionButton
          editarCompra={
            store.checkPermissions(permissoes.CONTROLE_RACAO_COMPRA_ESCRITA)
              ? () => {
                  state.selectedCompra = rowData;
                  if (rowData.tipo === 'Compras') {
                    // modalCompra.open();
                    modalEditarCompra.open();
                  } else {
                    modalAjuste.open();
                  }
                }
              : undefined
          }
          excluirCompra={
            store.checkPermissions(permissoes.CONTROLE_RACAO_COMPRA_ESCRITA)
              ? () => {
                  state.selectedCompra = rowData;
                  if (rowData.tipo === 'Compras') {
                    modalExcluirCompra.open();
                  } else {
                    modalExclusao.open();
                  }
                }
              : undefined
          }
        />
      ),
      ...(true && ({ width: 25 } as object)),
    },
    {
      field: 'tipo',
      title: 'Tipo',
      render: (rowData) => {
        if (rowData.tipo === 'Compras') {
          return <b>{rowData.tipo}</b>;
        } else {
          return <b style={{ color: '#42a5f5' }}>{rowData.tipo}</b>;
        }
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'fornecedor',
      title: 'Fornecedor',
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'data',
      title: 'Data',
      type: 'date',
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'quantidade',
      title: 'Quantidade (kg)',
      type: 'numeric',
      render: ({ quantidade }) => {
        return Formatter.formatNumber(2, quantidade);
      },
      ...(true && ({ width: 180 } as object)),
    },
    {
      field: 'valor_unitario',
      title: 'Vr. Unidade',
      type: 'numeric',
      render: ({ valor_unitario }) => {
        return Formatter.formatNumber(2, valor_unitario);
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'valor_total',
      title: 'Vr. Total',
      type: 'numeric',
      render: ({ valor_total }) => {
        return Formatter.formatNumber(2, valor_total);
      },
      ...(true && ({ width: 120 } as object)),
    },
  ];

  function rows(comprasDeRacoes: CompraRacao[]): Row[] {
    if (comprasDeRacoes.length) {
      if (comprasDeRacoes.length >= 50) {
        state.setPageSize(50);
      } else {
        state.setPageSize(comprasDeRacoes.length);
      }
    } else {
      state.setPageSize(0);
    }

    return comprasDeRacoes.map((compraDeRacao) => {
      const data = Formatter.getData(compraDeRacao.data);

      if (!compraDeRacao.ajuste_estoque) {
        return {
          id: compraDeRacao.id,
          nome: compraDeRacao.racao.nome,
          fornecedor: compraDeRacao.fornecedor.nome,
          data,
          quantidade: compraDeRacao.total_kg,
          valor_unitario: +((compraDeRacao.valor_compra ?? 0) / (compraDeRacao.total_kg ?? 0)).toFixed(3) || 0,
          valor_total: compraDeRacao.valor_compra || 0,
          tipo: 'Compras',
          ajuste_estoque: compraDeRacao.ajuste_estoque,
          compraDeRacao,
        };
      } else {
        const tipo = compraDeRacao.tipo === 'entrada' ? 'Entrada' : 'Saída';

        return {
          id: compraDeRacao.id,
          nome: compraDeRacao.racao.nome,
          data,
          fornecedor: 'Sem fornecedor',
          quantidade: compraDeRacao.total_kg,
          tipo: 'Ajuste - ' + tipo,
        };
      }
    });
  }

  async function buscarDados(page: number): Promise<QueryResult<Row>> {
    let data = rows([]);
    let totalCount = 0;
    if (store.propriedade) {
      try {
        const res = await api.get('/compra-racao/', {
          params: {
            propriedade: store.propriedade?.id,
            page_size: 50,
            page: page + 1,
            fornecedor: state.filtroFornecedor?.value,
            racao: state.filtroRacao?.value,
            inicio: state.filtroDataInicio ? Formatter.dateToString(state.filtroDataInicio, '-') : null,
            fim: state.filtroDataFim ? Formatter.dateToString(state.filtroDataFim, '-') : null,
          },
        });

        if (res.data.count) {
          if (res.data.count >= 50) {
            state.setPageSize(50);
          } else {
            state.setPageSize(res.data.count);
          }
        } else {
          state.setPageSize(0);
        }

        store.comprasDeRacoes.arr = res.data.results;
        data = rows(res.data.results);
        totalCount = res.data.count;
      } catch (err) {
        X9Logger.report(err as Error);
        store.notificar('Ocorreu um erro ao adquirir informações. Tente novamente mais tarde!');
      }
    }

    return {
      data,
      page,
      totalCount,
    };
  }

  async function recarregarTabela() {
    await (tableRef.current as any).onQueryChange();
  }

  async function sync() {
    await syncModel.compraDeRacoes();
    state.sync = true;
  }

  async function filtrar() {
    store.toggleLoader();
    if (state.filtroFornecedor || state.filtroRacao || state.filtroDataInicio || state.filtroDataFim) {
      await recarregarTabela();
    } else {
      await sync();
      await recarregarTabela();
    }
    store.toggleLoader();
  }

  async function limparFiltro() {
    state.filtroRacao = null;
    state.filtroFornecedor = null;
    state.filtroDataInicio = null;
    state.filtroDataFim = null;
    await recarregarTabela();
  }

  async function deletar() {
    modalExclusao.close();
    store.toggleLoader();
    if (state.selectedCompra?.id) {
      await store.comprasDeRacoes.excluir(state.selectedCompra.id);
      await recarregarTabela();
      await sync();
    }
    store.toggleLoader();
  }

  function getAjuste(): CompraRacao | undefined {
    const ajuste = store.comprasDeRacoes?.get(state.selectedCompra?.id || 0);
    if (ajuste?.ajuste_estoque) {
      return ajuste;
    } else {
      return undefined;
    }
  }

  useEffect(() => {
    if (state.filtroRacao) recarregarTabela();
    // eslint-disable-next-line
  }, [state.filtroRacao]);

  useEffect(() => {
    if (state.sync) {
      ApexCharts.exec('racoes', 'updateSeries', [
        {
          data: store.racoes?.map((racao) => {
            const estoque = racao.estoque < 0 ? 0 : racao.estoque;
            return { x: racao.nome, y: estoque };
          }),
        },
      ]);
    }
    // eslint-disable-next-line
  }, [state.sync, store.racoes.arr]);

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Rações'), []);

  useEffect(() => {
    if (store.propriedade) {
      filtrar();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  const ExcluirRegistro: React.FC = () => {
    return (
      <div style={{ width: '100%' }}>
        <b>Tipo:</b>{' '}
        {state.selectedCompra && state.selectedCompra?.ajuste_estoque ? 'Ajuste de Estoque' : 'Compra de Ração'}
        <br />
        <b>Ração: </b> {state.selectedCompra && state.selectedCompra?.nome}
        <br />
        <b>Quantidade: </b> {state.selectedCompra && Formatter.formatNumber(2, state.selectedCompra?.quantidade)} kg
        <br />
        <b>Data: </b> {state.selectedCompra && Formatter.getData(state.selectedCompra.data)}
        <br />
        <b>Fornecedor:</b>{' '}
        {state.selectedCompra && state.selectedCompra?.ajuste_estoque
          ? 'Sem Fornecedor'
          : state.selectedCompra?.fornecedor}
      </div>
    );
  };

  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn
            disabled={!store.checkPermissions(permissoes.CONTROLE_RACAO_COMPRA_ESCRITA)}
            dropdown
            onClick={() => {
              state.selectedCompra = null;
              // modalCompra.open();
              modalCriarCompra.open();
            }}
            text="NOVA COMPRA"
            Icon={AddIcon}
          >
            <MenuList>
              <MenuItem
                onClick={() => {
                  state.selectedRacao = 0;
                  modalRacao.open();
                }}
              >
                Nova ração
              </MenuItem>
              <MenuItem onClick={modalAjuste.open}>Ajuste de estoque</MenuItem>
              <MenuItem
                onClick={() => {
                  state.selectedCompra = null;
                  modalCompra.open();
                }}
              >
                Nova compra
              </MenuItem>
            </MenuList>
          </AddBtn>

          <div style={{ width: 200 }}>
            <ReactSelect
              value={state.filtroRacao}
              classNamePrefix="sel"
              placeholder="Ração"
              onChange={(e: any) => (state.filtroRacao = e)}
              options={store.racoes.getSelectRows()}
            />
          </div>
        </Flex>
        <Flex>
          <Filtro sync={filtrar} clear={limparFiltro}>
            <div style={{ width: '90%', margin: 10 }}>
              <Select
                value={state.filtroRacao}
                placeholder="Ração"
                onChange={(e: any) => (state.filtroRacao = e)}
                options={store.racoes.getSelectRows()}
              />
              <Select
                value={state.filtroFornecedor}
                placeholder="Fornecedor"
                onChange={(e: any) => (state.filtroFornecedor = e)}
                options={store.fornecedores!.getSelectRows()}
              />
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data Início"
                value={state.filtroDataInicio}
                onChange={(date) => (state.filtroDataInicio = date)}
              />
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Data Fim"
                value={state.filtroDataFim}
                onChange={(date) => (state.filtroDataFim = date)}
              />
            </div>
          </Filtro>
        </Flex>
      </Header>

      <Body gridColumns="1fr 500px">
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          {store.comprasDeRacoes.arr && (
            <MaterialTable
              tableRef={tableRef}
              columns={columns}
              data={(query) => new Promise((resolve) => resolve(buscarDados(query.page)))}
              options={{ ...config.options, pageSize: 50, maxBodyHeight: window.innerHeight - 170 }}
              localization={config.localization}
              style={config.style}
            />
          )}
        </div>
        <div>
          <Display style={{ marginBottom: '10px', height: 220 }} overflowY={false} title="Estoque de Ração">
            <ReactApexChart height={150} options={graficoBarra.options} series={graficoBarra.series} type="bar" />
          </Display>
          <Display
            add={() => {
              state.selectedRacao = 0;
              modalRacao.open();
            }}
            style={{ marginBottom: '10px', height: '55vh' }}
            overflowY
            title="Minhas rações"
          >
            {store.racoes?.map((racao) => {
              const ultimaCompra = store.comprasDeRacoes?.filter(
                (compra) => compra.racao.id === racao.id && compra.ajuste_estoque === false,
              )[0];

              return (
                <RacaoInfo
                  key={racao.id}
                  onClick={() => {
                    state.selectedRacao = racao.id;
                    modalRacao.open();
                  }}
                >
                  <p className="left">{racao.nome}</p>
                  <p className="right">
                    R$/kg{' '}
                    {ultimaCompra
                      ? Formatter.formatNumber(2, ultimaCompra.movimentacao.valor_total / ultimaCompra.total_kg)
                      : 0}
                  </p>
                  <p className="left">{ultimaCompra ? ultimaCompra.fornecedor.nome : 'Sem fornecedor'}</p>
                  <p className="right">
                    Estoque Atual: {Formatter.formatNumber(2, racao.estoque < 0 ? 0 : racao.estoque)} kg
                  </p>
                </RacaoInfo>
              );
            })}
          </Display>
        </div>
      </Body>
      {modalCompra.state ? (
        <FormCompra
          open={modalCompra.state}
          handleClose={modalCompra.close}
          handleUpdate={recarregarTabela}
          compraID={state.selectedCompra?.id || -1}
        />
      ) : null}
      {modalAjuste.state ? (
        <AjusteEstoque open={modalAjuste.state} handleClose={modalAjuste.close} ajuste={getAjuste()} />
      ) : null}
      {modalRacao.state ? (
        <FormRacao open={modalRacao.state} handleClose={modalRacao.close} racaoID={state.selectedRacao} />
      ) : null}

      {modalEditarCompra.state && state.selectedCompra?.compraDeRacao?.movimentacao && (
        <ModalCompras
          open={modalEditarCompra.state}
          compra={state.selectedCompra?.compraDeRacao}
          handleClose={() => modalEditarCompra.close()}
          onConfirm={recarregarTabela}
        />
      )}

      {modalExcluirCompra.state && state.selectedCompra?.compraDeRacao?.movimentacao && (
        <ModalCompras
          open={modalExcluirCompra.state}
          compra={state.selectedCompra?.compraDeRacao}
          handleClose={() => modalExcluirCompra.close()}
          onDelete={() => {
            deletar();
            recarregarTabela();
          }}
        />
      )}

      {modalCriarCompra.state && (
        <ModalCompras
          open={modalCriarCompra.state}
          handleClose={() => modalCriarCompra.close()}
          onConfirm={recarregarTabela}
        />
      )}

      <Confirmar
        open={modalExclusao.state}
        content={<ExcluirRegistro />}
        title="Tem certeza que deseja excluir?"
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={deletar}
      />
    </>
  ) : null;
};

export default observer(Racao);
