// import { useState } from 'react';
import { CompraInsumo } from '../../../../@types/Insumo';
import { toJS } from 'mobx';

interface UltimaDataValor {
  ultimaData: string;
  ultimoValor: number;
}

interface InsumoAnalisado {
  insumoId: number;
  insumoUltimaDataValor: UltimaDataValor;
}

const dataValorUltimaCompra = (store: any): InsumoAnalisado[] => {
  const compras = store.comprasDeInsumos.comprasDeInsumos;

  const insumosAnalisados = obterInsumos(toJS(compras));

  return insumosAnalisados;
};

export default dataValorUltimaCompra;

// -------------------------------------------------------

const obterInsumos = (compras: CompraInsumo[]): InsumoAnalisado[] => {
  const listaInsumos: InsumoAnalisado[] = [];

  compras.forEach(async (compra: CompraInsumo) => {
    let insumoRepetido: any = [];

    insumoRepetido = listaInsumos.find((insumo) => {
      return insumo.insumoId === compra.insumo.id;
    });

    if (insumoRepetido === undefined) {
      listaInsumos.push({
        insumoId: compra.insumo.id,
        insumoUltimaDataValor: {
          ultimaData: compra.data,
          ultimoValor: compra.valor_compra,
        },
      });
    } else {
      if (
        (await dataEmMilisegundos(insumoRepetido.insumoUltimaDataValor.ultimaData)) >
        (await dataEmMilisegundos(compra.data))
      ) {
        const local = listaInsumos.indexOf(insumoRepetido);

        listaInsumos[local] = {
          insumoId: insumoRepetido.insumoId,
          insumoUltimaDataValor: {
            ultimaData: compra.data,
            ultimoValor: compra.valor_compra,
          },
        };
      }
    }
  });
  return listaInsumos;
};

const dataEmMilisegundos = (dataString: string): number => {
  const dataSplit = dataString.split('/');
  const dia = dataSplit[0];
  const mes = dataSplit[1];
  const dataSplit2 = dataSplit[2].split(' ');
  const ano = dataSplit2[0];
  const horas = dataSplit2[1];
  const horasArray = horas.split(':');

  const novaData = [];
  novaData.push(mes);
  novaData.push(dia);
  novaData.push(ano);

  const dataFormato = new Date(novaData.join('-'));
  dataFormato.setHours(parseInt(horasArray[0]));
  dataFormato.setMinutes(parseInt(horasArray[1]));
  dataFormato.setSeconds(parseInt(horasArray[2]));

  return dataFormato.getTime();
};
