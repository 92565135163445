import React, { useEffect } from 'react';
import useStore from '../../../services/hooks/useStore';
import Select from '../../../components/Select';
import { ListItem, Collapse, FormControlLabel, Checkbox } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { returnTanque } from '../../../services/utils/propriedade';
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import InputNumero from '../../../components/InputNumero';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { Dados } from './styles';
import { useLocalStore, observer } from 'mobx-react-lite';
import Formatter from '../../../services/Formatter';

type Props = ModalProps & {
  venda: Venda;
};

const VisualizarVenda: React.FC<Props> = ({ open, handleClose, venda }) => {
  const store = useStore();
  const categoriaID = venda.movimentacao?.categoria?.id || venda.categoria?.id || 0;

  const state = useLocalStore(() => ({
    collapse: false,
    sync: false,
    biomassa: 0,
    quantidade: 0,

    valorTotal() {
      if (venda.movimentacao) {
        if (categoriaID === 2) {
          return venda.movimentacao?.valor_unitario * this.quantidade;
        } else {
          return venda.movimentacao?.valor_unitario * this.biomassa;
        }
      } else return 0;
    },
  }));

  useEffect(() => {
    if (venda) {
      venda.lote_tanque_vendas.map((loteTanque: any) => {
        state.quantidade += loteTanque.quantidade;
        state.biomassa += loteTanque.biomassa / 1000;
        return state.biomassa;
      });
    }
    // eslint-disable-next-line
  }, [venda]);

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Detalhes da venda</ModalHeader>
      <ModalBody>
        {venda && (
          <div style={{ width: '100%' }}>
            <div style={{ width: '100%', marginTop: 10 }}>
              <Select
                disabled
                placeholder="Lote"
                value={{
                  label: venda.lote_tanque_vendas[0].lote_tanque.lote.codigo,
                  value: '0',
                }}
                options={[]}
              />
            </div>
            <div style={{ width: '100%', marginTop: 10 }}>
              <Select
                disabled
                placeholder="Cliente"
                value={{
                  label: venda.cliente.nome,
                  value: '0',
                }}
                options={[]}
              />
            </div>
            <div style={{ width: '100%', marginTop: 10 }}>
              <Select
                disabled
                placeholder="Categoria"
                value={{
                  label: store.categorias.get(categoriaID)?.nome || '',
                  value: '0',
                }}
                options={[]}
              />
            </div>

            <ListItem
              style={{ justifyContent: 'center', marginTop: 10, marginBottom: state.collapse ? -10 : 10 }}
              button
              onClick={() => (state.collapse = !state.collapse)}
            >
              <b>Tanques Selecionados</b>
              {state.collapse ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={state.collapse}>
              {venda.lote_tanque_vendas.map((item: any) => {
                item.lote_tanque.tanque.id;
                return (
                  <div
                    key={item.id}
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '10px',
                      margin: '10px 0',
                      borderRadius: '5px',
                      border: '1px solid #56C323BF',
                      borderBottom: 'none',
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <b className="left">{returnTanque(item.lote_tanque.tanque)}</b>
                      <p>{Formatter.formatNumber(0, item.quantidade)} un</p>
                      <p className="right" style={{ color: '#00C853' }}>
                        {Formatter.formatNumber(
                          3,
                          item.quantidade ? store.tanques.get(item.lote_tanque.tanque.id).biomassa / 1000 : 0,
                        )}{' '}
                        Kg
                      </p>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <b className="left" style={{ color: '#C80000' }}>
                        Nova venda
                      </b>
                      <p style={{ color: '#C80000' }}>+{Formatter.formatNumber(0, item.quantidade)} un</p>

                      <p className="right" style={{ color: '#C80000' }}>
                        {Formatter.formatNumber(3, item.biomassa / 1000)} kg
                      </p>
                    </div>
                  </div>
                );
              })}
            </Collapse>

            <KeyboardDatePicker
              autoOk
              disableToolbar
              disabled
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Data"
              onChange={() => null}
              value={new Date(venda.data.substr(3, 2) + '/' + venda.data.substr(0, 2) + '/' + venda.data.substr(6, 4))}
            />

            <InputNumero disabled value={state.biomassa} precision="2" label="Biomassa para venda (kg)" />
            <InputNumero
              disabled
              value={state.biomassa / state.quantidade}
              precision="3"
              label="Peso Médio Vendido (kg)"
            />

            <InputNumero disabled value={venda.valor_por_quilo} precision="2" label="Valor Unitário (R$)" />

            <Dados>
              <div>
                <h2>Valor total</h2>
                <h1 style={{ color: '#42a5f5' }}>R$ {Formatter.formatNumber(2, venda.valor_total)}</h1>
              </div>
              <div>
                <h2>Quantidade</h2>
                <h1 style={{ color: '#42a5f5' }}>{Formatter.formatNumber(0, state.quantidade)}</h1>
              </div>
            </Dados>

            <FormControlLabel
              control={<Checkbox checked={!!venda.movimentacao} disabled color="primary" />}
              label="Gerar financeiro"
            />

            <KeyboardDateTimePicker
              disabled
              autoOk
              variant="inline"
              margin="normal"
              ampm={false}
              label="Data da entrega"
              value={
                new Date(
                  venda.data_entrega.substr(3, 2) +
                    '/' +
                    venda.data_entrega.substr(0, 2) +
                    '/' +
                    venda.data_entrega.substr(6, 4),
                )
              }
              onChange={() => null}
              onError={console.log}
              format="dd/MM/yyyy HH:mm"
            />
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default observer(VisualizarVenda);
