import React, { useEffect } from 'react';
import useStore from '../../../services/hooks/useStore';
import { useLocalStore, observer } from 'mobx-react-lite';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import Botao from '../../../components/Botoes/Botao';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Flex } from '../../../components/NavBar/styles';
import { TextField } from '@material-ui/core';
import InputNumero from '../../../components/InputNumero';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Select from '../../../components/Select';
import Formatter from '../../../services/Formatter';
import syncModel from '../../../services/models/Sync';

type Props = ModalProps & {
  entrada: Movimentacao;
  onConfirm(): void;
};
type State = {
  descricao: string;
  valor: number;
  data: MaterialUiPickersDate;
  documento: string;
  categoria: SelectRow | null;
  conta: SelectRow;
  recebido: boolean;
  sync: boolean;
};
const FormOutras: React.FC<Props> = ({ open, handleClose, entrada, onConfirm }) => {
  const store = useStore();
  const state = useLocalStore(
    (): State => ({
      sync: false,
      descricao: entrada.descricao || '',
      valor: Object.keys(entrada).length > 0 ? entrada.valor_total : 0,
      data:
        Object.keys(entrada).length > 0
          ? new Date(entrada.data.substr(3, 2) + '/' + entrada.data.substr(0, 2) + '/' + entrada.data.substr(6, 4))
          : new Date(),
      documento: entrada.documento || '',
      categoria:
        Object.keys(entrada).length > 0
          ? {
              label: entrada.categoria.nome,
              value: entrada.categoria.id.toString(),
            }
          : null,
      conta:
        Object.keys(entrada).length > 0
          ? {
              label: entrada.conta.titulo,
              value: entrada.conta.id.toString(),
            }
          : {
              label: store.contas.getContaPadrao()?.titulo || '',
              value: store.contas.getContaPadrao()?.id.toString(),
            },
      recebido: true,
    }),
  );

  async function cadastrar(): Promise<void> {
    const conta = store.contas.get(parseInt(state.conta.value));

    if (!conta) return;

    let dataConta;

    if (conta.data_inicio) {
      dataConta = new Date(
        conta.data_inicio!.substr(3, 2) + '/' + conta.data_inicio!.substr(0, 2) + '/' + conta.data_inicio!.substr(6, 4),
      );
    } else {
      dataConta = new Date(
        conta.created_at!.substr(3, 2) + '/' + conta.created_at!.substr(0, 2) + '/' + conta.created_at!.substr(6, 4),
      );
    }

    if (!state.descricao) store.notificar('Descreva a entrada');
    else if (!state.valor) store.notificar('Informe o valor total');
    else if (!state.categoria) store.notificar('Informe a categoria');
    else if (state.data!.getTime() < dataConta.getTime())
      store.notificar('A data da moviemntação não pode ser anterior a da criação da conta');
    else {
      handleClose();
      store.toggleLoader();

      const data = {
        propriedade: store.propriedade!.id,
        documento: state.documento,
        data: Formatter.dateToString(state.data),
        valor_total: state.valor,
        descricao: state.descricao,
        conta: parseInt(state.conta.value),
        categoria: parseInt(state.categoria.value),
        situacao: state.recebido,
        lote_tanque_movimentacao: [],
        parcelas:
          Object.keys(entrada).length > 0 && entrada.id !== 0
            ? undefined
            : [
                {
                  num_parcela: 1,
                  data_pagamento: Formatter.dateToString(state.data),
                  recebido: state.recebido,
                  pagamento_tipo: 1,
                  valor: state.valor,
                  conta: parseInt(state.conta.value),
                },
              ],
      };

      if (Object.keys(entrada).length > 0) {
        await store.movimentacoes.editarEntrada(entrada.id, state.recebido, data);
        onConfirm();
      } else {
        await store.movimentacoes.criar(data);
        onConfirm();
      }

      store.toggleLoader();
    }
  }

  async function sync(): Promise<void> {
    await syncModel.movimentacoesModal();

    state.sync = true;
  }

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  return state.sync ? (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader cor="#9e9e9e">{Object.keys(entrada).length > 0 ? 'Editar' : 'Nova'} entrada</ModalHeader>
      <ModalBody style={{ width: 550 }}>
        <div style={{ width: '100%' }}>
          <Select
            disabled={Object.keys(entrada).length > 0 && entrada.situacao}
            placeholder="Categoria"
            value={state.categoria}
            onChange={(e: any) => (state.categoria = e)}
            options={store.categorias.getByTipo('outras').map((categoria) => {
              return { label: categoria.nome, value: categoria.id.toString() };
            })}
          />

          <Select
            disabled={Object.keys(entrada).length > 0 && entrada.situacao}
            placeholder="Conta"
            onChange={(e: any) => (state.conta = e)}
            value={state.conta}
            options={store.contas.getSelectRows()}
          />

          <TextField value={state.descricao} onChange={(e) => (state.descricao = e.target.value)} label="Descrição" />

          <InputNumero
            disabled={Object.keys(entrada).length > 0 && entrada.situacao}
            value={state.valor}
            onChange={(value) => (state.valor = value)}
            precision="2"
            label="Valor (R$)"
            allowNegative
          />
          <KeyboardDatePicker
            disabled={Object.keys(entrada).length > 0 && entrada.situacao}
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data"
            value={state.data}
            onChange={(date) => (state.data = date)}
          />

          <TextField
            disabled={Object.keys(entrada).length > 0 && entrada.situacao}
            value={state.documento}
            inputProps={{ maxLength: 30 }}
            onChange={(e) => (state.documento = e.target.value)}
            label="Documento"
          />

          <Flex>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao onClick={cadastrar} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          </Flex>
        </div>
      </ModalBody>
    </Modal>
  ) : null;
};

export default observer(FormOutras);
