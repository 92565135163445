import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { Modal, ModalHeader, ModalBody, Divisor } from '../../../components/Modais/styles';
import { apiV2 } from '../../../services/utils';
import Botao from '../../../components/Botoes/Botao';
import useStore from '../../../services/hooks/useStore';
import { useLocalStore, observer } from 'mobx-react-lite';
import InputNumero from '../../../components/InputNumero';
import useModal from '../../../services/hooks/useModal';
import Select from '../../../components/Select';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import add from 'date-fns/add';
import Formatter from '../../../services/Formatter';
import SelecionarFornecedor from '../Povoamento/SelecionarFornecedor';
import { TanqueItem } from '../Povoamento/styles';

type Props = ModalProps & {
  plantio: any;
};

type State = {
  codigo: string;
  especie: SelectRow | null;
  fornecedor: SelectRow | null;
  fase: SelectRow | null;
  data: MaterialUiPickersDate;
  valor: number;
  pesoMedio: number;
  pesoMedioEsperado: number;
  tamanho: number;
  tanques: SelectedTanque[];
  diasPrevistos: number;

  previsao: number;
  dataPrevista: MaterialUiPickersDate;
  sync: boolean;
  tanque: Lote;
};

const EditarPlantio: React.FC<Props> = ({ open, handleClose, plantio }) => {
  const store = useStore();
  const modalFornecedor = useModal();

  const state = useLocalStore(
    (): State => ({
      codigo: plantio.codigo || '',
      especie: plantio.especie_explorada
        ? { label: plantio.especie_explorada.nome, value: plantio.especie_explorada.id.toString() }
        : null,
      fornecedor: plantio.fornecedor
        ? { label: plantio.fornecedor.nome, value: plantio.fornecedor.id.toString() }
        : null,
      fase: plantio.fase_lote ? { label: plantio.fase_lote.nome, value: plantio.fase_lote.id.toString() } : null,
      data: new Date(plantio.data_alojamento),
      valor: plantio.valor,
      pesoMedio: plantio.peso_medio || 0,
      pesoMedioEsperado: plantio.pm_esperado || 0,
      tamanho: plantio.tamanho_medio || 0,
      tanques: plantio.lote_tanque_lote || [],
      diasPrevistos: plantio.dias_previstos || 0,
      dataPrevista: null,
      previsao: plantio.previsao ? plantio.previsao : 0,
      sync: false,
      tanque: {} as Lote,
    }),
  );

  async function editar(): Promise<void> {
    if (!state.fase) store.notificar('Selecione a fase do plantio');
    else if (state.pesoMedio === 0) store.notificar('Informe o peso médio do plantio');
    else if (state.pesoMedioEsperado === 0) store.notificar('Informe o peso médio esperado do plantio');
    else {
      store.toggleLoader();

      const dataEditada = {
        codigo: state.codigo,
        data_prevista: Formatter.dateToString(state.dataPrevista),
        fase_lote: store.fasesLote.get(parseInt(state.fase.value))?.id,
        dias_previstos: Number(state.previsao),
        peso_medio: Number(state.pesoMedio),
        pm_esperado: Number(state.pesoMedioEsperado),
      };

      await apiV2
        .patch(`/lote/${plantio.id}/?prop=${store.propriedade!.id}`, dataEditada)
        .then(() => store.notificar('Plantio editado com sucesso!'))
        .catch(() => store.notificar('Ocorreu um erro ao editar, tente novamente'));

      await Promise.all([store.lotes.populate(), store.loteTanque.populate()]);

      store.toggleLoader();
      handleClose();
    }
  }

  const BancadasSelecionadas: React.FC = () => (
    <>
      {state.tanques.length > 0 &&
        state.tanques.map((tanque: any) => (
          <TanqueItem key={tanque.id}>
            <p>{tanque.nome || tanque.codigo}</p>
            <p>{Formatter.formatNumber(0, tanque.quantidade)}</p>
          </TanqueItem>
        ))}
    </>
  );
  async function sync(): Promise<void> {
    store.toggleLoader();

    await store.lotes.populate();

    await apiV2
      .get(`/lote/${plantio.id}/`, {
        params: {
          prop: store.propriedade!.id,
        },
      })
      .then((res) => {
        state.tanque = res.data;
        state.codigo = state.tanque.nome || state.tanque.codigo;
        state.especie = state.tanque.especie_explorada
          ? { label: state.tanque.especie_explorada.nome, value: state.tanque.especie_explorada.id.toString() }
          : { label: '', value: '' };
        state.fornecedor = { label: state.tanque.fornecedor.nome, value: state.tanque.fornecedor.id.toString() };
        state.fase = { label: state.tanque.fase_lote.nome, value: state.tanque.fase_lote.id.toString() };
        state.valor = state.tanque.valor_total;
        state.pesoMedio = state.tanque.peso_medio;
        state.tamanho = state.tanque.tamanho_medio ? state.tanque.tamanho_medio : 0;
        state.data = new Date(
          state.tanque.data.substr(3, 2) + '/' + state.tanque.data.substr(0, 2) + '/' + state.tanque.data.substr(6, 4),
        );
        state.previsao = state.tanque.dias_previstos ? state.tanque.dias_previstos : 0;

        state.dataPrevista = add(state.data!, { days: state.previsao });
      });

    store.toggleLoader();
    state.sync = true;
  }

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  return state.sync ? (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Editar Plantio</ModalHeader>
      <ModalBody>
        <TextField label="Lote do Plantio" value={state.codigo} onChange={(e) => (state.codigo = e.target.value)} />
        <div style={{ width: '100%', marginTop: 10 }}>
          {store.especiesAquaponia.arr && (
            <Select
              disabled
              value={state.especie}
              placeholder="Espécie"
              onChange={(e: any) => (state.especie = e)}
              options={store.especiesAquaponia.getSelectRows()}
            />
          )}
        </div>
        <div style={{ width: '100%', marginTop: 10 }}>
          {store.fornecedores.arr && (
            <Select
              disabled
              value={state.fornecedor}
              placeholder="Fornecedor"
              onChange={(e: any) => (state.fornecedor = e)}
              options={store.fornecedores?.getSelectRows()}
              onFocus={(e) => {
                e.target.blur();
                modalFornecedor.open();
              }}
            />
          )}
        </div>
        <KeyboardDatePicker
          disabled
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data"
          value={state.data}
          onChange={(date) => {
            state.data = date;
            state.dataPrevista = add(state.data!, { days: state.diasPrevistos });
          }}
        />
        <div style={{ width: '100%' }}>
          <h2>Bancadas:</h2>
          <BancadasSelecionadas />
        </div>
        <div style={{ width: '100%' }}>
          <Select
            value={state.fase}
            placeholder="Fase"
            onChange={(e: any) => (state.fase = e)}
            options={store.fasesLote.getSelectRows()}
          />
        </div>
        <InputNumero
          disabled
          value={state.valor}
          onChange={(value) => (state.valor = value)}
          precision="2"
          label="Valor do plantio (R$)"
        />
        <InputNumero
          value={state.pesoMedio}
          onChange={(value) => (state.pesoMedio = value)}
          precision="3"
          label="Peso Médio (g)"
        />

        <Divisor>
          <p>Previsão de Colheita</p>
        </Divisor>
        <InputNumero
          value={state.pesoMedioEsperado}
          onChange={(value) => (state.pesoMedioEsperado = value)}
          precision="3"
          label="Peso Médio Esperado (g)"
        />
        <TextField
          value={state.previsao}
          onChange={(e: any) => {
            state.previsao = parseInt(e.target.value);
            state.dataPrevista = add(state.data!, { days: parseInt(e.target.value) });
          }}
          type="number"
          label="Dias Previstos"
          inputProps={{ min: 0 }}
        />

        <div style={{ margin: 10, textAlign: 'center' }}>
          <h2>Data Prevista</h2>
          <h1 style={{ color: '#42a5f5' }}>
            {state.dataPrevista ? Formatter.dateToString(state.dataPrevista, '/') : 'Sem previsão'}
          </h1>
        </div>

        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao onClick={editar} variant="contained" cor="#00C853">
            Salvar
          </Botao>
        </div>
      </ModalBody>
      <SelecionarFornecedor
        onConfirm={(fornecedor: SelectRow) => {
          state.fornecedor = fornecedor;
          modalFornecedor.close();
        }}
        open={modalFornecedor.state}
        handleClose={modalFornecedor.close}
      />
    </Modal>
  ) : null;
};

export default observer(EditarPlantio);
