/* eslint-disable react/display-name */
import React, { useEffect, useRef } from 'react';
import { Flex } from '../../../components/NavBar/styles';
import { NewBody, Conteudo } from '../../../components/Body';
import { IconButton, Tooltip } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import useStore from '../../../services/hooks/useStore';
import { Link, useHistory } from 'react-router-dom';
import { useLocalStore, observer } from 'mobx-react-lite';
import MaterialTable, { Column } from 'material-table';
import { newConfig } from '../../../components/Tabela';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { aqReports } from '../../../services/utils';
import X9Logger from '../../../services/X9Logger';
import { permissoes } from '../../../services/utils/permissoes';

const DesempenhoTanques: React.FC = () => {
  const store = useStore();
  const tableRef = useRef(null);
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.PAINEL_ZOOTECNICO_LEITURA, history);

  const state = useLocalStore(() => ({
    pageSize: 0,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
  }));

  async function sync(): Promise<void> {
    const tabela: any = tableRef.current;
    tabela.onQueryChange();
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Produção'), []);

  const columns: Column<object>[] = [
    {
      field: 'tanque',
      title: 'Tanque',
      render: (rowData: any) => {
        return rowData.tanque.nome;
      },
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'lote',
      title: 'Lote',
      render: (rowData: any) => {
        const lote: DesempenhoTanque['lote'] = rowData.lote;

        if (lote.nome.length > 20) {
          return lote.nome.substr(0, 20) + '...';
        } else {
          return lote.nome;
        }
      },
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'tamanho',
      title: 'Tamanho (m²/m³)',
      ...(true && ({ width: 200 } as object)),
    },
    {
      field: 'fase',
      title: 'Fase',
      render: ({ fase }: any) => {
        const color = fase === 'FINALIZADO' ? 'green' : 'black';
        return <span style={{ color: color }}>{fase}</span>;
      },
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'data_inicio',
      title: 'Data Início',
      type: 'date',
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'data_atual',
      title: 'Data Atual',
      type: 'date',
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'ciclo',
      title: 'Ciclo (D)',
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'qtd_animais_inicial',
      title: 'Qtd Animais Inicial',
      ...(true && ({ width: 200 } as object)),
    },
    {
      field: 'qtd_animais_atual',
      title: 'Qtd Animais Atual',
      ...(true && ({ width: 200 } as object)),
    },
    {
      field: 'qtd_mortalidade',
      title: 'Qtd Mortalidade',
      ...(true && ({ width: 200 } as object)),
    },
    {
      field: 'qtd_transferida',
      title: 'Qtd Transferida',
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'qtd_vendida',
      title: 'Qtd Vendida (kg)',
      ...(true && ({ width: 230 } as object)),
    },
    {
      field: 'pm_inicial',
      title: 'PM Inicial (g)',
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'pm_atual',
      title: 'PM Atual (g)',
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'gp',
      title: 'GP (g)',
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'bg',
      title: 'BG (kg)',
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'be',
      title: 'BE (kg)',
      ...(true && ({ width: 150 } as object)),
    },
    {
      field: 'racao_consumida',
      title: 'Ração Consumida (kg)',
      ...(true && ({ width: 250 } as object)),
    },
    {
      field: 'ca',
      title: 'CA',
      ...(true && ({ width: 250 } as object)),
    },
    {
      field: 'gpd',
      title: 'GPD (g)',
      ...(true && ({ width: 250 } as object)),
    },
    {
      field: 'de',
      title: 'DE (kg/m² ou m³)',
      ...(true && ({ width: 250 } as object)),
    },
    {
      field: 'sv',
      title: 'SV',
      render: (rowData: any) => {
        return rowData.sv + ' %';
      },
      ...(true && ({ width: 150 } as object)),
    },
  ];

  return (
    <NewBody>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Link
          to={'/relatorios/relatorios-zootecnicos'}
          style={{ color: '#049CE7', textDecoration: 'none', fontFamily: 'Roboto' }}
        >
          <Flex>
            <img src={'/images/returnArrow.svg'} />
            <div style={{ paddingLeft: '5px', fontFamily: 'Roboto', color: '#049CE7' }}>Lista de Relatórios</div>
          </Flex>
        </Link>
        <div>
          <Tooltip title="Exportar para Excel">
            <IconButton className="blue" onClick={() => store.notificar('Em Breve')}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Baixar PDF">
            <IconButton className="blue" onClick={() => store.notificar('Em Breve')}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sincronizar">
            <IconButton className="blue" onClick={sync}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Flex>

      <Conteudo style={{ padding: 0 }}>
        {store.propriedade && (
          <MaterialTable
            tableRef={tableRef}
            columns={columns}
            data={(query) =>
              new Promise((resolve, reject) => {
                aqReports
                  .get('/relatorio/desempenho/' + store.propriedade?.uuid + '/tanques', {
                    params: {
                      page: query.page + 1,
                    },
                  })
                  .then((res) => {
                    if (res.data.count) {
                      if (res.data.count >= 50) {
                        state.setPageSize(50);
                      } else {
                        state.setPageSize(res.data.count);
                      }
                    } else {
                      state.setPageSize(0);
                    }

                    resolve({
                      data: res.data.results,
                      page: query.page,
                      totalCount: res.data.count,
                    });
                  })
                  .catch((err) => {
                    X9Logger.report(err);
                    store.notificar('Ocorreu um erro ao gerar relatório. Tente novamente mais tarde!');
                    reject();
                  });
              })
            }
            options={{ ...newConfig.options, pageSize: state.pageSize }}
            localization={newConfig.localization}
            style={newConfig.style}
          />
        )}
      </Conteudo>
    </NewBody>
  );
};

export default observer(DesempenhoTanques);
