import React from 'react';
import Formatter from '../../../services/Formatter';
import { NoneValue, NormalValue, OutlierValue } from './styles';

interface Props {
  value: number;
  min: number;
  max: number;
}

export function ValorTabela({ value, min, max }: Props) {
  if (!value || value === 0) {
    return (
      <NoneValue>
        <strong>-</strong>
      </NoneValue>
    );
  } else if (value < min || value > max) {
    return <OutlierValue>{Formatter.formatNumber(3, value)}</OutlierValue>;
  } else {
    return <NormalValue>{Formatter.formatNumber(3, value)}</NormalValue>;
  }
}
