import styled, { css } from 'styled-components';

type Props = {
  selected?: boolean;
};
export const TanqueInfoVendas = styled.div<Props>`
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  div {
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 1fr;
    margin: 5px 0;

    p {
      text-align: center;
    }

    .left {
      text-align: start;
    }

    .right {
      text-align: end;
    }
  }

  cursor: pointer;

  ${(props) =>
    props.selected &&
    css`
      box-shadow: 0 0 0 2px rgba(86, 196, 35, 0.75), 0px 4px 4px rgba(0, 0, 0, 0.25);
    `}
`;
