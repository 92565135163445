import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Body } from '../../../../components/Body';
import MaterialTable, { Column } from 'material-table';
import Formatter from '../../../../services/Formatter';
import useStore from '../../../../services/hooks/useStore';
//import { useHistory } from 'react-router-dom';
import { newConfig } from '../../../../components/Tabela';
import { aqReports, apiMain } from '../../../../services/utils';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Flex } from '../../../../components/NavBar/styles';
import { Button, makeStyles } from '@material-ui/core';
import Select from '../../../../components/Select';
import { ReportButton } from '../styles';
import { Link } from 'react-router-dom';

type RelLista = {
  data: string;
  documento: string;
  nome: string;
  quantidade: number;
  valor_total: number;
  valor_unitario: number;
};

type RelCompras = {
  data: string;
  results: Array<RelLista>;
  qtd_total: number;
  total: number;
};

const useInputStyles = makeStyles({
  root: {
    width: '12vw',
    verticalAlign: 'middle',
    fontSize: '14px',
    border: 'solid 1px #303030',
    borderRadius: '5px',
    marginTop: '-10px',
  },
});

const RelatorioVendaProdutos: React.FC = () => {
  const store = useStore();
  const [cliente, setCliente] = useState({
    label: '',
    value: '',
    id: '',
  });
  const [categoria, setCategoria] = useState({
    label: '',
    value: '',
    id: '',
  });

  const [tableData, setTableData] = useState<RelLista[]>([]);
  const [, setRelData] = useState<RelCompras[]>([]);

  const [filtroApply, setFiltroApply] = useState(true);
  const currentDate = new Date();
  const initialDate = new Date();
  initialDate.setDate(1);
  const [filtroDate, setFiltroDate] = useState({
    dataInicial: {
      value: initialDate as MaterialUiPickersDate,
      valueFormatted: Formatter.getData(Formatter.dateToString(initialDate)),
    },
    dataFinal: {
      value: currentDate as MaterialUiPickersDate,
      valueFormatted: Formatter.getData(Formatter.dateToString(currentDate)),
    },
  });

  const columns: Column<RelLista>[] = [
    {
      field: 'data',
      title: 'Data',
      type: 'string',
      headerStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
      },
      cellStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'documento',
      title: 'Num. Documento',
      type: 'string',
      headerStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      cellStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'categoria.nome',
      title: 'Categoria',
      type: 'string',
      headerStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      cellStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'descricao',
      title: 'Descrição',
      type: 'string',
      headerStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      cellStyle: {
        textAlign: 'left',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      ...(true && ({ width: 140 } as object)),
    },
    {
      field: 'quantidade',
      title: 'Quant.(Kg)',
      type: 'numeric',
      headerStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      cellStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      render: (rowData) => {
        return Formatter.formatNumber(0, rowData.quantidade);
      },
      ...(true && ({ width: 60 } as object)),
    },
    {
      field: 'valor_unitario',
      title: 'Vr. Unit. (R$)',
      type: 'numeric',
      headerStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      cellStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.valor_unitario);
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      field: 'valor_total',
      title: 'Valor (R$)',
      type: 'numeric',
      headerStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      cellStyle: {
        textAlign: 'center',
        backgroundColor: '#F5F6F8',
        borderRight: '1px solid #CFCFCF',
      },
      render: (rowData) => {
        return Formatter.formatNumber(2, rowData.valor_total);
      },
      ...(true && ({ width: 100 } as object)),
    },
  ];

  const inputClasses = useInputStyles();

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Relatório Financeiro'), []);

  useEffect(() => {
    async function syncTable() {
      aqReports
        .get('/financeiro/' + store.propriedade?.uuid + '/extrato-produtos-clientes', {
          params: {
            cliente:cliente.id !== '' ? cliente.id : undefined,
            data_inicio: filtroDate.dataInicial.valueFormatted,
            data_fim: filtroDate.dataFinal.valueFormatted,
            categoria:categoria.id !== '' ? categoria.id : undefined,
       
          },
        })
        .then((res) => {
          let tableList: RelLista[] = [];
          let relList: RelCompras[] = [];
          const results = res.data.results;
          results.forEach((element: RelCompras) => {
            relList.push(element);
            element.results.forEach((lista: RelLista) => {
              tableList.push(lista);
            });
          });
          setTableData(tableList);
          setRelData(relList);
        });
    }

    if (filtroApply && store.propriedade) {
      syncTable();
      setFiltroApply(false);
    }

    // eslint-disable-next-line
  }, [store.propriedade, filtroApply]);

  useEffect(() => {
    store.clientes.populate();
    store.categorias.populate();
  }, []);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getPDF() {
    try {
      const pdf = await apiMain.get(`/gerador/pdf/financeiro/${store.propriedade?.id}/extrato-produtos-clientes/`, {
        params: {
          cliente:cliente.id !== '' ? cliente.id :undefined,
          data_inicio: filtroDate.dataInicial.valueFormatted,
          data_fim: filtroDate.dataFinal.valueFormatted,
          categoria:categoria.id !== '' ? categoria.id : undefined,
          authori: store.token,
        },
      });
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o PDF! Favor entrar em contato com o Suporte.');
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getCSV() {
    try {
      const pdf = await apiMain.get(`/gerador/csv/financeiro/${store.propriedade?.id}/extrato-produtos-clientes/`, {
        params: {
          cliente:cliente.id !== '' ? cliente.id : undefined,
          data_inicio: filtroDate.dataInicial.valueFormatted,
          data_fim: filtroDate.dataFinal.valueFormatted,
          categoria:categoria.id !== '' ? categoria.id : undefined,
          authori: store.token,
        },
      });
      window.open(pdf.request.responseURL, '_blank');
    } catch {
      store.notificar('Houve algum problema ao gerar o CSV! Favor entrar em contato com o Suporte.');
    }
  }

  return (
    <>
      <Body style={{ backgroundColor: 'white' }}>
        <Link
          to={'/financeiro/painel/relatorio-financeiro'}
          style={{ color: '#049CE7', textDecoration: 'none', fontFamily: 'Roboto' }}
        >
          <Flex>
            <img src={'/images/returnArrow.svg'} />
            <div style={{ paddingLeft: '5px', fontFamily: 'Roboto', color: '#049CE7' }}>Lista de Relatórios</div>
          </Flex>
        </Link>
        <div style={{ fontWeight: 'bold', fontSize: '24px', height: '30px' }}>Relatório de Vendas de Produtos</div>
        <Flex
          style={{
            width: '90vw',
            height: '10vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '200px' }}>
            {store.categorias!.arr && (
              <Select
                placeholder="Categoria"
                value={categoria}
                options={store.categorias.getByTipo('produtos').map((programa) => {
                  return { label: programa.nome, value: programa.uuid, id: programa.id };
                })}
                onChange={(e: any) => {
                  setCategoria(e);
                }}
              />
            )}
          </div>
          <div style={{ width: '200px' }}>
            {store.clientes!.arr && (
              <Select
                placeholder="Escolha um Cliente"
                value={cliente}
                options={store.clientes!.arr.map((programa) => {
                  return { label: programa.nome, value: programa.uuid, id: programa.id };
                })}
                onChange={(e: any) => {
                  setCliente(e);
                }}
              />
            )}
          </div>

          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            value={filtroDate.dataInicial.value}
            onChange={(date) => {
              setFiltroDate({
                ...filtroDate,
                dataInicial: {
                  value: date,
                  valueFormatted: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                },
              });
            }}
            InputProps={{ classes: inputClasses }}
          />

          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            value={filtroDate.dataFinal.value}
            onChange={(date) => {
              setFiltroDate({
                ...filtroDate,
                dataFinal: {
                  value: date,
                  valueFormatted: date ? Formatter.getData(Formatter.dateToString(date)) : '',
                },
              });
            }}
            InputProps={{ classes: inputClasses }}
          />
          <div>
            <ReportButton onClick={() => setFiltroApply(true)}>Gerar Relatório</ReportButton>
          </div>
          <div>
            <Button className="blue" onClick={getPDF}>
              <Flex>
                <div style={{ paddingRight: '10px', fontFamily: 'Roboto', color: '#303030', fontSize: '12px' }}>
                  Exportar PDF
                </div>
                <img src={'/images/exportPDF.svg'} />
              </Flex>
            </Button>
            <Button className="blue" onClick={getCSV}>
              <Flex>
                <div style={{ paddingRight: '10px', fontFamily: 'Roboto', color: '#303030', fontSize: '12px' }}>
                  Exportar CSV
                </div>
                <img src={'/images/exportCSV.svg'} />
              </Flex>
            </Button>
          </div>
        </Flex>
      </Body>
      <Body style={{ padding: '0px' }}>
        <MaterialTable
          columns={columns}
          data={tableData}
          options={{
            ...newConfig.options,
            maxBodyHeight: window.innerHeight - 300,
            minBodyHeight: window.innerHeight - 300,
            paging: true,
            sorting: false,
            pageSize: 10,
          }}
          localization={newConfig.localization}
          style={{ ...newConfig.style, backgroundColor: '#F5F6F8' }}
        />
      </Body>
    </>
  );
};

export default observer(RelatorioVendaProdutos);
