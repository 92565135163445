/**
 * Valida uma senha complexa com base em critérios específicos.
 *
 * @param {string} password - A senha a ser validada.
 * @returns {boolean} Retorna true se a senha atender aos critérios, caso contrário, retorna false.
 *
 * Critérios de validação:
 * - Deve conter pelo menos uma letra maiúscula.
 * - Deve conter pelo menos uma letra minúscula.
 * - Deve conter pelo menos um dígito (número).
 * - Deve conter pelo menos um dos seguintes caracteres especiais: #?!@$%^&*-.
 * - Deve ter no mínimo 8 caracteres de comprimento.
 */
function validateComplex(password: string): boolean {
  const tester = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/;
  return tester.test(password);
}

/**
 * Valida uma senha simples com base em critérios específicos.
 *
 * @param {string} password - A senha a ser validada.
 * @returns {boolean} Retorna true se a senha atender aos critérios, caso contrário, retorna false.
 *
 * Critérios de validação:
 * - Deve ter no mínimo 6 caracteres de comprimento.
 */
function validateSimple(password: string): boolean {
  return password.length >= 6;
}

export const passwordValidator = {
  validateComplex,
  validateSimple,
};
