import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { observer, useLocalStore } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Botao from '../../../components/Botoes/Botao';
import InputNumero from '../../../components/InputNumero';
import SelecionarLote from '../../../components/Modais/SelecionarLote';
import EditIcon from '@material-ui/icons/Edit';
import { Modal, ModalBody, ModalHeader } from '../../../components/Modais/styles';
import { Flex } from '../../../components/NavBar/styles';
import Select from '../../../components/Select';
import Formatter from '../../../services/Formatter';
import useModal from '../../../services/hooks/useModal';
import useStore from '../../../services/hooks/useStore';
import { apiV2 } from '../../../services/utils';
import { returnQuantidade } from '../../../services/utils/producao';

import SelecionarLoteTanques from './SelecionarLoteTanques';
import SelecionarTanque from './SelecionarTanque';
import EditPesoMedio from './EditPesoMedio';
import SelecionarTanqueUnico from './SelecionarTanqueUnico';

type Amostragem = {
  lote_tanque: number;
  tara: number;
  qtd_peixes: number;
  peso: number;
  media: number;
};

type Props = ModalProps & {
  onConfirm(): void;
};
const FormBiometria: React.FC<Props> = ({ open, handleClose, onConfirm }) => {
  const store = useStore();

  const state = useLocalStore(() => ({
    tanque: null as SelectRow | null,
    lote: null as SelectRow | null,
    data: new Date() as MaterialUiPickersDate,
    tanques: [] as SelectedTanque[],
    // tanque: null as { label: string; value: string; loteTanque: number } | null,
    select: {
      index: 0,
      peso_medio: 0,
    },
    tanqueNome: '',
    resposta: [] as Amostragem[],
    amostragens: [
      {
        tara: 0,
        qtd_peixes: 1,
        peso: 0,
        media: 0,
      },
    ],
    media: 0,
    selectKind: '',
    selectLote: false,
    sync: false,
  }));
  const modalTanques = useModal();
  const modalEditPesoMedio = useModal();
  const modalSelecionarLote = useModal();
  const modalSelecionarTanque = useModal();
  const modalSelecionarTanqueUnico = useModal();
  const [tanques, setTanques] = useState<Tanque[]>([] as Tanque[]);
  const [lote, setLote] = useState<Lote[]>([] as Lote[]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function searchTanque() {
      store.toggleLoader();

      await apiV2
        .get('/tanque/modals/', {
          params: {
            prop: store.propriedade?.id,
          },
        })
        .then((res) => {
          setTanques(res.data.results);
        });

      state.sync = true;
      store.toggleLoader();
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function searchLote() {
      store.toggleLoader();
      await apiV2
        .get('/lote/modals/', {
          params: {
            prop: store.propriedade?.id,
          },
        })
        .then((res) => {
          setLote(res.data.results);
        });
      state.sync = true;
      store.toggleLoader();
    }
    searchLote();
    searchTanque();
    // eslint-disable-next-line
  }, []);

  function addTanque(tanqueID: number, loteTanqueId?: number): void {
    if (state.tanques.filter((tanque) => tanque.item.id === tanqueID).length === 0) {
      const tanque = { item: tanques.find((tanque: any) => tanque.id === tanqueID), loteTanqueId };

      const amostragem = {
        lote_tanque: store.tanques.get(tanqueID).id,
        tara: 0,
        qtd_peixes: 1,
        peso: 0,
        media: 0,
      };
      const amostragens = [...state.resposta, amostragem] as Amostragem[];
      state.resposta = amostragens;
      const tanqueProps = [...state.tanques, tanque];
      state.tanques = tanqueProps as any;
    }
  }

  function removeTanque(tanqueID: number): void {
    state.tanques = state.tanques.filter((tanque) => tanque.item.id !== tanqueID);
    state.resposta = state.resposta.filter((tanque: any) => tanque.lote_tanque !== tanqueID);
  }
  async function cadastrar(): Promise<void> {
    if (state.tanques.length === 0) store.notificar('Selecione os tanques!');
    else if (state.amostragens.length === 0) store.notificar('Adicione uma amostragem!');
    else {
      handleClose();
      store.toggleLoader();
      const loteTanques: number[] = [];

      const loteFinal = state.lote || loteDoTanqueSelecionadoOption || null;
      if (loteFinal === null) {
        state.resposta.map((tanque) => {
          const loteTanque = tanque.lote_tanque;
          if (loteTanque) return loteTanques.push(loteTanque);
          else return null;
        });
      } else {
        state.resposta.map((tanque) => {
          const loteTanque = store.loteTanque
            .getByTanque(tanque.lote_tanque)
            ?.filter((loteTanque) => loteTanque.lote.id === parseInt(loteFinal?.value))[0];
          if (loteTanque) return (tanque.lote_tanque = loteTanque.id);
          else return null;
        });
      }

      const amostragens: Amostragem[] = [];

      state.resposta.map((amostragem: any) => {
        const peso = (): number => {
          if (typeof amostragem.peso === 'number' && typeof amostragem.tara === 'number') {
            return amostragem.peso - amostragem.tara;
          } else if (typeof amostragem.peso === 'string' && typeof amostragem.tara === 'string') {
            return amostragem.peso - amostragem.tara;
          } else return 0;
        };

        amostragens.push({
          tara: Math.abs(amostragem.tara),
          peso: parseFloat(Math.abs(peso()).toFixed(3)),
          lote_tanque: Math.abs(amostragem.lote_tanque),
          qtd_peixes: Math.trunc(Math.abs(amostragem.qtd_peixes)),
          media: Math.abs(amostragem.media.toFixed(3)),
        });
      });

      const data = {
        data: Formatter.dateToString(state.data),
        amostragem: amostragens,
      };

      await apiV2
        .post(`/biometria/?propriedade=${store.propriedade?.id}`, data)
        .then(async () => {
          await store.biometrias.populate();
          store.notificar('Biometria cadastrada com sucesso!');
          onConfirm();
        })
        .catch(() => {
          store.notificar('Ocorreu um erro no cadastro, tente novamente!');
        });
      store.toggleLoader();
    }
  }
  const TanquesSelecionados: React.FC = () => (
    <>
      {state.tanques &&
        state.tanques.map((tanque: any) => {
          const index = state.resposta.findIndex((item: any) => item.lote_tanque === tanque.item.id);

          return (
            <Flex style={{ width: '100%', justifyContent: 'space-between' }} key={tanque.item.id}>
              <p>{tanque.item.nome || tanque.item.codigo}</p>
              <p>{Formatter.formatNumber(3, state.resposta[index].media)}</p>
              <div>
                <IconButton
                  onClick={() => {
                    state.select = {
                      index: index,
                      peso_medio: state.resposta[index].media,
                    };
                    modalEditPesoMedio.open();
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => removeTanque(tanque.item.id)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </Flex>
          );
        })}
    </>
  );

  const ocultarTanquesSelecionados = !state.tanque;
  const loteTanqueDoTanqueSelecionado = store.loteTanque.getByTanque(
    state.tanque?.value ? Number(state.tanque?.value) : -1,
  );
  let loteDoTanqueSelecionadoOption: SelectRow | null = null;
  if (loteTanqueDoTanqueSelecionado && loteTanqueDoTanqueSelecionado.length > 0) {
    loteDoTanqueSelecionadoOption = {
      value: loteTanqueDoTanqueSelecionado[0].lote.id.toString(),
      label: loteTanqueDoTanqueSelecionado[0].lote.codigo,
    };
  }

  return state.sync ? (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Nova biometria</ModalHeader>
      <ModalBody>
        <div style={{ width: '540px' }}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data"
            value={state.data}
            onChange={(date) => (state.data = date)}
          />

          <div style={{ width: '100%' }}>
            <Select
              value={state.tanque}
              placeholder="Escolha o tanque"
              onChange={(e: any) => (state.tanque = e)}
              onFocus={(e) => {
                e.target.blur();
                modalSelecionarTanqueUnico.open();
              }}
              options={store.tanques.map((lote) => {
                return { value: lote.id.toString(), label: lote.codigo };
              })}
              disabled={Boolean(state.lote)}
            />
          </div>

          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex' }}>
              <Select
                value={state.lote}
                placeholder="Escolha o lote"
                onChange={(e: any) => (state.lote = e)}
                onFocus={(e) => {
                  e.target.blur();
                  modalSelecionarLote.open();
                }}
                options={lote.map((lote) => {
                  return { value: lote.id.toString(), label: lote.codigo };
                })}
                disabled={Boolean(state.tanque)}
              />
            </div>
          </div>

          <Botao onClick={modalSelecionarTanque.open} disabled={Boolean(state.tanque)}>
            Adicionar tanque
          </Botao>
          {ocultarTanquesSelecionados && <TanquesSelecionados />}

          <InputNumero
            value={state.media}
            onChange={(value) => {
              state.amostragens[0].media = value;
              state.amostragens[0].peso = value * 1;
              state.amostragens[0].qtd_peixes = 1;
              state.resposta.map((item) => ((item.peso = value * 1), (item.media = value)));
              state.media = value;
            }}
            precision="3"
            label="Peso Médio (g)"
          />

          <div style={{ width: '100%', display: 'flex' }}>
            <Botao
              onClick={() => {
                handleClose();
              }}
              variant="contained"
              cor="#FC7467"
            >
              Cancelar
            </Botao>
            <Botao
              onClick={() => {
                cadastrar();
                onConfirm();
              }}
              variant="contained"
              cor="#00C853"
            >
              Salvar
            </Botao>
          </div>
        </div>
      </ModalBody>
      <SelecionarLote
        open={modalSelecionarLote.state}
        handleClose={modalSelecionarLote.close}
        onItemClick={(lote) => {
          state.tanques = [];
          state.lote = { label: lote.codigo, value: lote.id.toString() };
          store.loteTanque.getByLote(lote.id).map((loteTanque) => {
            const estoque = returnQuantidade(loteTanque, false);
            if (estoque) {
              const amostragem = {
                lote_tanque: loteTanque.tanque.id,
                tara: 0,
                qtd_peixes: 1,
                peso: 0,
                media: 0,
              };

              const amostragens = [...state.resposta, amostragem];

              state.resposta = amostragens;

              return state.tanques.push({ item: loteTanque.tanque });
            } else {
              return null;
            }
          });
        }}
        loteID={state.lote ? parseInt(state.lote.value) : null}
      />
      <SelecionarTanqueUnico
        handleClose={modalSelecionarTanqueUnico.close}
        open={modalSelecionarTanqueUnico.state}
        onItemClick={(tanque: Tanque | null) => {
          if (tanque) {
            state.tanque = { value: tanque.id.toString(), label: tanque.nome };
            addTanque(tanque.id);
          } else {
            state.lote = null;
            state.tanque = null;
            state.tanques = [];
          }
        }}
        selecionadoId={+state.tanque?.value}
      />

      <SelecionarLoteTanques
        open={modalTanques.state}
        handleClose={modalSelecionarTanque.close}
        setTanque={(tanque) => addTanque(Number(tanque.value), tanque.loteTanque)}
        setLote={(lote) => console.log(lote)}
        exclude={[]}
      />
      {state.lote ? (
        <SelecionarTanque
          open={modalSelecionarTanque.state}
          handleClose={modalSelecionarTanque.close}
          onItemClick={addTanque}
          selectedTanques={state.tanques}
          loteID={parseInt(state.lote.value)}
        />
      ) : null}

      {modalEditPesoMedio.state ? (
        <EditPesoMedio
          onConfirm={() => {
            state.resposta[state.select.index].media = state.select.peso_medio;
            modalEditPesoMedio.close;
          }}
          open={modalEditPesoMedio.state}
          handleClose={modalEditPesoMedio.close}
          state={state}
        />
      ) : null}
    </Modal>
  ) : null;
};

export default observer(FormBiometria);
