import { TextField } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { observer, useLocalStore } from 'mobx-react-lite';
import React from 'react';
import InputNumero from '../../../components/InputNumero';
import { Modal, ModalBody, ModalHeader } from '../../../components/Modais/styles';
import Select from '../../../components/Select';
import useStore from '../../../services/hooks/useStore';
import { returnTanque } from '../../../services/utils/propriedade';

interface Props extends ModalProps {
  transferencia: Transferencia | null;
}
const VisualizarTransferencia: React.FC<Props> = ({ open, handleClose, transferencia }) => {
  const store = useStore();

  const loteOrigem = transferencia?.origem;
  const tanqueOrigem = store.tanques.get(transferencia?.origem.tanque || 0)!;
  const tanqueDestino = store.tanques.get(transferencia?.destino.tanque || 0)!;

  const state = useLocalStore(() => ({
    especie: loteOrigem?.especie_explorada || null,
    loteOrigem: { label:  transferencia?.origem.codigo_lote || "" , value: '0' },
    loteDestino: { label: transferencia?.destino.codigo_lote || "" , value: '0' },
    tqOrigem: { label: returnTanque(tanqueOrigem || 0), value: '0' },
    tqDestino: { label: returnTanque(tanqueDestino || 0), value: '0' },
    data: new Date(
      transferencia?.data.substr(3, 2) +
        '/' +
        transferencia?.data.substr(0, 2) +
        '/' +
        transferencia?.data.substr(6, 4),
    ) as MaterialUiPickersDate,
    biomassa: Number(transferencia?.biomassa),
    peso: Number(transferencia?.peso),
    quantidade: Number(transferencia?.quantidade),
    tamanho: Number(transferencia?.tamanho),
    observacao: transferencia?.observacao,
  }));

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Visualizar Transferência</ModalHeader>
      <ModalBody>
        <div style={{ width: '100%' }}>
          {store.lotes.arr && <Select disabled value={state.loteOrigem} placeholder="Lote Origem" options={[]} />}
        </div>
        <div style={{ width: '100%', marginTop: 10 }}>
          <Select disabled value={state.tqOrigem} placeholder="Tanque Origem" options={[]} />
        </div>
        <div style={{ width: '100%' }}>
          {store.lotes.arr && <Select disabled value={state.loteDestino} placeholder="Lote Destino" options={[]} />}
        </div>
        <div style={{ width: '100%', marginTop: 10 }}>
          <Select disabled value={state.tqDestino} placeholder="Tanque Destino" options={[]} />
        </div>
        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data"
          value={state.data}
          onChange={(date) => (state.data = date)}
          disabled
        />
        <InputNumero
          value={state?.biomassa || 0}
          onChange={(value) => (state.biomassa = value)}
          precision="3"
          label="Biomassa Total (kg)"
          disabled
        />
        <InputNumero
          value={state?.peso || 0}
          // onChange={(value) => (state.peso = value)}
          precision="3"
          label={state.especie === 'Camarão' ? 'PLs/Grama' : 'Peso Médio (g)'}
          disabled
        />
        <InputNumero
          value={state?.quantidade || 0}
          onChange={(value) => (state.quantidade = value)}
          precision="0"
          label="Quantidade Total"
          disabled
        />
        <InputNumero
          value={state?.tamanho || 0}
          onChange={(value) => (state.tamanho = value)}
          precision="2"
          label="Tamanho Médio (cm) *Opcional"
          disabled
        />
        <TextField
          label="Observação *Opcional"
          value={state.observacao}
          onChange={(e) => (state.observacao = e.target.value)}
          disabled
        />
      </ModalBody>
    </Modal>
  );
};

export default observer(VisualizarTransferencia);
