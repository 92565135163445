import React, { useEffect, useMemo, useState } from 'react';
import useStore, { stateContext } from '../../../../services/hooks/useStore';
import { useLocalStore, observer } from 'mobx-react-lite';
import { Modal, ModalHeader, ModalBody } from '../../../../components/Modais/styles';
import Botao from '../../../../components/Botoes/Botao';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Flex } from '../../../../components/NavBar/styles';
import { TextField, Checkbox, FormControlLabel, Box, IconButton, Grid } from '@material-ui/core';
import InputNumero from '../../../../components/InputNumero';
import { KeyboardDatePicker } from '@material-ui/pickers';
import useModal from '../../../../services/hooks/useModal';
import SelecionarFornecedor from '../../../Financeiro/Movimentacoes/SelecionarFornecedor';
import { Parcela } from '../../../Vendas/MinhasVendas/styles';
import Select from '../../../../components/Select';
import Formatter from '../../../../services/Formatter';
import NewSelect from '../../../../components/Select/newSelect';
import Edit from '@material-ui/icons/Edit';
import { Delete } from '@material-ui/icons';
import { ModalCompraInsumoItem } from './ModalCompraInsumoItem';
import { MovimentacaoData } from '../../../../services/models/Movimentacoes';
import { api, apiV2, getThingsV2 } from '../../../../services/utils';
import ParcelaParcial from '../../../../components/Modais/ParcelaParcial';
import { CompraInsumo } from '../../../../@types/Insumo';
import Confirmar from '../../../../components/Modais/Confirmar';

type ModeType = 'criar' | 'editar' | 'deletar';

type DespesaItemRacao = DespesaItemCriacao & {
  insumo: number;
};

type State = {
  descricao: string;
  valorTotal: number;
  quantidade: number;
  valorUnitario: number;
  valorFrete: number;
  outrosValores: number;
  data: MaterialUiPickersDate;
  documento: string;
  categoria: SelectRow | null;
  insumo: SelectRow | null;
  conta: SelectRow;
  fornecedor: SelectRow | null;
  qtdParcelas: number;
  parcelas: ParcelaData[];
  pagamento: SelectRow;
  sync: boolean;
  setPagamento(tipo: SelectRow): void;
  setConta(tipo: SelectRow): void;
  setQtdParcelas(value: number): void;
  somaParcelas(): number;
  somaValorItems(): number;
  somaValorTotal(): number;
  somaQuantidadeItems(): number;
  itens: DespesaItemRacao[];
  props: ParcelaData;
  cliente: SelectRow | null;
  estrangeira: boolean;
};

type Props = ModalProps & {
  onConfirm?: () => void;
  compra?: CompraInsumo | null;
  soParcelas?: boolean;
  onDelete?: () => void;
};

const ModalCompraInsumo: React.FC<Props> = ({ open, handleClose, onConfirm, onDelete, compra, soParcelas = false }) => {
  const store = useStore();
  const modalFornecedor = useModal();
  const modalDepesaItem = useModal();
  const modalParceal = useModal();
  const modalConfirmarExclusao = useModal();

  const mostrarTodosInsumos = true;
  const movimentacaoId = compra?.movimentacao;
  const editMovimentacao = compra && store.movimentacoes.arr && store.movimentacoes.get(movimentacaoId);
  const eEdicao = Boolean(editMovimentacao);

  const categoriasOptions = [{ label: 'insumos', value: '25' }];

  const modo = useMemo(() => {
    let modoInterno: ModeType = 'criar';
    if (eEdicao) modoInterno = 'editar';
    if (onDelete) modoInterno = 'deletar';
    return modoInterno;
  }, [eEdicao, onDelete]);
  const eDelecao = modo === 'deletar';

  const titulo = useMemo(() => {
    if (modo === 'criar') return 'Nova Compra de Insumo';
    if (modo === 'editar') return 'Editar Insumo';
    if (modo === 'deletar') return 'Excluir Insumo';
  }, [modo]);

  const temParcelaPaga = eEdicao && Boolean(editMovimentacao?.parcelas?.find((m) => m.recebido));
  const desabilitarCampos = eDelecao || temParcelaPaga;

  const etapaInicial = soParcelas ? 1 : 0;
  const [etapa, setEtapa] = useState(etapaInicial);
  const [itemSelecionado, setItemSelecionado] = useState<DespesaItemRacao | null>(null);

  const corBotaoVoltar = !eDelecao ? '#FC7467' : '#939393';
  const corBotaoAvancar = !eDelecao ? '#00C853' : '#FC7467';

  const state = useLocalStore(
    (): State => ({
      descricao: '',
      valorTotal: 0,
      quantidade: 0,
      valorUnitario: 0,
      valorFrete: 0,
      outrosValores: 0,
      data: new Date(),
      documento: '',
      categoria: categoriasOptions[0],
      insumo: null,
      conta: {
        label: store.contas.getContaPadrao()?.titulo || '',
        value: store.contas.getContaPadrao()?.id.toString(),
      },
      fornecedor: null,
      sync: false,
      // Parcelas
      qtdParcelas: 1,
      parcelas: [
        {
          num_parcela: 1,
          data_pagamento: Formatter.dateToString(new Date()),
          recebido: false,
          pagamento_tipo: store.tiposDePagamento.arr![0].id,
          valor: 0,
          conta: store.contas.arr![0].id,
        },
      ],
      pagamento: store.tiposDePagamento.getSelectRows()[0],
      itens: [],
      cliente: null,
      estrangeira: false,

      props: {} as ParcelaData,

      setPagamento(tipo) {
        this.pagamento = tipo;
        this.parcelas.map((parcela) => {
          return (parcela.pagamento_tipo = parseInt(tipo.value));
        });
      },
      setConta(tipo: any) {
        this.conta = tipo;
        this.parcelas.map((parcela) => {
          return (parcela.conta = parseInt(tipo.value));
        });
      },
      setQtdParcelas(value) {
        this.qtdParcelas = value;
        this.parcelas = [];

        for (let num_parcela = 0; num_parcela < this.qtdParcelas; num_parcela++) {
          const data: MaterialUiPickersDate = new Date(state.data!);
          data.setMonth(data.getMonth() + num_parcela);
          const valorDaParcela = this.valorTotal / this.qtdParcelas;
          this.parcelas.push({
            num_parcela: num_parcela + 1,
            data_pagamento: Formatter.dateToString(data),
            recebido: false,
            pagamento_tipo: parseInt(this.pagamento.value),
            conta: parseInt(this.conta.value),
            valor: Number(valorDaParcela.toFixed(2)),
          });
        }
      },

      somaParcelas() {
        let valor = 0;
        this.parcelas.map((parcela) => {
          return (valor += parcela.valor as number);
        });
        return valor;
      },
      somaValorItems() {
        let valor = 0;
        this.itens.map((item) => (valor += item.valor_total_item));
        return valor;
      },
      somaQuantidadeItems() {
        let valor = 0;
        this.itens.map((item) => (valor += item.quantidade));
        return valor;
      },
      somaValorTotal() {
        let total = this.somaValorItems() + this.valorFrete + this.outrosValores;
        return total;
      },
    }),
  );

  function fecharModalDespesasItem() {
    modalDepesaItem.close();
    setItemSelecionado(null);
  }

  function selecionarItem(item: DespesaItemRacao) {
    setItemSelecionado(item);
    modalDepesaItem.open();
  }

  function adicionarItem(dados: DespesaItemRacao) {
    state.itens = [...state.itens, dados];
  }

  function removerItem(index: number) {
    if (index >= 0 && index < state.itens.length) {
      const novosItens = [...state.itens];
      novosItens.splice(index, 1);
      state.itens = novosItens;
    }
  }

  function atualizarItem(id: number, dados: DespesaItemRacao) {
    const index = state.itens.findIndex((item) => item.id === id);
    if (index !== -1) {
      const novosItens = [...state.itens];
      novosItens[index] = dados;
      state.itens = novosItens;
    }
  }

  async function cadastrar(): Promise<void> {
    if (eDelecao && onDelete) {
      onDelete();
      handleClose();
      return;
    }
    const conta = store.contas.get(parseInt(state.conta.value));

    if (!conta) return;
    let dataConta;

    if (conta.data_inicio) {
      dataConta = new Date(
        conta.data_inicio!.substr(3, 2) + '/' + conta.data_inicio!.substr(0, 2) + '/' + conta.data_inicio!.substr(6, 4),
      );
    } else {
      dataConta = new Date(
        conta.created_at!.substr(3, 2) + '/' + conta.created_at!.substr(0, 2) + '/' + conta.created_at!.substr(6, 4),
      );
    }

    if (!state.valorTotal) store.notificar('Informe o valor total');
    else if (!state.quantidade) store.notificar('Informe a quantidade');
    else if (!state.categoria) store.notificar('Informe a categoria');
    else if (!state.fornecedor) store.notificar('Informe o fornecedor');
    else if (state.data!.getTime() < dataConta.getTime()) {
      store.notificar('A data da moviemntação não pode ser anterior a da criação da conta');
    } else if (!state.qtdParcelas) {
      store.notificar('Informe a quantidade de parcelas');
    } else if (state.parcelas.some((parcela) => !parcela.valor)) {
      store.notificar('O valor de uma parcela não pode ser 0 ou nulo');
    } else if (
      state.valorTotal - Number(state.somaParcelas().toFixed(2)) > 1 ||
      state.valorTotal - Number(state.somaParcelas().toFixed(2)) < -1
    ) {
      store.notificar('A soma das parcelas deve coincidir com o valor total da venda.');
    } else {
      store.toggleLoader();
      const diferenca = state.valorTotal - state.somaParcelas();
      if (diferenca >= -1 && diferenca <= 1 && diferenca !== 0) {
        Number((state.parcelas[state.qtdParcelas - 1].valor += diferenca).toFixed(2));
        store.notificar(
          'A soma das parcelas deve coincidir com o valor total da venda. Ajustamos o valor da última parcela!',
        );
      }
      const parcelas = state.parcelas.map((parcela) => {
        return {
          ...parcela,
          valor: Number(parcela.valor.toFixed(2)),
        };
      });
      const fornecedor = state.fornecedor ? parseInt(state.fornecedor.value) : undefined;
      const cliente = undefined;
      let dadosMovimentacao: MovimentacaoData = {
        propriedade: store.propriedade!.id,
        documento: state.documento,
        data: Formatter.dateToString(state.data),
        conta: parseInt(state.conta.value),
        categoria: parseInt(state.categoria.value),
        lote_tanque_movimentacao: [],
        fornecedor: parseInt(state.fornecedor!.value),
        cliente,
        valor_frete: state.valorFrete,
        valor_outros: state.outrosValores,

        // racao: parseInt(state.insumo!.value),
        // descricao: 'Compra de insumo',
        // situacao: despesa.pago,
        // quantidade: state.quantidade,
        // valor_total: valorTotal,
        // valor_unitario: parseFloat((valorTotal / state.quantidade).toFixed(3)),
        // parcelas: despesa.parcelas,
      };

      const itensFinal = state.itens.map((item) => ({
        id: item.id,
        categoria: item.categoria,
        descricao: item.descricao,
        quantidade: item.quantidade,
        valor_uni_item: item.valor_uni_item,
        valor_total_item: item.valor_total_item,
        racao: item.racao,
        insumo: item.insumo,
      }));

      if (editMovimentacao) {
        const comprasMesmaMovimentacao = (store.comprasDeInsumos?.comprasDeInsumos ?? []).filter(
          (c) => c?.movimentacao === movimentacaoId,
        );
        if (comprasMesmaMovimentacao.length > 0) {
          await Promise.all(
            comprasMesmaMovimentacao.map(async (c) => {
              const itemEncontrato = editMovimentacao?.itens?.find(
                (i: any) => i.valor_total == c?.valor_compra && i.quantidade == c?.total_kg,
              );

              const valorCompraAnterior = itemEncontrato?.valor_total_item;
              const valor_compra = valorCompraAnterior ? obterValorComFreteDoItem(valorCompraAnterior) : undefined;
              const dadosCompra = {
                fornecedor,
                data: Formatter.dateToString(state.data),
                valor_compra,
                propriedade: store.propriedade!.id,
                qtd_sacos: 1,
                tipo: 'entrada',
                estoque_inicial: false,
                ajuste_estoque: false,
                justificativa: 'Compra',
              };

              await apiV2
                .put(`/comprar-insumo/${c.id}/?propriedade=${store.propriedade!.id}`, dadosCompra)
                .catch(() => store.notificar('Ocorreu um erro ao editar a compra!'));
            }),
          ).then(async () => {
            await store.movimentacoes.editarDespesa(editMovimentacao.id, dadosMovimentacao, parcelas);
            store.notificar('Compra editada com sucesso!');
          });

          Promise.all([store.movimentacoes.populate(), store.contas.populate(), store.racoes.populate()]);
        }
      } else {
        dadosMovimentacao = {
          ...dadosMovimentacao,
          parcelas,
          itens: itensFinal as any,
        };
        await api
          .post(`/movimentacao/?propriedade=${store.propriedade!.id}`, dadosMovimentacao)
          .then(async (movRes) => {
            await Promise.all(
              itensFinal.map(async (item) => {
                const itemCriado = movRes?.data?.itens?.find(
                  (i: any) =>
                    i.categoria == item.categoria &&
                    i.quantidade == item.quantidade &&
                    i.descricao == item.descricao &&
                    i.valor_total_item == item.valor_total_item &&
                    i.valor_uni_item == item.valor_uni_item,
                );
                const movimentacao_item = itemCriado?.id ?? null;

                const dadosCompra = {
                  propriedade: store.propriedade!.id,
                  insumo: item.insumo,
                  fornecedor,
                  data: Formatter.dateToString(state.data),
                  valor_compra: obterValorComFreteDoItem(item.valor_total_item),
                  total_kg: item.quantidade,
                  justificativa: 'Compra',
                  movimentacao: movRes.data.id,
                  movimentacao_item,
                  qtd_sacos: 1,
                  valor_por_saco: obterValorComFreteDoItem(item.valor_total_item),
                  tipo: 'entrada',
                  estoque_inicial: false,
                  ajuste_estoque: false,
                };

                await apiV2
                  .post(`/comprar-insumo/?propriedade=${store.propriedade!.id}`, dadosCompra)
                  .then(() => store.notificar('Compra de insumo registrada com sucesso!'))
                  .catch(() =>
                    store.notificar('Ocorreu um erro ao registrar a compra, tente novamente ou contate nosso suporte.'),
                  );
              }),
            );
          });
      }

      store.comprasDeInsumos.comprasDeInsumos = await getThingsV2<CompraInsumo>('/comprar-insumo/', {
        propriedade: stateContext.state.propriedade!.id,
      });
      Promise.all([
        store.insumos.populate(mostrarTodosInsumos),
        store.comprasDeInsumos.populate(),
        store.movimentacoes.populate(),
        store.contas.populate(),
      ]);

      store.toggleLoader();
      handleClose();
      if (onConfirm) onConfirm();
    }
  }

  function obterValorComFreteDoItem(valorItem: number) {
    if (state.valorFrete === 0 && state.outrosValores === 0) return valorItem;
    const valorFrete = (valorItem / state.somaValorItems()) * state.valorFrete;
    const valorOutros = (valorItem / state.somaValorItems()) * state.outrosValores;
    return +(valorItem + valorFrete + valorOutros).toFixed(3);
  }

  function avancarEtapa() {
    if (eDelecao) modalConfirmarExclusao.open();
    else setEtapa((prev) => prev + 1);
  }

  useEffect(() => {
    if (state.valorTotal && state.quantidade) state.valorUnitario = state.valorTotal / state.quantidade;

    if (state.valorTotal) {
      const somaParcelas = state.parcelas.reduce((total, parcela) => {
        return total + parcela.valor;
      }, 0);
      const deveAtualizarParcelas = Math.abs(somaParcelas - state.valorTotal) > 1;
      if (deveAtualizarParcelas) {
        state.parcelas.map((_parcela, index) => {
          return (state.parcelas[index].valor = +(state.valorTotal / state.qtdParcelas).toFixed(3));
        });
      }
    }
  }, [state.valorTotal, state.quantidade]);

  async function sync(): Promise<void> {
    store.toggleLoader();
    if (!store.movimentacoes.arr) {
      await Promise.all([store.movimentacoes.populate(), store.insumos.populate(mostrarTodosInsumos)]);
    }
    store.toggleLoader();
    state.sync = true;
  }

  useEffect(() => {
    store.propriedade && sync();
  }, [store.propriedade]);

  useEffect(() => {
    state.valorTotal = state.somaValorTotal();
    state.quantidade = state.somaQuantidadeItems();
  }, [state.itens, state.valorFrete, state.outrosValores]);

  useEffect(() => {
    if (editMovimentacao) {
      state.descricao = editMovimentacao.descricao;
      state.valorTotal = editMovimentacao.valor_total;
      state.quantidade = editMovimentacao.quantidade;
      state.valorUnitario = editMovimentacao.valor_unitario;
      state.documento = editMovimentacao.documento;
      state.qtdParcelas = editMovimentacao.parcelas.length || 1;
      state.outrosValores = editMovimentacao.valor_outros || 0;
      state.valorFrete = editMovimentacao.valor_frete || 0;
      state.parcelas = editMovimentacao.parcelas as ParcelaData[];
      state.pagamento = store.tiposDePagamento.getSelectRows()[0];
      state.data = new Date(
        editMovimentacao.data.substr(3, 2) +
          '/' +
          editMovimentacao.data.substr(0, 2) +
          '/' +
          editMovimentacao.data.substr(6, 4),
      );

      state.itens = editMovimentacao.itens as any[];
      state.conta = {
        label: editMovimentacao.conta.titulo,
        value: editMovimentacao.conta.id,
      };
      state.fornecedor = editMovimentacao.fornecedor
        ? {
            label: editMovimentacao.fornecedor!.nome,
            value: editMovimentacao.fornecedor!.id.toString(),
          }
        : {
            label: '',
            value: '',
          };
      state.conta = {
        label: store.contas.getContaPadrao()?.titulo || '',
        value: store.contas.getContaPadrao()?.id.toString(),
      };
      state.cliente = editMovimentacao.cliente
        ? {
            label: editMovimentacao.cliente!.nome,
            value: editMovimentacao.cliente!.id.toString(),
          }
        : {
            label: '',
            value: '',
          };
      state.categoria = categoriasOptions.find((cat) => cat.value === editMovimentacao.categoria.id.toString()) ?? null;
    }
  }, [editMovimentacao]);

  useEffect(() => {
    if (editMovimentacao) {
      if (
        editMovimentacao.categoria!.nome === 'Insumos' ||
        editMovimentacao.descricao?.includes('Povoamento') ||
        editMovimentacao.descricao?.includes('Compra de ração')
      ) {
        state.estrangeira = true;
      } else {
        state.estrangeira = false;
      }
    }
  }, [editMovimentacao]);

  if (!state.sync) return null;

  function RenderizarExcluirRegistro() {
    if (!compra) return null;
    return (
      <div style={{ width: '100%' }}>
        <b>Tipo:</b> Compra de Insumos
        <br />
        <b>Data: </b> {Formatter.getData(compra?.data)}
        <br />
        <b>Fornecedor:</b> {compra?.ajuste_estoque ? 'Sem Fornecedor' : compra?.fornecedor?.label}
        <br />
        <b>Valor Total da NF: </b> R$ {Formatter.formatNumber(2, state.somaValorTotal())}
      </div>
    );
  }

  function renderizarEtapa0() {
    return (
      <Modal onClose={handleClose} open={open}>
        <ModalHeader>{titulo}</ModalHeader>
        <ModalBody style={{ width: 950 }}>
          <Box width="100%">
            <Select
              placeholder="Fornecedor"
              value={state.fornecedor}
              onFocus={(e) => {
                e.target.blur();
                modalFornecedor.open();
              }}
              options={[]}
              disabled={desabilitarCampos}
            />

            <KeyboardDatePicker
              autoOk
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Data"
              value={state.data}
              onChange={(date) => {
                state.data = date;
                state.setQtdParcelas(state.qtdParcelas);
              }}
              disabled={desabilitarCampos}
            />
            <TextField
              value={state.documento}
              onChange={(e) => (state.documento = e.target.value)}
              label="Documento"
              inputProps={{ maxLength: 30 }}
              disabled={desabilitarCampos}
            />
            <InputNumero
              value={state.valorFrete}
              onChange={(value) => (state.valorFrete = value)}
              precision="2"
              label="Valor do Frete"
              disabled={desabilitarCampos}
            />
            <InputNumero
              value={state.outrosValores}
              onChange={(value) => (state.outrosValores = value)}
              precision="2"
              label="Outros Valores"
              disabled={desabilitarCampos}
            />

            <Select
              disabled={state.estrangeira || desabilitarCampos || eEdicao}
              placeholder="Selecione a Categoria"
              value={state.categoria}
              onChange={(e: any) => (state.categoria = e)}
              options={categoriasOptions}
            />

            <Box display="flex" flexDirection="column" alignItems="center" mt={2} mb={2}>
              {!editMovimentacao && (
                <Box width="50%">
                  <Botao onClick={modalDepesaItem.open} disabled={!state.categoria}>
                    Adicionar Item
                  </Botao>
                </Box>
              )}

              <Box width="100%">
                <Grid container direction="column">
                  {state.itens.map((item, index) => (
                    <Grid key={item.descricao} container justifyContent="center" spacing={2}>
                      <Grid item sm={2} alignContent="center">
                        <TextField value={item.descricao} label="Descrição" disabled />
                      </Grid>
                      <Grid item sm={2} alignContent="center">
                        <Box pt="10px">
                          <InputNumero
                            value={obterValorComFreteDoItem(item.valor_total_item)}
                            precision="3"
                            label="Valor + Frete"
                            disabled
                          />
                        </Box>
                      </Grid>
                      <Grid item sm={2} alignContent="center">
                        <Box pt="10px">
                          <InputNumero value={item.valor_total_item} precision="2" label="Valor Total" disabled />
                        </Box>
                      </Grid>
                      <Grid item sm={2} alignContent="center">
                        <Box pt="10px">
                          <InputNumero value={item.quantidade} precision="3" label="Quantidade" disabled />
                        </Box>
                      </Grid>
                      <Grid item sm={2} alignContent="center">
                        <Box pt="10px">
                          <InputNumero value={item.valor_uni_item} precision="2" label="Valor Unitário" disabled />
                        </Box>
                      </Grid>
                      <Grid item sm={1} alignContent="center">
                        <IconButton onClick={() => selecionarItem(item)} disabled={eEdicao}>
                          <Edit />
                        </IconButton>
                      </Grid>
                      {!eEdicao && (
                        <Grid item sm={1} alignContent="center">
                          <IconButton onClick={() => removerItem(index)}>
                            <Delete htmlColor="red" />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-around" alignItems="center">
              <Box display="flex" flexDirection="column" alignItems="center">
                <h2>Valor Total Itens</h2>
                <h1 style={{ color: '#42a5f5' }}>R$ {Formatter.formatNumber(2, state.somaValorItems())}</h1>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <h2>Valor Total Geral</h2>
                <h1 style={{ color: '#42a5f5' }}>R$ {Formatter.formatNumber(2, state.somaValorTotal())}</h1>
              </Box>
            </Box>

            <Flex>
              <Botao onClick={handleClose} variant="contained" cor={corBotaoVoltar}>
                Cancelar
              </Botao>
              <Botao onClick={avancarEtapa} variant="contained" cor={corBotaoAvancar}>
                {eDelecao ? 'Excluir' : 'Avançar'}
              </Botao>
            </Flex>
          </Box>
        </ModalBody>

        <SelecionarFornecedor
          open={modalFornecedor.state}
          handleClose={modalFornecedor.close}
          onConfirm={(fornecedor) => {
            state.fornecedor = fornecedor;
            modalFornecedor.close();
          }}
        />
        <ModalCompraInsumoItem
          open={modalDepesaItem.state}
          handleClose={fecharModalDespesasItem}
          handleAdd={adicionarItem}
          handleUpdate={atualizarItem}
          item={itemSelecionado}
          categoria={state.categoria?.value}
          valorFrete={state.valorFrete}
          outrosValores={state.outrosValores}
        />

        <Confirmar
          open={modalConfirmarExclusao.state}
          content={<RenderizarExcluirRegistro />}
          title="Tem certeza que deseja excluir?"
          handleClose={modalConfirmarExclusao.close}
          onCancel={modalConfirmarExclusao.close}
          onConfirm={cadastrar}
        />
      </Modal>
    );
  }

  function renderizarEtapa1() {
    return (
      <Modal onClose={handleClose} open={open}>
        <ModalHeader>
          <Box display="flex" justifyContent="space-between">
            <span>Pagamento de Parcelas</span>
          </Box>
        </ModalHeader>
        <ModalBody style={{ width: 950 }}>
          <Box width="100%">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box display="flex" flexDirection="column" alignItems="center">
                <h2>Valor Total</h2>
                <h1 style={{ color: '#42a5f5' }}>R$ {Formatter.formatNumber(2, state.somaValorTotal())}</h1>
              </Box>
            </Box>
            <br />
            <br />

            <Select
              placeholder="Conta Bancária"
              value={state.conta}
              onChange={(e: any) => state.setConta(e)}
              options={store.contas.getSelectRows()}
              disabled={desabilitarCampos}
            />
            <br />
            <Select
              onChange={(e: any) => state.setPagamento(e)}
              placeholder="Forma de pagamento"
              value={state.pagamento}
              defaultValue={store.tiposDePagamento.getSelectRows()[0]}
              options={store.tiposDePagamento.getSelectRows()}
              disabled={desabilitarCampos}
            />
            <TextField
              value={state.qtdParcelas}
              onChange={(e) => state.setQtdParcelas(parseInt(e.target.value))}
              type="number"
              label="Quantidade de parcelas"
              inputProps={{ min: 1 }}
              onBlur={() => !state.qtdParcelas && state.setQtdParcelas(1)}
              disabled={Boolean(editMovimentacao)}
            />

            {/* Criação de parcelas */}
            {!editMovimentacao
              ? state.parcelas.map((parcela, index) => (
                  <Parcela key={index}>
                    <Box mt="18px">
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        label="Data"
                        value={Formatter.stringToDate(parcela.data_pagamento)}
                        onChange={(date) => (state.parcelas[index].data_pagamento = Formatter.dateToString(date))}
                      />
                    </Box>

                    <Box mt="18px">
                      <InputNumero
                        value={typeof parcela.valor === 'string' ? parcela.valor : parcela.valor}
                        onChange={(value) => (state.parcelas[index].valor = value)}
                        precision="2"
                        label="Valor"
                        id={'parcela-' + index}
                      />
                    </Box>

                    <div
                      style={{
                        width: '100%',
                        marginTop: 18,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {store.contas.arr && (
                        <NewSelect
                          value={
                            store.contas.getSelectRows().filter((tipo) => parseInt(tipo.value) === parcela.conta)[0]
                          }
                          onChange={(e: any) => (state.parcelas[index].conta = parseInt(e.value))}
                          placeholder="Conta Banco"
                          options={store.contas.getSelectRows()}
                        />
                      )}
                      {store.tiposDePagamento.arr && (
                        <NewSelect
                          value={
                            store.tiposDePagamento
                              .getSelectRows()
                              .find((tipo) => parseInt(tipo.value) === parcela.pagamento_tipo) || null
                          }
                          onChange={(e: any) => (state.parcelas[index].pagamento_tipo = Number(e.value))}
                          placeholder="Forma de pagamento"
                          options={store.tiposDePagamento.getSelectRows()}
                          menuPlacement={index + 1 === state.parcelas.length ? 'top' : 'bottom'}
                        />
                      )}
                    </div>

                    <FormControlLabel
                      labelPlacement="top"
                      control={
                        <Checkbox
                          checked={parcela.recebido}
                          onChange={(e) => (state.parcelas[index].recebido = e.target.checked)}
                          color="primary"
                        />
                      }
                      label="Pago"
                    />
                  </Parcela>
                ))
              : null}

            {/* Edição de parcelas   */}
            {editMovimentacao
              ? state.parcelas.map((parcela, index) => (
                  <Parcela key={index}>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      label="Data"
                      value={Formatter.stringToDate(parcela.data_pagamento)}
                      onChange={(date) => (state.parcelas[index].data_pagamento = date!)}
                      disabled={parcela.recebido}
                    />

                    <InputNumero
                      value={typeof parcela.valor === 'string' ? parcela.valor : parcela.valor}
                      onChange={(value) => (state.parcelas[index].valor = value)}
                      precision="2"
                      label="Valor"
                      id={'parcela-' + index}
                      disabled
                    />

                    <div
                      style={{
                        width: '600px',
                        marginTop: 18,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'bottom',
                      }}
                    >
                      {store.contas.arr && (
                        //@typescript-eslint/no-unused-expressions
                        <NewSelect
                          margin={false}
                          value={
                            store.contas
                              .getSelectRows()
                              .filter((tipo) => parseInt(tipo.value) === state.parcelas[index].conta)[0]
                          }
                          onChange={(e: any) => (state.parcelas[index].conta = parseInt(e.value))}
                          placeholder="Conta Banco"
                          options={store.contas.getSelectRows()}
                          disabled={parcela.recebido || eDelecao}
                        />
                      )}

                      {store.tiposDePagamento.arr && (
                        <NewSelect
                          margin={false}
                          value={
                            store.tiposDePagamento
                              .getSelectRows()
                              .filter((tipo) => parseInt(tipo.value) === parcela.pagamento_tipo)[0]
                          }
                          onChange={(e: any) => (state.parcelas[index].pagamento_tipo = parseInt(e.value))}
                          placeholder="Forma de pagamento"
                          options={store.tiposDePagamento.getSelectRows()}
                          menuPlacement={index + 1 === state.parcelas.length ? 'top' : 'bottom'}
                          disabled={parcela.recebido || eDelecao}
                        />
                      )}

                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Checkbox
                            checked={parcela.recebido}
                            onChange={(e) => (state.parcelas[index].recebido = e.target.checked)}
                            color="primary"
                            style={{ height: '1px' }}
                            disabled={eDelecao}
                          />
                        }
                        style={{ marginBottom: '25px' }}
                        label="Pago"
                      />

                      <Box>
                        <IconButton
                          disabled={parcela.recebido || eDelecao}
                          onClick={() => {
                            state.props = {
                              ...state.parcelas[index],
                              data_pagamento: Formatter.stringToDate(state.parcelas[index].data_pagamento),
                            };
                            modalParceal.open();
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Box>
                    </div>
                  </Parcela>
                ))
              : null}

            {modalParceal.state ? (
              <ParcelaParcial
                open={modalParceal.state}
                handleClose={modalParceal.close}
                onConfirm={() => {
                  modalParceal.close();
                  handleClose();
                }}
                stateProps={state}
              />
            ) : null}

            <Flex>
              <Botao
                onClick={() => (soParcelas ? handleClose() : setEtapa(0))}
                variant="contained"
                cor={corBotaoVoltar}
              >
                {soParcelas ? 'Cancelar' : 'Voltar'}
              </Botao>
              <Botao onClick={cadastrar} variant="contained" cor={corBotaoAvancar}>
                Salvar
              </Botao>
            </Flex>
          </Box>
        </ModalBody>
      </Modal>
    );
  }

  function renderizarModal() {
    if (etapa === 0) return renderizarEtapa0();
    if (etapa === 1) return renderizarEtapa1();
    return null;
  }

  if (!state.sync) return null;

  return <>{renderizarModal()}</>;
};

export default observer(ModalCompraInsumo);
