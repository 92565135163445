import React from 'react';
import ReactSelect, { ActionMeta, components, FocusEventHandler, ValueType } from 'react-select';

type Props = {
  value?: SelectRow | null;
  defaultValue?: SelectRow;
  disabled?: boolean;
  placeholder?: string;
  options: SelectRow[];
  menuPlacement?: 'auto' | 'bottom' | 'top' | undefined;
  width?:boolean,
  onChange?(e: ValueType<SelectRow, false>, actionMeta: ActionMeta<SelectRow>): void;
  onFocus?: FocusEventHandler;
};

const { ValueContainer, Placeholder } = components;

const CustomValueContainer: React.FC<any> = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
    </ValueContainer>
  );
};

const Select: React.FC<Props> = ({
  value,
  defaultValue,
  disabled,
  placeholder,
  options,
  menuPlacement,
  onChange,
  onFocus,
  width
}) => {
  return (
    <ReactSelect
      styles={{
        menuList: (provided) => ({
          ...provided,
          height: '150px',

          '::-webkit-scrollbar': {
            width: '5px',
            height: '0px',
          },
          '::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#2EB8F8',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        }),
        control: (styles) => ({ ...styles }),
        container: (provided) => ({
          ...provided,
          margin: '5px 0',
          width:  width ? '300px' : '100%',
        }),
        valueContainer: (provided) => ({
          ...provided,
          overflow: 'visible',
        }),
        placeholder: (provided, state) => ({
          ...provided,
          position: 'absolute',
          top: state.hasValue || state.selectProps.inputValue ? -3 : '50%',
          background: state.isDisabled
            ? 'linear-gradient(0, hsl(0,0%,95%) 65%, #ffffff00 50%)'
            : 'linear-gradient(0, #ffffff 65%, #ffffff00 50%)',
          padding: '0 3px',
          transition: 'top 0.3s, font-size 0.1s',
          fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
        }),
      }}
      classNamePrefix="sel"
      value={value}
      defaultValue={defaultValue}
      isDisabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      menuPlacement={menuPlacement}
      options={options}
      components={{
        ValueContainer: CustomValueContainer,
      }}
      onFocus={onFocus}
    />
  );
};

export default Select;
