import React  from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import Botao from '../../../components/Botoes/Botao';
import useStore from '../../../services/hooks/useStore';
import { useLocalStore, observer } from 'mobx-react-lite';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { KeyboardDatePicker } from '@material-ui/pickers';
import add from 'date-fns/add';
import Select from '../../../components/Select';
import { TextField } from '@material-ui/core';
import Formatter from '../../../services/Formatter';
import { apiV2 } from '../../../services/utils';

type State = {
  nomeLote: string;
  fornecedor: SelectRow | null;
  especie: SelectRow | null;
  data: MaterialUiPickersDate;
  fase: SelectRow | null;
  diasPrevistos: number;
  dataPrevista: MaterialUiPickersDate;
};
type Props = ModalProps & {
  open: any;
  handleClose: any;
  novoLoteDestino: any;
  especieNome: any;
  especieID: any;
  fornecedorNome: any;
  fornecedorID: any;
  setLoteOrigem: any;
};
const ModalNovoLote: React.FC<Props> = ({
  open,
  handleClose,
  novoLoteDestino,
  especieNome,
  especieID,
  fornecedorNome,
  fornecedorID,
  setLoteOrigem,
}) => {
  const store = useStore();
  const state = useLocalStore(
    (): State => ({
      nomeLote: '',
      fornecedor: { label: fornecedorNome, value: fornecedorID },
      especie: { label: especieNome, value: especieID },
      data: new Date(),
      fase: null,
      diasPrevistos: 0,
      dataPrevista: new Date(),
    }),
  );

  async function cadastrar(): Promise<void> {
    store.toggleLoader();

    try {
      const data = {
        propriedade: store.propriedade!.id,
        codigo: state.nomeLote,
        data: Formatter.dateToString(state.data),
        dias_previstos: state.diasPrevistos,
        especie_explorada: state.especie!.value,
        fase_lote: state.fase!.value,
        fornecedor: state.fornecedor!.value,
        nome: state.nomeLote,
        peso_medio: 0,
        quantidade: 0,
        tamanho_medio: 0.1,
        valor_total: 0,
      };

      apiV2
        .post('/lote/', data, {
          params: {
            propriedade: store.propriedade!.id,
          },
        })
        .then((response) => novoLoteDestino(response.data.codigo, response.data.id));

      store.notificar('Lote cadastrado com sucesso!');
    } catch (err) {
      store.notificar('Ocorreu um erro ao cadastrar o lote!');
    }

    store.toggleLoader();
    handleClose();

    state.nomeLote = '';
    state.fornecedor = { label: fornecedorNome, value: fornecedorID };
    state.especie = { label: especieNome, value: especieID };
    state.data = new Date();
    state.fase = null;
    state.diasPrevistos = 0;
    state.dataPrevista = new Date();
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Cadastro de Lote</ModalHeader>
      <ModalBody>
        <TextField label="Nome do Lote" value={state.nomeLote} onChange={(e) => (state.nomeLote = e.target.value)} />

        <br />

        <Select
          value={state.especie}
          placeholder="Espécie"
          onChange={(e: any) => (state.especie = e)}
          options={store.especies.getSelectRows()}
          disabled
        />

        <br />

        <Select
          value={state.fornecedor}
          placeholder="Fornecedor"
          onChange={(e: any) => (state.fornecedor = e)}
          options={store.fornecedores.getSelectRows()}
          disabled
        />

        <br />

        <Select
          value={state.fase}
          placeholder="Fase"
          onChange={(e: any) => (state.fase = e)}
          options={store.fasesLote.getSelectRows()}
        />

        <br />

        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data"
          value={state.data}
          onChange={(date) => (state.data = date)}
        />

        <TextField
          value={state.diasPrevistos}
          onChange={(e: any) => {
            state.diasPrevistos = parseInt(e.target.value);
            state.dataPrevista = add(state.data!, { days: parseInt(e.target.value) });
          }}
          type="number"
          label="Dias Previstos"
          inputProps={{ min: 0 }}
        />

        <div style={{ margin: 10, textAlign: 'center' }}>
          <h2>Data Prevista</h2>
          <h1 style={{ color: '#42a5f5' }}>{Formatter.dateToString(state.dataPrevista, '/')}</h1>
        </div>

        <br />

        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao
            onClick={() => {
              setLoteOrigem(false);
              cadastrar();
            }}
            variant="contained"
            cor="#00C853"
          >
            Salvar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(ModalNovoLote);
