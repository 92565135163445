/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Header from '../../../components/Header';
import { Flex } from '../../../components/NavBar/styles';
import AddBtn from '../../../components/Botoes/AddBtn';
import { Body } from '../../../components/Body';
import { config } from '../../../components/Tabela';
import useStore from '../../../services/hooks/useStore';
import MaterialTable, { Column } from 'material-table';
import { useLocalStore, observer } from 'mobx-react-lite';
import useModal from '../../../services/hooks/useModal';
import ActionButton from '../../../components/Tabela/ActionButton';
import Confirmar from '../../../components/Modais/Confirmar';
import FormConta from './Form';
import { useHistory } from 'react-router-dom';
import syncModel from '../../../services/models/Sync';
import { Checkbox, FormControlLabel } from '@mui/material';
import { permissoes } from '../../../services/utils/permissoes';

const Temperatura: React.FC = () => {
  const state = useLocalStore(() => ({
    pageSize: 0,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
    selectedConta: 0,
    filtroRapidoFinalizado: false,
    ativo: 0,
    sync: false,
  }));
  const modalConta = useModal();
  const modalExclusao = useModal();
  const store = useStore();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.QUALIDADE_E_SANIDADE_TEMPERATURA_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.QUALIDADE_E_SANIDADE_TEMPERATURA_ESCRITA);

  const columns: Column<object>[] = [
    {
      field: 'temperatura',
      align: 'right',
      title: 'Temperatura',
      ...(true && ({ width: 10 } as object)),
    },
    {
      field: 'acoes',
      render: (rowData: any) => (
        <ActionButton
          editar={
            temPermissaoEscrita
              ? () => {
                  state.selectedConta = rowData.id;
                  modalConta.open();
                }
              : undefined
          }
          excluir={
            temPermissaoEscrita && rowData.padrao
              ? undefined
              : () => {
                  if (rowData.editavel) {
                    state.selectedConta = rowData.id;
                    modalExclusao.open();
                  } else {
                    store.notificar('Não pode excluir essa Temperatura!');
                  }
                }
          }
        />
      ),
      ...(true && ({ width: 10 } as object)),
    },
    {
      field: 'racao',
      title: '% Ração',
      type: 'numeric',

      ...(true && ({ width: 10 } as object)),
    },

    {
      align: 'right',
      field: 'is_padrao',
      title: 'Padrão',
      render: ({ is_padrao }: any) => {
        return !is_padrao ? <b style={{ color: 'red' }}>Não</b> : <b style={{ color: 'green' }}>Sim</b>;
      },
      ...(true && ({ width: 10 } as object)),
    },
    {
      align: 'right',
      field: 'ativo',
      title: 'Situação',
      render: ({ ativo }: any) => {
        return !ativo ? <b style={{ color: 'red' }}>Inativo</b> : <b style={{ color: 'green' }}>Ativo</b>;
      },
      ...(true && ({ width: 10 } as object)),
    },
  ];
  const rows = (temperatura: Temperaturas[]): object[] => {
    if (temperatura.length) {
      if (temperatura.length >= 50) {
        state.setPageSize(50);
      } else {
        state.setPageSize(temperatura.length);
      }
    } else {
      state.setPageSize(0);
    }

    if (!state.filtroRapidoFinalizado) {
      const find = temperatura.filter((item) => {
        return item.ativo === true;
      });

      return find
        .sort((a, b) => {
          if (a.temperatura > b.temperatura) return 1;
          if (a.temperatura < b.temperatura) return -1;
          return 0;
        })
        .map((temperatura: any) => {
          return {
            id: temperatura.id,
            is_padrao: temperatura.is_padrao,
            racao: temperatura.racao,
            ativo: temperatura.ativo,
            temperatura: temperatura.temperatura,
            editavel: temperatura.removivel,
          };
        });
    } else {
      return temperatura
        .sort((a, b) => {
          if (a.temperatura > b.temperatura) return 1;
          if (a.temperatura < b.temperatura) return -1;
          return 0;
        })
        .map((temperatura: Temperaturas) => {
          return {
            id: temperatura.id,
            is_padrao: temperatura.is_padrao,
            racao: temperatura.racao,
            ativo: temperatura.ativo,
            temperatura: temperatura.temperatura,
            editavel: temperatura.removivel,
          };
        });
    }
  };

  async function sync(): Promise<void> {
    await syncModel.temperaturas();

    state.sync = true;
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Temperatura da Água'), []);

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  const ExcluirConta: React.FC = () => {
    const conta = store.temperatura.get(state.selectedConta);

    return (
      <div style={{ width: '100%' }}>
        <b>Temperatura: </b> {conta.temperatura}
        <br />
        <b>% Ração: </b> {conta.racao} <br />
        <b>Padrao: </b>{' '}
        {conta.is_padrao ? <b style={{ color: 'red' }}>Padrão</b> : <b style={{ color: 'red' }}>Não Padrão</b>}
        <br />
        <b>Status: </b>{' '}
        {!conta.ativo ? <b style={{ color: 'red' }}>Inativo</b> : <b style={{ color: 'green' }}>Ativo</b>}
        <br />
      </div>
    );
  };

  return state.sync ? (
    <>
      <Header>
        <Flex>
          <AddBtn
            disabled={!temPermissaoEscrita}
            onClick={() => {
              state.selectedConta = 0;
              modalConta.open();
            }}
            text="ADICIONAR"
            Icon={AddIcon}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.filtroRapidoFinalizado}
                onChange={(_e, checked) => {
                  if (state.filtroRapidoFinalizado) {
                    state.filtroRapidoFinalizado = false;
                    state.ativo = 0;
                  } else {
                    state.filtroRapidoFinalizado = checked;
                    state.ativo = 1;
                  }
                }}
                color="primary"
              />
            }
            label="Mostrar Todos"
          />
        </Flex>
      </Header>

      <Body>
        <div style={{ overflowX: 'hidden', borderRadius: '10px' }}>
          {store.temperatura.arr && rows && (
            <MaterialTable
              columns={columns}
              data={rows(store.temperatura.arr as any)}
              options={{ ...config.options, pageSize: state.pageSize }}
              localization={config.localization}
              style={config.style}
            />
          )}
        </div>
      </Body>
      {modalConta.state && (
        <FormConta open={modalConta.state} handleClose={modalConta.close} contaID={state.selectedConta} />
      )}
      <Confirmar
        open={modalExclusao.state}
        content={<ExcluirConta />}
        title="Tem certeza que deseja excluir?"
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={async () => {
          modalExclusao.close();

          await store.temperatura.excluir(state.selectedConta);
        }}
      />
    </>
  ) : null;
};

export default observer(Temperatura);
