import { getThingsV2 } from '../utils';
import { observable, computed } from 'mobx';

class Usuarios {
  @observable arr: RawUser[] | null = null;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(): Promise<void> {
    this.arr = await getThingsV2<RawUser>('/usuario/');
  }

  async getEverything(params?: object): Promise<RawUser[]> {
    return await getThingsV2<RawUser>('/usuario/', params);
  }

  get(id: number): RawUser {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  getSelectRows(): SelectRow[] {
    return this.arr!.slice()
      .sort((a: RawUser, b: RawUser) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      })
      .map((item: RawUser) => {
        return { label: item.nome, value: item.id.toString() };
      });
  }

  sort(callback: (a: any, b: any) => any): any {
    return this.arr!.sort(callback);
  }

  filter(callback: (item: RawUser, index: number, array: RawUser[]) => void): RawUser[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: RawUser, index: number, array: RawUser[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default Usuarios;
