import React from 'react';
import useStore from '../../../services/hooks/useStore';
interface TipoExclusaoCompra {
  compraID?: number | null;
}

const ExcluirCompra: React.FC<TipoExclusaoCompra> = ({ compraID }) => {
  const store = useStore();
  const registro = compraID ? store.comprasDeInsumos.get(compraID) : null;

  return (
    <div style={{ width: '100%' }}>
      <b>Tipo:</b> {registro && registro.tipo ? 'Ajuste de Estoque' : 'Compra de Insumo'}
      <br />
      <b>Insumo: </b> {registro && registro.insumo.nome}
      <br />
      <b>Quantidade: </b> {registro && registro.total_kg}
      <br />
      <b>Data: </b> {registro && registro.data}
      <br />
      <b>Fornecedor: </b> {registro && registro.fornecedor.label}
      <br />
    </div>
  );
};

export default ExcluirCompra;
