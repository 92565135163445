import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Conteudo, NewBody } from '../../../components/Body';
import { Checkbox, Grid, IconButton, Paper } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Flex } from '../../../components/NavBar/styles';
import { useHistory } from 'react-router';
import useStore from '../../../services/hooks/useStore';
import { apiV2 } from '../../../services/utils';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import SelectLote from '../../../components/Select/SelectLote';
import Select from '../../../components/Select';
import { returnQuantidade } from '../../../services/utils/producao';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { LoteTanqueItem } from './styles';
import { evaluate } from 'mathjs';
import Formatter from '../../../services/Formatter';
import { returnTanque } from '../../../services/utils/propriedade';
import Botao from '../../../components/Botoes/Botao';
import { Scroll } from '../../../components/Modais/styles';
import InputNumero from '../../../components/InputNumero';
import { Alert } from '@material-ui/lab';

interface Values {
  programa: SelectRow | null;
  temperatura: SelectRow | null;
  lote: string;
  dataInicio: Date;
  dataFinal: Date;
  tanque_data: { lote_tanque: number; tanque: number }[];
  quantidade: number;
  quantidadeDias: number;
  sobrevivencia: number;
  semanas: any[];
  opcoesSemanas: any[];
}

interface AutoUpdateProps {
  setQuantidadeAnimais: React.Dispatch<React.SetStateAction<number>>;
  temperaturas: any[];
  programas: any[];
}

const AutoUpdateRacao: React.FC<AutoUpdateProps> = ({ setQuantidadeAnimais, temperaturas, programas }) => {
  const store = useStore();
  const { values, setFieldValue } = useFormikContext<Values>();

  useEffect(() => {
    async function getDataInicial(): Promise<void> {
      store.toggleLoader();
      const response = await apiV2.get('/arracoamento-programa/data-inicial', {
        params: {
          programa: values.programa?.value,
          lote: values.lote,
          prop: store.propriedade!.id,
        },
      });
      store.toggleLoader();

      const programa = programas.find((programa: any) => programa.id === Number(values.programa!.value));
      const opcoesSemanas = programa.semanas.filter((semana: any) => semana.pm_fim > response.data.pesoMedio);
      const semanas = programa.semanas.filter((semana: any) => semana.pm_fim > response.data.pesoMedio);

      setFieldValue('dataInicio', new Date(response.data?.dataInicial));
      setFieldValue('opcoesSemanas', opcoesSemanas);
      setFieldValue('semanas', semanas);
      setFieldValue('quantidadeDias', semanas.length);
    }

    if (values.programa && values.lote) getDataInicial();
    // eslint-disable-next-line
  }, [values.programa, values.lote]);

  useEffect(() => {
    const programa = programas.find((programa: any) => programa.id === Number(values.programa!.value));

    if (programa) {
      const semanas = values.opcoesSemanas.slice(0, values.quantidadeDias);

      setFieldValue('semanas', semanas);
    }
    // eslint-disable-next-line
  }, [values.quantidadeDias]);

  useEffect(() => {
    if (values.lote) {
      const lote = store.lotes.get(Number(values.lote));
      const programa = values.programa
        ? programas.find((programa) => programa.id === Number(values.programa?.value))
        : null;
      const isSemanal = programa ? programa.slug === 'SEMANAL' : false;
      const temperatura = values.temperatura
        ? temperaturas.find((temp) => temp.id === Number(values.temperatura!.value)).racao / 100
        : 1;
      const sobrevivencia = values.sobrevivencia ? values.sobrevivencia / 100 : 1;
      const newTanqueData: any[] = [];
      let newQuantidade = 0;

      store.loteTanque.getByLote(lote.id).forEach((loteTanque) => {
        const qtd = returnQuantidade(loteTanque, false);

        if (qtd) {
          newTanqueData.push({ lote_tanque: loteTanque.id, tanque: loteTanque.tanque.id });
          newQuantidade += qtd;
        }
      });

      const newQuantidadeRacao = values.semanas.reduce((prev, next: any) => {
        if (isSemanal) {
          return (
            prev +
            evaluate(
              `((${next.pm_fim} - ${next.pm_ini}) * ${next.ca_esperado} * ${sobrevivencia} * ${newQuantidade} * ${temperatura}) / 1000`,
            )
          );
        } else {
          return prev + evaluate(`(${next.consumo_g} * ${newQuantidade} * ${temperatura} * ${sobrevivencia}) / 1000`);
        }
      }, 0);

      setQuantidadeAnimais(newQuantidade);

      setFieldValue('tanque_data', newTanqueData);
      setFieldValue('quantidade', newQuantidadeRacao);
    }
    // eslint-disable-next-line
  }, [values.lote]);

  useEffect(() => {
    const programa = values.programa
      ? programas.find((programa) => programa.id === Number(values.programa?.value))
      : null;
    const isSemanal = programa ? programa.tipo.slug === 'SEMANAL' : false;
    const temperatura = values.temperatura
      ? temperaturas.find((temp) => temp.id === Number(values.temperatura!.value)).racao / 100
      : 1;
    const sobrevivencia = values.sobrevivencia ? values.sobrevivencia / 100 : 1;
    const newQuantidadeAnimais = values.tanque_data.reduce((prev: number, next) => {
      return prev + returnQuantidade(store.loteTanque.get(next.lote_tanque), false);
    }, 0);
    const newQuantidadeRacao = values.semanas.reduce((prev, next: any) => {
      if (isSemanal) {
        return (
          prev +
          evaluate(
            `((${next.pm_fim} - ${next.pm_ini}) * ${next.ca_esperado} * ${sobrevivencia} * ${newQuantidadeAnimais} * ${temperatura}) / 1000`,
          )
        );
      } else {
        return (
          prev + evaluate(`(${next.consumo_g} * ${newQuantidadeAnimais} * ${temperatura} * ${sobrevivencia}) / 1000`)
        );
      }
    }, 0);

    const dataFinalArracoamento = new Date(values.dataInicio);
    dataFinalArracoamento.setDate(
      dataFinalArracoamento.getDate() + (isSemanal ? values.semanas.length * 7 - 1 : values.semanas.length - 1),
    );

    setFieldValue('quantidade', newQuantidadeRacao);
    setQuantidadeAnimais(newQuantidadeAnimais);
    setFieldValue('dataFinal', dataFinalArracoamento);
    // eslint-disable-next-line
  }, [values.semanas, values.tanque_data, values.temperatura, values.sobrevivencia]);

  useEffect(() => {
    if (temperaturas.length) {
      const temp = temperaturas.find((temperatura: any) => temperatura.is_padrao);

      if (temp) {
        setFieldValue('temperatura', {
          label: temp.temperatura + '° - ' + temp.racao + '%',
          value: temp.id.toString(),
        });
      }
    }
    // eslint-disable-next-line
  }, [temperaturas]);

  return null;
};

function CadastroArracoamentoPrograma(): JSX.Element {
  const store = useStore();
  const history = useHistory();
  const [programas, setProgramas] = useState<any[]>([]);
  const [temperaturas, setTemperaturas] = useState<any[]>([]);
  const [quantidadeAnimais, setQuantidadeAnimais] = useState(0);
  const [tipo, setTipo] = useState([{ label: 'Por Tanque', value: 'novo-tanque' }]) as any;
  const initialValues: Values = {
    programa: null,
    lote: '',
    dataInicio: new Date(),
    dataFinal: new Date(),
    tanque_data: [],
    quantidade: 0,
    quantidadeDias: 0,
    temperatura: null,
    sobrevivencia: 0,
    semanas: [],

    opcoesSemanas: [],
  };

  function handleBack(): void {
    history.replace('/producao/arracoamento');
  }

  async function sync(): Promise<void> {
    store.toggleLoader();

    if (!store.lotes.arr || !store.tanques.arr || !store.loteTanque.arr)
      await Promise.all([store.loteTanque.populate(), store.lotes.populate(), store.tanques.populate()]);

    let programas = [] as any;

    await apiV2
      .get('/programas-alimentares/', {
        params: { prop: store.propriedade!.id, page_size: 50, page: 1 },
      })
      .then(async (res) => {
        const data = res.data;
        if (res.data.next) {
          do {
            const request = await apiV2.get(data.next);

            data.results = [...data.results, ...request.data.results];
            data.next = request.data.next;
          } while (data.next !== null);
        }
        if (!data.results) {
          programas.push(...data);
        } else {
          programas.push(...data.results);
        }
      });
    const temperaturas = await apiV2.get('/temperaturas', {
      params: { prop: store.propriedade!.id, total: true },
    });

    setProgramas(programas.data.results);
    setTemperaturas(temperaturas.data.results);

    store.toggleLoader();
  }

  async function handleSubmit(values: Values, { setSubmitting }: FormikHelpers<any>): Promise<void> {
    if (!values.programa) {
      store.notificar('Informe o programa alimentar');
    } else if (!values.lote) {
      store.notificar('Informe o lote');
    } else if (!values.temperatura) {
      store.notificar('Informe a temperatura');
    } else if (!values.tanque_data.length) {
      store.notificar('Informe os tanques');
    } else if (!values.semanas.length) {
      store.notificar('Informe os dias/semanas');
    } else {
      store.toggleLoader();

      try {
        const data = {
          tanque_data: values.tanque_data,
          programa: Number(values.programa.value),
          temperatura_id: Number(values.temperatura.value),
          lote: Number(values.lote),
          dataInicio: Formatter.dateToString(values.dataInicio),
          dataFinal: Formatter.dateToString(values.dataFinal),
          quantidade: values.quantidade,
          semanas: values.semanas,
        };

        await apiV2.post('/arracoamento/programa/', data, {
          params: {
            prop: store.propriedade!.id,
          },
        });

        store.notificar('Arraçoamentos cadastrados com sucesso!');
        handleBack();
      } catch (err) {
        store.notificar('Ocorreu um erro ao cadastrar o arraçoamento!');
      }

      store.toggleLoader();
      setSubmitting(false);
    }
  }

  useEffect(() => {
    store.propriedade && sync();
    // eslint-disable-next-line
  }, [store.propriedade]);

  return (
    <NewBody>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Grid container justify="flex-start">
          <Grid item>
            <IconButton onClick={handleBack}>
              <ChevronLeftIcon />
            </IconButton>
          </Grid>

          <Grid item>
            <h1 style={{ color: '#383838' }}>Arraçoamento com programa</h1>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <Select
            value={tipo}
            defaultValue={{ label: 'Por Tanque', value: 'novo-tanque' }}
            placeholder="Tipo Arraçoamento"
            options={[
              // { label: 'Por Lote', value: 'lote' },
              { label: 'Por Tanque', value: 'novo-tanque' },
            ]}
            onChange={(value) => {
              setTipo(value);
              if (value?.value === 'tanque') {
                history.push('/producao/novo-arracoamento-programa-por-tanque');
              } else if (value?.value === 'novo-tanque') {
                history.push('/producao/v2-novo-arracoamento-programa-por-tanque');
              }
            }}
          ></Select>
        </Grid>
      </Flex>

      <Conteudo overflowY>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, setFieldValue }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <h2 style={{ color: '#383838' }}>Dados do arraçoamento</h2>
                  <br />
                  <Select
                    value={values.programa}
                    placeholder="Programa Alimentar"
                    onChange={(e) => {
                      setFieldValue('programa', e);
                      setFieldValue('semanas', []);
                      setFieldValue('quantidade', 0);
                    }}
                    options={programas.map((programa) => {
                      const isSemanal = programa.tipo.slug === 'SEMANAL';
                      const isUnique = programa.semanas.length === 1;
                      const strings = ['semanas', 'semana', 'dias', 'dia'];
                      const dias = `${programa.semanas.length} ${
                        isSemanal ? (isUnique ? strings[1] : strings[0]) : isUnique ? strings[3] : strings[2]
                      }`;

                      return { label: `${programa.nome} - ${dias}`, value: programa.id.toString() };
                    })}
                  />
                  <SelectLote onChange={(value) => setFieldValue('lote', Number(value))} />
                  <Select
                    value={values.temperatura}
                    placeholder="Temperatura"
                    onChange={(e) => {
                      setFieldValue('temperatura', e);
                    }}
                    options={temperaturas.map((temp) => ({
                      label: temp.temperatura + '° - ' + temp.racao + '%',
                      value: temp.id.toString(),
                    }))}
                  />
                  <InputNumero
                    value={values.sobrevivencia}
                    onChange={(val) => setFieldValue('sobrevivencia', val)}
                    label="% Sobrevivência"
                    precision="2"
                  />
                  <InputNumero
                    value={values.quantidadeDias}
                    onChange={(val) => setFieldValue('quantidadeDias', val)}
                    label="Quantidade de Dias/Semanas"
                    precision="0"
                  />
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Data de início"
                    value={values.dataInicio}
                    disabled={!values.programa}
                    onChange={(date) => {
                      const isSemanal =
                        programas.find((programa) => programa.id === Number(values.programa?.value))?.tipo.slug ===
                        'SEMANAL';
                      const dataFinalArracoamento = new Date(date!);
                      dataFinalArracoamento.setDate(
                        dataFinalArracoamento.getDate() +
                          (isSemanal ? values.semanas.length * 7 - 1 : values.semanas.length - 1),
                      );
                      setFieldValue('dataFinal', dataFinalArracoamento);
                      setFieldValue('dataInicio', date);
                    }}
                  />
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Data final prevista"
                    value={values.dataFinal}
                    disabled={values.semanas.length === 1 ? false : true}
                    onChange={(date) => {
                      const dataFinalArracoamento = new Date(date!);
                      setFieldValue('dataFinal', dataFinalArracoamento);
                    }}
                  />

                  <div style={{ margin: 10, textAlign: 'center' }}>
                    <p style={{ fontSize: 16, color: '#383838' }}>Quantidade total de ração</p>
                    <b style={{ fontSize: 22, color: '#42a5f5' }}>{Formatter.formatNumber(3, values.quantidade)} kg</b>
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <h2 style={{ color: '#383838' }}>Seleção de semanas/dias</h2>
                  <br />

                  <Scroll style={{ maxHeight: 'calc(100vh - 280px)' }}>
                    {values.opcoesSemanas.length ? (
                      values.opcoesSemanas.map((semana: any) => {
                        const isSemanal = semana.tipo?.slug === 'SEMANAL';
                        const selected = values.semanas.some((item: any) => item.id === semana.id);
                        const temperatura = values.temperatura
                          ? temperaturas.find((temp) => temp.id === Number(values.temperatura!.value)).racao / 100
                          : 1;
                        const sobrevivencia = values.sobrevivencia
                          ? values.sobrevivencia / 100
                          : semana.sobrevivencia / 100;
                        let quantidadeRacao = 0;

                        if (isSemanal) {
                          quantidadeRacao = evaluate(
                            `((${semana.pm_fim} - ${semana.pm_ini}) * ${semana.ca_esperado} * ${sobrevivencia} * ${quantidadeAnimais} * ${temperatura}) / 1000 / 7`,
                          );
                        } else {
                          quantidadeRacao = evaluate(
                            `(${semana.consumo_g} * ${quantidadeAnimais} * ${temperatura} * ${sobrevivencia}) / 1000`,
                          );
                        }

                        const data = new Date(values.dataInicio);
                        const dataFim = new Date(values.dataInicio);
                        const selectedIndex = values.semanas.findIndex((item: any) => item.id === semana.id);

                        if (isSemanal) {
                          data.setDate(data.getDate() + (selectedIndex ? selectedIndex * 7 : 0));
                          dataFim.setDate(data.getDate() + 6);
                        } else {
                          data.setDate(data.getDate() + selectedIndex);
                        }

                        function handleClickSemana(): void {
                          let newSemanas: any[] = [...values.semanas];

                          if (newSemanas.some((item: any) => item.id === semana.id)) {
                            newSemanas = newSemanas.filter((item: any) => item.id !== semana.id);
                          } else {
                            newSemanas.push(semana);
                          }

                          newSemanas = newSemanas.sort(
                            (a, b) => Number(a.codigo.substr(1)) - Number(b.codigo.substr(1)),
                          );

                          const dataFinalArracoamento = new Date(values.dataInicio);
                          dataFinalArracoamento.setDate(
                            dataFinalArracoamento.getDate() +
                              (isSemanal ? newSemanas.length * 7 - 1 : newSemanas.length - 1),
                          );
                          setFieldValue('dataFinal', dataFinalArracoamento);
                          setFieldValue('semanas', newSemanas);
                        }

                        return (
                          <Flex key={semana.codigo}>
                            <Checkbox color="primary" checked={selected} onChange={handleClickSemana} />
                            <Paper
                              elevation={3}
                              style={{
                                marginBottom: 10,
                                padding: 20,
                                width: '100%',
                                border: selected ? '1px solid green' : undefined,
                                cursor: 'pointer',
                              }}
                              onClick={handleClickSemana}
                            >
                              <Grid container spacing={0}>
                                <Grid item xs={12} style={{ display: 'flex' }} justify="space-between">
                                  <b>{semana.codigo}</b>
                                  <span>
                                    {selected &&
                                      (isSemanal
                                        ? Formatter.dateToString(data, '/') +
                                          ' - ' +
                                          Formatter.dateToString(dataFim, '/')
                                        : Formatter.dateToString(data, '/'))}
                                  </span>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex' }} justify="space-between">
                                  <p>{semana.racao.nome}</p>
                                  <p>Qtde Ração dia: {Formatter.formatNumber(3, quantidadeRacao)} kg</p>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex' }} justify="space-between">
                                  <p>
                                    <b>Fase: </b>
                                    {semana.fase.nome}
                                  </p>
                                  <p>
                                    <b>N° Tratos e kg: </b>
                                    {semana.num_tratos}x -{' '}
                                    {Formatter.formatNumber(3, quantidadeRacao / semana.num_tratos)} kg
                                  </p>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                  <p>
                                    <b>PM: </b>
                                    {Formatter.formatNumber(3, semana.pm_ini)} g -{' '}
                                    {Formatter.formatNumber(3, semana.pm_fim)} g
                                  </p>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Flex>
                        );
                      })
                    ) : values.programa ? (
                      <Alert severity="warning">
                        Não foram encontrados Dias/Semanas compatíveis com o Peso Médio e data de arraçoamento do lote!
                      </Alert>
                    ) : (
                      <Alert severity="info">Selecione um programa alimentar!</Alert>
                    )}
                  </Scroll>
                </Grid>
                <Grid item xs={4}>
                  <h2 style={{ color: '#383838' }}>Seleção de tanques</h2>
                  <br />

                  <Scroll style={{ maxHeight: 'calc(100vh - 250px)' }}>
                    {values.lote ? (
                      store.loteTanque.getByLote(Number(values.lote)).map((loteTanque) => {
                        const selected = values.tanque_data.some((tanque) => tanque.lote_tanque === loteTanque.id);
                        const quantidade = returnQuantidade(store.loteTanque.get(loteTanque.id), false);

                        function handleChange(_e: any, checked: boolean): void {
                          let newTanqueData = [...values.tanque_data];

                          if (checked) {
                            newTanqueData.push({ lote_tanque: loteTanque.id, tanque: loteTanque.tanque.id });
                          } else {
                            newTanqueData = newTanqueData.filter((item) => item.lote_tanque !== loteTanque.id);
                          }

                          setFieldValue('tanque_data', newTanqueData);
                        }

                        return quantidade ? (
                          <LoteTanqueItem style={{ gridTemplateColumns: '50px 1fr 1fr' }} key={loteTanque.id}>
                            <Checkbox color="primary" checked={selected} onChange={handleChange} />
                            <p>{returnTanque(store.tanques.get(loteTanque.tanque.id)!)}</p>
                            <p>
                              {Formatter.formatNumber(0, quantidade)}
                              un
                            </p>
                          </LoteTanqueItem>
                        ) : null;
                      })
                    ) : (
                      <Alert severity="info">Selecione um lote!</Alert>
                    )}
                  </Scroll>
                </Grid>
              </Grid>

              <AutoUpdateRacao
                temperaturas={temperaturas}
                setQuantidadeAnimais={setQuantidadeAnimais}
                programas={programas}
              />

              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Botao cor="gray" onClick={handleBack} variant="contained">
                  Voltar
                </Botao>
                <Botao type="submit" variant="contained" cor="#00C853">
                  Salvar
                </Botao>
              </div>
            </Form>
          )}
        </Formik>
      </Conteudo>
    </NewBody>
  );
}

export default observer(CadastroArracoamentoPrograma);
