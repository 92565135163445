export function calcularPLsPorGrama(biomassa: number, pesoMedio: number): number {
  // Convertendo a biomassa para gramas
  const biomassaEmGramas = biomassa * 1000;

  // Calculando a quantidade
  const quantidade = biomassaEmGramas / pesoMedio;

  // Calculando PLs por Grama
  const plsPorGrama = quantidade / biomassaEmGramas;

  return Number(plsPorGrama.toFixed(3));
}
