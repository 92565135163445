import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import { observer, useLocalStore } from 'mobx-react-lite';
import Botao from '../../../components/Botoes/Botao';
import useStore from '../../../services/hooks/useStore';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { api } from '../../../services/utils';
import X9Logger from '../../../services/X9Logger';

type Props = ModalProps & {
  lote: Lote;
  atualizarFaseAsync(): void;
};
type State = {
  faseLote: number;
  novaFase: number;
};
const AtualizarFase: React.FC<Props> = ({ open, handleClose, lote,atualizarFaseAsync }) => {
  const store = useStore();
  const state = useLocalStore(
    (): State => ({
      faseLote: 0,
      novaFase: 0,
    }),
  );

  useEffect(() => {
    const fase = lote.fase_lote.id;

    state.faseLote = fase;
    state.novaFase = fase;

    // eslint-disable-next-line
  }, []);

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Atualizar a fase do lote</ModalHeader>
      <ModalBody style={{ minWidth: '400px' }}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Selecione a nova fase abaixo:</FormLabel>
          <RadioGroup
            value={state.novaFase}
            onChange={(e) => {
              state.novaFase = parseInt(e.target.value);
            }}
          >
            {store.fasesLote.getSelectRows().map((fase) => {
              return (
                <FormControlLabel
                  key={fase.value}
                  checked={state.novaFase === parseInt(fase.value)}
                  value={fase.value}
                  control={<Radio color="primary" />}
                  label={fase.label}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao
            onClick={async () => {
              try {
                handleClose();
                store.toggleLoader();
                await api.patch(`/lote/${lote.uuid}/?propriedade=${store.propriedade!.id}`, {
                  fase_lote: store.fasesLote.get(state.novaFase)?.id,
                });
                await store.lotes.populate();
                store.toggleLoader();
              } catch (err) {
                X9Logger.report(err as Error);
                store.notificar('Não foi possível atualizar a fase do lote. Tente novamente mais tarde.');
                store.toggleLoader();
              }
              atualizarFaseAsync()
            }}
            variant="contained"
            cor="#00C853"
          >
            Salvar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(AtualizarFase);
