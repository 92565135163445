import styled from 'styled-components';

export const Body = styled.div`
  padding: 15px;
  background-color: white;
  height: 100%;
`;

export const MenuGrade = styled.div`
  display: grid;
  width: 100%;
  height: 516px;
  padding: 5%;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
`;

export const HomeLink = styled.span`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 18px;
  color: #696969;
  text-decoration: none;
  width: max-content;
  margin: 0 auto;
`;

type Props = {
  disabled?: boolean;
};
export const SquareContainer = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 140px;
  height: 140px;

  background-color: ${(props) => (props.disabled ? '#e5e5e5' : '#f7f7f7')};
  border: ${(props) => (props.disabled ? 'none' : '2px solid #b5b5b5')};
  border-radius: 20%;

  box-shadow: 0 4px 6px 0 #00000021;
  transition: 0.3s;
  margin-bottom: 10px;

  img {
    width: 82px;
    height: 82px;
  }

  &:hover {
    box-shadow: 0 8px 20px 2px #0000001a;
    transform: scale(1.05);
  }
`;
