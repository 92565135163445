import styled from 'styled-components';

export const GridDados = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  margin: 15px 0;
  margin-bottom: 40px;
`;

export const DadosCompletos = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0px 29px 50px rgba(0, 0, 0, 0.05);

  padding: 10px;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #656565;
    margin: 0 10px;
  }

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 25px;

    display: flex;
    align-items: flex-end;

    color: #42a5f5;
    margin: 0 10px;
  }
`;

export const DadosCompactos = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0px 29px 50px rgba(0, 0, 0, 0.05);

  padding: 15px;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    color: #656565;
  }

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;

    display: flex;
    align-items: flex-end;

    color: #42a5f5;
  }
`;
