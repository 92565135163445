import { apiV2, getThingsV2 } from '../utils';
import { stateContext } from './../hooks/useStore';
import { observable, computed } from 'mobx';

class Racoes {
  @observable arr: Racao[] | null = null;
  @observable totalRes: number = 0;
  @computed
  get length(): number {
    return this.arr ? this.arr.length : 0;
  }

  async populate(ativo?: number): Promise<void> {
    this.arr = await getThingsV2<Racao>('/racao/', {
      propriedade: stateContext.state.propriedade!.id,
      ativo: ativo,
    });
  }

  async getEverything(params?: object): Promise<Racao[]> {
    return await getThingsV2<Racao>('/racao/', params);
  }
  /*  getPaginated*/

  async getPaginated(page: number, pageSize: number, propriedadeId: number, filterURL?: string): Promise<void> {
    const baseUrl = `/produto/?propriedade=${propriedadeId}`;
    const urlArray = [];
    urlArray.push(baseUrl);

    if (filterURL) {
      urlArray.push(filterURL);
    }

    const requestUrl = urlArray.join('');

    apiV2
      .get(requestUrl, {
        params: {
          page_size: pageSize,
          page: page + 1,
          prop: propriedadeId,
          ativo: true,
        },
      })
      .then((res: any) => {
        this.totalRes = res.data.count;
        this.arr = res.data.results;
      });
  }

  get(id: number): Racao {
    return this.arr!.filter((item) => item.id === id)[0];
  }

  getSelectRows(): SelectRow[] {
    return this.arr!.map((item) => {
      return { label: item.nome, value: item.id.toString() };
    });
  }

  filter(callback: (item: Racao, index: number, array: Racao[]) => void): Racao[] {
    return this.arr!.filter(callback);
  }

  map(callback: (item: Racao, index: number, array: Racao[]) => any): any {
    return this.arr!.map(callback);
  }
}

export default Racoes;
