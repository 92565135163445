export function calcularPesoMedio(biomassa: number, PLsPorGrama: number): number {
  // Convertendo a biomassa para gramas
  const biomassaEmGramas = biomassa * 1000;

  // Calculando a quantidade
  const quantidade = PLsPorGrama * biomassaEmGramas;

  // Calculando o Peso Médio
  const pesoMedio = biomassaEmGramas / quantidade;

  return Number(pesoMedio.toFixed(3));
}
