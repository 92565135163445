import React, { useEffect, useRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Header from '../../../components/Header';
import { Flex } from '../../../components/NavBar/styles';
import AddBtn from '../../../components/Botoes/AddBtn';
import { Body, Conteudo } from '../../../components/Body';
import useStore from '../../../services/hooks/useStore';
import MaterialTable, { Column } from 'material-table';
import { useLocalStore, observer } from 'mobx-react-lite';
import useModal from '../../../services/hooks/useModal';
import ActionButton from '../../../components/Tabela/ActionButton';
import Confirmar from '../../../components/Modais/Confirmar';
import FormConta from './FormConta';
import { useHistory } from 'react-router-dom';
import syncModel from '../../../services/models/Sync';
import Formatter from '../../../services/Formatter';
import Select from '../../../components/Select';
import { apiV2 } from '../../../services/utils';
import X9Logger from '../../../services/X9Logger';
import { newConfigVendas } from '../../../components/Tabela';
import Filtro from '../../../components/Filtro';
import { TextField } from '@material-ui/core';
import { permissoes } from '../../../services/utils/permissoes';

const Cheque: React.FC = () => {
  const tableRef = useRef();
  const sintuation = [
    { label: 'Aguardando', value: 'aguardando' },
    { label: 'Depositado', value: 'depositado' },
    { label: 'Reapresentado', value: 'reapresentado' },
  ];
  const state = useLocalStore(() => ({
    pageSize: 0,
    setPageSize(value: number) {
      this.pageSize = value + 1;
    },
    totais: 0,
    selectedCheque: 0,
    props: {} as Cheque,
    situacao: null as SelectRow | null,
    sync: false,
    nome_emitente: '',
  }));
  // const modalConta = useModal();
  const modalCadastrarCheque = useModal();
  const modalExclusao = useModal();
  const store = useStore();
  const history = useHistory();
  store.propriedade && store.validarPermissao(permissoes.FINANCEIRO_CONTROLE_DE_CHEQUES_LEITURA, history);
  const temPermissaoEscrita = store.checkPermissions(permissoes.FINANCEIRO_CONTROLE_DE_CHEQUES_ESCRITA);

  const columns: Column<object>[] = [
    {
      align: 'center',
      field: 'data_recebido',
      title: 'D. Recebido',
      render({ data_recebido }: any) {
        return Formatter.dateToString(new Date(data_recebido), '/');
      },
      ...(true && ({ width: 160 } as object)),
    },
    {
      field: 'acoes',
      render: (rowData: any) => (
        <ActionButton
          editar={
            temPermissaoEscrita
              ? () => {
                  state.selectedCheque = rowData.id;
                  // modalConta.open();
                  modalCadastrarCheque.open();
                }
              : undefined
          }
          voltou={
            temPermissaoEscrita
              ? async () => {
                  await store.cheques.voltou(rowData.id, {
                    voltou: true,
                  } as any);
                  (tableRef.current as any).onQueryChange();
                }
              : undefined
          }
          excluir={
            temPermissaoEscrita && rowData.padrao
              ? undefined
              : () => {
                  state.props = rowData.props;
                  modalExclusao.open();
                }
          }
        />
      ),
      ...(true && ({ width: 25 } as object)),
    },
    {
      align: 'left',
      field: 'cliente',
      title: 'Cliente',
      ...(true && ({ width: 150 } as object)),
    },
    {
      align: 'center',
      field: 'banco',
      title: 'Banco',
      ...(true && ({ width: 100 } as object)),
    },
    {
      align: 'right',
      field: 'n_folha',
      title: 'N. Folha',
      ...(true && ({ width: 130 } as object)),
    },
    {
      align: 'left',
      field: 'nome_emitente',
      title: 'Nome Emitente',
      ...(true && ({ width: 200 } as object)),
    },
    {
      align: 'right',
      field: 'valor',
      title: 'Valor (R$)',
      type: 'numeric',
      render: ({ valor }: any) => {
        return Formatter.formatNumber(2, valor);
      },
      ...(true && ({ width: 120 } as object)),
    },
    {
      align: 'left',
      field: 'data_deposito',
      title: 'D. depósito',
      type: 'date',
      render({ data_deposito }: any) {
        return data_deposito ? Formatter.dateToString(new Date(data_deposito), '/') : null;
      },
      ...(true && ({ width: 100 } as object)),
    },
    {
      align: 'left',
      field: 'fornecedor',
      title: 'Foi para quem',
      render: ({ fornecedor }: any) => {
        return fornecedor ? fornecedor.nome : 'Nenhum fornecedor';
      },
      ...(true && ({ width: 200 } as object)),
    },
    {
      align: 'center',
      field: 'voltou',
      title: 'Voltou',
      render({ voltou }: any) {
        return voltou ? <p style={{ color: 'red' }}>Sim</p> : 'Não';
      },
      ...(true && ({ width: 50 } as object)),
    },
    {
      align: 'left',
      field: 'situacao',
      title: 'Situação',
      render({ situacao }: any) {
        return situacao[0].toUpperCase() + situacao.substring(1);
      },
      ...(true && ({ width: 130 } as object)),
    },
    {
      align: 'left',
      field: 'data_situacao',
      title: 'D. Situação',
      render({ data_situacao }: any) {
        return data_situacao ? Formatter.dateToString(new Date(data_situacao), '/') : null;
      },
      ...(true && ({ width: 100 } as object)),
    },
  ];
  const rows = (cheques: Cheque[]): object[] => {
    if (cheques.length) {
      if (cheques.length >= 50) {
        state.setPageSize(50);
      } else {
        state.setPageSize(cheques.length);
      }
    } else {
      state.setPageSize(0);
    }

    return cheques.map((cheque) => {
      return {
        id: cheque.id,
        propriedade: cheque.propriedade,
        banco: cheque.banco,
        cliente: cheque.cliente && cheque.cliente?.nome,
        data_deposito: cheque.data_deposito,
        data_recebido: cheque.data_recebido,
        data_situacao: cheque.data_situacao,
        fornecedor: cheque.fornecedor,
        n_folha: cheque.n_folha,
        nome_emitente: cheque.nome_emitente,
        observacao: cheque.observacao,
        situacao: cheque.situacao,
        valor: cheque.valor,
        voltou: cheque.voltou,
        props: cheque,
      };
    });
  };

  async function sync(): Promise<void> {
    await syncModel.cheques();

    state.sync = true;
  }

  // eslint-disable-next-line
  useEffect(() => store.setTitulo('Controle de Cheques'), []);

  useEffect(() => {
    if (store.propriedade) {
      sync();
      (tableRef.current as any)?.onQueryChange();
    }
    // eslint-disable-next-line
  }, [store.propriedade]);

  useEffect(() => {
    if (state.situacao !== null) {
      (tableRef.current as any).onQueryChange();
    }
  }, [state.situacao]);

  // async function filtrar(): Promise<void> {
  //   store.toggleLoader();
  //   await store.cheques.populate();
  //   store.toggleLoader();
  // }

  const searchByNomeEmitent = (e: React.ChangeEvent<HTMLInputElement>): void => {
    state.nome_emitente = e.target.value as string;
    (tableRef.current as any)?.onQueryChange();
  };

  const ExcluirConta: React.FC = () => {
    return (
      <div style={{ width: '100%' }}>
        <b>Cliente: </b> {state.props.cliente.nome}
        <br />
        <b>Data Recebido: </b>
        {Formatter.dateToString(new Date(state.props.data_recebido), '/')}
        <br />
        <b>Data Deposito: </b> {Formatter.dateToString(new Date(state.props.data_deposito), '/')}
        <br />
        <b>Data Situalção: </b>
        {Formatter.dateToString(new Date(state.props.data_situacao), '/')}
        <br />
        <b>Valor: </b> R$ {Formatter.formatNumber(2, state.props.valor)}
        <br />
        <b>Situação: </b> {`${state.props.situacao[0].toUpperCase()}${state.props.situacao.substring(1)}`}
        <br />
        {state.props.voltou ? <b style={{ color: 'red' }}>Voltou</b> : <b style={{ color: 'green' }}>Não Voltou</b>}
        <br />
      </div>
    );
  };

  return state.sync ? (
    <>
      <Header>
        <Flex style={{ width: 500 }}>
          <AddBtn
            disabled={!temPermissaoEscrita}
            onClick={() => {
              state.selectedCheque = 0;
              // modalConta.open();
              modalCadastrarCheque.open();
            }}
            text="ADICIONAR"
            Icon={AddIcon}
          />
          <div style={{ width: 200 }}>
            <Select
              placeholder="Situação "
              value={state.situacao as any}
              options={sintuation as any}
              onChange={(e: any) => {
                state.situacao = e;
              }}
            />
          </div>
          <div style={{ marginLeft: 15 }}>
            <TextField value={state.nome_emitente} onChange={searchByNomeEmitent} placeholder="Nome do emitente" />
          </div>
        </Flex>
        <Flex>
          <Filtro
            display={true}
            sync={() => {}}
            clear={() => {
              state.situacao = null;
              state.nome_emitente = '';
              (tableRef.current as any).onQueryChange();
            }}
          ></Filtro>
        </Flex>
      </Header>

      <Body grid={true}>
        <Conteudo style={{ padding: 0 }}>
          <MaterialTable
            tableRef={tableRef}
            columns={columns}
            data={
              store.propriedade
                ? (query) =>
                    new Promise((resolve) => {
                      try {
                        store.propriedade &&
                          apiV2
                            .get('/cheque/', {
                              params: {
                                page_size: 50,
                                page: query.page + 1,
                                prop: store.propriedade!.id,
                                situacao: state.situacao ? state.situacao.value : undefined,
                                nome_emitente: state.nome_emitente,
                              },
                            })
                            .then((res: any) => {
                              if (res.data.count) {
                                if (res.data.count >= 50) {
                                  state.setPageSize(50);
                                } else {
                                  state.setPageSize(res.data.count);
                                }
                              } else {
                                state.setPageSize(0);
                              }
                              state.totais = res.data.total;
                              resolve({
                                data: rows(res.data.results) as any,
                                page: query.page,
                                totalCount: res.data.count,
                              });
                            });
                      } catch (err) {
                        X9Logger.report(err as Error);
                        store.notificar('Ocorreu um erro ao adquirir informações. Tente novamente mais tarde!');
                      }
                    })
                : []
            }
            options={{ ...newConfigVendas.options, pageSize: 50 }}
            localization={newConfigVendas.localization}
            style={newConfigVendas.style}
          />
          <Flex
            style={{
              width: '100%',
              padding: '1rem',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0 auto',
              marginBottom: '-15px',
              border: '1px solid #999',
            }}
          >
            <p>TOTAL GERAL</p>

            <strong>R$ {Formatter.formatNumber(2, state.totais)} </strong>
          </Flex>
        </Conteudo>
      </Body>
      {modalCadastrarCheque.state && (
        <FormConta
          open={modalCadastrarCheque.state}
          handleClose={() => {
            modalCadastrarCheque.close();
            (tableRef.current as any).onQueryChange();
          }}
          chequeID={state.selectedCheque}
        />
      )}
      <Confirmar
        open={modalExclusao.state}
        content={<ExcluirConta />}
        title="Tem certeza que deseja excluir?"
        handleClose={modalExclusao.close}
        onCancel={modalExclusao.close}
        onConfirm={async () => {
          modalExclusao.close();

          store.toggleLoader();
          await store.cheques.excluir(state.props.id);
          (tableRef.current as any).onQueryChange();
          store.toggleLoader();
        }}
      />
    </>
  ) : null;
};

export default observer(Cheque);
