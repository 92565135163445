import React from 'react';
import useStore from '../../../services/hooks/useStore';
import { useLocalStore, observer } from 'mobx-react-lite';
import { Modal, ModalHeader, ModalBody, Divisor } from '../../../components/Modais/styles';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import Botao from '../../../components/Botoes/Botao';
import { tipos } from './index';
import Select from '../../../components/Select';

type Props = ModalProps & {
  categoriaID: number;
  rateio: boolean;
};
const FormCategoria: React.FC<Props> = ({ open, handleClose, categoriaID, rateio }) => {
  const store = useStore();
  const categoria = categoriaID ? store.categorias.get(categoriaID) : null;
  const state = useLocalStore(() => ({
    nome: categoria ? categoria.nome : '',
    tipo: categoria ? tipos.filter((tipo) => tipo.value === categoria.tipo)[0] : null,
    rateio: rateio,
  }));

  async function cadastrar(): Promise<void> {
    if (!state.nome) store.notificar('Informe o nome da categoria');
    else if (!state.tipo) store.notificar('Informe o tipo da categoria');
    else {
      handleClose();
      store.toggleLoader();

      const dados = {
        propriedade: store.propriedade!.id,
        nome: state.nome,
        tipo: state.tipo.value,
        editavel: true,
        rateio: state.rateio,
      };

      if (categoriaID) {
        await store.categorias.editar(categoriaID, dados);
        state.rateio = false;
      } else {
        await store.categorias.criar(dados);
        state.rateio = false;
      }

      store.toggleLoader();
    }
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{categoriaID ? 'Editar' : 'Novo'} categoria</ModalHeader>
      <ModalBody style={{ width: 400, height: 420, justifyContent: 'space-between' }}>
        <div style={{ width: '100%' }}>
          <Select value={state.tipo} placeholder="Tipo" onChange={(e: any) => (state.tipo = e)} options={tipos} />
          <TextField value={state.nome} onChange={(e) => (state.nome = e.target.value)} label="Nome" />
          <Divisor>
            <p>Rateio Custo Fixo</p>
          </Divisor>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.rateio}
                  onChange={() => {
                    state.rateio = true;
                  }}
                  color="default"
                />
              }
              label="Sim"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={!state.rateio}
                  color="default"
                  onChange={() => {
                    state.rateio = false;
                  }}
                />
              }
              label="Não"
            />
          </div>
        </div>

        <div style={{ width: 400, display: 'flex' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao onClick={cadastrar} variant="contained" cor="#00C853">
            Salvar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(FormCategoria);
