import React, { useEffect, useState } from 'react';
import { Conteudo, NewBody } from '../../components/Body';
import { PerfilForm } from './styles';

import useStore from '../../services/hooks/useStore';
import { api } from '../../services/utils';
import Botao from '../../components/Botoes/Botao';
import ReactInputMask from 'react-input-mask';
import { useLocalStore, observer } from 'mobx-react-lite';
import syncModel from '../../services/models/Sync';
import Formatter from '../../services/Formatter';
import { Flex } from '../../components/NavBar/styles';
import CPFCNPJInput from '../../components/CPFCNPJInput';
import {  TextField } from '@material-ui/core';

// TODO: remover endpoint cidades da api de relatórios ao implementar o material ui autocomplete em todas as telas

const Perfil: React.FC = () => {
  const [sync, setSync] = useState(false);
  const store = useStore();
  const cidadeRows = (estadoID: number): SelectRow[] =>
    store.cidades.arr
      ? store.cidades
          .getByEstado(estadoID)
          .map((cidade) => {
            return {
              label: cidade.nome,
              value: cidade.id.toString(),
            };
          })
          .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
      : [];

  const usuario = useLocalStore(() => ({
    nome: store.usuario.nome,
    inscricaoFederal: store.usuario.inscricao_federal,
    telefone: store.usuario.telefone,
    email: store.usuario.email,

    isCPF: false,

    populate() {
      this.nome = store.usuario.nome;
      this.inscricaoFederal = store.usuario.inscricao_federal;
      this.telefone = store.usuario.telefone;
      this.email = store.usuario.email;
    },
    setInscricaoFederal(e: React.ChangeEvent<HTMLInputElement>) {
      usuario.inscricaoFederal = e.target.value;
      if (Formatter.getNumeros(e.target.value).length <= 11) {
        usuario.isCPF = true;
      } else {
        usuario.isCPF = false;
      }
    },
    async salvar(e: React.FormEvent<HTMLFormElement>): Promise<void> {
      e.preventDefault();
      store.toggleLoader();
      await api
        .put(`/usuarios/${store.usuario.id}/?propriedade=${store.propriedade!.id}`, {
          nome: this.nome,

          inscricao_federal: Formatter.getNumeros(this.inscricaoFederal),
          telefone: Formatter.getNumeros(this.telefone),
          email: this.email,

          tipo_de_usuario: store.usuario.tipo_de_usuario.id,
          propriedade: store.propriedade!.id,
          permissoes: store.usuario.permissoes
            .filter((perm) => perm.propriedade.id === store.propriedade!.id)[0]
            .permissoes.map((perm) => perm.id),
        })
        .then(async () => {
          const request = await api.get('/auth/me/');
          store.setLogado(request.data);
          await store.refreshUser();
        });
      store.toggleLoader();
    },
  }));

  const propriedade = useLocalStore(() => ({
    estado: null as SelectRow | null,
    nome: '',
    atividade: null as SelectRow | null,
    inscricaoEstadual: '',
    licencaAmbiental: '',
    cidade: null as SelectRow | null,
    cor: '#000',
    populate() {
      (this.cor = store.propriedade!.cor),
        (this.estado = {
          label: store.propriedade!.estado.nome,
          value: store.propriedade!.estado.id.toString(),
        });
      this.nome = store.propriedade!.nome;
      this.atividade = {
        label: store.propriedade!.atividades[0].nome,
        value: store.propriedade!.atividades[0].id.toString(),
      };
      this.inscricaoEstadual = store.propriedade!.inscricao_estadual;
      this.licencaAmbiental = store.propriedade!.licenca_ambiental;
      this.cidade = {
        label: store.propriedade!.cidade.nome,
        value: store.propriedade!.cidade.id.toString(),
      };
    },
    async salvar(e: React.FormEvent<HTMLFormElement>): Promise<void> {
      e.preventDefault();
      store.toggleLoader();
      await api.put('/propriedades/' + store.propriedade!.id + '/', {
        nome: this.nome,
        estado: (this.estado && parseInt(this.estado.value)) || null,
        cidade: (this.cidade && parseInt(this.cidade.value)) || null,
        cor: this.cor,
        licenca_ambiental: this.licencaAmbiental,

        inscricao_estadual: this.inscricaoEstadual,
        usuarios: store.propriedade!.usuarios.map((usuario) => usuario.id),
        atividades: [(this.atividade && parseInt(this.atividade.value)) || null],
        cnpj: store.propriedade!.cnpj,
      });
      await store.refreshUser();
      store.toggleLoader();
    },
  }));

  async function sincronizar(): Promise<void> {
    await syncModel.perfil();
    setSync(true);

    propriedade.populate();
    usuario.populate();
  }

  useEffect(() => {
    store.setTitulo('Editar perfil');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (propriedade.estado && parseInt(propriedade.estado.value) !== store.propriedade?.estado.id) {
      const cidades = store.cidades?.getByEstado(parseInt(propriedade.estado!.value));
      propriedade.cidade =
        cidadeRows(parseInt(propriedade.estado.value)).filter((row) => parseInt(row.value) === cidades![0].id)[0] ||
        null;
    }

    // eslint-disable-next-line
  }, [propriedade.estado]);

  useEffect(() => {
    store.propriedade && sincronizar();
    // eslint-disable-next-line
  }, [store.propriedade]);

  return sync ? (
    <NewBody>
      <Conteudo>
        <Flex style={{ justifyContent: 'center', alignItems: 'flex-start' }}>
          <div style={{ width: '50%', textAlign: 'center' }}>
            <h1>Editar Usuário</h1>
            <PerfilForm onSubmit={usuario.salvar}>
              <TextField
                fullWidth
                required
                value={usuario.nome}
                onChange={(e) => (usuario.nome = e.target.value)}
                label="Nome"
              />
              <CPFCNPJInput value={usuario.inscricaoFederal} name="cpfCnpj" onChange={usuario.setInscricaoFederal} />
              <ReactInputMask
                value={usuario.telefone}
                onChange={(e) => (usuario.telefone = e.target.value)}
                mask="(99) 99999-9999"
                maskChar=""
              >
                {() => <TextField required label="Telefone" />}
              </ReactInputMask>
              <TextField
                required
                value={usuario.email}
                onChange={(e) => (usuario.email = e.target.value)}
                type="email"
                label="E-mail"
              />

              <Botao type="submit" variant="contained" cor="#00C853">
                Salvar perfil
              </Botao>
            </PerfilForm>
          </div>        
        </Flex>
      </Conteudo>
    </NewBody>
  ) : null;
};

export default observer(Perfil);
