import React, { useEffect, useMemo } from 'react';
import { VerticalBar, Lista, ListaItem, ListaTexto, DrawerShadow, ListaIcone } from './styles';
import useStore from '../../services/hooks/useStore';
import { Collapse, ListItem, ListItemIcon } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { observer, useLocalStore } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { permissoes } from '../../services/utils/permissoes';

const Drawer: React.FC = () => {
  const store = useStore();
  const history = useHistory();
  const state = useLocalStore(() => [false, false, false, false, false, false, false]);

  const isAquaponia = useMemo(() => {
    if (store.propriedade?.atividades?.length) {
      return store.propriedade?.atividades.some((atividade) => atividade.id === 8);
    }
    return false;
  }, [store.propriedade?.atividades]);

  function handleClick(index: number): void {
    if (store.onDrawer) {
      store.checkClick();
    } else {
      store.toggleDrawer();
    }
    state[index] = !state[index];
  }

  useEffect(() => {
    if (store.drawer === false) {
      state.fill(false);
    }
    // eslint-disable-next-line
  }, [store.drawer]);

  function onPointerLeave(): void {
    store.onDrawer && store.toggleOnDrawer();
  }

  return (
    <>
      <VerticalBar onPointerEnter={store.toggleOnDrawer} onPointerLeave={onPointerLeave} state={store.drawer}>
        <Lista>
          {/* Propriedade/Tanques */}
          <ListItem button onClick={() => handleClick(0)}>
            <ListItemIcon>
              <ListaIcone src="/images/propriedade.svg" alt="Propriedade" />
            </ListItemIcon>
            <ListaTexto>Propriedade</ListaTexto>
            {state[0] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={state[0]} timeout="auto" unmountOnExit>
            {store.propriedade && (
              <Lista>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.PROPRIEDADE_TANQUES_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.PROPRIEDADE_TANQUES_LEITURA)) {
                      history.push('/propriedade/tanques');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Tanques</ListaTexto>
                </ListaItem>
                {isAquaponia && (
                  <>
                    <ListaItem
                      onClick={() => {
                        history.push('/propriedade/reservatorios');
                        store.toggleDrawer();
                      }}
                    >
                      <ListaTexto>Reservatórios</ListaTexto>
                    </ListaItem>
                    <ListaItem
                      onClick={() => {
                        history.push('/propriedade/bancadas');
                        store.toggleDrawer();
                      }}
                    >
                      <ListaTexto>Bancadas</ListaTexto>
                    </ListaItem>
                  </>
                )}
              </Lista>
            )}
          </Collapse>

          {/* Produção */}
          <ListItem button onClick={() => handleClick(1)}>
            <ListItemIcon>
              <ListaIcone src="/images/producao.svg" alt="Produção" />
            </ListItemIcon>
            <ListaTexto>Produção</ListaTexto>
            {state[1] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={state[1]} timeout="auto" unmountOnExit>
            {store.propriedade && (
              <Lista>
                {isAquaponia && (
                  <ListaItem
                    onClick={() => {
                      history.push('/producao/plantio');
                      store.toggleDrawer();
                    }}
                  >
                    <ListaTexto>Plantio</ListaTexto>
                  </ListaItem>
                )}
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.PRODUCAO_POVOAMENTO_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.PRODUCAO_POVOAMENTO_LEITURA)) {
                      history.push('/producao/povoamento');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Povoamento</ListaTexto>
                </ListaItem>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.PRODUCAO_BIOMETRIA_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.PRODUCAO_BIOMETRIA_LEITURA)) {
                      history.push('/producao/biometria');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Biometria</ListaTexto>
                </ListaItem>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.PRODUCAO_MORTALIDADE_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.PRODUCAO_MORTALIDADE_LEITURA)) {
                      history.push('/producao/mortalidade');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Mortalidade</ListaTexto>
                </ListaItem>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.PRODUCAO_TRANSFERENCIA_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.PRODUCAO_TRANSFERENCIA_LEITURA)) {
                      history.push('/producao/transferencia');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Transferência</ListaTexto>
                </ListaItem>
              </Lista>
            )}
          </Collapse>

          {/* Controle de Rações */}
          <ListItem button onClick={() => handleClick(2)}>
            <ListItemIcon>
              <ListaIcone src="/images/controle-racoes.svg" alt="Controle Ração" />
            </ListItemIcon>
            <ListaTexto>Controle Ração</ListaTexto>
            {state[2] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={state[2]} timeout="auto" unmountOnExit>
            {store.propriedade && (
              <Lista>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.CONTROLE_RACAO_ESTOQUE_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.CONTROLE_RACAO_ESTOQUE_LEITURA)) {
                      history.push('/producao/estoque');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Estoque de Ração</ListaTexto>
                </ListaItem>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.CONTROLE_RACAO_COMPRA_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.CONTROLE_RACAO_COMPRA_LEITURA)) {
                      history.push('/producao/racao');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Compra Ração</ListaTexto>
                </ListaItem>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.CONTROLE_RACAO_ARRACOAMENTO_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.CONTROLE_RACAO_ARRACOAMENTO_LEITURA)) {
                      history.push('/producao/arracoamento');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Arraçoamento</ListaTexto>
                </ListaItem>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.CONTROLE_RACAO_PROGRAMA_ALIMENTAR_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.CONTROLE_RACAO_PROGRAMA_ALIMENTAR_LEITURA)) {
                      history.push('/producao/programas-alimentares');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Programas Alimentares</ListaTexto>
                </ListaItem>
              </Lista>
            )}
          </Collapse>
          {/* -------------------------------------------------------- */}
          {/* insumos */}
          <ListItem button onClick={() => handleClick(3)}>
            <ListItemIcon>
              <ListaIcone src="/images/Estoque_de_Insumos.png" alt="Insumos" />
            </ListItemIcon>
            <ListaTexto>Controle de Insumos</ListaTexto>
            {state[3] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={state[3]} timeout="auto" unmountOnExit>
            {store.propriedade && (
              <Lista>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.CONTROLE_DE_INSUMOS_ESTOQUE_DE_INSUMOS_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.CONTROLE_DE_INSUMOS_ESTOQUE_DE_INSUMOS_LEITURA)) {
                      history.push('/producao/insumos');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Estoque de Insumos</ListaTexto>
                </ListaItem>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.CONTROLE_DE_INSUMOS_COMPRAS_INSUMOS_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.CONTROLE_DE_INSUMOS_COMPRAS_INSUMOS_LEITURA)) {
                      history.push('/producao/insumos/compras');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Compras Insumos</ListaTexto>
                </ListaItem>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.CONTROLE_DE_INSUMOS_APLICACAO_DE_INSUMOS_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.CONTROLE_DE_INSUMOS_APLICACAO_DE_INSUMOS_LEITURA)) {
                      history.push('/producao/insumos/aplicacao');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Aplicação de Insumos</ListaTexto>
                </ListaItem>
              </Lista>
            )}
          </Collapse>

          {/* --------------------------------------------------------- */}

          {/* Vendas */}
          <ListItem button onClick={() => handleClick(4)}>
            <ListItemIcon>
              <ListaIcone src="/images/venda.svg" alt="Vendas" />
            </ListItemIcon>
            <ListaTexto>Vendas</ListaTexto>
            {state[4] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={state[4]} timeout="auto" unmountOnExit>
            {store.propriedade && (
              <Lista>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.VENDAS_MINHAS_VENDAS_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.VENDAS_MINHAS_VENDAS_LEITURA)) {
                      history.push('/vendas');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Minhas Vendas</ListaTexto>
                </ListaItem>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.VENDAS_CLIENTES_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.VENDAS_CLIENTES_LEITURA)) {
                      history.push('/vendas/clientes');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Clientes</ListaTexto>
                </ListaItem>

                <ListaItem
                  disabled={!store.checkPermissions(permissoes.VENDAS_VENDAS_PRODUTOS_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.VENDAS_VENDAS_PRODUTOS_LEITURA)) {
                      history.push('/financeiro/movimentacao/true');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Vendas Produtos</ListaTexto>
                </ListaItem>
              </Lista>
            )}
          </Collapse>

          {/* Financeiro */}
          <ListItem button onClick={() => handleClick(5)}>
            <ListItemIcon>
              <ListaIcone src="/images/financeiro.svg" alt="Financeiro" />
            </ListItemIcon>
            <ListaTexto>Financeiro</ListaTexto>
            {state[5] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={state[5]} timeout="auto" unmountOnExit>
            {store.propriedade && (
              <Lista>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.FINANCEIRO_PAINEL_FINANCEIRO_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.FINANCEIRO_PAINEL_FINANCEIRO_LEITURA)) {
                      history.push('/financeiro/painel/dashboard');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Painel Financeiro</ListaTexto>
                </ListaItem>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.FINANCEIRO_MOVIMENTACOES_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.FINANCEIRO_MOVIMENTACOES_LEITURA)) {
                      history.push('/financeiro/movimentacao/');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Movimentações</ListaTexto>
                </ListaItem>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.FINANCEIRO_CONTA_BANCARIA_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.FINANCEIRO_CONTA_BANCARIA_LEITURA)) {
                      history.push('/financeiro/contas');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Conta Bancária</ListaTexto>
                </ListaItem>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.FINANCEIRO_CATEGORIA_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.FINANCEIRO_CATEGORIA_LEITURA)) {
                      history.push('/financeiro/categorias');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Categoria</ListaTexto>
                </ListaItem>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.FINANCEIRO_FORNECEDORES_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.FINANCEIRO_FORNECEDORES_LEITURA)) {
                      history.push('/financeiro/fornecedores');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Fornecedores</ListaTexto>
                </ListaItem>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.FINANCEIRO_RATEIO_DE_CUSTO_FIXO_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.FINANCEIRO_RATEIO_DE_CUSTO_FIXO_LEITURA)) {
                      history.push('/financeiro/rateio');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Rateio de Custo Fixo</ListaTexto>
                </ListaItem>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.FINANCEIRO_CONTROLE_DE_CHEQUES_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.FINANCEIRO_CONTROLE_DE_CHEQUES_LEITURA)) {
                      history.push('/financeiro/controle-cheque');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Controle de Cheques</ListaTexto>
                </ListaItem>
              </Lista>
            )}
          </Collapse>

          {/* Qualidade */}
          <ListItem button onClick={() => handleClick(6)}>
            <ListItemIcon>
              <ListaIcone src="/images/qualidade_da_agua.svg" />
            </ListItemIcon>
            <ListaTexto>Qualidade e Sanidade</ListaTexto>
            {state[6] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={state[6]} timeout="auto" unmountOnExit>
            <Lista>
              {isAquaponia && (
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.QUALIDADE_E_SANIDADE_QUALIDADE_DA_AGUA_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.QUALIDADE_E_SANIDADE_QUALIDADE_DA_AGUA_LEITURA)) {
                      history.push('/agua-de-reservatorios');
                      store.toggleDrawer();
                    } else {
                      store.notificar('Você não tem permissão para acessar este módulo!');
                    }
                  }}
                >
                  <ListaTexto>Água de Reservatórios</ListaTexto>
                </ListaItem>
              )}

              <ListaItem
                disabled={!store.checkPermissions(permissoes.QUALIDADE_E_SANIDADE_QUALIDADE_DA_AGUA_LEITURA)}
                onClick={() => {
                  if (store.checkPermissions(permissoes.QUALIDADE_E_SANIDADE_QUALIDADE_DA_AGUA_LEITURA)) {
                    history.push('/qualidade-da-agua');
                    store.toggleDrawer();
                  } else {
                    store.notificar('Você não tem permissão para acessar este módulo!');
                  }
                }}
              >
                <ListaTexto>Qualidade da Água</ListaTexto>
              </ListaItem>
              <ListaItem
                disabled={!store.checkPermissions(permissoes.QUALIDADE_E_SANIDADE_TEMPERATURA_LEITURA)}
                onClick={() => {
                  if (store.checkPermissions(permissoes.QUALIDADE_E_SANIDADE_TEMPERATURA_LEITURA)) {
                    history.push('/temperatura');
                    store.toggleDrawer();
                  } else {
                    store.notificar('Você não tem permissão para acessar este módulo!');
                  }
                }}
              >
                <ListaTexto>Cadastro de Temperaturas</ListaTexto>
              </ListaItem>
              <ListaItem
                disabled={!store.checkPermissions(permissoes.QUALIDADE_E_SANIDADE_TEMPERATURA_LEITURA)}
                onClick={() => {
                  if (store.checkPermissions(permissoes.QUALIDADE_E_SANIDADE_TEMPERATURA_LEITURA)) {
                    history.push('/qualidade-da-agua/relatorio-analises');
                    store.toggleDrawer();
                  } else {
                    store.notificar('Você não tem permissão para acessar este módulo!');
                  }
                }}
              >
                <ListaTexto>Relatório de Análises</ListaTexto>
              </ListaItem>
            </Lista>
          </Collapse>

          <ListItem button onClick={() => handleClick(7)}>
            <ListItemIcon>
              <ListaIcone src="/images/relatorios.png" />
            </ListItemIcon>
            <ListaTexto>Relatórios</ListaTexto>
            {state[7] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={state[7]} timeout="auto" unmountOnExit>
            {store.propriedade && (
              <Lista>
                <ListaItem
                  disabled={!store.checkPermissions(permissoes.PAINEL_ZOOTECNICO_LEITURA)}
                  onClick={() => {
                    if (store.checkPermissions(permissoes.PAINEL_ZOOTECNICO_LEITURA)) {
                      history.push('/relatorios/painel-zootecnico');
                      store.toggleDrawer();
                    } else store.notificar('Você não tem permissão para acessar este módulo!');
                  }}
                >
                  <ListaTexto>Painel Zootécnico</ListaTexto>
                </ListaItem>
              </Lista>
            )}
          </Collapse>
        </Lista>
      </VerticalBar>
      <DrawerShadow state={store.drawer} onClick={store.checkClick} />
    </>
  );
};

export default observer(Drawer);
