import { NavLink } from 'react-router-dom';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import Select from 'react-select';

export const TabBar = styled.div`
  display: flex;
  align-items: center;

  padding: 10px;

  border-bottom-style: solid;
  border-color: #d9d9d9;
  border-width: 2px;

  background-color: #f5f6f8;
`;

export const TabItem = styled.div`
  display: flex;
  align-items: center;

  border-right-style: solid;
  border-color: #cfcfcf;
  border-width: 2px;

  padding: 0 60px;
`;

export const NavbarLink = styled(NavLink)`
  color: black;
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 18px;
  text-decoration: none;

  &:hover {
    color: #ec7722;
    text-decoration: underline 3px;
    text-underline-offset: 10px;
  }
  &:active-style {
    color: #ec7722;
    text-decoration: underline 3px;
    text-underline-offset: 10px;
  }
`;

export const Conta = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;

  width: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);

  .right {
    text-align: right;
  }
  .left {
    text-align: left;
  }

  &:hover {
    background: #eeeeee;
  }
`;

export const SelectPropriedade = styled(Select)`
  width: 150px;
  margin-right: 10px;

  .navSel__placeholder {
    color: white;
  }
  .navSel__control {
    background-color: #42a5f4;
    border: 1px solid;
    border-color: white;
    color: white;
    .navSel__value-container .navSel__single-value {
      color: white;
    }
  }

  .navSel__menu {
    color: black;
    z-index: 1000000000 !important;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2) !important;
  }

  .navSel__indicators {
    .navSel__indicator-separator {
      background-color: hsla(0, 0%, 100%, 0.68);
    }
    .navSel__dropdown-indicator {
      color: hsla(0, 0%, 100%, 0.68);
    }
  }
`;

export const BarGraph = styled.div.attrs((props: { color: string; scale: number }) => props)`
  background-color: ${(props) => props.color};
  height: 7vh;
  width: ${(props) => (18 * props.scale).toString()}vw;
`;

export const ReportButton = styled(Button)`
  background-color: #059de7 !important;
  color: white !important;
  border-radius: 5px !important;

  margin-right: 15px !important;

  box-shadow: 0 2px 4px 0 #00000063 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 12px !important;
`;
export const ButtonOut = styled(Button)`
  border-radius: 5px !important;
  box-shadow: 0 2px 4px 0 #00000063 !important;
  background-color: #fff !important;
  color: #000 !important;
  border: 2px solid #29b5f6 !important;
  font-weight: ligth;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-size: 11px !important;
  text-transform: normal;
`;
export const ButtonDisable = styled(Button)`
  border-radius: 5px !important;
  box-shadow: 0 2px 4px 0 #00000063 !important;
  background-color: gray !important;
  color: #000 !important;
  border: none !important;
  font-weight: ligth;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-size: 11px !important;
  text-transform: normal;

`;
