import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

class Formatter {
  /**
   * Função que transforma um número em uma string em formato local
   * @param precision Quantidade de casas decimais
   * @param number Int, float
   * @example
   * // retorna '1.000,50'
   * Formatter.Formatter.formatNumber(2, 1000.50)
   */
  static formatNumber(precision: number, number?: number | string): string {
    if (typeof number === 'number') {
      return Number(number.toFixed(precision)).toLocaleString('pt', {
        minimumFractionDigits: precision,
      });
    } else if (typeof number === 'string') {
      return Number(parseFloat(number).toFixed(precision)).toLocaleString('pt', {
        minimumFractionDigits: precision,
      });
    } else return '';
  }

  /**
   * Retorna o dia/mês/ano
   * @param data Ex: "00/00/0000 00:00:00"
   */
  static getData(data: string): string {
    return data.split(' ')[0];
  }

  /**
   * Retorna o dia/mês/ano a partir de um objeto data
   * @param data Date object
   */
  static dateToString(date: MaterialUiPickersDate, char?: string): string {
    if (char) {
      return (
        ('0' + date!.getDate()).slice(-2) + char + ('0' + (date!.getMonth() + 1)).slice(-2) + char + date!.getFullYear()
      );
    }
    return (
      ('0' + date!.getDate()).slice(-2) +
      '/' +
      ('0' + (date!.getMonth() + 1)).slice(-2) +
      '/' +
      date!.getFullYear() +
      ' ' +
      ('0' + date!.getHours()).slice(-2) +
      ':' +
      ('0' + date!.getMinutes()).slice(-2) +
      ':' +
      ('0' + date!.getSeconds()).slice(-2)
    );
  }

  /**
   * Retorna o yyyy-mm-dd a partir de um objeto data
   * @param data Date object
   */
  static dateToISOString(date: MaterialUiPickersDate): string | null {
    if (!date) return null;
    return date.toISOString().slice(0, 10);
  }

  static formatCPFCNPJ(cpf_cnpj: string): string {
    const cpfCNPJ = cpf_cnpj.replace(/[^0-9]/g, '');

    if (cpfCNPJ.length === 11) {
      return cpfCNPJ.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (cpfCNPJ.length === 14) {
      return cpfCNPJ.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
    } else {
      return cpf_cnpj;
    }
  }

  /**
   * Retorna apenas os números de uma string
   * @param maskedValue CPF ou CNPJ com máscara, telefone com máscara, etc
   */
  static getNumeros(maskedValue: string): string {
    return maskedValue.replace(/[^0-9]/g, '');
  }

  /**
   * Formata um valor numérico para a moeda brasileira (Real - BRL).
   *
   * @param rawValue O valor numérico a ser formatado.
   * @returns Uma string formatada no formato de moeda brasileira (Real - BRL).
   */
  static formatBRLCurrency(rawValue: number): string {
    return rawValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  /**
   * Formada datas no formato de string "14/07/2023 17:27:07" para datas do JavaScript
   * @param dateString
   * @returns Date
   */
  static stringToDate(dateString: string) {
    const regex = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}$/;
    if (!regex.test(dateString)) return new Date();

    const [datePart, timePart] = dateString.split(' '); // Separa a parte da data da parte do tempo
    const [day, month, year] = datePart.split('/').map(Number); // Extrai o dia, mês e ano
    const [hour, minute, second] = timePart.split(':').map(Number); // Extrai a hora, minuto e segundo

    // Cria uma nova instância de Date com os componentes extraídos
    const date = new Date(year, month - 1, day, hour, minute, second);
    return date;
  }
}

export default Formatter;
