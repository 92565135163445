import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, TextField } from '@material-ui/core';

import { ModalBody, ModalHeader, Modal } from '../../../components/Modais/styles';
import { Flex } from '../../../components/NavBar/styles';
import Botao from '../../../components/Botoes/Botao';
import InputNumero from '../../../components/InputNumero';
import Select from '../../../components/Select';
import useStore from '../../../services/hooks/useStore';

type Form = {
  categoria: SelectRow | null;
  descricao: string;
  valorTotal: number;
  quantidade: number;
  valorUnitario: number;
};

type Props = {
  open: boolean;
  handleClose: () => void;
  handleAdd: (dados: DespesaItemCriacao) => void;
  handleUpdate: (id: number, dados: DespesaItemCriacao) => void;
  item: DespesaItemCriacao | null;
  categoria: string;
  valorFrete?: number;
  outrosValores?: number;
  tipoCategoria?: string;
};

export function ModalMovimentacaoItem({
  open,
  handleClose,
  handleAdd,
  handleUpdate,
  item,
  categoria,
  tipoCategoria,
}: Props) {
  const store = useStore();
  const [sync, setSync] = useState(false);

  const categoriasOptions = store.categorias.getByTipo(tipoCategoria ?? 'despesas').map((categoria) => {
    return { label: categoria.nome, value: categoria.id };
  });

  const defaultCategoriaId = Number(categoria) || null;
  const categoriaDefault = categoriasOptions.find((cat) => cat.value === defaultCategoriaId) || null;
  const defaultForm: Form = {
    categoria: categoriaDefault,
    descricao: '',
    quantidade: 0,
    valorTotal: 0,
    valorUnitario: 0,
  };
  const initialForm: Form = item
    ? {
        categoria: null,
        descricao: item.descricao,
        quantidade: item.quantidade,
        valorTotal: item.valor_total_item,
        valorUnitario: item.valor_uni_item,
      }
    : defaultForm;
  const [form, setForm] = useState<Form>(initialForm);

  function handleSubmit() {
    if (!form.categoria) store.notificar('Informe a categoria');
    else if (!form.descricao) store.notificar('Informe a descrição');
    else if (!form.quantidade || form.quantidade <= 0) store.notificar('Informe a quantidade');
    else if (!form.valorTotal || form.valorTotal <= 0) store.notificar('Informe o valor total');
    else if (!form.valorUnitario || form.valorUnitario <= 0)
      store.notificar('Não foi possível calcular o valor unitário');
    else {
      const dados: DespesaItemCriacao = {
        id: item ? item.id : new Date().getTime(),
        categoria: form.categoria?.value,
        descricao: form.descricao,
        quantidade: form.quantidade,
        valor_uni_item: form.valorUnitario,
        valor_total_item: form.valorTotal,
        valor_total: 0,
        valor_unitario: 0,
        valor_outros_item: 0,
        valor_frete_item: 0,
      };

      if (item) handleUpdate(item.id, dados);
      else handleAdd(dados);

      handleClose();
    }
  }

  function updateFormEvent(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    if (name) {
      setForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  }

  useEffect(() => {
    setSync(true);
  }, []);

  useEffect(() => {
    setForm(initialForm);
  }, [open]);

  useEffect(() => {
    if (item) {
      const categoria = categoriasOptions.find((cat) => cat.value === item.categoria);
      if (categoria) {
        setForm((prev) => ({ ...prev, categoria }));
      }
    }
  }, [item]);

  useEffect(() => {
    if (form.quantidade > 0)
      setForm((prev) => ({
        ...prev,
        valorUnitario: +(prev.valorTotal / prev.quantidade).toFixed(2),
      }));
  }, [form.quantidade, form.valorTotal]);

  if (!sync) return null;
  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>{item ? 'Editar' : 'Novo'} Item</ModalHeader>
      <ModalBody style={{ width: 500 }}>
        <Box width="100%">
          <Select
            placeholder="Categoria"
            value={form.categoria}
            onChange={(e: any) => setForm((prev) => ({ ...prev, categoria: e }))}
            options={categoriasOptions}
          />
          <TextField
            value={form.descricao}
            onChange={updateFormEvent}
            name="descricao"
            label="Descrição"
            inputProps={{ maxLength: 100 }}
          />
          <InputNumero
            value={form.valorTotal}
            onChange={(value) => setForm((prev) => ({ ...prev, valorTotal: value }))}
            precision="2"
            label="Valor Total"
          />
          <InputNumero
            value={form.quantidade}
            onChange={(value) => setForm((prev) => ({ ...prev, quantidade: value }))}
            precision="3"
            label="Quantidade"
          />
          <InputNumero
            value={form.valorUnitario}
            disabled
            onChange={(value) => setForm((prev) => ({ ...prev, valorUnitario: value }))}
            precision="2"
            label="Valor Unitário"
          />

          <Flex>
            <Botao onClick={handleClose} variant="contained" cor="#FC7467">
              Cancelar
            </Botao>
            <Botao onClick={handleSubmit} variant="contained" cor="#00C853">
              Salvar
            </Botao>
          </Flex>
        </Box>
      </ModalBody>
    </Modal>
  );
}
