import { observer } from 'mobx-react-lite';
import React from 'react';
import { TabBar, TabItem, NavbarLink } from './styles';

const PainelNavigationTab: React.FC = () => {
    return (
        <>
            <TabBar>
                <TabItem>
                    <NavbarLink className="nav-link" to="/financeiro/painel/dashboard"
                        activeStyle={{ color: '#EC7722', textDecorationLine: 'underline', textDecorationThickness: '3px', textUnderlineOffset: '10px' }}>
                        Painel
                    </NavbarLink >
                </TabItem>
                <TabItem>
                    <NavbarLink className="nav-link" to="/financeiro/painel/fluxo-caixa"
                        activeStyle={{ color: '#EC7722', textDecorationLine: 'underline', textDecorationThickness: '3px', textUnderlineOffset: '10px' }}>
                        Fluxo de Caixa
                    </NavbarLink >
                </TabItem>
                <TabItem>
                    <NavbarLink className="nav-link" to="/financeiro/painel/relatorio-financeiro"
                        activeStyle={{ color: '#EC7722', textDecorationLine: 'underline', textDecorationThickness: '3px', textUnderlineOffset: '10px' }}>
                        Relatórios Financeiros e Compras
                    </NavbarLink >
                </TabItem>
                {false && <TabItem>
                    <NavbarLink className="nav-link" to="/financeiro/painel/relatorio-compra-venda"
                        onClick={() => { }}>
                        Relatórios Compras e Vendas
                    </NavbarLink >
                </TabItem>
                }
            </TabBar>
        </>
    );
};

export default observer(PainelNavigationTab);