import React from 'react';
import { Modal, ModalHeader, ModalBody } from '../../../components/Modais/styles';
import Botao from '../../../components/Botoes/Botao';
import useStore from '../../../services/hooks/useStore';
import { api } from '../../../services/utils';
import { useLocalStore, observer } from 'mobx-react-lite';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { TextField } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import InputNumero from '../../../components/InputNumero';
import Select from '../../../components/Select';
import Formatter from '../../../services/Formatter';

const tiposDeAjuste = [
  { label: 'Entrada', value: 'entrada' },
  { label: 'Saída', value: 'saida' },
];

type State = {
  racao: SelectRow | null;
  ajuste: SelectRow | null;
  data: MaterialUiPickersDate;
  quantidade: number;
  motivo: string;
};

type Props = ModalProps & {
  ajuste?: CompraRacao;
};

const AjusteEstoque: React.FC<Props> = ({ open, handleClose, ajuste }) => {
  const state = useLocalStore(
    (): State => ({
      racao: ajuste ? { label: ajuste.racao.nome, value: ajuste.racao.id.toString() } : null,
      ajuste: ajuste ? tiposDeAjuste.filter((tipo) => tipo.value === ajuste.tipo)[0] : null,
      data: ajuste
        ? new Date(ajuste.data.substr(3, 2) + '/' + ajuste.data.substr(0, 2) + '/' + ajuste.data.substr(6, 4))
        : new Date(),
      quantidade: ajuste ? ajuste?.total_kg : 0,
      motivo: ajuste?.justificativa || '',
    }),
  );
  const store = useStore();

  async function cadastrar(): Promise<void> {
    if (!state.racao) store.notificar('Informe a ração!');
    else if (!state.ajuste) store.notificar('Informe o tipo de ajuste!');
    else if (state.quantidade === 0) store.notificar('Informe a quantidade!');
    else if (state.ajuste.value === 'saida' && state.quantidade > store.racoes.get(Number(state.racao?.value))?.estoque)
      store.notificar('Ajuste de Estoque - Saída a quantidade está maior que o estoque!');
    else {
      handleClose();
      store.toggleLoader();
      let total_kg = state.quantidade;
      if (state.ajuste.value === 'saida') {
        total_kg = state.quantidade * -1;
      }

      const data = {
        ajuste_estoque: true,
        propriedade: store.propriedade!.id,
        tipo: state.ajuste.value,
        racao: parseInt(state.racao.value),
        data: Formatter.dateToString(state.data),
        total_kg: total_kg,
        justificativa: state.motivo,
      };

      if (ajuste) {
        await api
          .patch(`/compra-racao/${ajuste.id}/?propriedade=${store.propriedade!.id}`, data)
          .then(async () => {
            await store.racoes.populate();
            await store.comprasDeRacoes.populate();
            store.notificar('Ajuste editado com sucesso!');
          })
          .catch(() => store.notificar('Ocorreu um erro na edição do ajuste. Entre em contato com o nosso suporte!'));
      } else {
        await api
          .post(`/compra-racao/?propriedade=${store.propriedade!.id}`, data)
          .then(async () => {
            await store.racoes.populate();
            await store.comprasDeRacoes.populate();
            store.notificar('Ajuste registrado com sucesso!');
          })
          .catch(() => store.notificar('Ocorreu um erro no registro de ajuste. Entre em contato com o nosso suporte!'));
      }
      store.toggleLoader();
    }
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader>Ajuste de estoque</ModalHeader>
      <ModalBody>
        <Select
          value={state.racao}
          placeholder="Nome da ração"
          onChange={(e: any) => (state.racao = e)}
          options={store.racoes.getSelectRows()}
        />
        <Select
          value={state.ajuste}
          placeholder="Tipo de ajuste"
          onChange={(e: any) => (state.ajuste = e)}
          options={tiposDeAjuste}
        />

        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Data"
          value={state.data}
          onChange={(date) => (state.data = date)}
        />

        <InputNumero
          value={state.quantidade}
          onChange={(value) => (state.quantidade = value)}
          precision="2"
          label="Quantidade (kg)"
        />
        <TextField label="Motivo" value={state.motivo} onChange={(e) => (state.motivo = e.target.value)} />

        <div style={{ width: '100%', display: 'flex' }}>
          <Botao onClick={handleClose} variant="contained" cor="#FC7467">
            Cancelar
          </Botao>
          <Botao onClick={cadastrar} variant="contained" cor="#00C853">
            Salvar
          </Botao>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(AjusteEstoque);
