import React from 'react';
import { Center, LoginMessage, BotaoApp } from './styles';
import { Flex } from '../../components/NavBar/styles';
import AppleIcon from '@material-ui/icons/Apple';
import ShopIcon from '@material-ui/icons/Shop';

const Mobile: React.FC = () => {
  return (
    <Center>
      <h1>Essa versão é apenas para uso no computador!</h1>
      <LoginMessage>
        <p>Você não possui uma conta ainda?</p>
        <Flex style={{ justifyContent: 'space-between' }}>
          <BotaoApp
            secondary
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/br/app/aquabit/id1491215734"
          >
            <AppleIcon /> App Store
          </BotaoApp>
          <BotaoApp
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.aquabit.aquabitfarm"
          >
            <ShopIcon /> <p>Play Store</p>
          </BotaoApp>
        </Flex>
      </LoginMessage>
    </Center>
  );
};

export default Mobile;
